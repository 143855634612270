import {
  DASHBOARD_ANALYSIS_FAIL,
  DASHBOARD_ANALYSIS_REQUEST,
  DASHBOARD_ANALYSIS_SUCCESS,
  DASHBOARD_TAG_ANALYSIS_FAIL,
  DASHBOARD_TAG_ANALYSIS_REQUEST,
  DASHBOARD_TAG_ANALYSIS_SUCCESS,
} from '../../../constants/adminConstants/dashboardConstants'

export const dashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_ANALYSIS_REQUEST:
      return { loading: true }
    case DASHBOARD_ANALYSIS_SUCCESS:
      return { loading: false, result: action.payload }
    case DASHBOARD_ANALYSIS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardTagAnalysisReducer = (
  state = { loading: true },
  action,
) => {
  switch (action.type) {
    case DASHBOARD_TAG_ANALYSIS_REQUEST:
      return { loading: true }
    case DASHBOARD_TAG_ANALYSIS_SUCCESS:
      return { loading: false, tags: action.payload }
    case DASHBOARD_TAG_ANALYSIS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
