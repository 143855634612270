import axios from 'axios'
import {
  ASSIGN_BATCH_REQUEST,
  ASSIGN_BATCH_SUCCESS,
  ASSIGN_BATCH_FAIL,
} from '../../../constants/adminConstants/TagsConstants'

export const assignTag = (company, batch, orderId, productId) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: ASSIGN_BATCH_REQUEST,
    payload: { company, batch, orderId, productId },
  })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.post(
      '/api/admin/assign-batch',
      { company, batch, orderId, productId },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      },
    )
    dispatch({ type: ASSIGN_BATCH_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: ASSIGN_BATCH_FAIL, payload: message })
  }
}
