import {
  FARMER_SCAN_TAG_FAIL,
  FARMER_SCAN_TAG_REQUEST,
  FARMER_SCAN_TAG_RESET,
  FARMER_SCAN_TAG_SUCCESS,
  FARMER_CHECK_STATUS_FAIL,
  FARMER_CHECK_STATUS_REQUEST,
  FARMER_CHECK_STATUS_RESET,
  FARMER_CHECK_STATUS_SUCCESS,
  FARMER_VALIDATE_TAG_REQUEST,
  FARMER_VALIDATE_TAG_SUCCESS,
  FARMER_VALIDATE_TAG_FAIL,
  FARMER_VALIDATE_TAG_RESET,
} from '../../../constants/farmerConstants/tagsConstants'

export const farmerTagReducer = (state = {}, action) => {
  switch (action.type) {
    case FARMER_SCAN_TAG_REQUEST:
      return { loading: true }
    case FARMER_SCAN_TAG_SUCCESS:
      return { loading: false, result: action.payload }
    case FARMER_SCAN_TAG_FAIL:
      return { loading: false, error: action.payload }
    case FARMER_SCAN_TAG_RESET:
      return {}
    default:
      return state
  }
}

export const farmerTagValidateReducer = (state = {}, action) => {
  switch (action.type) {
    case FARMER_VALIDATE_TAG_REQUEST:
      return { loading: true }
    case FARMER_VALIDATE_TAG_SUCCESS:
      return { loading: false, result: action.payload }
    case FARMER_VALIDATE_TAG_FAIL:
      return { loading: false, error: action.payload }
    case FARMER_VALIDATE_TAG_RESET:
      return {}
    default:
      return state
  }
}

export const farmerTagStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case FARMER_CHECK_STATUS_REQUEST:
      return { loading: true }
    case FARMER_CHECK_STATUS_SUCCESS:
      return { loading: false, status: action.payload }
    case FARMER_CHECK_STATUS_FAIL:
      return { loading: false, error: action.payload }
    case FARMER_CHECK_STATUS_RESET:
      return {}
    default:
      return state
  }
}
