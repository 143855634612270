import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import MonoConnect from '@mono.co/connect.js'
import { useDispatch, useSelector } from 'react-redux'
import { exchangeToken } from '../../../actions/farmerActions/farmtroveAction/exchangeToken'
import { accountInformation } from '../../../actions/farmerActions/farmtroveAction/accountInformation'
import { accountTransactions } from '../../../actions/farmerActions/farmtroveAction/accountTransactions'
import { accountIncome } from '../../../actions/farmerActions/farmtroveAction/accountIncome'
import { TailSpin } from 'react-loading-icons'
import { MDBDataTableV5 } from 'mdbreact'
import moment from 'moment'
import { formatNumber } from '../../../components/functions'
import { profileDetail } from '../../../actions/authActions/profile'
import { MessageBox } from '../../../components'

// Farmtrove Financial - Dropdown
// - Accounts
// - Farmtrove Pay
// - Farmtrove Wallet

// Farmtrove Pension
// Farmtrove Insurance
export default function AccountPage() {
  const [datatable, setDatatable] = useState({})
  const [hide, setHide] = useState(false)
  const [codeID, setCodeID] = useState('')
  const [bal, setBal] = useState('')

  const toogleAcc = () => {
    setHide(hide ? false : true)
  }

  const accountDetail = useSelector((state) => state.accountDetail)
  const {
    loading: loadingDetails,
    error: detailsError,
    details,
  } = accountDetail

  const accountHistory = useSelector((state) => state.accountHistory)
  const {
    loading: loadingHistory,
    error: historyError,
    history,
  } = accountHistory

  const incomeAnalysis = useSelector((state) => state.incomeAnalysis)
  const { loading: loadingIncome, error: incomeError, incomes } = incomeAnalysis

  const userDetail = useSelector((state) => state.userDetail)
  const { loading: loadDetails, user } = userDetail

  const tokenExchanged = useSelector((state) => state.tokenExchanged)
  const { loading: loadingExchange, success } = tokenExchanged

  const monoConnect = React.useMemo(() => {
    const monoInstance = new MonoConnect({
      onSuccess: ({ code }) => setCodeID(code),
      key: process.env.REACT_APP_MONO_PUBLIC_KEY,
    })

    monoInstance.setup()

    return monoInstance
  }, [])

  const dispatch = useDispatch()

  useEffect(() => {
    if (codeID) {
      dispatch(exchangeToken(codeID))
      setCodeID('')
    }
  }, [codeID])

  useEffect(() => {
    dispatch(accountInformation())
    dispatch(accountTransactions())
    dispatch(accountIncome())
    dispatch(profileDetail())
  }, [dispatch, success])

  let num = 1

  useEffect(() => {
    if (history && history.data && history.data.length > 0) {
      setDatatable({
        columns: [
          {
            label: 'S/N',
            field: 'row',
          },
          {
            label: 'Type',
            field: 'type',
            width: 100,
          },
          {
            label: 'Date',
            field: 'date',
            width: 100,
          },
          {
            label: 'Amount',
            field: 'amount',
            width: 100,
          },
          {
            label: 'Balance',
            field: 'balance',
            width: 50,
          },
        ],
        rows:
          history &&
          history.data.map((item) => ({
            row: num++,
            type: item.type,
            date: moment(item.date).format('Do MMMM YYYY,  h:mm a'),
            amount: item.amount
              ? `${item.currency} ${formatNumber(item.amount)}`
              : '',
            balance: item.balance
              ? `${item.currency} ${formatNumber(item.balance)}`
              : '',
          })),
      })
    }
  }, [history])

  useEffect(() => {
    if (details && details.account && details.account.balance) {
      setBal(
        formatNumber(details && details.account && details.account.balance),
      )
    }
  }, [details])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-md-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Accounts</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmtrove</a>
                      </li>
                      <li className="breadcrumb-item active">Accounts</li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <h1 className="mb-3">
                            {hide === true ? (
                              <>{bal ? <b>{`₦${bal}`}</b> : <b>₦0.00</b>}</>
                            ) : (
                              <b>₦*.**</b>
                            )}
                          </h1>
                          <p className="text-truncate font-size-8 mb-2">
                            Account Balance
                          </p>
                        </div>
                        <div className="col-md-6">
                          <div className="text-primary float-end">
                            {loadDetails ? (
                              '...'
                            ) : (
                              <>
                                {user && user.monoId ? (
                                  <div
                                    className={`badge badge-soft-success font-size-13 text-success me-3`}
                                  >
                                    ACCOUNT CONNECTED{' '}
                                  </div>
                                ) : (
                                  <button
                                    className="btn btn-sm btn-success mt-3 me-3"
                                    onClick={() => monoConnect.open()}
                                  >
                                    Link your financial account
                                  </button>
                                )}
                              </>
                            )}

                            <button className="btn btn-sm btn-success mt-3 me-3">
                              <i className="fa fa-sync"></i>
                            </button>

                            <button
                              className="btn btn-sm btn-success mt-3 me-3"
                              onClick={toogleAcc}
                            >
                              {hide === true ? (
                                <i className="fa fa-eye-slash"></i>
                              ) : (
                                <i className="fa fa-eye"></i>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Your User Information</h4>
                      {detailsError ? (
                        <MessageBox variant="danger">{detailsError}</MessageBox>
                      ) : null}
                      {loadingDetails ? (
                        <div
                          className="tailspin"
                          style={{ height: 'calc(50vh - 100px)' }}
                        >
                          <TailSpin
                            stroke="#98ff98"
                            strokeWidth="3"
                            color="#06bcee"
                          />
                        </div>
                      ) : (
                        <div className="d-flex mt-3">
                          {details &&
                          details.meta &&
                          details.meta.data_status === 'AVAILABLE' ? (
                            <div className="row">
                              <div className="col-sm-12">
                                <p className="mb-0">Name</p>
                                <h5 className="text-muted font-size-17 mb-3">
                                  {details &&
                                    details.account &&
                                    details.account.name}
                                </h5>
                              </div>
                              <div className="col-sm-12">
                                <p className="mb-0">Account Number</p>
                                <h5 className="text-muted font-size-17 mb-3">
                                  {details &&
                                    details.account &&
                                    details.account.accountNumber}
                                </h5>
                              </div>
                              <div className="col-sm-12">
                                <p className="mb-0">Bank Name</p>
                                <h5 className="text-muted font-size-17 mb-3">
                                  {details &&
                                    details.account &&
                                    details.account.institution.name}
                                </h5>
                              </div>
                              <div className="col-sm-12">
                                <p className="mb-0">Balance</p>
                                <h5 className="text-muted font-size-17 mb-3">
                                  {details &&
                                    details.account &&
                                    details.account.currency}{' '}
                                  {details &&
                                    details.account &&
                                    details.account.balance &&
                                    formatNumber(
                                      details &&
                                        details.account &&
                                        details.account.balance,
                                    )}
                                </h5>
                              </div>
                              <div className="col-sm-12">
                                <p className="mb-0">Account Type</p>
                                <h5 className="text-muted font-size-17 mb-3">
                                  {details &&
                                    details.account &&
                                    details.account.type}
                                </h5>
                              </div>

                              <div className="col-sm-12">
                                <p className="mb-0">BVN</p>
                                <h5 className="text-muted font-size-17 mb-3">
                                  {details &&
                                  details.account &&
                                  details.account.bvn
                                    ? details.account.bvn
                                    : ''}
                                </h5>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Your Income</h4>
                      {incomeError ? (
                        <MessageBox variant="danger">{incomeError}</MessageBox>
                      ) : null}
                      {loadingIncome ? (
                        <div
                          className="tailspin"
                          style={{ height: 'calc(40vh - 60px)' }}
                        >
                          <TailSpin
                            stroke="#98ff98"
                            strokeWidth="3"
                            color="#06bcee"
                          />
                        </div>
                      ) : (
                        <div className="d-flex mt-3">
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="mb-0">Average Income</p>
                              <h5 className="text-muted font-size-17 mb-3">
                                {incomes &&
                                  formatNumber(
                                    incomes && incomes.average_income,
                                  )}
                              </h5>
                            </div>
                            <div className="col-sm-12">
                              <p className="mb-0">Monthly Income</p>
                              <h5 className="text-muted font-size-17 mb-3">
                                {incomes &&
                                  formatNumber(
                                    incomes && incomes.monthly_income,
                                  )}
                              </h5>
                            </div>
                            <div className="col-sm-12">
                              <p className="mb-0">Yearly Income</p>
                              <h5 className="text-muted font-size-17 mb-3">
                                {incomes &&
                                  formatNumber(
                                    incomes && incomes.yearly_income,
                                  )}
                              </h5>
                            </div>
                            <div className="col-sm-12">
                              <p className="mb-0">Source of Income</p>
                              <h5 className="text-muted font-size-17">
                                {incomes && incomes.income_sources}
                              </h5>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-2">Transactions History</h4>
                      {historyError ? (
                        <MessageBox variant="danger">{historyError}</MessageBox>
                      ) : null}
                      {loadingHistory ? (
                        <div
                          className="tailspin"
                          style={{ height: 'calc(50vh - 100px)' }}
                        >
                          <TailSpin
                            stroke="#98ff98"
                            strokeWidth="3"
                            color="#06bcee"
                          />
                        </div>
                      ) : (
                        <div className="table-responsive-xl">
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 20, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
