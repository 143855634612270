export const ADD_CLUSTER_REQUEST = 'ADD_CLUSTER_REQUEST'
export const ADD_CLUSTER_SUCCESS = 'ADD_CLUSTER_SUCCESS'
export const ADD_CLUSTER_FAIL = 'ADD_CLUSTER_FAIL'
export const ADD_CLUSTER_RESET = 'ADD_CLUSTER_RESET'

export const CLUSTER_LIST_REQUEST = 'CLUSTER_LIST_REQUEST'
export const CLUSTER_LIST_SUCCESS = 'CLUSTER_LIST_SUCCESS'
export const CLUSTER_LIST_FAIL = 'CLUSTER_LIST_FAIL'

export const UPDATE_CLUSTER_REQUEST = 'UPDATE_CLUSTER_REQUEST'
export const UPDATE_CLUSTER_SUCCESS = 'UPDATE_CLUSTER_SUCCESS'
export const UPDATE_CLUSTER_FAIL = 'UPDATE_CLUSTER_FAIL'
export const UPDATE_CLUSTER_RESET = 'UPDATE_CLUSTER_RESET'

export const CLUSTER_DELETE_REQUEST = 'CLUSTER_DELETE_REQUEST'
export const CLUSTER_DELETE_SUCCESS = 'CLUSTER_DELETE_SUCCESS'
export const CLUSTER_DELETE_FAIL = 'CLUSTER_DELETE_FAIL'
export const CLUSTER_DELETE_RESET = 'CLUSTER_DELETE_RESET'

export const ADD_CLUSTER_CATEGORY_REQUEST = 'ADD_CLUSTER_CATEGORY_REQUEST'
export const ADD_CLUSTER_CATEGORY_SUCCESS = 'ADD_CLUSTER_CATEGORY_SUCCESS'
export const ADD_CLUSTER_CATEGORY_FAIL = 'ADD_CLUSTER_CATEGORY_FAIL'
export const ADD_CLUSTER_CATEGORY_RESET = 'ADD_CLUSTER_CATEGORY_RESET'

export const UPDATE_CLUSTER_CATEGORY_REQUEST = 'UPDATE_CLUSTER_CATEGORY_REQUEST'
export const UPDATE_CLUSTER_CATEGORY_SUCCESS = 'UPDATE_CLUSTER_CATEGORY_SUCCESS'
export const UPDATE_CLUSTER_CATEGORY_FAIL = 'UPDATE_CLUSTER_CATEGORY_FAIL'
export const UPDATE_CLUSTER_CATEGORY_RESET = 'UPDATE_CLUSTER_CATEGORY_RESET'

export const CLUSTER_CATEGORY_DELETE_REQUEST = 'CLUSTER_CATEGORY_DELETE_REQUEST'
export const CLUSTER_CATEGORY_DELETE_SUCCESS = 'CLUSTER_CATEGORY_DELETE_SUCCESS'
export const CLUSTER_CATEGORY_DELETE_FAIL = 'CLUSTER_CATEGORY_DELETE_FAIL'
export const CLUSTER_CATEGORY_DELETE_RESET = 'CLUSTER_CATEGORY_DELETE_RESET'

export const CLUSTER_CATEGORY_LIST_REQUEST = 'CLUSTER_CATEGORY_LIST_REQUEST'
export const CLUSTER_CATEGORY_LIST_SUCCESS = 'CLUSTER_CATEGORY_LIST_SUCCESS'
export const CLUSTER_CATEGORY_LIST_FAIL = 'CLUSTER_CATEGORY_LIST_FAIL'