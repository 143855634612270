import {
  ACCOUNT_INCOME_FAIL,
  ACCOUNT_INCOME_REQUEST,
  ACCOUNT_INCOME_SUCCESS,
  ACCOUNT_INFORMATION_FAIL,
  ACCOUNT_INFORMATION_REQUEST,
  ACCOUNT_INFORMATION_SUCCESS,
  ACCOUNT_TRANSATIONS_FAIL,
  ACCOUNT_TRANSATIONS_REQUEST,
  ACCOUNT_TRANSATIONS_SUCCESS,
  EXCHANGE_TOKEN_FAIL,
  EXCHANGE_TOKEN_REQUEST,
  EXCHANGE_TOKEN_RESET,
  EXCHANGE_TOKEN_SUCCESS,
} from '../../../constants/farmerConstants/farmtrove/accountConstants'

export const exchangeTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case EXCHANGE_TOKEN_REQUEST:
      return { loading: true }
    case EXCHANGE_TOKEN_SUCCESS:
      return { loading: false, success: true }
    case EXCHANGE_TOKEN_FAIL:
      return { loading: false, error: action.payload }
    case EXCHANGE_TOKEN_RESET:
      return { }
    default:
      return state
  }
}

export const accountInformationReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_INFORMATION_REQUEST:
      return { loading: true }
    case ACCOUNT_INFORMATION_SUCCESS:
      return { loading: false, details: action.payload }
    case ACCOUNT_INFORMATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const accountTransactionsReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_TRANSATIONS_REQUEST:
      return { loading: true }
    case ACCOUNT_TRANSATIONS_SUCCESS:
      return { loading: false, history: action.payload }
    case ACCOUNT_TRANSATIONS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const accountIncomeReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_INCOME_REQUEST:
      return { loading: true }
    case ACCOUNT_INCOME_SUCCESS:
      return { loading: false, incomes: action.payload }
    case ACCOUNT_INCOME_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
