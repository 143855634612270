import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { LoadingBox, MessageBox } from '../../index.js'
import 'chart.js/auto'
import { analysis } from '../../../actions/adminActions/dashboardActions/analysis.js'
import { countTodayTransactions, formatNumber, getTodayEscrowTransfers, getTotalVolumeOfTodayTransactions, recentOrder } from '../../functions.js'

const Admin = () => {
  const dashboardAnalysis = useSelector((state) => state.dashboardAnalysis)
  const { loading, error, result } = dashboardAnalysis

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(analysis())
  }, [])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">Dashboard</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="javascript: void(0);">Farmsured</a>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div>
          <div className="row">
            {/* Farmsured Overview Cards */}
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Farmsured</h4>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Organizations
                          </p>
                          <h4 className="mb-0">
                            {result && result.manufacturer
                              ? result.manufacturer.length
                              : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-store font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/manufacturers">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Tag Request
                          </p>
                          <h4 className="mb-0">
                            {result && result.order
                              ? recentOrder(result.order).length
                              : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-tag font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/tag-request">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Products
                          </p>
                          <h4 className="mb-0">
                            {result && result.product
                              ? result.product.length
                              : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-box-check font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/products">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Enterprise Package
                          </p>
                          <h4 className="mb-0">
                            {result && result.enterprise
                              ? result.enterprise
                              : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-box-check font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/all-enterprise">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* Farmtrove Overview Cards */}
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Farmtrove</h4>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Today's Transactions
                          </p>
                          <h4 className="mb-0">
                            {result && result.transactions
                              ? countTodayTransactions(result.transactions) : 0
                            }
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-credit-card font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/farmtrove/overview">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Today's Transfer Volume
                          </p>
                          <h4 className="mb-0">
                            ₦ {result && result.transactions ? formatNumber(getTotalVolumeOfTodayTransactions(result.transactions)) : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-exchange-alt font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/farmtrove/overview">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Today's Escrow Transfer
                          </p>
                          <h4 className="mb-0">
                            {result && result.escrow
                              ? getTodayEscrowTransfers(result.escrow)
                              : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-money-check font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/farmtrove/overview">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Loan Application
                          </p>
                          <h4 className="mb-0">
                            {result && result.loans
                              ? result.loans
                              : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-money-bill-wave-alt font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/farmtrove/loan">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            {/* Farmcluster Overview Cards */}
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Farmcluster</h4>
              </div>
            </div>

            <div className="col-xl-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Clusters
                          </p>
                          <h4 className="mb-0">
                            {result && result.clusters
                              ? result.clusters
                              : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-users font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/farmcluster/all-clusters">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Cluster Category
                          </p>
                          <h4 className="mb-0">
                            {result && result.categories
                              ? result.categories
                              : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-chart-network font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/cluster-categories">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Farmers
                          </p>
                          <h4 className="mb-0">
                            {result && result.farmer ? result.farmer.length : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-id-badge font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/farmers">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Farm Cycle
                          </p>
                          <h4 className="mb-0">
                            {result && result.farmcycles ? result.farmcycles.length : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-tractor font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/all-farm-cycle">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Admin
