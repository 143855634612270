import axios from 'axios'
import {
   SERVICE_PROVIDERS_FAIL,
   SERVICE_PROVIDERS_REQUEST,
   SERVICE_PROVIDERS_SUCCESS,
} from '../../../constants/manufacturerConstants/serviceProviderConstants'

export const allServiceProviders = () => async (dispatch, getState) => {
  dispatch({ type:  SERVICE_PROVIDERS_REQUEST })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/manufacturer/service-providers', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type:  SERVICE_PROVIDERS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type:  SERVICE_PROVIDERS_FAIL,
      payload: message,
    })
  }
}
