import axios from 'axios'
import {
  BENEFITS_CREATE_FAIL,
  BENEFITS_CREATE_REQUEST,
  BENEFITS_CREATE_SUCCESS,
} from '../../../constants/adminConstants/benefitContants'

export const createBenefit = (
  name,
  category,
  description,
  tags,
  TandC,
) => async (dispatch, getState) => {
  dispatch({
    type: BENEFITS_CREATE_REQUEST,
    payload: { name, category, description, tags, TandC },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/add-new-benefit',
      { name, category, description, tags, TandC },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: BENEFITS_CREATE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: BENEFITS_CREATE_FAIL,
      payload: message,
    })
  }
}
