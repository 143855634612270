import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { allServiceCategory } from '../../../actions/adminActions/serviceActions/allServiceCategory'
import { newServiceCoverage } from '../../../actions/adminActions/serviceActions/addServiceCoverage'
import {
  ADD_SERVICE_COVERAGE_RESET,
  UPDATE_SERVICE_COVERAGE_RESET,
  SERVICE_COVERAGE_DELETE_RESET,
} from '../../../constants/adminConstants/serviceConstants'
import { deleteServiceCoverage } from '../../../actions/adminActions/serviceActions/deleteServiceCoverage'
import { editServiceCategory } from '../../../actions/adminActions/serviceActions/editServiceCategory'
import SweetAlert from 'react-bootstrap-sweetalert'

function ServiceCategoryPage() {
  const [alert, setAlert] = useState(null)
  const [open, setOpen] = useState(false)
  const [itemId, setItemId] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const serviceCategory = useSelector((state) => state.serviceCategory)
  const { loading, error, categorylist } = serviceCategory

  const addServiceCategory = useSelector((state) => state.addServiceCategory)
  const {
    loading: addCatLoading,
    error: addCatError,
    category,
  } = addServiceCategory

  const updateServiceCategory = useSelector(
    (state) => state.updateServiceCategory,
  )
  const {
    loading: updateCatLoading,
    error: updateCatError,
    success: updateSuccess,
  } = updateServiceCategory

  const deteleServiceCategory = useSelector(
    (state) => state.deteleServiceCategory,
  )
  const {
    loading: loadingDelete,
    error: errorDelete,
    success,
  } = deteleServiceCategory

  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(newServiceCoverage(name, description))
  }

  const deleteHandler = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deleteServiceCoverage(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  const handleClickOpen = (item) => {
    setOpen(true)
    setItemId(item._id)
    setName(item.name)
    setDescription(item.description)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleEditSubmit = (e) => {
    e.preventDefault()
    dispatch(editServiceCategory(itemId, name, description))
    setOpen(false)
  }

  useEffect(() => {
    dispatch(allServiceCategory())
    if (success) {
      setAlert(null)
      setTimeout(() => {
        dispatch({ type: SERVICE_COVERAGE_DELETE_RESET })
      }, 1500)
    }

    if (category) {
      setTimeout(() => {
        dispatch({ type: ADD_SERVICE_COVERAGE_RESET })
      }, 1500)
    }

    if (updateSuccess) {
      setTimeout(() => {
        dispatch({ type: UPDATE_SERVICE_COVERAGE_RESET })
      }, 1500)
    }
  }, [dispatch, category, success, updateSuccess])

  return (
    <div id="layout-wrapper">
      <Header />
      <RightSidebar />
      <LeftSidebar />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Service Provider Category</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Service Provider</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Service Provider category
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-5">
                        {category && (
                          <MessageBox variant="success">
                            {category.message}
                          </MessageBox>
                        )}
                        {addCatError && (
                          <MessageBox variant="danger">
                            {addCatError}
                          </MessageBox>
                        )}
                        <div className="">
                          <small className="font-size-13">
                            Service provider category for the Farmtrove Benefits
                            can be managed here.
                          </small>
                        </div>
                        <div className="mt-2 mb-2">
                          <h4 className="card-title">
                            Add New Service Category
                          </h4>
                        </div>

                        {addCatLoading ? (
                          <LoadingBox />
                        ) : (
                          <form onSubmit={handleSubmit}>
                            <div className="col-md-12 mb-3">
                              <label
                                htmlFor="example-text-input"
                                className="col-md-12 col-form-label"
                              >
                                Name
                              </label>
                              <input
                                className="form-control"
                                required
                                placeholder="Service category name"
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label" forHtml="name">
                                Description
                              </label>
                              <textarea
                                onChange={(e) => setDescription(e.target.value)}
                                className="form-control"
                                placeholder="Description of service provider category"
                                required
                              />
                            </div>

                            <div className="mt-2">
                              <button
                                className="btn btn-sm btn-success"
                                type="submit"
                              >
                                Add new category
                              </button>
                            </div>
                          </form>
                        )}
                      </div>
                      <div className="col-md-7">
                        {updateCatLoading && <LoadingBox />}
                        {updateCatError && (
                          <MessageBox variant="danger">
                            {updateCatError}
                          </MessageBox>
                        )}
                        {error && (
                          <MessageBox variant="danger">{error}</MessageBox>
                        )}
                        {loadingDelete && <LoadingBox />}
                        {updateSuccess && (
                          <MessageBox variant="success">
                            Category updated successfully
                          </MessageBox>
                        )}
                        {success && (
                          <MessageBox variant="success">
                            Category deleted successfully
                          </MessageBox>
                        )}
                        {errorDelete && (
                          <MessageBox variant="danger">
                            {errorDelete}
                          </MessageBox>
                        )}
                        <div className="table-responsive mt-4">
                          {loading ? (
                            <LoadingBox />
                          ) : (
                            <table
                              className="table table-centered datatable dt-responsive nowrap"
                              style={{
                                borderCollapse: 'collapse',
                                borderSpacing: 0,
                                width: '100%',
                              }}
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th>Category Name</th>
                                  <th>Description</th>
                                  <th>Date Created</th>
                                  <th style={{ width: '140px' }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {categorylist.length === 0 ? (
                                  <div className="d-sm-flex align-items-center justify-content-between">
                                    <p className="card-title-desc">
                                      There are no service provider category
                                      data
                                    </p>
                                  </div>
                                ) : (
                                  categorylist.map((item) => (
                                    <tr key={item._id}>
                                      <td>{item.name}</td>
                                      <td>{item.description}</td>
                                      <td>
                                        {moment(item.createdAt).format(
                                          'Do MMMM YYYY,  h:mm a',
                                        )}
                                      </td>
                                      <td id="tooltip-container1">
                                        <button
                                          className="btn btn-sm btn-primary"
                                          style={{ marginRight: '15px' }}
                                          data-bs-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit"
                                          onClick={() => handleClickOpen(item)}
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="btn btn-sm text-danger"
                                          data-bs-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete"
                                          onClick={() => deleteHandler(item)}
                                        >
                                          <i className="mdi mdi-trash-can font-size-18"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          )}
                        </div>

                        {/* Edit Modal */}
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="form-dialog-title"
                          fullWidth
                          maxWidth={'md'}
                        >
                          <>
                            <div className="modal-header">
                              <h5 className="modal-title">
                                Edit service provider coverage
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                              ></button>
                            </div>
                            <DialogContent className="">
                              <div className="">
                                <form onSubmit={handleEditSubmit}>
                                  <div className="row mb-3">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-md-12 col-form-label"
                                    >
                                      Service Provider
                                    </label>
                                    <div className="col-md-12">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Service Provider"
                                        id="example-text-input"
                                        value={name}
                                        onChange={(e) =>
                                          setName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <label
                                      htmlFor="example-text-input"
                                      className="col-md-12 col-form-label"
                                    >
                                      Description
                                    </label>
                                    <div className="col-md-12">
                                      <textarea
                                        name="description"
                                        className="form-control"
                                        value={description}
                                        onChange={(e) =>
                                          setDescription(e.target.value)
                                        }
                                      ></textarea>
                                    </div>
                                  </div>

                                  <DialogActions>
                                    <button
                                      className="btn btn-success btn-sm"
                                      type="submit"
                                    >
                                      Update
                                    </button>
                                  </DialogActions>
                                </form>
                              </div>
                            </DialogContent>
                          </>
                        </Dialog>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {alert}
      </div>
      <Footer />
    </div>
  )
}

export default ServiceCategoryPage
