import axios from 'axios'
import {
  PACKAGE_CATEGORY_DELETE_FAIL,
  PACKAGE_CATEGORY_DELETE_REQUEST,
  PACKAGE_CATEGORY_DELETE_SUCCESS,
} from '../../../constants/adminConstants/packageConstants'

export const deleteCategory = (itemId) => async (dispatch, getState) => {
  dispatch({
    type: PACKAGE_CATEGORY_DELETE_REQUEST,
    payload: {
      itemId,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/package-category/delete',
      { itemId },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: PACKAGE_CATEGORY_DELETE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: PACKAGE_CATEGORY_DELETE_FAIL, payload: message })
  }
}
