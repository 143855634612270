import axios from 'axios'
import {
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
} from '../../../constants/adminConstants/productConstants'

export const AddProduct = (
  productName,
  category,
  productDescription,
  manufacturer,
) => async (dispatch, getState) => {
  dispatch({
    type: ADD_PRODUCT_REQUEST,
    payload: {
      productName,
      category,
      productDescription,
      manufacturer,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/add-product',
      { productName, category, productDescription, manufacturer },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: ADD_PRODUCT_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: ADD_PRODUCT_FAIL, payload: message })
  }
}
