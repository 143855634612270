export const FUND_USER_REQUEST = 'FUND_USER_REQUEST'
export const FUND_USER_SUCCESS = 'FUND_USER_SUCCESS'
export const FUND_USER_FAIL = 'FUND_USER_FAIL'
export const FUND_USER_RESET = 'FUND_USER_RESET'

export const FUND_ALLOCATION_TRANSACTION_REQUEST = 'FUND_ALLOCATION_TRANSACTION_REQUEST'
export const FUND_ALLOCATION_TRANSACTION_SUCCESS = 'FUND_ALLOCATION_TRANSACTION_SUCCESS'
export const FUND_ALLOCATION_TRANSACTION_FAIL = 'FUND_ALLOCATION_TRANSACTION_FAIL'

export const FUND_MAIN_WALLET_REQUEST = 'FUND_MAIN_WALLET_REQUEST'
export const FUND_MAIN_WALLET_SUCCESS = 'FUND_MAIN_WALLET_SUCCESS'
export const FUND_MAIN_WALLET_FAIL = 'FUND_MAIN_WALLET_FAIL'
export const FUND_MAIN_WALLET_RESET = 'FUND_MAIN_WALLET_RESET'

export const MAIN_WALLET_BALANCE_REQUEST = 'MAIN_WALLET_BALANCE_REQUEST'
export const MAIN_WALLET_BALANCE_SUCCESS = 'MAIN_WALLET_BALANCE_SUCCESS'
export const MAIN_WALLET_BALANCE_FAIL = 'MAIN_WALLET_BALANCE_FAIL'