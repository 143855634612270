import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import sendOtp from '../../actions/authActions/sendOtp'
import verifyOtp from '../../actions/authActions/verifyOtp'
import { MessageBox, LoadingBox } from '../../components'
import { SEND_OTP_RESET } from '../../constants/authConstants/otpConstants'

function VerifyOTPPage(props) {
  const location = useLocation()
  const [otp, setOtp] = useState('')
  const [phone, setPhone] = useState('')

  const date = new Date().getFullYear()

  const dispatch = useDispatch()

  const verifyHandler = (e) => {
    e.preventDefault()

    dispatch(verifyOtp(otp, phone))
  }

  const retryHandler = (e) => {
    e.preventDefault()
    dispatch(sendOtp(phone))
  }

  const userVerify = useSelector((state) => state.userVerify)
  const { loading, error, userInfo } = userVerify

  const userOtpRequest = useSelector((state) => state.userOtpRequest)
  const { loading: optLoading, error: otpError, result } = userOtpRequest

  useEffect(() => {
    if (location.state.phone) {
      setPhone(location.state.phone)
    }

    if (result) {
      setTimeout(() => {
        dispatch({ type: SEND_OTP_RESET })
      }, 2500)
    }
  }, [location, result])

  useEffect(() => {
    if (userInfo) {
      setTimeout(() => {
        props.history.push('/dashboard')
        window.location.reload()
      }, 2500)
    }
  }, [userInfo, props.history])

  return (
    <div className="auth-body-bg">
      {optLoading ? <LoadingBox /> : null}
      {loading ? (
        <LoadingBox />
      ) : (
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-lg-4">
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <div className="row justify-content-center">
                    {error && <MessageBox variant="danger">{error}</MessageBox>}
                    {otpError && (
                      <MessageBox variant="danger">{otpError}</MessageBox>
                    )}
                    {userInfo && (
                      <MessageBox variant="success">
                        You are successfully logged in
                      </MessageBox>
                    )}
                    {result ? (
                      <MessageBox variant="success">
                        {result.message}
                      </MessageBox>
                    ) : null}
                    <div className="col-lg-9">
                      <div>
                        <div className="text-center">
                          <div>
                            <a href="/" className="">
                              <img
                                src="/assets/images/farmsured!.png"
                                alt=""
                                height="50"
                                className="auth-logo logo-dark mx-auto"
                              />
                              <img
                                src="/assets/images/farmsured!.png"
                                alt=""
                                height="50"
                                className="auth-logo logo-light mx-auto"
                              />
                            </a>
                          </div>

                          <h4 className="font-size-18 mt-4">
                            We sent your code !
                          </h4>
                          <p className="text-muted">
                            Enter the confirmation code below
                          </p>
                        </div>

                        <div className="p-2 mt-3">
                          <form className="" onSubmit={verifyHandler}>
                            <div className="mb-3 auth-form-group-custom mb-4">
                              <i className="ri-account-pin-box-line auti-custom-input-icon"></i>
                              <label htmlFor="email">One Time Password</label>
                              <input
                                type="number"
                                className="form-control"
                                id="otp"
                                placeholder="Enter OTP Code"
                                required
                                onChange={(e) => setOtp(e.target.value)}
                              />
                            </div>

                            <div className="mt-4 text-center">
                              <button
                                className="btn btn-success me-3 w-md waves-effect waves-light"
                                type="submit"
                              >
                                Verify
                              </button>
                            </div>

                            <div class="content d-flex justify-content-center align-items-center mt-4">
                              <span>Didn't get the code?</span>
                              <button
                                onClick={retryHandler}
                                className=" btn text-decoration-none"
                              >
                                Resend
                              </button>
                            </div>

                            <div className="mt-3 text-center">
                              <p className="text-center text-line">
                                or sign in with your email
                              </p>
                              <a
                                href="/"
                                className="p-0 w-md waves-effect text-decoration-none waves-light"
                              >
                                Login with Email
                              </a>
                            </div>
                          </form>
                        </div>

                        <div className="mt-3 text-center">
                          <p>© {date} Farmsured. Developed by AgDyna</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default VerifyOTPPage
