import {
  ADMIN_READ_MESSAGE_FAIL,
  ADMIN_READ_MESSAGE_REQUEST,
  ADMIN_READ_MESSAGE_SUCCESS,
  ALL_INBOX_MESSAGE_FAIL,
  ALL_INBOX_MESSAGE_REQUEST,
  ALL_INBOX_MESSAGE_SUCCESS,
  ALL_PUSH_NOTIFICATION_FAIL,
  ALL_PUSH_NOTIFICATION_REQUEST,
  ALL_PUSH_NOTIFICATION_SUCCESS,
  SEND_MESSAGE_FAIL,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_RESET,
  SEND_MESSAGE_SUCCESS,
  SEND_PUSH_NOTIFICATION_FAIL,
  SEND_PUSH_NOTIFICATION_REQUEST,
  SEND_PUSH_NOTIFICATION_RESET,
  SEND_PUSH_NOTIFICATION_SUCCESS,
} from '../../../constants/adminConstants/notificationConstants'

export const messageListReducer = (
  state = { loading: true, sentMessages: [] },
  action,
) => {
  switch (action.type) {
    case ALL_INBOX_MESSAGE_REQUEST:
      return { loading: true }
    case ALL_INBOX_MESSAGE_SUCCESS:
      return {
        loading: false,
        sentMessages: action.payload,
      }
    case ALL_INBOX_MESSAGE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const sendMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_MESSAGE_REQUEST:
      return { loading: true }
    case SEND_MESSAGE_SUCCESS:
      return {
        loading: false,
        notification: action.payload,
      }
    case SEND_MESSAGE_FAIL:
      return { loading: false, error: action.payload }
    case SEND_MESSAGE_RESET:
      return {}
    default:
      return state
  }
}

export const adminReadMessageReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case ADMIN_READ_MESSAGE_REQUEST:
      return { loading: true }
    case ADMIN_READ_MESSAGE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      }
    case ADMIN_READ_MESSAGE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const pushNotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_PUSH_NOTIFICATION_REQUEST:
      return { loading: true }
    case SEND_PUSH_NOTIFICATION_SUCCESS:
      return {
        loading: false,
        sent: action.payload,
      }
    case SEND_PUSH_NOTIFICATION_FAIL:
      return { loading: false, error: action.payload }
    case SEND_PUSH_NOTIFICATION_RESET:
      return {}
    default:
      return state
  }
}

export const allPushNotificationsReducer = (state = { loading: true, notifications: [] }, action) => {
  switch (action.type) {
    case ALL_PUSH_NOTIFICATION_REQUEST:
      return { loading: true }
    case ALL_PUSH_NOTIFICATION_SUCCESS:
      return {
        loading: false,
        notifications: action.payload,
      }
    case ALL_PUSH_NOTIFICATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
