import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { allClusterCategory } from '../../../actions/adminActions/farmclusterActions/allClusterCategory'
import { addClusterCategory } from '../../../actions/adminActions/farmclusterActions/addClusterCategory'
import { editClusterCategory } from '../../../actions/adminActions/farmclusterActions/editClusterCategory'
import { deleteCategory } from '../../../actions/adminActions/farmclusterActions/deleteCategory'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import { ADD_CLUSTER_CATEGORY_RESET, CLUSTER_CATEGORY_DELETE_RESET, UPDATE_CLUSTER_CATEGORY_RESET } from '../../../constants/adminConstants/farmcluster/clusterContants'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import SweetAlert from 'react-bootstrap-sweetalert'

export default function ClusterCategoryPage() {
    const [open, setOpen] = useState(false)
    const [itemId, setItemId] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [alert, setAlert] = useState(null)

    const dispatch = useDispatch()

    const newClusterCategory = useSelector((state) => state.newClusterCategory)
    const {
        loading: addCatLoading,
        error: addCatError,
        category,
    } = newClusterCategory

    const clusterCategoryList = useSelector((state) => state.clusterCategoryList)
    const { loading, categorylist, error } = clusterCategoryList

    const updateClusterCategory = useSelector((state) => state.updateClusterCategory)
    const {
        loading: updateCatLoading,
        error: updateCatError,
        success: updateSuccess,
    } = updateClusterCategory

    const deleteClusterCategory = useSelector(
        (state) => state.deleteClusterCategory,
    )
    const {
        loading: loadingDelete,
        error: errorDelete,
        success,
    } = deleteClusterCategory

    useEffect(() => {
        dispatch(allClusterCategory())

        if (category) {
            setTimeout(() => {
                dispatch({ type: ADD_CLUSTER_CATEGORY_RESET })
            }, 5000)
        }

        if (updateSuccess) {
            setOpen(false)
            setTimeout(() => {
                dispatch({ type: UPDATE_CLUSTER_CATEGORY_RESET })
            }, 5000)
        }


        if (success) {
            setTimeout(() => {
                dispatch({ type: CLUSTER_CATEGORY_DELETE_RESET })
            }, 5000)
        }
    }, [category, updateSuccess, success])

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(addClusterCategory(name, description))
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleClickOpen = (item) => {
        setOpen(true)
        setItemId(item._id)
        setName(item.name)
        setDescription(item.description)
    }

    const handleEditSubmit = (e) => {
        e.preventDefault()

        dispatch(editClusterCategory(itemId, name, description))
    }


    const deleteHandler = (item) => {
        setAlert(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={() => (setAlert(null), dispatch(deleteCategory(item._id)))}
                onCancel={() => setAlert(null)}
                focusCancelBtn
            >
                You can not undo this!!!
            </SweetAlert >,
        )
    }



    return (
        <div id="layout-wrapper">
            <Header />
            <RightSidebar />
            <LeftSidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Cluster Category</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Farmclusters</a>
                                            </li>
                                            <li className="breadcrumb-item active">
                                                Clusters Category
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end page title --> */}

                        <div className="row">
                            <div className="col-md-4">
                                {
                                    addCatLoading
                                        ?
                                        <LoadingBox />
                                        :
                                        <div className="card">
                                            <div className="card-body">
                                                {addCatError ? <MessageBox variant='danger'>{addCatError}</MessageBox> : null}
                                                {category ? <MessageBox variant='success'>{category.message}</MessageBox> : null}
                                                <div class="mt-2 mb-2">
                                                    <h4 class="card-title">Add New Category</h4>
                                                    <div class="mb-3">
                                                        <small class="font-size-13">Categories for the Farmluster system can be managed here.
                                                        </small>
                                                    </div>
                                                </div>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Name</label>
                                                        <input
                                                            id="name"
                                                            name="name"
                                                            type="text"
                                                            className="form-control form-control"
                                                            required
                                                            placeholder='Cluster Name'
                                                            onChange={(e) => setName(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Description</label>
                                                        <textarea
                                                            id="description"
                                                            name="description"
                                                            type="text"
                                                            className="form-control form-control"
                                                            placeholder='Description'
                                                            required
                                                            onChange={(e) => setDescription(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="mt-2">
                                                        <button
                                                            className="btn btn-sm btn-success"
                                                            type="submit"
                                                            onClick={handleSubmit}
                                                        >
                                                            Add new category
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                }

                            </div>
                            <div className="col-md-8">
                                <div className="card">
                                    <div className="card-body">
                                        {updateCatLoading && <LoadingBox />}
                                        {updateSuccess && (
                                            <MessageBox variant="success">
                                                {updateSuccess.message}
                                            </MessageBox>
                                        )}
                                        {loadingDelete && <LoadingBox />}
                                        {success && (
                                            <MessageBox variant="success">
                                                Category deleted successfully
                                            </MessageBox>
                                        )}
                                        {errorDelete && (
                                            <MessageBox variant="danger">
                                                {errorDelete}
                                            </MessageBox>
                                        )}
                                        {loading ? (
                                            <LoadingBox />
                                        ) : (
                                            <table
                                                className="table table-centered datatable dt-responsive nowrap"
                                                style={{
                                                    borderCollapse: 'collapse',
                                                    borderSpacing: 0,
                                                    width: '100%',
                                                }}
                                            >
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>Category Name</th>
                                                        <th>Description</th>
                                                        <th>Date Created</th>
                                                        <th style={{ width: '140px' }}>
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        error ? <MessageBox variant='danger'>{error}</MessageBox> :
                                                            categorylist && categorylist.length === 0 ? (
                                                                <div className="d-sm-flex align-items-center justify-content-between">
                                                                    <p className="card-title-desc">
                                                                        There is no cluster categories data
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                categorylist && categorylist.map((item) => (
                                                                    <tr key={item._id}>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.description}</td>
                                                                        <td>
                                                                            {moment(item.createdAt).format(
                                                                                'Do MMMM YYYY,  h:mm a',
                                                                            )}
                                                                        </td>
                                                                        <td id="tooltip-container1">
                                                                            <button
                                                                                className="btn btn-sm btn-primary text-edit"
                                                                                style={{ marginRight: '15px' }}
                                                                                data-bs-toggle="tooltip"
                                                                                data-placement="top"
                                                                                title="Edit"
                                                                                onClick={() => handleClickOpen(item)}
                                                                            >
                                                                                Edit
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-sm text-danger p-0 m-0"
                                                                                data-bs-toggle="tooltip"
                                                                                data-placement="top"
                                                                                title="Delete"
                                                                                onClick={() => deleteHandler(item)}
                                                                            >
                                                                                <i className="mdi mdi-trash-can font-size-18"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Edit Modal */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <>
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Edit cluster category
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    <DialogContent className="dialog_content">
                        {updateCatError && (
                            <MessageBox variant="danger">
                                {updateCatError}
                            </MessageBox>
                        )}
                        <div className="">
                            <form>
                                <div className="row mb-3">
                                    <label className="col-md-12 col-form-label">
                                        Name
                                    </label>
                                    <div className="col-md-12">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Artisanal kale"
                                            id="example-text-input"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-md-12 col-form-label">
                                        Description
                                    </label>
                                    <div className="col-md-12">
                                        <textarea
                                            className="form-control"
                                            type="text"
                                            placeholder="Artisanal kale"
                                            id="example-text-input"
                                            value={description}
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>

                                <DialogActions>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        type="close"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="btn btn-success btn-sm"
                                        type="submit"
                                        onClick={handleEditSubmit}
                                    >
                                        Update
                                    </button>
                                </DialogActions>
                            </form>
                        </div>
                    </DialogContent>
                </>
            </Dialog>
            {alert}
            <Footer />
        </div>
    )
}
