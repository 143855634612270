import axios from 'axios'
import {
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
} from '../../constants/authConstants/resetPasswordContants'

const passwordReset = (token, id, password) => async (dispatch) => {
  dispatch({
    type: RESET_PASSWORD_REQUEST,
    payload: { token, id, password },
  })
  try {
    const { data } = await axios.post('/api/reset-password', {
      token,
      id,
      password,
    })
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export default passwordReset
