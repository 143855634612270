import axios from 'axios'
import {
  ORGANISATION_SERVICE_CATEGORY_FAIL,
  ORGANISATION_SERVICE_CATEGORY_REQUEST,
  ORGANISATION_SERVICE_CATEGORY_SUCCESS,
} from '../../../constants/manufacturerConstants/serviceProviderConstants'

export const organisationServiceCategory = () => async (dispatch, getState) => {
  dispatch({ type: ORGANISATION_SERVICE_CATEGORY_REQUEST })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/manufacturer/organisation-service-category', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: ORGANISATION_SERVICE_CATEGORY_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: ORGANISATION_SERVICE_CATEGORY_FAIL,
      payload: message,
    })
  }
}
