import axios from 'axios'
import {
    SEND_OTP_CODE_FAIL,
    SEND_OTP_CODE_REQUEST,
    SEND_OTP_CODE_SUCCESS,
} from '../../../constants/adminConstants/farmtrove/verifyAdminConstants'

const sendOtpCode = (phone) => async (dispatch, getState) => {
    dispatch({ type: SEND_OTP_CODE_REQUEST, payload: { phone } })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post('/api/admin/farmtrove/send-otp', { phone },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                }
            })
        dispatch({ type: SEND_OTP_CODE_SUCCESS, payload: data })

    } catch (error) {
        dispatch({
            type: SEND_OTP_CODE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export default sendOtpCode
