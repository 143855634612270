import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import { packageDetails } from '../../../actions/adminActions/packagesActions/packageDetails'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { editProtocolActivity } from '../../../actions/adminActions/packagesActions/editProtocolActivity'
import { allPackageCategory } from '../../../actions/adminActions/packagesActions/allPackageCategory'
import { allEnterpriseList } from '../../../actions/adminActions/enterpriseActions/allEnterpriseList'
import { updatePackageDetail } from '../../../actions/adminActions/packagesActions/updatePackageDetail'
import {
  UPDATE_PACKAGE_DETAILS_RESET,
  UPDATE_PROTOCOL_ACTIVITY_RESET,
  UPDATE_PROTOCOL_BENEFIT_RESET,
} from '../../../constants/adminConstants/packageConstants'
import { AllBenefits } from '../../../actions/adminActions/benefitsActions/allBenefits'
import { editPackageBenefit } from '../../../actions/adminActions/packagesActions/editPackageBenefit'

export default function EditPackagePage(props) {
  const itemId = props.match.params.id

  const [open, setOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [packageName, setPackageName] = useState('')
  const [enterpriseId, setEnterpriseId] = useState('')
  const [activityId, setActivityId] = useState('')
  const [farmType, setFarmType] = useState('')
  const [minUnit, setMinUnit] = useState('')
  const [measuringUnit, setMeasuringUnit] = useState('')
  const [averageLoss, setAverageLoss] = useState('')
  const [averageLossMoU, setAverageLossMoU] = useState('')
  const [activity, setActivity] = useState('')
  const [tagColor, setTagColor] = useState('')
  const [farmActivity, setScanFarmActivity] = useState('')
  const [expectedTask, setScanExpectedTask] = useState('')
  const [timelineFrom, setScanTimelineFrom] = useState('')
  const [timelineTo, setScanTimelineTo] = useState('')
  const [point, setScanPoint] = useState('')
  const [benefitId, setBenefitId] = useState('')
  const [benefit, setBenefit] = useState('')
  const [benefitFrom, setBenefitFrom] = useState('')
  const [benefitTo, setBenefitTo] = useState('')
  const [minPointToQualify, setMinPointToQualify] = useState('')

  const viewPackageDetail = useSelector((state) => state.viewPackageDetail)
  const { loading, packageDetail, error } = viewPackageDetail

  const packageCategoryList = useSelector((state) => state.packageCategoryList)
  const { loading: loadingCategory, categoryList } = packageCategoryList

  const allEnterprise = useSelector((state) => state.allEnterprise)
  const { loading: loadingEnterprises, enterprises } = allEnterprise

  const packageDetailUpdate = useSelector((state) => state.packageDetailUpdate)
  const {
    loading: loadingDetailUpdate,
    error: detailUpdateError,
    updatedDetail,
  } = packageDetailUpdate

  const packageActivityUpdate = useSelector(
    (state) => state.packageActivityUpdate,
  )
  const {
    loading: loadingActivityUpdate,
    updatedActivity,
    error: activityUpdateError,
  } = packageActivityUpdate

  // Update Benefits State
  const packageBenefitUpdate = useSelector(
    (state) => state.packageBenefitUpdate,
  )
  const {
    loading: loadingBenefitUpdate,
    updatedBenefit,
    error: benefitUpdateError,
  } = packageBenefitUpdate

  // Benefit List State
  const benefitsList = useSelector((state) => state.benefitsList)
  const { loading: benefitsLoading, allbenefits } = benefitsList

  const dispatch = useDispatch()

  useEffect(() => {
    if (itemId) {
      dispatch(packageDetails(itemId))
    }
    dispatch(allPackageCategory())
    dispatch(allEnterpriseList())
    dispatch(AllBenefits())

    if (updatedDetail) {
      setTimeout(() => {
        dispatch({ type: UPDATE_PACKAGE_DETAILS_RESET })
      }, 2000)
    }

    if (updatedActivity) {
      setTimeout(() => {
        dispatch({ type: UPDATE_PROTOCOL_ACTIVITY_RESET })
      }, 2000)
    }

    if (updatedBenefit) {
      setTimeout(() => {
        dispatch({ type: UPDATE_PROTOCOL_BENEFIT_RESET })
      }, 2000)
    }
  }, [dispatch, itemId, updatedDetail, updatedActivity, updatedBenefit])

  useEffect(() => {
    if (packageDetail) {
      setEnterpriseId(packageDetail.enterprise)
      setPackageName(packageDetail.packageName)
      setAverageLoss(packageDetail.averageLoss && packageDetail.averageLoss.qty)
      setAverageLossMoU(packageDetail.averageLoss && packageDetail.averageLoss.measuringUnit)
      setFarmType(packageDetail.farmType && packageDetail.farmType._id)
      setMinUnit(
        packageDetail.packageMinUnit && packageDetail.packageMinUnit.minUnit,
      )
      setMeasuringUnit(
        packageDetail.packageMinUnit &&
        packageDetail.packageMinUnit.measuringUnit,
      )
    }
  }, [packageDetail])

  // Open activity edit model
  const handleClickOpen = (item) => {
    setOpen(true)
    setActivityId(item._id)
    setActivity(item.activity)
    setScanFarmActivity(item.farmActivity)
    setScanExpectedTask(item.expectedTask)
    setScanTimelineFrom(item.timeLineFrom)
    setScanTimelineTo(item.timeLineTo)
    setScanPoint(item.point)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const editItem = (e) => {
    e.preventDefault()

    if (activityId) {
      dispatch(
        editProtocolActivity(
          itemId,
          activityId,
          activity,
          tagColor,
          farmActivity,
          expectedTask,
          timelineFrom,
          timelineTo,
          point,
        ),
      )
    }
    setOpen(false)
  }

  const handleSaveDetail = (e) => {
    e.preventDefault()

    dispatch(
      updatePackageDetail(
        itemId,
        packageName,
        averageLoss,
        averageLossMoU,
        enterpriseId,
        farmType,
        minUnit,
        measuringUnit,
      ),
    )
  }

  // Open benefit edit model
  const handleOpenDialog = (item) => {
    setOpenDialog(true)
    setBenefitId(item._id)
    setBenefit(item.benefit._id)
    setBenefitFrom(item.benefitFrom)
    setBenefitTo(item.benefitTo)
    setMinPointToQualify(item.minPointToQualify)
  }
  // Close benefit edit model
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  // Handle Benefit Edit Submit
  const handleBenefitEditSumbit = (e) => {
    e.preventDefault()

    dispatch(
      editPackageBenefit(
        itemId,
        benefitId,
        benefit,
        benefitFrom,
        benefitTo,
        minPointToQualify,
      ),
    )
    setOpenDialog(false)
  }

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <RightSidebar />
        <LeftSidebar />
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                {/* <!-- start page title --> */}
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="mb-sm-0">Edit Protocol</h4>

                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            <a href="javascript: void(0);">Protocol</a>
                          </li>
                          <li className="breadcrumb-item active">
                            Protocol System
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end page title --> */}

                {loadingDetailUpdate && <LoadingBox />}
                {loadingActivityUpdate && <LoadingBox />}
                {loadingBenefitUpdate && <LoadingBox />}

                <div className="row">
                  <div className="col-md-12">
                    <div class="card">
                      <div class="card-body">
                        {updatedDetail && (
                          <MessageBox variant="success">
                            {updatedDetail.message}
                          </MessageBox>
                        )}
                        {detailUpdateError && (
                          <MessageBox variant="danger">
                            {detailUpdateError}
                          </MessageBox>
                        )}
                        {updatedActivity && (
                          <MessageBox variant="success">
                            {updatedActivity.message}
                          </MessageBox>
                        )}
                        {activityUpdateError && (
                          <MessageBox variant="danger">
                            {activityUpdateError}
                          </MessageBox>
                        )}

                        {updatedBenefit && (
                          <MessageBox variant="success">
                            {updatedBenefit.message}
                          </MessageBox>
                        )}
                        {benefitUpdateError && (
                          <MessageBox variant="danger">
                            {benefitUpdateError}
                          </MessageBox>
                        )}
                        <h4 class="card-title">Edit Package Details</h4>
                        <p class="card-title-desc">
                          Use the below sections to edit benefit details
                        </p>

                        {/* <!-- Nav tabs --> */}
                        <ul class="nav nav-pills nav-justified" role="tablist">
                          <li class="nav-item waves-effect waves-light">
                            <a
                              class="nav-link active"
                              data-bs-toggle="tab"
                              href="#home-1"
                              role="tab"
                              aria-selected="false"
                            >
                              <span class="d-block d-sm-none">
                                <i class="fas fa-home"></i>
                              </span>
                              <span class="d-none d-sm-block">
                                Package Detail
                              </span>
                            </a>
                          </li>
                          <li class="nav-item waves-effect waves-light">
                            <a
                              class="nav-link"
                              data-bs-toggle="tab"
                              href="#profile-1"
                              role="tab"
                              aria-selected="false"
                            >
                              <span class="d-block d-sm-none">
                                <i class="far fa-user"></i>
                              </span>
                              <span class="d-none d-sm-block">
                                Package Activities
                              </span>
                            </a>
                          </li>
                          <li class="nav-item waves-effect waves-light">
                            <a
                              class="nav-link"
                              data-bs-toggle="tab"
                              href="#messages-1"
                              role="tab"
                              aria-selected="false"
                            >
                              <span class="d-block d-sm-none">
                                <i class="far fa-envelope"></i>
                              </span>
                              <span class="d-none d-sm-block">
                                Package Benefit
                              </span>
                            </a>
                          </li>
                        </ul>

                        {/* <!-- Tab panes --> */}
                        <div class="tab-content p-3 text-muted">
                          {/* First Tab Panel */}
                          <div
                            class="tab-pane active"
                            id="home-1"
                            role="tabpanel"
                          >
                            <div>

                              <div className="row">
                                <div className="col-lg-8">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="productname"
                                    >
                                      Enterprise Package
                                    </label>
                                    <select
                                      className="form-select form-control select2"
                                      onChange={(e) =>
                                        setEnterpriseId(e.target.value)
                                      }
                                      value={enterpriseId}
                                    >
                                      <option>
                                        Select the enterprise package
                                      </option>
                                      {loadingEnterprises ? (
                                        <>Loading...</>
                                      ) : enterprises &&
                                        enterprises.length === 0 ? (
                                        <>
                                          <option>
                                            Please create an enterprise
                                          </option>
                                        </>
                                      ) : (
                                        enterprises &&
                                        enterprises.map((item) => (
                                          <option value={item._id}>
                                            {item.name}
                                          </option>
                                        ))
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="productname"
                                    >
                                      Protocol Name
                                    </label>
                                    <input
                                      id="packagename"
                                      name="packagename"
                                      type="text"
                                      className="form-control"
                                      placeholder="Protocol Name"
                                      onChange={(e) =>
                                        setPackageName(e.target.value)
                                      }
                                      value={packageName}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Protocol Categories
                                    </label>
                                    <select
                                      className="form-select form-control select2"
                                      onChange={(e) =>
                                        setFarmType(e.target.value)
                                      }
                                      value={farmType}
                                    >
                                      <option>
                                        Select the protocol categories type
                                      </option>
                                      {loadingCategory ? (
                                        <>Loading...</>
                                      ) : categoryList &&
                                        categoryList.length === 0 ? (
                                        <>
                                          <option>
                                            Please create a protocol category
                                          </option>
                                        </>
                                      ) : (
                                        categoryList &&
                                        categoryList.map((item) => (
                                          <option value={item._id}>
                                            {item.name}
                                          </option>
                                        ))
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="price"
                                    >
                                      Protocol Minimum Unit
                                    </label>
                                    <input
                                      id="unit"
                                      name="unit"
                                      type="number"
                                      className="form-control"
                                      placeholder="Protocol Minimum Unit"
                                      onChange={(e) =>
                                        setMinUnit(e.target.value)
                                      }
                                      value={minUnit}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="manufacturerbrand"
                                    >
                                      Measuring unit
                                    </label>
                                    <select
                                      className="form-select"
                                      onChange={(e) =>
                                        setMeasuringUnit(e.target.value)
                                      }
                                      value={measuringUnit}
                                    >
                                      <option>Select the measuring unit</option>
                                      <option value="Birds">Birds</option>
                                      <option value="Box(es)">Box(es)</option>
                                      <option value="Kg">kg</option>
                                      <option value="Units">Units</option>
                                      <option value="Animals">Animals</option>
                                      <option value="Bags">Bags</option>
                                      <option value="Tonnes">Tonnes</option>
                                      <option value="Litres">Litres</option>
                                      <option value="Items">Items</option>
                                      <option value="Hectares">Hectares</option>
                                      <option value="Acre">Acre</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Aver. Percentage Loss (%)
                                    </label>
                                    <input
                                      className="form-control"
                                      onChange={(e) =>
                                        setAverageLoss(e.target.value)
                                      }
                                      placeholder="Aver. Percentage Loss (%)"
                                      value={averageLoss}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-2">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="manufacturerbrand"
                                    >
                                      Measuring unit
                                    </label>
                                    <select
                                      className="form-select"
                                      onChange={(e) =>
                                        setAverageLossMoU(e.target.value)
                                      }
                                      value={averageLossMoU}
                                    >
                                      <option>Select the measuring unit</option>
                                      <option value="Birds">Birds</option>
                                      <option value="Box(es)">Box(es)</option>
                                      <option value="Kg">kg</option>
                                      <option value="Units">Units</option>
                                      <option value="Animals">Animals</option>
                                      <option value="Bags">Bags</option>
                                      <option value="Tonnes">Tonnes</option>
                                      <option value="Litres">Litres</option>
                                      <option value="Items">Items</option>
                                      <option value="Hectares">Hectares</option>
                                      <option value="Acre">Acre</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      forhtml="ProductName"
                                    >
                                      Organization
                                    </label>
                                    <input
                                      id="organization"
                                      name="organization"
                                      type="organization"
                                      className="form-control"
                                      placeholder="Protocol Minimum Unit"
                                      disabled
                                      value={
                                        packageDetail &&
                                        packageDetail.organisation &&
                                        packageDetail.organisation.name
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-4">
                                    <label
                                      className="form-label"
                                      htmlFor="manufacturerbrand"
                                    >
                                      Product/Service Name
                                    </label>
                                    <input
                                      id="organization"
                                      name="organization"
                                      type="organization"
                                      className="form-control"
                                      placeholder="Protocol Minimum Unit"
                                      disabled
                                      value={
                                        packageDetail &&
                                        packageDetail.product &&
                                        packageDetail.product.name
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="float-end">
                                <button
                                  className="btn btn-success"
                                  onClick={handleSaveDetail}
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* Second Tab Panel */}
                          <div class="tab-pane" id="profile-1" role="tabpanel">
                            <div>
                              <h4 className="card-title mt-1">
                                Farm Activities
                              </h4>
                              <p className="card-title-desc mb-3">
                                Edit Farm Protocol Standards
                              </p>

                              <div className="row">
                                {packageDetail &&
                                  packageDetail.activities &&
                                  packageDetail.activities.length > 0 &&
                                  packageDetail.activities.map((item) => (
                                    <div className="col-md-4">
                                      <div className="mx-3">
                                        <div
                                          className="container-card"
                                          style={{ padding: '14px 14px 0' }}
                                          key={item._id}
                                        >
                                          <div className="row mt-2">
                                            <div className="col-md-8">
                                              <div className="container__text">
                                                <h1 className="card-title">
                                                  {item.farmActivity}
                                                </h1>

                                                <p>
                                                  <span className="expected_task">
                                                    <b>Expected Task:</b>
                                                  </span>
                                                  <br />
                                                  {item.expectedTask}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-md-4 red">
                                              {item.activity ? (
                                                <div className="">
                                                  <div
                                                    className="align"
                                                    style={{
                                                      display: 'flex',
                                                      justifyContent:
                                                        'space-around',
                                                      alignContent:
                                                        'space-evenly',
                                                    }}
                                                  >
                                                    {item.activity ===
                                                      'Scan Activity' ? (
                                                      <div className="font-awesome-size">
                                                        <i
                                                          className="fa fa-qrcode"
                                                          style={{
                                                            fontSize: '130px',
                                                          }}
                                                        ></i>
                                                      </div>
                                                    ) : (
                                                      <div className="font-awesome-size mt-3">
                                                        <i
                                                          className="fa fa-file"
                                                          style={{
                                                            fontSize: '130px',
                                                          }}
                                                        ></i>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div
                                            className="container__text"
                                            style={{ padding: '14px 14px 0' }}
                                          >
                                            <div className="container__text__timing">
                                              <div className="container__text__timing_time mr-2">
                                                <h2>Timeline</h2>
                                                <p>
                                                  {item.timeLineFrom} -{' '}
                                                  {item.timeLineTo} hrs
                                                </p>
                                              </div>
                                              <div className="container__text__timing_time">
                                                <h2>Allocated point</h2>
                                                <p>{`${item.point} points`}</p>
                                              </div>
                                              {item.tagColor ? (
                                                <div className="container__text__timing_time">
                                                  <h2>Tag Color</h2>
                                                  <p>{item.tagColor}</p>
                                                </div>
                                              ) : null}
                                            </div>
                                            <div className="btn">
                                              <span
                                                style={{
                                                  marginRight: '20px',
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                  handleClickOpen(item)
                                                }
                                              >
                                                Edit
                                              </span>
                                              <span>
                                                <i
                                                  className="fa fa-trash"
                                                  id="sa-warning"
                                                  style={{
                                                    color: 'red',
                                                    cursor: 'pointer',
                                                  }}
                                                // onClick={() =>
                                                //   removeFromActivityHandler(
                                                //     item._id,
                                                //   )
                                                // }
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>

                          {/* Third Tab Panel */}
                          <div class="tab-pane" id="messages-1" role="tabpanel">
                            <div className="">
                              <h4 className="card-title mt-1">
                                Farmsured Benefits
                              </h4>
                              <p className="card-title-desc mb-3">
                                Edit Farm Package Benefits
                              </p>

                              {packageDetail &&
                                packageDetail.packageBenefits &&
                                packageDetail.packageBenefits.length === 0 ? (
                                <div className="centerBenefit">
                                  <p>Benefit is empty</p>
                                </div>
                              ) : (
                                <ul className="list-unstyled product-desc-list">
                                  <table className="table table-borderless">
                                    <thead>
                                      <tr>
                                        <th
                                          className="p-0 mt-1 text-black"
                                          scope="col-6"
                                        >
                                          Benefits
                                        </th>
                                        <th
                                          className="p-0 mt-1 text-black"
                                          scope="col-3"
                                        >
                                          Timeframe(hrs)
                                        </th>
                                        <th
                                          className="p-0 mt-1 text-black"
                                          scope="col-3"
                                        >
                                          Points
                                        </th>
                                        <th
                                          className="p-0 mt-1 text-black"
                                          scope="col-3"
                                        >
                                          Acitons
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {packageDetail &&
                                        packageDetail.packageBenefits
                                        ? packageDetail.packageBenefits.map(
                                          (item) => (
                                            <tr key={item._id}>
                                              <td>
                                                <i className="far fa-gem ml-0 me-1 align-middle font-size-16"></i>
                                                {item.benefit &&
                                                  item.benefit.benefit}
                                              </td>
                                              <td>
                                                {item.benefitFrom} -{' '}
                                                {item.benefitTo}
                                              </td>
                                              <td>
                                                {item.minPointToQualify}
                                              </td>
                                              <td>
                                                <button
                                                  className="btn btn-sm btn-warning mt-0"
                                                  onClick={() =>
                                                    handleOpenDialog(item)
                                                  }
                                                >
                                                  Edit
                                                </button>
                                              </td>
                                            </tr>
                                          ),
                                        )
                                        : null}
                                    </tbody>
                                  </table>
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />

        {/* Edit Modal */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <div>
            <div className="modal-header">
              <h5 className="modal-title">Edit compliance activity</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <DialogContent>
              <form>
                <div className="row mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label"
                  >
                    Activity
                  </label>
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Activity"
                      id="example-text-input"
                      value={activity}
                      onChange={(e) => setActivity(e.target.value)}
                    />
                  </div>
                </div>
                {activity && activity === 'Scan Activity' ? (
                  <div className="row mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-12 col-form-label"
                    >
                      Tag Color
                    </label>
                    <div className="col-md-12">
                      <select
                        className="form-control"
                        type="text"
                        placeholder="Activity"
                        id="example-text-input"
                        value={tagColor}
                        onChange={(e) => setTagColor(e.target.value)}
                      >
                        <option value="Verify (RED Label)">
                          Verify (RED Label)
                        </option>
                        <option value="Activate (GREEN Label)">
                          Activate (GREEN Label)
                        </option>
                      </select>
                    </div>
                  </div>
                ) : null}
                <div className="row mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label"
                  >
                    Farm Activity
                  </label>
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      id="example-text-input"
                      value={farmActivity}
                      onChange={(e) => setScanFarmActivity(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label"
                  >
                    Expected Task
                  </label>
                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      type="text"
                      placeholder=""
                      id="example-text-input"
                      value={expectedTask}
                      onChange={(e) => setScanExpectedTask(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label"
                  >
                    Timeline
                  </label>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      id="example-text-input"
                      value={timelineFrom}
                      onChange={(e) => setScanTimelineFrom(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      id="example-text-input"
                      value={timelineTo}
                      onChange={(e) => setScanTimelineTo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label"
                  >
                    Point Allocated
                  </label>

                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Points"
                      id="example-text-input"
                      disabled={activity === 'Scan Activity' ? true : false}
                      value={point}
                      onChange={(e) => setScanPoint(e.target.value)}
                    />
                  </div>
                </div>
                <DialogActions>
                  <button
                    className="btn btn-success btn-sm"
                    type="submit"
                    onClick={editItem}
                  >
                    Update
                  </button>
                </DialogActions>
              </form>
            </DialogContent>
          </div>
        </Dialog>

        {/* ==================================
        ======================================
        ======== Benefit Edit Model ==========
        ======================================
        ====================================*/}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <div>
            <div className="modal-header">
              <h5 className="modal-title">Edit compliance benefit</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseDialog}
              ></button>
            </div>
            <DialogContent>
              <form>
                <div className="row mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label"
                  >
                    Benefit
                  </label>
                  <div className="col-md-12">
                    <select
                      className="form-select input-group input-group-sm"
                      required
                      value={benefit}
                      onChange={(e) => setBenefit(e.target.value)}
                    >
                      <option>Choose...</option>
                      {benefitsLoading ? (
                        <>Loading...</>
                      ) : allbenefits.length === 0 ? (
                        <>
                          <option>Please create a benefit</option>
                        </>
                      ) : (
                        allbenefits.map((item) => (
                          <option value={item._id} key={item._id}>
                            {item.benefit}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label"
                  >
                    Min. Points to Qualify
                  </label>
                  <div className="col-md-12">
                    <input
                      type="number"
                      className="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-sm"
                      value={minPointToQualify}
                      placeholder="0.00"
                      required
                      onChange={(e) => setMinPointToQualify(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label"
                  >
                    Timeline
                  </label>
                  <div className="col-md-6">
                    <input
                      type="number"
                      className="form-control"
                      name="start"
                      placeholder="0.00"
                      required
                      time-format="HH:mm"
                      value={benefitFrom}
                      onChange={(e) => setBenefitFrom(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      className="form-control"
                      name="end"
                      required
                      placeholder="0.00"
                      value={benefitTo}
                      onChange={(e) => setBenefitTo(e.target.value)}
                    />
                  </div>
                </div>

                <DialogActions>
                  <button
                    className="btn btn-success btn-sm"
                    type="submit"
                    onClick={handleBenefitEditSumbit}
                  >
                    Update
                  </button>
                </DialogActions>
              </form>
            </DialogContent>
          </div>
        </Dialog>
      </div>
    </div>
  )
}
