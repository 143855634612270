import axios from 'axios'
import {
  PACKAGES_CATEGORY_LIST_FAIL,
  PACKAGES_CATEGORY_LIST_REQUEST,
  PACKAGES_CATEGORY_LIST_SUCCESS,
} from '../../../constants/adminConstants/packageConstants'

export const allPackageCategory = () => async (dispatch, getState) => {
  dispatch({
    type: PACKAGES_CATEGORY_LIST_REQUEST,
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/admin/all-package-category', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: PACKAGES_CATEGORY_LIST_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: PACKAGES_CATEGORY_LIST_FAIL, payload: message })
  }
}
