import {
    FARMTROVE_OVERVIEW_FAIL,
    FARMTROVE_OVERVIEW_REQUEST,
    FARMTROVE_OVERVIEW_SUCCESS
} from "../../../constants/adminConstants/farmtrove/dashboardConstants"

export const overviewReducer = (state = {}, action) => {
    switch (action.type) {
        case FARMTROVE_OVERVIEW_REQUEST:
            return { loading: true }
        case FARMTROVE_OVERVIEW_SUCCESS:
            return { loading: false, details: action.payload }
        case FARMTROVE_OVERVIEW_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
