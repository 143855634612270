import {
  FARMER_PRODUCT_LIST_FAIL,
  FARMER_PRODUCT_LIST_REQUEST,
  FARMER_PRODUCT_LIST_SUCCESS,
  FARMER_PRODUCT_SERVICE_LIST_FAIL,
  FARMER_PRODUCT_SERVICE_LIST_REQUEST,
  FARMER_PRODUCT_SERVICE_LIST_SUCCESS,
} from '../../../constants/farmerConstants/productConstants'

export const farmerProductListReducer = (
  state = { loading: true, products: [] },
  action,
) => {
  switch (action.type) {
    case FARMER_PRODUCT_LIST_REQUEST:
      return { loading: true }
    case FARMER_PRODUCT_LIST_SUCCESS:
      return { loading: false, products: action.payload }
    case FARMER_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const farmerProductServiceListReducer = (
  state = { loading: true, products: [] },
  action,
) => {
  switch (action.type) {
    case FARMER_PRODUCT_SERVICE_LIST_REQUEST:
      return { loading: true }
    case FARMER_PRODUCT_SERVICE_LIST_SUCCESS:
      return { loading: false, products: action.payload }
    case FARMER_PRODUCT_SERVICE_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}