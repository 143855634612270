export const FARMER_SCAN_TAG_REQUEST = 'FARMER_SCAN_TAG_REQUEST'
export const FARMER_SCAN_TAG_SUCCESS = 'FARMER_SCAN_TAG_SUCCESS'
export const FARMER_SCAN_TAG_FAIL = 'FARMER_SCAN_TAG_FAIL'
export const FARMER_SCAN_TAG_RESET = 'FARMER_SCAN_TAG_RESET'

export const FARMER_VALIDATE_TAG_REQUEST = 'FARMER_VALIDATE_TAG_REQUEST'
export const FARMER_VALIDATE_TAG_SUCCESS = 'FARMER_VALIDATE_TAG_SUCCESS'
export const FARMER_VALIDATE_TAG_FAIL = 'FARMER_VALIDATE_TAG_FAIL'
export const FARMER_VALIDATE_TAG_RESET = 'FARMER_VALIDATE_TAG_RESET'

export const FARMER_CHECK_STATUS_REQUEST = 'FARMER_CHECK_STATUS_REQUEST'
export const FARMER_CHECK_STATUS_SUCCESS = 'FARMER_CHECK_STATUS_SUCCESS'
export const FARMER_CHECK_STATUS_FAIL = 'FARMER_CHECK_STATUS_FAIL'
export const FARMER_CHECK_STATUS_RESET = 'FARMER_CHECK_STATUS_RESET'
