import axios from 'axios'
import {
  UPDATE_BENEFIT_CATEGORY_FAIL,
  UPDATE_BENEFIT_CATEGORY_REQUEST,
  UPDATE_BENEFIT_CATEGORY_SUCCESS,
} from '../../../constants/adminConstants/benefitContants'

export const editBenefitCategory = (
  itemId,
  name,
  productCat,
  description,
) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_BENEFIT_CATEGORY_REQUEST,
    payload: {
      itemId,
      name,
      productCat,
      description,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/benefit-category/update',
      { itemId, name, productCat, description },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: UPDATE_BENEFIT_CATEGORY_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: UPDATE_BENEFIT_CATEGORY_FAIL, payload: message })
  }
}
