import axios from 'axios'
import {
  EMAIL_PASSWORD_RESET_FAIL,
  EMAIL_PASSWORD_RESET_REQUEST,
  EMAIL_PASSWORD_RESET_SUCCESS,
} from '../../constants/authConstants/resetPasswordContants'

const requestResetPassword = (email) => async (dispatch) => {
  dispatch({
    type: EMAIL_PASSWORD_RESET_REQUEST,
    payload: { email },
  })
  try {
    const { data } = await axios.post('/api/request-password-reset', {
      email,
    })
    dispatch({ type: EMAIL_PASSWORD_RESET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: EMAIL_PASSWORD_RESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export default requestResetPassword
