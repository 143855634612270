export const USER_ACCOUNT_INFORMATION_REQUEST = 'USER_ACCOUNT_INFORMATION_REQUEST'
export const USER_ACCOUNT_INFORMATION_SUCCESS = 'USER_ACCOUNT_INFORMATION_SUCCESS'
export const USER_ACCOUNT_INFORMATION_FAIL = 'USER_ACCOUNT_INFORMATION_FAIL'

export const USER_ACCOUNT_TRANSATIONS_REQUEST = 'USER_ACCOUNT_TRANSATIONS_REQUEST'
export const USER_ACCOUNT_TRANSATIONS_SUCCESS = 'USER_ACCOUNT_TRANSATIONS_SUCCESS'
export const USER_ACCOUNT_TRANSATIONS_FAIL = 'USER_ACCOUNT_TRANSATIONS_FAIL'

export const USER_ACCOUNT_INCOME_REQUEST = 'USER_ACCOUNT_INCOME_REQUEST'
export const USER_ACCOUNT_INCOME_SUCCESS = 'USER_ACCOUNT_INCOME_SUCCESS'
export const USER_ACCOUNT_INCOME_FAIL = 'USER_ACCOUNT_INCOME_FAIL'

export const USER_ACCOUNT_IDENTITY_REQUEST = 'USER_ACCOUNT_IDENTITY_REQUEST'
export const USER_ACCOUNT_IDENTITY_SUCCESS = 'USER_ACCOUNT_IDENTITY_SUCCESS'
export const USER_ACCOUNT_IDENTITY_FAIL = 'USER_ACCOUNT_IDENTITY_FAIL'

