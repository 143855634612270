import {
  ACCEPT_LOAN_OFFER_FAIL,
  ACCEPT_LOAN_OFFER_REQUEST,
  ACCEPT_LOAN_OFFER_RESET,
  ACCEPT_LOAN_OFFER_SUCCESS,
  ALL_LOANS_APPLICATION_FAIL,
  ALL_LOANS_APPLICATION_REQUEST,
  ALL_LOANS_APPLICATION_SUCCESS,
  LOAN_APPLICATION_FAIL,
  LOAN_APPLICATION_REQUEST,
  LOAN_APPLICATION_RESET,
  LOAN_APPLICATION_SUCCESS,
  LOAN_PROVIDERS_FAIL,
  LOAN_PROVIDERS_REQUEST,
  LOAN_PROVIDERS_SUCCESS,
  PAY_LOAN_FAIL,
  PAY_LOAN_REQUEST,
  PAY_LOAN_RESET,
  PAY_LOAN_SUCCESS,
  VERIFY_IDENTITY_FAIL,
  VERIFY_IDENTITY_REQUEST,
  VERIFY_IDENTITY_RESET,
  VERIFY_IDENTITY_SUCCESS,
} from '../../../constants/farmerConstants/farmtrove/loanConstants'

export const verifyIdentityReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_IDENTITY_REQUEST:
      return { loading: true }
    case VERIFY_IDENTITY_SUCCESS:
      return { loading: false, verified: action.payload }
    case VERIFY_IDENTITY_FAIL:
      return { loading: false, error: action.payload }
    case VERIFY_IDENTITY_RESET:
      return {}
    default:
      return state
  }
}

export const loanApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAN_APPLICATION_REQUEST:
      return { loading: true }
    case LOAN_APPLICATION_SUCCESS:
      return { loading: false, application: action.payload }
    case LOAN_APPLICATION_FAIL:
      return { loading: false, error: action.payload }
    case LOAN_APPLICATION_RESET:
      return {}
    default:
      return state
  }
}

export const allLoansReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_LOANS_APPLICATION_REQUEST:
      return { loading: true }
    case ALL_LOANS_APPLICATION_SUCCESS:
      return { loading: false, loans: action.payload }
    case ALL_LOANS_APPLICATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const allLoansProviderReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAN_PROVIDERS_REQUEST:
      return { loading: true }
    case LOAN_PROVIDERS_SUCCESS:
      return { loading: false, providers: action.payload }
    case LOAN_PROVIDERS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const acceptLoanOfferReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCEPT_LOAN_OFFER_REQUEST:
      return { loading: true }
    case ACCEPT_LOAN_OFFER_SUCCESS:
      return { loading: false, result: action.payload }
    case ACCEPT_LOAN_OFFER_FAIL:
      return { loading: false, error: action.payload }
    case ACCEPT_LOAN_OFFER_RESET:
      return {}
    default:
      return state
  }
}

export const loanRepaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case PAY_LOAN_REQUEST:
      return { loading: true }
    case PAY_LOAN_SUCCESS:
      return { loading: false, repaid: action.payload }
    case PAY_LOAN_FAIL:
      return { loading: false, error: action.payload }
    case PAY_LOAN_RESET:
      return {}
    default:
      return state
  }
}