import axios from 'axios'
import {
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
} from '../../constants/authConstants/RegisterContants'

const register = (registerData) => async (
  dispatch,
) => {
  dispatch({
    type: USER_REGISTER_REQUEST,
    payload: registerData,
  })
  try {
    const { data } = await axios.post('/api/register',
      registerData
    )
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export default register
