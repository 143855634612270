import axios from 'axios'
import {
  LIST_SERVICE_PROVIDER_FAIL,
  LIST_SERVICE_PROVIDER_REQUEST,
  LIST_SERVICE_PROVIDER_SUCCESS,
} from '../../constants/authConstants/serviceConstants'

export const allService = () => async (dispatch) => {
  dispatch({ type: LIST_SERVICE_PROVIDER_REQUEST })
  try {
    const { data } = await axios.get('/api/all-service-provider')
    dispatch({ type: LIST_SERVICE_PROVIDER_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: LIST_SERVICE_PROVIDER_FAIL,
      payload: message,
    })
  }
}
