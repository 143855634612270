import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { farmerActivities } from '../../../actions/farmerActions/activitiesActions/farmerActivites'
import { LoadingBox, MessageBox } from '../../index'
import Modal from 'react-modal'
import moment from 'moment'
import CountdownTimer, { CountdownStatus } from '../../Counter/CountdownTimer'
import { profileDetail } from '../../../actions/authActions/profile'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { verifyStatus } from '../../../actions/farmerActions/tagsActions/tagStatus'
import {
  FARMER_CHECK_STATUS_RESET,
  FARMER_VALIDATE_TAG_RESET,
} from '../../../constants/farmerConstants/tagsConstants'
import { claimBenefit } from '../../../actions/farmerActions/claimActions/claimBenefit'
import { FARMER_CLAIM_BENEFIT_RESET } from '../../../constants/farmerConstants/claimBenefitConstants'
import { validateTag } from '../../../actions/farmerActions/tagsActions/validateTag'
import { MDBDataTableV5 } from 'mdbreact'
import { formatNumber } from '../../functions'
import Geocode from 'react-geocode'
import states from '../../../utilis'
import NaijaStates from 'naija-state-local-government'
import { getServiceProviders } from '../../../actions/farmerActions/getServiceProvidersActions/getServiceProviders'
import { Editor } from '@tinymce/tinymce-react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { allCycles } from '../../../actions/farmerActions/farmcycleActions/allCycles'
import { ToastContainer, toast } from 'react-toastify'
import LinearProgress from '@material-ui/core/LinearProgress'
import {
  POSTPONE_FARM_CYCLE_RESET,
  START_FARM_CYCLE_RESET,
} from '../../../constants/farmerConstants/packagesConstants'
import { startCycle } from '../../../actions/farmerActions/farmcycleActions/startCycle'
import { postponeCycle } from '../../../actions/farmerActions/farmcycleActions/postponeCycle'
import { checkStatus } from '../../../components/functions'
import { TailSpin } from 'react-loading-icons'

// set Google Maps Geocoding API for purposes of quota management.
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY)

const Farmer = (props) => {
  const [datatable, setDatatable] = useState({})
  const [productdatatable, setProductDatatable] = useState({})
  const [alert, setAlert] = useState(null)
  const [serviceProvider, setServiceProvider] = useState('')
  const [setIsOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [pin, setPin] = useState('')
  const [open, setOpen] = useState(false)
  const [openValidateDialog, setOpenValidateDialog] = useState(false)
  const [openForm, setOpenForm] = useState(false)
  const [tag, setTag] = useState('')
  const [color, setColor] = useState('')
  const [setIsProductDialogOpen] = useState(false)
  const [geolocation, setGeolocation] = useState({})
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [currentState, setCurrentState] = useState('')
  const [benefit, setBenefit] = useState({})
  const [lga, setLga] = useState('')
  const [country, setCountry] = useState('')
  const [geoError, setError] = useState(null)
  const [services, setServices] = useState([])
  const [SPLocation, setSPLocation] = useState([])
  const [mailOpen, setMailOpen] = useState(false)
  const [email, setEmail] = useState('')

  const editorRef = useRef(null)

  const activities = useSelector((state) => state.activities)
  const { loading: loadingProducts, error: productError, result } = activities

  const userDetail = useSelector((state) => state.userDetail)
  const { loading: loadingDetails, user } = userDetail

  const farmValidate = useSelector((state) => state.farmValidate)
  const {
    loading: loadingValidate,
    error: validateErr,
    result: validate,
  } = farmValidate

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const tagStatus = useSelector((state) => state.tagStatus)
  const { loading: statusLoading, error: statusError, status } = tagStatus

  const benefitClaim = useSelector((state) => state.benefitClaim)
  const { loading: claimLoading, error: claimError, claim } = benefitClaim

  const listProviders = useSelector((state) => state.listProviders)
  const {
    loading: serviceLoading,
    error: serviceError,
    listService,
  } = listProviders

  const dispatch = useDispatch()

  const dateTime = (item) => {
    const c =
      item.batch && item.batch.product && item.batch.product.category
        ? item.batch.product.category.cycleDuration
        : 0

    let hrs = c * 60 * 60 * 1000

    const d = new Date(item.redActivatedAt)
    let ms = d.getTime()
    return ms + hrs
  }

  const handleMailOpen = () => {
    setMailOpen(true)
    setOpenForm(false)
  }

  useEffect(() => {
    dispatch(farmerActivities())
    dispatch(profileDetail())
  }, [dispatch, validate])

  useEffect(() => {
    if (status) {
      setPin(status.checkTag.tag)
      setBenefit(
        status &&
          status.checkTag &&
          status.checkTag.product &&
          status.checkTag.product.category &&
          status.checkTag.product.category.benefitCategory
          ? status.checkTag.product.category.benefitCategory.name
          : null,
      )
    }
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(verifyStatus(pin))
  }

  const resetError = (e) => {
    e.preventDefault()
    setOpen(false)
    dispatch({ type: FARMER_CHECK_STATUS_RESET })
  }

  const handleStatusReset = (e) => {
    e.preventDefault()
    dispatch({ type: FARMER_CHECK_STATUS_RESET })
    setOpen(false)
    setOpenForm(true)
  }

  const handleClaimSubmit = (e) => {
    e.preventDefault()
    if (editorRef.current) {
      let detail = editorRef.current.getContent()
      dispatch(
        claimBenefit(pin, benefit, currentState, lga, serviceProvider, detail),
      )
    }
  }

  const onClose = (e) => {
    e.preventDefault()
    dispatch({ type: FARMER_CLAIM_BENEFIT_RESET })
    setOpen(false)
    setShow(false)
    setOpenForm(false)
    setSPLocation([])
    setCurrentState('')
    setServices([])
    setServiceProvider(null)
  }

  const onCloseMail = (e) => {
    e.preventDefault()
    dispatch({ type: FARMER_CLAIM_BENEFIT_RESET })
    setOpen(false)
    setMailOpen(false)
    setShow(false)
    setOpenForm(false)
    setSPLocation([])
    setCurrentState('')
    setServices([])
    setServiceProvider(null)
  }

  const handleScanSubmit = (e) => {
    e.preventDefault()

    if (!tag) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Input Tag Pin"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          A tag pin must be filled!!!
        </SweetAlert>,
      )
    } else if (!color) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Select Tag Color"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          A tag color must be selected!!!
        </SweetAlert>,
      )
    } else {
      dispatch(validateTag(tag, color, geolocation))
      setColor('')
    }
  }

  const resetHandler = (e) => {
    e.preventDefault()
    setOpenValidateDialog(false)
    dispatch({ type: FARMER_VALIDATE_TAG_RESET })
  }

  const resetScanError = (e) => {
    e.preventDefault()
    setOpenValidateDialog(false)
    dispatch({ type: FARMER_VALIDATE_TAG_RESET })
  }

  const customStyles = {
    content: {
      width: '330px',
      margin: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
  }

  useEffect(() => {
    if (result) {
      setProductDatatable({
        columns: [
          {
            label: 'Product',
            field: 'product',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'product',
            },
          },
          {
            label: 'Expected Points',
            field: 'expectedPoint',
            width: 270,
          },
          {
            label: 'Earned Points',
            field: 'earnedPoints',
            width: 200,
          },
          {
            label: 'Points to Quality',
            field: 'poq',
            width: 100,
          },
          {
            label: 'Cycle Duration',
            field: 'duration',
            width: 150,
          },
          {
            label: 'Date Verified',
            field: 'dateVerified',
            width: 100,
          },
          {
            label: 'Date Activated',
            field: 'dateActivated',
            width: 100,
          },
          {
            label: 'Protection Timeline',
            field: 'timeline',
            width: 50,
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'disabled',
          },
        ],
        rows: result.map((item) => ({
          ...item,
          product: item.batch.product.name,
          expectedPoint: item.batch.product.category.expectedPoint,
          earnedPoints:
            item.redActivatedAt && !item.greenActivatedAt
              ? item.points
              : item.redActivatedAt && item.greenActivatedAt
              ? item.points + item.points
              : null,
          poq: POQ(item),
          duration: item.batch.product.category.cycleDuration,
          dateVerified: item.redActivatedAt
            ? moment(item.redActivatedAt).format('Do MMMM YYYY,  h:mm a')
            : null,
          dateActivated: item.greenActivatedAt
            ? moment(item.greenActivatedAt).format('Do MMMM YYYY,  h:mm a')
            : null,
          timeline: <CountdownTimer targetDate={dateTime(item)} />,
          status: <CountdownStatus item={item} targetDate={dateTime(item)} />,
        })),
      })
    }
  }, [result])

  const POQ = (item) => {
    let expectedPoint = item.batch.product.category.expectedPoint
    let earnedPoint =
      item.redActivatedAt && !item.greenActivatedAt
        ? item.points
        : item.redActivatedAt && item.greenActivatedAt
        ? item.points * 2
        : 0
    return expectedPoint - earnedPoint
  }

  const onChange = ({ coords }) => {
    setGeolocation({
      lat: coords.latitude,
      lng: coords.longitude,
    })
  }

  const onError = (locaionError) => {
    setError(locaionError.message)
  }

  useEffect(() => {
    const geo = navigator.geolocation
    if (!geo) {
      setError('Geolocation is not supported')
      return
    }
    const watcher = geo.watchPosition(onChange, onError)
    return () => geo.clearWatch(watcher)
  }, [navigator.geolocation])

  useEffect(() => {
    const fetch = async () => {
      if (geolocation && geolocation.lat && geolocation.lng) {
        Geocode.fromLatLng(geolocation.lat, geolocation.lng).then(
          (response) => {
            for (
              let i = 0;
              i < response.results[0].address_components.length;
              i++
            ) {
              for (
                let j = 0;
                j < response.results[0].address_components[i].types.length;
                j++
              ) {
                switch (response.results[0].address_components[i].types[j]) {
                  case 'locality':
                    setCity(response.results[0].address_components[i].long_name)
                    break
                  case 'administrative_area_level_1':
                    setState(
                      response.results[0].address_components[i].long_name,
                    )
                    break
                  case 'country':
                    setCountry(
                      response.results[0].address_components[i].long_name,
                    )
                    break
                }
              }
            }
          },
          (error) => {
            console.error(error)
          },
        )
      }
    }
    fetch()
  }, [geolocation])

  useMemo(() => {
    if (listService) {
      setServices(listService)
    }
  }, [listService])

  // New

  const [itemId, setItemId] = useState('')
  const [name, setName] = useState('')
  const [packageName, setPackageName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [duration, setDuration] = useState('')
  const [image, setImage] = useState('')
  const [openDialog, setOpenDialog] = useState(false)

  const listCycles = useSelector((state) => state.listCycles)
  const { loading, cycles, error } = listCycles

  const cycleStarts = useSelector((state) => state.cycleStarts)
  const {
    loading: loadingStart,
    updatedCycle,
    error: updateError,
  } = cycleStarts

  const cyclePostpone = useSelector((state) => state.cyclePostpone)
  const {
    loading: loadingPosted,
    postedCycle,
    error: postedError,
  } = cyclePostpone

  useEffect(() => {
    dispatch(allCycles())

    if (updatedCycle) {
      toast.success(updatedCycle.message, {
        position: toast.POSITION.TOP_RIGHT,
      })

      setTimeout(() => {
        props.history.push(`/user/farm-cycle/detail/${itemId}`)
        window.location.reload()
        dispatch({ type: START_FARM_CYCLE_RESET })
      }, 2000)
    }

    if (postedCycle) {
      setOpen(false)
      toast.success(postedCycle.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      setTimeout(() => {
        dispatch({ type: POSTPONE_FARM_CYCLE_RESET })
      }, 2000)
    }
  }, [dispatch, updatedCycle, postedCycle])

  const checkDate = (startDate) => {
    // Convert the input string to a Date object
    var inputDate = new Date(startDate)

    // Get the current date
    var today = new Date()

    // Compare the input date with today's date
    if (inputDate >= today) {
      return true
    } else {
      return false
    }
  }

  const handleOpenDialog = (item) => {
    setOpenDialog(true)
    setItemId(item._id)
    setStartDate(moment(item.startDate).format('YYYY-MM-DD'))
    setDuration(item.productCategory.cycleDuration)
    setName(item.name)
    setPackageName(item.packageId.packageName)
    setImage(item.packageImg)
  }

  const startToday = (e) => {
    e.preventDefault()
    // dispatch start cycle action
    dispatch(startCycle(itemId, duration))
  }

  const postponeDate = (e) => {
    e.preventDefault()
    // dispatch start cycle action
    dispatch(postponeCycle(itemId, startDate, duration))
  }

  const Countdown = ({ date }) => {
    const [remainingTime, setRemainingTime] = useState(getRemainingTime(date))

    useEffect(() => {
      const timer = setTimeout(() => {
        setRemainingTime(getRemainingTime(date))
      }, 1000)

      return () => clearTimeout(timer)
    })

    const { days, hours, minutes, seconds } = remainingTime

    return (
      <div>
        {days} days, {hours} hours, {minutes} minutes, {seconds} seconds
      </div>
    )
  }

  function getRemainingTime(endTime) {
    const total = Date.parse(endTime) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
    const days = Math.floor(total / (1000 * 60 * 60 * 24))

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    }
  }

  useEffect(() => {
    if (cycles) {
      setDatatable({
        columns: [
          {
            label: 'Date Created',
            field: 'date',
            width: 50,
          },
           {
            label: 'Enterprise',
            field: 'enterprise',
            width: 100,
          },
          {
            label: 'FarmCycle Name',
            field: 'name',
            width: 100,
          },
          {
            label: 'Package',
            field: 'package',
            width: 100,
          },
          {
            label: 'Point',
            field: 'point',
          },
          {
            label: 'Progress',
            field: 'chart',
            width: 200,
          },
          {
            label: 'Start Date',
            field: 'startDate',
            width: 100,
          },
          {
            label: 'Status',
            field: 'status',
            width: 50,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: cycles.map((item) => ({
          name: item.name,
          enterprise:
            item.packageId &&
            item.packageId.enterprise &&
            item.packageId.enterprise.name
              ? item.packageId.enterprise.name
              : '',
          package:
            item.packageId && item.packageId.packageName
              ? item.packageId.packageName
              : '',
          point: item.point,
          chart: (
            <LinearProgress
              variant="determinate"
              className="mt-2 p-1"
              color="primary"
              value={
                item.point && item.productCategory.expectedPoint
                  ? (item.point / item.productCategory.expectedPoint) * 100
                  : 0
              }
            />
          ),
          status: checkStatus(item.startDate, item.endDate),
          startDate: moment(item.startDate).format('DD MMM YYYY'),
          date: moment(item.createdAt).format('Do MMM YYYY, h:mm a'),
          action: checkDate(item.startDate) ? (
            <button
              className="btn btn-warning btn-sm"
              data-bs-toggle="tooltip"
              data-placement="top"
              title="Pospone"
              onClick={() => handleOpenDialog(item)}
            >
              Postpone
            </button>
          ) : (
            <a
              href={`/user/farm-cycle/detail/${item._id}`}
              style={{ marginRight: '15px' }}
              className="btn btn-sm btn-success"
              data-bs-toggle="tooltip"
              data-placement="top"
              title="Manage"
            >
              Manage
            </a>
          ),
        })),
      })
    }
  }, [cycles])

  useEffect(() => {
    if (claim) {
      setTimeout(() => {
        setOpenForm(false)
        setMailOpen(false)
        setEmail('')
        setSPLocation([])
        setServices([])
        setServiceProvider('')
        setCurrentState('')
        setShow(false)
        dispatch({ type: FARMER_CLAIM_BENEFIT_RESET })
      }, 2100)
    }

    if (serviceProvider) {
      const SP = services.filter((item) => {
        if (item._id === serviceProvider) {
          return item
        }
        return false
      })

      if (SP) {
        setShow(true)
        setSPLocation(SP)
      }
    }
  }, [dispatch, claim, serviceProvider])

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">My Activity</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="javascript: void(0);">Farmsured</a>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {loadingStart ? <LoadingBox /> : null}
      {loadingPosted ? <LoadingBox /> : null}
      {claimLoading ? <LoadingBox /> : null}
      {error ? <MessageBox variant="danger">{error}</MessageBox> : null}
      {productError ? (
        <MessageBox variant="danger">{productError}</MessageBox>
      ) : null}

      <div className="row">
        <div className="col-md-3">
          <div
            className="card"
            style={{
              borderRadius: '15px',
              background: '#e0ffd7',
              color: '#fff',
            }}
          >
            <div
              className="card-body"
              style={{
                minHeight: 160,
              }}
            >
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <div className="text-primary mb-0">
                    <i className="fas fa-seedling" style={{fontSize: '30px'}}></i>
                  </div>

                  <h4
                    className="center"
                    style={{
                      fontSize: '38px',
                      fontWeight: 700,
                      marginTop: '11px',
                      height: 50,
                      color: 'black',
                    }}
                  >
                    <a
                      href="/user/create-farm-cycle"
                      className="btn btn-success btn-bg font-size-12"
                    >
                      Start an Agribusiness
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
 
        {/* <div className="col-md-2">
          <div
            className="card"
            style={{
              borderRadius: '15px',
              background: '#e0ffd7',
              color: '#fff',
            }}
          >
            <div
              className="card-body"
              style={{
                minHeight: 160,
              }}
            >
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <div className="text-primary mb-0">
                    <i className="fas fa-tags font-size-22"></i>
                  </div>

                  <h4
                    className="center"
                    style={{
                      fontSize: '38px',
                      fontWeight: 700,
                      marginTop: '11px',
                      height: 50,
                      color: 'black',
                    }}
                  >
                    <button
                      onClick={() => setOpenValidateDialog(true)}
                      className="btn btn-success btn-bg font-size-12"
                    >
                      Verify / Activate Product
                    </button>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-md-2">
          <div
            className="card"
            style={{
              borderRadius: '15px',
              background: '#e0ffd7',
              color: '#fff',
            }}
          >
            <div
              className="card-body"
              style={{
                minHeight: 160,
              }}
            >
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <div className="text-primary mb-0">
                    <i className="fas fa-shield-alt font-size-24"></i>
                  </div>

                  <h4
                    className="center"
                    style={{
                      fontSize: '38px',
                      fontWeight: 700,
                      marginTop: '11px',
                      height: 50,
                      color: 'black',
                    }}
                  >
                    <button
                      className="btn btn-success btn-bg font-size-12"
                      onClick={() => setOpen(true)}
                    >
                      Check Protection Status
                    </button>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="card">
            <div
              className="card-body"
              style={{
                minHeight: 160,
              }}
            >
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <div className="text-primary mt-1">
                    <i className="fas  fa-map-marker-alt font-size-24"></i>
                  </div>

                  <h4
                    className="center card-title"
                    style={{
                      fontSize: '15px',
                      fontWeight: 700,
                      marginTop: '15px',
                      height: 30,
                      // color: 'black',
                    }}
                  >
                    {city}, {state}
                  </h4>
                  <p
                    className="float-end text-truncate mb-2"
                    style={{
                      fontSize: '15px',
                      fontWeight: 700,
                      // color: 'black',
                    }}
                  >
                    {country}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              {loadingDetails ? (
                <TailSpin />
              ) : (
                <div className="row">
                  <div className="col-md-5 card-border">
                    <div className="text-center ">
                      <img
                        src={
                          user && user.image
                            ? user.image
                            : 'assets/images/default-user.png'
                        }
                        alt=""
                        className="farmer-user-image mt-0"
                      />
                      <h5 className="mt-2">
                        {user && user.firstName
                          ? `${user.firstName} ${user.lastName}`
                          : null}
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="info-box">
                      <p className="">
                        <i className="fal fa-phone-alt me-2"></i>
                        {user && user.phone ? `0${user && user.phone}` : null}
                      </p>
                      <p className="">
                        <i className="fal fa-envelope me-2"></i>
                        {user && user.email ? user.email : 'Not FIlled Yet'}
                      </p>
                      <p className="">
                        <i className="fal fa-atom-alt me-2"></i>
                        {user && user.points
                          ? formatNumber(user.points)
                          : 0}{' '}
                        Points
                      </p>
                      <p className="">
                        <i className="fal fa-map-marker-alt me-2"></i>
                        {user && user.state ? user.state : 'Not FIlled Yet'}
                      </p>
                      <p className="">
                        <i className="fal fa-city me-2"></i>
                        {user && user.lga ? user.lga : 'Not FIlled Yet'}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Farm Cycle Table Section Starts Here */}
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">My Agribusiness</h4>
          {loading ? (
            <div className="tailspin" style={{ height: 'calc(40vh - 50px)' }}>
              <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
            </div>
          ) : (
            <>
              <div className="table-responsive-xl">
                <MDBDataTableV5
                  hover
                  entriesOptions={[5, 20, 25, 50]}
                  entries={10}
                  pagesAmount={4}
                  data={datatable}
                  searchTop
                  searchBottom={false}
                  barReverse
                />
              </div>
            </>
          )}
        </div>
      </div>
      {/* Farm Cycle Table Section Ends Here */}

      {/* First Section Ends Here */}

      {/* Products Table Section Starts Here */}
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">AgroInput Verification</h4>
          <div className="table-responsive-xl">
            {loadingProducts ? (
              <div
                className="tailspin"
                style={{ height: 'calc(30vh - 50px)' }}
              >
                <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
              </div>
            ) : (
              <MDBDataTableV5
                hover
                entriesOptions={[5, 20, 25, 50]}
                entries={5}
                pagesAmount={4}
                data={productdatatable}
                searchTop
                searchBottom={false}
                barReverse
              />
            )}
          </div>
        </div>
      </div>
      {/* Products Table Section Ends Here */}

      {/* Status Check Modal Starts Here */}
      {statusLoading ? (
        <LoadingBox />
      ) : status ? (
        <div>
          <Modal
            isOpen
            onRequestClose={handleStatusReset}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div
              className="modal-header-false"
              style={{
                background: '#47c9a2',
                borderBottom: 'none',
                position: 'relative',
                textAlign: 'center',
                margin: '-20px -20px 0',
                borderRadius: '5px 5px 0 0',
                padding: '35px',
              }}
            >
              <div
                className="icon-box"
                style={{
                  color: '#fff',
                  textAlign: 'center',
                }}
              >
                <center>
                  <i
                    className="font-circle fa fa-check"
                    style={{
                      borderRadius: '60%',
                      display: 'inline-block',
                      zIndex: 9,
                      border: '5px solid #fff',
                      padding: '20px 20px 20px 20px',
                      fontSize: '70px',
                      margin: '-5px 0 0 -5px',
                      textAlign: 'center',
                    }}
                  ></i>
                </center>
              </div>
            </div>
            <div className="modal-body text-center">
              <p className="model-text">
                Hello <br />
              </p>
              <h6
                style={{
                  textAlign: 'center',
                  fontSize: '16px',
                  margin: '10px 0',
                }}
              >
                {status.message}
              </h6>
              <button
                className="btn btn-success"
                onClick={handleStatusReset}
                style={{
                  color: '#fff',
                  borderRadius: '30px',
                  textDecoration: 'none',
                  transition: 'all 0.4s',
                  lineHeight: 'normal',
                  marginTop: '5px',
                  padding: '6px 20px 10px ',
                  border: 'none',
                }}
              >
                Any Issue? Request for Support
              </button>
            </div>
          </Modal>
        </div>
      ) : statusError ? (
        <div>
          <Modal
            isOpen
            onRequestClose={() => setIsOpen(false)}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div
              className="modal-header-false"
              style={{
                background: '#d13e3e',
                borderBottom: 'none',
                position: 'relative',
                textAlign: 'center',
                margin: '-20px -20px 0',
                borderRadius: '5px 5px 0 0',
                padding: '35px',
              }}
            >
              <div
                className="icon-box"
                style={{
                  color: '#fff',
                  textAlign: 'center',
                }}
              >
                <center>
                  <i
                    className="font-circle fa fa-times"
                    style={{
                      borderRadius: '60%',
                      display: 'inline-block',
                      zIndex: 9,
                      border: '5px solid #fff',
                      padding: '20px 30px 20px 30px',
                      fontSize: '70px',
                      margin: '-5px 0 0 -5px',
                      textAlign: 'center',
                    }}
                  ></i>
                </center>
              </div>
            </div>
            <div className="modal-body text-center">
              <p className="model-text">
                Hello <br />
              </p>
              <h6
                style={{
                  textAlign: 'center',
                  fontSize: '16px',
                  margin: '10px 0',
                }}
              >
                {statusError}
              </h6>
              <button
                className="btn btn-success"
                onClick={resetError}
                style={{
                  color: '#fff',
                  borderRadius: '30px',
                  textDecoration: 'none',
                  transition: 'all 0.4s',
                  lineHeight: 'normal',
                  marginTop: '5px',
                  padding: '6px 20px 10px ',
                  border: 'none',
                }}
              >
                Close
              </button>
            </div>
          </Modal>
        </div>
      ) : (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <>
            <div className="modal-header">
              <h5 className="modal-title">Protection Status</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setOpen(false)}
              ></button>
            </div>

            <DialogContent>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className=" mb-3">
                      <label className="col-md-12 col-form-label">
                        Tag Pin (Red or Green)
                      </label>
                      <div className="col-md-12">
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Product Tag Pin"
                          id="example-text-input"
                          required
                          onChange={(e) => setPin(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    className="btn btn-light btn-sm waves-effect"
                    data-bs-dismiss="modal"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                  <button className="btn btn-success btn-sm" type="submit">
                    Check
                  </button>
                </DialogActions>
              </form>
            </DialogContent>
          </>
        </Dialog>
      )}
      {/* Status Check Modal Ends Here */}

      {/* Claim Benefit Form Starts Here*/}
      <Dialog
        open={openForm}
        onClose={() => setOpenForm(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <>
          <div className="modal-header">
            <h5 className="modal-title">Claim Beneift</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>

          <DialogContent>
            {serviceError ? (
              <MessageBox variant="danger">{serviceError}</MessageBox>
            ) : null}

            <div className="row">
              <div className="col-12">
                <div className="mb-2">
                  <label className="col-md-12 col-form-label">
                    Farmer's ID
                  </label>
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="User Phone Number"
                      id="example-text-input"
                      disabled
                      value={user && user.phone ? user.phone : null}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className=" mb-2">
                  <label className="col-md-12 col-form-label">
                    Farmsured Secured Tag Pin
                  </label>
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Farmsured Secured Tag Pin"
                      id="example-text-input"
                      disabled
                      value={pin}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-2">
                  <label className="col-md-12 col-form-label">
                    Benefit Category
                  </label>
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Benefit"
                      id="example-text-input"
                      disabled
                      value={benefit}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-2">
                  <label className="col-md-12 col-form-label">State</label>
                  <div className="col-md-12">
                    <select
                      className="form-select form-control select2"
                      required
                      placeholder="Select a state"
                      onChange={(e) => setCurrentState(e.target.value)}
                    >
                      <option>Select a state</option>
                      {states.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className=" mb-2">
                  <label className="col-md-12 col-form-label">
                    Local Government Area
                  </label>
                  <div className="col-md-12">
                    <select
                      className="form-select form-control select2"
                      required
                      name="lga"
                      placeholder="Select a local government area"
                      onChange={(e) => (
                        setLga(e.target.value),
                        dispatch(
                          getServiceProviders(
                            benefit,
                            currentState,
                            e.target.value,
                          ),
                        )
                      )}
                    >
                      {currentState && currentState === 'Select a state' ? (
                        <option>Select a local government area</option>
                      ) : currentState ? (
                        <>
                          <option>Select a local government area</option>
                          {NaijaStates.lgas(currentState).lgas.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </>
                      ) : (
                        <option>Select a local government area</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className=" mb-2">
                  <label className="col-md-12 col-form-label">
                    Service Provider
                  </label>
                  <div className="col-md-12">
                    <select
                      className="form-select form-control select2"
                      required
                      name="lga"
                      placeholder="Select a local government area"
                      onChange={(e) => setServiceProvider(e.target.value)}
                    >
                      {serviceLoading ? (
                        'Loading'
                      ) : listService ? (
                        <>
                          <option>Select a service provider</option>
                          {services.map((item) => (
                            <option value={item._id} key={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </>
                      ) : (
                        <option>Select a service provider</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              {show ? (
                <div>
                  <>
                    <div>
                      {SPLocation && SPLocation.length > 0 ? (
                        <>
                          <div className="card">
                            <div className="card-body ">
                              {SPLocation.map((item) => (
                                <>
                                  <h5 className="card-title">
                                    <b>{item.name}</b>
                                  </h5>
                                  <p className="m-0">
                                    <b>Phone:</b> {item.phone}
                                  </p>
                                  <p className="m-0">
                                    <b>Email:</b> {item.email}
                                  </p>
                                  <p className="m-0">
                                    <b>Address:</b> {item.address}
                                  </p>
                                  <p className="m-0">
                                    <b>Local Government Area:</b> {item.lga}
                                  </p>
                                  <p className="m-0">
                                    <b>State:</b> {item.state}
                                  </p>

                                  {item.locations &&
                                  item.locations.length > 0 ? (
                                    <div>
                                      <p className="m-0 mt-2">
                                        <b>Other Branches</b>
                                      </p>
                                      {item.locations.map((itm) => (
                                        <div className="m-2">
                                          <div className="mt-0">
                                            <b>Local Government Area: </b>
                                            {itm.lga}
                                          </div>
                                          <div className="">
                                            <b>Address: </b>
                                            {itm.details}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : null}

                                  <div>
                                    <button
                                      className="btn btn-sm btn-primary float-end me-2"
                                      onClick={() => (
                                        handleMailOpen(), setEmail(item.email)
                                      )}
                                    >
                                      <i className="fa fa-envelope me-1"></i>
                                      Email
                                    </button>
                                    <a
                                      href={`tel:+234${item.phone}`}
                                      className="btn btn-sm btn-success float-end me-2"
                                    >
                                      <i className="fa fa-phone-alt"></i> Call
                                    </a>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          <p className="bagde">
                            There are no service provider around this local
                            government. Kindly select the nearest local
                            government area
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                </div>
              ) : null}
            </div>
            <DialogActions>
              <button
                type="button"
                className="btn btn-light btn-sm waves-effect"
                data-bs-dismiss="modal"
                onClick={onClose}
              >
                Close
              </button>
            </DialogActions>
          </DialogContent>
        </>
      </Dialog>
      {/* Claim Benefit Form Ends Here*/}

      {/* Product Validation Dialog Starts Here*/}
      {loadingValidate ? (
        <LoadingBox />
      ) : validateErr ? (
        <div>
          <Modal
            isOpen
            onRequestClose={() => setIsProductDialogOpen(false ? true : false)}
            style={customStyles}
          >
            <div
              className="modal-header-false"
              style={{
                background: '#d13e3e',
                borderBottom: 'none',
                position: 'relative',
                textAlign: 'center',
                margin: '-20px -20px 0',
                borderRadius: '5px 5px 0 0',
                padding: '35px',
              }}
            >
              <div
                className="icon-box"
                style={{
                  color: '#fff',
                  textAlign: 'center',
                }}
              >
                <center>
                  <i
                    className="font-circle fa fa-times"
                    style={{
                      borderRadius: '60%',
                      display: 'inline-block',
                      zIndex: 9,
                      border: '5px solid #fff',
                      padding: '20px 30px 20px 30px',
                      fontSize: '70px',
                      margin: '-5px 0 0 -5px',
                      textAlign: 'center',
                    }}
                  ></i>
                </center>
              </div>
            </div>
            <div className="modal-body text-center">
              <p className="model-text">
                Hello <br />
              </p>
              <h4
                style={{
                  textAlign: 'center',
                  fontSize: '16.5px',
                  margin: '10px 0',
                }}
              >
                TRY AGAIN
              </h4>
              <h6
                style={{
                  textAlign: 'center',
                  fontSize: '16px',
                  margin: '10px 0',
                }}
              >
                {validateErr}
              </h6>
              <br />
              <button
                className="btn btn-success"
                onClick={resetScanError}
                style={{
                  color: '#fff',
                  textDecoration: 'none',
                  transition: 'all 0.4s',
                  lineHeight: 'normal',
                  borderRadius: '30px',
                  marginTop: '10px',
                  padding: '6px 20px 10px ',
                  border: 'none',
                }}
              >
                Close
              </button>
            </div>
          </Modal>
        </div>
      ) : validate ? (
        <div>
          <Modal
            isOpen
            onRequestClose={() => setIsOpen(false)}
            style={customStyles}
            contentLabel="Modal"
          >
            <div
              className="modal-header-false"
              style={{
                background: '#47c9a2',
                borderBottom: 'none',
                position: 'relative',
                textAlign: 'center',
                margin: '-20px -20px 0',
                borderRadius: '5px 5px 0 0',
                padding: '35px',
              }}
            >
              <div
                className="icon-box"
                style={{
                  color: '#fff',
                  textAlign: 'center',
                }}
              >
                <center>
                  <i
                    className="font-circle fa fa-check"
                    style={{
                      borderRadius: '60%',
                      display: 'inline-block',
                      zIndex: 9,
                      border: '5px solid #fff',
                      padding: '20px 20px 20px 20px',
                      fontSize: '70px',
                      margin: '-5px 0 0 -5px',
                      textAlign: 'center',
                    }}
                  ></i>
                </center>
              </div>
            </div>
            <div className="modal-body text-center">
              <p className="model-text">
                Hello {userInfo.firstName} <br />
              </p>
              <h4
                style={{
                  textAlign: 'center',
                  fontSize: '18px',
                  margin: '10px 0',
                }}
              >
                {validate && validate.message ? validate.message : null}
              </h4>
              <button
                className="btn btn-success"
                onClick={resetHandler}
                style={{
                  color: '#fff',
                  textDecoration: 'none',
                  transition: 'all 0.4s',
                  lineHeight: 'normal',
                  borderRadius: '30px',
                  marginTop: '10px',
                  padding: '6px 20px 10px ',
                  border: 'none',
                }}
              >
                Earn Benefits
              </button>
            </div>
          </Modal>
        </div>
      ) : (
        <Dialog
          open={openValidateDialog}
          onClose={() => setOpenValidateDialog(false)}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <>
            <div className="modal-header">
              <h5 className="modal-title">Verify / Activate Product</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setOpenValidateDialog(false)}
              ></button>
            </div>

            <DialogContent>
              <form className="form" onSubmit={handleScanSubmit}>
                <div
                  id="container"
                  style={{
                    minHeight: '75vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div className="text-center">
                    <p className="font-size-12">
                      Select the tag and input the pin code
                    </p>
                    <div
                      className="row "
                      onChange={(e) => setColor(e.target.value)}
                      required
                    >
                      <div className="col-lg-6">
                        <div>
                          <label className="form-label card-radio-label mb-3">
                            <input
                              type="radio"
                              name="pay-method"
                              id="pay-methodoption3"
                              className="card-radio-input"
                              value="red"
                            />

                            <img
                              src="assets/images/red_tag.png"
                              alt="red tag"
                              className="card-radio me-1"
                              style={{ width: '200px' }}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div>
                          <label className="form-label card-radio-label mb-3">
                            <input
                              type="radio"
                              name="pay-method"
                              id="pay-methodoption1"
                              className="card-radio-input"
                              value="green"
                            />
                            <img
                              src="assets/images/green_tag.png"
                              className="card-radio me-1"
                              alt="green tag"
                              style={{ width: '200px' }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="input-container mt-3">
                      <div className="container__item">
                        <input
                          type="number"
                          className="form-control form__field"
                          placeholder="Insert Pin Code"
                          onChange={(e) => setTag(e.target.value)}
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn--primary mt-3 btn-block uppercase"
                      >
                        Validate
                      </button>
                    </div>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    className="btn btn-light btn-sm waves-effect"
                    data-bs-dismiss="modal"
                    onClick={() => setOpenValidateDialog(false)}
                  >
                    Close
                  </button>
                </DialogActions>
              </form>
            </DialogContent>
          </>
        </Dialog>
      )}
      {/* Product Validation Dialog End Here */}

      {mailOpen ? (
        <Dialog
          open={mailOpen}
          onClose={() => setOpenForm(false)}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <>
            <div className="modal-header">
              <h5 className="modal-title">Claim Beneift</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onCloseMail}
              ></button>
            </div>

            <DialogContent>
              {claim ? (
                <MessageBox variant="success">{claim.message}</MessageBox>
              ) : null}
              {claimError ? (
                <MessageBox variant="danger">{claimError}</MessageBox>
              ) : null}

              <form onSubmit={handleClaimSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <input
                        className="form-control "
                        type="text"
                        placeholder="To"
                        id="example-text-input"
                        value={`${email} <${email}>`}
                        required
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mt-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="CC"
                        id="example-text-input"
                        required
                        value={'admin <farmsured>'}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mt-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Subject"
                        id="example-text-input"
                        value={benefit}
                        required
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mt-3">
                      <label htmlFor="">
                        A well detailed explanation of what happened
                      </label>
                      <Editor
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue=""
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                          ],
                          toolbar:
                            'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                          content_style:
                            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    className="btn btn-secondary waves-effect"
                    data-bs-dismiss="modal"
                    onClick={onCloseMail}
                  >
                    Close
                  </button>
                  <button className="btn btn-success" type="submit">
                    Submit<i className="fab fa-telegram-plane ms-1"></i>
                  </button>
                </DialogActions>
              </form>
            </DialogContent>
          </>
        </Dialog>
      ) : null}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'lg'}
      >
        <>
          <div className="modal-header">
            <h5 className="modal-title">Postpone Farm Cycle</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setOpenDialog(false)}
            ></button>
          </div>
          <DialogContent>
            {updateError ? (
              <MessageBox variant="danger">{updateError}</MessageBox>
            ) : null}

            {postedError ? (
              <MessageBox variant="danger">{postedError}</MessageBox>
            ) : null}
            <div className="mt-5">
              <div className="row">
                <div className="col-md-4">
                  <div className="count-image">
                    <img
                      src={image}
                      alt=""
                      className="count-down-image"
                      style={{
                        maxWidth: '100%',
                        margin: 'auto',
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="card">
                    <div
                      className="card-body"
                      style={{
                        border: '1px solid #0d89c7',
                        borderRadius: '10px',
                      }}
                    >
                      <div className="standard-body">Countdown to Start</div>

                      <div className="mt-4">
                        <div className="row">
                          {/* First Column */}
                          <div className="col-md-2">
                            <div className="card">
                              <div className="card-body">
                                <i
                                  className="fal fa-id-card"
                                  style={{
                                    fontSize: '34px',
                                    width: '100%',
                                    margin: 'auto',
                                    textAlign: 'center',
                                    padding: '12px',
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-10">
                            <div className="card">
                              <div className="card-body">
                                <p className="font-size-13">Cycle Name</p>

                                <h4 className="card-title font-size-16">
                                  {name ? name : 'Farm Cycle Name'}
                                </h4>
                              </div>
                            </div>
                          </div>

                          {/* Second column */}
                          <div className="col-md-2">
                            <div className="card">
                              <div className="card-body">
                                <i
                                  className="fal fa-badge-check"
                                  style={{
                                    fontSize: '39px',
                                    width: '100%',
                                    margin: 'auto',
                                    textAlign: 'center',
                                    padding: '12px',
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-10">
                            <div className="card">
                              <div className="card-body">
                                <p className="font-size-13">Package Name</p>

                                <h4 className="card-title font-size-16">
                                  {packageName
                                    ? packageName
                                    : 'Farmsured package'}
                                </h4>
                              </div>
                            </div>
                          </div>

                          {/* Third Column */}
                          <div className="col-md-2">
                            <div className="card">
                              <div className="card-body">
                                <i
                                  className="fal fa-calendar"
                                  style={{
                                    fontSize: '40px',
                                    width: '100%',
                                    margin: 'auto',
                                    textAlign: 'center',
                                    padding: '12px',
                                    paddingBottom: '10px',
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-10">
                            <div className="card">
                              <div className="card-body">
                                <p className="font-size-13">Postpone date</p>
                                <input
                                  id="date"
                                  name="date"
                                  type="date"
                                  className="form-control m-0"
                                  min={moment().format('YYYY-MM-DD')}
                                  required
                                  onChange={(e) => setStartDate(e.target.value)}
                                  placeholder="Date"
                                  value={startDate}
                                />
                              </div>
                            </div>
                          </div>

                          {/* Fourth Column */}
                          <div className="col-md-2">
                            <div className="card">
                              <div className="card-body">
                                <i
                                  className="fal fa-hourglass-start"
                                  style={{
                                    fontSize: '39px',
                                    width: '100%',
                                    margin: 'auto',
                                    textAlign: 'center',
                                    padding: '12px',
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-10">
                            <div className="card">
                              <div className="card-body">
                                <p className="font-size-13">Countdown</p>

                                <h4 className="card-title font-size-16">
                                  <Countdown date={`${startDate}T00:00:00`} />
                                </h4>
                              </div>
                            </div>
                          </div>

                          {/* Fiveth Column */}
                          <div className="col-md-6">
                            <div className="d-grid">
                              <button
                                className="btn btn-success btn-lg"
                                onClick={startToday}
                              >
                                Start Now
                              </button>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-grid">
                              <button
                                className="btn btn-warning btn-lg"
                                onClick={postponeDate}
                              >
                                Postpone
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </>
      </Dialog>

      {alert}
      <ToastContainer />
    </div>
  )
}

export default Farmer
