import React, { useState, useEffect } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { getLoanApplicationDetail } from '../../../actions/adminActions/farmtroveActions/getLoanApplicationDetail'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import { formatNumber } from '../../../components/functions'
import { getCheckRiskIntellignce } from '../../../actions/adminActions/farmtroveActions/getCheckRiskIntellignce'
import { updateLoanApplicationStatus } from '../../../actions/adminActions/farmtroveActions/updateLoanApplicationStatus'
import { TailSpin } from 'react-loading-icons'
import { UPDATE_LOAN_APPLICATION_RESET } from '../../../constants/adminConstants/farmtrove/loanConstants'

export default function ViewLoanApplicationPage(props) {
  const itemId = props.match.params.id

  const [show, setShow] = useState(false)
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')
  const [amount, setAmount] = useState('')

  const loanDetail = useSelector((state) => state.loanDetail)
  const { loading, detail, error } = loanDetail

  const loanRisk = useSelector((state) => state.loanRisk)
  const { loading: loadingCheck, result, error: checkError } = loanRisk

  const loanUpdate = useSelector((state) => state.loanUpdate)
  const { loading: loadingUpdate, updated, error: updateError } = loanUpdate

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLoanApplicationDetail(itemId))
    dispatch(getCheckRiskIntellignce(itemId))

    if (updated) {
      setTimeout(() => {
        dispatch({ type: UPDATE_LOAN_APPLICATION_RESET })
      }, 2500)
    }
  }, [dispatch, itemId, updated])

  const handleSave = (e) => {
    e.preventDefault()

    dispatch(updateLoanApplicationStatus(itemId, status, amount, message))
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Loan Application</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmtrove</a>
                      </li>
                      <li className="breadcrumb-item active">
                        User Loan Application
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {error && <MessageBox variant="danger">{error}</MessageBox>}
            {updateError && (
              <MessageBox variant="danger">{updateError}</MessageBox>
            )}

            {updated && (
              <MessageBox variant="success">{updated.message}</MessageBox>
            )}
            {loadingUpdate && <LoadingBox />}
            {loading ? (
              <LoadingBox />
            ) : (
              <div className="">
                <div className="row mb-2">
                  <div className="col-lg-6">
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src="assets/images/farmsured_icon.png"
                          alt="logo-sm-light"
                          className="avatar-xs"
                        />
                      </div>
                      <div className="flex-1">
                        <h5>Loan application Dashboard</h5>
                        <p className="mb-1">Loan ID: {detail && detail.loan && detail.loan.loanID ? `#${detail && detail.loan.loanID}` : '`'}</p>
                        <p className="mb-1">
                          Application date:{' '}
                          {detail && detail.loan &&
                            detail.loan.applicationDate &&
                            moment(detail && detail.loan.applicationDate).format(
                              'DD MMM YYYY, hh:mm a',
                            )}
                        </p>
                        <span
                          className={`badge badge-soft-${detail && detail.loan && detail.loan.status === 'Pending'
                            ? 'secondary'
                            : detail && detail.loan && detail.loan.status === 'Approved'
                              ? 'success'
                              : detail && detail.loan && detail.loan.status === 'Active'
                                ? 'primary'
                                : detail && detail.loan && detail.loan.status === 'Reviewing'
                                  ? 'warning'
                                  : detail && detail.loan && detail.loan.status === 'Complete'
                                    ? 'secondary'
                                    : detail && detail.loan && detail.loan.status === 'Reject'
                                      ? 'danger'
                                      : ''
                            } font-size-13`}
                        >
                          {detail && detail.loan && detail.loan.status}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="text-lg-end">
                      <div className="row">
                        <div className="col-12 col-lg-3 col-6 col-md-3"></div>

                        <div className="col-12 col-lg-6 col-6 col-md-3"></div>
                        <div className="col-12 col-lg-3 col-6 col-md-3">
                          <button
                            type="button"
                            className="btn btn-success me-3"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                          <button type="button" className="btn btn-secondary">
                            <i className="fal fa-print me-1"></i> Print
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <div className="card shadow-sm border-0 overflow-hidden">
                      <div className="card-body">
                        <h5 className="card-title">User Detail</h5>
                        <hr className="my-2 mb-3" />
                        <div className="profile-avatar text-center">
                          <img
                            src={detail &&
                              detail.loan && detail.loan.user && detail.loan.user.image
                              ? detail.loan.user.image
                              : 'assets/images/farmsured_icon.png'
                            }
                            className="rounded-circle shadow"
                            width="150"
                            height="150"
                            alt=""
                          />
                        </div>
                        <div className="text-center mt-4">
                          <h4 className="mb-1 text-capitalize">
                            {detail && detail.loan &&
                              detail.loan.user &&
                              detail.loan.user.firstName &&
                              detail.loan.user.lastName
                              ? `${detail.loan.user.firstName} ${detail.loan.user.lastName}`
                              : 'User Name'}
                          </h4>
                          <p className="mb-0 text-secondary">
                            {detail && detail.loan && detail.loan.user && detail.loan.user.lga && detail.loan.user.state
                              ? `${detail.loan.user.lga}, ${detail.loan.user.state}`
                              : 'Location not filled'}
                          </p>
                          <div className="mt-3"></div>
                          <h6 className="mb-1">
                            {detail && detail.loan && detail.loan.user && detail.loan.user.phone
                              ? `0${detail.loan.user.phone}`
                              : 'User ID'}
                          </h6>
                          <p className="mb-0 text-secondary">
                            {detail && detail.loan && detail.loan.user && detail.loan.user.account
                              ? detail.loan.user.account
                              : 'User'}
                          </p>
                        </div>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent border-top">
                          Farmsured Points
                          <span className="badge bg-primary rounded-pill">
                            {detail && detail.loan && detail.loan.user && detail.loan.user.point
                              ? `${detail.loan.user.point} points`
                              : '0 point'}
                          </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
                          Farm Cycle
                          <span className="">{detail && detail.farmCycles ? `${detail.farmCycles} Enterprise(s)` : 0}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
                          Wallet
                          <span className="">NGN {detail && detail.wallet ? `${formatNumber(detail.wallet.balance)}.00` : 0.00}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="row">
                      <div className="col-12 col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <h4 className="card-title mb-2">Loan Provider</h4>
                            <hr className="my-2 mb-4" />

                            <div className="">
                              <div className="row">
                                <div className="col-12 col-md-6">
                                  <h5 className="card-desc font-size-15">
                                    Name:
                                  </h5>
                                  <p className="font-size-14">
                                    {detail && detail.loan &&
                                      detail.loan.loanProvider &&
                                      detail.loan.loanProvider.name
                                      ? detail.loan.loanProvider.name
                                      : 'Null'}
                                  </p>
                                </div>
                                <div className="col-12 col-md-6">
                                  <h5 className="card-desc font-size-15">
                                    Loan Duration:
                                  </h5>
                                  <p className="font-size-14">
                                    {detail && detail.loan &&
                                      detail.loan.loanProvider &&
                                      detail.loan.loanProvider.duration
                                      ? `${detail.loan.loanProvider.duration} Month(s)`
                                      : 'Null'}
                                  </p>
                                </div>

                                <div className="col-12 col-md-6">
                                  <h5 className="card-desc font-size-15">
                                    Maximum Amount:
                                  </h5>
                                  <p className="font-size-14">
                                    {detail && detail.loan &&
                                      detail.loan.loanProvider &&
                                      detail.loan.loanProvider.maxAmount
                                      ? `NGN ${formatNumber(
                                        detail.loan.loanProvider.maxAmount,
                                      )}`
                                      : 'NGN 0.00'}
                                  </p>
                                </div>
                                <div className="col-12 col-md-6">
                                  <h5 className="card-desc font-size-15">
                                    Moratorium Period:
                                  </h5>
                                  <p className="font-size-14">
                                    {detail && detail.loan && detail.loan.loanProvider && detail.loan.loanProvider.moratoriumPeriod
                                      ? `${detail.loan.loanProvider.moratoriumPeriod} Month(s)`
                                      : '0 Month(s)'}
                                  </p>
                                </div>

                                <div className="col-12 col-md-6">
                                  <h5 className="card-desc font-size-15">
                                    Interest Rate (%):
                                  </h5>
                                  <p className="font-size-14">
                                    {detail && detail.loan &&
                                      detail.loan.loanProvider &&
                                      detail.loan.loanProvider.interest
                                      ? `${detail.loan.loanProvider.interest}%`
                                      : 'Null'}
                                  </p>
                                </div>
                                <div className="col-12 col-md-6">
                                  <h5 className="card-desc font-size-15">
                                    Description:
                                  </h5>
                                  <p className="font-size-14 text-uppercase">
                                    {detail && detail.loan &&
                                      detail.loan.loanProvider &&
                                      detail.loan.loanProvider.description
                                      ? detail.loan.loanProvider.description
                                      : 'Description'}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title mb-2">
                              Loan Application Detail
                            </h5>
                            <hr className="my-2 mb-4" />

                            <div className="">
                              <div className="row">
                                <div className="col-12 col-md-4">
                                  <h5 className="card-desc font-size-15">
                                    Loan Amount:
                                  </h5>
                                  <p className="font-size-14">
                                    {detail && detail.loan && detail.loan.loanAmount
                                      ? `NGN ${formatNumber(detail.loan.loanAmount)}`
                                      : 'NGN 0.00'}
                                  </p>
                                </div>
                                <div className="col-12 col-md-4">
                                  <h5 className="card-desc font-size-15">
                                    Enterprise Name:
                                  </h5>
                                  <p className="font-size-14">
                                    {detail && detail.loan && detail.loan.farmCycle && detail.loan.farmCycle.name}
                                  </p>
                                </div>
                                <div className="col-12 col-md-4">
                                  <h5 className="card-desc font-size-15">
                                    Enterprise E.O.P Total Sum:
                                  </h5>
                                  <p className="font-size-14">
                                    {detail && detail.loan && detail.loan.farmCycle && detail.loan.farmCycle.eopTotalSum ? `NGN ${formatNumber(detail.loan.farmCycle.eopTotalSum)}.00` : "NGN0.00"}
                                  </p>
                                </div>

                                <div className="col-12 col-md-4">
                                  <h5 className="card-desc font-size-15">
                                    Enterprise Standard E.O.P Sum:
                                  </h5>
                                  <p className="font-size-14">
                                    {detail && detail.loan && detail.loan.farmCycle && detail.loan.farmCycle.totalSum ? `NGN ${formatNumber(detail.loan.farmCycle.totalSum)}.00` : "NGN0.00"}
                                  </p>
                                </div>
                                <div className="col-12 col-md-4">
                                  <h5 className="card-desc font-size-15">
                                    ID Verification Type:
                                  </h5>
                                  <p className="font-size-14 text-uppercase">
                                    {detail && detail.loan && detail.loan.idType}
                                  </p>
                                </div>

                                <div className="col-12 col-md-4">
                                  <h5 className="card-desc font-size-15">
                                    ID Number
                                  </h5>
                                  <p className="font-size-14">
                                    {show
                                      ? detail && detail.loan && detail.loan.idNumber
                                      : '***********'}{' '}
                                    <button
                                      className="btn btn-success btn-sm m-0 me-4"
                                      onClick={() => setShow(!show)}
                                    >
                                      <i
                                        className={`${show
                                          ? 'fal fa-eye-slash'
                                          : 'fal fa-eye'
                                          }`}
                                      ></i>
                                    </button>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="col-12 col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">Risk Intelligence Report</h5>

                        <p className="card-desc">
                          Swiftly confirm the credit score and history of both
                          individuals and businesses.
                        </p>

                        {loadingCheck ? (
                          <div
                            className="tailspin"
                            style={{ height: 'calc(50vh - 50px)' }}
                          >
                            <TailSpin
                              stroke="#98ff98"
                              strokeWidth="3"
                              color="#06bcee"
                            />
                          </div>
                        ) : (
                          <div className="">
                            <div className="card bg-soft-info">
                              <div className="card-body p-3">
                                <h5 className="card-title mb-0 font-size-16">
                                  Entity Information
                                </h5>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col md-12">
                                <h5 className="card-title mb-4 font-size-16">
                                  Personal Information
                                </h5>
                              </div>
                              <div className="col-12 col-md-6">
                                <h5 className="card-desc font-size-15">
                                  First Name:
                                </h5>
                                <p className="font-size-14">
                                  {result &&
                                    result.data &&
                                    result.data.entityInformation &&
                                    result.data.entityInformation
                                      .personalInformation &&
                                    result.data.entityInformation
                                      .personalInformation.firstName
                                    ? result.data.entityInformation
                                      .personalInformation.firstName
                                    : 'Null'}
                                </p>
                              </div>
                              <div className="col-12 col-md-6">
                                <h5 className="card-desc font-size-15">
                                  Last Name:
                                </h5>
                                <p className="font-size-14">
                                  {result &&
                                    result.data &&
                                    result.data.entityInformation &&
                                    result.data.entityInformation
                                      .personalInformation &&
                                    result.data.entityInformation
                                      .personalInformation.lastName
                                    ? result.data.entityInformation
                                      .personalInformation.lastName
                                    : 'Null'}
                                </p>
                              </div>

                              <div className="col-12 col-md-6">
                                <h5 className="card-desc font-size-15">
                                  ID Number:
                                </h5>
                                <p className="font-size-14">
                                  {result &&
                                    result.data &&
                                    result.data.entityInformation &&
                                    result.data.entityInformation
                                      .personalInformation &&
                                    result.data.entityInformation
                                      .personalInformation.idNumber
                                    ? result.data.entityInformation
                                      .personalInformation.idNumber
                                    : 'Null'}
                                </p>
                              </div>
                              <div className="col-12 col-md-6">
                                <h5 className="card-desc font-size-15">
                                  ID Expiry Date:
                                </h5>
                                <p className="font-size-14 text-uppercase">
                                  {result &&
                                    result.data &&
                                    result.data.entityInformation &&
                                    result.data.entityInformation
                                      .personalInformation &&
                                    result.data.entityInformation
                                      .personalInformation.idExpiryDate
                                    ? result.data.entityInformation
                                      .personalInformation.idExpiryDate
                                    : 'Null'}
                                </p>
                              </div>

                              <div className="col-12 col-md-6">
                                <h5 className="card-desc font-size-15">
                                  Is ID Verified
                                </h5>
                                <p className="font-size-14 text-capitalize">
                                  {result &&
                                    result.data &&
                                    result.data.entityInformation &&
                                    result.data.entityInformation
                                      .personalInformation &&
                                    result.data.entityInformation
                                      .personalInformation.isIdVerified
                                    ? result.data.entityInformation
                                      .personalInformation.isIdVerified
                                    : 'Null'}
                                </p>
                              </div>
                            </div>
                            <hr className="my-2 mb-3 mt-4" />
                            <div className="row">
                              <div className="col-12 col md-12">
                                <h5 className="card-title mb-4 font-size-16">
                                  Geographic Information
                                </h5>
                              </div>
                              <div className="col-12 col-md-6">
                                <h5 className="card-desc font-size-15">
                                  Country:
                                </h5>
                                <p className="font-size-14">
                                  {result &&
                                    result.data &&
                                    result.data.entityInformation &&
                                    result.data.entityInformation
                                      .geographicInformation &&
                                    result.data.entityInformation
                                      .geographicInformation.country
                                    ? result.data.entityInformation
                                      .geographicInformation.country
                                    : 'Null'}
                                </p>
                              </div>
                              <div className="col-12 col-md-6">
                                <h5 className="card-desc font-size-15">
                                  City:
                                </h5>
                                <p className="font-size-14 TRXT-">
                                  {result &&
                                    result.data &&
                                    result.data.entityInformation &&
                                    result.data.entityInformation
                                      .geographicInformation &&
                                    result.data.entityInformation
                                      .geographicInformation.city
                                    ? result.data.entityInformation
                                      .geographicInformation.city
                                    : 'Null'}
                                </p>
                              </div>

                              <div className="col-12 col-md-6">
                                <h5 className="card-desc font-size-15">
                                  Length of Stay:
                                </h5>
                                <p className="font-size-14">
                                  {result &&
                                    result.data &&
                                    result.data.entityInformation &&
                                    result.data.entityInformation
                                      .geographicInformation &&
                                    result.data.entityInformation
                                      .geographicInformation.lengthOfStay
                                    ? result.data.entityInformation
                                      .geographicInformation.lengthOfStay
                                    : 'Null'}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-lg-12'>
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title mb-2">
                          Loan Repayment Schedule
                        </h4>
                        <hr className="my-2 mb-4" />

                        {detail && detail.loan && detail.loan.repaymentSchedule &&
                          detail.loan.repaymentSchedule.length > 0
                          ? detail && detail.loan && detail.loan.repaymentSchedule.map((item) => (
                            <div className="card task-box">
                              <div
                                className="progress progress-sm animated-progess"
                                style={{ height: '3px' }}
                              >
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: '72%' }}
                                  aria-valuenow="72"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <div className="card-body">
                                <div className="float-end ms-2">
                                  <div>
                                    <p>
                                      <b>Due Date: </b>
                                      {moment(item.date).format('DD MMM YYYY')}
                                    </p>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <a href="#" className="">
                                    Repayment {item.month}/
                                    {detail && detail.loan && detail.loan.repaymentSchedule.length}
                                  </a>
                                </div>
                                <div className="row">
                                  <div className="col-md-2">
                                    <p className="mb-1 font-size-13">Month</p>
                                    <h5 className="font-size-14">
                                      Month {item.month}
                                    </h5>
                                  </div>
                                  <div className="col-md-2">
                                    <p className="mb-1 font-size-13">
                                      Principal Payment
                                    </p>
                                    <h5 className="font-size-14">
                                      {item && item.principal && `NGN ${formatNumber(item && item.principal)}`}
                                    </h5>
                                  </div>
                                  <div className="col-md-2">
                                    <p className="mb-1 font-size-13">
                                      Interest Payment
                                    </p>
                                    <h5 className="font-size-14">
                                      {item && item.interest && `NGN ${formatNumber(item && item.interest)}`}
                                    </h5>
                                  </div>
                                  <div className="col-md-2">
                                    <p className="mb-1 font-size-13">
                                      Total Repayment
                                    </p>
                                    <h5 className="font-size-14">
                                      {item && item.payment && `NGN ${formatNumber(item.payment)}`}
                                    </h5>
                                  </div>
                                  <div className="col-md-4 text-center">
                                    <p className="mb-1 font-size-13">
                                      Payment Status
                                    </p>
                                    <h5 className="font-size-14">
                                      <span
                                        className={`badge badge-soft-${item.status === 'Paid'
                                          ? 'success' : 'danger'
                                          } font-size-13`}
                                      >
                                        {item.status === 'Paid' ? "Paid" : "Not Paid"}
                                      </span>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                          :
                          <div className='' style={{ margin: '4em' }}>
                            <h5 className="card-title flex text-center" style={{}}>No Replayment Plan</h5>
                            <p className="card-desc text-center">No Replayment Plan</p>
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-6">
                    <div className="card">
                      <div className="card-body">
                        <label className="form-label" forhtml="message">
                          Status Message
                        </label>
                        <textarea
                          name="message"
                          id=""
                          className="form-control"
                          rows="10"
                          placeholder="Reason for Reject"
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-6">
                    <div className="card">
                      <div className="card-body">
                        <p>
                          Application Status:{' '}
                          <span
                            className={`badge badge-soft-${detail && detail.loan && detail.loan.status === 'Pending'
                              ? 'secondary'
                              : detail && detail.loan && detail.loan.status === 'Approved'
                                ? 'success'
                                : detail && detail.loan && detail.loan.status === 'Active'
                                  ? 'primary'
                                  : detail && detail.loan && detail.loan.status === 'Reviewing'
                                    ? 'warning'
                                    : detail && detail.loan && detail.loan.status === 'Complete'
                                      ? 'secondary'
                                      : detail && detail.loan && detail.loan.status === 'Reject'
                                        ? 'danger'
                                        : ''
                              } font-size-13`}
                          >
                            {detail && detail.loan && detail.loan.status}
                          </span>
                        </p>
                        {
                          detail && detail.loan &&
                            detail.loan.status === 'Approved' ? null : detail && detail.loan &&
                              detail.loan.status === 'Reject' ? null
                            : <div
                              className="d-flex flex"
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <div>
                                <label className="form-label card-radio-label me-3">
                                  <input
                                    type="radio"
                                    name="pay-method"
                                    id="pay-methodoption1"
                                    className="card-radio-input"
                                    value="Approved"
                                  />
                                  <div className="card-radio status-button text-center bg-success text-white">
                                    <p className="mb-2">
                                      <i className="fas fa-check font-size-24 align-middle"></i>
                                    </p>
                                    <p className="mb-1">Approved</p>
                                  </div>
                                </label>
                              </div>
                              <div>
                                <label className="form-label card-radio-label me-3">
                                  <input
                                    type="radio"
                                    name="pay-method"
                                    id="pay-methodoption3"
                                    className="card-radio-input"
                                    value="Pending"
                                  />
                                  <div className="card-radio status-button text-center bg-warning text-white">
                                    <p className="mb-2">
                                      <i className="fas fa-sync font-size-24 align-middle"></i>
                                    </p>
                                    <p className="mb-1">Pending</p>
                                  </div>
                                </label>
                              </div>
                              <div>
                                <label className="form-label card-radio-label me-3">
                                  <input
                                    type="radio"
                                    name="pay-method"
                                    id="pay-methodoption3"
                                    className="card-radio-input"
                                    value="Reviewing"
                                  />
                                  <div className="card-radio status-button text-center bg-info text-white">
                                    <p className="mb-2">
                                      <i className="fas fa-clipboard-check font-size-24 align-middle"></i>
                                    </p>
                                    <p className="mb-1">Reviewing</p>
                                  </div>
                                </label>
                              </div>
                              <div>
                                <label className="form-label card-radio-label me-3">
                                  <input
                                    type="radio"
                                    name="pay-method"
                                    id="pay-methodoption3"
                                    className="card-radio-input"
                                    value="Reject"
                                  />
                                  <div className="card-radio status-button text-center bg-danger text-white">
                                    <p className="mb-2">
                                      <i className="fas fa-times font-size-24 align-middle"></i>
                                    </p>
                                    <p className="mb-1">Reject</p>
                                  </div>
                                </label>
                              </div>
                            </div>
                        }

                        {
                          detail && detail.loan.status === 'Reject' && detail && detail.loan && detail.loan.rejectionReason ? (
                            <>
                              <p forhtml="loanOffer mb-0">
                                Reason for Rejection
                              </p>
                              <div className="card">
                                <div className="card-body bg-soft-danger b-4">
                                  <p className="">{detail && detail.loan && detail.loan.rejectionReason}</p>
                                </div>
                              </div>
                            </>
                          ) : null
                        }

                        {detail && detail.loan && !detail.loan.offerAmount ? (
                          <>
                            {status === 'Approved' ? (
                              <div className="mb-2 mt-4">
                                <label
                                  className="form-label"
                                  forhtml="loanOffer"
                                >
                                  Loan Application Offer:
                                </label>
                                <input
                                  id="loanOffer"
                                  name="loanOffer"
                                  type="number"
                                  className="form-control"
                                  onChange={(e) => setAmount(e.target.value)}
                                  placeholder="Loan Offer"
                                  required
                                />
                              </div>
                            ) : null}
                          </>
                        ) : null}

                        {detail && detail.loan &&
                          detail.loan.status === 'Approved' &&
                          detail.loan.offerAmount ? (
                          <div className="mb-1 mt-4">
                            <p forhtml="loanOffer mb-0">
                              Loan Application Offer:
                            </p>
                            <div className="card bg-soft-success">
                              <div className="card-body">
                                <h3 className="text-truncate mb-0">
                                  <b>
                                    {detail && detail.loan && detail.loan.offerAmount
                                      ? `NGN ${formatNumber(
                                        detail.loan.offerAmount,
                                      )}`
                                      : 'NGN 0.00'}
                                  </b>
                                </h3>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
