import {
    USER_GET_CLUSTER_CATEGORY_FAIL,
    USER_GET_CLUSTER_CATEGORY_REQUEST,
    USER_GET_CLUSTER_CATEGORY_SUCCESS,
    USER_GET_CLUSTER_LIST_FAIL,
    USER_GET_CLUSTER_LIST_REQUEST,
    USER_GET_CLUSTER_LIST_SUCCESS
} from "../../constants/authConstants/farmcluster/cluster"

export const userClusterListReducer = (state = { loading: true, clusters: [] }, action) => {
    switch (action.type) {
        case USER_GET_CLUSTER_LIST_REQUEST:
            return { loading: true }
        case USER_GET_CLUSTER_LIST_SUCCESS:
            return { loading: false, clusters: action.payload }
        case USER_GET_CLUSTER_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const userClusterCategoryListReducer = (state = { loading: true, categories: [] }, action) => {
    switch (action.type) {
        case USER_GET_CLUSTER_CATEGORY_REQUEST:
            return { loading: true }
        case USER_GET_CLUSTER_CATEGORY_SUCCESS:
            return { loading: false, categories: action.payload }
        case USER_GET_CLUSTER_CATEGORY_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}