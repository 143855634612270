import axios from 'axios'
import {
    UPDATE_LOAN_FAIL,
    UPDATE_LOAN_REQUEST,
    UPDATE_LOAN_SUCCESS,
} from '../../../constants/adminConstants/farmtrove/loanConstants'

export const updateLoan = (
    itemId,
    name,
    manufacturer,
    maxAmount,
    moratoriumPeriod,
    duration,
    interest,
    description,
    TandC,
) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_LOAN_REQUEST,
        payload: {
            itemId,
            name,
            manufacturer,
            maxAmount,
            moratoriumPeriod,
            duration,
            interest,
            description,
            TandC,
        },
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/admin/farmtrove/update-loan',
            {
                itemId,
                name,
                manufacturer,
                maxAmount,
                moratoriumPeriod,
                duration,
                interest,
                description,
                TandC,
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: UPDATE_LOAN_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({
            type: UPDATE_LOAN_FAIL,
            payload: message,
        })
    }
}
