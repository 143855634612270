import axios from 'axios'
import {
  CLAIM_DETAIL_FAIL,
  CLAIM_DETAIL_REQUEST,
  CLAIM_DETAIL_SUCCESS,
} from '../../../constants/adminConstants/benefitContants'

export const claimDetail = (claimID) => async (dispatch, getState) => {
  dispatch({ type: CLAIM_DETAIL_REQUEST, payload: claimID })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/claim-detail',
      { claimID },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: CLAIM_DETAIL_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: CLAIM_DETAIL_FAIL,
      payload: message,
    })
  }
}
