import {
  ADMIN_RESET_USER_PASSWORD_FAIL,
  ADMIN_RESET_USER_PASSWORD_REQUEST,
  ADMIN_RESET_USER_PASSWORD_RESET,
  ADMIN_RESET_USER_PASSWORD_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_RESET,
  UPDATE_USER_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
} from '../../../constants/adminConstants/usersContants'

export const usersListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true }
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload }
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return { loading: true }
    case UPDATE_USER_SUCCESS:
      return { loading: false, updated: action.payload, success: true }
    case UPDATE_USER_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_USER_RESET:
      return {}
    default:
      return state
  }
}

export const adminResetUserPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_RESET_USER_PASSWORD_REQUEST:
      return { loading: true }
    case ADMIN_RESET_USER_PASSWORD_SUCCESS:
      return { loading: false, reset: action.payload }
    case ADMIN_RESET_USER_PASSWORD_FAIL:
      return { loading: false, error: action.payload }
    case ADMIN_RESET_USER_PASSWORD_RESET:
      return {}
    default:
      return state
  }
}
