import axios from 'axios'
import {
    SERVICE_PRODUCT_LIST_FAIL,
    SERVICE_PRODUCT_LIST_REQUEST,
    SERVICE_PRODUCT_LIST_SUCCESS,
} from '../../../constants/serviceProviderConstants/productConstants'

export const listProducts = () => async (dispatch, getState) => {
    dispatch({ type: SERVICE_PRODUCT_LIST_REQUEST })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.get('/api/service/products', {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })
        dispatch({ type: SERVICE_PRODUCT_LIST_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: SERVICE_PRODUCT_LIST_FAIL, payload: message })
    }
}
