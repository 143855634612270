export const SERVICE_PROVIDERS_REQUEST = 'SERVICE_PROVIDERS_REQUEST'
export const SERVICE_PROVIDERS_SUCCESS = 'SERVICE_PROVIDERS_SUCCESS'
export const SERVICE_PROVIDERS_FAIL = 'SERVICE_PROVIDERS_FAIL'

export const ORGANISATION_SERVICE_CATEGORY_REQUEST = 'ORGANISATION_SERVICE_CATEGORY_REQUEST'
export const ORGANISATION_SERVICE_CATEGORY_SUCCESS = 'ORGANISATION_SERVICE_CATEGORY_SUCCESS'
export const ORGANISATION_SERVICE_CATEGORY_FAIL = 'ORGANISATION_SERVICE_CATEGORY_FAIL'

export const ORGANISATION_SERVICE_PROVIDER_CREATE_REQUEST = 'ORGANISATION_SERVICE_PROVIDER_CREATE_REQUEST'
export const ORGANISATION_SERVICE_PROVIDER_CREATE_SUCCESS = 'ORGANISATION_SERVICE_PROVIDER_CREATE_SUCCESS'
export const ORGANISATION_SERVICE_PROVIDER_CREATE_FAIL = 'ORGANISATION_SERVICE_PROVIDER_CREATE_FAIL'
export const ORGANISATION_SERVICE_PROVIDER_CREATE_RESET = 'ORGANISATION_SERVICE_PROVIDER_CREATE_RESET'

export const ORGANISATION_SERVICE_PROVIDER_UPDATE_REQUEST = 'ORGANISATION_SERVICE_PROVIDER_UPDATE_REQUEST'
export const ORGANISATION_SERVICE_PROVIDER_UPDATE_SUCCESS = 'ORGANISATION_SERVICE_PROVIDER_UPDATE_SUCCESS'
export const ORGANISATION_SERVICE_PROVIDER_UPDATE_FAIL = 'ORGANISATION_SERVICE_PROVIDER_UPDATE_FAIL'
export const ORGANISATION_SERVICE_PROVIDER_UPDATE_RESET = 'ORGANISATION_SERVICE_PROVIDER_UPDATE_RESET'