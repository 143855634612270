import axios from 'axios'
import {
    ADD_CLUSTER_REQUEST,
    ADD_CLUSTER_SUCCESS,
    ADD_CLUSTER_FAIL,
} from '../../../constants/adminConstants/farmcluster/clusterContants'

export const addCluster = (
    manufacturer, name, category, description
) => async (dispatch, getState) => {
    dispatch({
        type: ADD_CLUSTER_REQUEST,
        payload: {
            manufacturer,
            name,
            category,
            description,
        },
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/admin/farmcluster/add-cluster',
            {
                manufacturer,
                name,
                category,
                description,
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: ADD_CLUSTER_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: ADD_CLUSTER_FAIL, payload: message })
    }
}
