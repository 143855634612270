import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tagActivation } from '../../../actions/manufacturerActions/labelsActions/activateLabel'
import { LoadingBox, MessageBox } from '../../../components'
import { MANUFACTURER_ACTIVATE_TAG_RESET } from '../../../constants/manufacturerConstants/labelsConstants'
import { Header, LeftSidebar, RightSidebar, Footer } from '../../../partials'
import Modal from 'react-modal'

function ActivateLabelsPage() {
  const [tag, setTag] = useState('')
  const [setIsOpen] = React.useState(false)

  const activateTag = useSelector((state) => state.activateTag)
  const { loading, error, activate } = activateTag

  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(tagActivation(tag))
  }

  const resetHandler = (e) => {
    e.preventDefault()
    dispatch({ type: MANUFACTURER_ACTIVATE_TAG_RESET })
  }

  useEffect(() => {
    if (activate) {
      setTimeout(() => {
        dispatch({ type: MANUFACTURER_ACTIVATE_TAG_RESET })
      }, 500100)
    }
  }, [dispatch, activate])

  const customStyles = {
    content: {
      width: '28%',
      margin: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },

    modalConfirm: {
      color: '#434e65',
      maxWidth: '100%',
    },
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Activate Tag</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Tag Management</a>
                      </li>
                      <li className="breadcrumb-item active">Activate Tags </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {error ? <MessageBox variant="danger">{error}</MessageBox> : null}
            {activate ? (
              <div>
                <Modal
                  isOpen
                  onRequestClose={() => setIsOpen(false)}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div
                    className="modal-header-false"
                    style={{
                      background: '#47c9a2',
                      borderBottom: 'none',
                      position: 'relative',
                      textAlign: 'center',
                      margin: '-20px -20px 0',
                      borderRadius: '5px 5px 0 0',
                      padding: '35px',
                    }}
                  >
                    <div
                      className="icon-box"
                      style={{
                        color: '#fff',
                        textAlign: 'center',
                      }}
                    >
                      <center>
                        <i
                          className="font-circle fa fa-check"
                          style={{
                            borderRadius: '60%',
                            display: 'inline-block',
                            zIndex: 9,
                            border: '5px solid #fff',
                            padding: '20px 20px 20px 20px',
                            fontSize: '70px',
                            margin: '-5px 0 0 -5px',
                            textAlign: 'center',
                          }}
                        ></i>
                      </center>
                    </div>
                  </div>
                  <div className="modal-body text-center">
                    <p className="model-text">
                      Hello <br />
                    </p>

                    <div>
                      <center>
                        <h6
                          style={{
                            textAlign: 'center',
                            fontSize: '19px',
                            margin: '10px 0',
                          }}
                        >
                          {activate.message}
                        </h6>
                      </center>
                    </div>
                    <button
                      className="btn btn-success"
                      onClick={resetHandler}
                      style={{
                        color: '#fff',
                        borderRadius: '4px',
                        textDecoration: 'none',
                        transition: 'all 0.4s',
                        lineHeight: 'normal',
                        borderRadius: '30px',
                        marginTop: '10px',
                        padding: '6px 20px 10px ',
                        border: 'none',
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </Modal>
              </div>
            ) : null}

            <div className="card">
              <div className="card-body ">
                <div
                  id="container-fluid"
                  style={{
                    minHeight: '75vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src="assets/images/blue_tag.png" style={{ width: ' 120px' }} />
                  <small className="p-2 font-size-12">
                    Please input the code on the blue tag (Image
                    above)
                  </small>

                  <div className="input-container mt-3">
                    {loading ? (
                      <LoadingBox />
                    ) : (
                      <div className="text-center">
                        <div className="container__item">
                          <form className="form" onSubmit={handleSubmit}>
                            <input
                              type="number"
                              className="form-control form__field"
                              style={{ minWidth: '250px'}}
                              placeholder="Tag Number (Blue Tag)"
                              onChange={(e) => setTag(e.target.value)}
                              required
                            />
                            <button
                              type="submit"
                              className="btn btn--primary mt-3 center uppercase"
                            >
                              Activate
                            </button>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default ActivateLabelsPage
