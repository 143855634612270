import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
import farmsured from '../../farmsured.png'
import { formatNumber } from '../functions'
import moment from 'moment'

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: 'column',
  },

  clearfix: {
    content: '',
    display: 'table',
    clear: 'both',
  },

  logo: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '30%',
  },

  company: {
    float: 'right',
    textAlign: 'right',
  },

  details: {
    marginBottom: 50,
  },

  client: {
    paddingLeft: 6,
    borderLeft: '6 solid ',
    borderLeftColor: 'green',
    borderLeftWidth: 4,
    float: 'left',
    color: '#777777',
  },

  container: {
    flexDirection: 'row',
    borderBottomColor: '#3778C2',
    backgroundColor: '#3778C2',
    color: '#fff',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  name: {
    flexDirection: 'row',
    marginTop: 36,
    justifyContent: 'flex-end',
    fontSize: '13px',
    fontWeight: 'normal',
    margin: 0,
  },

  invoice: {
    float: 'right',
    textAlign: 'right',
    color: 'green',
    fontSize: 13,
    lineHeight: 1,
    fontWeight: 'normal',
    margin: '0  0 10 0',
  },

  invoiceNum: {
    float: 'right',
    textAlign: 'right',
    color: 'green',
    fontSize: 12,
    fontWeight: 'normal',
    margin: '10 0 7 0',
  },

  row: {
    flexDirection: 'row',
    borderBottomColor: '#3778C2',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
  },

  description: {
    width: '60%',
    textAlign: 'left',
    borderRightColor: '#3778C2',
    borderRightWidth: 1,
    paddingLeft: 8,
  },

  qty: {
    width: '10%',
    borderRightColor: '#3778C2',
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },

  rate: {
    width: '15%',
    borderRightColor: '#3778C2',
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },

  amount: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },

  date: {
    fontSize: 12,
    color: '#777777',
    float: 'right',
    textAlign: 'right',
  },

  costOfTag: {
    flexDirection: 'row',
    float: 'left',
    textAlign: 'right',
    fontSize: '12px',
    fontWeight: '500',
    marginTop: 6,
  },

  costPrice: {
    paddingRight: 1000,
    flexDirection: 'row',
    float: 'left',
  },

  row1: {
    marginTop: '10px',
    flexDirection: 'row',
    alignItems: 'center',
    height: 25,
    fontSize: 12,
    fontStyle: 'bold',
  },

  rows: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 25,
    fontSize: 12,
    fontStyle: 'bold',
  },

  description1: {
    width: '85%',
    textAlign: 'right',
    paddingRight: 8,
  },

  descriptionTotal: {
    width: '85%',
    textAlign: 'right',
    paddingRight: 8,
    fontWeight: 900,
    fontSize: 16,
    color: 'green',
  },

  totalInNGN: {
    width: '85%',
    textAlign: 'right',
    paddingRight: 8,
    fontWeight: 900,
    fontSize: 13,
    color: 'green',
    paddingTop: 3,
  },

  grandTotal: {
    width: '30%',
    textAlign: 'right',
    paddingRight: 8,
    fontWeight: 900,
    fontSize: 16,
    color: 'green',
  },

  NGNTotal: {
    width: '30%',
    textAlign: 'right',
    paddingRight: 8,
    fontWeight: 900,
    fontSize: 13,
    color: 'green',
    borderTop: '2px solid green',
    paddingTop: 3,
  },

  total1: {
    width: '30%',
    textAlign: 'right',
    paddingRight: 8,
  },

  notices: {
    paddingLeft: 6,
    borderLeft: '6 solid ',
    borderLeftColor: 'green',
    borderLeftWidth: 4,
    float: 'left',
    color: '#777777',
    marginTop: 60,
  },

  notice: {
    marginTop: 10,
    fontSize: 10,
  },

  bold: {
    fontSize: 12,
    fontWeight: 900,
  },

  footer: {
    color: '#777777',
    width: 100,
    height: 30,
    position: 'absolute',
    bottom: 0,
    borderTop: '1px solid #AAAAAA',
    padding: '8 0',
    textAlign: 'center',
  },

  footerNote: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
})

const Invoice = ({
  detail,
  shippingFee,
  costOfRoll,
  subTotal,
  costPerRoll,
  accountName,
  accountNumber,
  bankName,
  dollarRate,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section1}>
        <View style={styles.clearfix}>
          <View id="logo">
            <View style={styles.company}>
              <Image source={farmsured} style={styles.logo} />

              <Text style={styles.name}>
                Agribusiness Dynamics Technology Limited
              </Text>
              <Text style={styles.address}>
                14, Alaafin Ave. Oluyole Estate, Off Ring Road, Ibadan, Oyo
                State.
              </Text>
              <Text>(+234) 907 000 0770</Text>
              <View>
                <Text>team@agdyna.com</Text>
              </View>
            </View>
          </View>
        </View>
        <View>
          <View style={styles.clearfix}>
            <View style={styles.client}>
              <Text style={styles.to}>INVOICE TO:</Text>
              <Text style={styles.name}>{detail.manufacturer.name}</Text>
              <Text style={styles.address}>
                {detail.manufacturer.address}, {detail.manufacturer.city},
                {detail.manufacturer.state}
              </Text>
              <Text style={styles.address}>
                {detail.manufacturer.contactPerson}
              </Text>
              <Text style={styles.address}>
                {`0${detail.manufacturer.phone}`}
              </Text>
              <Text style={styles.email}>
                <a href={`mailto:${detail.manufacturer.email}`}>
                  {detail.manufacturer.email}
                </a>
              </Text>

              <Text style={{ marginTop: 5 }}>Product</Text>
              <Text>{detail.product ? detail.product.name : null}</Text>
            </View>
          </View>
          <View style={styles.invoice}>
            <Text style={styles.invoiceNum}>INVOICE: {detail.orderID}</Text>
            <Text style={styles.date}>
              Date of Invoice: {moment().format('Do MMMM YYYY')}
            </Text>
          </View>
          <View style={styles.tableContainer}>
            <View style={styles.container}>
              <Text style={styles.description}>Item Description</Text>
              <Text style={styles.qty}>Qty</Text>
              <Text style={styles.rate}>Price</Text>
              <Text style={styles.amount}>Total</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.description}>
                Farmsured Day Old Chick Protection Package
              </Text>
              <Text style={styles.qty}>
                {detail.amount ? formatNumber(detail.amount) : null}
              </Text>
              <Text style={styles.rate}>{formatNumber(costPerRoll) || 0}</Text>
              <Text style={styles.amount}>
                {detail.amount ? formatNumber(detail.amount) : null}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.description}>
                Farmsured DOCP Covers Product(s)
              </Text>
              <Text style={styles.qty}>
                {detail.amount ? formatNumber(detail.amount * 1000) : null}
              </Text>
              <Text style={styles.rate}>0</Text>
              <Text style={styles.amount}>0</Text>
            </View>
          </View>
          <View style={styles.row1}>
            <Text style={styles.description1}>
              Cost Per Farmsured Day Old Chick Protection Package
            </Text>
            <Text style={styles.total1}> $ {costOfRoll}</Text>
          </View>

          <View style={styles.rows}>
            <Text style={styles.description1}>Shipping</Text>
            <Text style={styles.total1}> $ {shippingFee || 0}</Text>
          </View>

          <View style={styles.rows}>
            <Text style={styles.description1}>Subtotal</Text>
            <Text style={styles.total1}> $ {formatNumber(subTotal) || 0}</Text>
          </View>

          <View style={styles.rows}>
            <Text style={styles.descriptionTotal}>Grand Total</Text>
            <Text style={styles.grandTotal}>
              $ {formatNumber(subTotal) || 0}
            </Text>
          </View>

          <View style={styles.rows}>
            <Text style={styles.totalInNGN}>
              Total in NGN (Currency Convertion Rate: {dollarRate})
            </Text>
            <Text style={styles.NGNTotal}>
              NGN {formatNumber(parseInt(subTotal) * parseInt(dollarRate)) || 0}
            </Text>
          </View>

          <View style={styles.notices}>
            <Text style={styles.bold}>Account Details</Text>
            <Text>
              <Text style={styles.bold}>Bank Name:</Text> {bankName}
            </Text>
            <Text>
              <Text style={styles.bold}>Account Name:</Text> {accountName}
            </Text>
            <Text>
              <Text style={styles.bold}>Account Number:</Text> {accountNumber}
            </Text>
            <br />
            <Text style={styles.notice}>
              NOTICE: Kindly send an email to team@agdyna.com with proof of
              payment upon completion of payment
            </Text>

            <Text style={{ marginTop: '10px' }}>Thank You!</Text>
          </View>
        </View>
      </View>

      <Text style={styles.footerNote} fixed>
        Farmsured™ is a product of AgDyna®
      </Text>
    </Page>
  </Document>
)

export default Invoice
