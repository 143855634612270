import axios from 'axios'
import {
    USER_GET_CLUSTER_CATEGORY_FAIL,
    USER_GET_CLUSTER_CATEGORY_REQUEST,
    USER_GET_CLUSTER_CATEGORY_SUCCESS,
} from '../../../constants/authConstants/farmcluster/cluster'


export const allClusterCategory = () => async (dispatch) => {
    dispatch({
        type: USER_GET_CLUSTER_CATEGORY_REQUEST,
    })
    try {
        const { data } = await axios.get('/api/farmcluster/all-cluster-category')
        dispatch({ type: USER_GET_CLUSTER_CATEGORY_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: USER_GET_CLUSTER_CATEGORY_FAIL, payload: message })
    }
}