import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { MDBDataTableV5 } from 'mdbreact'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { userFarmCycleDetail } from '../../../actions/adminActions/farmcycleActions/userFarmCycleDetail'
import moment from 'moment'
import { LoadingBox, MessageBox } from '../../../components'
import { checkStatus } from '../../../components/functions'
import LinearProgress from '@material-ui/core/LinearProgress'

function UserFarmCycleDetailPage(props) {
  const itemId = props.match.params.id

  const [datatable, setDatatable] = useState({})
  const [packageId, setPackageId] = useState('')

  const userfarmcycle = useSelector((state) => state.userfarmcycle)
  const { loading, error, detail } = userfarmcycle

  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    if (location.state.itemId) {
      setPackageId(location.state.itemId)
    }
  }, [location])

  useEffect(() => {
    if (itemId && packageId) dispatch(userFarmCycleDetail(itemId, packageId))
  }, [dispatch, itemId, packageId])

  useEffect(() => {
    if (detail && detail.result) {
      setDatatable({
        columns: [
          {
            label: 'Date Created',
            field: 'date',
            width: 50,
          },
          {
            label: 'Farm Cycle Name',
            field: 'name',
            width: 100,
          },
          {
            label: 'Package',
            field: 'package',
            width: 100,
          },
          {
            label: 'Point',
            field: 'point',
          },
          {
            label: 'Progress',
            field: 'chart',
            width: 200,
          },
          {
            label: 'Start Date',
            field: 'startDate',
            width: 100,
          },
          {
            label: 'Status',
            field: 'status',
            width: 50,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: detail.result.map((item) => ({
          date: moment(item.create_at),
          name: item.name,
          package:
            item.packageId && item.packageId.packageName
              ? item.packageId.packageName
              : '',
          point: item.point,
          chart: (
            <LinearProgress
              variant="determinate"
              className="mt-2 p-1"
              color="primary"
              value={
                item.point &&
                item.productCategory.expectedPoint &&
                item.packageNum
                  ? (item.point /
                      (item.productCategory.expectedPoint * item.packageNum)) *
                    100
                  : 0
              }
            />
          ),
          status: checkStatus(item.startDate, item.endDate),
          startDate: moment(item.startDate).format('DD MMM YYYY'),
          date: moment(item.createdAt).format('Do MMM YYYY, h:mm a'),
          action: (
            <a
              href={`/farm-cycle/${item._id}`}
              style={{ marginRight: '15px' }}
              className="btn btn-sm btn-success"
              data-bs-toggle="tooltip"
              data-placement="top"
              title="Manage"
            >
              Manage
            </a>
          ),
        })),
      })
    }
  }, [detail])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">User Farm Cycle Detail</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farm Cycle</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Farm Cycle Detail
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {error ? <MessageBox variant="danger">{error}</MessageBox> : null}
            {loading ? (
              <LoadingBox />
            ) : (
              <div>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={
                            detail && detail.user && detail.user.image
                              ? detail.user.image
                              : 'assets/images/farmsured_icon.png'
                          }
                          alt="logo-sm-light"
                          className="avatar-xs"
                          style={{
                            width: '5rem',
                            height: '5rem',
                            borderRadius: '50%',
                          }}
                        />
                      </div>
                      <div className="flex-1">
                        <h5>{detail && detail.user && detail.user.phone}</h5>
                        <h6 className="text-capitalize">
                          {detail && detail.user && detail.user.firstName}{' '}
                          {detail && detail.user && detail.user.lastName}
                        </h6>

                        <p className="card-desc mb-0 pb-0">
                          {detail && detail.user && detail.user.email
                            ? detail.user.email
                            : 'Not FIlled Yet'}
                        </p>

                        <p className="pb-0 mb-0 ">
                          {`${
                            detail && detail.user && detail.user.lga
                              ? detail.user.lga
                              : null
                          }, ${
                            detail && detail.user && detail.user.state
                              ? detail.user.state
                              : null
                          }`}
                        </p>

                        <span
                          className={`badge badge-soft-${
                            detail && detail.user && detail.user.status
                              ? 'success'
                              : 'danger'
                          }`}
                        >
                          {detail && detail.user && detail.user.status
                            ? 'Active'
                            : 'Inactive'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div
                      className="card"
                      style={{
                        borderRadius: '15px',
                        background: '#e0ffd7',
                        color: '#fff',
                      }}
                    >
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-1 overflow-hidden">
                            <div className="text-primary mb-0">
                              <i className="fas fa-coins font-size-24"></i>
                            </div>
                            <h4
                              className="center"
                              style={{
                                fontSize: '38px',
                                fontWeight: 700,
                                marginTop: '11px',
                                height: 50,
                                color: 'black',
                              }}
                            >
                              {detail && detail.user ? detail.user.points : 0}
                            </h4>
                            <p
                              className="float-end text-truncate font-size-14 mb-2"
                              style={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: 'black',
                              }}
                            >
                              Compliance Points
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive-xl">
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 10, 20, 25, 50]}
                            entries={10}
                            pagesAmount={5}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default UserFarmCycleDetailPage
