import axios from 'axios'
import {
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
} from '../../constants/authConstants/ProfileContants'

export const profileDetail = () => async (dispatch, getState) => {
  dispatch({ type: USER_PROFILE_REQUEST })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.get('/api/user-profile', {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    })
    dispatch({ type: USER_PROFILE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: USER_PROFILE_FAIL, payload: message })
  }
}

export const update = (user) => async (dispatch, getState) => {
  const {
    userSignin: { userInfo },
  } = getState()
  dispatch({
    type: USER_UPDATE_REQUEST,
    payload: user,
  })
  try {
    const { data } = await axios.put(
      '/api/update-user',
      { user },
      {
        headers: {
          Authorization: 'Bearer ' + userInfo.token,
        },
      },
    )
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: USER_UPDATE_FAIL, payload: message })
  }
}
