import {
  MANUFACTURER_ACTIVATE_TAG_FAIL,
  MANUFACTURER_ACTIVATE_TAG_REQUEST,
  MANUFACTURER_ACTIVATE_TAG_RESET,
  MANUFACTURER_ACTIVATE_TAG_SUCCESS,
} from '../../../constants/manufacturerConstants/labelsConstants'

export const manufacturerActivateTagReducer = (state = {}, action) => {
  switch (action.type) {
    case MANUFACTURER_ACTIVATE_TAG_REQUEST:
      return { loading: true }
    case MANUFACTURER_ACTIVATE_TAG_SUCCESS:
      return { loading: false, activate: action.payload }
    case MANUFACTURER_ACTIVATE_TAG_FAIL:
      return { loading: false, error: action.payload }
    case MANUFACTURER_ACTIVATE_TAG_RESET:
      return {}
    default:
      return state
  }
}
