import {
  ALL_ENTERPRISE_FAIL,
  ALL_ENTERPRISE_REQUEST,
  ALL_ENTERPRISE_SUCCESS,
} from '../../../constants/farmerConstants/farmtrove/enterpriseConstants'

export const enterpriseListReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_ENTERPRISE_REQUEST:
      return { loading: true }
    case ALL_ENTERPRISE_SUCCESS:
      return { loading: false, enterprises: action.payload }
    case ALL_ENTERPRISE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
