import axios from 'axios'
import {
  EXCHANGE_TOKEN_FAIL,
  EXCHANGE_TOKEN_REQUEST,
  EXCHANGE_TOKEN_SUCCESS,
} from '../../../constants/farmerConstants/farmtrove/accountConstants'

export const exchangeToken = (codeID) => async (dispatch, getState) => {
  dispatch({
    type: EXCHANGE_TOKEN_REQUEST,
    payload: codeID,
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/farmer/accounts/exchange-token',
      { codeID },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: EXCHANGE_TOKEN_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: EXCHANGE_TOKEN_FAIL, payload: message })
  }
}
