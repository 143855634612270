import axios from 'axios'
import {
  BATCH_LIST_REQUEST,
  BATCH_LIST_SUCCESS,
  BATCH_LIST_FAIL,
} from '../../../constants/adminConstants/TagsConstants'

// List of all tag batch on the system
export const getBatch = () => async (dispatch, getState) => {
  dispatch({ type: BATCH_LIST_REQUEST })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.get('/api/admin/batchs', {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    })
    dispatch({ type: BATCH_LIST_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: BATCH_LIST_FAIL, payload: message })
  }
}
