import axios from 'axios'
import {
  BENEFIT_CATEGORY_DELETE_FAIL,
  BENEFIT_CATEGORY_DELETE_REQUEST,
  BENEFIT_CATEGORY_DELETE_SUCCESS,

} from '../../../constants/adminConstants/benefitContants'

export const deletebenefitcategory = (categoryId) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: BENEFIT_CATEGORY_DELETE_REQUEST, payload: categoryId })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.delete(
      `/api/admin/benefit-category/${categoryId}`,
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      },
    )
    dispatch({ type: BENEFIT_CATEGORY_DELETE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: BENEFIT_CATEGORY_DELETE_FAIL, payload: message })
  }
}
