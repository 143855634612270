import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBox, MessageBox } from '../../../components'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { listUsers } from '../../../actions/adminActions/usersActions/allUser'
import { sendMessage } from '../../../actions/adminActions/notificationActions/sendMessage'
import { SEND_MESSAGE_RESET } from '../../../constants/adminConstants/notificationConstants'
import { deleteMessage } from '../../../actions/adminActions/notificationActions/deleteMessage'
import { inbox, outbox } from '../../../components/functions'
import Dialog from '@material-ui/core/Dialog'

export default function InboxPage() {
  const [receiver, setReceiver] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [open, setOpen] = useState(false)

  const today = moment().format('DD-MM-YYYY')

  const adminListNotification = useSelector(
    (state) => state.adminListNotification,
  )
  const { loading, error, sentMessages } = adminListNotification

  const usersList = useSelector((state) => state.usersList)
  const { loading: loadingUsers, error: userError, users } = usersList

  const sendNotificaiton = useSelector((state) => state.sendNotificaiton)
  const {
    loading: MessageLoading,
    error: messageError,
    notification,
  } = sendNotificaiton

  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(sendMessage(receiver, subject, message))
  }

  const handleDelete = (item) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteMessage(item._id))
    }
  }

  const customStyles = {
    content: {
      width: '400px',
      margin: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
  }

  useEffect(() => {
    dispatch(listUsers())

    if (notification) {
      setTimeout(() => {
        setOpen(false)
        dispatch({ type: SEND_MESSAGE_RESET })
        window.location.reload()
      }, 2500)
    }
  }, [dispatch, notification])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Inbox</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Messages</a>
                      </li>
                      <li className="breadcrumb-item active">Inbox</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {userError ? (
              <MessageBox variant="danger">{userError}</MessageBox>
            ) : null}
            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="email-leftbar card">
                    <button
                      type="button"
                      className="btn btn-danger btn-block waves-effect waves-light"
                      data-bs-toggle="modal"
                      data-bs-target="#composemodal"
                      onClick={() => setOpen(true)}
                    >
                      Compose
                    </button>
                    <div className="mail-list mt-4">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <Link
                          className="nav-link mb-2 active"
                          id="v-pills-home-tab"
                          data-bs-toggle="pill"
                          to="#v-pills-home"
                          role="tab"
                          aria-controls="v-pills-home"
                          aria-selected="true"
                        >
                          <i className="mdi mdi-email-outline me-2"></i> Inbox{' '}
                          {/* <span className="ms-1 float-end">(18)</span> */}
                        </Link>
                        <Link
                          className="nav-link mb-2"
                          id="v-pills-profile-tab"
                          data-bs-toggle="pill"
                          to="#v-pills-profile"
                          role="tab"
                          aria-controls="v-pills-profile"
                          aria-selected="false"
                        >
                          <i className="mdi mdi-email-check-outline me-2"></i>
                          Sent Mail
                        </Link>
                      </div>
                    </div>

                    <h6 className="mt-4">Chat</h6>

                    <div className="mt-2">
                      <a href="#" className="d-flex">
                        <img
                          className="me-3 rounded-circle"
                          src="assets/images/users/avatar-2.jpg"
                          alt="Generic placeholder image"
                          height="36"
                        />
                        <div className="flex-1 chat-user-box overflow-hidden">
                          <p className="user-title m-0">Scott Median</p>
                          <p className="text-muted text-truncate">Hello</p>
                        </div>
                      </a>

                      <a href="#" className="d-flex">
                        <img
                          className="me-3 rounded-circle"
                          src="assets/images/users/avatar-3.jpg"
                          alt="Generic placeholder image"
                          height="36"
                        />
                        <div className="flex-1 chat-user-box overflow-hidden">
                          <p className="user-title m-0">Julian Rosa</p>
                          <p className="text-muted text-truncate">
                            What about our next..
                          </p>
                        </div>
                      </a>

                      <a href="#" className="d-flex">
                        <img
                          className="me-3 rounded-circle"
                          src="assets/images/users/avatar-4.jpg"
                          alt="Generic placeholder image"
                          height="36"
                        />
                        <div className="flex-1 chat-user-box overflow-hidden">
                          <p className="user-title m-0">David Medina</p>
                          <p className="text-muted text-truncate">
                            Yeah everything is fine
                          </p>
                        </div>
                      </a>

                      <a href="#" className="d-flex">
                        <img
                          className="me-3 rounded-circle"
                          src="assets/images/users/avatar-6.jpg"
                          alt="Generic placeholder image"
                          height="36"
                        />
                        <div className="flex-1 chat-user-box overflow-hidden">
                          <p className="user-title m-0">Jay Baker</p>
                          <p className="text-muted text-truncate">
                            Wow that's great
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className="email-rightbar mb-3">
                    <div className="card">
                      <div
                        className="tab-content text-muted mt-4 mt-md-0"
                        id="v-pills-tabContent"
                      >
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-home"
                          role="tabpanel"
                          aria-labelledby="v-pills-home-tab"
                        >
                          <div className="btn-toolbar p-3" role="toolbar">
                            <div className="btn-group me-2 mb-2 mb-sm-0">
                              <h6 className="card-title">All Inbox</h6>
                            </div>
                          </div>
                          <ul className="message-list">
                            {sentMessages.length > 0 &&
                              (inbox(sentMessages).length > 0 ? (
                                inbox(sentMessages).map((item) => (
                                  <li
                                    className={
                                      item.is_read === false ? 'unread' : ''
                                    }
                                    key={item._id}
                                  >
                                    <div className="col-mail col-mail-1">
                                      <div className=" ">
                                        <a
                                          href="javascript:void(0);"
                                          className="text-danger"
                                          data-bs-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete"
                                          onClick={() => handleDelete(item)}
                                        >
                                          <i className="fas fa-trash delete-mail"></i>
                                        </a>
                                      </div>
                                      <a
                                        href={`/message/${item._id}`}
                                        className="title"
                                      >
                                        {item.sender &&
                                        item.sender.account === 'farmer'
                                          ? `${item.sender.firstName} ${item.sender.lastName}`
                                          : item.sender &&
                                            item.sender.account ===
                                              'manufacturer'
                                          ? `${item.sender.company.name}`
                                          : null}
                                      </a>
                                    </div>
                                    <div className="col-mail col-mail-2">
                                      <a
                                        href={`/message/${item._id}`}
                                        className="subject"
                                      >
                                        {item.subject} –{' '}
                                        <span className="teaser">
                                          {item.message}
                                        </span>
                                      </a>
                                      <div className="date">
                                        {moment(item.created_at).format(
                                          'DD-MM-YYYY',
                                        ) === today
                                          ? moment(item.created_at).format(
                                              'h:mm a',
                                            )
                                          : moment(item.created_at).format(
                                              'MMM D',
                                            )}
                                      </div>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <div className="center">No Message</div>
                              ))}
                          </ul>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-profile"
                          role="tabpanel"
                          aria-labelledby="v-pills-profile-tab"
                        >
                          <div className="btn-toolbar p-3" role="toolbar">
                            <div className="btn-group me-2 mb-2 mb-sm-0">
                              <h6 className="card-title">All Sent </h6>
                            </div>
                          </div>
                          <ul className="message-list">
                            {sentMessages.length > 0 &&
                              outbox(sentMessages).map((item) => (
                                <li>
                                  <div className="col-mail col-mail-1">
                                    <div className=" ">
                                      <a
                                        href="javascript:void(0);"
                                        className="text-danger"
                                        data-bs-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete"
                                        onClick={() => handleDelete(item)}
                                      >
                                        <i className="fas fa-trash delete-mail"></i>
                                      </a>
                                    </div>
                                    <a
                                      href={`/message/${item._id}`}
                                      className="title"
                                    >
                                      {item.receiver &&
                                      item.receiver.account === 'farmer'
                                        ? item.receiver
                                          ? `${item.receiver.firstName} ${item.receiver.lastName}`
                                          : null
                                        : item.receiver &&
                                          item.receiver.account ===
                                            'manufacturer'
                                        ? `${item.receiver.company.name}`
                                        : null}
                                    </a>
                                  </div>
                                  <div className="col-mail col-mail-2">
                                    <a
                                      href={`/message/${item._id}`}
                                      className="subject"
                                    >
                                      {item.subject} –{' '}
                                      <span className="teaser">
                                        {item.message}
                                      </span>
                                    </a>
                                    <div className="date">
                                      {moment(item.created_at).format(
                                        'DD-MM-YYYY',
                                      ) === today
                                        ? moment(item.created_at).format(
                                            'h:mm a',
                                          )
                                        : moment(item.created_at).format(
                                            'MMM D',
                                          )}
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="md"
        >
          {MessageLoading ? (
            <LoadingBox />
          ) : (
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="composemodalTitle">
                  New Message
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setOpen(false)}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  {notification ? (
                    <MessageBox variant="success">
                      {notification.message}
                    </MessageBox>
                  ) : messageError ? (
                    <MessageBox variant="danger">{messageError}</MessageBox>
                  ) : null}
                  <div className="mb-3">
                    {loadingUsers
                      ? 'Loading...'
                      : users
                      ? users.length > 0 && (
                          <Select
                            onChange={(opt) => setReceiver(opt.value)}
                            placeholder="To"
                            required
                            options={users.map((opt) => ({
                              label: opt.phone,
                              value: opt._id,
                            }))}
                          />
                        )
                      : null}
                  </div>

                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <textarea
                      className="form-control message"
                      name="message"
                      placeholder="Message"
                      required
                      rows={10}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Send <i className="fab fa-telegram-plane ms-1"></i>
                  </button>
                </div>
              </form>
            </div>
          )}
        </Dialog>
      </div>
      <Footer />
    </div>
  )
}
