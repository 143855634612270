import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { adminReadMessage } from '../../../actions/adminActions/notificationActions/adminReadMessage'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'

export default function ReadEmailPage(props) {
  const messageId = props.match.params.id

  const readMessage = useSelector((state) => state.readMessage)
  const { loading, error, message } = readMessage

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(adminReadMessage(messageId))
  }, [dispatch])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Read Email</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Email</a>
                      </li>
                      <li className="breadcrumb-item active">Read Email</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <LoadingBox />
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <div className="row">
              <div className="col-12">
                <div className="email mb-3">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="font-size-20 mb-4">
                        <b>
                          {message && message.subject ? message.subject : null}
                        </b>
                      </h4>
                      <div className="d-flex mb-4">
                        <img
                          className="me-3 rounded-circle avatar-sm"
                          src="assets/images/default-user.png"
                          alt="user image"
                        />
                        <div className="flex-1">
                          {message && message.receiverRole === 'admin' ? (
                            <div className="">
                              <h5 className="font-size-14 my-1">
                                {message &&
                                message.sender &&
                                message.sender.account === 'farmer'
                                  ? `${message.sender.firstName} ${message.sender.lastName}`
                                  : null}
                                {message &&
                                message.sender &&
                                message.sender.account === 'manufacturer'
                                  ? message.sender.company
                                    ? message.sender.company.name
                                    : null
                                  : null}
                              </h5>
                              <small className="text-muted">
                                {message &&
                                message.sender &&
                                message.sender.account === 'farmer'
                                  ? message.sender.email
                                  : null}

                                {message &&
                                message.sender &&
                                message.sender.account === 'manufacturer'
                                  ? message.sender.company
                                    ? message.sender.company.email
                                    : null
                                  : null}
                              </small>
                            </div>
                          ) : (
                            <div className="">
                              <h5 className="font-size-14 my-1">
                                {message &&
                                message.sender &&
                                message.sender.account === 'admin'
                                  ? `${message.sender.firstName} <${message.sender.email}>`
                                  : null}
                              </h5>
                              <small className="text-muted">
                                to{' '}
                                {message &&
                                message.sender &&
                                message.sender.account === 'admin' &&
                                message.receiver.account === 'manufacturer'
                                  ? message.receiver.email
                                  : null}
                                {message &&
                                message.sender &&
                                message.sender.account === 'admin' &&
                                message.receiver.account === 'farmer'
                                  ? message.receiver.phone
                                  : null}
                              </small>
                            </div>
                          )}
                        </div>
                        <div className="float-end">
                          <span className="date font-size-12">
                            {message && message.created_at
                              ? moment(message.created_at).format(
                                  'DD MMM YYYY, h:mm a',
                                )
                              : null}
                          </span>
                        </div>
                      </div>

                      <p>
                        {message && message.message ? message.message : null}
                      </p>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}
