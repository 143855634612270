import {
    FARMER_PRODUCT_ORDERS_FAIL,
    FARMER_PRODUCT_ORDERS_REQUEST,
    FARMER_PRODUCT_ORDERS_SUCCESS,
    SERVICE_PROVIDER_ALL_ORDER_FAIL,
    SERVICE_PROVIDER_ALL_ORDER_REQUEST,
    SERVICE_PROVIDER_ALL_ORDER_SUCCESS,
    SERVICE_PROVIDER_PLACE_ORDER_FAIL,
    SERVICE_PROVIDER_PLACE_ORDER_REQUEST,
    SERVICE_PROVIDER_PLACE_ORDER_RESET,
    SERVICE_PROVIDER_PLACE_ORDER_SUCCESS,
    UPDATE_FARMER_PRODUCT_ORDER_FAIL,
    UPDATE_FARMER_PRODUCT_ORDER_REQUEST,
    UPDATE_FARMER_PRODUCT_ORDER_RESET,
    UPDATE_FARMER_PRODUCT_ORDER_SUCCESS,
} from '../../../constants/serviceProviderConstants/orderConstants'

export const serviceProviderplaceOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case SERVICE_PROVIDER_PLACE_ORDER_REQUEST:
            return { loading: true }
        case SERVICE_PROVIDER_PLACE_ORDER_SUCCESS:
            return { loading: false, savedOrder: action.payload }
        case SERVICE_PROVIDER_PLACE_ORDER_FAIL:
            return { loading: false, error: action.payload }
        case SERVICE_PROVIDER_PLACE_ORDER_RESET:
            return {}
        default:
            return state
    }
}

export const serviceProviderOrdersReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case SERVICE_PROVIDER_ALL_ORDER_REQUEST:
            return { loading: true }
        case SERVICE_PROVIDER_ALL_ORDER_SUCCESS:
            return { loading: false, orders: action.payload }
        case SERVICE_PROVIDER_ALL_ORDER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const farmersOrdersReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case FARMER_PRODUCT_ORDERS_REQUEST:
            return { loading: true }
        case FARMER_PRODUCT_ORDERS_SUCCESS:
            return { loading: false, orders: action.payload }
        case FARMER_PRODUCT_ORDERS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const updatefarmerOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_FARMER_PRODUCT_ORDER_REQUEST:
            return { loading: true }
        case UPDATE_FARMER_PRODUCT_ORDER_SUCCESS:
            return { loading: false, updated: action.payload }
        case UPDATE_FARMER_PRODUCT_ORDER_FAIL:
            return { loading: false, error: action.payload }
        case UPDATE_FARMER_PRODUCT_ORDER_RESET:
            return {}
        default:
            return state
    }
}