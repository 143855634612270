import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import { MDBDataTableV5 } from 'mdbreact'
import { formatNumber } from '../../../components/functions'
import { allFarmerOrders } from '../../../actions/serviceproviderActions/orderAction/allFarmerOrders'
import { updateOrder } from '../../../actions/serviceproviderActions/orderAction/updateOrder'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { UPDATE_FARMER_PRODUCT_ORDER_RESET } from '../../../constants/serviceProviderConstants/orderConstants'

export default function FarmerOrdersPage() {
    const [datatable, setDatatable] = useState({})
    const [itemId, setItemId] = useState('')
    const [detail, setDetail] = useState({})
    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState('')

    const farmerOrders = useSelector((state) => state.farmerOrders)
    const { loading, orders, error } = farmerOrders


    const farmerUpdateOrder = useSelector((state) => state.farmerUpdateOrder)
    const { loading: loadingUpdate, updated, error: updateError } = farmerUpdateOrder

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(allFarmerOrders())

        if (updated) {
            setOpen(false)
            setStatus()
            setItemId()
            setDetail()
            setTimeout(() => {
                dispatch({ type: UPDATE_FARMER_PRODUCT_ORDER_RESET })
            }, 2500)
        }
    }, [updated])

    useEffect(() => {
        if (orders) {
            setDatatable({
                columns: [
                    {
                        label: 'Order ID',
                        field: 'orderID',
                        width: 150,
                        attributes: {
                            'aria-controls': 'DataTable',
                            'aria-label': 'orderID',
                        },
                    },
                    {
                        label: 'Date',
                        field: 'date',
                        width: 200,
                    },
                    {
                        label: 'Product',
                        field: 'product',
                        width: 100,
                    },
                    {
                        label: 'User ID',
                        field: 'user',
                        width: 100,
                    },
                    {
                        label: 'Full Name',
                        field: 'name',
                        width: 100,
                    },
                    {
                        label: 'Quantity',
                        field: 'amount',
                        width: 100,
                    },
                    {
                        label: 'Amount',
                        field: 'price',
                        width: 200,
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        width: 100,
                        sort: 'disabled',
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        sort: 'disabled',
                    },
                ],
                rows: orders.map((item) => ({
                    orderID: item.orderID,
                    date: moment(item.created_at).format('Do MMMM YYYY,  h:mm a'),
                    user: item.user && item.user.phone,
                    name: item.user && item.user
                        ?
                        <div style={{ textTransform: 'capitalize' }}>
                            {`${item.user && item.user.firstName && item.user.firstName} ${item.user && item.user.lastName && item.user.lastName}`}
                        </div>
                        : '',
                    product: item.product && item.product.name,
                    amount: item.quantity,
                    status: (
                        <div className={`badge badge-soft-${item.status} font-size-11`}>
                            {item.status}
                        </div>
                    ),
                    price: `NGN ${formatNumber(item.price)}`,
                    action: (
                        <>
                            <button
                                className="btn btn-sm btn-primary"
                                style={{ marginRight: '15px' }}
                                data-bs-container="#tooltip-container1"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Edit"
                                onClick={() => editOrder(item)}
                            >
                                Edit
                            </button>
                        </>
                    ),
                })),
            })
        }
    }, [orders])

    const editOrder = (item) => {
        setItemId(item._id)
        setOpen(true)
        setDetail(item)
        setStatus(item.status)
    }

    const handleUpdateOrder = (e) => {
        e.preventDefault()

        dispatch(updateOrder(itemId, status))
    }

    return (
        <div id="layout-wrapper">
            <Header />
            <LeftSidebar />
            <RightSidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">All Orders</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Farmsured</a>
                                            </li>
                                            <li className="breadcrumb-item active">Orders</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {
                            loading
                                ?
                                <LoadingBox />
                                :
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                {error ? <MessageBox variant='danger'>{error}</MessageBox> : null}
                                                {updateError ? <MessageBox variant='danger'>{updateError}</MessageBox> : null}
                                                {updated ? <MessageBox variant='success'>{updated.message}</MessageBox> : null}
                                                <MDBDataTableV5
                                                    hover
                                                    entriesOptions={[5, 10, 20, 25, 50]}
                                                    entries={10}
                                                    pagesAmount={4}
                                                    data={datatable}
                                                    searchTop
                                                    searchBottom={false}
                                                    barReverse
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                        {loadingUpdate
                            ? <LoadingBox />
                            :
                            <Dialog
                                open={open}
                                onClose={() => setOpen(false)}
                                aria-labelledby="form-dialog-title"
                                fullWidth
                                maxWidth="md"
                            >
                                <div style={{ backgroundColor: '#f1f5f7' }}>
                                    <div className="modal-header">

                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => setOpen(false)}
                                        ></button>
                                    </div>

                                    <div className="bg-success" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
                                        <h5 className="modal-title white" style={{ color: 'white', paddingLeft: '20px' }}>Update Order</h5>
                                    </div>
                                    <DialogContent >
                                        <div className="" >
                                            <div className="row mt-2">
                                                <div className="col-md-4">
                                                    <div className="card" style={{ borderRadius: '20px' }}>
                                                        <div className="card-body">
                                                            <h4 className="card-title  font-size-16">Order Details</h4>

                                                            <div className="mt-4">
                                                                <p className="text-muted mb-1 font-size-15">Order ID</p>
                                                                <h5 className="card-title">{detail && detail.orderID}</h5>
                                                            </div>



                                                            <div className="mt-4">
                                                                <p className="text-muted mb-1 font-size-15">Order Status</p>
                                                                <select
                                                                    className="form-select form-control select2"
                                                                    onChange={(e) => setStatus(e.target.value)}
                                                                    value={status}
                                                                >
                                                                    <option value="Order_Received">
                                                                        Order Received
                                                                    </option>
                                                                    <option value="Processing">
                                                                       Processing
                                                                    </option>
                                                                    <option value="Order_in_Transit">
                                                                        Order in Transit
                                                                    </option>
                                                                    <option value="Delivered">Delivered</option>
                                                                    <option value="Declined">Declined</option>
                                                                </select>
                                                            </div>

                                                            <div className="mt-4">
                                                                <p className="text-muted mb-1 font-size-15">Order Date</p>
                                                                <h5 className="card-title">{moment(detail && detail.created_at).format('Do MMMM YYYY,  h:mm a')}</h5>
                                                            </div>

                                                            <div className="mt-4">
                                                                <p className="text-muted mb-1 font-size-15">Order Date</p>
                                                                <span className={`badge badge-soft-${detail && detail.status}`}>
                                                                    {detail && detail.status}
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="card" style={{ borderRadius: '20px' }}>
                                                        <div className="card-body">
                                                            <h4 className="card-title  font-size-16">User Details</h4>

                                                            <div className="mt-4">
                                                                <p className="text-muted mb-1 font-size-15">Full Name</p>
                                                                <h5 className="card-title" style={{ textTransform: 'capitalize' }}> {`${detail && detail.user && detail.user.firstName && detail.user.firstName} ${detail && detail.user && detail.user.lastName && detail.user.lastName}`}</h5>
                                                            </div>


                                                            <div className="mt-4">
                                                                <p className="text-muted mb-1 font-size-15">User ID</p>
                                                                <h5 className="card-title">{detail && detail.user && detail.user.phone}</h5>
                                                            </div>

                                                            <div className="mt-4">
                                                                <p className="text-muted mb-1 font-size-15">Phone</p>
                                                                <h5 className="card-title">{detail && detail.user && `0${detail.user.phone}`}</h5>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card" style={{ borderRadius: '20px' }}>
                                                        <div className="card-body">
                                                            <h4 className="card-title font-size-16">Product Details</h4>

                                                            <div className="mt-4">
                                                                <p className="text-muted mb-1 font-size-15">Product Name</p>
                                                                <h5 className="card-title">{detail && detail.product && detail.product.name}</h5>
                                                            </div>

                                                            <div className="mt-4">
                                                                <p className="text-muted mb-1 font-size-15">Product Price</p>
                                                                <h5 className="card-title">{detail && detail.price && `NGN ${formatNumber(detail.price)}`}</h5>
                                                            </div>

                                                            <div className="mt-4">
                                                                <p className="text-muted mb-1 font-size-15">Quantity</p>
                                                                <h5 className="card-title">{detail && detail.quantity}</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card" style={{ borderRadius: '20px' }}>
                                                        <div className="card-body">
                                                            <h4 className="card-title font-size-16">Delivery Details</h4>


                                                            <div className="mt-4">
                                                                <p className="text-muted mb-1 font-size-15">Address</p>
                                                                <h5 className="card-title">{detail && detail.user && detail.user.address ? detail.user.address : 'Not filled'}</h5>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <div className="mt-4">
                                                                        <p className="text-muted mb-1 font-size-15">City</p>
                                                                        <h5 className="card-title">{detail && detail.user && detail.user.city ? detail.user.city : 'Not filled'}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    <div className="mt-4">
                                                                        <p className="text-muted mb-1 font-size-15">Local Government Area</p>
                                                                        <h5 className="card-title">{detail && detail.user && detail.user.lga ? detail.user.lga : 'Not filled'}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className="mt-4">
                                                                        <p className="text-muted mb-1 font-size-15">State</p>
                                                                        <h5 className="card-title">{detail && detail.user && detail.user.state ? detail.user.state : 'Not filled'}</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <DialogActions>
                                                <div className="float-end mb-4">
                                                    <button
                                                        className=" btn btn-success me-1"
                                                        type="submit"
                                                        onClick={handleUpdateOrder}
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        className=" btn btn-danger"
                                                        type="submit"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </DialogActions>
                                        </div>
                                    </DialogContent>
                                </div>
                            </Dialog>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
