import {
    USER_WALLET_BALANCE_FAIL,
    USER_WALLET_BALANCE_REQUEST,
    USER_WALLET_BALANCE_SUCCESS,
    USER_WALLET_TRANSACTIONS_FAIL,
    USER_WALLET_TRANSACTIONS_REQUEST,
    USER_WALLET_TRANSACTIONS_SUCCESS
} from "../../../constants/adminConstants/farmtrove/walletConstants"

export const userWalletBalanceReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_WALLET_BALANCE_REQUEST:
            return { loading: true }
        case USER_WALLET_BALANCE_SUCCESS:
            return { loading: false, wallet: action.payload }
        case USER_WALLET_BALANCE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const userWalletTransactionReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_WALLET_TRANSACTIONS_REQUEST:
            return { loading: true }
        case USER_WALLET_TRANSACTIONS_SUCCESS:
            return { loading: false, transactions: action.payload }
        case USER_WALLET_TRANSACTIONS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
