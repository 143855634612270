import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addPackageCategory } from '../../../actions/adminActions/packagesActions/addPackageCategory'
import { LoadingBox, MessageBox } from '../../../components'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import moment from 'moment'
import {
  CREATE_PACKAGE_CATEGORY_RESET,
  PACKAGE_CATEGORY_DELETE_RESET,
  UPDATE_PACKAGE_CATEGORY_RESET,
} from '../../../constants/adminConstants/packageConstants'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { deleteCategory } from '../../../actions/adminActions/packagesActions/deletePackageCategory'
import { updatePackage } from '../../../actions/adminActions/packagesActions/updatePackageCategory'
import { allPackageCategory } from '../../../actions/adminActions/packagesActions/allPackageCategory'
import { MDBDataTableV5 } from 'mdbreact'
import SweetAlert from 'react-bootstrap-sweetalert'

function PackageCategoryPage() {
  const initialState = ''
  const [datatable, setDatatable] = useState({})
  const [open, setOpen] = useState(false)
  const [itemId, setItemId] = useState(initialState)
  const [name, setName] = useState(initialState)
  const [description, setDescription] = useState(initialState)
  const [alert, setAlert] = useState(null)

  const packageCategory = useSelector((state) => state.packageCategory)
  const { loading, error, category } = packageCategory

  const packageCategoryList = useSelector((state) => state.packageCategoryList)
  const {
    loading: loadingCategory,
    error: listError,
    categoryList,
  } = packageCategoryList

  const deletePackageCategory = useSelector(
    (state) => state.deletePackageCategory,
  )
  const {
    loading: loadingDelete,
    error: errorDelete,
    success,
  } = deletePackageCategory

  const updatePackageCategory = useSelector(
    (state) => state.updatePackageCategory,
  )
  const {
    loading: loadingUpdate,
    error: updateError,
    updated,
  } = updatePackageCategory

  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(addPackageCategory(name, description))
  }

  const deleteHandler = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deleteCategory(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  const handleClickOpen = (item) => {
    setOpen(true)
    setItemId(item._id)
    setName(item.name)
    setDescription(item.description)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleEditSubmit = (e) => {
    e.preventDefault()
    dispatch(updatePackage(itemId, name, description))
    setOpen(false)
  }

  useEffect(() => {
    if (success) {
      setAlert(null)
      setTimeout(() => {
        dispatch({ type: PACKAGE_CATEGORY_DELETE_RESET })
      }, 1500)
    }

    if (category) {
      setTimeout(() => {
        dispatch({ type: CREATE_PACKAGE_CATEGORY_RESET })
      }, 1500)
    }

    if (updated) {
      setTimeout(() => {
        dispatch({ type: UPDATE_PACKAGE_CATEGORY_RESET })
      }, 1500)
    }

    dispatch(allPackageCategory())
  }, [dispatch, category, success, updated])

  useEffect(() => {
    if (categoryList) {
      setDatatable({
        columns: [
          {
            label: 'Category Name',
            field: 'name',
            width: 200,
          },
          {
            label: 'Description',
            field: 'description',
            width: 400,
          },
          {
            label: 'Date Created',
            field: 'date',
            // width: 100,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: categoryList.map((item) => ({
          name: item.name,
          description: item.description,
          date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
          action: (
            <div>
              <button
                style={{ marginRight: '15px' }}
                className="btn btn-sm btn-primary"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Edit"
                onClick={() => handleClickOpen(item)}
              >
                Edit
              </button>
              <button
                className="btn btn-sm m-0 p-0 text-danger"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Delete"
                onClick={() => deleteHandler(item)}
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </button>
            </div>
          ),
        })),
      })
    }
  }, [categoryList])

  return (
    <div id="layout-wrapper">
      <Header />
      <RightSidebar />
      <LeftSidebar />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Protocol Category</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Protocol</a>
                      </li>
                      <li className="breadcrumb-item active">Category</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {category && (
                          <MessageBox variant="success">
                            {category.message}
                          </MessageBox>
                        )}
                        {error && (
                          <MessageBox variant="danger">{error}</MessageBox>
                        )}
                        <div className="">
                          <small className="font-size-13">
                            Protocol categories for the Farmsured can be managed
                            here.
                          </small>
                        </div>
                        <div className="mt-2 mb-2">
                          <h4 className="card-title">Add New Category</h4>
                        </div>

                        {loading ? (
                          <LoadingBox />
                        ) : (
                          <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <label className="form-label" forhtml="name">
                                Name
                              </label>
                              <input
                                id="name"
                                name="name"
                                type="text"
                                className="form-control form-control"
                                required
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label" forhtml="name">
                                Description
                              </label>
                              <textarea
                                id="description"
                                name="description"
                                type="text"
                                className="form-control form-control"
                                required
                                onChange={(e) => setDescription(e.target.value)}
                              />
                              <small>
                                The description is not prominent by default.
                              </small>
                            </div>

                            <div className="mt-2">
                              <button
                                className="btn btn-sm btn-success"
                                type="submit"
                                onSubmit={handleSubmit}
                              >
                                Add new category
                              </button>
                            </div>
                          </form>
                        )}
                      </div>
                      <div className="col-md-8">
                        {loadingDelete && <LoadingBox />}
                        {loadingUpdate ? <LoadingBox /> : null}
                        {updated ? (
                          <MessageBox variant="success">
                            {updated.message}
                          </MessageBox>
                        ) : null}
                        {success && (
                          <MessageBox variant="success">
                            Category deleted successfully
                          </MessageBox>
                        )}
                        {updateError ? (
                          <MessageBox variant="danger">
                            {updateError}
                          </MessageBox>
                        ) : null}
                        {errorDelete && (
                          <MessageBox variant="danger">
                            {errorDelete}
                          </MessageBox>
                        )}
                        {listError ? (
                          <MessageBox variant="danger">{listError}</MessageBox>
                        ) : null}
                        <div className="table-responsive-x1 mt-4">
                          {loadingCategory ? (
                            <LoadingBox />
                          ) : (
                            <div className="table-responsive-xl">
                              <MDBDataTableV5
                                hover
                                entriesOptions={[5, 20, 25, 50]}
                                entries={10}
                                pagesAmount={4}
                                data={datatable}
                                searchTop
                                searchBottom={false}
                                barReverse
                              />
                            </div>
                          )}

                          {/* Edit Modal */}
                          <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="form-dialog-title"
                          >
                            <>
                              <div className="modal-header">
                                <h5 className="modal-title">
                                  Edit protocol category
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={handleClose}
                                ></button>
                              </div>
                              <DialogContent className="dialog_content">
                                <div className="">
                                  <form onSubmit={handleEditSubmit}>
                                    <div className="row mb-3">
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-md-12 col-form-label"
                                      >
                                        Name
                                      </label>
                                      <div className="col-md-12">
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder="Artisanal kale"
                                          id="example-text-input"
                                          value={name}
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="row mb-3">
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-md-12 col-form-label"
                                      >
                                        Description
                                      </label>
                                      <div className="col-md-12">
                                        <textarea
                                          className="form-control"
                                          type="text"
                                          placeholder="Artisanal kale"
                                          id="example-text-input"
                                          value={description}
                                          onChange={(e) =>
                                            setDescription(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <DialogActions>
                                      <button
                                        className="btn btn-danger btn-sm"
                                        type="button"
                                        onClick={handleClose}
                                      >
                                        Close
                                      </button>
                                      <button
                                        className="btn btn-success btn-sm"
                                        type="submit"
                                      >
                                        Update
                                      </button>
                                    </DialogActions>
                                  </form>
                                </div>
                              </DialogContent>
                            </>
                          </Dialog>

                          {alert}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default PackageCategoryPage
