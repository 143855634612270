import axios from 'axios'
import {
  FARM_CYCLE_DETAIL_FAIL,
  FARM_CYCLE_DETAIL_REQUEST,
  FARM_CYCLE_DETAIL_SUCCESS,
} from '../../../constants/farmerConstants/packagesConstants'

export const cycleDetail = (itemId) => async (dispatch, getState) => {
  dispatch({ type: FARM_CYCLE_DETAIL_REQUEST, payload: itemId })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/farmer/farm-cycle/detail',
      { itemId },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: FARM_CYCLE_DETAIL_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: FARM_CYCLE_DETAIL_FAIL,
      payload: message,
    })
  }
}
