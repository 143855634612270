import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { profileDetail } from '../../actions/authActions/profile'
import { Footer, Header, RightSidebar, LeftSidebar } from '../../partials'
import { MessageBox, LoadingBox } from '../../components'

function ProfilePage() {
  const dispatch = useDispatch()

  const userDetail = useSelector((state) => state.userDetail)
  const { loading, error, user } = userDetail

  useEffect(() => {
    dispatch(profileDetail())
    return () => {
      //
    }
  }, [dispatch])
  return (
    <div id="layout-wrapper">
      <Header />
      <RightSidebar />
      <LeftSidebar />

      <div className="main-content">
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">User Profile</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a href="javascript: void(0);">Profile</a>
                        </li>
                        <li className="breadcrumb-item active">Profile Page</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-9">
                  <div className="card">
                    <div className="card-body border-bottom">
                      <div className="card-header border-0">
                        <div className=" d-sm-flex align-items-center justify-content-between">
                          <div className="">
                            <h5 className="mb-0">My account</h5>
                          </div>
                          <div className=" text-right d-sm-flex justify-content-between">
                            <a
                              href="/edit-profile"
                              className="btn btn-sm btn-success"
                            >
                              Edit Profile
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="todo-task" className="task-list">
                        <div className="card task-box">
                          <div className="card-body">
                            <h6>USER INFORMATION</h6>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <h6>
                                    <b>First Name</b>
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={user.firstName || 'Not filled yet'}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <h6>
                                    <b>Last Name</b>
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={user.lastName || 'Not filled yet'}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <h6>
                                    <b>Email</b>
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={user.email || 'Not filled yet'}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <h6>
                                    <b>Phone</b>
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={user.phone || 'Not filled yet'}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- end task card --> */}

                        <div className="card task-box">
                          <div className="card-body">
                            <h6>CONTACT INFORMATION</h6>
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-3">
                                  <h6>
                                    <b>Address</b>
                                  </h6>
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    value={user.address || 'Not filled yet'}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <h6>
                                    <b>Local Government Area</b>
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      user && user.lga
                                        ? user.lga
                                        : 'Not filled yet'
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <h6>
                                    <b>State</b>
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={user.state || 'Not filled yet'}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <h6>
                                    <b>Country</b>
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={user.country || 'Nigeria'}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- end task card --> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card">
                    <div className="card-body border-bottom">
                      <div className=" border-right">
                        <div className="center m-0">
                          <img
                            className="rounded-circle profile-circle "
                            alt="profile-pic"
                            src={
                              user && user.image
                                ? user.image
                                : 'assets/images/default-user.png'
                            }
                          />
                        </div>
                        <div className="mt-3">
                          <div className="mb-3">
                            <h6>
                              <b>Company</b>
                            </h6>
                            <p>
                              {user.company
                                ? user.company.name
                                : 'Not filled yet '}
                            </p>
                          </div>
                          <hr />
                          <div className="mb-3">
                            <h6>
                              <b>Role</b>
                            </h6>
                            <p style={{ textTransform: 'capitalize' }}>
                              {user.account}
                            </p>
                          </div>
                          <hr />
                          <div className="mb-3">
                            <h6>
                              <b>Address</b>
                            </h6>
                            <p>
                              {user.company
                                ? user.company.address
                                : 'Not filled yet '}
                            </p>
                          </div>
                          <hr />
                          <div className="mb-3">
                            <h6>
                              <b>City</b>
                            </h6>
                            <p>
                              {user.company
                                ? user.company.city
                                : 'Not filled yet '}
                            </p>
                          </div>
                          <hr />
                          <div className="mb-1">
                            <h6>
                              <b>State</b>
                            </h6>
                            <p>
                              {user.company
                                ? user.company.state
                                : 'Not filled yet '}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end row --> */}
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default ProfilePage
