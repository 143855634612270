import {
  GET_SERVICE_PROVIDER_FAIL,
  GET_SERVICE_PROVIDER_REQUEST,
  GET_SERVICE_PROVIDER_SUCCESS,
} from '../../../constants/farmerConstants/getServiceProvidersConstants'

export const serviceProviderListReducers = (
  state = { listService: [] },
  action,
) => {
  switch (action.type) {
    case GET_SERVICE_PROVIDER_REQUEST:
      return { loading: true }
    case GET_SERVICE_PROVIDER_SUCCESS:
      return { loading: false, listService: action.payload }
    case GET_SERVICE_PROVIDER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
