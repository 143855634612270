import axios from 'axios'
import {
    FARMTROVE_OVERVIEW_REQUEST,
    FARMTROVE_OVERVIEW_SUCCESS,
    FARMTROVE_OVERVIEW_FAIL,
} from '../../../constants/adminConstants/farmtrove/dashboardConstants'

export const farmtroveDashboard = () => async (
    dispatch,
    getState,
) => {
    dispatch({
        type: FARMTROVE_OVERVIEW_REQUEST,
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.get(
            '/api/admin/farmtrove/overview',
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: FARMTROVE_OVERVIEW_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: FARMTROVE_OVERVIEW_FAIL, payload: message })
    }
}
