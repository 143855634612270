import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import { MDBDataTableV5 } from 'mdbreact'
import { listUsers } from '../../../actions/adminActions/usersActions/allUser'
import Switch from '@material-ui/core/Switch'
import { updateUser } from '../../../actions/adminActions/usersActions/updateUser'
import {
  ADMIN_RESET_USER_PASSWORD_RESET,
  UPDATE_USER_RESET,
} from '../../../constants/adminConstants/usersContants'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import states from '../../../utilis'
import NaijaStates from 'naija-state-local-government'
import { resetUserPassword } from '../../../actions/adminActions/usersActions/resetUserPassword'
import Select from 'react-select'
import { allCluster } from '../../../actions/adminActions/farmclusterActions/allCluster'

export default function AllUsersPage() {
  const [alert, setAlert] = useState(null)
  const [datatable, setDatatable] = useState({})
  const [itemId, setItemId] = useState('')
  const [dialog, setDialog] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [state, setState] = useState('')
  const [lga, setLga] = useState('')
  const [isSuper, setIsSuper] = useState(Boolean)
  const [status, setStatus] = useState('')
  const [role, setRole] = useState('')
  const [user, setUser] = useState({})
  const [clusters, setClusters] = useState([])

  const dispatch = useDispatch()

  const usersList = useSelector((state) => state.usersList)
  const { loading, users, error } = usersList

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const userUpdate = useSelector((state) => state.userUpdate)
  const {
    loading: loadingUpdate,
    success,
    updated,
    error: errorUpdating,
  } = userUpdate

  const adminResetPassword = useSelector((state) => state.adminResetPassword)
  const { loading: loadingReset, reset, error: errorReset } = adminResetPassword

  const listCluster = useSelector((state) => state.listCluster)
  const { loading: loadingClusters, clusters: clusterList, error: clusterError } = listCluster

  const handleUpdate = (e) => {
    e.preventDefault()
    dispatch(
      updateUser(
        itemId,
        firstName,
        lastName,
        email,
        address,
        phone,
        state,
        lga,
        isSuper,
        status,
        role,
        clusters
      ),
    )
  }

  const handleResetPassword = (e) => {
    e.preventDefault()
    dispatch(resetUserPassword(itemId))
  }

  // const handleDeleteUser = (item) => {
  //   setAlert(
  //     <SweetAlert
  //       warning
  //       showCancel
  //       confirmBtnText="Yes, delete it!"
  //       confirmBtnBsStyle="danger"
  //       title="Are you sure?"
  //       // onConfirm={() => dispatch(deleteUser(item._id))}
  //       onCancel={() => setAlert(null)}
  //       focusCancelBtn
  //     >
  //       You can not undo this!!!
  //     </SweetAlert>,
  //   )
  // }

  const openDialog = (item) => {
    setDialog(true)
    setUser(item)
    setItemId(item._id)
    setFirstName(item.firstName)
    setLastName(item.lastName)
    setEmail(item.email)
    setPhone(item.phone)
    setAddress(item.address)
    setState(item.state)
    setLga(item.lga)
    setIsSuper(item.isSuper)
    setStatus(item.status)
    setRole(item.account)
    setClusters(item.clusters)
  }

  const closeDialog = (e) => {
    e.preventDefault()
    setItemId('')
    setDialog(false)
    setUser({})
  }

  useEffect(() => {
    dispatch(listUsers())
    dispatch(allCluster())
    if (success) {
      setItemId('')
      setDialog(false)
      setTimeout(() => {
        dispatch({ type: UPDATE_USER_RESET })
      }, 5000)
    }

    if (errorUpdating) {
      setTimeout(() => {
        dispatch({ type: UPDATE_USER_RESET })
      }, 5000)
    }

    if (reset) {
      setItemId('')
      setDialog(false)
      setTimeout(() => {
        dispatch({ type: ADMIN_RESET_USER_PASSWORD_RESET })
      }, 5000)
    }
  }, [dispatch, success, reset, errorUpdating,])

  useEffect(() => {
    if (users) {
      setDatatable({
        columns: [
          {
            label: 'Unique ID ',
            field: 'phone',
            width: 200,
          },
          {
            label: 'Email',
            field: 'email',
            width: 200,
          },
          {
            label: 'Role',
            field: 'account',
            width: 100,
            sort: 'disabled',
          },
          {
            label: 'Date Joined',
            field: 'date',
            width: 100,
          },
          {
            label: 'Status',
            field: 'status',
            width: 100,
            sort: 'disabled',
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: users.map((item) => ({
          phone: item.phone ? `0${item.phone}` : '',
          email: item.email,
          account:
            item.account === 'admin' ? (
              <div className={`badge bg-danger font-size-13`}>
                {item.account}
              </div>
            ) : item.account === 'farmer' ? (
              <div className={`badge bg-info font-size-13`}>{item.account}</div>
            ) : item.account === 'manufacturer' ? (
              <div className={`badge bg-warning font-size-13`}>
                {item.account}
              </div>
            ) : item.account === 'service' ? (
              <div className={`badge bg-success font-size-13`}>
                {item.account}
              </div>
            ) : null,
          date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
          status: item.status ? (
            <div className={`badge bg-success font-size-13`}>Active</div>
          ) : (
            <div className={`badge bg-danger font-size-13`}>Inactive</div>
          ),
          action: (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => openDialog(item)}
            >
              Manage
            </button>
          ),
        })),
      })
    }
  }, [users])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Users</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Users</a>
                      </li>
                      <li className="breadcrumb-item active">All Users</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {loadingUpdate ? <LoadingBox /> : null}

            {updated ? (
              <MessageBox variant="success">{updated.message}</MessageBox>
            ) : null}

            {reset ? (
              <MessageBox variant="success">{reset.message}</MessageBox>
            ) : null}

            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive-xl">
                        <MDBDataTableV5
                          hover
                          entriesOptions={[5, 10, 20, 25, 50]}
                          entries={10}
                          pagesAmount={4}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                          barReverse
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <Dialog
            open={dialog}
            onClose={closeDialog}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth={'lg'}
          >
            <div className="bg-light">
              <DialogContent>
                {errorReset ? (
                  <MessageBox variant="danger">{errorReset}</MessageBox>
                ) : null}
                {errorUpdating ? (
                  <MessageBox variant="danger">{errorUpdating}</MessageBox>
                ) : null}
                {clusterError ? (
                  <MessageBox variant="danger">{clusterError}</MessageBox>
                ) : null
                }
                <form>
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="page-title-box d-sm-flex align-items-center justify-content-between m-0 pb-0">
                            <div className="">
                              <h4 className="mb-sm-0">
                                <b>{`${user && user.firstName ? user.firstName : ''
                                  } ${user && user.lastName ? user.lastName : ''
                                  }`}</b>
                              </h4>
                              <p className="font-size-14 mb-0 title">
                                User ID:{' '}
                                {user && user.phone ? user.phone : null}
                              </p>
                            </div>

                            <div className="page-title-right">
                              <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                  {user && user.status ? (
                                    <div
                                      className={`badge bg-success font-size-13`}
                                    >
                                      Active
                                    </div>
                                  ) : (
                                    <div
                                      className={`badge bg-danger font-size-13`}
                                    >
                                      Inactive
                                    </div>
                                  )}
                                </li>
                                <li className="breadcrumb-item rvm-breadcrumb-item status-border">
                                  <span className="card-text">Role: </span>
                                  {user && user.account === 'admin' ? (
                                    <div
                                      className={`badge bg-danger font-size-13`}
                                    >
                                      {user.account}
                                    </div>
                                  ) : user && user.account === 'farmer' ? (
                                    <div
                                      className={`badge bg-info font-size-13`}
                                    >
                                      {user.account}
                                    </div>
                                  ) : user &&
                                    user.account === 'manufacturer' ? (
                                    <div
                                      className={`badge bg-warning font-size-13`}
                                    >
                                      {user.account}
                                    </div>
                                  ) : user && user.account === 'service' ? (
                                    <div
                                      className={`badge bg-success font-size-13`}
                                    >
                                      {user.account}
                                    </div>
                                  ) : null}
                                </li>
                                <li className="breadcrumb-item rvm-breadcrumb-item status-border">
                                  <span className="card-text">
                                    {' '}
                                    Super Admin:{' '}
                                  </span>
                                  {user && user.isSuper ? (
                                    <div
                                      className={`badge bg-success font-size-13`}
                                    >
                                      Active
                                    </div>
                                  ) : (
                                    <div
                                      className={`badge bg-danger  font-size-13`}
                                    >
                                      Inactive
                                    </div>
                                  )}
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-8">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">First Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-firstname-input"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-lastname-input"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">Phone</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="basicpill-phoneno-input"
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="basicpill-email-input"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  disabled={
                                    userInfo && userInfo.isSuper === true
                                      ? false
                                      : true
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label className="form-label">Address</label>
                                <textarea
                                  id="basicpill-address-input"
                                  className="form-control"
                                  rows="2"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">State</label>
                                <select
                                  onChange={(e) => setState(e.target.value)}
                                  className="select2 form-select"
                                  value={state}
                                  required
                                >
                                  <option>Select a state</option>
                                  {states.map((item) => (
                                    <option value={item}>{item}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Local Government Area
                                </label>
                                <select
                                  className="form-select"
                                  placeholder="Select a local government area"
                                  onChange={(e) => setLga(e.target.value)}
                                  value={lga}
                                >
                                  {state && state === 'Select a state' ? (
                                    <option>
                                      Select a local government area
                                    </option>
                                  ) : state ? (
                                    <>
                                      <option>
                                        Select a local government area
                                      </option>
                                      {NaijaStates.lgas(state).lgas.map(
                                        (item) => (
                                          <option value={item}>{item}</option>
                                        ),
                                      )}
                                    </>
                                  ) : (
                                    <option>
                                      Select a local government area
                                    </option>
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                          {
                            role === 'farmer' &&
                            <div className="mb-3">
                              <label className="form-label">Cluster</label>
                              <Select
                                onChange={(opt) => setClusters(opt)}
                                required
                                isMulti
                                components={{ LoadingIndicator: loadingClusters ? true : false }}
                                options={loadingClusters ? [] :
                                  clusters.length === 5 ? []
                                    : clusterList.length === 0 ? []
                                      : clusterList.map((opt) => ({
                                        label: opt.name,
                                        value: opt._id,
                                      }))}
                                noOptionsMessage={() => {
                                  return clusters.length === 5 ? "You've reached the max options value" : 'No options available'
                                }}
                                defaultValue={clusters.map((opt) => ({
                                  label: opt.name,
                                  value: opt._id,
                                }))}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-body">
                          <div className="mb-3">
                            <label>Role</label>
                            <select
                              className="form-select"
                              value={role}
                              placeholder="Select user role"
                              onChange={(e) => setRole(e.target.value)}
                              disabled={
                                userInfo && userInfo.isSuper === true
                                  ? false
                                  : true
                              }
                            >
                              <option value="admin">Admin</option>
                              <option value="manufacturer">Manufacturer</option>
                              <option value="service">Service Provider</option>
                              <option value="farmer">Farmer</option>
                            </select>
                          </div>
                          <div className="mb-1">
                            <div className="row">
                              <div className="col-8">
                                <label className="m-0">
                                  Manage User Access
                                </label>
                                <p className="font-size-12 p-0">
                                  Allow user login into Farmsured
                                </p>
                              </div>
                              <div className="col-4">
                                <Switch
                                  size="medium"
                                  defaultChecked={status}
                                  onChange={(e) => setStatus(e.target.checked)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-1">
                            <div className="row">
                              <div className="col-8">
                                <label className="m-0">
                                  Manage Super Admin
                                </label>
                                <p className="font-size-12 p-0">
                                  Activate user as Super Admin
                                </p>
                              </div>
                              <div className="col-4">
                                <Switch
                                  size="medium"
                                  defaultChecked={isSuper}
                                  onChange={(e) => setIsSuper(e.target.checked)}
                                  disabled={
                                    userInfo && userInfo.isSuper === true
                                      ? false
                                      : true
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-body">
                          <div className="">
                            <button
                              className="btn block btn-md btn-danger w-100"
                              onClick={handleResetPassword}
                            >
                              {loadingReset
                                ? 'Loading...'
                                : 'Reset User Password'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <DialogActions>
                    <button
                      className="btn btn-success btn-sm"
                      type="submit"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={closeDialog}
                    >
                      Close
                    </button>
                  </DialogActions>
                </form>
              </DialogContent>
            </div>
          </Dialog>

          {alert}
        </div>
      </div>
      <Footer />
    </div>
  )
}
