export const ALL_MANUFACTURER_MESSAGE_REQUEST = 'ALL_MANUFACTURER_MESSAGE_REQUEST'
export const ALL_MANUFACTURER_MESSAGE_SUCCESS = 'ALL_MANUFACTURER_MESSAGE_SUCCESS'
export const ALL_MANUFACTURER_MESSAGE_FAIL = 'ALL_MANUFACTURER_MESSAGE_FAIL'

export const MANUFACTURER_SEND_MESSAGE_REQUEST = 'MANUFACTURER_SEND_MESSAGE_REQUEST'
export const MANUFACTURER_SEND_MESSAGE_SUCCESS = 'MANUFACTURER_SEND_MESSAGE_SUCCESS'
export const MANUFACTURER_SEND_MESSAGE_FAIL = 'MANUFACTURER_SEND_MESSAGE_FAIL'
export const MANUFACTURER_SEND_MESSAGE_RESET = 'MANUFACTURER_SEND_MESSAGE_RESET'

export const MANUFACTURER_READ_MESSAGE_REQUEST = 'MANUFACTURER_READ_MESSAGE_REQUEST'
export const MANUFACTURER_READ_MESSAGE_SUCCESS = 'MANUFACTURER_READ_MESSAGE_SUCCESS'
export const MANUFACTURER_READ_MESSAGE_FAIL = 'MANUFACTURER_READ_MESSAGE_FAIL'
