import axios from 'axios'
import {
  BENEFIT_DELETE_FAIL,
  BENEFIT_DELETE_REQUEST,
  BENEFIT_DELETE_SUCCESS,
} from '../../../constants/adminConstants/benefitContants'

export const deleteBenefit = (benefitId) => async (dispatch, getState) => {
  dispatch({ type: BENEFIT_DELETE_REQUEST, payload: benefitId })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.delete(`/api/admin/benefit/${benefitId}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    })
    dispatch({ type: BENEFIT_DELETE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: BENEFIT_DELETE_FAIL, payload: message })
  }
}
