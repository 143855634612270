import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { listManufacuturers } from '../../../actions/adminActions/manufactuerActions/listManufacturers'
import { allClusterCategory } from '../../../actions/adminActions/farmclusterActions/allClusterCategory'
import { addCluster } from '../../../actions/adminActions/farmclusterActions/addCluster'
import { LoadingBox, MessageBox } from '../../../components'
import { ADD_CLUSTER_RESET } from '../../../constants/adminConstants/farmcluster/clusterContants'

export default function AddClusterPage() {
  const [manufacturer, setManufacturer] = useState('')
  const [name, setClusterName] = useState('')
  const [category, setClusterCategory] = useState('')
  const [description, setClusterDescription] = useState('')

  const dispatch = useDispatch()

  const manufacturersList = useSelector((state) => state.manufacturersList)
  const { loading: loadingMaufacturer, manufacturers } = manufacturersList

  const clusterCategoryList = useSelector((state) => state.clusterCategoryList)
  const { loading: loadingCategory, categorylist, error: categoryError } = clusterCategoryList

  const newCluster = useSelector((state) => state.newCluster)
  const { loading, cluster, error } = newCluster

  useEffect(() => {
    dispatch(listManufacuturers())
    dispatch(allClusterCategory())

    if (cluster) {
      setTimeout(() => {
        dispatch({ type: ADD_CLUSTER_RESET })
      }, 5000)
    }

    if (error) {
      setTimeout(() => {
        dispatch({ type: ADD_CLUSTER_RESET })
      }, 5000)
    }

  }, [cluster, error])

  const submitHandler = () => {
    dispatch(addCluster(manufacturer, name, category, description))
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Add New Cluster</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmcluster</a>
                      </li>
                      <li className="breadcrumb-item active">Add Cluster</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            {loading ? <LoadingBox /> :
              <div className="card">
                <div className="card-body">
                  <div>
                    {error ? <MessageBox variant='danger'>{error}</MessageBox> : null}
                    {cluster ? <MessageBox variant='success'>{cluster.message}</MessageBox> : null}
                    <h4 className="card-title">Basic Information</h4>
                    <p className="card-title-desc">
                      Enter cluster information below
                    </p>
                    <div className="row">
                      <div className="col-6">
                        <div className="mb-3">
                          <label className="form-label" forHtml="ProductName">
                            Select Organization
                          </label>
                          <select
                            onChange={(e) => setManufacturer(e.target.value)}
                            className="select2 form-select"
                            required
                          >
                            <option>Select</option>
                            {loadingMaufacturer ? (
                              'Loading...'
                            ) : manufacturers.length > 0 ? (
                              <>
                                {manufacturers.map((item) => (
                                  <option value={item._id}>
                                    {item.name}
                                  </option>
                                ))}
                              </>
                            ) : (
                              'There is no organization'
                            )}
                          </select>
                        </div>
                      </div>
                    </div>

                    <form onSubmit={submitHandler}>
                      <div className="row mt-3">
                        <h3 className="card-title">Cluster Details</h3>
                        <div className="col-9">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              forHtml="ClusterName"
                            >
                              Name
                            </label>
                            <input
                              id="ClusterName"
                              name="ClusterName"
                              type="text"
                              className="form-control"
                              required
                              onChange={(e) => setClusterName(e.target.value)}
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              forHtml="contactPersonName"
                            >
                              Category
                            </label>
                            <select
                              onChange={(e) =>
                                setClusterCategory(e.target.value)
                              }
                              className="select2 form-select"
                              required
                            >
                              <option>Select</option>
                              {loadingCategory ? (
                                'Loading...'
                              ) : categorylist.length > 0 ? (
                                <>
                                  {categorylist.map((item) => (
                                    <option value={item._id} key={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </>
                              ) : (
                                'There is no Cluster category'
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              forHtml="ClusterDescription"
                            >
                              Description
                            </label>
                            <textarea
                              id="ClusterDescription"
                              name="ClusterDescription"
                              type="text"
                              className="form-control"
                              placeholder="Description"
                              required
                              onChange={(e) =>
                                setClusterDescription(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <button
                        className="btn btn-success mt-3"
                        type="submit"
                        onSubmit={submitHandler}
                      >
                        Add New
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
