import axios from 'axios'
import {
    ADD_CLUSTER_CATEGORY_FAIL,
    ADD_CLUSTER_CATEGORY_REQUEST,
    ADD_CLUSTER_CATEGORY_SUCCESS,
} from '../../../constants/adminConstants/farmcluster/clusterContants'

export const addClusterCategory = (name, description) => async (
    dispatch,
    getState,
) => {
    dispatch({
        type: ADD_CLUSTER_CATEGORY_REQUEST,
        payload: {
            name,
            description,
        },
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/admin/farmcluster/add-cluster-category',
            { name, description },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: ADD_CLUSTER_CATEGORY_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: ADD_CLUSTER_CATEGORY_FAIL, payload: message })
    }
}
