import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listProducts } from '../../../actions/manufacturerActions/productActions/allProduct'
import { LoadingBox, MessageBox } from '../../../components'
import { formatNumber } from '../../../components/functions'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { MDBDataTableV5 } from 'mdbreact'
import { EDIT_PRODUCT_RESET } from '../../../constants/manufacturerConstants/productConstants'
import { updateProduct } from '../../../actions/manufacturerActions/productActions/updateProduct'
import { organisationServiceCategory } from '../../../actions/manufacturerActions/serviceProviderActions/organisationCategory'

function ProductsPage() {
  const [open, setOpen] = useState(false)
  const [itemId, setItemId] = useState('')
  const [name, setName] = useState('')
  const [price, setPrice] = useState('')
  const [category, setCategory] = useState('')
  const [serviceCategory, setServiceCategory] = useState('')
  const [description, setDescription] = useState('')
  const [datatable, setDatatable] = useState({})

  const manufacturerProductList = useSelector(
    (state) => state.manufacturerProductList,
  )
  const { loading, error, products } = manufacturerProductList

  const manufacturerEditProduct = useSelector(
    (state) => state.manufacturerEditProduct,
  )
  const {
    loading: loadingUpdate,
    error: updateError,
    updatedProduct,
  } = manufacturerEditProduct

  const organisationCategory = useSelector(
    (state) => state.organisationCategory,
  )
  const {
    loading: loadingCategory,
    error: assignError,
    categoryList,
  } = organisationCategory

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listProducts())
    dispatch(organisationServiceCategory())

    if (updatedProduct) {
      setTimeout(() => {
        dispatch({ type: EDIT_PRODUCT_RESET })
      }, 3500)
    }
  }, [dispatch, updatedProduct])

  const editProduct = (item) => {
    setOpen(true)
    setItemId(item._id)
    setName(item.name)
    setCategory(item.category && item.category.name)
    setDescription(item.description)
    setPrice(item.price)
  }

  useEffect(() => {
    if (products) {
      setDatatable({
        columns: [
          {
            label: 'Name',
            field: 'name',
            width: 200,
          },
          {
            label: 'Category',
            field: 'category',
            width: 100,
          },
          {
            label: 'Product Description',
            field: 'description',
            width: 100,
          },
          {
            label: 'Product Price',
            field: 'price',
            width: 100,
          },
          {
            label: 'Service Category',
            field: 'serviceCategory',
            width: 100,
          },
          {
            label: 'Date Created',
            field: 'date',
            width: 100,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: products.map((item) => ({
          name: item.name,
          category:
            item.category && item.category.name ? item.category.name : '',
          description: item.description,
          price: item.price ? `NGN ${formatNumber(item.price)}` : '',
          serviceCategory: item.serviceCategory && item.serviceCategory.name,
          date: moment(item.created_at).format('Do MMMM YYYY,  h:mm a'),
          action: (
            <>
              <button
                className="btn btn-sm btn-primary"
                style={{ marginRight: '15px' }}
                data-bs-container="#tooltip-container1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit"
                onClick={() => editProduct(item)}
              >
                Update
              </button>
            </>
          ),
        })),
      })
    }
  }, [products])

  const updateProductHandler = (e) => {
    e.preventDefault()

    dispatch(updateProduct(itemId, name, description, price, serviceCategory))
    setOpen(false)
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Product</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Products</a>
                      </li>
                      <li className="breadcrumb-item active">All Product</li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      {error ? (
                        <MessageBox variant="danger">{error}</MessageBox>
                      ) : null}
                      {loadingUpdate ? <LoadingBox /> : null}
                      {updateError ? (
                        <MessageBox variant="danger">{updateError}</MessageBox>
                      ) : null}
                      {updatedProduct ? (
                        <MessageBox variant="success">
                          {updatedProduct.message}
                        </MessageBox>
                      ) : null}

                      <div className="table-responsive-xl">
                        {loading ? (
                          <LoadingBox />
                        ) : (
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 10, 20, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Modal */}
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <>
            <div className="modal-header">
              <h5 className="modal-title">Update product & service information</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setOpen(false)}
              ></button>
            </div>
            <DialogContent>
              <div className="">
                <form onSubmit={updateProductHandler}>
                  <div className="row">
                    <div className="col-12">
                      <div className=" mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-md-12">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Product Name"
                            id="example-text-input"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className=" mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Category
                        </label>
                        <div className="col-md-12">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Description"
                            id="example-text-input"
                            value={category}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className=" mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Description
                        </label>
                        <div className="col-md-12">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Description"
                            id="example-text-input"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className=" mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Product Price
                        </label>
                        <div className="col-md-12">
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Product price"
                            id="example-text-input"
                            onChange={(e) => setPrice(e.target.value)}
                            value={price}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          forHtml="contactPersonName"
                        >
                          Service Category
                        </label>
                        <select
                          onChange={(e) =>
                            setServiceCategory(e.target.value)
                          }
                          className="select2 form-select"
                          required
                        >
                          <option>Select</option>
                          {loadingCategory
                            ? 'loading...'
                            : categoryList && categoryList.category && categoryList.category.map((opt) => (
                              <option value={opt._id}>{opt.name}</option>)
                            )
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <DialogActions>
                    <button
                      type="button"
                      className="btn btn-light btn-sm waves-effect"
                      data-bs-dismiss="modal"
                      onClick={() => setOpen(false)}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn-success btn-sm"
                      type="submit"
                      onClick={updateProductHandler}
                    >
                      Update
                    </button>
                  </DialogActions>
                </form>
              </div>
            </DialogContent>
          </>
        </Dialog>
      </div>
      <Footer />
    </div>
  )
}

export default ProductsPage
