import axios from 'axios'
import {
  ALL_PACKAGES_FAIL,
  ALL_PACKAGES_REQUEST,
  ALL_PACKAGES_SUCCESS,
} from '../../../constants/adminConstants/packageConstants'

export const allPackages = () => async (dispatch, getState) => {
  dispatch({ type: ALL_PACKAGES_REQUEST })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/admin/all-packages', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: ALL_PACKAGES_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: ALL_PACKAGES_FAIL,
      payload: message,
    })
  }
}
