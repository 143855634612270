import axios from 'axios'
import {
  GET_SERVICE_PROVIDER_REQUEST,
  GET_SERVICE_PROVIDER_SUCCESS,
  GET_SERVICE_PROVIDER_FAIL,
} from '../../../constants/farmerConstants/getServiceProvidersConstants'

export const getServiceProviders = (benefitCategory, state, lga) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: GET_SERVICE_PROVIDER_REQUEST,
    payload: { benefitCategory, state, lga },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/farmer/all-service-providers',
      { benefitCategory, state, lga },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: GET_SERVICE_PROVIDER_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: GET_SERVICE_PROVIDER_FAIL, payload: message })
  }
}
