import React, { useState, useEffect } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MDBDataTableV5 } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { allCycles } from '../../../actions/farmerActions/farmcycleActions/allCycles'
import moment from 'moment'
import { LoadingBox, MessageBox } from '../../../components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { startCycle } from '../../../actions/farmerActions/farmcycleActions/startCycle'
import { ToastContainer, toast } from 'react-toastify'
import { postponeCycle } from '../../../actions/farmerActions/farmcycleActions/postponeCycle'
import {
  POSTPONE_FARM_CYCLE_RESET,
  START_FARM_CYCLE_RESET,
} from '../../../constants/farmerConstants/packagesConstants'
import { checkStatus } from '../../../components/functions'

function FarmCyclePage(props) {
  const [datatable, setDatatable] = useState({})
  const [itemId, setItemId] = useState('')
  const [name, setName] = useState('')
  const [packageName, setPackageName] = useState('')
  const [enterprise, setEnterprise] = useState('')
  const [startDate, setStartDate] = useState('')
  const [duration, setDuration] = useState('')
  const [image, setImage] = useState('')
  const [open, setOpen] = useState(false)

  const listCycles = useSelector((state) => state.listCycles)
  const { loading, cycles, error } = listCycles

  const cycleStarts = useSelector((state) => state.cycleStarts)
  const {
    loading: loadingStart,
    updatedCycle,
    error: updateError,
  } = cycleStarts

  const cyclePostpone = useSelector((state) => state.cyclePostpone)
  const {
    loading: loadingPosted,
    postedCycle,
    error: postedError,
  } = cyclePostpone

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(allCycles())

    if (updatedCycle) {
      toast.success(updatedCycle.message, {
        position: toast.POSITION.TOP_RIGHT,
      })

      setTimeout(() => {
        props.history.push(`/user/farm-cycle/detail/${itemId}`)
        window.location.reload()
        dispatch({ type: START_FARM_CYCLE_RESET })
      }, 2000)
    }

    if (postedCycle) {
      setOpen(false)
      toast.success(postedCycle.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      setTimeout(() => {
        dispatch({ type: POSTPONE_FARM_CYCLE_RESET })
      }, 2000)
    }
  }, [dispatch, updatedCycle, postedCycle])

  const checkDate = (startDate) => {
    // Convert the input string to a Date object
    var inputDate = new Date(startDate)

    // Get the current date
    var today = new Date()

    // Compare the input date with today's date
    if (inputDate >= today) {
      return true
    } else {
      return false
    }
  }

  const handleOpenDialog = (item) => {
    setOpen(true)
    setItemId(item._id)
    setStartDate(moment(item.startDate).format('YYYY-MM-DD'))
    setDuration(item.productCategory.cycleDuration)
    setName(item.name)
    setPackageName(item.packageId.packageName)
    setEnterprise(item.packageId.enterprise.name)
    setImage(item.packageImg)
  }

  const startToday = (e) => {
    e.preventDefault()
    // dispatch start cycle action
    dispatch(startCycle(itemId, duration))
  }

  const postponeDate = (e) => {
    e.preventDefault()
    // dispatch start cycle action
    dispatch(postponeCycle(itemId, startDate, duration))
  }

  const Countdown = ({ date }) => {
    const [remainingTime, setRemainingTime] = useState(getRemainingTime(date))

    useEffect(() => {
      const timer = setTimeout(() => {
        setRemainingTime(getRemainingTime(date))
      }, 1000)

      return () => clearTimeout(timer)
    })

    const { days, hours, minutes, seconds } = remainingTime

    return (
      <div>
        {days} days, {hours} hours, {minutes} minutes, {seconds} seconds
      </div>
    )
  }

  function getRemainingTime(endTime) {
    const total = Date.parse(endTime) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
    const days = Math.floor(total / (1000 * 60 * 60 * 24))

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    }
  }

  useEffect(() => {
    if (cycles) {
      setDatatable({
        columns: [
          {
            label: 'Date Created',
            field: 'date',
            width: 50,
          },
          {
            label: 'Enterprise',
            field: 'enterprise',
            width: 100,
          },
          {
            label: 'FarmCycle Name',
            field: 'name',
            width: 100,
          },
          {
            label: 'Package',
            field: 'package',
            width: 100,
          },
          {
            label: 'Point',
            field: 'point',
          },
          {
            label: 'Progress',
            field: 'chart',
            width: 200,
          },
          {
            label: 'Start Date',
            field: 'startDate',
            width: 100,
          },
          {
            label: 'Status',
            field: 'status',
            width: 50,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: cycles.map((item) => ({
          name: item.name,
          enterprise:
            item.packageId &&
            item.packageId.enterprise &&
            item.packageId.enterprise.name
              ? item.packageId.enterprise.name
              : '',
          package:
            item.packageId && item.packageId.packageName
              ? item.packageId.packageName
              : '',
          point: item.point,
          chart: (
            <LinearProgress
              variant="determinate"
              className="mt-2 p-1"
              color="primary"
              value={
                item.point && item.productCategory.expectedPoint
                  ? (item.point / item.productCategory.expectedPoint) * 100
                  : 0
              }
            />
          ),
          status: checkStatus(item.startDate, item.endDate),
          startDate: moment(item.startDate).format('DD MMM YYYY'),
          date: moment(item.createdAt).format('Do MMM YYYY, h:mm a'),
          action: checkDate(item.startDate) ? (
            <button
              className="btn btn-warning btn-sm"
              data-bs-toggle="tooltip"
              data-placement="top"
              title="Pospone"
              onClick={() => handleOpenDialog(item)}
            >
              View Details
            </button>
          ) : (
            <a
              href={`/user/farm-cycle/detail/${item._id}`}
              style={{ marginRight: '15px' }}
              className="btn btn-sm btn-success"
              data-bs-toggle="tooltip"
              data-placement="top"
              title="Manage"
            >
              Manage
            </a>
          ),
        })),
      })
    }
  }, [cycles])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">All Farm Cycle</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farm Cycle</a>
                      </li>
                      <li className="breadcrumb-item active">All Farm Cycle</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {loadingStart ? <LoadingBox /> : null}
            {loadingPosted ? <LoadingBox /> : null}

            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive-xl">
                        <MDBDataTableV5
                          hover
                          entriesOptions={[5, 10, 20, 25, 50]}
                          entries={10}
                          pagesAmount={4}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                          barReverse
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth={'lg'}
            >
              <>
                <div className="modal-header">
                  <h5 className="modal-title">Postpone Farm Cycle</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setOpen(false)}
                  ></button>
                </div>
                <DialogContent>
                  {updateError ? (
                    <MessageBox variant="danger">{updateError}</MessageBox>
                  ) : null}

                  {postedError ? (
                    <MessageBox variant="danger">{postedError}</MessageBox>
                  ) : null}
                  <div className="mt-5">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="count-image">
                          <img
                            src={image}
                            alt=""
                            className="count-down-image"
                            style={{
                              maxWidth: '100%',
                              margin: 'auto',
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{
                              border: '1px solid #0d89c7',
                              borderRadius: '10px',
                            }}
                          >
                            <div className="standard-body">
                              Countdown to Start
                            </div>

                            <div className="mt-4">
                              <div className="row">
                                {/* First Column */}
                                <div className="col-md-2">
                                  <div className="card">
                                    <div className="card-body">
                                      <i
                                        className="fal fa-id-card"
                                        style={{
                                          fontSize: '34px',
                                          width: '100%',
                                          margin: 'auto',
                                          textAlign: 'center',
                                          padding: '12px',
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-10">
                                  <div className="card">
                                    <div className="card-body">
                                      <p className="font-size-13">Cycle Name</p>

                                      <h4 className="card-title font-size-16">
                                        {name ? name : 'Farm Cycle Name'}
                                      </h4>
                                    </div>
                                  </div>
                                </div>

                                {/* Second column */}
                                <div className="col-md-2">
                                  <div className="card">
                                    <div className="card-body">
                                      <i
                                        className="fal fa-building"
                                        style={{
                                          fontSize: '39px',
                                          width: '100%',
                                          margin: 'auto',
                                          textAlign: 'center',
                                          padding: '12px',
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-10">
                                  <div className="card">
                                    <div className="card-body">
                                      <p className="font-size-13">
                                        Enterprise Package
                                      </p>

                                      <h4 className="card-title font-size-16">
                                        {enterprise
                                          ? enterprise
                                          : 'Farmsured Enterprise package'}
                                      </h4>
                                    </div>
                                  </div>
                                </div>

                                {/* Third column */}
                                <div className="col-md-2">
                                  <div className="card">
                                    <div className="card-body">
                                      <i
                                        className="fal fa-badge-check"
                                        style={{
                                          fontSize: '39px',
                                          width: '100%',
                                          margin: 'auto',
                                          textAlign: 'center',
                                          padding: '12px',
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-10">
                                  <div className="card">
                                    <div className="card-body">
                                      <p className="font-size-13">
                                        Package Name
                                      </p>

                                      <h4 className="card-title font-size-16">
                                        {packageName
                                          ? packageName
                                          : 'Farmsured package'}
                                      </h4>
                                    </div>
                                  </div>
                                </div>

                                {/* Fourth Column */}
                                <div className="col-md-2">
                                  <div className="card">
                                    <div className="card-body">
                                      <i
                                        className="fal fa-calendar"
                                        style={{
                                          fontSize: '40px',
                                          width: '100%',
                                          margin: 'auto',
                                          textAlign: 'center',
                                          padding: '12px',
                                          paddingBottom: '10px',
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-10">
                                  <div className="card">
                                    <div className="card-body">
                                      <p className="font-size-13">
                                        Postpone date
                                      </p>

                                      <input
                                        id="date"
                                        name="date"
                                        type="date"
                                        className="form-control m-0"
                                        min={moment().format('YYYY-MM-DD')}
                                        required
                                        onChange={(e) =>
                                          setStartDate(e.target.value)
                                        }
                                        placeholder="Date"
                                        value={startDate}
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/* Fourth Column */}
                                <div className="col-md-2">
                                  <div className="card">
                                    <div className="card-body">
                                      <i
                                        className="fal fa-hourglass-start"
                                        style={{
                                          fontSize: '39px',
                                          width: '100%',
                                          margin: 'auto',
                                          textAlign: 'center',
                                          padding: '12px',
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-10">
                                  <div className="card">
                                    <div className="card-body">
                                      <p className="font-size-13">Countdown</p>

                                      <h4 className="card-title font-size-16">
                                        <Countdown
                                          date={`${startDate}T00:00:00`}
                                        />
                                      </h4>
                                    </div>
                                  </div>
                                </div>

                                {/* Fiveth Column */}
                                <div className="col-md-6">
                                  <div className="d-grid ">
                                    <button
                                      className="btn btn-success btn-lg mb-2"
                                      onClick={startToday}
                                    >
                                      Start Now
                                    </button>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="d-grid">
                                    <button
                                      className="btn btn-warning btn-lg mb-2"
                                      onClick={postponeDate}
                                    >
                                      Postpone
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContent>
              </>
            </Dialog>
            <ToastContainer />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default FarmCyclePage
