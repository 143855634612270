import axios from 'axios'
import {
    USER_GET_CLUSTER_LIST_FAIL,
    USER_GET_CLUSTER_LIST_REQUEST,
    USER_GET_CLUSTER_LIST_SUCCESS,
} from '../../../constants/authConstants/farmcluster/cluster'


export const allCluster = () => async (dispatch) => {
    dispatch({
        type: USER_GET_CLUSTER_LIST_REQUEST,
    })
    try {
        const { data } = await axios.get('/api/farmcluster/all-cluster')
        dispatch({ type: USER_GET_CLUSTER_LIST_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: USER_GET_CLUSTER_LIST_FAIL, payload: message })
    }
}