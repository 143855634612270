import {
  FARMER_CLAIM_BENEFIT_REQUEST,
  FARMER_CLAIM_BENEFIT_SUCCESS,
  FARMER_CLAIM_BENEFIT_FAIL,
  FARMER_CLAIM_BENEFIT_RESET,
} from '../../../constants/farmerConstants/claimBenefitConstants'

export const farmerClaimBenefitReducer = (state = {}, action) => {
  switch (action.type) {
    case FARMER_CLAIM_BENEFIT_REQUEST:
      return { loading: true }
    case FARMER_CLAIM_BENEFIT_SUCCESS:
      return { loading: false, claim: action.payload }
    case FARMER_CLAIM_BENEFIT_FAIL:
      return { loading: false, error: action.payload }
    case FARMER_CLAIM_BENEFIT_RESET:
      return {}
    default:
      return state
  }
}
