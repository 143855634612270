import axios from 'axios'
import {
  SEND_INVOICE_FAIL,
  SEND_INVOICE_REQUEST,
  SEND_INVOICE_SUCCESS,
} from '../../../constants/adminConstants/TagsConstants'

export const sendClientInvoice = (
  orderId,
  costPerRoll,
  shippingFee,
  dollarRate,
  accountName,
  accountNumber,
  bankName,
) => async (dispatch, getState) => {
  dispatch({
    type: SEND_INVOICE_REQUEST,
    payload: {
      orderId,
      costPerRoll,
      shippingFee,
      dollarRate,
      accountName,
      accountNumber,
      bankName,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/send-invoice',
      {
        orderId,
        costPerRoll,
        shippingFee,
        dollarRate,
        accountName,
        accountNumber,
        bankName,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: SEND_INVOICE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: SEND_INVOICE_FAIL, payload: message })
  }
}
