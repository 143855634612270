import axios from 'axios'
import {
  ACCEPT_LOAN_OFFER_FAIL,
  ACCEPT_LOAN_OFFER_REQUEST,
  ACCEPT_LOAN_OFFER_SUCCESS,
} from '../../../constants/farmerConstants/farmtrove/loanConstants'

export const acceptoffer = (
  itemId,
  status,
  startDate,
  dueDate,
  repaymentSchedule,
) => async (dispatch, getState) => {
  dispatch({
    type: ACCEPT_LOAN_OFFER_REQUEST,
    payload: { itemId, status, startDate, dueDate, repaymentSchedule },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/farmer/farmtrove/loan-application/accept-offer',
      { itemId, status, startDate, dueDate, repaymentSchedule },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: ACCEPT_LOAN_OFFER_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: ACCEPT_LOAN_OFFER_FAIL, payload: message })
  }
}
