import axios from 'axios'
import {
    UPDATE_CLUSTER_CATEGORY_REQUEST,
    UPDATE_CLUSTER_CATEGORY_SUCCESS,
    UPDATE_CLUSTER_CATEGORY_FAIL,
} from '../../../constants/adminConstants/farmcluster/clusterContants'

export const editClusterCategory = (
    itemId,
    name,
    description
) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_CLUSTER_CATEGORY_REQUEST,
        payload: {
            itemId,
            name,
            description
        },
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/admin/farmcluster/edit-cluster-category',
            {
                itemId,
                name,
                description
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: UPDATE_CLUSTER_CATEGORY_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: UPDATE_CLUSTER_CATEGORY_FAIL, payload: message })
    }
}
