import axios from 'axios'
import {
    ALL_SECURE_FUNDS_FAIL,
    ALL_SECURE_FUNDS_REQUEST,
    ALL_SECURE_FUNDS_SUCCESS,
} from '../../../constants/authConstants/walletConstants'

export const allClaimTransfers = () => async (dispatch, getState) => {
    dispatch({
        type: ALL_SECURE_FUNDS_REQUEST,
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.get(
            '/api/wallet/escrow/transactions/claims',
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: ALL_SECURE_FUNDS_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: ALL_SECURE_FUNDS_FAIL, payload: message })
    }
}
