import {
  EDIT_PRODUCT_FAIL,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_RESET,
  EDIT_PRODUCT_SUCCESS,
  MANUFACTURER_PRODUCT_LIST_FAIL,
  MANUFACTURER_PRODUCT_LIST_REQUEST,
  MANUFACTURER_PRODUCT_LIST_SUCCESS,
} from '../../../constants/manufacturerConstants/productConstants'

export const manufacturerProductListReducer = (
  state = { loading: true, products: [] },
  action,
) => {
  switch (action.type) {
    case MANUFACTURER_PRODUCT_LIST_REQUEST:
      return { loading: true }
    case MANUFACTURER_PRODUCT_LIST_SUCCESS:
      return { loading: false, products: action.payload }
    case MANUFACTURER_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const editProductReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_PRODUCT_REQUEST:
      return { loading: true }
    case EDIT_PRODUCT_SUCCESS:
      return { loading: false, updatedProduct: action.payload }
    case EDIT_PRODUCT_FAIL:
      return { loading: false, error: action.payload }
    case EDIT_PRODUCT_RESET:
      return {}
    default:
      return state
  }
}
