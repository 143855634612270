import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import { allPackages } from '../../../actions/adminActions/packagesActions/allPackages'
import { deletePackage } from '../../../actions/adminActions/packagesActions/deletePackage'
import { MDBDataTableV5 } from 'mdbreact'
import moment from 'moment'
import { PACKAGE_DELETE_RESET } from '../../../constants/adminConstants/packageConstants'
import SweetAlert from 'react-bootstrap-sweetalert'

function AllPackagesPage() {
  const [datatable, setDatatable] = useState({})
  const [alert, setAlert] = useState(null)

  const allpackages = useSelector((state) => state.allpackages)
  const { loading, packages, error } = allpackages

  const packageDelete = useSelector((state) => state.packageDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = packageDelete

  const dispatch = useDispatch()

  const deleteHandler = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deletePackage(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  useEffect(() => {
    if (successDelete) {
      setAlert(null)
      dispatch({ type: PACKAGE_DELETE_RESET })
    }
    dispatch(allPackages())
  }, [dispatch, successDelete])

  useEffect(() => {
    if (packages) {
      setDatatable({
        columns: [
          {
            label: 'Protocol Name',
            field: 'name',
            width: 150,
          },
          {
            label: 'Protocol Category',
            field: 'category',
            width: 200,
          },
          {
            label: 'Minimum Unit',
            field: 'minUnit',
            width: 400,
          },
          {
            label: 'Measuring Unit',
            field: 'unit',
            width: 400,
          },

          {
            label: 'Date Created',
            field: 'date',
            // width: 100,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: packages.map((item) => ({
          name: item.packageName,
          category:
            item.farmType && item.farmType.name ? item.farmType.name : '',
          minUnit:
            item.packageMinUnit && item.packageMinUnit.minUnit
              ? item.packageMinUnit.minUnit
              : null,
          unit:
            item.packageMinUnit && item.packageMinUnit.measuringUnit
              ? item.packageMinUnit.measuringUnit
              : null,
          date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
          action: (
            <>
              <a
                style={{ marginRight: '15px' }}
                className="btn btn-sm btn-primary"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="View"
                href={`/protocol/detail/${item._id}`}
              >
                View
              </a>
              <a
                style={{ marginRight: '15px' }}
                className="btn btn-sm btn-warning"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Edit"
                href={`/protocol/edit/${item._id}`}
              >
                Edit
              </a>
              <button
                className="btn btn-sm m-0 p-0 text-danger"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Delete"
                onClick={() => deleteHandler(item)}
                style={{ cursor: 'pointer' }}
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </button>
            </>
          ),
        })),
      })
    }
  }, [packages])

  return (
    <div id="layout-wrapper">
      {loadingDelete && <LoadingBox />}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Protocol</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmsured</a>
                      </li>
                      <li className="breadcrumb-item active">All Protocols</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}
            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <small>
                        <a
                          href="/create-protocol"
                          className="btn btn-success btn-sm mb-2 float-end"
                        >
                          <i className="mdi mdi-plus me-2"></i> Add Protocol
                        </a>
                      </small>
                      <h4 className="card-title">Protocol Package</h4>

                      <p className="card-title-desc">
                        A list of all Farmsured protocol and minimum point to
                        quality for the selected protocol.
                      </p>

                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive-xl mb-0"
                          data-pattern="priority-columns"
                        >
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 20, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end col --> */}
              </div>
            )}
          </div>
        </div>
      </div>
      {alert}
      <Footer />
    </div>
  )
}

export default AllPackagesPage
