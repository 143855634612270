export const ALL_USER_MESSAGE_REQUEST = 'ALL_USER_MESSAGE_REQUEST'
export const ALL_USER_MESSAGE_SUCCESS = 'ALL_USER_MESSAGE_SUCCESS'
export const ALL_USER_MESSAGE_FAIL = 'ALL_USER_MESSAGE_FAIL'

export const USER_SEND_MESSAGE_REQUEST = 'USER_SEND_MESSAGE_REQUEST'
export const USER_SEND_MESSAGE_SUCCESS = 'USER_SEND_MESSAGE_SUCCESS'
export const USER_SEND_MESSAGE_FAIL = 'USER_SEND_MESSAGE_FAIL'
export const USER_SEND_MESSAGE_RESET = 'USER_SEND_MESSAGE_RESET'

export const USER_READ_MESSAGE_REQUEST = 'USER_READ_MESSAGE_REQUEST'
export const USER_READ_MESSAGE_SUCCESS = 'USER_READ_MESSAGE_SUCCESS'
export const USER_READ_MESSAGE_FAIL = 'USER_READ_MESSAGE_FAIL'

export const AI_CHAT_REQUEST = 'AI_CHAT_REQUEST'
export const AI_CHAT_SUCCESS = 'AI_CHAT_SUCCESS'
export const AI_CHAT_FAIL = 'AI_CHAT_FAIL'
export const AI_CHAT_RESET = 'AI_CHAT_RESET'

export const AI_CHAT_HISTORY_REQUEST = 'AI_CHAT_HISTORY_REQUEST'
export const AI_CHAT_HISTORY_SUCCESS = 'AI_CHAT_HISTORY_SUCCESS'
export const AI_CHAT_HISTORY_FAIL = 'AI_CHAT_HISTORY_FAIL'
