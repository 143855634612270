import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { getAllWithdrawRequest } from '../../../actions/adminActions/farmtroveActions/getAllWithdrawRequest'
import { updateWithdrawRequest } from '../../../actions/adminActions/farmtroveActions/updateWithdrawRequest'
import { MDBDataTableV5 } from 'mdbreact'
import { MessageBox, LoadingBox } from '../../../components'
import moment from 'moment'
import { formatNumber } from '../../../components/functions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Banks from '../../../components/Banks'
import { TailSpin } from 'react-loading-icons'
import { UPDATE_WITHDRAWREQUEST_RESET } from '../../../constants/adminConstants/farmtrove/walletConstants'

export default function WithdrawRequestPage() {
  const [datatable, setDatatable] = useState({})
  const [open, setOpen] = useState(false)
  const [detail, setDetail] = useState({})
  const [itemId, setItemId] = useState('')
  const [status, setStatus] = useState('')
  const [bank, setBank] = useState('')

  const withdrawRequests = useSelector((state) => state.withdrawRequests)
  const { loading, error, requests } = withdrawRequests

  const updateWithdrawStatus = useSelector(
    (state) => state.updateWithdrawStatus,
  )
  const {
    loading: loadingUpdate,
    error: updateError,
    updated,
  } = updateWithdrawStatus

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllWithdrawRequest())
  }, [dispatch, updated])

  useEffect(() => {
    if (requests) {
      setDatatable({
        columns: [
          {
            label: 'Status',
            field: 'status',
            width: 50,
          },
          {
            label: 'Full Name',
            field: 'name',
            width: 100,
          },
          {
            label: 'User ID',
            field: 'user',
            width: 100,
          },
          {
            label: 'Amount',
            field: 'amount',
            width: 100,
          },
          {
            label: 'Date',
            field: 'date',
            width: 100,
          },

          {
            label: 'Action',
            field: 'action',
            width: 50,
          },
        ],
        rows: requests.map((item) => ({
          user: item.user && item.user.phone,
          name:
            item.user && item.user.firstName && item.user.lastName
              ? `${item.user.firstName} ${item.user.lastName}`
              : '',
          amount: item.amount ? `NGN ${formatNumber(item.amount)}.00` : '',
          date: moment(item.created_at).format('Do MMMM YYYY,  h:mm a'),
          status: (
            <div
              className={`badge badge-soft-${
                item.status === 'Pending'
                  ? 'primary'
                  : item.status === 'Completed'
                  ? 'success'
                  : item.status === 'Processing'
                  ? 'warning'
                  : item.status === 'Declined'
                  ? 'danger'
                  : ''
              } font-size-13`}
            >
              {item.status}
            </div>
          ),
          action: (
            <button
              className="btn btn-sm btn-success"
              onClick={() => openDialog(item)}
            >
              View Request
            </button>
          ),
        })),
      })
    }
  }, [requests])

  useEffect(() => {
    setTimeout(() => {
      setOpen(false)
      setStatus('')
      setItemId('')
      setDetail({})
      dispatch({ type: UPDATE_WITHDRAWREQUEST_RESET })
    }, 2200)
  }, [updated])

  const openDialog = (item) => {
    setOpen(true)
    setDetail(item)
    setStatus(item.status)
    setItemId(item._id)

    let cbnCode = item.bank_code

    const bank = Banks.find((item) => item.cbn_code === cbnCode)
    if (bank) {
      setBank(bank.bank_name)
    }
  }

  const closeDialog = () => {
    setOpen(false)
    setStatus('')
    setItemId('')
    setDetail({})
  }

  const handleUpdate = (e) => {
    e.preventDefault()

    dispatch(updateWithdrawRequest(itemId, status))
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Withdraw Request</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmtrove</a>
                      </li>
                      <li className="breadcrumb-item active">
                        User Withdraw Request
                      </li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {error ? (
                      <MessageBox variant="danger">{error}</MessageBox>
                    ) : null}
                    {loading ? (
                      <LoadingBox />
                    ) : (
                      <div className="table-responsive-xl">
                        <MDBDataTableV5
                          hover
                          entriesOptions={[5, 20, 25, 50]}
                          entries={5}
                          pagesAmount={4}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                          barReverse
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'md'}
      >
        <div className="modal-header">
          <h5 className="modal-title">Withdraw Request</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeDialog}
          ></button>
        </div>
        <DialogContent>
          {loadingUpdate ? (
            <div className="tailspin" style={{ height: 'calc(20vh - 40px)' }}>
              <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
            </div>
          ) : (
            <div className="mt-3">
              <div className="row">
                <div className="col-md-12">
                  {updateError ? (
                    <MessageBox variant="danger">{updateError}</MessageBox>
                  ) : null}
                  {updated ? (
                    <MessageBox variant="success">{updated.message}</MessageBox>
                  ) : null}
                  <div className="d-flex">
                    <div className="avatar-sm me-3">
                      <span className="avatar-title bg-dark rounded-circle text-white font-size-24">
                        ₦
                      </span>
                    </div>
                    <div className="flex-1 align-self-center overflow-hidden">
                      <h5 className="mb-0 mr-4">
                        {detail.amount
                          ? `NGN ${formatNumber(detail.amount)}.00`
                          : 'NGN 0.00'}
                        <small
                          className={`badge badge-soft-${
                            detail.status === 'Pending'
                              ? 'primary'
                              : detail.status === 'Completed'
                              ? 'success'
                              : detail.status === 'Processing'
                              ? 'warning'
                              : detail.status === 'Declined'
                              ? 'danger'
                              : ''
                          } font-size-16`}
                          style={{ marginLeft: '10px' }}
                        >
                          {detail.status}
                        </small>
                      </h5>
                      <p className="text-muted mb-0 font-size-13">
                        Reference: {detail && detail.reference}
                      </p>
                    </div>
                  </div>

                  <hr className="my-4" />

                  <div className="row mt-4">
                    <div className="col-md-3">
                      <div className="">
                        <p className="mb-0">Fees</p>
                        <h5 className="">NGN 0.00</h5>

                        <hr className="my-4" />
                      </div>

                      <div class="mt-4 mt-xl-3">
                        <div className="">
                          <p className="mb-1 font-size-13">Bank</p>
                          <h5 class="mt-1 mb-3">{bank}</h5>

                          <p className="mb-1 font-size-13">Account Number</p>
                          <h5 class="mt-1 mb-3">{detail && detail.account}</h5>

                          <p className="mb-1 font-size-13">Account Name</p>
                          <h5 class="mt-1 mb-3">
                            {detail && detail.account_name}
                          </h5>

                          <hr class="my-4" />
                        </div>

                        <div>
                          <h5 class="font-size-14">
                            <i class="mdi mdi-location"></i> Details
                          </h5>

                          <div className="mt-4">
                            <p className="mb-1 font-size-13">Date Submitted</p>
                            <h5 className="mt-1 mb-3 font-size-14">
                              {moment(detail && detail.created_at).format(
                                'Do MMMM YYYY,  h:mm a',
                              )}
                            </h5>
                          </div>

                          <hr class="my-4" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9 mt-3">
                      <div className="">
                        <h5 class="font-size-14">
                          <i class="mdi mdi-location"></i> User Details
                        </h5>

                        <hr class="my-3" />
                      </div>

                      <div className="row mt-4">
                        <div className="col-xl-4 text-center">
                          <img
                            src={
                              detail.user && detail.user.image
                                ? detail.user.image
                                : 'assets/images/default-user.png'
                            }
                            alt=""
                            className="mt-0 avatar-lg round-circle mx-auto"
                            style={{ width: '12rem', height: '12rem' }}
                          />
                        </div>
                        <div className="col-xl-8">
                          <div className="row">
                            <div className="col-md-4">
                              <p className="mb-1 font-size-12">User ID</p>
                              <h5 class="mt-1 mb-3 font-size-14">
                                {detail && detail.user && detail.user.phone}
                              </h5>
                            </div>
                            <div className="col-md-4">
                              <p className="mb-1 font-size-12">Full Name</p>
                              <h5 class="mt-1 mb-3 font-size-14">
                                {detail &&
                                detail.user &&
                                detail.user.firstName &&
                                detail.user.lastName
                                  ? `${detail.user.firstName} ${detail.user.lastName}`
                                  : ''}
                              </h5>
                            </div>

                            <div className="col-md-4">
                              <p className="mb-1 font-size-12">Account</p>
                              <h5 class="mt-1 mb-3 font-size-14">
                                {detail && detail.user && detail.user.account}
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <p className="mb-1 font-size-12">Email</p>
                              <h5 class="mt-1 mb-3 font-size-14">
                                {detail && detail.user && detail.user.email}
                              </h5>
                            </div>

                            <div className="col-md-6">
                              <p className="mb-1 font-size-12">State</p>
                              <h5 class="mt-1 mb-3 font-size-14">
                                {detail && detail.user && detail.user.state}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-5">
                        <h5 class="font-size-14">
                          <i class="mdi mdi-location"></i> Withdraw Request
                          Status
                        </h5>

                        <hr class="my-3" />

                        <div className="row">
                          <div className="col-md-12">
                            <p className="">
                              This feature allows administrators to swiftly
                              review and process withdrawal requests. Confirm
                              that the account information provided by the user
                              matches during payout. Validate that the
                              withdrawal amount.
                            </p>

                            <div className="card">
                              <div
                                className="card-body bg-soft-danger text-black"
                                style={{ borderRadius: '1rem' }}
                              >
                                <p className="m-0">
                                  {detail.amount
                                    ? `NGN ${formatNumber(detail.amount)}.00`
                                    : 'NGN 0.00'}
                                </p>
                              </div>
                            </div>

                            {detail.status === 'Completed' ? (
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="d-grid">
                                    <button
                                      className="btn btn-danger btn-md mt-3 mb-3"
                                      onClick={closeDialog}
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <form onSubmit={handleUpdate}>
                                <select
                                  className="form-select form-control select2"
                                  onChange={(e) => setStatus(e.target.value)}
                                  value={status}
                                >
                                  <option value="Processing">Processing</option>
                                  <option value="Completed">Completed</option>
                                  <option value="Pending">Pending</option>
                                  <option value="Declined">Declined</option>
                                </select>

                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="d-grid">
                                      <button
                                        className="btn btn-success btn-md mt-3 mb-3"
                                        type="submit"
                                      >
                                        Confirm
                                      </button>
                                    </div>
                                  </div>

                                  <div className="col-sm-6">
                                    <div className="d-grid">
                                      <button
                                        className="btn btn-danger btn-md mt-3 mb-3"
                                        onClick={closeDialog}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Footer />
    </div>
  )
}
