import axios from 'axios'
import {
  MANUFACTURER_ACTIVATE_TAG_REQUEST,
  MANUFACTURER_ACTIVATE_TAG_SUCCESS,
  MANUFACTURER_ACTIVATE_TAG_FAIL,
} from '../../../constants/manufacturerConstants/labelsConstants'

export const tagActivation = (tag) => async (dispatch, getState) => {
  dispatch({
    type: MANUFACTURER_ACTIVATE_TAG_REQUEST,
    payload: { tag },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/manufacturer/activate-label',
      { tag },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: MANUFACTURER_ACTIVATE_TAG_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: MANUFACTURER_ACTIVATE_TAG_FAIL, payload: message })
  }
}
