import axios from 'axios'
import {
  TAGS_PDF_DOWNLOAD_REQUEST,
  TAGS_PDF_DOWNLOAD_SUCCESS,
  TAGS_PDF_DOWNLOAD_FAIL,
} from '../../../constants/adminConstants/TagsConstants'

export const downloadTagsPDF = (batchId, batchNum) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: TAGS_PDF_DOWNLOAD_REQUEST, payload: { batchId, batchNum } })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.post(
      '/api/admin/generate-pdf',
      {
        batchId,
        batchNum,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: TAGS_PDF_DOWNLOAD_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: TAGS_PDF_DOWNLOAD_FAIL, payload: message })
  }
}
