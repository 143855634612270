import {
    SEND_OTP_CODE_FAIL,
    SEND_OTP_CODE_REQUEST,
    SEND_OTP_CODE_RESET,
    SEND_OTP_CODE_SUCCESS,
    VERIFY_OTP_CODE_FAIL,
    VERIFY_OTP_CODE_REQUEST,
    VERIFY_OTP_CODE_RESET,
    VERIFY_OTP_CODE_SUCCESS,
} from '../../../constants/adminConstants/farmtrove/verifyAdminConstants'

export function adminSendOtpReducer(state = {}, action) {
    switch (action.type) {
        case SEND_OTP_CODE_REQUEST:
            return { loading: true }
        case SEND_OTP_CODE_SUCCESS:
            return { loading: false, result: action.payload }
        case SEND_OTP_CODE_FAIL:
            return { loading: false, error: action.payload }
        case SEND_OTP_CODE_RESET:
            return {}
        default:
            return state
    }
}

export function adminVerifyOtpReducer(state = {}, action) {
    switch (action.type) {
        case VERIFY_OTP_CODE_REQUEST:
            return { loading: true }
        case VERIFY_OTP_CODE_SUCCESS:
            return { loading: false, verify: action.payload }
        case VERIFY_OTP_CODE_FAIL:
            return { loading: false, error: action.payload }
        case VERIFY_OTP_CODE_RESET:
            return {}
        default:
            return state
    }
}
