import {
  STATUS_VALIDATION_FAIL,
  STATUS_VALIDATION_REQUEST,
  STATUS_VALIDATION_RESET,
  STATUS_VALIDATION_SUCCESS,
} from '../../constants/authConstants/statusConstants'

export const statusValidateReducer = (state = {}, action) => {
  switch (action.type) {
    case STATUS_VALIDATION_REQUEST:
      return { loading: true }
    case STATUS_VALIDATION_SUCCESS:
      return { loading: false, result: action.payload }
    case STATUS_VALIDATION_FAIL:
      return { loading: false, error: action.payload }
    case STATUS_VALIDATION_RESET:
      return {}
    default:
      return state
  }
}
