import React, { useState, useEffect } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { MDBDataTableV5 } from 'mdbreact'
import Select from 'react-select'
import { AllService } from '../../../actions/adminActions/serviceActions/allServices'
import { editService } from '../../../actions/adminActions/serviceActions/editService'
import {
  SERVICE_PROVIDER_DELETE_RESET,
  UPDATE_SERVICE_PROVIDER_RESET,
} from '../../../constants/adminConstants/serviceConstants'
import { deleteService } from '../../../actions/adminActions/serviceActions/deleteService'
import { AllBenefits } from '../../../actions/adminActions/benefitsActions/allBenefits'
import { allServiceCategory } from '../../../actions/adminActions/serviceActions/allServiceCategory'
import NaijaStates from 'naija-state-local-government'
import states from '../../../utilis'
import SweetAlert from 'react-bootstrap-sweetalert'

function ServicePage() {
  const [alert, setAlert] = useState(null)
  const [itemId, setItemId] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [uniqueCode, setUniqueCode] = useState('')
  const [name, setName] = useState('')
  const [benefit, setBenefit] = useState([])
  const [category, setCategory] = useState([])
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [lga, setLga] = useState('')
  const [state, setState] = useState('')
  const [datatable, setDatatable] = useState({})
  const [options, setOptions] = useState([])
  const [options1, setOptions1] = useState([])

  const dispatch = useDispatch()

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  // All Service State
  const serviceList = useSelector((state) => state.serviceList)
  const { loading, allservices, error } = serviceList

  // Delete Service State
  const deletedService = useSelector((state) => state.deletedService)
  const {
    loading: loadingDelete,
    error: deleteErr,
    serviceDeleted,
  } = deletedService

  // Benefit State
  const benefitsList = useSelector((state) => state.benefitsList)
  const {
    loading: loadingBenefit,
    error: benefitsErr,
    allbenefits,
  } = benefitsList

  // Edit Benefit State
  const updateService = useSelector((state) => state.updateService)
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateErr,
  } = updateService

  const serviceCategory = useSelector((state) => state.serviceCategory)
  const { categorylist } = serviceCategory

  const handleEditOpen = (item) => {
    setOpenDialog(true)
    setItemId(item._id)
    setUniqueCode(item.uniqueCode)
    setName(item.name)
    setCategory(item.category)
    setBenefit(item.benefit)
    setEmail(item.email)
    setPhone(item.phone)
    setAddress(item.address)
    setState(item.state)
    setLga(item.lga)
  }

  const updateHandler = (e) => {
    e.preventDefault()

    dispatch(
      editService(
        itemId,
        uniqueCode,
        name,
        category,
        benefit,
        email,
        phone,
        address,
        state,
        lga,
      ),
    )
    setOpenDialog(false)
  }

  useEffect(() => {
    dispatch(AllService())
    dispatch(AllBenefits())
    dispatch(allServiceCategory())

    if (serviceDeleted) {
      setAlert(null)
      setTimeout(() => {
        dispatch({ type: SERVICE_PROVIDER_DELETE_RESET })
      }, 3000)
    }

    if (updateSuccess) {
      setTimeout(() => {
        dispatch({ type: UPDATE_SERVICE_PROVIDER_RESET })
      }, 3000)
    }
  }, [dispatch, serviceDeleted, updateSuccess])

  const deleteHandler = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deleteService(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  useEffect(() => {
    if (allservices) {
      setDatatable({
        columns: [
          {
            label: 'Unique Code',
            field: 'uniqueCode',
            width: 30,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'uniqueCode',
            },
          },
          {
            label: 'Organisation',
            field: 'organisation',
          },
          {
            label: 'Name',
            field: 'name',
            width: 200,
          },
          {
            label: 'Category',
            field: 'category',
            sort: 'disabled',
            width: 200,
          },
          {
            label: 'Service',
            field: 'service',
            sort: 'disabled',
            width: 200,
          },
          {
            label: 'Email',
            field: 'email',
            width: 200,
          },
          {
            label: 'Phone',
            field: 'phone',
            width: 100,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
            width: 50,
          },
        ],
        rows: allservices.map((item) => ({
          uniqueCode: item.uniqueCode,
          organisation:
            item.organisation && item.organisation.name
              ? item.organisation.name
              : '',
          name: item.name,
          category:
            item.category && item.category.length < 0 ? (
              <div>There is no category</div>
            ) : (
              item.category.map((item) => (
                <div className={`badge badge-soft-success font-size-12 m-1`}>
                  {item.name}
                </div>
              ))
            ),
          service:
            item.benefit && item.benefit.length < 0 ? (
              <div>There is no benefit</div>
            ) : (
              item.benefit.map((item) => (
                <div className={`badge badge-soft-success font-size-12 m-1`}>
                  {item.benefit}
                </div>
              ))
            ),
          email: item.email,
          phone: item.phone ? `0${item.phone}` : '', 
          action: (
            <>
              <button
                onClick={() => handleEditOpen(item)}
                style={{ marginRight: '15px' }}
                className="btn btn-sm m-0 btn-primary"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Manage"
              >
                Manage
              </button>
              <button
                className="btn btn-sm m-0 text-danger"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Delete"
                onClick={() => deleteHandler(item)}
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </button>
            </>
          ),
        })),
      })
    }
  }, [allservices])

  useEffect(() => {
    if (categorylist && categorylist.length > 0) {
      setOptions(categorylist)
    }

    if (allbenefits && allbenefits.length > 0) {
      setOptions1(allbenefits)
    }
  }, [categorylist, allbenefits])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Service Provider</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">
                          Benefit Service Provider
                        </a>
                      </li>
                      <li className="breadcrumb-item active">
                        Service Provider
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {updateLoading && <LoadingBox />}
            {updateErr && <MessageBox variant="danger">{updateErr}</MessageBox>}
            {updateSuccess && (
              <MessageBox variant="success">
                Service provider successfully updated
              </MessageBox>
            )}
            {loadingDelete && <LoadingBox />}
            {serviceDeleted && (
              <MessageBox variant="success">
                {serviceDeleted.message}
              </MessageBox>
            )}
            {deleteErr && <MessageBox variant="danger">{deleteErr}</MessageBox>}
            {benefitsErr && (
              <MessageBox variant="danger">{benefitsErr}</MessageBox>
            )}
            {/* <!-- end page title --> */}
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive-xl">
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 20, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Update Benefit */}
                <Dialog
                  open={openDialog}
                  onClose={handleDialogClose}
                  aria-labelledby="form-dialog-title"
                  fullWidth
                  maxWidth={'lg'}
                >
                  <>
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Service Provider</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleDialogClose}
                      ></button>
                    </div>
                    <DialogContent>
                      <div className="">
                        <form onSubmit={updateHandler}>
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label className="col-md-12 col-form-label">
                                Unique Code
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Unique Code"
                                id="example-text-input"
                                onChange={(e) => setUniqueCode(e.target.value)}
                                value={uniqueCode}
                                required
                                disabled
                              />
                            </div>

                            <div className="col-md-12">
                              <label className="col-md-12 col-form-label">
                                Name
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Name"
                                id="example-text-input"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                              />
                            </div>

                            <div className="col-md-12">
                              <label className="col-md-12 col-form-label">
                                Benefits
                              </label>
                              <Select
                                onChange={(item) => setBenefit(item)}
                                defaultValue={benefit.map((opt) => ({
                                  label: opt.benefit,
                                  value: opt._id,
                                }))}
                                required
                                placeholder="Select benefit"
                                isMulti
                                options={options1.map((item) => ({
                                  label: item.benefit,
                                  value: item._id,
                                }))}
                              />
                            </div>

                            <div className="col-md-12">
                              <label className="col-md-12 col-form-label">
                                Service Category
                              </label>
                              <Select
                                onChange={(opt) => setCategory(opt)}
                                defaultValue={category.map((opt) => ({
                                  label: opt.name,
                                  value: opt._id,
                                }))}
                                required
                                isMulti
                                placeholder="Select category"
                                options={options.map((opt) => ({
                                  label: opt.name,
                                  value: opt._id,
                                }))}
                              />
                            </div>

                            <div className="col-md-12">
                              <label className="col-md-12 col-form-label">
                                Email
                              </label>
                              <input
                                className="form-control"
                                type="email"
                                placeholder="Email"
                                id="example-text-input"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                required
                              />
                            </div>

                            <div className="col-md-12">
                              <label className="col-md-12 col-form-label">
                                Phone
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Phone"
                                id="example-text-input"
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                                required
                              />
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="col-md-12 col-form-label">
                                Address
                              </label>
                              <input
                                id="address"
                                name="address"
                                type="text"
                                className="form-control form-control"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </div>

                            <div className="col-md-6">
                              <label
                                htmlFor="example-text-input"
                                className="col-md-12 col-form-label"
                              >
                                Select a state
                              </label>
                              <select
                                className="form-select form-control select2"
                                required
                                placeholder="Select a state"
                                onChange={(e) => setState(e.target.value)}
                                value={state}
                              >
                                <option>Select a state</option>
                                {states.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-md-6">
                              <label
                                htmlFor="example-text-input"
                                className="col-form-label"
                              >
                                Select a local government area
                              </label>
                              <select
                                className="form-select form-control select2"
                                required
                                name="lga"
                                placeholder="Select a local government area"
                                onChange={(e) => setLga(e.target.value)}
                                value={lga}
                              >
                                {state && state === 'Select a state' ? (
                                  <option>
                                    Select a local government area
                                  </option>
                                ) : state ? (
                                  <>
                                    <option>
                                      Select a local government area
                                    </option>
                                    {NaijaStates.lgas(state).lgas.map(
                                      (item) => (
                                        <option value={item} key={item}>
                                          {item}
                                        </option>
                                      ),
                                    )}
                                  </>
                                ) : (
                                  <option>
                                    Select a local government area
                                  </option>
                                )}
                              </select>
                            </div>
                          </div>
                          <DialogActions>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={handleDialogClose}
                            >
                              Close
                            </button>
                            <button
                              className="btn btn-success btn-sm"
                              type="submit"
                            >
                              Update
                            </button>
                          </DialogActions>
                        </form>
                      </div>
                    </DialogContent>
                  </>
                </Dialog>
              </div>
            )}
          </div>
          {alert}
          {/* <!-- container-fluid --> */}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ServicePage
