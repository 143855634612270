import axios from 'axios'
import {
  ASSIGNED_PRODUCT_CATEGORY_POINT_FAIL,
  ASSIGNED_PRODUCT_CATEGORY_POINT_REQUEST,
  ASSIGNED_PRODUCT_CATEGORY_POINT_SUCCESS,
} from '../../../constants/adminConstants/productConstants'

export const allAssignedPC = () => async (dispatch, getState) => {
  dispatch({ type: ASSIGNED_PRODUCT_CATEGORY_POINT_REQUEST })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get(
      '/api/admin/assigned-products-category-point',
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: ASSIGNED_PRODUCT_CATEGORY_POINT_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: ASSIGNED_PRODUCT_CATEGORY_POINT_FAIL, payload: message })
  }
}
