import React, { useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { validate } from '../../../actions/adminActions/tagActions/validateStatus'
import { LoadingBox } from '../../../components'
import Modal from 'react-modal'
import { TAG_STATUS_VALIDATION_RESET } from '../../../constants/adminConstants/TagsConstants'
import moment from 'moment'
import { CountdownStatus } from '../../../components/Counter/CountdownTimer'

function TagStatusPage() {
  const [tag, setTag] = useState('')

  const dispatch = useDispatch()

  const statusValidation = useSelector((state) => state.statusValidation)
  const { loading, error, result } = statusValidation

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(validate(tag))
  }

  const resetError = (e) => {
    e.preventDefault()
    dispatch({ type: TAG_STATUS_VALIDATION_RESET })
  }

  const customStyles = {
    content: {
      maxWidth: '810px',
      margin: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },

    modalConfirm: {
      color: '#434e65',
      maxWidth: '100%',
    },
  }

  const errorCustomStyles = {
    content: {
      width: '400px',
      margin: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },

    modalConfirm: {
      color: '#434e65',
      maxWidth: '100%',
    },
  }

  const dateTime = (item) => {
    const c =
      item.batch && item.batch.product && item.batch.product.category
        ? item.batch.product.category.cycleDuration
        : 0

    let hrs = c * 60 * 60 * 1000

    const d = new Date(item.redActivatedAt)
    let ms = d.getTime()
    return ms + hrs
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}

            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Benefit</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Benefit</a>
                      </li>
                      <li className="breadcrumb-item active">Benefit Status</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <LoadingBox />
            ) : (
              <div className="row">
                <div className="card">
                  <div className="card-body">
                    <form className="form" onSubmit={handleSubmit}>
                      <div
                        id="container"
                        style={{
                          minHeight: '70vh',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <p className="font-size-16">
                          Benefit status verification portal
                        </p>
                        <small className="font-size-13">
                          Enter the pin to verify the status
                        </small>

                        <div className="input-container center">
                          <div className="container__item">
                            <div className="row">
                              <div className="col-lg-12">
                                <input
                                  style={{ minWidth: '230px' }}
                                  type="number"
                                  className="form-control form__field"
                                  placeholder="Tag Number"
                                  onChange={(e) => setTag(e.target.value)}
                                  required
                                />
                                <button
                                  type="submit"
                                  className="btn btn--primary mt-3 uppercase"
                                >
                                  Check
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {error ? (
          <div>
            <Modal
              isOpen
              onRequestClose={resetError}
              style={errorCustomStyles}
              contentLabel="Example Modal"
            >
              <div
                className="modal-header-false"
                style={{
                  background: '#d13e3e',
                  borderBottom: 'none',
                  position: 'relative',
                  textAlign: 'center',
                  margin: '-20px -20px 0',
                  borderRadius: '5px 5px 0 0',
                  padding: '35px',
                }}
              >
                <div
                  className="icon-box"
                  style={{
                    color: '#fff',
                    textAlign: 'center',
                  }}
                >
                  <center>
                    <i
                      className="font-circle fa fa-times"
                      style={{
                        borderRadius: '60%',
                        display: 'inline-block',
                        zIndex: 9,
                        border: '5px solid #fff',
                        padding: '20px 30px 20px 30px',
                        fontSize: '70px',
                        margin: '-5px 0 0 -5px',
                        textAlign: 'center',
                      }}
                    ></i>
                  </center>
                </div>
              </div>
              <div className="modal-body text-center">
                <p className="model-text">
                  Hello <br />
                </p>
                <h4
                  style={{
                    textAlign: 'center',
                    fontSize: '16.5px',
                    margin: '10px 0',
                  }}
                >
                  TRY AGAIN
                </h4>
                <h6
                  style={{
                    textAlign: 'center',
                    fontSize: '15px',
                    margin: '10px 0',
                  }}
                >
                  {error}
                </h6>
                <br />
                <button
                  className="btn btn-success"
                  onClick={resetError}
                  style={{
                    color: '#fff',
                    borderRadius: '4px',
                    textDecoration: 'none',
                    transition: 'all 0.4s',
                    lineHeight: 'normal',
                    borderRadius: '30px',
                    marginTop: '10px',
                    padding: '6px 20px 10px ',
                    border: 'none',
                  }}
                >
                  Continue
                </button>
              </div>
            </Modal>
          </div>
        ) : null}

        {result ? (
          <div>
            <Modal
              isOpen
              onRequestClose={resetError}
              style={customStyles}
              contentLabel="Modal"
            >
              <div className="modal-body ">
                <div
                  className="model-text"
                  style={{
                    fontSize: '19px',
                  }}
                >
                  <div
                    className="modal-header-false"
                    style={{
                      background: '#47c9a2',
                      borderBottom: 'none',
                      position: 'relative',
                      textAlign: 'center',
                      margin: '-35px -36px 0',
                      borderRadius: '5px 5px 0 0',
                      padding: '35px',
                    }}
                  >
                    <div
                      className="icon-box"
                      style={{
                        color: '#fff',
                        textAlign: 'center',
                      }}
                    >
                      <center>
                        <i
                          className="font-circle fa fa-check"
                          style={{
                            borderRadius: '60%',
                            display: 'inline-block',
                            zIndex: 9,
                            border: '5px solid #fff',
                            padding: '20px 20px 20px 20px',
                            fontSize: '70px',
                            margin: '-5px 0 0 -5px',
                            textAlign: 'center',
                          }}
                        ></i>
                      </center>
                    </div>
                  </div>
                  {result && result.farmer ? (
                    <div className="mt-4">
                      <div className="row">
                        <div className="col-md-7">
                          <h6 className="font-size-17">Product Detail</h6>
                          <p
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Product Name:{' '}
                            {result && result.batch.product
                              ? result.batch.product.name
                              : null}
                          </p>
                          <p
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Product Category:{' '}
                            {result &&
                            result.batch &&
                            result.batch.product &&
                            result.batch.product.category
                              ? result.batch.product.category.name
                              : null}
                          </p>
                          <p
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Manufacturer:{' '}
                            {result && result.batch && result.batch.assignedTo
                              ? result.batch.assignedTo.name
                              : null}
                          </p>
                          <p
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Tag Number:{' '}
                            {result && result.tag ? result.tag : null}
                          </p>
                          <p
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Batch Number:{' '}
                            {result && result.batch ? result.batch.batch : null}
                          </p>
                          <p
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Expected Point:{' '}
                            {result &&
                            result.batch &&
                            result.batch.product &&
                            result.batch.product.category
                              ? result.batch.product.category.expectedPoint
                              : null}
                          </p>
                        </div>
                        <div className="col-md-5">
                          <h6 className="font-size-17">Farmer Detail</h6>
                          <p
                            className=" "
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Farmer ID:{' '}
                            {result && result.farmer
                              ? `0${result.farmer.phone}`
                              : null}{' '}
                            <a
                              href={`/farmer/detail/${result.farmer._id}`}
                              className="btn btn-sm btn-success p-1"
                            >
                              View Detail
                            </a>
                          </p>
                          <p
                            className=""
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Earned Point:{' '}
                            {result.redActivatedAt && !result.greenActivatedAt
                              ? result.points
                              : result.redActivatedAt && result.greenActivatedAt
                              ? result.points + result.points
                              : null}{' '}
                            point
                          </p>
                          <p className="">
                            Date Verified:{' '}
                            {result && result.redActivatedAt ? (
                              moment(result.redActivatedAt).format(
                                'Do MMMM YYYY,  h:mm a',
                              )
                            ) : (
                              <div
                                className={`badge badge-soft-danger font-size-12`}
                              >
                                Not Verified
                              </div>
                            )}
                          </p>
                          <p className="">
                            Date Activated:{' '}
                            {result && result.greenActivatedAt ? (
                              moment(result.greenActivatedAt).format(
                                'Do MMMM YYYY,  h:mm a',
                              )
                            ) : (
                              <div
                                className={`badge badge-soft-danger font-size-12`}
                              >
                                Not Activated
                              </div>
                            )}
                          </p>
                          <p className="">
                            Benefit Status:{' '}
                            <CountdownStatus
                              item={result}
                              targetDate={dateTime(result)}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <center>
                        <p>This product has not been verified</p>
                      </center>
                    </>
                  )}
                </div>
                <center>
                  <button
                    className="btn btn-success"
                    onClick={resetError}
                    style={{
                      color: '#fff',
                      borderRadius: '4px',
                      textDecoration: 'none',
                      transition: 'all 0.4s',
                      lineHeight: 'normal',
                      borderRadius: '30px',
                      marginTop: '10px',
                      padding: '6px 20px 10px ',
                      border: 'none',
                    }}
                  >
                    Close
                  </button>
                </center>
              </div>
            </Modal>
          </div>
        ) : null}
      </div>
      <Footer />
    </div>
  )
}

export default TagStatusPage
