import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MessageBox } from '../../../components'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { getNotAssignedBatch } from '../../../actions/adminActions/tagActions/getBatchs'
import { allProductCategory } from '../../../actions/adminActions/productActions/productCategory'
import { downloadTagsPDF } from '../../../actions/adminActions/tagActions/downloadTagsPDF'
import { TailSpin } from 'react-loading-icons'
// import { TAGS_PDF_DOWNLOAD_RESET } from '../../../constants/adminConstants/TagsConstants'
import QRCode from 'react-qr-code'

function LabelGenerationPage() {
  const [alert, setAlert] = useState(null)
  const [batchId, setBatchId] = useState('')
  const [batchNum, setBatchNum] = useState('')
  const [tab1, setTab1] = useState(false)
  const [tab2, setTab2] = useState(false)
  const [category, setProductCategory] = useState('')

  // Not Assigned Batch State
  const notAssignedBatchs = useSelector((state) => state.notAssignedBatchs)
  const { loading: loadingLists, error: listError, lists } = notAssignedBatchs

  // Product Category State
  const productCategory = useSelector((state) => state.productCategory)
  const { loading: loadingCategory, categorylist } = productCategory

  // Generate Tag State
  const downloadPDF = useSelector((state) => state.downloadPDF)
  const { loading: loadingCodes, error: pdfDwlodError, codes } = downloadPDF

  const dispatch = useDispatch()

  const fileName = `Farmsured_Labels_${batchNum}_${Date.now()}.pdf`

  useEffect(() => {
    dispatch(allProductCategory())
  }, [dispatch])

  useEffect(() => {
    if (category === 'Select a category') {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="danger"
          title="Category is empty"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Category field can not be empty!!!
        </SweetAlert>,
      )
    } else if (category) dispatch(getNotAssignedBatch(category))
  }, [dispatch, category])

  const handleLabelGeneration = (e) => {
    e.preventDefault()
    if (!batchId) {
    } else {
      dispatch(downloadTagsPDF(batchId, batchNum))
      setTab2(true)
      setTab1(false)
    }
  }

  const handlePreviousTag = () => {
    setTab1(true)
    setTab2(false)
  }

  // useEffect(() => {
  //   if (codes) {
  //     const downloadPDF = async () => {
  //       const blob = new Blob([codes], { type: 'application/pdf' })
  //       const url = URL.createObjectURL(blob)
  //       const link = document.createElement('a')
  //       link.href = url
  //       link.download = `${fileName}.pdf`
  //       // link.click()
  //       URL.revokeObjectURL(url)
  //       setBatchId('')
  //       dispatch({ type: TAGS_PDF_DOWNLOAD_RESET })
  //     }
  //     downloadPDF()
  //   }
  // }, [codes, dispatch])

  const handlePrint = () => {
    window.print()
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Generate Label</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Tags</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Label Generation
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {/* Page content starts here  */}
            <div className="row">
              <div className="col-lg-12">
                {tab2 ? (
                  <div>
                    <div className="mb-3">
                      <button
                        className="btn btn-success btn-bg"
                        onClick={handlePreviousTag}
                      >
                        Previous
                      </button>
                      <div className="float-end">
                        <button
                          className="btn btn-success btn-bg"
                          onClick={handlePrint}
                        >
                          Generate PDF
                        </button>
                      </div>
                    </div>
                    <>
                      {loadingCodes ? (
                        <TailSpin />
                      ) : (
                        <div>
                          <div
                            class="blue"
                            style={{
                              marginRight: '60px',
                              position: 'relative',
                            }}
                          >
                            <img
                              src="assets/images/blue_tag.png"
                              width="300"
                            />
                            <div
                              class="code"
                              style={{
                                position: 'absolute',
                                top: '20px',
                                left: '180px',
                                background: 'rgba(0, 0, 0, 0)',
                              }}
                            >
                              <div
                                id="qrcodeblue"
                                style={{
                                  height: '70px',
                                  position: 'relative',
                                  top: '48px',
                                  right: '70px',
                                }}
                              >
                                <QRCode
                                  size={256}
                                  style={{
                                    height: 'auto',
                                    maxWidth: '100%',
                                    width: '23%',
                                  }}
                                  value={`blue/${batchNum}`}
                                  viewBox={`0 0 256 256`}
                                />
                              </div>
                              <p
                                style={{
                                  padding: '0px',
                                  margin: '0px',
                                  color: 'black',
                                  fontSize: '15px',
                                  lineHeight: '14.06px',
                                }}
                              >
                                <b>{batchNum}</b>
                              </p>
                            </div>
                          </div>
                          {codes && codes.length > 0
                            ? codes.map((item) => (
                                <div
                                  style={{
                                    marginBottom: '0px',
                                    display: 'flex',
                                  }}
                                >
                                  <div
                                    className="red"
                                    style={{ position: 'relative' }}
                                  >
                                    <img
                                      src="assets/images/red_tag.png"
                                      width="300"
                                    />
                                    <div
                                      className="code"
                                      style={{
                                        position: 'absolute',
                                        top: '25px',
                                        right: '50px',
                                        background: 'rgba(0, 0, 0, 0)',
                                      }}
                                    >
                                      <div
                                        id="qrcodered"
                                        style={{
                                          height: '70px',
                                          position: 'relative',
                                          top: '41.7px',
                                          left: '109px',
                                        }}
                                      >
                                        <QRCode
                                          size={256}
                                          style={{
                                            height: 'auto',
                                            maxWidth: '100%',
                                            width: '23%',
                                          }}
                                          value={`red/${item.redtag}`}
                                          viewBox={`0 0 256 256`}
                                        />
                                      </div>
                                      <p
                                        id="unique"
                                        style={{
                                          position: 'relative',
                                          bottom: '3px',
                                          left: '182px',
                                          padding: '0px',
                                          margin: '0px',
                                          color: 'black',
                                          fontSize: '12px',
                                          lineHeight: '14.06px',
                                        }}
                                      >
                                        <b  style={{
                                         
                                        }}>{item.redtag}</b>
                                      </p>
                                    </div>
                                    <p
                                      className="batchid"
                                      style={{
                                        position: 'absolute',
                                        top: '73px',
                                        right: '30px',
                                        writingMode: 'vertical-rl',
                                        padding: '0px',
                                        margin: '0px',
                                        color: 'black',
                                        fontSize: '9px',
                                      }}
                                    >
                                      <b>{batchNum}</b>
                                    </p>
                                  </div>
                                  <div
                                    className="green"
                                    style={{
                                      position: 'relative',
                                      top: '150',
                                      right: '300',
                                    }}
                                  >
                                    <img
                                      src="assets/images/green_tag.png"
                                      width="300"
                                    />
                                    <div
                                      className="code"
                                      style={{
                                        position: 'absolute',
                                        top: '25px',
                                        right: '50px',
                                        background: 'rgba(0, 0, 0, 0)',
                                      }}
                                    >
                                      <div
                                        id="qrcodered"
                                        style={{
                                          height: '70px',
                                          position: 'relative',
                                          top: '41.7px',
                                          left: '109px',
                                        }}
                                      >
                                        <QRCode
                                          size={256}
                                          style={{
                                            height: 'auto',
                                            maxWidth: '100%',
                                            width: '23%',
                                          }}
                                          value={`green/${item.greentag}`}
                                          viewBox={`0 0 256 256`}
                                        />
                                      </div>
                                      <p
                                        id="unique"
                                        style={{
                                          position: 'relative',
                                          bottom: '3px',
                                          left: '182px',
                                          padding: '0px',
                                          margin: '0px',
                                          color: 'black',
                                          fontSize: '12px',
                                          lineHeight: '14.06px',
                                        }}
                                      >
                                        <b>{item.greentag}</b>
                                      </p>
                                    </div>
                                    <p
                                      className="batchid"
                                      style={{
                                        position: 'absolute',
                                        top: '73px',
                                        right: '30px',
                                        writingMode: 'vertical-rl',
                                        padding: '0px',
                                        margin: '0px',
                                        color: 'black',
                                        fontSize: '9px',
                                      }}
                                    >
                                      <b>{batchNum}</b>
                                    </p>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      )}
                    </>
                  </div>
                ) : (
                  <div className="card">
                    <div className="card-body">
                      {listError ? (
                        <MessageBox variant="danger">{listError}</MessageBox>
                      ) : null}
                      <div className="row">
                        <div className="col-md-10">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              forHtml="contactPersonName"
                            >
                              Product Category
                            </label>
                            <select
                              onChange={(e) =>
                                setProductCategory(e.target.value)
                              }
                              value={category}
                              className="select2 form-select"
                              required
                            >
                              {loadingCategory ? (
                                'Loading...'
                              ) : categorylist.length > 0 ? (
                                <>
                                  <option>Select a category</option>
                                  {categorylist.map((item) => (
                                    <option value={item._id} key={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </>
                              ) : (
                                'there is no product category'
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-10">
                          {loadingLists ? (
                            'Loading...'
                          ) : lists && lists.length > 0 ? (
                            <>
                              <label
                                className="form-label"
                                forHtml="contactPersonName"
                              >
                                Batch Number
                              </label>
                              <Select
                                onChange={(opt) => (
                                  setBatchId(opt.value), setBatchNum(opt.label)
                                )}
                                required
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={lists.map((opt) => ({
                                  label: opt.batch,
                                  value: opt._id,
                                }))}
                              />
                            </>
                          ) : (
                            <div
                              className=""
                              style={{
                                backgroundColor: 'red',
                                color: 'white',

                                padding: '15px',
                                borderRadius: '10px',
                              }}
                            >
                              <b>There are no batch available</b>
                            </div>
                          )}
                        </div>
                        <div className="col-md-10">
                          <button
                            className="btn btn-bg btn-success mt-4"
                            onClick={handleLabelGeneration}
                          >
                            Generate Label
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Page content ends here */}
          </div>
        </div>
        {alert}
      </div>
      <Footer />
    </div>
  )
}

export default LabelGenerationPage
