import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MDBDataTableV5 } from 'mdbreact'
import { allApplication } from '../../../actions/adminActions/applicationActions/allApplication'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import { saveDate } from '../../../actions/adminActions/applicationActions/saveDate'
import { CSVLink } from 'react-csv'
import Headers from '../../../components/Headers'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { ToastContainer, toast } from 'react-toastify'
import { SAVE_DATE_RESET } from '../../../constants/adminConstants/applicationContants'

export default function ApplicationPage() {
  const [open, setOpen] = useState(false)
  const [datatable, setDatatable] = useState({})
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(allApplication())
  }, [dispatch])

  const applicationEntries = useSelector((state) => state.applicationEntries)
  const { loading, error, applications } = applicationEntries

  // Download Tag State
  const applicationOpenDate = useSelector((state) => state.applicationOpenDate)
  const { loading: loadingSave, error: saveError, saved } = applicationOpenDate

  useEffect(() => {
    if (applications) {
      setDatatable({
        columns: [
          {
            label: 'Surname',
            field: 'surname',
            width: 100,
          },
          {
            label: 'First Name',
            field: 'firstName',
            width: 100,
          },
          {
            label: 'Other Name',
            field: 'otherName',
            width: 100,
          },
          // {
          //   label: 'Email',
          //   field: 'email',
          //   width: 100,
          // },
          // {
          //   label: 'Phone',
          //   field: 'phone',
          //   width: 100,
          // },
          {
            label: 'Batch',
            field: 'batch',
            width: 100,
          },
          {
            label: 'Need Loan',
            field: 'needLoan',
            width: 100,
          },
          {
            label: 'Date Created',
            field: 'date',
            width: 100,
          },
          // {
          //   label: 'Action',
          //   field: 'action',
          //   sort: 'disabled',
          // },
        ],
        rows: applications.map((item) => ({
          surname: item.surname,
          firstName: item.firstName,
          lastName: item.lastName,
          otherName: item.otherName,
          phone: item.phone,
          email: item.email,
          batch: item.batch,
          needLoan: item.needsLoan,
          date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
        })),
      })
    }
  }, [applications])

  const countLoanApplication = () => {
    let count = 0
    if (applications) {
      applications.forEach((person) => {
        if (person.needsLoan === 'Yes') {
          count++
        }
      })

      return count
    }
  }

  const handleSave = () => {
    dispatch(saveDate(startDate, endDate))
  }

  useEffect(() => {
    if (saved) {
      setOpen(false)
      setTimeout(() => {
        dispatch({ type: SAVE_DATE_RESET })
      }, 2500)
    }
  }, [saved])

  useEffect(() => {
    if (saveError) {
      setTimeout(() => {
        dispatch({ type: SAVE_DATE_RESET })
      }, 2500)
    }
  }, [saveError])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">YEAP_SAfER</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">YEAP_SAfER</a>
                      </li>
                      <li className="breadcrumb-item active">All Users</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Total Submission
                          </p>
                          <h4 className="mb-0">
                            {applications ? applications.length : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-file-alt font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Loan
                          </p>
                          <h4 className="mb-0">
                            {applications ? countLoanApplication() : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-money-check-alt font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          {/* <CSVLink
                            data={applications}
                            headers={Headers}
                            filename={'YEAP-SAfER.csv'}
                            className="btn m-2"
                            target="_blank"
                          >
                            Download
                          </CSVLink> */}
                          <button className='btn m-2 btn-success' disabled="disabled">Download</button>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-download font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <button
                            className="btn m-2"
                            onClick={() => setOpen(true)}
                          >
                            Set Date
                          </button>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-calendar-alt font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="table-responsive-xl mb-0"
                        data-pattern="priority-columns"
                      >
                        <MDBDataTableV5
                          hover
                          entriesOptions={[5, 20, 25, 50]}
                          entries={10}
                          pagesAmount={4}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                          barReverse
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {saved
            ? toast.success(saved.message, {
                position: toast.POSITION.TOP_RIGHT,
              })
            : null}

          {saveError
            ? toast.error(saveError, {
                position: toast.POSITION.TOP_RIGHT,
              })
            : null}

          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth={'md'}
          >
            <>
              <div className="modal-header">
                <h5 className="modal-title">Set Date</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setOpen(false)}
                ></button>
              </div>
              <DialogContent>
                {loadingSave ? (
                  <LoadingBox />
                ) : (
                  <div className="mt-5">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{
                              border: '1px solid #0d89c7',
                              borderRadius: '10px',
                            }}
                          >
                            <div className="standard-body">Countdown Date</div>

                            <h5 className=" card-title font-size-14 mt-4">
                              Please set up the opening and closing dates for
                              the YEAP-SAfER application form
                            </h5>

                            <div className="mt-4">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="card">
                                    <div className="card-body">
                                      <p className="font-size-13">Start date</p>

                                      <input
                                        id="date"
                                        name="date"
                                        type="date"
                                        className="form-control m-0"
                                        min={moment().format('YYYY-MM-DD')}
                                        required
                                        onChange={(e) =>
                                          setStartDate(e.target.value)
                                        }
                                        placeholder="Date"
                                        value={startDate}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="card">
                                    <div className="card-body">
                                      <p className="font-size-13">End date</p>

                                      <input
                                        id="date"
                                        name="date"
                                        type="date"
                                        className="form-control m-0"
                                        min={moment().format('YYYY-MM-DD')}
                                        required
                                        onChange={(e) =>
                                          setEndDate(e.target.value)
                                        }
                                        placeholder="Date"
                                        value={endDate}
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/* Fiveth Column */}
                                <div className="col-md-12">
                                  <div className="d-grid">
                                    <button
                                      className="btn btn-success btn-lg"
                                      onClick={handleSave}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </DialogContent>
            </>
          </Dialog>
          <ToastContainer />
        </div>
      </div>
      <Footer />
    </div>
  )
}
