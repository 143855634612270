import React, { useEffect } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { getUserWalletBalance } from '../../../actions/adminActions/farmtroveActions/getUserWalletBalance'
import { getUserWalletTransaction } from '../../../actions/adminActions/farmtroveActions/getUserWalletTransaction'
import { useDispatch, useSelector } from 'react-redux'
import { calculateMoneyFlow, formatNumber } from '../../../components/functions'
import { TailSpin } from 'react-loading-icons'
import {  MessageBox } from '../../../components'
import moment from 'moment'

export default function UserWalletPage(props) {
    const itemId = props.match.params.id

    console.log(itemId)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUserWalletBalance(itemId))
        dispatch(getUserWalletTransaction(itemId))
    }, [itemId])

    const userWalletBalance = useSelector((state) => state.userWalletBalance)
    const { loading: loadingWallet, wallet, error: walletErr } = userWalletBalance

    const userTransactions = useSelector((state) => state.userTransactions)
    const {
        loading: loadingTransactions,
        transactions,
        error: transactionsError,
    } = userTransactions

    const handleRefresh = (e) => {
        e.preventDefault()

        dispatch(getUserWalletBalance(itemId))
        dispatch(getUserWalletTransaction(itemId))
    }


    return (
        <div id="layout-wrapper">
            <Header />
            <LeftSidebar />
            <RightSidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">User Wallet</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Farmtrove</a>
                                            </li>
                                            <li className="breadcrumb-item active">Wallet</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card" style={{ borderRadius: '1rem' }}>
                                            <div className="card-body balance-body">
                                                {walletErr ? (
                                                    <MessageBox variant="danger">
                                                        {walletErr}
                                                    </MessageBox>
                                                ) : null}

                                                {loadingWallet ? (
                                                    <div
                                                        className="tailspin"
                                                        style={{ height: 'calc(20vh - 40px)' }}
                                                    >
                                                        <TailSpin
                                                            stroke="#98ff98"
                                                            strokeWidth="3"
                                                            color="#06bcee"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="">
                                                        <div className="d-flex">
                                                            <div className="flex-1 overflow-hidden">
                                                                <h5 className="card-title balance-title">
                                                                    My Wallet Balance
                                                                </h5>

                                                                <div>
                                                                    <h4 className="wallet-bal">
                                                                        NGN{' '}
                                                                        {wallet
                                                                            ? formatNumber(wallet.balance)
                                                                            : '0.00'}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                            <div className="ms-auto">
                                                                {wallet && wallet.status ? (
                                                                    <span className="badge badge-soft-success font-size-13 me-3">
                                                                        Active
                                                                    </span>
                                                                ) : (
                                                                    <span className="badge badge-soft-danger font-size-13 me-3">
                                                                        Inactive
                                                                    </span>
                                                                )}

                                                                <button
                                                                    className="btn btn-sm btn-success"
                                                                    onClick={handleRefresh}
                                                                >
                                                                    <i className="fa fa-sync"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="balance-container">
                                                            {loadingTransactions ? (
                                                                <div
                                                                    className="tailspin"
                                                                    style={{
                                                                        height: 'calc(2vh - 2px)',
                                                                    }}
                                                                >
                                                                    <TailSpin
                                                                        stroke="#98ff98"
                                                                        strokeWidth="3"
                                                                        color="#06bcee"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="balance-item">
                                                                        <div className="balance-profit">
                                                                            <ul
                                                                                className="list-unstyled balance-list"
                                                                                style={{ display: 'flex' }}
                                                                            >
                                                                                <li
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-arrow-down bg-soft-success"></i>
                                                                                </li>
                                                                                <li
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column',
                                                                                    }}
                                                                                >
                                                                                    <p className="m-0 p-0">
                                                                                        Total Credit
                                                                                    </p>
                                                                                    {transactions ? (
                                                                                        <h5 className="m-0 p-0">
                                                                                            {`+ NGN ${formatNumber(
                                                                                                calculateMoneyFlow(
                                                                                                    transactions,
                                                                                                ).inflow,
                                                                                            )}`}
                                                                                        </h5>
                                                                                    ) : (
                                                                                        <h5 className="">+ NGN 0.00</h5>
                                                                                    )}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>

                                                                    <div className="balance-item">
                                                                        <div className="balance-profit">
                                                                            <ul
                                                                                className="list-unstyled balance-list"
                                                                                style={{ display: 'flex' }}
                                                                            >
                                                                                <li
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-arrow-up bg-soft-danger"></i>
                                                                                </li>
                                                                                <li
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column',
                                                                                    }}
                                                                                >
                                                                                    <p className="m-0 p-0">
                                                                                        Total Debit
                                                                                    </p>
                                                                                    {transactions ? (
                                                                                        <h5 className="m-0 p-0">
                                                                                            {`- NGN ${formatNumber(
                                                                                                calculateMoneyFlow(
                                                                                                    transactions,
                                                                                                ).outflow,
                                                                                            )}`}
                                                                                        </h5>
                                                                                    ) : (
                                                                                        <h5 className="">- NGN 0.00</h5>
                                                                                    )}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card" style={{ borderRadius: '1rem' }}>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">
                                                    Monthly Spend
                                                </p>
                                                {loadingTransactions ? (
                                                    <div
                                                        className="tailspin"
                                                        style={{ height: 'calc(10vh - 10px)' }}
                                                    >
                                                        <TailSpin
                                                            stroke="#98ff98"
                                                            strokeWidth="3"
                                                            color="#06bcee"
                                                        />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {transactions ? (
                                                            <h4 className="pb-0 mb-0">
                                                                {`NGN ${formatNumber(
                                                                    calculateMoneyFlow(transactions)
                                                                        .outflowMonth,
                                                                )}.00`}
                                                            </h4>
                                                        ) : (
                                                            <h4 className="pb-0 mb-0">NGN 0.00</h4>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div className="text-primary ms-auto">
                                                <i className="ri-upload-2-fill font-size-24"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card" style={{ borderRadius: '1rem' }}>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">
                                                    Monthly Income
                                                </p>
                                                {loadingTransactions ? (
                                                    <div
                                                        className="tailspin"
                                                        style={{ height: 'calc(10vh - 10px)' }}
                                                    >
                                                        <TailSpin
                                                            stroke="#98ff98"
                                                            strokeWidth="3"
                                                            color="#06bcee"
                                                        />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {transactions ? (
                                                            <h4 className="pb-0 mb-0">
                                                                {`NGN ${formatNumber(
                                                                    calculateMoneyFlow(transactions)
                                                                        .inflowMonth,
                                                                )}.00`}
                                                            </h4>
                                                        ) : (
                                                            <h4 className="pb-0 mb-0">NGN 0.00</h4>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div className="text-primary ms-auto">
                                                <i className="ri-download-2-fill font-size-24"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='row'>
                            <div className='col-md-4'>

                            </div>
                        </div>

                        <div className="card" style={{ borderRadius: '1rem' }}>
                            <div className="card-body">
                                <h5 className="card-title">Recent Transaction</h5>

                                {transactionsError ? (
                                    <MessageBox variant="danger">
                                        {transactionsError}
                                    </MessageBox>
                                ) : null}

                                <div
                                    className=""
                                    style={{ padding: '0px', marginTop: '30px' }}
                                >
                                    <ul className="list-unstyled">
                                        {loadingTransactions ? (
                                            <div
                                                className="tailspin"
                                                style={{ height: 'calc(20vh - 40px)' }}
                                            >
                                                <TailSpin
                                                    stroke="#98ff98"
                                                    strokeWidth="3"
                                                    color="#06bcee"
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {transactions && transactions.length > 0 ? (
                                                    transactions.slice(0, 7).map((item) => (
                                                        <li
                                                            className="transaction-list"
                                                            key={item._id}
                                                        >
                                                            <div className="activity-icon avatar-xs transaction-items">
                                                                <span
                                                                    className={`avatar-title ${item.type === 'credit'
                                                                        ? 'bg-soft-success'
                                                                        : 'bg-soft-danger'
                                                                        } text-primary`}
                                                                    style={{ borderRadius: '10px' }}
                                                                >
                                                                    <i
                                                                        className={`${item.type === 'credit'
                                                                            ? 'ri-download-2-fill'
                                                                            : 'ri-upload-2-fill'
                                                                            } p-4`}
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                            <div className="transaction-items">
                                                                <div>
                                                                    <h5 className="font-size-13">
                                                                        {item.description}
                                                                    </h5>
                                                                </div>

                                                                <div>
                                                                    <p className="text-muted mt-0 mb-0">
                                                                        {moment(item.timestamp).format(
                                                                            'DD MMM YYYY',
                                                                        )}{' '}
                                                                        <small className="text-muted">
                                                                            {moment(item.timestamp).format(
                                                                                'h:mm a',
                                                                            )}
                                                                        </small>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="transaction-items text-center">
                                                                <p className="m-0 mb-0">
                                                                    <b
                                                                        className={`${item.type === 'credit'
                                                                            ? 'text-success'
                                                                            : 'text-danger'
                                                                            } font-size-17`}
                                                                    >
                                                                        {item.type === 'credit'
                                                                            ? `+ ₦${formatNumber(
                                                                                item.amount,
                                                                            )}`
                                                                            : `- ₦${formatNumber(
                                                                                item.amount,
                                                                            )}`}
                                                                    </b>
                                                                </p>
                                                                <small
                                                                    className={`text-${item.status === 'successful'
                                                                        ? 'success'
                                                                        : item.status === 'processing'
                                                                            ? 'warning'
                                                                            : item.status === 'pending'
                                                                                ? 'info'
                                                                                : 'danger'
                                                                        } font-size-12`}
                                                                >
                                                                    {item.status}
                                                                </small>
                                                            </div>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <div className="card-empty-body">
                                                        <div className="card-empty">
                                                            <h5 className="card-empty-title">
                                                                Nothing to See Here
                                                            </h5>
                                                            <p className="card-empty-text">
                                                                Sorry, you are yet to perform a
                                                                transaction at the moment.
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}