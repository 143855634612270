import axios from 'axios'
import {
  ACCOUNT_TRANSATIONS_FAIL,
  ACCOUNT_TRANSATIONS_REQUEST,
  ACCOUNT_TRANSATIONS_SUCCESS,
} from '../../../constants/farmerConstants/farmtrove/accountConstants'

export const accountTransactions = () => async (dispatch, getState) => {
  dispatch({
    type: ACCOUNT_TRANSATIONS_REQUEST,
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/farmer/accounts/transactions', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: ACCOUNT_TRANSATIONS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: ACCOUNT_TRANSATIONS_FAIL, payload: message })
  }
}
