const Banks = [
  {
    cbn_code: '044',
    bank_name: 'Access Bank Nigeria Plc',
  },
  {
    cbn_code: '063',
    bank_name: 'Diamond Bank Plc',
  },
  {
    cbn_code: '050',
    bank_name: 'Ecobank Nigeria',
  },
  {
    cbn_code: '084',
    bank_name: 'Enterprise Bank Plc',
  },
  {
    cbn_code: '070',
    bank_name: 'Fidelity Bank Plc',
  },
  {
    cbn_code: '011',
    bank_name: 'First Bank of Nigeria Plc',
  },
  {
    cbn_code: '214',
    bank_name: 'First City Monument Bank',
  },
  {
    cbn_code: '058',
    bank_name: 'Guaranty Trust Bank Plc',
  },
  {
    cbn_code: '030',
    bank_name: 'Heritage Banking Company Ltd',
  },
  {
    cbn_code: '301',
    bank_name: 'Jaiz Bank',
  },
  {
    cbn_code: '082',
    bank_name: 'Keystone Bank Ltd',
  },
  {
    cbn_code: '014',
    bank_name: 'Mainstreet Bank Plc',
  },
  {
    cbn_code: '076',
    bank_name: 'Skye Bank Plc',
  },
  {
    cbn_code: '039',
    bank_name: 'Stanbic IBTC Plc',
  },
  {
    cbn_code: '232',
    bank_name: 'Sterling Bank Plc',
  },
  {
    cbn_code: '032',
    bank_name: 'Union Bank Nigeria Plc',
  },
  {
    cbn_code: '033',
    bank_name: 'United Bank for Africa Plc',
  },
  {
    cbn_code: '215',
    bank_name: 'Unity Bank Plc',
  },
  {
    cbn_code: '035',
    bank_name: 'WEMA Bank Plc',
  },
  {
    cbn_code: '057',
    bank_name: 'Zenith Bank International',
  },
]

export default Banks