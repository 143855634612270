import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signin } from '../../actions/authActions'
import sendOtp from '../../actions/authActions/sendOtp'
import { MessageBox, LoadingBox } from '../../components'
import { SEND_OTP_RESET } from '../../constants/authConstants/otpConstants'

function LoginPage(props) {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [show, setShow] = useState(true)

  const userSignin = useSelector((state) => state.userSignin)
  const { loading, error, userInfo } = userSignin

  const userOtpRequest = useSelector((state) => state.userOtpRequest)
  const { loading: optLoading, error: otpError, result } = userOtpRequest

  const dispatch = useDispatch()

  const submitHandler = async (e) => {
    e.preventDefault()

    dispatch(signin(email, password))
  }

  const verifyHandler = (e) => {
    e.preventDefault()
    dispatch(sendOtp(phone))
  }

  useEffect(() => {
    if (userInfo) {
      setTimeout(() => {
        props.history.push('/dashboard')
        window.location.reload()
      }, 1500)
    }
    if (result) {
      setTimeout(() => {
        props.history.push({
          pathname: '/verify-otp',
          state: { phone },
        })
        dispatch({ type: SEND_OTP_RESET })
      }, 2500)
    }
  }, [userInfo, result, props.history])

  const date = new Date().getFullYear()

  return (
    <>
      <div className="auth-body-bg">
        {loading ? (
          <LoadingBox />
        ) : (
          <div className="container-fluid p-0">
            <div className="row g-0">
              <div className="col-lg-4">
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <div className="row justify-content-center">
                      {error && (
                        <MessageBox variant="danger">{error}</MessageBox>
                      )}
                      {otpError && (
                        <MessageBox variant="danger">{otpError}</MessageBox>
                      )}
                      {userInfo && (
                        <MessageBox variant="success">
                          You are successfully logged in
                        </MessageBox>
                      )}
                      {result ? (
                        <MessageBox variant="success">
                          {result.message}
                        </MessageBox>
                      ) : null}
                      <div className="col-lg-9">
                        <div>
                          <div className="text-center">
                            <div>
                              <a href="/" className="">
                                <img
                                  src="/assets/images/farmsured!.png"
                                  alt=""
                                  height="50"
                                  className="auth-logo logo-dark mx-auto"
                                />
                                <img
                                  src="/assets/images/farmsured!.png"
                                  alt=""
                                  height="50"
                                  className="auth-logo logo-light mx-auto"
                                />
                              </a>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Welcome Back !
                            </h4>
                            <p className="text-muted">
                              Sign in to continue to Farmsured.
                            </p>
                          </div>

                          <div className="p-2 mt-5">
                            {show ? (
                              <>
                                {optLoading ? (
                                  <LoadingBox />
                                ) : (
                                  <>
                                    <form className="" onSubmit={verifyHandler}>
                                      <div className="mb-3 auth-form-group-custom mb-4">
                                        <i className="ri-phone-line auti-custom-input-icon"></i>
                                        <label htmlFor="email">
                                          Phone Number
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="phone"
                                          placeholder="Enter Phone Number"
                                          required
                                          onChange={(e) =>
                                            setPhone(e.target.value)
                                          }
                                        />
                                      </div>

                                      <div className="mt-4 text-center">
                                        <button
                                          className="btn btn-success w-md waves-effect waves-light"
                                          type="submit"
                                        >
                                          Send OTP
                                        </button>
                                      </div>
                                    </form>
                                    <div className="mt-4 me-1 text-center">
                                      <p className="text-center text-line">
                                        or sign in with your email address
                                      </p>
                                      <button
                                        className="btn success  text-muted"
                                        onClick={() => setShow(false)}
                                      >
                                        Login with Email
                                      </button>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <form className="" onSubmit={submitHandler}>
                                <div className="mb-3 auth-form-group-custom mb-4">
                                  <i className="ri-user-2-line auti-custom-input-icon"></i>
                                  <label htmlFor="email">Email</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter Email"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>

                                <div className="mb-3 auth-form-group-custom mb-4">
                                  <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                  <label htmlFor="userpassword">Password</label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="userpassword"
                                    placeholder="Enter password"
                                    required
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customControlInline"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customControlInline"
                                  >
                                    Remember me
                                  </label>
                                </div>

                                <div className="mt-4 text-center">
                                  <button
                                    className="btn btn-success w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    Log In
                                  </button>
                                </div>

                                <div className="mt-4 text-center">
                                  <a
                                    href="/auth-recoverpassword"
                                    className="text-muted"
                                  >
                                    <i className="mdi mdi-lock me-1"></i> Forgot
                                    your password?
                                  </a>
                                </div>

                                <div className="">
                                  <p className="text-center text-line">
                                    or sign in with your phone number
                                  </p>

                                  <div className="text-center">
                                    <button
                                      className="btn success text-muted"
                                      onClick={() => setShow(true)}
                                    >
                                      Login with Phone Number
                                    </button>
                                  </div>
                                </div>
                              </form>
                            )}
                          </div>

                          <div className="mt-3 text-center">
                            <p>
                              © {date} Farmsured
                              <sup>
                                <small>TM</small>
                              </sup>
                              {''} A product of AgDyna
                              <sup>
                                <i className="fal fa-registered font-size-12"></i>
                              </sup>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default LoginPage
