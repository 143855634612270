import React, { useEffect, useMemo, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { viewCycleDetail } from '../../../actions/adminActions/farmcycleActions/viewCycleDetail'
import moment from 'moment'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { LoadingBox, MessageBox } from '../../../components'
import { filterActivitiesByTime } from '../../../components/functions'
import LinearProgress from '@material-ui/core/LinearProgress'
import ReactCardFlip from 'react-card-flip'
import { Box, Slider } from '@material-ui/core'
import { MDBDataTableV5 } from 'mdbreact'
import { TailSpin } from 'react-loading-icons'
import { userScanActivities } from '../../../actions/adminActions/farmcycleActions/userScanActivities'
import CountdownTimer, {
  CountdownStatus,
} from '../../../components/Counter/CountdownTimer'
import parse from 'html-react-parser'

function ViewFarmCyclePage(props) {
  const itemId = props.match.params.id

  const [levelPoint, setLevelPoint] = useState('')
  const [expectedPoint, setExpectedPoint] = useState(0)
  const [today, setToday] = useState(new Date())
  const [colorTag, setTagColorTag] = useState('')
  const [activityType, setActivityType] = useState('')
  const [complianceStatus, setComplianceStatus] = useState(Boolean)
  const [filterPoint, setFilterPoint] = useState(0)
  const [filterResult, setFilterResult] = useState([])
  const [value, setValue] = useState([0, 24])
  const [filter, setFilter] = useState({})
  const [datatable, setDatatable] = useState({})
  const [isFlipped, setIsFlipped] = useState(false)
  const [activityId, setActivityId] = useState('')
  const [point, setPoint] = useState(0)
  const [farmActivity, setFarmActivity] = useState('')
  const [activity, setActivity] = useState('')
  const [tagColor, setTagColor] = useState('')
  const [task, setTask] = useState('')
  const [timeLineFrom, setTimelineFrom] = useState('')
  const [timeLineTo, setTimelineTo] = useState('')
  const [comment, setComment] = useState('')
  const [compliance, setCompliance] = useState(false)
  const [date, setDate] = useState('')

  const viewCycle = useSelector((state) => state.viewCycle)
  const { loading, detail, error } = viewCycle

  const scanActivities = useSelector((state) => state.scanActivities)
  const {
    loading: loadingScanActivities,
    error: scanActivitiesError,
    cycleScans,
  } = scanActivities

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(viewCycleDetail(itemId))
    dispatch(userScanActivities(itemId))
  }, [dispatch])

  const dateTime = (item) => {
    const c =
      item.batch && item.batch.product && item.batch.product.category
        ? item.batch.product.category.cycleDuration
        : 0

    let hrs = c * 60 * 60 * 1000

    const d = new Date(item.redActivatedAt)
    let ms = d.getTime()
    return ms + hrs
  }

  const countActivities = (activities) => {
    let count = 0
    activities.map((item) => {
      if (item.compliance) return count++
    })
    return count
  }

  const numberOfDays = (date) => {
    const today = moment() // today's date
    const otherDate = moment(date) // the other date

    // calculate the difference in days between the two dates
    const diffInDays = today.diff(otherDate, 'days') + 1

    // return the number of days
    return diffInDays
  }

  const daysOfWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

  const currentDayOfWeek = today.getDay()
  const daysSinceMonday = (currentDayOfWeek + 6) % 7
  const currentWeekDays = daysOfWeek
    .slice(daysSinceMonday)
    .concat(daysOfWeek.slice(0, daysSinceMonday))

  const generateGreetings = () => {
    var currentHour = moment().format('HH')

    if (currentHour >= 3 && currentHour < 12) {
      return 'Good Morning'
    } else if (currentHour >= 12 && currentHour < 15) {
      return 'Good Afternoon'
    } else if (currentHour >= 15 && currentHour < 24) {
      return 'Good Evening'
    } else if (currentHour >= 0 || currentHour < 3) {
      return 'Good Morning'
    } else {
      return 'Hello'
    }
  }

  const filterData = (data, filters) => {
    return data.filter((item) => {
      for (let key in filters) {
        if (filters[key] && item[key] !== filters[key]) {
          return false
        }
      }
      return true
    })
  }

  const handleFilter = (data) => {
    setFilterResult(filterData(data, filter))
  }

  const handleResetFilter = () => {
    setTagColorTag('')
    setActivityType('')
    setComplianceStatus(false)
    setValue([0, 24])
    setFilterPoint('')
    setFilterResult(filterActivitiesByTime(detail.startDate, detail.activities))
  }

  useEffect(() => {
    if (detail && detail.activities)
      setFilterResult(
        filterActivitiesByTime(detail.startDate, detail.activities),
      )
  }, [detail])

  useMemo(() => {
    if (
      detail &&
      detail.productCategory &&
      detail.productCategory.expectedPoint
    ) {
      setExpectedPoint(detail.productCategory.expectedPoint * detail.packageNum)
    }

    if (detail && detail.point && expectedPoint) {
      setLevelPoint(Math.ceil((detail.point / expectedPoint) * 100))
    }
  }, [detail, expectedPoint])

  useMemo(() => {
    setFilter({
      activity: activityType,
      compliance: complianceStatus,
      timeLineFrom: value && value[0],
      timeLineTo: value && value[1],
      point: filterPoint,
      tagColor: colorTag,
    })
  }, [activityType, complianceStatus, value, filterPoint, colorTag])

  const changeValue = (event, newValue) => {
    setValue(newValue)
  }

  const handleChange = (event, newValue) => {
    setFilterPoint(newValue)
  }

  function valuetext(value) {
    return `${value} points`
  }

  const handleComplianceDialog = (item) => {
    setIsFlipped(!isFlipped)
    setActivityId(item._id)
    setActivity(item.activity)
    setFarmActivity(item.farmActivity)
    setTask(item.expectedTask)
    setTimelineFrom(item.timeLineFrom)
    setTimelineTo(item.timeLineTo)
    setPoint(item.point)
    setComment(item.comment)
    setTagColor(item.tagColor)
    setCompliance(item.compliance)
    setDate(item.date)
  }

  useEffect(() => {
    if (cycleScans) {
      setDatatable({
        columns: [
          {
            label: 'Product',
            field: 'product',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'product',
            },
          },
          {
            label: 'Earned Points',
            field: 'earnedPoints',
            width: 200,
          },
          {
            label: 'Date Verified',
            field: 'dateVerified',
            width: 100,
          },
          {
            label: 'Date Activated',
            field: 'dateActivated',
            width: 100,
          },
          {
            label: 'Protection Timeline',
            field: 'timeline',
            width: 50,
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'disabled',
          },
        ],
        rows: cycleScans.map((item) => ({
          product: item.batch.product.name,
          earnedPoints:
            item.redActivatedAt && !item.greenActivatedAt
              ? item.points
              : item.redActivatedAt && item.greenActivatedAt
              ? item.points + item.points
              : null,

          dateVerified: item.redActivatedAt
            ? moment(item.redActivatedAt).format('Do MMMM YYYY,  h:mm a')
            : null,
          dateActivated: item.greenActivatedAt
            ? moment(item.greenActivatedAt).format('Do MMMM YYYY,  h:mm a')
            : null,
          timeline: <CountdownTimer targetDate={dateTime(item)} />,
          status: <CountdownStatus item={item} targetDate={dateTime(item)} />,
        })),
      })
    }
  }, [cycleScans])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Farm Cycle Details</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farm Cycle</a>
                      </li>
                      <li className="breadcrumb-item active">Detail</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-5">
                        <h5>{generateGreetings()}, </h5>
                        <p className="card-desc">
                          Let’s check{' '}
                          {detail && detail.user && detail.user.firstName} farm
                          today
                        </p>
                        <div className="card">
                          <div className="card-body">
                            <h6>
                              <i className="fal fa-coins me-2"></i>Compliance
                              Level
                            </h6>

                            <div
                              className=""
                              style={{
                                maxWidth: 250,
                                maxHeight: 250,
                                margin: 'auto',
                                marginTop: '22px',
                              }}
                            >
                              <CircularProgressbar
                                value={levelPoint ? levelPoint : 0}
                                text={`${levelPoint ? levelPoint : 0}%`}
                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap="butt"
                                // Text size
                                textSize="18px"
                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration="0.5"
                                strokeWidth="14"
                                styles={buildStyles({
                                  textColor: `${
                                    levelPoint <= 20
                                      ? '#96c400'
                                      : levelPoint <= 40
                                      ? '#8bc34a'
                                      : levelPoint <= 60
                                      ? '#569e02'
                                      : '#1d6639'
                                  }`,
                                  pathColor: `${
                                    levelPoint <= 20
                                      ? '#96c400'
                                      : levelPoint <= 40
                                      ? '#8bc34a'
                                      : levelPoint <= 60
                                      ? '#569e02'
                                      : '#1d6639'
                                  }`,
                                })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="card">
                          <div className="card-body">
                            <div className="mb-3">
                              <p className="card-text p-0 m-0 ">
                                <i className="fal fa-tractor me-1"></i>Farm Name
                              </p>

                              <h6 className="card-title font-size-17  p-0  m-0 mt-1">
                                {detail && detail.name ? detail.name : ''}
                              </h6>
                            </div>

                            <div className="">
                              <p className="card-text p-0 m-0 ">
                                <i className="fal fa-id-badge me-1"></i>{' '}
                                Farmsured Package
                              </p>

                              <h6 className="card-title font-size-17  p-0  m-0 mt-1">
                                {detail &&
                                detail.packageId &&
                                detail.packageId.packageName
                                  ? detail.packageId.packageName
                                  : ''}
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="card mb-0">
                              <div className="card-body">
                                <h6 className="card-title mb-1">
                                  <i className="fal fa-coins me-2"></i> Progress
                                  Report
                                </h6>
                                <div className="row">
                                  <div className="col-md-4 p-1">
                                    <div className="card mb-0 card-border-radius bg-dark text-light">
                                      <div className="card-body">
                                        <div className="item-1">
                                          <p className="">
                                            <i className="fal fa-coins font-size-35"></i>
                                          </p>
                                          <p className="m-0 mt-0 mb-1">Point</p>
                                          <p className="item-2">
                                            {detail && detail.point
                                              ? detail.point
                                              : 0}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 p-1">
                                    <div className="card mb-0 card-border-radius bg-success text-light">
                                      <div className="card-body">
                                        <div className="item-1">
                                          <p className="">
                                            <i className="fal fa-bullseye-arrow"></i>
                                          </p>
                                          <p className="m-0">Expected</p>
                                          <p className="item-2">
                                            {expectedPoint ? expectedPoint : 0}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 p-1">
                                    <div className="card mb-0 card-border-radius text-white">
                                      <div className="card-body">
                                        <div className="item-1">
                                          <p className="">
                                            <i className="fal fa-shield-check"></i>
                                          </p>
                                          <p className="m-0">Completed</p>
                                          <p className="item-2">
                                            {detail &&
                                            detail.activities &&
                                            detail.activities.length > 0
                                              ? countActivities(
                                                  detail.activities,
                                                )
                                              : 0}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="card">
                              <div className="card-body">
                                <div className="item-1">
                                  <p className="">
                                    <i className="fal fa-calendar-day"></i>
                                  </p>
                                  <p className="m-0">Days</p>
                                  <p className="item-2">
                                    {detail && detail.startDate
                                      ? numberOfDays(detail.startDate)
                                      : 0}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card">
                              <div className="card-body">
                                <div className="item-1">
                                  <p className="">
                                    <i className="fal fa-calendar-day"></i>
                                  </p>
                                  <p className="m-0">Package(s)</p>
                                  <p className="item-2">
                                    {detail && detail.packageNum
                                      ? detail.packageNum
                                      : 0}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                  <p className="text-truncate font-size-14 mb-2">
                                    Start Date
                                  </p>
                                  <h5 className="mb-0">
                                    {' '}
                                    {detail && detail.startDate
                                      ? moment(detail.startDate).format(
                                          'DD MMM YYYY',
                                        )
                                      : moment().format('DD MMM YYYY')}
                                  </h5>
                                </div>
                                <div className="text-primary ms-auto">
                                  <i className="fal fa-calendar-day font-size-24"></i>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                  <p className="text-truncate font-size-14 mb-2">
                                    End Date
                                  </p>
                                  <h5 className="mb-0">
                                    {detail && detail.endDate
                                      ? moment(detail.endDate).format(
                                          'DD MMM YYYY',
                                        )
                                      : moment().format('DD MMM YYYY')}
                                  </h5>
                                </div>
                                <div className="text-primary ms-auto">
                                  <i className="fal fa-calendar-day font-size-24"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="card">
                          <div className="card-body border-bottom">
                            <div className="user-chat-border">
                              <div className="row">
                                <div className="col-md-5 col-9">
                                  <h5 className="font-size-15 mb-1">Admin</h5>
                                  <p className="text-muted mb-0">
                                    <i className="mdi mdi-circle text-success align-middle me-1"></i>
                                    Active now
                                  </p>
                                </div>
                                <div className="col-md-7 col-3">
                                  <ul className="list-inline user-chat-nav text-end mb-0">
                                    <li className="list-inline-item">
                                      <div className="dropdown">
                                        <button
                                          className="btn nav-btn dropdown-toggle"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="mdi mdi-magnify"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end dropdown-menu-md p-0">
                                          <form className="p-2">
                                            <div className="search-box">
                                              <div className="position-relative">
                                                <input
                                                  type="text"
                                                  className="form-control bg-light border-0"
                                                  placeholder="Search..."
                                                />
                                                <i className="mdi mdi-magnify search-icon"></i>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="list-inline-item d-none d-sm-inline-block">
                                      <div className="dropdown">
                                        <button
                                          className="btn nav-btn dropdown-toggle"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="mdi mdi-cog"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          <a
                                            className="dropdown-item"
                                            href="javascript: void(0);"
                                          >
                                            View Profile
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript: void(0);"
                                          >
                                            Clear chat
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript: void(0);"
                                          >
                                            Muted
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript: void(0);"
                                          >
                                            Delete
                                          </a>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="list-inline-item">
                                      <div className="dropdown">
                                        <button
                                          className="btn nav-btn dropdown-toggle"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="mdi mdi-dots-horizontal"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          <a className="dropdown-item" href="#">
                                            Action
                                          </a>
                                          <a className="dropdown-item" href="#">
                                            Another action
                                          </a>
                                          <a className="dropdown-item" href="#">
                                            Something else
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="chat-widget">
                              <div
                                className="chat-conversation"
                                data-simplebar="init"
                                style={{ maxHeight: '190px' }}
                              >
                                <div
                                  className="simplebar-wrapper"
                                  style={{ margin: '0px' }}
                                >
                                  <div className="simplebar-height-auto-observer-wrapper">
                                    <div className="simplebar-height-auto-observer"></div>
                                  </div>
                                  <div className="simplebar-mask">
                                    <div
                                      className="simplebar-offset"
                                      style={{
                                        right: '-18.8889px',
                                        bottom: '0px',
                                      }}
                                    >
                                      <div
                                        className="simplebar-content-wrapper"
                                        style={{
                                          height: 'auto',
                                          overflow: 'hidden scroll',
                                        }}
                                      >
                                        <div
                                          className="simplebar-content"
                                          style={{ padding: '0px' }}
                                        >
                                          <ul className="list-unstyled mb-0 pe-3">
                                            <li>
                                              <div className="conversation-list">
                                                <div className="chat-avatar">
                                                  <img
                                                    src="assets/images/users/avatar-2.jpg"
                                                    alt="avatar-2"
                                                  />
                                                </div>
                                                <div className="ctext-wrap">
                                                  <div className="conversation-name">
                                                    Frank Vickery
                                                  </div>
                                                  <div className="ctext-wrap-content">
                                                    <p className="mb-0">
                                                      Hey! I am available
                                                    </p>
                                                  </div>
                                                  <p className="chat-time mb-0">
                                                    <i className="mdi mdi-clock-outline align-middle me-1"></i>
                                                    12:09
                                                  </p>
                                                </div>
                                              </div>
                                            </li>

                                            <li className="right">
                                              <div className="conversation-list">
                                                <div className="ctext-wrap">
                                                  <div className="conversation-name">
                                                    Ricky Clark
                                                  </div>
                                                  <div className="ctext-wrap-content">
                                                    <p className="mb-0">
                                                      Hi, How are you? What
                                                      about our next meeting?
                                                    </p>
                                                  </div>

                                                  <p className="chat-time mb-0">
                                                    <i className="bx bx-time-five align-middle me-1"></i>
                                                    10:02
                                                  </p>
                                                </div>
                                              </div>
                                            </li>

                                            <li>
                                              <div className="chat-day-title">
                                                <span className="title">
                                                  Today
                                                </span>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="conversation-list">
                                                <div className="chat-avatar">
                                                  <img
                                                    src="assets/images/users/avatar-2.jpg"
                                                    alt="avatar-2"
                                                  />
                                                </div>
                                                <div className="ctext-wrap">
                                                  <div className="conversation-name">
                                                    Frank Vickery
                                                  </div>
                                                  <div className="ctext-wrap-content">
                                                    <p className="mb-0">
                                                      Hello!
                                                    </p>
                                                  </div>
                                                  <p className="chat-time mb-0">
                                                    <i className="mdi mdi-clock-outline align-middle me-1"></i>
                                                    10:00
                                                  </p>
                                                </div>
                                              </div>
                                            </li>

                                            <li className="right">
                                              <div className="conversation-list">
                                                <div className="ctext-wrap">
                                                  <div className="conversation-name">
                                                    Ricky Clark
                                                  </div>
                                                  <div className="ctext-wrap-content">
                                                    <p className="mb-0">
                                                      Hi, How are you? What
                                                      about our next meeting?
                                                    </p>
                                                  </div>

                                                  <p className="chat-time mb-0">
                                                    <i className="bx bx-time-five align-middle me-1"></i>
                                                    10:02
                                                  </p>
                                                </div>
                                              </div>
                                            </li>

                                            <li>
                                              <div className="conversation-list">
                                                <div className="chat-avatar">
                                                  <img
                                                    src="assets/images/users/avatar-2.jpg"
                                                    alt="avatar-2"
                                                  />
                                                </div>
                                                <div className="ctext-wrap">
                                                  <div className="conversation-name">
                                                    Frank Vickery
                                                  </div>
                                                  <div className="ctext-wrap-content">
                                                    <p className="mb-0">
                                                      Yeah everything is fine
                                                    </p>
                                                  </div>

                                                  <p className="chat-time mb-0">
                                                    <i className="bx bx-time-five align-middle me-1"></i>
                                                    10:06
                                                  </p>
                                                </div>
                                              </div>
                                            </li>

                                            <li>
                                              <div className="conversation-list">
                                                <div className="chat-avatar">
                                                  <img
                                                    src="assets/images/users/avatar-2.jpg"
                                                    alt="avatar-2"
                                                  />
                                                </div>
                                                <div className="ctext-wrap">
                                                  <div className="conversation-name">
                                                    Frank Vickery
                                                  </div>
                                                  <div className="ctext-wrap-content">
                                                    <p className="mb-0">
                                                      &amp; Next meeting
                                                      tomorrow 10.00AM
                                                    </p>
                                                  </div>
                                                  <p className="chat-time mb-0">
                                                    <i className="bx bx-time-five align-middle me-1"></i>
                                                    10:06
                                                  </p>
                                                </div>
                                              </div>
                                            </li>

                                            <li className="right">
                                              <div className="conversation-list">
                                                <div className="ctext-wrap">
                                                  <div className="conversation-name">
                                                    Ricky Clark
                                                  </div>
                                                  <div className="ctext-wrap-content">
                                                    <p className="mb-0">
                                                      Wow that's great
                                                    </p>
                                                  </div>

                                                  <p className="chat-time mb-0">
                                                    <i className="bx bx-time-five align-middle me-1"></i>
                                                    10:07
                                                  </p>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="simplebar-placeholder"
                                    style={{ width: 'auto', height: '887px' }}
                                  ></div>
                                </div>
                                <div
                                  className="simplebar-track simplebar-horizontal"
                                  style={{ visibility: 'hidden' }}
                                >
                                  <div
                                    className="simplebar-scrollbar"
                                    style={{
                                      transform: 'translate3d(0px, 0px, 0px)',
                                      display: 'none;',
                                    }}
                                  ></div>
                                </div>
                                <div
                                  className="simplebar-track simplebar-vertical"
                                  style={{ visibility: 'visible' }}
                                >
                                  <div
                                    className="simplebar-scrollbar"
                                    style={{
                                      height: '98px',
                                      transform: 'translate3d(0px, 36px, 0px)',
                                      display: 'block;',
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="p-3 chat-input-section border-top">
                            <div className="row">
                              <div className="col">
                                <div>
                                  <input
                                    type="text"
                                    className="form-control chat-input ps-3"
                                    placeholder="Enter Message..."
                                  />
                                </div>
                              </div>
                              <div className="col-auto">
                                <button
                                  type="submit"
                                  className="btn btn-primary chat-send w-md waves-effect waves-light"
                                >
                                  <span className="d-none d-sm-inline-block me-2">
                                    Send
                                  </span>
                                  <i className="mdi mdi-send"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="card mb-2">
                      <div className="card-header bg-transparent border-bottom">
                        <div className="page-title-box pb-0 d-sm-flex align-items-center justify-content-between">
                          <h4 className="mb-sm-0 ">Filters</h4>
                          <div className="page-title-right">
                            <button
                              onClick={() =>
                                handleFilter(detail.activities, filter)
                              }
                              className="btn btn-sm btn-success"
                            >
                              Filter
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="custom-accordion">
                        <div className="card-body border-top">
                          <div>
                            <h5 className="font-size-14 mb-0">
                              <a
                                href="#collapseExample1"
                                className="text-dark d-block"
                                data-bs-toggle="collapse"
                              >
                                Activity{' '}
                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                              </a>
                            </h5>

                            <div
                              className="collapse show"
                              id="collapseExample1"
                            >
                              <div className="mt-4">
                                <div className="form-check mt-2">
                                  <input
                                    type="radio"
                                    id="productdiscountRadio6"
                                    name="productdiscountRadio"
                                    className="form-check-input"
                                    value="Scan Activity"
                                    checked={activityType === 'Scan Activity'}
                                    onChange={(e) =>
                                      setActivityType(e.target.value)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="productdiscountRadio6"
                                  >
                                    Scan Activity
                                  </label>
                                </div>
                                <div className="form-check mt-2">
                                  <input
                                    type="radio"
                                    id="productdiscountRadio5"
                                    name="productdiscountRadio"
                                    className="form-check-input"
                                    value="Record Activity"
                                    checked={activityType === 'Record Activity'}
                                    onChange={(e) =>
                                      setActivityType(e.target.value)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="productdiscountRadio5"
                                  >
                                    Record Activity
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card-body border-top">
                          <div>
                            <h5 className="font-size-14 mb-0">
                              <a
                                href="#collapseExample2"
                                className="text-dark d-block"
                                data-bs-toggle="collapse"
                              >
                                Tag Color{' '}
                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                              </a>
                            </h5>

                            <div
                              className="collapse show"
                              id="collapseExample2"
                              onChange={(e) => setTagColorTag(e.target.value)}
                            >
                              <div className="mt-4">
                                <div className="form-check mt-2">
                                  <input
                                    type="radio"
                                    id="productsizeRadio1"
                                    name="productsizeRadio"
                                    className="form-check-input"
                                    value="Activate (GREEN Label)"
                                    checked={
                                      colorTag === 'Activate (GREEN Label)'
                                    }
                                    onChange={(e) =>
                                      setTagColorTag(e.target.value)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="productsizeRadio1"
                                  >
                                    Activate (GREEN Label)
                                  </label>
                                </div>
                                <div className="form-check mt-2">
                                  <input
                                    type="radio"
                                    id="productsizeRadio2"
                                    name="productsizeRadio"
                                    className="form-check-input"
                                    value="Verify (RED Label)"
                                    checked={colorTag === 'Verify (RED Label)'}
                                    onChange={(e) =>
                                      setTagColorTag(e.target.value)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="productsizeRadio2"
                                  >
                                    Verify (RED Label)
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body border-top">
                          <div>
                            <h5 className="font-size-14 mb-0">
                              <a
                                href="#collapseExample3"
                                className="collapsed text-dark d-block"
                                data-bs-toggle="collapse"
                              >
                                Activity Compliance
                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                              </a>
                            </h5>

                            <div className="collapse" id="collapseExample3">
                              <div className="mt-4">
                                <div className="form-check mt-2">
                                  <input
                                    type="checkbox"
                                    id="productratingRadio1"
                                    name="productratingRadio1"
                                    className="form-check-input"
                                    value={complianceStatus}
                                    checked={!!complianceStatus}
                                    onChange={(e) =>
                                      setComplianceStatus(e.target.checked)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="productratingRadio1"
                                  >
                                    Compliance
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card-body border-top">
                          <div>
                            <h5 className="font-size-14 mb-0">
                              <a
                                href="#collapseExample4"
                                className="collapsed text-dark d-block"
                                data-bs-toggle="collapse"
                              >
                                Timeframe Range (Hrs)
                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                              </a>
                            </h5>

                            <div className="collapse" id="collapseExample4">
                              <div className="mt-4">
                                <div className="form-check mt-2">
                                  <Box display="flex" flexDirection="column">
                                    <Slider
                                      min={0}
                                      max={1000}
                                      step={24}
                                      defaultValue={value}
                                      marks
                                      onChange={changeValue}
                                      getAriaLabel={() => 'points'}
                                      valueLabelDisplay="auto"
                                      getAriaValueText={valuetext}
                                    />
                                  </Box>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card-body border-top">
                          <div>
                            <h5 className="font-size-14 mb-0">
                              <a
                                href="#collapseExample5"
                                className="collapsed text-dark d-block"
                                data-bs-toggle="collapse"
                              >
                                Point Range
                                <i className="mdi mdi-minus float-end accor-plus-icon"></i>
                              </a>
                            </h5>
                            <div className="collapse" id="collapseExample5">
                              <div className="mt-4">
                                <div className="form-check mt-2">
                                  <Box display="flex" flexDirection="column">
                                    <Slider
                                      min={0}
                                      max={100}
                                      step={5}
                                      defaultValue={filterPoint}
                                      marks
                                      onChange={handleChange}
                                      getAriaLabel={() => 'points'}
                                      valueLabelDisplay="auto"
                                      getAriaValueText={valuetext}
                                    />
                                  </Box>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="float-end">
                      <button
                        className="btn-sm btn mt-2 mb-2 btn-danger"
                        onClick={() => handleResetFilter()}
                      >
                        Clear filter
                      </button>
                    </div>
                  </div>
                  <div className="col-xl-8">
                    <ReactCardFlip
                      isFlipped={isFlipped}
                      flipDirection="horizontal"
                    >
                      <div className="front">
                        <div className="row">
                          <div className="col-12 mb-2">
                            <div className="d-sm-flex align-items-center justify-content-between">
                              <h5 className="card-title mb-sm-0 font-size-15">
                                Farmsured Protocol Activities
                              </h5>
                            </div>
                          </div>
                        </div>

                        {detail &&
                        detail.activities &&
                        detail.activities.length > 0 ? (
                          <div className="scroll">
                            {filterResult.map((item) => (
                              <div className="flex-contain mb-3" key={item._id}>
                                <div className="item color-bar p-4">
                                  <div className="d-flex">
                                    <div className="avatar-sm me-3">
                                      <span
                                        className={`avatar-title bg-light rounded-circle ${
                                          item.compliance
                                            ? 'text-primary'
                                            : 'text-danger'
                                        } font-size-24`}
                                      >
                                        <i
                                          className={`${
                                            item.compliance
                                              ? 'ri-checkbox-circle-line m-1'
                                              : 'ri-close-circle-line'
                                          }`}
                                        ></i>
                                      </span>
                                    </div>
                                    <div className="flex-1 align-self-center overflow-hidden">
                                      <h5 className="card-title">
                                        {item.activity}
                                      </h5>
                                      <p className="text-muted mb-0">
                                        {`${item.point} points`}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="item">
                                  <h5 className="card-title">
                                    <b>{item.farmActivity}</b>{' '}
                                  </h5>
                                  <p className="">
                                    <span className="me-2">
                                      <i className="fal fa-hourglass-half font-size-14 me-1"></i>{' '}
                                      {item.timeLineFrom} - {item.timeLineTo}{' '}
                                      Hrs
                                    </span>
                                  </p>
                                </div>
                                <div className="item">
                                  <h5 className="card-title mt-2">
                                    <b>
                                      {item.compliance
                                        ? '100% Completed'
                                        : '0% Completed'}
                                    </b>
                                  </h5>
                                  <LinearProgress
                                    variant="determinate"
                                    className="mt-2 p-1"
                                    color="primary"
                                    value={item.compliance ? 100 : 0}
                                  />
                                </div>
                                <div className="item">
                                  <div className="">
                                    <button
                                      className="btn btn-success"
                                      onClick={() =>
                                        handleComplianceDialog(item)
                                      }
                                    >
                                      <i className="fal fa-file me-2"></i>{' '}
                                      Details
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </div>

                      <div className="back">
                        <div className="card">
                          <div className="card-body">
                            <button
                              className="btn btn-info btn-sm mb-3"
                              onClick={() => (
                                setIsFlipped(false), setComment('')
                              )}
                            >
                              Back
                            </button>
                            <div className="row mt-3">
                              <div className="col-md-5">
                                <div className="product-img">
                                  {activity === 'Scan Activity' &&
                                  compliance ? (
                                    <div className="card_image_scan">
                                      <div
                                        className=""
                                        style={{
                                          fontSize: '150px',
                                          color: 'green',
                                        }}
                                      >
                                        <i className="fa fa-qrcode img-fluid mx-auto d-block"></i>
                                      </div>
                                    </div>
                                  ) : activity === 'Scan Activity' &&
                                    !compliance ? (
                                    <div className="card_image_scan">
                                      <div
                                        className=""
                                        style={{
                                          fontSize: '150px',
                                          color: 'red',
                                        }}
                                      >
                                        <i className="fa fa-qrcode img-fluid mx-auto d-block"></i>
                                      </div>
                                    </div>
                                  ) : null}

                                  {activity === 'Record Activity' &&
                                  compliance ? (
                                    <div className="card_image_record">
                                      <div
                                        className=""
                                        style={{
                                          fontSize: '150px',
                                          color: 'green',
                                        }}
                                      >
                                        <i className="fa fa-file img-fluid mx-auto d-block"></i>
                                      </div>
                                    </div>
                                  ) : activity === 'Record Activity' &&
                                    !compliance ? (
                                    <div className="card_image_record">
                                      <div
                                        className=""
                                        style={{
                                          fontSize: '150px',
                                          color: 'red',
                                        }}
                                      >
                                        <i className="fa fa-file img-fluid mx-auto d-block"></i>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-7 mt-2">
                                {compliance ? (
                                  <>
                                    <div>
                                      <i className="me-2">👍</i>
                                      <p
                                        className="badge bg-success font-size-13"
                                        style={{
                                          borderRadius: '100px',
                                          padding: '5px 10px 5px 10px',
                                        }}
                                      >
                                        Complied
                                      </p>
                                      <p
                                        className="badge font-size-13"
                                        style={{
                                          borderRadius: '100px',
                                          padding: '5px 10px 5px 10px',
                                          background: 'green',
                                          color: 'white',
                                          marginLeft: '10px',
                                        }}
                                      >
                                        {moment(date).format(
                                          'Do MMM YYYY, h:mm a',
                                        )}
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <div className="">
                                    <i className="me-2">👎</i>
                                    <p
                                      className="badge bg-danger font-size-13"
                                      style={{
                                        borderRadius: '100px',
                                        padding: '5px 10px 5px 10px',
                                      }}
                                    >
                                      Not Complied
                                    </p>
                                  </div>
                                )}
                                <p className="mb-0">
                                  <a href="#" className="text-primary ">
                                    {activity}
                                  </a>
                                </p>

                                <h4 className="card-title">
                                  <b>{farmActivity}</b>
                                </h4>

                                <p>{task}</p>

                                <ul className="item-list">
                                  {tagColor ? (
                                    <li>
                                      <i className="fal fa-tags  me-1"></i>
                                      {tagColor}
                                    </li>
                                  ) : null}

                                  <li>
                                    <i className="fal fa-hourglass-half me-1"></i>{' '}
                                    {`${timeLineFrom} - ${timeLineTo} Hours`}
                                  </li>

                                  <li>
                                    <i className="fal fa-clock me-1"></i>
                                    {point} points
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <hr className="my-4"></hr>

                            <div className="col-lg-12">
                              {activity === 'Record Activity' ? (
                                <div className="card comment--card text-dark">
                                  <div className="card-body">
                                    <p className="card-title">
                                      <b>Activity Comment:</b>
                                    </p>
                                    <p className="card-desc">
                                      {comment ? parse(comment) : 'No Comment'}
                                    </p>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ReactCardFlip>
                  </div>
                </div>
              </div>
            )}

            <section>
              {loadingScanActivities ? (
                <div className="tailspin">
                  <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
                </div>
              ) : scanActivitiesError ? (
                <MessageBox variant="danger">{scanActivitiesError}</MessageBox>
              ) : (
                <div className="row mt-4">
                  <div className="col-md-12">
                    <h5 className="card-title mb-2">Scanned Activities</h5>
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive-xl">
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 20, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ViewFarmCyclePage
