import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { createTag } from '../../../actions/adminActions/tagActions/tagGeneration'
import { LoadingBox, MessageBox } from '../../../components'
import { TAG_GENERATION_RESET } from '../../../constants/adminConstants/TagsConstants'
import SweetAlert from 'react-bootstrap-sweetalert'
import { allProductCategory } from '../../../actions/adminActions/productActions/productCategory'
import { allAssignedPC } from '../../../actions/adminActions/productActions/allAssignedPC'

function TagGenerationPage(props) {
  const [quantity, setQuantity] = useState('')
  const [points, setPoint] = useState('')
  const [category, setProductCategory] = useState('')
  const [alert, setAlert] = useState(null)

  const tagsCreation = useSelector((state) => state.tagsCreation)
  const { loading, newTag, error } = tagsCreation

  const productCategory = useSelector((state) => state.productCategory)
  const { loading: loadingCategory, categorylist } = productCategory

  const allProductCategoryPoint = useSelector(
    (state) => state.allProductCategoryPoint,
  )
  const { assignedPC } = allProductCategoryPoint

  const dispatch = useDispatch()

  const handleCreateTag = (e) => {
    e.preventDefault()

    if (!quantity || !points) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="danger"
          title="Fill all fields"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          All fields should be filled!!!
        </SweetAlert>,
      )
    } else if (quantity < 500 || quantity > 3000) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="danger"
          title="Error"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          You can't generate less than 500 or greater than 3000 at once!!!
        </SweetAlert>,
      )
    } else {
      dispatch(createTag(category, quantity, points))
    }
  }

  useEffect(() => {
    if (newTag) {
      setTimeout(() => {
        dispatch({ type: TAG_GENERATION_RESET })
      }, 2000)

      setTimeout(() => {
        props.history.push('/tag-analysis')
        window.location.reload()
      }, 2200)
    }
  }, [newTag, dispatch])

  useEffect(() => {
    dispatch(allProductCategory())
    dispatch(allAssignedPC())
  }, [dispatch])

  useEffect(() => {
    if (category && assignedPC) {
      const object =
        assignedPC &&
        assignedPC.length > 0 &&
        assignedPC.filter((obj) => obj.category._id === category)

      if (object[0] && object[0].point) {
        return setPoint(object[0].point)
      } else {
        setPoint('')
      }
    }
  }, [category, allAssignedPC])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      {loading ? (
        <LoadingBox />
      ) : (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Tag Generation</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a href="javascript: void(0);">Tags</a>
                        </li>
                        <li className="breadcrumb-item active">
                          Tag Generation
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              {newTag && (
                <MessageBox variant="success">
                  Tag created successfully
                </MessageBox>
              )}
              {error && <MessageBox variant="danger">{error} </MessageBox>}
              {/* start row */}
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Tag Generation System</h4>

                      <p className="card-tile-desc mt-1">
                        Note: You can't generate less than 500 or greater than
                        3000 at once!
                      </p>
                      <form
                        className="needs-validation"
                        onSubmit={handleCreateTag}
                        novalidate
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                forHtml="contactPersonName"
                              >
                                Product Category
                              </label>
                              <select
                                onChange={(e) =>
                                  setProductCategory(e.target.value)
                                }
                                className="select2 form-select"
                                required
                              >
                                <option>Select product category</option>
                                {loadingCategory ? (
                                  'Loading...'
                                ) : categorylist.length > 0 ? (
                                  <>
                                    {categorylist.map((item) => (
                                      <option value={item._id} key={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </>
                                ) : (
                                  'there is no product category'
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3"></div>

                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                for="validationCustom01"
                                className="form-label"
                              >
                                Amount of Tags*
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Quantity"
                                required
                                onChange={(e) => setQuantity(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-3"></div>

                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                for="validationCustom01"
                                className="form-label"
                              >
                                Assign Point to each Tag*
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Points"
                                required
                                disabled
                                value={points}
                                onChange={(e) => setPoint(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-3"></div>
                        </div>

                        <div>
                          <button className="btn btn-success" type="submit">
                            Generate Tags
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* <!-- end card --> */}
                </div>
              </div>
              {/* end row */}
            </div>
          </div>
        </div>
      )}
      {alert}
      <Footer />
    </div>
  )
}

export default TagGenerationPage
