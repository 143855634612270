import axios from 'axios'
import {
  FARMER_CHECK_STATUS_REQUEST,
  FARMER_CHECK_STATUS_SUCCESS,
  FARMER_CHECK_STATUS_FAIL,
} from '../../../constants/farmerConstants/tagsConstants'

export const verifyStatus = (pin) => async (dispatch, getState) => {
  dispatch({
    type: FARMER_CHECK_STATUS_REQUEST,
    payload: { pin },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/farmer/check-status',
      { pin },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: FARMER_CHECK_STATUS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: FARMER_CHECK_STATUS_FAIL, payload: message })
  }
}
