import axios from 'axios'
import {
  UPDATE_SERVICE_COVERAGE_FAIL,
  UPDATE_SERVICE_COVERAGE_REQUEST,
  UPDATE_SERVICE_COVERAGE_SUCCESS,
} from '../../../constants/adminConstants/serviceConstants'

export const editServiceCategory = (itemId, name, description) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: UPDATE_SERVICE_COVERAGE_REQUEST,
    payload: {
      itemId,
      name,
      description,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/service-category/update',
      { itemId, name, description },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: UPDATE_SERVICE_COVERAGE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: UPDATE_SERVICE_COVERAGE_FAIL, payload: message })
  }
}
