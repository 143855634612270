import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import states from '../../../utilis.js'
import { generatePassword } from '../../../components/functions'
import { adminAddManufacturer } from '../../../actions/adminActions/manufactuerActions/createManufacturer'
import { ADD_MANUFACTURER_RESET } from '../../../constants/adminConstants/manufacturersConstants'
import { LoadingBox, MessageBox } from '../../../components'

function CreateManufacturerPage() {
  const [companyName, setCompanyName] = useState('')
  const [personName, setContactPersonName] = useState('')
  const [phone, setContactPersonPhone] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [password, setPassword] = useState('')
  const [account] = useState('manufacturer')

  const newManufacturer = useSelector((state) => state.newManufacturer)
  const { loading, result, error } = newManufacturer

  const myFunction = () => {
    var x = document.getElementById('visible')
    var y = document.getElementById('visibleText')
    if (x.type === 'password') {
      x.type = 'text'
      y.textContent = 'Hide Password'
    } else {
      x.type = 'password'
      y.textContent = 'Show Password'
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (result) {
      setTimeout(() => {
        dispatch({ type: ADD_MANUFACTURER_RESET })
      }, 2100)
    }

    return () => {
      //
    }
  }, [result, dispatch])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      adminAddManufacturer({
        companyName,
        personName,
        phone,
        email,
        address,
        city,
        state,
        account,
        password,
      }),
    )
    setPassword('')
  }

  const handlePassword = (e) => {
    e.preventDefault()
    setPassword(generatePassword())
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        {loading ? (
          <LoadingBox />
        ) : (
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}

              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Add New Manufacturer</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a href="javascript: void(0);">Manufacturer</a>
                        </li>
                        <li className="breadcrumb-item active">
                          Manufacturers
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}
              {result && (
                <MessageBox variant="success">{result.message}</MessageBox>
              )}
              {error && <MessageBox variant="danger">{error}</MessageBox>}
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div>
                        <h4 className="card-title">Basic Information</h4>
                        <p className="card-title-desc">
                          Enter manufacturer's information below
                        </p>

                        <form onSubmit={submitHandler}>
                          <div className="row mt-3">
                            <div className="col-6">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  forHtml="companyName"
                                >
                                  Company Name
                                </label>
                                <input
                                  id="companyName"
                                  name="companyName"
                                  type="text"
                                  className="form-control"
                                  required
                                  onChange={(e) =>
                                    setCompanyName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  forHtml="contactPersonName"
                                >
                                  Contact Person Name
                                </label>
                                <input
                                  id="contactPersonName"
                                  name="contactPersonName"
                                  type="text"
                                  className="form-control"
                                  required
                                  onChange={(e) =>
                                    setContactPersonName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="mb-3">
                                <label className="form-label" forHtml="name">
                                  Email
                                </label>
                                <input
                                  id="companyEmail"
                                  name="email"
                                  type="email"
                                  className="form-control"
                                  required
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="mb-3">
                                <label className="form-label" forHtml="name">
                                  Contact Phone Number
                                </label>
                                <input
                                  id="phone"
                                  name="number"
                                  type="number"
                                  className="form-control"
                                  required
                                  onChange={(e) =>
                                    setContactPersonPhone(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <label className="form-label" forHtml="address">
                                Address
                              </label>
                              <input
                                id="address"
                                name="address"
                                type="text"
                                className="form-control"
                                required
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </div>
                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label
                                    className="form-label"
                                    forHtml="address"
                                  >
                                    State
                                  </label>
                                  <select
                                    onChange={(e) => setState(e.target.value)}
                                    className="select2 form-select"
                                    required
                                  >
                                    <option>Select</option>

                                    {states.map((item) => (
                                      <option value={item}>{item}</option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label className="form-label" forHtml="city">
                                    City
                                  </label>
                                  <input
                                    id="city"
                                    name="city"
                                    type="text"
                                    className="form-control"
                                    required
                                    onChange={(e) => setCity(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="mb-3 mt-3">
                                <label
                                  className="form-label"
                                  htmlFor="password"
                                >
                                  Password
                                </label>
                                <input
                                  id="visible"
                                  name="password"
                                  type="password"
                                  className="form-control"
                                  required
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                              <span
                                className="form-control btn btn-sm btn-password toggleText"
                                id="visibleText"
                                onClick={myFunction}
                              >
                                Show Password
                              </span>

                              <div className="mt-2">
                                <button
                                  className="form-control btn btn-sm btn-success"
                                  onClick={handlePassword}
                                >
                                  Generate Password
                                </button>
                              </div>
                            </div>
                          </div>
                          <button
                            className="btn btn-success mt-3"
                            type="submit"
                            onSubmit={submitHandler}
                          >
                            Add New Manufacturer
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end row --> */}
            </div>
            {/* <!-- container-fluid --> */}
          </div>
        )}
      </div>
      {/* <!-- end main content--> */}

      <Footer />
    </div>
  )
}

export default CreateManufacturerPage
