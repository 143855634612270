import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MDBDataTableV5 } from 'mdbreact'
import moment from 'moment'
import { formatNumber } from '../../../components/functions'
import { MessageBox, LoadingBox } from '../../../components'
import { getAllUserLoans } from '../../../actions/adminActions/farmtroveActions/getAllUserLoans'

export default function LoanPage() {
  const [datatable, setDatatable] = useState({})

  const userLoans = useSelector((state) => state.userLoans)
  const { loading, loans, error } = userLoans

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllUserLoans())
  }, [dispatch])

  useEffect(() => {
    if (loans) {
      setDatatable({
        columns: [
          {
            label: 'Application Date',
            field: 'date',
            width: 50,
          },
          {
            label: 'User',
            field: 'user',
            width: 100,
          },
          {
            label: 'Amount',
            field: 'amount',
            width: 100,
          },
          {
            label: 'Offered Amount',
            field: 'offerAmount',
            width: 100,
          },
          {
            label: 'Loan Duration',
            field: 'duration',
            width: 200,
          },
          {
            label: 'Status',
            field: 'status',
            width: 50,
          },
          {
            label: 'Offer Status',
            field: 'offerstatus',
            width: 50,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: loans.map((item) => ({
          date: moment(item.applicationDate).format('DD MMM YYYY, hh:mm a'),
          user:
            item.user && item.user.firstName && item.user.lastName
              ? `${item && item.user && item.user.firstName} ${item && item.user && item.user.lastName
              }`
              : '',
          amount: item.loanAmount
            ? `NGN ${formatNumber(item.loanAmount)}`
            : 'NGN 0',
          offerAmount: item.offerAmount
            ? `NGN ${formatNumber(item.offerAmount)}`
            : 'NGN 0',
          duration: `${item && item.loanProvider && item.loanProvider.duration} month(s)`,
          status: (
            <div
              className={`badge badge-soft-${item.status === 'Pending'
                ? 'secondary'
                : item.status === 'Approved'
                  ? 'success'
                  : item.status === 'Active'
                    ? 'primary'
                    : item.status === 'Reviewing'
                      ? 'warning'
                      : item.status === 'Complete'
                        ? 'secondary'
                        : item.status === 'Reject'
                          ? 'danger'
                          : ''

                } font-size-13`}
            >
              {item.status}
            </div>
          ),
          action: (
            <a
              href={`/farmtrove/application/${item._id}`}
              className="btn btn-sm btn-success"
            >
              Details
            </a>
          ),
        })),
      })
    }
  }, [loans])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Loan</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmtrove</a>
                      </li>
                      <li className="breadcrumb-item active">Loans</li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title mb-3">Loans Application</h5>
                    <div className="table-responsive-xl">
                      {error && (
                        <MessageBox variant="danger">{error}</MessageBox>
                      )}
                      {loading ? (
                        <LoadingBox />
                      ) : (
                        <div className="table-responsive-xl">
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 10, 20, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
