import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listOrders } from '../../../actions/manufacturerActions/labelsActions/allOrders'
import { Header, LeftSidebar, RightSidebar, Footer } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import { formatNumber } from '../../../components/functions'
import { MDBDataTableV5 } from 'mdbreact'

function LabelsOrdersPage() {
  const [datatable, setDatatable] = useState({})

  const manufacturerOrdersList = useSelector(
    (state) => state.manufacturerOrdersList,
  )
  const { loading, error, orders } = manufacturerOrdersList

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listOrders())
    return () => {
      //
    }
  }, [dispatch])

  useEffect(() => {
    if (orders) {
      setDatatable({
        columns: [
          {
            label: 'Order ID',
            field: 'orderId',
            width: 50,
          },
          {
            label: 'Date Requested',
            field: 'date',
            width: 300,
          },
          {
            label: 'Product',
            field: 'product',
            width: 200,
          },
          {
            label: 'Product Category',
            field: 'category',
            width: 200,
          },
          {
            label: 'No. of Package(s)',
            field: 'amount',
            width: 200,
          },
          {
            label: 'Status',
            field: 'status',
            width: 100,
          },
        ],
        rows: orders.map((item) => ({
          orderId: item.orderID,
          date: moment(item.created_at).format('Do MMMM YYYY,  h:mm a'),
          product: item.product && item.product.name ? item.product.name : '',
          category:
            item.product && item.product.category
              ? item.product.category.name
              : '',
          amount: item.amount ? formatNumber(item.amount) : '',
          status: (
            <div className={`badge badge-soft-${item.status} font-size-12`}>
              {item.status}
            </div>
          ),
        })),
      })
    }
  }, [orders])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">All Tag Request</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Tag Management</a>
                      </li>
                      <li className="breadcrumb-item active">Tag Requests</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {error ? <MessageBox variant="danger">{error}</MessageBox> : null}
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="float-end">
                      <a
                        href="/manufacturer/new-tag-request"
                        className="btn btn-sm btn-success"
                      >
                        New Order
                      </a>
                    </div>
                    {loading ? (
                      <LoadingBox />
                    ) : (
                      <div className="table-responsive-xl">
                        <MDBDataTableV5
                          hover
                          entriesOptions={[5, 20, 25, 50]}
                          entries={10}
                          pagesAmount={4}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                          barReverse
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default LabelsOrdersPage
