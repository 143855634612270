export const ALL_PACKAGES_REQUEST = 'ALL_PACKAGES_REQUEST'
export const ALL_PACKAGES_SUCCESS = 'ALL_PACKAGES_SUCCESS'
export const ALL_PACKAGES_FAIL = 'ALL_PACKAGES_FAIL'

export const PACKAGE_CREATE_REQUEST = 'PACKAGE_CREATE_REQUEST'
export const PACKAGE_CREATE_SUCCESS = 'PACKAGE_CREATE_SUCCESS'
export const PACKAGE_CREATE_FAIL = 'PACKAGE_CREATE_FAIL'
export const PACKAGE_CREATE_RESET = 'PACKAGE_CREATE_RESET'

export const PACKAGE_DELETE_REQUEST = 'PACKAGE_DELETE_REQUEST'
export const PACKAGE_DELETE_SUCCESS = 'PACKAGE_DELETE_SUCCESS'
export const PACKAGE_DELETE_FAIL = 'PACKAGE_DELETE_FAIL'
export const PACKAGE_DELETE_RESET = 'PACKAGE_DELETE_RESET'

export const PACKAGE_DETAILS_REQUEST = 'PACKAGE_DETAILS_REQUEST'
export const PACKAGE_DETAILS_SUCCESS = 'PACKAGE_DETAILS_SUCCESS'
export const PACKAGE_DETAILS_FAIL = 'PACKAGE_DETAILS_FAIL'

export const UPDATE_PACKAGE_DETAILS_REQUEST = 'UPDATE_PACKAGE_DETAILS_REQUEST'
export const UPDATE_PACKAGE_DETAILS_SUCCESS = 'UPDATE_PACKAGE_DETAILS_SUCCESS'
export const UPDATE_PACKAGE_DETAILS_FAIL = 'UPDATE_PACKAGE_DETAILS_FAIL'
export const UPDATE_PACKAGE_DETAILS_RESET = 'UPDATE_PACKAGE_DETAILS_RESET'

export const PACKAGES_CATEGORY_LIST_REQUEST = 'PACKAGES_CATEGORY_LIST_REQUEST'
export const PACKAGES_CATEGORY_LIST_SUCCESS = 'PACKAGES_CATEGORY_LIST_SUCCESS'
export const PACKAGES_CATEGORY_LIST_FAIL = 'PACKAGES_CATEGORY_LIST_FAIL'

export const CREATE_PACKAGE_CATEGORY_REQUEST = 'CREATE_PACKAGE_CATEGORY_REQUEST'
export const CREATE_PACKAGE_CATEGORY_SUCCESS = 'CREATE_PACKAGE_CATEGORY_SUCCESS'
export const CREATE_PACKAGE_CATEGORY_FAIL = 'CREATE_PACKAGE_CATEGORY_FAIL'
export const CREATE_PACKAGE_CATEGORY_RESET = 'CREATE_PACKAGE_CATEGORY_RESET'

export const PACKAGE_CATEGORY_DELETE_REQUEST = 'PACKAGE_CATEGORY_DELETE_REQUEST'
export const PACKAGE_CATEGORY_DELETE_SUCCESS = 'PACKAGE_CATEGORY_DELETE_SUCCESS'
export const PACKAGE_CATEGORY_DELETE_FAIL = 'PACKAGE_CATEGORY_DELETE_FAIL'
export const PACKAGE_CATEGORY_DELETE_RESET = 'PACKAGE_CATEGORY_DELETE_RESET'

export const UPDATE_PACKAGE_CATEGORY_REQUEST = 'UPDATE_PACKAGE_CATEGORY_REQUEST'
export const UPDATE_PACKAGE_CATEGORY_SUCCESS = 'UPDATE_PACKAGE_CATEGORY_SUCCESS'
export const UPDATE_PACKAGE_CATEGORY_FAIL = 'UPDATE_PACKAGE_CATEGORY_FAIL'
export const UPDATE_PACKAGE_CATEGORY_RESET = 'UPDATE_PACKAGE_CATEGORY_RESET'

export const UPDATE_PROTOCOL_ACTIVITY_REQUEST = 'UPDATE_PROTOCOL_ACTIVITY_REQUEST'
export const UPDATE_PROTOCOL_ACTIVITY_SUCCESS = 'UPDATE_PROTOCOL_ACTIVITY_SUCCESS'
export const UPDATE_PROTOCOL_ACTIVITY_FAIL = 'UPDATE_PROTOCOL_ACTIVITY_FAIL'
export const UPDATE_PROTOCOL_ACTIVITY_RESET = 'UPDATE_PROTOCOL_ACTIVITY_RESET'

export const UPDATE_PROTOCOL_BENEFIT_REQUEST = 'UPDATE_PROTOCOL_BENEFIT_REQUEST'
export const UPDATE_PROTOCOL_BENEFIT_SUCCESS = 'UPDATE_PROTOCOL_BENEFIT_SUCCESS'
export const UPDATE_PROTOCOL_BENEFIT_FAIL = 'UPDATE_PROTOCOL_BENEFIT_FAIL'
export const UPDATE_PROTOCOL_BENEFIT_RESET = 'UPDATE_PROTOCOL_BENEFIT_RESET'