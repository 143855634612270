import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import NaijaStates from 'naija-state-local-government'
import states from '../../../utilis'
import { useDispatch, useSelector } from 'react-redux'
import { listProducts } from '../../../actions/serviceproviderActions/productsAction/products'
import { addService } from '../../../actions/serviceproviderActions/productsAction/addService'
import { LoadingBox, MessageBox } from '../../../components'
import { ADD_SERVICE_RESET } from '../../../constants/serviceProviderConstants/productConstants'

export default function AddNewServicePage() {

    const [product, setProduct] = useState('')
    const [price, setPrice] = useState(0)
    const [markup, setMarkup] = useState(0)
    const [total, setTotal] = useState(0)
    const [state, setState] = useState('')
    const [inputList, setinputList] = useState([{ lga: '', price: '' }])

    const dispatch = useDispatch()

    // All product State
    const serviceProducts = useSelector((state) => state.serviceProducts)
    const { loading: loadingProducts, products, error: productsError } = serviceProducts

    // New Service State 
    const newService = useSelector((state) => state.newService)
    const { loading, created, error } = newService


    useEffect(() => {
        dispatch(listProducts())

        
        if (created) {
            setinputList([{ lga: '', price: '' }])
            setProduct('')
            setPrice(0)
            setMarkup(0)
            setTotal(0)
            setTimeout(() => {
                dispatch({ type: ADD_SERVICE_RESET })
            }, 2500)
        }
    }, [created])

    useEffect(() => {
        if (product) {
            const result = products && products.find((itm) => itm._id === product)
            setPrice(result && result.price)
        }

        if (product && markup) {
            const result = (price * (markup / 100) + price)
            setTotal(result)
        }
    }, [product, markup])


    const handleinputchange = (e, index) => {
        const { name, value } = e.target
        const list = [...inputList]
        list[index][name] = value
        list[index]["state"] = state
        setinputList(list)
    }

    const handleremove = (index) => {
        const list = [...inputList]
        list.splice(index, 1)
        setinputList(list)
    }

    const handleaddclick = () => {
        setinputList([...inputList, { lga: '', price: '' }])
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(addService(product, markup, state, inputList))
    }

    return (
        <div id="layout-wrapper">
            <Header />
            <LeftSidebar />
            <RightSidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Products/Service</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Farmsured</a>
                                            </li>
                                            <li className="breadcrumb-item active">Product/Service</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loading ? <LoadingBox /> :
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {productsError ? <MessageBox variant='danger'>{productsError}</MessageBox> : null}
                                            {error ? <MessageBox variant='danger'>{error}</MessageBox> : null}
                                            {created ? <MessageBox variant='success'>{created.message}</MessageBox> : null}
                                            <div className='row'>
                                                <div className="col-8">
                                                    <div className="mb-3">
                                                        <label
                                                            className="form-label"
                                                            forHtml="contactPersonName"
                                                        >
                                                            Product
                                                        </label>
                                                        <select
                                                            onChange={(e) =>
                                                                setProduct(e.target.value)
                                                            }
                                                            className="select2 form-select"
                                                            required
                                                        >
                                                            <option>Select</option>
                                                            {loadingProducts ? (
                                                                'Loading...'
                                                            ) : products && products.length > 0 ? (
                                                                <>
                                                                    {products.map((item) => (
                                                                        <option value={item._id} key={item._id}>
                                                                            {item.name}
                                                                        </option>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                'there is no product category'
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-8">
                                                    <div className="mb-3">
                                                        <label
                                                            className="form-label"
                                                            forHtml="ProductPrice"
                                                        >
                                                            Price
                                                        </label>
                                                        <input
                                                            id="ProductPrice"
                                                            name="ProductPrice"
                                                            type="number"
                                                            className="form-control"
                                                            required
                                                            value={price}
                                                            placeholder="Product Price"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-8">
                                                    <div className="mb-3">
                                                        <label
                                                            className="form-label"
                                                            forHtml="ProductPrice"
                                                        >
                                                            Price Markup
                                                        </label>
                                                        <input
                                                            id="ProductPrice"
                                                            name="ProductPrice"
                                                            type="number"
                                                            className="form-control"
                                                            required
                                                            onChange={(e) => setMarkup(e.target.value)}
                                                            placeholder="Product Price (%)"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-8">
                                                    <div className="mb-3">
                                                        <label
                                                            className="form-label"
                                                            forHtml="ProductPrice"
                                                        >
                                                            Total Price
                                                        </label>
                                                        <input
                                                            id="ProductPrice"
                                                            name="ProductPrice"
                                                            type="number"
                                                            className="form-control"
                                                            required
                                                            value={total}
                                                            placeholder="Product Price"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>


                                                <h4 className="card-title">Logistics</h4>

                                                <div className="col-md-6 mb-3 mt-1">
                                                    <label htmlFor="example-text-input8">
                                                        Select assigned state
                                                    </label>
                                                    <select
                                                        className="form-select form-control select2"
                                                        required
                                                        placeholder="Select a state"
                                                        onChange={(e) => setState(e.target.value)}
                                                    >
                                                        <option>Select a state</option>
                                                        {states.map((item) => (
                                                            <option value={item}>{item}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                {inputList.map((x, i) => {
                                                    return (
                                                        <div className="row mb-3">
                                                            <div className="form-group col-md-8 mb-2">
                                                                <label
                                                                    htmlFor="example-text-input"
                                                                    className="col-md-12 col-form-label"
                                                                >
                                                                    Select assigned local government area
                                                                </label>
                                                                <select
                                                                    className="form-select form-control select2"
                                                                    required
                                                                    name="lga"
                                                                    placeholder="Select a local government area"
                                                                    onChange={(e) => handleinputchange(e, i)}
                                                                >
                                                                    {state && state === 'Select a state' ? (
                                                                        <option>
                                                                            Select a local government area
                                                                        </option>
                                                                    ) : state ? (
                                                                        <>
                                                                            <option>
                                                                                Select a local government area
                                                                            </option>
                                                                            {NaijaStates.lgas(state).lgas.map(
                                                                                (item) => (
                                                                                    <option value={item}>{item}</option>
                                                                                ),
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <option>
                                                                            Select a local government area
                                                                        </option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-8">
                                                                <label
                                                                    htmlFor="example-text-input"
                                                                    className="col-md-12 col-form-label"
                                                                >
                                                                    Delivery price
                                                                </label>
                                                                <input
                                                                    id="price"
                                                                    name="price"
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Price"
                                                                    required
                                                                    onChange={(e) => handleinputchange(e, i)}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-4 mt-4">
                                                                {inputList.length !== 1 && (
                                                                    <button
                                                                        className="btn btn-danger mx-1"
                                                                        type="button"
                                                                        onClick={() => handleremove(i)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                )}
                                                                {inputList.length - 1 === i && (
                                                                    <button
                                                                        className="btn btn-success"
                                                                        onClick={handleaddclick}
                                                                    >
                                                                        +
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                })}


                                            </div>
                                            <button
                                                className="btn btn-success btn-bg" onClick={handleSubmit}
                                                type="submit"
                                            >
                                                Create Service
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
