import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import resetPassword from '../../actions/authActions/resetPassword'
import resetPin from '../../actions/authActions/resetPin'
import { Footer, Header, RightSidebar, LeftSidebar } from '../../partials'
import { LoadingBox, MessageBox } from '../../components'
import {
  PASSWORD_RESET_RESET,
  RESET_TRASNACTION_PIN_RESET,
} from '../../constants/authConstants/resetPasswordContants'
import SweetAlert from 'react-bootstrap-sweetalert'

function PasswordPage() {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmNewPassword] = useState('')
  const [oldPin, setOldPin] = useState('')
  const [newPin, setNewPin] = useState('')
  const [confirmPin, setConfirmNewPin] = useState('')
  const [alert, setAlert] = useState(null)

  // Password reset state
  const passwordReset = useSelector((state) => state.passwordReset)
  const {
    loading: resetLoading,
    error: resetError,
    userResetPassword,
  } = passwordReset

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const resetTransactionPin = useSelector((state) => state.resetTransactionPin)
  const {
    loading: updatePinLoading,
    error: updatePinError,
    pinReset,
  } = resetTransactionPin

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    if (newPassword !== confirmPassword) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Incorrect Password"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Password and confirm password doesn't match
        </SweetAlert>,
      )
    } else {
      dispatch(resetPassword(oldPassword, newPassword))
    }
  }

  useEffect(() => {
    if (userResetPassword) {
      setTimeout(() => {
        dispatch({ type: PASSWORD_RESET_RESET })
        window.location.reload()
      }, 2000)
    }

    if (pinReset) {
      setTimeout(() => {
        dispatch({ type: RESET_TRASNACTION_PIN_RESET })
        window.location.reload()
      }, 2000)
    }
  }, [dispatch, userResetPassword, pinReset])

  const handlePinReset = (e) => {
    e.preventDefault()
    if (newPin !== confirmPin) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Incorrect PIN"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          PIN and confirm pin doesn't match
        </SweetAlert>,
      )
    } else if (newPin.length < 4) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="PIN too short"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          PIN must be 4 or more characters
        </SweetAlert>,
      )
    } else {
      dispatch(resetPin(oldPin, newPin))
    }
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <RightSidebar />
      <LeftSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="">
              <h5 className="">Manage Passwords</h5>
            </div>
            <div className="card">
              <div className="card-body">
                {/* <!-- Nav tabs --> */}
                <ul className="nav nav-tabs nav-tabs-custom " role="tablist">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      data-bs-toggle="tab"
                      to="#profile1"
                      role="tab"
                    >
                      <span className="d-block d-sm-none">
                        <i className="fa fa-key"></i>
                      </span>
                      <span className="d-none d-sm-block">Password</span>
                    </Link>
                  </li>
                </ul>

                {/* <!-- Tab panes --> */}
                <div className="tab-content p-3 text-muted">
                  <div className="tab-pane active"  id="profile1" role="tabpanel">
                    {resetLoading && <LoadingBox />}
                    <form onSubmit={submitHandler}>
                      <div className="row">
                        <div className="col-lg-3">
                          <h6>Password</h6>
                          <p style={{ width: '80%' }}>
                            Changing your password will also reset your password
                            on the Farmsured sysytem.
                          </p>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-md-6">
                              {userResetPassword && (
                                <MessageBox variant="success">
                                  {userResetPassword.message}
                                </MessageBox>
                              )}
                              {resetError && (
                                <MessageBox variant="danger">
                                  {resetError}
                                </MessageBox>
                              )}
                              <label htmlFor="email">Current Password</label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Enter your current password"
                                onChange={(e) => setOldPassword(e.target.value)}
                                required
                              />
                              <hr />
                            </div>
                            <div className="col-md-6"></div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-6">
                              <label htmlFor="email">New Password</label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Enter a new password"
                                required
                                onChange={(e) => setNewPassword(e.target.value)}
                              />
                              <em>Password must be 8 or more characters</em>
                            </div>
                            <div className="col-md-6"></div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-6">
                              <label htmlFor="email">
                                Confirm New Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Enter the password again"
                                required
                                onChange={(e) =>
                                  setConfirmNewPassword(e.target.value)
                                }
                              />

                              <button
                                type="submit"
                                className="btn btn-sm btn-success mt-3"
                                onSubmit={submitHandler}
                              >
                                Update Password
                              </button>
                            </div>
                            <div className="col-md-6"></div>
                          </div>
                        </div>
                      </div>
                    </form>

                    <hr className="my-4" />

                    {updatePinLoading && <LoadingBox />}
                    <form onSubmit={handlePinReset}>
                      <div className="row">
                        <div className="col-lg-3">
                          <h6>Wallet Transaction PIN </h6>
                          <p style={{ width: '80%' }}>
                            Changing your transaction pin will also reset your
                            pin on the Farmtrove wallet sysytem.
                          </p>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-md-6">
                              {pinReset && (
                                <MessageBox variant="success">
                                  {pinReset.message}
                                </MessageBox>
                              )}
                              {updatePinError && (
                                <MessageBox variant="danger">
                                  {updatePinError}
                                </MessageBox>
                              )}
                              <label htmlFor="email">
                                Current Transaction PIN
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Enter your current transaction pin"
                                onChange={(e) => setOldPin(e.target.value)}
                              />
                              <span>
                                <i>For first time user - Leave empty</i>
                              </span>
                              <hr />
                            </div>
                            <div className="col-md-6"></div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-6">
                              <label htmlFor="email">New PIN</label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Enter a new pin"
                                required
                                onChange={(e) => setNewPin(e.target.value)}
                              />
                              <em>PIN must be 4 or more characters</em>
                            </div>
                            <div className="col-md-6"></div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-6">
                              <label htmlFor="email">Confirm New PIN</label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Enter the pin again"
                                required
                                onChange={(e) =>
                                  setConfirmNewPin(e.target.value)
                                }
                              />

                              <button
                                type="submit"
                                className="btn btn-sm btn-success mt-3"
                              >
                                Update PIN
                              </button>
                            </div>
                            <div className="col-md-6"></div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alert}
      <Footer />
    </div>
  )
}

export default PasswordPage
