import {
    SERVICE_PRODUCT_LIST_FAIL,
    SERVICE_PRODUCT_LIST_REQUEST,
    SERVICE_PRODUCT_LIST_SUCCESS,
} from '../../../constants/serviceProviderConstants/productConstants'

export const serviceProductListReducer = (
    state = { loading: true, products: [] },
    action,
) => {
    switch (action.type) {
        case SERVICE_PRODUCT_LIST_REQUEST:
            return { loading: true }
        case SERVICE_PRODUCT_LIST_SUCCESS:
            return { loading: false, products: action.payload }
        case SERVICE_PRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}