import React from 'react'
import { useCountdown } from './useCountdown'
import DateTimeDisplay from './DateTimeDisplay'

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      <button className="btn countdown-link" style={{ padding: 0 }}>
        <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
      </button>
    </div>
  )
}

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate)

  if (days + hours + minutes + seconds <= 0) {
    return <div className={`badge badge-soft-danger font-size-12`}>Expired</div>
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    )
  }
}

export default CountdownTimer

export const CountdownStatus = ({ item, targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate)
  if (days + hours + minutes + seconds <= 0) {
    return <div className={`badge badge-soft-open font-size-13`}>Closed</div>
  } else if (item.redActivatedAt && days + hours + minutes + seconds <= 0) {
    return <div className={`badge badge-soft-open font-size-13`}>Closed</div>
  } else if (item.redActivatedAt && !item.greenActivatedAt) {
    return (
      <div className={`badge badge-soft-danger font-size-13`}>
        Protection Active
      </div>
    )
  } else {
    return (
      <div className={`badge badge-soft-success font-size-13`}>
        Protection Active
      </div>
    )
  }
}
