export const SERVICE_PRODUCT_LIST_REQUEST = 'SERVICE_PRODUCT_LIST_REQUEST'
export const SERVICE_PRODUCT_LIST_SUCCESS = 'SERVICE_PRODUCT_LIST_SUCCESS'
export const SERVICE_PRODUCT_LIST_FAIL = 'SERVICE_PRODUCT_LIST_FAIL'

export const ADD_SERVICE_REQUEST = 'ADD_SERVICE_REQUEST'
export const ADD_SERVICE_SUCCESS = 'ADD_SERVICE_SUCCESS'
export const ADD_SERVICE_FAIL = 'ADD_SERVICE_FAIL'
export const ADD_SERVICE_RESET = 'ADD_SERVICE_RESET'

export const ALL_SERVICE_PRODUCT_LIST_REQUEST = 'ALL_SERVICE_PRODUCT_LIST_REQUEST'
export const ALL_SERVICE_PRODUCT_LIST_SUCCESS = 'ALL_SERVICE_PRODUCT_LIST_SUCCESS'
export const ALL_SERVICE_PRODUCT_LIST_FAIL = 'ALL_SERVICE_PRODUCT_LIST_FAIL'

export const EDIT_SERVICE_PRODUCT_REQUEST = 'EDIT_SERVICE_PRODUCT_REQUEST'
export const EDIT_SERVICE_PRODUCT_SUCCESS = 'EDIT_SERVICE_PRODUCT_SUCCESS'
export const EDIT_SERVICE_PRODUCT_FAIL = 'EDIT_SERVICE_PRODUCT_FAIL'
export const EDIT_SERVICE_PRODUCT_RESET = 'EDIT_SERVICE_PRODUCT_RESET'