export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL'
export const PASSWORD_RESET_RESET = 'PASSWORD_RESET_RESET'

export const RESET_TRASNACTION_PIN_REQUEST = 'RESET_TRASNACTION_PIN_REQUEST'
export const RESET_TRASNACTION_PIN_SUCCESS = 'RESET_TRASNACTION_PIN_SUCCESS'
export const RESET_TRASNACTION_PIN_FAIL = 'RESET_TRASNACTION_PIN_FAIL'
export const RESET_TRASNACTION_PIN_RESET = 'RESET_TRASNACTION_PIN_RESET'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET'

export const EMAIL_PASSWORD_RESET_REQUEST = 'EMAIL_PASSWORD_RESET_REQUEST'
export const EMAIL_PASSWORD_RESET_SUCCESS = 'EMAIL_PASSWORD_RESET_SUCCESS'
export const EMAIL_PASSWORD_RESET_FAIL = 'EMAIL_PASSWORD_RESET_FAIL'
export const EMAIL_PASSWORD_RESET = 'EMAIL_PASSWORD_RESET'
