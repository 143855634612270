import axios from 'axios'
import {
  ORGANISATIONS_SERVICE_CATEGORY_FAIL,
  ORGANISATIONS_SERVICE_CATEGORY_REQUEST,
  ORGANISATIONS_SERVICE_CATEGORY_SUCCESS,
} from '../../../constants/adminConstants/serviceConstants'

export const allOrganisationCategory = () => async (dispatch, getState) => {
  dispatch({
    type: ORGANISATIONS_SERVICE_CATEGORY_REQUEST,
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get(
      '/api/admin/service-category/organisation',
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: ORGANISATIONS_SERVICE_CATEGORY_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: ORGANISATIONS_SERVICE_CATEGORY_FAIL, payload: message })
  }
}
