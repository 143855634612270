import {
  BATCH_LIST_FAIL,
  BATCH_LIST_REQUEST,
  BATCH_LIST_SUCCESS,
  TAG_GENERATION_FAIL,
  TAG_GENERATION_REQUEST,
  TAG_GENERATION_RESET,
  TAG_GENERATION_SUCCESS,
  TAGS_DOWNLOAD_FAIL,
  TAGS_DOWNLOAD_REQUEST,
  TAGS_DOWNLOAD_SUCCESS,
  TAGS_REQUEST_LIST_REQUEST,
  TAGS_REQUEST_LIST_SUCCESS,
  TAGS_REQUEST_LIST_FAIL,
  TAG_ORDER_DETAIL_REQUEST,
  TAG_ORDER_DETAIL_SUCCESS,
  TAG_ORDER_DETAIL_FAIL,
  BATCH_NOT_ASSIGNED_LIST_REQUEST,
  BATCH_NOT_ASSIGNED_LIST_SUCCESS,
  BATCH_NOT_ASSIGNED_LIST_FAIL,
  ASSIGN_BATCH_REQUEST,
  ASSIGN_BATCH_SUCCESS,
  ASSIGN_BATCH_FAIL,
  ASSIGN_BATCH_RESET,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_RESET,
  ORDER_DELETE_FAIL,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_RESET,
  ORDER_DELETE_SUCCESS,
  TAG_STATUS_VALIDATION_FAIL,
  TAG_STATUS_VALIDATION_REQUEST,
  TAG_STATUS_VALIDATION_RESET,
  TAG_STATUS_VALIDATION_SUCCESS,
  SEND_INVOICE_REQUEST,
  SEND_INVOICE_SUCCESS,
  SEND_INVOICE_FAIL,
  SEND_INVOICE_RESET,
  TAGS_PDF_DOWNLOAD_REQUEST,
  TAGS_PDF_DOWNLOAD_SUCCESS,
  TAGS_PDF_DOWNLOAD_FAIL,
  BATCH_NOT_ASSIGNED_LIST_RESET,
  TAGS_PDF_DOWNLOAD_RESET,
} from '../../../constants/adminConstants/TagsConstants'

export const tagGenerationReducer = (state = {}, action) => {
  switch (action.type) {
    case TAG_GENERATION_REQUEST:
      return { loading: true }
    case TAG_GENERATION_SUCCESS:
      return { loading: false, newTag: action.payload, success: true }
    case TAG_GENERATION_FAIL:
      return { loading: false, error: action.payload }
    case TAG_GENERATION_RESET:
      return {}
    default:
      return state
  }
}

export const batchListReducer = (state = { lists: [] }, action) => {
  switch (action.type) {
    case BATCH_LIST_REQUEST:
      return { loading: true }
    case BATCH_LIST_SUCCESS:
      return { loading: false, lists: action.payload }
    case BATCH_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const batchNotAssignedListReducer = (state = { lists: [] }, action) => {
  switch (action.type) {
    case BATCH_NOT_ASSIGNED_LIST_REQUEST:
      return { loading: true }
    case BATCH_NOT_ASSIGNED_LIST_SUCCESS:
      return { loading: false, lists: action.payload }
    case BATCH_NOT_ASSIGNED_LIST_FAIL:
      return { loading: false, error: action.payload }
    case BATCH_NOT_ASSIGNED_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const assignBatchReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSIGN_BATCH_REQUEST:
      return { loading: true }
    case ASSIGN_BATCH_SUCCESS:
      return { loading: false, result: action.payload }
    case ASSIGN_BATCH_FAIL:
      return { loading: false, error: action.payload }
    case ASSIGN_BATCH_RESET:
      return {}
    default:
      return state
  }
}

export const tagsDownloadReducer = (state = {}, action) => {
  switch (action.type) {
    case TAGS_DOWNLOAD_REQUEST:
      return { loading: true }
    case TAGS_DOWNLOAD_SUCCESS:
      return { loading: false, tags: action.payload }
    case TAGS_DOWNLOAD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const tagsPDFDownloadReducer = (state = { codes: [] }, action) => {
  switch (action.type) {
    case TAGS_PDF_DOWNLOAD_REQUEST:
      return { loading: true }
    case TAGS_PDF_DOWNLOAD_SUCCESS:
      return { loading: false, codes: action.payload }
    case TAGS_PDF_DOWNLOAD_FAIL:
      return { loading: false, error: action.payload }
    case TAGS_PDF_DOWNLOAD_RESET:
      return {}
    default:
      return state
  }
}

export const tagsRequestsReducer = (state = { requests: [] }, action) => {
  switch (action.type) {
    case TAGS_REQUEST_LIST_REQUEST:
      return { loading: true }
    case TAGS_REQUEST_LIST_SUCCESS:
      return { loading: false, requests: action.payload }
    case TAGS_REQUEST_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const tagsRequestDeatilReducer = (
  state = { loading: true, detail: {} },
  action,
) => {
  switch (action.type) {
    case TAG_ORDER_DETAIL_REQUEST:
      return { loading: true }
    case TAG_ORDER_DETAIL_SUCCESS:
      return { loading: false, detail: action.payload }
    case TAG_ORDER_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const tagsUpdateOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ORDER_REQUEST:
      return { loading: true }
    case UPDATE_ORDER_SUCCESS:
      return { loading: false, success: true }
    case UPDATE_ORDER_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_ORDER_RESET:
      return {}
    default:
      return state
  }
}

export const tagOrderDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELETE_REQUEST:
      return { loading: true }
    case ORDER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case ORDER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case ORDER_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const tagStatusValidateReducer = (state = {}, action) => {
  switch (action.type) {
    case TAG_STATUS_VALIDATION_REQUEST:
      return { loading: true }
    case TAG_STATUS_VALIDATION_SUCCESS:
      return { loading: false, result: action.payload }
    case TAG_STATUS_VALIDATION_FAIL:
      return { loading: false, error: action.payload }
    case TAG_STATUS_VALIDATION_RESET:
      return {}
    default:
      return state
  }
}

export const sendInvoiceReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_INVOICE_REQUEST:
      return { loading: true }
    case SEND_INVOICE_SUCCESS:
      return { loading: false, sent: action.payload }
    case SEND_INVOICE_FAIL:
      return { loading: false, error: action.payload }
    case SEND_INVOICE_RESET:
      return {}
    default:
      return state
  }
}
