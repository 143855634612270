import React, { useState, useEffect } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MDBDataTableV5 } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { allFarmCycles } from '../../../actions/adminActions/farmcycleActions/allFarmCycles'
import { groupPackage } from '../../../components/functions'
import { LoadingBox, MessageBox } from '../../../components'

function AllFarmcyclePage() {
  // const [alert, setAlert] = useState(null)
  const [datatable, setDatatable] = useState({})
  const [cycleData, setCycleData] = useState([])

  const cycleList = useSelector((state) => state.cycleList)
  const { loading, error, cycles } = cycleList

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(allFarmCycles())
  }, [dispatch])

  useEffect(() => {
    if (cycles) {
      setCycleData(groupPackage(cycles))
    }
  }, [cycles])

  // useEffect(() => {
  //   if (cycles) {
  //     setDatatable({
  //       columns: [
  //         {
  //           label: 'Farm Cycle Name',
  //           field: 'name',
  //           width: 200,
  //         },
  //         {
  //           label: 'Package',
  //           field: 'package',
  //           width: 100,
  //         },
  //         {
  //           label: 'User',
  //           field: 'user',
  //           width: 200,
  //         },
  //         {
  //           label: 'Start Date',
  //           field: 'startDate',
  //           width: 100,
  //         },
  //         {
  //           label: 'Point',
  //           field: 'point',
  //           width: 100,
  //         },
  //         {
  //           label: 'Progress',
  //           field: 'chart',
  //           width: 100,
  //         },
  //         {
  //           label: 'Date Created',
  //           field: 'date',
  //           width: 100,
  //         },
  //         {
  //           label: 'Status',
  //           field: 'status',
  //           width: 50,
  //         },
  //         {
  //           label: 'Action',
  //           field: 'action',
  //           sort: 'disabled',
  //         },
  //       ],
  //       rows: cycles.map((item) => ({
  //         name: item.name,
  //         user:
  //           item.user && item.user.firstName ? (
  //             <div className="text-cap">
  //               {item.user.firstName} {item.user.lastName}
  //             </div>
  //           ) : (
  //             ''
  //           ),
  //         package:
  //           item.packageId && item.packageId.packageName
  //             ? item.packageId.packageName
  //             : '',
  //         startDate: moment(item.startDate).format('DD MMM YYYY'),
  //         status: checkStatus(item.startDate, item.endDate),
  //         point: item.point,
  //         date: moment(item.createdAt).format('Do MMM YYYY, h:mm a'),
  //         status: checkStatus(item.startDate, item.endDate),
  //         chart: (
  //           <LinearProgress
  //             variant="determinate"
  //             className="mt-2 p-1"
  //             color="primary"
  //             value={
  //               item.point && item.productCategory.expectedPoint
  //                 ? (item.point / item.productCategory.expectedPoint) * 100
  //                 : 0
  //             }
  //           />
  //         ),
  //         action: (
  //           <a
  //             href={`/farm-cycle/detail/${item._id}`}
  //             style={{ marginRight: '15px' }}
  //             className="btn btn-sm btn-success"
  //             data-bs-toggle="tooltip"
  //             data-placement="top"
  //             title="Manage"
  //           >
  //             Manage
  //           </a>
  //         ),
  //       })),
  //     })
  //   }
  // }, [cycles])

  useEffect(() => {
    if (cycleData) {
      setDatatable({
        columns: [
          {
            label: 'Farmsured Packages',
            field: 'package',
            width: 100,
          },
          {
            label: 'Total Number of Subscribers',
            field: 'numOfSub',
            width: 200,
          },
          {
            label: 'Pending',
            field: 'pending',
            width: 100,
          },
          {
            label: 'Ongoing',
            field: 'point',
            width: 100,
          },
          {
            label: 'Closed',
            field: 'closed',
            width: 100,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: cycleData.map((item) => ({
          package: item.name,
          numOfSub: item.numSubscribers,
          action: (
            <a
              href={`/farm-cycle/detail/${item._id}`}
              style={{ marginRight: '15px' }}
              className="btn btn-sm btn-success"
              data-bs-toggle="tooltip"
              data-placement="top"
              title="Manage"
            >
              Manage
            </a>
          ),
        })),
      })
    }
  }, [cycles, cycleData])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">All Farm Cycle</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farm Cycle</a>
                      </li>
                      <li className="breadcrumb-item active">All Farm Cycle</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {error ? <MessageBox variant="danger">{error}</MessageBox> : null}
            {loading ? (
              <LoadingBox />
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive-xl">
                        <MDBDataTableV5
                          hover
                          entriesOptions={[5, 10, 20, 25, 50]}
                          entries={10}
                          pagesAmount={4}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                          barReverse
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AllFarmcyclePage
