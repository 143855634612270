import {
  ADD_BENEFIT_CATEGORY_FAIL,
  ADD_BENEFIT_CATEGORY_REQUEST,
  ADD_BENEFIT_CATEGORY_RESET,
  ADD_BENEFIT_CATEGORY_SUCCESS,
  BENEFITS_CREATE_FAIL,
  BENEFITS_CREATE_REQUEST,
  BENEFITS_CREATE_RESET,
  BENEFITS_CREATE_SUCCESS,
  BENEFIT_DELETE_FAIL,
  BENEFIT_DELETE_REQUEST,
  BENEFIT_DELETE_RESET,
  BENEFIT_DELETE_SUCCESS,
  BENEFITS_LIST_FAIL,
  BENEFITS_LIST_REQUEST,
  BENEFITS_LIST_SUCCESS,
  BENEFIT_CATEGORY_DELETE_FAIL,
  BENEFIT_CATEGORY_DELETE_REQUEST,
  BENEFIT_CATEGORY_DELETE_RESET,
  BENEFIT_CATEGORY_DELETE_SUCCESS,
  BENEFIT_CATEGORY_LIST_FAIL,
  BENEFIT_CATEGORY_LIST_REQUEST,
  BENEFIT_CATEGORY_LIST_SUCCESS,
  UPDATE_BENEFIT_CATEGORY_REQUEST,
  UPDATE_BENEFIT_CATEGORY_SUCCESS,
  UPDATE_BENEFIT_CATEGORY_FAIL,
  UPDATE_BENEFIT_CATEGORY_RESET,
  UPDATE_BENEFIT_REQUEST,
  UPDATE_BENEFIT_SUCCESS,
  UPDATE_BENEFIT_FAIL,
  UPDATE_BENEFIT_RESET,
  CLAIM_LIST_REQUEST,
  CLAIM_LIST_SUCCESS,
  CLAIM_LIST_FAIL,
  CLAIM_DETAIL_REQUEST,
  CLAIM_DETAIL_SUCCESS,
  CLAIM_DETAIL_FAIL,
  UPDATE_CLAIM_REQUEST,
  UPDATE_CLAIM_SUCCESS,
  UPDATE_CLAIM_FAIL,
  UPDATE_CLAIM_RESET,
  CLAIM_DELETE_REQUEST,
  CLAIM_DELETE_SUCCESS,
  CLAIM_DELETE_FAIL,
  CLAIM_DELETE_RESET,
} from '../../../constants/adminConstants/benefitContants'

export const allBenefitsReducer = (state = { allbenefits: [] }, action) => {
  switch (action.type) {
    case BENEFITS_LIST_REQUEST:
      return { loading: true }
    case BENEFITS_LIST_SUCCESS:
      return { loading: false, allbenefits: action.payload }
    case BENEFITS_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const benefitsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BENEFITS_CREATE_REQUEST:
      return { loading: true }
    case BENEFITS_CREATE_SUCCESS:
      return { loading: false, success: true, newBenefit: action.payload }
    case BENEFITS_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case BENEFITS_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const benefitDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BENEFIT_DELETE_REQUEST:
      return { loading: true }
    case BENEFIT_DELETE_SUCCESS:
      return { loading: false, benefitDeleted: action.payload }
    case BENEFIT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case BENEFIT_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const updateBenefitReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BENEFIT_REQUEST:
      return { loading: true }
    case UPDATE_BENEFIT_SUCCESS:
      return { loading: false, success: true }
    case UPDATE_BENEFIT_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_BENEFIT_RESET:
      return {}
    default:
      return state
  }
}

export const benefitCategoryListReducer = (
  state = { loading: true, categorylist: [] },
  action,
) => {
  switch (action.type) {
    case BENEFIT_CATEGORY_LIST_REQUEST:
      return { loading: true }
    case BENEFIT_CATEGORY_LIST_SUCCESS:
      return { loading: false, categorylist: action.payload }
    case BENEFIT_CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addBenefitCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_BENEFIT_CATEGORY_REQUEST:
      return { loading: true }
    case ADD_BENEFIT_CATEGORY_SUCCESS:
      return { loading: false, category: action.payload }
    case ADD_BENEFIT_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    case ADD_BENEFIT_CATEGORY_RESET:
      return {}
    default:
      return state
  }
}

export const benefitCategoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BENEFIT_CATEGORY_DELETE_REQUEST:
      return { loading: true }
    case BENEFIT_CATEGORY_DELETE_SUCCESS:
      return { loading: false, success: true }
    case BENEFIT_CATEGORY_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case BENEFIT_CATEGORY_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const updateBenefitCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BENEFIT_CATEGORY_REQUEST:
      return { loading: true }
    case UPDATE_BENEFIT_CATEGORY_SUCCESS:
      return { loading: false, success: true }
    case UPDATE_BENEFIT_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_BENEFIT_CATEGORY_RESET:
      return {}
    default:
      return state
  }
}

export const allClaimReducer = (state = { claims: [] }, action) => {
  switch (action.type) {
    case CLAIM_LIST_REQUEST:
      return { loading: true }
    case CLAIM_LIST_SUCCESS:
      return { loading: false, claims: action.payload }
    case CLAIM_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const claimDetailReducer = (state = { claim: {} }, action) => {
  switch (action.type) {
    case CLAIM_DETAIL_REQUEST:
      return { loading: true }
    case CLAIM_DETAIL_SUCCESS:
      return { loading: false, claim: action.payload }
    case CLAIM_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateClaimReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CLAIM_REQUEST:
      return { loading: true }
    case UPDATE_CLAIM_SUCCESS:
      return { loading: false, success: true }
    case UPDATE_CLAIM_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_CLAIM_RESET:
      return {}
    default:
      return state
  }
}

export const deleteClaimReducer = (state = {}, action) => {
  switch (action.type) {
    case CLAIM_DELETE_REQUEST:
      return { loading: true }
    case CLAIM_DELETE_SUCCESS:
      return { loading: false, deleted: action.payload }
    case CLAIM_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case CLAIM_DELETE_RESET:
      return {}
    default:
      return state
  }
}
