import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { sendPushNotification } from '../../../actions/adminActions/notificationActions/sendPushNotification'
import { allPushNotifications } from '../../../actions/adminActions/notificationActions/allPushNotifications'
import { LoadingBox, MessageBox } from '../../../components'
import { SEND_PUSH_NOTIFICATION_RESET } from '../../../constants/adminConstants/notificationConstants'
import { MDBDataTableV5 } from 'mdbreact'
import moment from 'moment'

function PushNotificationPage() {
    const [messageTitle, setMessageTitle] = useState('')
    const [messageBody, setMessageBody] = useState('')
    const [messageData, setMessageData] = useState('')
    const [show, setShow] = useState(false)
    const [datatable, setDatatable] = useState({})

    const dispatch = useDispatch()

    const sentPushNotification = useSelector((state) => state.sentPushNotification)
    const { loading, error, sent } = sentPushNotification


    const allNotifications = useSelector((state) => state.allNotifications)
    const { loading: loadingNotifications, error: notificationError, notifications } = allNotifications


    const submitHandler = (e) => {
        e.preventDefault()

        dispatch(sendPushNotification(messageTitle, messageBody, messageData))
    }

    const sendNotification = (item) => {
        let { title: messageTitle, body: messageBody, data: messageData, _id: itemId } = item

        dispatch(sendPushNotification(messageTitle, messageBody, messageData, itemId))
    }

    useEffect(() => {
        dispatch(allPushNotifications())
        if (sent) {
            setShow(false)
            setTimeout(() => {
                dispatch({ type: SEND_PUSH_NOTIFICATION_RESET })
            }, 5000)
        }
        if (error) {
            setShow(false)
            setTimeout(() => {
                dispatch({ type: SEND_PUSH_NOTIFICATION_RESET })
            }, 5000)
        }
    }, [sent, error])

    useEffect(() => {
        if (notifications) {
            setDatatable({
                columns: [
                    {
                        label: 'Title',
                        field: 'title',
                    },
                    {
                        label: 'Body',
                        field: 'body',
                    },
                    {
                        label: 'Data',
                        field: 'data',
                    },
                    {
                        label: 'Date Created',
                        field: 'date',
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        sort: 'disabled',
                        width: 600,
                    },
                ],
                rows: notifications.map((item) => ({
                    title: (
                        <div style={{ width: '100%' }}>
                            {item.title}
                        </div>
                    ),
                    body: (
                        <div style={{ width: '600px' }}>
                            {item.body}
                        </div>
                    ),
                    data: item.data,
                    date: moment(item.createdAt).format('Do MMM YY,  h:mm a'),
                    action: (
                        <div>
                            <button
                                onClick={() => sendNotification(item)}
                                style={{ marginRight: '15px' }}
                                className="btn btn-sm btn-success"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="Manage"
                            >
                                Send Notification
                            </button>
                        </div>
                    ),
                })),
            })
        }
    }, [notifications])

    return (
        <div id="layout-wrapper">
            <Header />
            <LeftSidebar />
            <RightSidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Push Notification</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Messages</a>
                                            </li>
                                            <li className="breadcrumb-item active">Push Notification</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            show &&
                            <>
                                {loading ? (
                                    <LoadingBox />
                                ) : (
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div>

                                                        <h4 className="card-title">Push Notification Tool</h4>
                                                        <p className="card-title-desc">
                                                            Sending push notifications to Farmsured farmer mobile application.
                                                        </p>

                                                        <form onSubmit={submitHandler}>
                                                            <div className="row mt-3">
                                                                <div className="col-12">
                                                                    <div className="mb-3">
                                                                        <label
                                                                            className="form-label"
                                                                            forHtml="messageTitle"
                                                                        >
                                                                            Message title
                                                                        </label>
                                                                        <input
                                                                            id="messageTitle"
                                                                            name="messageTitle"
                                                                            type="text"
                                                                            className="form-control"
                                                                            required
                                                                            onChange={(e) => setMessageTitle(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="mb-3">
                                                                        <label
                                                                            className="form-label"
                                                                            forHtml="messageBody"
                                                                        >
                                                                            Message Body
                                                                        </label>
                                                                        <input
                                                                            id="messageBody"
                                                                            name="messageBody"
                                                                            type="text"
                                                                            className="form-control"
                                                                            required
                                                                            onChange={(e) => setMessageBody(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" forHtml="data">
                                                                            Data (JSON)
                                                                        </label>
                                                                        <input
                                                                            id="data"
                                                                            name="data"
                                                                            type="text"
                                                                            className="form-control"
                                                                            onChange={(e) => setMessageData(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button
                                                                className="btn btn-success mt-3"
                                                                type="submit"
                                                                onSubmit={submitHandler}
                                                            >
                                                                Send a Notification
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        }
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <button className='btn btn-md btn-success m-2 mb-4' onClick={() => setShow(!show)}>New Push Notification</button>
                                        <div className="table-responsive-xl">
                                            {notificationError && (
                                                <MessageBox variant="danger">{notificationError}</MessageBox>
                                            )}
                                            {error && (
                                                <MessageBox variant="danger">{error}</MessageBox>
                                            )}
                                            {sent && (
                                                <MessageBox variant="success">{sent?.message}</MessageBox>
                                            )}
                                            {
                                                loadingNotifications
                                                    ? <LoadingBox />
                                                    :
                                                    <MDBDataTableV5
                                                        hover
                                                        entriesOptions={[5, 20, 25, 50]}
                                                        entries={10}
                                                        pagesAmount={4}
                                                        data={datatable}
                                                        searchTop
                                                        searchBottom={false}
                                                        barReverse
                                                    />
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default PushNotificationPage