import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MDBDataTableV5 } from 'mdbreact'
import Select from 'react-select'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { useDispatch, useSelector } from 'react-redux'
import { listManufacuturers } from '../../../actions/adminActions/manufactuerActions/listManufacturers'
import { allServiceCategory } from '../../../actions/adminActions/serviceActions/allServiceCategory'
import { assignCategory } from '../../../actions/adminActions/serviceActions/assignCategory'
import { editAssignedCategory } from '../../../actions/adminActions/serviceActions/editAssignedCategory'
import { deleteAssignedServiceCategory } from '../../../actions/adminActions/serviceActions/deleteAssignedServiceCategory'
import LoadingBox from '../../../components/LoadingBox'
import MessageBox from '../../../components/MessageBox'
import {
  ASSIGN_SERVICE_CATEGORY_RESET,
  DELETE_ASSIGNED_SERVICE_CATEGORY_RESET,
  EDIT_ASSIGNED_SERVICE_CATEGORY_RESET,
} from '../../../constants/adminConstants/serviceConstants'
import { allOrganisationCategory } from '../../../actions/adminActions/serviceActions/allOrganisationCategory'
import moment from 'moment'
import SweetAlert from 'react-bootstrap-sweetalert'

function AllServicesPage() {
  const [datatable, setDatatable] = useState({})
  const [alert, setAlert] = useState()
  const [id, setId] = useState('')
  const [organisation, setOrganisation] = useState('')
  const [category, setCategory] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [options, setOptions] = useState([])

  const manufacturersList = useSelector((state) => state.manufacturersList)
  const { loading: loadingMaufacturer, manufacturers } = manufacturersList

  const serviceCategory = useSelector((state) => state.serviceCategory)
  const { categorylist } = serviceCategory

  const assignedCategory = useSelector((state) => state.assignedCategory)
  const {
    loading: loadingAssign,
    error: assignError,
    assigned,
  } = assignedCategory

  const organsationCategories = useSelector(
    (state) => state.organsationCategories,
  )
  const {
    loading: loadingCategories,
    error: categoriesError,
    organsationCat,
  } = organsationCategories

  const updateAssigned = useSelector((state) => state.updateAssigned)
  const { loading: loadingUpdate, error: updateError, updated } = updateAssigned

  const deleteAssigned = useSelector((state) => state.deleteAssigned)
  const { loading: loadingDelete, error: deleteError, success } = deleteAssigned

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listManufacuturers())
    dispatch(allServiceCategory())
    dispatch(allOrganisationCategory())

    if (assigned) {
      setTimeout(() => {
        dispatch({ type: ASSIGN_SERVICE_CATEGORY_RESET })
        setOpenDialog(false)
        setCategory([])
        setOrganisation('')
      }, 2500)
    }

    if (updated) {
      setTimeout(() => {
        dispatch({ type: EDIT_ASSIGNED_SERVICE_CATEGORY_RESET })
        setOpenEditDialog(false)
        setCategory([])
        setOrganisation('')
        setId('')
      }, 2500)
    }

    if (success) {
      setAlert(null)
      dispatch({ type: DELETE_ASSIGNED_SERVICE_CATEGORY_RESET })
    }
  }, [dispatch, assigned, updated, success])

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const handleEditDialogClose = () => {
    setOpenEditDialog(false)
  }

  const handleEditOpen = (item) => {
    setOpenEditDialog(true)
    setId(item._id)
    setOrganisation(item.organisation._id)
    setCategory(item.category)
  }

  const deleteHandler = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deleteAssignedServiceCategory(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  useEffect(() => {
    if (categorylist && categorylist.length > 0) {
      setOptions(categorylist)
    }
  }, [categorylist])

  useEffect(() => {
    if (organsationCat) {
      setDatatable({
        columns: [
          {
            label: 'Organisation',
            field: 'organisation',
          },
          {
            label: 'Category',
            field: 'category',
            sort: 'disabled',
            width: 200,
          },
          {
            label: 'Date Assigned',
            field: 'date',
            width: 200,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
            width: 50,
          },
        ],
        rows: organsationCat.map((item) => ({
          organisation:
            item.organisation && item.organisation.name
              ? item.organisation.name
              : '',
          category:
            item.category && item.category.length < 0 ? (
              <div>There is no category</div>
            ) : (
              item.category.map((item) => (
                <div className={`badge badge-soft-success font-size-12 m-1`}>
                  {item.name}
                </div>
              ))
            ),
          date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
          action: (
            <>
              <button
                onClick={() => handleEditOpen(item)}
                style={{ marginRight: '15px' }}
                className="btn btn-sm m-0 btn-primary"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Manage"
              >
                Manage
              </button>
              <button
                className="btn btn-sm m-0 text-danger"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Delete"
                onClick={() => deleteHandler(item)}
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </button>
            </>
          ),
        })),
      })
    }
  }, [organsationCat])

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(assignCategory(organisation, category))
  }

  const handleEditSubmit = (e) => {
    e.preventDefault()

    dispatch(editAssignedCategory(id, organisation, category))
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">All Service Provider</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">
                          Benefit Service Provider
                        </a>
                      </li>
                      <li className="breadcrumb-item active">
                        Service Provider
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {categoriesError ? (
                      <MessageBox variant="danger">
                        {categoriesError}
                      </MessageBox>
                    ) : null}
                    {deleteError ? (
                      <MessageBox variant="danger">{deleteError}</MessageBox>
                    ) : null}
                    {loadingDelete ? <LoadingBox /> : null}
                    <div className="float-end">
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => setOpenDialog(true)}
                      >
                        Assign Category
                      </button>
                    </div>
                    <div className="table-responsive-xl">
                      {loadingCategories ? (
                        <LoadingBox />
                      ) : (
                        <MDBDataTableV5
                          hover
                          entriesOptions={[5, 20, 25, 50]}
                          entries={10}
                          pagesAmount={4}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                          barReverse
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {loadingAssign ? <LoadingBox /> : null}
            <Dialog
              open={openDialog}
              onClose={handleDialogClose}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth={'md'}
            >
              <div className="modal-header">
                <h5 className="modal-title">Assign Service Category</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDialogClose}
                ></button>
              </div>
              <DialogContent>
                {assignError ? (
                  <MessageBox variant="danger">{assignError}</MessageBox>
                ) : null}
                {assigned ? (
                  <MessageBox variant="success">{assigned.message}</MessageBox>
                ) : null}
                <form onSubmit={handleSubmit}>
                  <div className="col-md-12 mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-12 col-form-label"
                    >
                      Organisation
                    </label>
                    <select
                      className="form-select form-control select2"
                      required
                      placeholder="Select an Organisation"
                      onChange={(e) => setOrganisation(e.target.value)}
                      value={organisation}
                    >
                      <option>Select an Organisation</option>
                      {loadingMaufacturer ? (
                        'Loading...'
                      ) : manufacturers.length > 0 ? (
                        <>
                          {manufacturers.map((item) => (
                            <option value={item._id}>{item.name}</option>
                          ))}
                        </>
                      ) : (
                        'There is no organisation'
                      )}
                    </select>
                  </div>

                  <div className="col-md-12">
                    <label className="col-md-12 col-form-label">
                      Service Category
                    </label>
                    <Select
                      onChange={(opt) => setCategory(opt)}
                      required
                      isMulti
                      placeholder="Select category"
                      options={options.map((opt) => ({
                        label: opt.name,
                        value: opt._id,
                      }))}
                    />
                  </div>
                  <DialogActions className="mt-3">
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={handleDialogClose}
                    >
                      Close
                    </button>
                    <button className="btn btn-success btn-sm" type="submit">
                      Save
                    </button>
                  </DialogActions>
                </form>
              </DialogContent>
            </Dialog>

            {/* Edit Assigned Dialog  */}
            {loadingUpdate ? <LoadingBox /> : null}
            <Dialog
              open={openEditDialog}
              onClose={handleEditDialogClose}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth={'md'}
            >
              <div className="modal-header">
                <h5 className="modal-title">Edit Service Category</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleEditDialogClose}
                ></button>
              </div>
              <DialogContent>
                {updateError ? (
                  <MessageBox variant="danger">{updateError}</MessageBox>
                ) : null}
                {updated ? (
                  <MessageBox variant="success">{updated.message}</MessageBox>
                ) : null}
                <form onSubmit={handleEditSubmit}>
                  <div className="col-md-12 mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-12 col-form-label"
                    >
                      Organisation
                    </label>
                    <select
                      className="form-select form-control select2"
                      required
                      placeholder="Select an Organisation"
                      onChange={(e) => setOrganisation(e.target.value)}
                      value={organisation}
                    >
                      <option>Select an Organisation</option>
                      {loadingMaufacturer ? (
                        'Loading...'
                      ) : manufacturers.length > 0 ? (
                        <>
                          {manufacturers.map((item) => (
                            <option value={item._id}>{item.name}</option>
                          ))}
                        </>
                      ) : (
                        'There is no organisation'
                      )}
                    </select>
                  </div>

                  <div className="col-md-12">
                    <label className="col-md-12 col-form-label">
                      Service Category
                    </label>
                    <Select
                      onChange={(opt) => setCategory(opt)}
                      defaultValue={category.map((opt) => ({
                        label: opt.name,
                        value: opt._id,
                      }))}
                      required
                      isMulti
                      placeholder="Select category"
                      options={options.map((opt) => ({
                        label: opt.name,
                        value: opt._id,
                      }))}
                    />
                  </div>
                  <DialogActions className="mt-3">
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={handleEditDialogClose}
                    >
                      Close
                    </button>
                    <button className="btn btn-success btn-sm" type="submit">
                      Save
                    </button>
                  </DialogActions>
                </form>
              </DialogContent>
            </Dialog>

            {alert}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AllServicesPage
