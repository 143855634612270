import axios from 'axios'
import {
    ADD_SERVICE_REQUEST,
    ADD_SERVICE_SUCCESS,
    ADD_SERVICE_FAIL,
} from '../../../constants/serviceProviderConstants/productConstants'

export const addService = (
    product, markup, state, inputList
) => async (dispatch, getState) => {
    dispatch({
        type: ADD_SERVICE_REQUEST,
        payload: {
            product, markup, state, inputList
        },
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/service/add-service',
            { product, markup, state, inputList },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: ADD_SERVICE_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: ADD_SERVICE_FAIL, payload: message })
    }
}
