import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createOrder } from '../../../actions/manufacturerActions/labelsActions/createOrder'
import { listProducts } from '../../../actions/manufacturerActions/productActions/allProduct'
import { LoadingBox, MessageBox } from '../../../components'
import { formatNumber } from '../../../components/functions'
import { ADD_ORDER_RESET } from '../../../constants/manufacturerConstants/orderConstants'
import { Header, LeftSidebar, RightSidebar, Footer } from '../../../partials'
import moment from 'moment'

function NewTagRequestPage() {
  const [product, setProduct] = useState('')
  const [amount, setAmount] = useState('0')
  const [description, setDescription] = useState('')

  const manufacturerProductList = useSelector(
    (state) => state.manufacturerProductList,
  )
  const { loading: loadingProduct, products } = manufacturerProductList

  const newOrder = useSelector((state) => state.newOrder)
  const { loading, error, order } = newOrder

  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(createOrder(product, amount, description))
  }

  useEffect(() => {
    dispatch(listProducts())

    if (order) {
      setTimeout(() => {
        dispatch({ type: ADD_ORDER_RESET })
      }, 2100)
    }
    return () => {
      //
    }
  }, [dispatch, order])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Farmsured Secured Tag Request</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Labels</a>
                      </li>
                      <li className="breadcrumb-item active">Labels Request</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {order ? (
              <MessageBox variant="success">{order.message}</MessageBox>
            ) : null}
            {error ? <MessageBox variant="danger">{error}</MessageBox> : null}
            {loading ? (
              <LoadingBox />
            ) : (
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label className="form-label" for="productname">
                            Your Product Name
                          </label>
                          <select
                            onChange={(e) => setProduct(e.target.value)}
                            className="select2 form-select"
                            required
                          >
                            <option>Select Product</option>
                            {loadingProduct ? (
                              'Loading...'
                            ) : (
                              <>
                                {products.length === 0 ? (
                                  <p className="card-title-desc">
                                    There is no product data
                                  </p>
                                ) : (
                                  products.map((item) => (
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  ))
                                )}
                              </>
                            )}
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="amount">
                            How many Farmsured Package
                            (Request must cover up to 1 month production)
                          </label>
                          <input
                            className="form-control"
                            id="amount"
                            type="Number"
                            onChange={(e) => setAmount(e.target.value)}
                          ></input>
                          <small className="font-size-12">{`*Note: ${amount} Farmsured Package will cover ${formatNumber(
                            1000 * amount,
                          )} product(s)`}</small>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="productdesc">
                            Do you have any other message
                          </label>
                          <textarea
                            className="form-control"
                            id="anyotherdesc"
                            rows="5"
                            onChange={(e) => setDescription(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="float-end mt-4">
                          <button
                            type="submit"
                            className="btn btn-success me-2 waves-effect waves-light"
                            onSubmit={handleSubmit}
                          >
                            Request
                          </button>
                          <button
                            type="submit"
                            className="btn btn-light waves-effect"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default NewTagRequestPage
