import {
  FARMER_ACTIVITIES_FAIL,
  FARMER_ACTIVITIES_REQUEST,
  FARMER_ACTIVITIES_SUCCESS,
  SCAN_ACTIVITIES_FAIL,
  SCAN_ACTIVITIES_REQUEST,
  SCAN_ACTIVITIES_SUCCESS,
} from '../../../constants/farmerConstants/activitiesConstants'

export const farmerActivitiesReducer = (state = {}, action) => {
  switch (action.type) {
    case FARMER_ACTIVITIES_REQUEST:
      return { loading: true }
    case FARMER_ACTIVITIES_SUCCESS:
      return { loading: false, result: action.payload }
    case FARMER_ACTIVITIES_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const scanActivitiesReducer = (state = {}, action) => {
  switch (action.type) {
    case SCAN_ACTIVITIES_REQUEST:
      return { loading: true }
    case SCAN_ACTIVITIES_SUCCESS:
      return { loading: false, cycleScans: action.payload }
    case SCAN_ACTIVITIES_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
