import {
    ADD_SERVICE_FAIL,
    ADD_SERVICE_REQUEST,
    ADD_SERVICE_RESET,
    ADD_SERVICE_SUCCESS,
    ALL_SERVICE_PRODUCT_LIST_FAIL,
    ALL_SERVICE_PRODUCT_LIST_REQUEST,
    ALL_SERVICE_PRODUCT_LIST_SUCCESS,
    EDIT_SERVICE_PRODUCT_FAIL,
    EDIT_SERVICE_PRODUCT_REQUEST,
    EDIT_SERVICE_PRODUCT_RESET,
    EDIT_SERVICE_PRODUCT_SUCCESS,
} from '../../../constants/serviceProviderConstants/productConstants'

export const createServiceReducer = (
    state = {},
    action,
) => {
    switch (action.type) {
        case ADD_SERVICE_REQUEST:
            return { loading: true }
        case ADD_SERVICE_SUCCESS:
            return { loading: false, created: action.payload }
        case ADD_SERVICE_FAIL:
            return { loading: false, error: action.payload }
        case ADD_SERVICE_RESET:
            return {}
        default:
            return state
    }
}

export const allServiceProductReducer = (
    state = { serviceProduct: [] },
    action,
) => {
    switch (action.type) {
        case ALL_SERVICE_PRODUCT_LIST_REQUEST:
            return { loading: true }
        case ALL_SERVICE_PRODUCT_LIST_SUCCESS:
            return { loading: false, result: action.payload }
        case ALL_SERVICE_PRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const editServiceProductReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_SERVICE_PRODUCT_REQUEST:
            return { loading: true }
        case EDIT_SERVICE_PRODUCT_SUCCESS:
            return { loading: false, updated: action.payload }
        case EDIT_SERVICE_PRODUCT_FAIL:
            return { loading: false, error: action.payload }
        case EDIT_SERVICE_PRODUCT_RESET:
            return {}
        default:
            return state
    }
}
