import axios from 'axios'
import {
  SCAN_TAG_FAIL,
  SCAN_TAG_REQUEST,
  SCAN_TAG_SUCCESS,
} from '../../../constants/farmerConstants/packagesConstants'

export const verifyQRCode = (activityId, itemId, result, geolocation) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: SCAN_TAG_REQUEST,
    payload: { activityId, itemId, result, geolocation },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/farmer/activity/tag',
      { activityId, itemId, result, geolocation },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: SCAN_TAG_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: SCAN_TAG_FAIL,
      payload: message,
    })
  }
}
