import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import { packageDetails } from '../../../actions/adminActions/packagesActions/packageDetails'

function PackageDetailPage(props) {
  const itemId = props.match.params.id

  const viewPackageDetail = useSelector((state) => state.viewPackageDetail)
  const { loading, packageDetail, error } = viewPackageDetail

  const dispatch = useDispatch()

  useEffect(() => {
    if (itemId) {
      dispatch(packageDetails(itemId))
    }
  }, [dispatch, itemId])

  let row = 1
  let col = 1
  let textNum = 1

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <RightSidebar />
        <LeftSidebar />
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                {/* <!-- start page title --> */}
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="mb-sm-0">Protocol</h4>

                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            <a href="javascript: void(0);">Protocol</a>
                          </li>
                          <li className="breadcrumb-item active">
                            Protocol System
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end page title --> */}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-xl-5">
                            <div className="product-detail">
                              <div className="row">
                                <div className="col-md-3 col-4"></div>
                                <div className="col-md-7 col-9 float-center">
                                  <div
                                    className="tab-content"
                                    id="v-pills-tabContent"
                                  >
                                    <div className="product-img center">
                                      <img
                                        src={
                                          (packageDetail &&
                                            packageDetail.packageImg) ||
                                          'https://via.placeholder.com/300'
                                        }
                                        alt=""
                                        style={{
                                          maxWidth: '80%',
                                          margin: 'auto',
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-7">
                            <div className="mt-4 mt-xl-3">
                              <a href="#" className="text-primary">
                                {packageDetail &&
                                  packageDetail.farmType &&
                                  packageDetail.farmType.name}
                              </a>
                              <h5 className="mt-1 mb-3">
                                {packageDetail && packageDetail.packageName}
                              </h5>

                              <h6 className="mt-2">
                                Protocol Minimum Unit:{' '}
                                {packageDetail &&
                                  packageDetail.packageMinUnit &&
                                  packageDetail.packageMinUnit.minUnit}{' '}
                                {packageDetail &&
                                  packageDetail.packageMinUnit &&
                                  packageDetail.packageMinUnit.measuringUnit}
                              </h6>

                              <p className="mt-3">
                                Based on the Farmsured protocol standard
                                activities, the farmers is expected to follow
                                the listed standards <br />
                                to earn points which will determine the farm
                                performance
                              </p>
                              <hr className="my-4" />

                              <div
                                className="row"
                                style={{ margin: '10px 0px 20px 0px' }}
                              >
                                <div className="col-md-4">
                                  <div className="d-flex">
                                    <div className="avatar-sm me-3">
                                      <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                        <i className="fal fa-store"></i>
                                      </span>
                                    </div>
                                    <div className="flex-1 align-self-center overflow-hidden">
                                      <h6 className="light">Organisation</h6>
                                      <p className="text-muted mb-0">
                                        {packageDetail &&
                                          packageDetail.organisation &&
                                          packageDetail.organisation.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="d-flex mt-4 mt-md-0">
                                    <div className="avatar-sm me-3">
                                      <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                        <i className="fal fa-box-check"></i>
                                      </span>
                                    </div>
                                    <div className="flex-1 align-self-center overflow-hidden">
                                      <h6>Product</h6>
                                      <p className="text-muted mb-0">
                                        {packageDetail &&
                                          packageDetail.product &&
                                          packageDetail.product.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="d-flex mt-4 mt-md-0">
                                    <div className="avatar-sm me-3">
                                      <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                        <i className="fal fa-folder-tree"></i>
                                      </span>
                                    </div>
                                    <div className="flex-1 align-self-center overflow-hidden">
                                      <h6>Product Category</h6>
                                      <p className="text-muted mb-0">
                                        {packageDetail &&
                                          packageDetail.product &&
                                          packageDetail.product.category &&
                                          packageDetail.product.category.name &&
                                          packageDetail.product.category.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ margin: '10px 0px 20px 0px' }}
                              >
                                <div className="col-md-4">
                                  <div className="d-flex mt-4 mt-md-0">
                                    <div className="avatar-sm me-3">
                                      <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                        <i className="fal fa-coins"></i>
                                      </span>
                                    </div>
                                    <div className="flex-1 align-self-center overflow-hidden">
                                      <h6>Expected Point</h6>
                                      <p className="text-muted mb-0">
                                        {packageDetail &&
                                          packageDetail.product &&
                                          packageDetail.product.category &&
                                          packageDetail.product.category
                                            .expectedPoint &&
                                          packageDetail.product.category
                                            .expectedPoint}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="d-flex mt-4 mt-md-0">
                                    <div className="avatar-sm me-3">
                                      <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                        <i className="fal fa-clock"></i>
                                      </span>
                                    </div>
                                    <div className="flex-1 align-self-center overflow-hidden">
                                      <h6>Cycle Duration</h6>
                                      <p className="text-muted mb-0">
                                        {packageDetail &&
                                          packageDetail.product &&
                                          packageDetail.product.category &&
                                          packageDetail.product.category
                                            .cycleDuration &&
                                          packageDetail.product.category
                                            .cycleDuration}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <h5 className="font-size-14">
                                    Farmsured Benefits:
                                  </h5>
                                  <ul className="list-unstyled product-desc-list">
                                    <table className="table table-borderless">
                                      <thead>
                                        <tr>
                                          <th scope="col">Benefits</th>
                                          <th scope="col">Timeframe(hrs)</th>
                                          <th scope="col">
                                            Min. Points to Qualify(points)
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {packageDetail &&
                                          packageDetail.packageBenefits.map(
                                            (item) => (
                                              <tr>
                                                <td>
                                                  <i className="far fa-gem text-primary me-1 align-middle font-size-16"></i>
                                                  {item.benefit ? (
                                                    <>{item.benefit.benefit}</>
                                                  ) : null}
                                                </td>
                                                <td>
                                                  {item.benefitFrom} -{' '}
                                                  {item.benefitTo}
                                                </td>
                                                <td>
                                                  {item.minPointToQualify}
                                                </td>
                                              </tr>
                                            ),
                                          )}
                                      </tbody>
                                    </table>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- end row --> */}

                        <div className="mt-4">
                          <h5 className="font-size-14 mb-3">
                            Protocol description:
                          </h5>
                          <div className="row">
                            <div className="col-xl-12">
                              <div className="card">
                                <div className="card-body">
                                  <h4 className="card-title">
                                    Farmsured Standards
                                  </h4>
                                  <p className="card-title-desc">
                                    Extend the default collapse to view farm
                                    package activities.
                                  </p>

                                  <div
                                    id="accordion"
                                    className="custom-accordion"
                                  >
                                    {packageDetail &&
                                    packageDetail.activities.length === 0 ? (
                                      <div className="">Activity is empty.</div>
                                    ) : (
                                      packageDetail &&
                                      packageDetail.activities.map((item) => (
                                        <div
                                          className="card mb-1 shadow-none"
                                          key={item._id}
                                        >
                                          <a
                                            href={`#collapse${row++}`}
                                            className="text-dark collapsed"
                                            data-bs-toggle="collapse"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                          >
                                            <div
                                              className={`card-header ${
                                                item.activity ===
                                                'Scan Activity'
                                                  ? 'card-scan-activity'
                                                  : 'card-record-activity'
                                              }`}
                                              id="headingOne"
                                            >
                                              <span className="m-0">
                                                {item.activity ===
                                                'Scan Activity' ? (
                                                  <i
                                                    className="fa fa-qrcode me-1"
                                                    style={{
                                                      fontSize: '18px',
                                                    }}
                                                  ></i>
                                                ) : (
                                                  <i
                                                    className="fa fa-file me-1"
                                                    style={{
                                                      fontSize: '18px',
                                                    }}
                                                  ></i>
                                                )}{' '}
                                                Compliance Activity #{textNum++}
                                                <div className="float-end">
                                                  Hours: {item.timeLineFrom} -{' '}
                                                  {item.timeLineTo}
                                                  <i
                                                    className="mdi mdi-minus accor-plus-icon"
                                                    style={{
                                                      marginLeft: '20px',
                                                    }}
                                                  ></i>
                                                </div>
                                              </span>
                                            </div>
                                          </a>

                                          <div
                                            id={`collapse${col++}`}
                                            className="collapse"
                                            aria-labelledby="headingOne"
                                            data-bs-parent="#accordion"
                                          >
                                            <div className="card-body">
                                              <div className="table-responsive">
                                                <table className="table  mb-0">
                                                  <tbody>
                                                    <tr>
                                                      <th
                                                        scope="row"
                                                        style={{
                                                          width: '400px',
                                                        }}
                                                      >
                                                        Type of Activity
                                                      </th>
                                                      <td>{item.activity}</td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">
                                                        Activity
                                                      </th>
                                                      <td>
                                                        {item.farmActivity}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">
                                                        Expected Task
                                                      </th>
                                                      <td>
                                                        <p
                                                          style={{
                                                            maxWidth: '100%',
                                                          }}
                                                        >
                                                          {item.expectedTask}
                                                        </p>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">
                                                        Timeline
                                                      </th>
                                                      <td>
                                                        {item.timeLineFrom} -{' '}
                                                        {item.timeLineTo} Hrs
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">Point</th>
                                                      <td>
                                                        {item.point ||
                                                          'Points has been embedd on the Farmsured Tag label'}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- end card --> */}
                  </div>
                </div>
                {/* <!-- end row --> */}
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default PackageDetailPage
