import axios from 'axios'
import {
  SAVE_FARM_COMPLIANCE_FAIL,
  SAVE_FARM_COMPLIANCE_REQUEST,
  SAVE_FARM_COMPLIANCE_SUCCESS,
} from '../../../constants/farmerConstants/packagesConstants'

export const saveCompliance = (activityId, itemId, comment, point) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: SAVE_FARM_COMPLIANCE_REQUEST,
    payload: { activityId, itemId, comment, point },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/farmer/activity-compliance',
      { activityId, itemId, comment, point },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: SAVE_FARM_COMPLIANCE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: SAVE_FARM_COMPLIANCE_FAIL,
      payload: message,
    })
  }
}
