import axios from 'axios'
import {
  SEND_FUND_REQUEST,
  SEND_FUND_SUCCESS,
  SEND_FUND_FAIL,
} from '../../../constants/authConstants/walletConstants'

export const sendFund = (amount, recipient, remark, walletPIN) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: SEND_FUND_REQUEST,
    payload: { amount, recipient, remark, walletPIN },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/wallet/sendfund',
      { amount, recipient, remark, walletPIN },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: SEND_FUND_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: SEND_FUND_FAIL, payload: message })
  }
}
