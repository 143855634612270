import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MDBDataTableV5 } from 'mdbreact'
import { allBenefitClaims } from '../../../actions/adminActions/serviceActions/allBenefitCalims'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { deleteServiceClaim } from '../../../actions/adminActions/serviceActions/deleteServiceClaim'
import SweetAlert from 'react-bootstrap-sweetalert'

export default function BenefitClaimPage() {
  const [alert, setAlert] = useState(null)
  const [datatable, setDatatable] = useState({})
  const [detail, setDetail] = useState({})
  const [open, setOpen] = useState(false)

  // All Benefit State
  const benefitClaims = useSelector((state) => state.benefitClaims)
  const { loading, allclaims, error } = benefitClaims

  const claimServiceDelete = useSelector((state) => state.claimServiceDelete)
  const {
    loading: deleteLoading,
    success,
    error: deleteError,
  } = claimServiceDelete

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(allBenefitClaims())

    if (success) {
      setAlert(null)
    }
  }, [dispatch, success])

  const deleteHandler = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deleteServiceClaim(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  const handleEditOpen = (item) => {
    setOpen(true)
    setDetail(item)
  }

  useEffect(() => {
    if (allclaims) {
      setDatatable({
        columns: [
          {
            label: 'Claim ID',
            field: 'claimID',
            width: 50,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'uniqueCode',
            },
          },
          {
            label: 'Unique Code',
            field: 'uniqueCode',
            width: 50,
          },
          {
            label: 'Service Provider',
            field: 'name',
            width: 300,
          },
          {
            label: 'Category',
            field: 'category',
            width: 200,
          },
          {
            label: 'Beneficiary',
            field: 'farmer',
            width: 300,
          },
          {
            label: 'Report Date',
            field: 'date',
            width: 100,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
            width: 50,
          },
        ],
        rows: allclaims.map((item) => ({
          claimID: item.claimID,
          uniqueCode: item.bspCode,
          phone: item.phone ? `0${item.phone}` : '',
          name: item.bsp && item.bsp.name ? item.bsp.name : null,
          category: item.category,
          farmer: item.farmer
            ? `${item.farmer.firstName} ${item.farmer.lastName}`
            : '',
          date: moment(item.created_at).format('Do MMMM YYYY,  h:mm a'),
          action: (
            <>
              <button
                onClick={() => handleEditOpen(item)}
                style={{ marginRight: '15px' }}
                className="btn btn-sm btn-primary"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Manage"
              >
                Detail
              </button>
              <button
                className="btn btn-sm p-0 m-0 text-danger"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Delete"
                onClick={() => deleteHandler(item)}
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </button>
            </>
          ),
        })),
      })
    }
  }, [allclaims])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0"> All Benefit Claims</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Benefit Claims</a>
                      </li>
                      <li className="breadcrumb-item active">All claims</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {deleteLoading ? <LoadingBox /> : null}
            {deleteError ? (
              <MessageBox variant="danger">{deleteError.message}</MessageBox>
            ) : null}
            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error.message}</MessageBox>
            ) : (
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive-xl">
                        <MDBDataTableV5
                          hover
                          entriesOptions={[5, 20, 25, 50]}
                          entries={10}
                          pagesAmount={4}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                          barReverse
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth={'lg'}
            >
              <>
                <div className="modal-header">
                  <h5 className="modal-title">
                    Service Claim Detail:{' '}
                    {detail && detail.claimID ? detail.claimID : null}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setOpen(false)}
                  ></button>
                </div>
                <DialogContent>
                  <div className="row mt-3">
                    <div className="col-md-3 center mt-0">
                      <img
                        src={
                          detail && detail.farmer && detail.farmer.image
                            ? detail.farmer.image
                            : 'assets/images/default-user.png'
                        }
                        alt="user-image"
                        className="user-image-status "
                        style={{ maxWidth: '100%', margin: 'auto' }}
                      />

                      <h5 className="mb-3 center">
                        {detail && detail.farmer
                          ? `0${detail.farmer.phone}`
                          : null}
                      </h5>

                      <div className="mb-3">
                        <h6>
                          <b>CLAIM STATUS</b>
                        </h6>
                        <div
                          className={`badge badge-soft-${detail.status} font-size-13`}
                        >
                          {detail.status}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="pb-1">
                            <h6 className="">
                              <b>BENEFICIARY DETAILS</b>
                            </h6>
                            <h6>Famer Name</h6>
                            <p>
                              {detail &&
                              detail.farmer &&
                              detail.farmer.firstName
                                ? detail.farmer.firstName
                                : ' '}{' '}
                              {detail && detail.farmer && detail.farmer.lastName
                                ? detail.farmer.lastName
                                : ' '}
                            </p>
                          </div>
                          <div className="pb-1">
                            <h6>Famer ID</h6>
                            <p>
                              {detail && detail.farmer
                                ? `0${detail.farmer.phone}`
                                : null}
                            </p>
                          </div>
                          <div className="pb-1">
                            <h6>Email</h6>
                            <p>
                              {detail && detail.farmer && detail.farmer.email
                                ? detail.farmer.email
                                : 'Not Filled'}
                            </p>
                          </div>
                          <div className="pb-1">
                            <div className="row">
                              <div className="col-lg-12">
                                <h6>Address</h6>
                                <p>
                                  {detail &&
                                  detail.farmer &&
                                  detail.farmer.address
                                    ? detail.farmer.address
                                    : 'Not Filled'}
                                </p>
                              </div>
                              <div className="col-lg-6">
                                <h6>City</h6>
                                <p>
                                  {detail && detail.farmer && detail.farmer.lga
                                    ? detail.farmer.lga
                                    : 'Not Filled'}
                                </p>
                              </div>
                              <div className="col-lg-6">
                                <h6>State</h6>
                                <p>
                                  {detail &&
                                  detail.farmer &&
                                  detail.farmer.state
                                    ? detail.farmer.state
                                    : 'Not Filled'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <h6 className="">
                            <b>SERVICE PROVIDER DETAILS</b>
                          </h6>

                          <div className="pb-1">
                            <h6>Name</h6>
                            <p>
                              {detail && detail.bsp && detail.bsp.name
                                ? detail.bsp.name
                                : null}
                            </p>
                          </div>

                          <div className="pb-1">
                            <h6>Email</h6>
                            <p>
                              {detail && detail.bsp && detail.bsp.email
                                ? detail.bsp.email
                                : null}
                            </p>
                          </div>

                          <div className="pb-1">
                            <h6>Phone</h6>
                            <p>
                              {detail && detail.bsp && detail.bsp.phone
                                ? `0${detail.bsp.phone}`
                                : null}
                            </p>
                          </div>

                          <div className="pb-1">
                            <h6>Farmtrove BSP Code</h6>
                            <p>
                              {detail && detail.category
                                ? detail.bspCode
                                : null}
                            </p>
                          </div>
                          <div className="pb-1">
                            <h6>Benefit Service Rendered</h6>
                            <p>
                              {detail && detail.category
                                ? detail.category
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <h6 className="">
                          <b>CLAIM DETAILS</b>
                        </h6>

                        <div className="">
                          <h6>
                            <b>Claim ID</b>
                          </h6>
                          <p>
                            {detail && detail.claimID ? detail.claimID : null}
                          </p>
                        </div>

                        <div className="">
                          <h6>
                            <b>Date of Claim</b>
                          </h6>
                          <p>
                            {moment(detail.created_at).format(
                              'Do MMMM YYYY,  h:mm a',
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="">
                        <h6>
                          <b>Service Rendered to Beneficiary</b>
                        </h6>
                        <textarea
                          className="form-control"
                          rows="5"
                          defaultValue={detail.detail}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <button
                    className="btn btn-info btn-sm"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                </DialogActions>
              </>
            </Dialog>
          </div>
        </div>
        {alert}
      </div>
      <Footer />
    </div>
  )
}
