import axios from 'axios'
import {
    PAY_LOAN_FAIL,
    PAY_LOAN_REQUEST,
    PAY_LOAN_SUCCESS,
} from '../../../constants/farmerConstants/farmtrove/loanConstants'

export const payLoan = (amount, loanRepaymentID, loanApplicationID) => async (dispatch, getState) => {
    dispatch({
        type: PAY_LOAN_REQUEST,
        payload: { amount, loanRepaymentID, loanApplicationID },
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/farmer/farmtrove/loan-payment',
            { amount, loanRepaymentID, loanApplicationID },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: PAY_LOAN_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: PAY_LOAN_FAIL, payload: message })
    }
}
