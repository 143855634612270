import axios from 'axios'
import {
  RESET_TRASNACTION_PIN_FAIL,
  RESET_TRASNACTION_PIN_REQUEST,
  RESET_TRASNACTION_PIN_SUCCESS,
} from '../../constants/authConstants/resetPasswordContants'

const resetPassword = (oldPin, newPin) => async (dispatch, getState) => {
  dispatch({
    type: RESET_TRASNACTION_PIN_REQUEST,
    payload: { oldPin, newPin },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/reset-transaction-pin',
      {
        oldPin,
        newPin,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: RESET_TRASNACTION_PIN_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: RESET_TRASNACTION_PIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export default resetPassword
