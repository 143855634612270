export const LOANS_APPLICATION_REQUEST = 'LOANS_APPLICATION_REQUEST'
export const LOANS_APPLICATION_SUCCESS = 'LOANS_APPLICATION_SUCCESS'
export const LOANS_APPLICATION_FAIL = 'LOANS_APPLICATION_FAIL'

export const LOAN_CREATE_REQUEST = 'LOAN_CREATE_REQUEST'
export const LOAN_CREATE_SUCCESS = 'LOAN_CREATE_SUCCESS'
export const LOAN_CREATE_FAIL = 'LOAN_CREATE_FAIL'
export const LOAN_CREATE_RESET = 'LOAN_CREATE_RESET'

export const UPDATE_LOAN_REQUEST = 'UPDATE_LOAN_REQUEST'
export const UPDATE_LOAN_SUCCESS = 'UPDATE_LOAN_SUCCESS'
export const UPDATE_LOAN_FAIL = 'UPDATE_LOAN_FAIL'
export const UPDATE_LOAN_RESET = 'UPDATE_LOAN_RESET'

export const ALL_LOANS_REQUEST = 'ALL_LOANS_REQUEST'
export const ALL_LOANS_SUCCESS = 'ALL_LOANS_SUCCESS'
export const ALL_LOANS_FAIL = 'ALL_LOANS_FAIL'

export const LOAN_APPLICATION_DETAIL_REQUEST = 'LOAN_APPLICATION_DETAIL_REQUEST'
export const LOAN_APPLICATION_DETAIL_SUCCESS = 'LOAN_APPLICATION_DETAIL_SUCCESS'
export const LOAN_APPLICATION_DETAIL_FAIL = 'LOAN_APPLICATION_DETAIL_FAIL'

export const UPDATE_LOAN_APPLICATION_REQUEST = 'UPDATE_LOAN_APPLICATION_REQUEST'
export const UPDATE_LOAN_APPLICATION_SUCCESS = 'UPDATE_LOAN_APPLICATION_SUCCESS'
export const UPDATE_LOAN_APPLICATION_FAIL = 'UPDATE_LOAN_APPLICATION_FAIL'
export const UPDATE_LOAN_APPLICATION_RESET = 'UPDATE_LOAN_APPLICATION_RESET'

export const LOAN_RISK_CHECK_REQUEST = 'LOAN_RISK_CHECK_REQUEST'
export const LOAN_RISK_CHECK_SUCCESS = 'LOAN_RISK_CHECK_SUCCESS'
export const LOAN_RISK_CHECK_FAIL = 'LOAN_RISK_CHECK_FAIL'
