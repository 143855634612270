import axios from 'axios'
import {
  UPDATE_PROTOCOL_ACTIVITY_FAIL,
  UPDATE_PROTOCOL_ACTIVITY_REQUEST,
  UPDATE_PROTOCOL_ACTIVITY_SUCCESS,
} from '../../../constants/adminConstants/packageConstants'

export const editProtocolActivity = (
  itemId,
  activityId,
  activity,
  tagColor,
  farmActivity,
  expectedTask,
  timelineFrom,
  timelineTo,
  point,
) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_PROTOCOL_ACTIVITY_REQUEST,
    payload: {
      itemId,
      activityId,
      activity,
      tagColor,
      farmActivity,
      expectedTask,
      timelineFrom,
      timelineTo,
      point,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/protocol-activity/update',
      {
        itemId,
        activityId,
        activity,
        tagColor,
        farmActivity,
        expectedTask,
        timelineFrom,
        timelineTo,
        point,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: UPDATE_PROTOCOL_ACTIVITY_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: UPDATE_PROTOCOL_ACTIVITY_FAIL, payload: message })
  }
}
