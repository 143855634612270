import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAccountReport } from '../../../actions/adminActions/farmtroveActions/getUserAccountReport'
import { getUserTransactionsReport } from '../../../actions/adminActions/farmtroveActions/getUserTransactionsReport'
import { getUserIncomeReport } from '../../../actions/adminActions/farmtroveActions/getUserIncomeReport'
import { farmerDetail } from '../../../actions/adminActions/farmersActions/farmerDetail'
import { getUserIdentity } from '../../../actions/adminActions/farmtroveActions/getUserIdentity'
import { MDBDataTableV5 } from 'mdbreact'
import moment from 'moment'
import { TailSpin } from 'react-loading-icons'
import { formatNumber } from '../../../components/functions'
import { MessageBox } from '../../../components'

export default function UserAccountReportPage(props) {
  const itemId = props.match.params.id

  const [datatable, setDatatable] = useState({})

  const farmersInfo = useSelector((state) => state.farmersInfo)
  const { loading, error, detail } = farmersInfo

  const userAccountReport = useSelector((state) => state.userAccountReport)
  const {
    loading: loadingDetails,
    error: detailsError,
    details,
  } = userAccountReport

  const userTransactionsReport = useSelector(
    (state) => state.userTransactionsReport,
  )
  const {
    loading: loadingHistory,
    error: historyError,
    history,
  } = userTransactionsReport

  const userIncomeReport = useSelector((state) => state.userIncomeReport)
  const {
    loading: loadingIncome,
    error: incomeError,
    incomes,
  } = userIncomeReport

  const userIdentityReport = useSelector((state) => state.userIdentityReport)
  const {
    loading: loadingIdentity,
    error: identityError,
    identity,
  } = userIdentityReport

  const dispatch = useDispatch()

  useEffect(() => {
    //  Dispatch Actions
    dispatch(farmerDetail(itemId))
    dispatch(getUserAccountReport(itemId))
    dispatch(getUserTransactionsReport(itemId))
    dispatch(getUserIncomeReport(itemId))
    dispatch(getUserIdentity(itemId))
  }, [dispatch])

  let num = 1

  useEffect(() => {
    if (history && history.data) {
      setDatatable({
        columns: [
          {
            label: 'S/N',
            field: 'row',
          },
          {
            label: 'Type',
            field: 'type',
            width: 100,
          },
          {
            label: 'Date',
            field: 'date',
            width: 100,
          },
          {
            label: 'Amount',
            field: 'amount',
            width: 100,
          },
          {
            label: 'Balance',
            field: 'balance',
            width: 50,
          },
        ],
        rows:
          history &&
          history.data.map((item) => ({
            row: num++,
            type: item.type,
            date: moment(item.date).format('Do MMMM YYYY,  h:mm a'),
            amount: item.amount
              ? `${item.currency} ${formatNumber(item.amount)}`
              : '',
            balance: item.balance
              ? `${item.currency} ${formatNumber(item.balance)}`
              : '',
          })),
      })
    }
  }, [history])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">User Account Report</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmtrove</a>
                      </li>
                      <li className="breadcrumb-item active">
                        User Account Report
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">User Info</h4>
                    {error ? (
                      <MessageBox variant="danger">{error}</MessageBox>
                    ) : null}
                    {loading ? (
                      <div
                        className="tailspin"
                        style={{ height: 'calc(50vh - 100px)' }}
                      >
                        <TailSpin
                          stroke="#98ff98"
                          strokeWidth="3"
                          color="#06bcee"
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="text-center">
                          <div className="profile-pic mb-1 mt-1">
                            <img
                              src={
                                detail && detail.user && detail.user.image
                                  ? detail.user.image
                                  : 'assets/images/farmsured_icon.png'
                              }
                              width="150"
                              className="rounded-circle"
                              alt="user"
                              style={{
                                borderRadius: '50%',
                                maxWidth: '80%',
                              }}
                            />
                            <h4 className="mt-1 mb-0 text-capitalize">
                              {detail && detail.user && detail.user.firstName}{' '}
                              {detail && detail.user && detail.user.lastName}
                            </h4>
                            <p className="mt-1 mb-1">
                              {detail && detail.user && detail.user.email
                                ? detail.user.email
                                : 'Not FIlled Yet'}
                            </p>

                            <a
                              href={`/farmer/detail/${
                                detail && detail.user && detail.user._id
                              }`}
                              className="mt-1 font-size-14"
                            >
                              {`0${detail && detail.user && detail.user.phone}`}
                            </a>
                          </div>
                          <div
                            className="row text-center"
                            style={{ marginTop: '25px' }}
                          >
                            <div className="col-4">
                              <h3 className="font-bold font-size-18">
                                {detail && detail.user && detail.user.state
                                  ? detail.user.state
                                  : 'Not Filled Yet'}
                              </h3>
                              <h6>State</h6>
                            </div>
                            <div className="col-4">
                              <h3 className="font-bold font-size-18">
                                {detail && detail.user && detail.user.lga
                                  ? detail.user.lga
                                  : 'Not Filled Yet'}
                              </h3>
                              <h6>LGA</h6>
                            </div>
                            <div className="col-4">
                              <h3 className="font-bold font-size-18">3</h3>
                              <h6>Closed</h6>
                            </div>
                          </div>
                        </div>
                        <div
                          className="border-top"
                          style={{
                            paddingTop: '20px',
                            paddingBottom: '5px',
                            marginTop: '15px',
                          }}
                        >
                          <div className="row text-center">
                            <div className="col-6 border-right">
                              <a
                                href="#"
                                className="link d-flex align-items-center justify-content-center font-medium"
                              >
                                <i className="fas fa-search-plus font-size-20 me-2"></i>
                                360 View
                              </a>
                            </div>
                            <div className="col-6">
                              <a
                                href="#"
                                className="link d-flex align-items-center justify-content-center font-medium"
                              >
                                <i className="mdi mdi-developer-board font-size-20 me-2"></i>
                                NIN View
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">User Bank Information</h4>
                    {detailsError ? (
                      <MessageBox variant="danger">{detailsError}</MessageBox>
                    ) : null}
                    {loadingDetails ? (
                      <div
                        className="tailspin"
                        style={{ height: 'calc(50vh - 100px)' }}
                      >
                        <TailSpin
                          stroke="#98ff98"
                          strokeWidth="3"
                          color="#06bcee"
                        />
                      </div>
                    ) : (
                      <div className="d-flex mt-3">
                        {details &&
                        details.meta &&
                        details.meta.data_status === 'AVAILABLE' ? (
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="mb-0">Name</p>
                              <h5 className="text-muted font-size-17 mb-3">
                                {details &&
                                  details.account &&
                                  details.account.name}
                              </h5>
                            </div>
                            <div className="col-sm-12">
                              <p className="mb-0">Account Number</p>
                              <h5 className="text-muted font-size-17 mb-3">
                                {details &&
                                  details.account &&
                                  details.account.accountNumber}
                              </h5>
                            </div>
                            <div className="col-sm-12">
                              <p className="mb-0">Bank Name</p>
                              <h5 className="text-muted font-size-17 mb-3">
                                {details &&
                                  details.account &&
                                  details.account.institution.name}
                              </h5>
                            </div>
                            <div className="col-sm-12">
                              <p className="mb-0">Balance</p>
                              <h5 className="text-muted font-size-17 mb-3">
                                {details &&
                                  details.account &&
                                  details.account.currency}{' '}
                                {formatNumber(
                                  details &&
                                    details.account &&
                                    details.account.balance,
                                )}
                              </h5>
                            </div>
                            <div className="col-sm-12">
                              <p className="mb-0">Account Type</p>
                              <h5 className="text-muted font-size-17 mb-3">
                                {details &&
                                  details.account &&
                                  details.account.type}
                              </h5>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Identity</h4>
                    {identityError ? (
                      <MessageBox variant="danger">{identityError}</MessageBox>
                    ) : null}
                    {loadingIdentity ? (
                      <div
                        className="tailspin"
                        style={{ height: 'calc(40vh - 60px)' }}
                      >
                        <TailSpin
                          stroke="#98ff98"
                          strokeWidth="3"
                          color="#06bcee"
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="col-sm-12 mt-2">
                          <p className="mb-0">Full Name</p>
                          <h5 className="text-muted font-size-17 mb-2">
                            {identity && identity.fullName}
                          </h5>
                        </div>

                        <div className="col-sm-12">
                          <p className="mb-0">Email</p>
                          <h5 className="text-muted font-size-17 mb-2">
                            {identity && identity.email}
                          </h5>
                        </div>

                        <div className="col-sm-12">
                          <p className="mb-0">Phone</p>
                          <h5 className="text-muted font-size-17 mb-2">
                            {identity && identity.phone}
                          </h5>
                        </div>

                        <div className="col-sm-12">
                          <p className="mb-0">Gender</p>
                          <h5 className="text-muted font-size-17 mb-2">
                            {identity && identity.gender}
                          </h5>
                        </div>

                        <div className="col-sm-12">
                          <p className="mb-0">BVN</p>
                          <h5 className="text-muted font-size-17 mb-2">
                            {identity && identity.bvn}
                          </h5>
                        </div>

                        <div className="col-sm-12">
                          <p className="mb-0">Martial Status</p>
                          <h5 className="text-muted font-size-17 mb-2">
                            {identity && identity.maritalStatus}
                          </h5>
                        </div>

                        <div className="col-sm-12">
                          <p className="mb-0">Address</p>
                          <h5 className="text-muted font-size-17 mb-2">
                            {identity && identity.addressLine1}
                          </h5>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Income</h4>
                    {incomeError ? (
                      <MessageBox variant="danger">{incomeError}</MessageBox>
                    ) : null}
                    {loadingIncome ? (
                      <div
                        className="tailspin"
                        style={{ height: 'calc(40vh - 60px)' }}
                      >
                        <TailSpin
                          stroke="#98ff98"
                          strokeWidth="3"
                          color="#06bcee"
                        />
                      </div>
                    ) : (
                      <div className="d-flex mt-3">
                        <div className="row">
                          <div className="col-sm-12">
                            <p className="mb-0">Average Income</p>
                            <h5 className="text-muted font-size-17 mb-3">
                              {incomes &&
                                formatNumber(incomes && incomes.average_income)}
                            </h5>
                          </div>
                          <div className="col-sm-12">
                            <p className="mb-0">Monthly Income</p>
                            <h5 className="text-muted font-size-17 mb-3">
                              {incomes &&
                                formatNumber(incomes && incomes.monthly_income)}
                            </h5>
                          </div>
                          <div className="col-sm-12">
                            <p className="mb-0">Yearly Income</p>
                            <h5 className="text-muted font-size-17 mb-3">
                              {incomes &&
                                formatNumber(incomes && incomes.yearly_income)}
                            </h5>
                          </div>
                          <div className="col-sm-12">
                            <p className="mb-0">Source of Income</p>
                            <h5 className="text-muted font-size-17">
                              {incomes && incomes.income_sources}
                            </h5>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-2">Transactions History</h4>
                    {historyError ? (
                      <MessageBox variant="danger">{historyError}</MessageBox>
                    ) : null}
                    {loadingHistory ? (
                      <div
                        className="tailspin"
                        style={{ height: 'calc(50vh - 100px)' }}
                      >
                        <TailSpin
                          stroke="#98ff98"
                          strokeWidth="3"
                          color="#06bcee"
                        />
                      </div>
                    ) : (
                      <div className="table-responsive-xl">
                        <MDBDataTableV5
                          hover
                          entriesOptions={[5, 20, 25, 50]}
                          entries={5}
                          pagesAmount={4}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                          barReverse
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
