import axios from 'axios'
import {
  UPDATE_BENEFIT_FAIL,
  UPDATE_BENEFIT_REQUEST,
  UPDATE_BENEFIT_SUCCESS,
} from '../../../constants/adminConstants/benefitContants'

export const editBenefit = (
  itemId,
  name,
  category,
  description,
  tags,
  TandC,
) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_BENEFIT_REQUEST,
    payload: {
      itemId,
      name,
      category,
      description,
      tags,
      TandC,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/benefit/update',
      { itemId, name, category, description, tags, TandC },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: UPDATE_BENEFIT_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: UPDATE_BENEFIT_FAIL, payload: message })
  }
}
