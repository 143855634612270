import axios from 'axios'
import {
    SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_FAIL,
    SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_REQUEST,
    SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_SUCCESS,
} from '../../../constants/manufacturerConstants/orderConstants'

export const updateProductOrder = (itemId, status) => async (dispatch, getState) => {
    dispatch({
        type: SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_REQUEST,
        payload: {
            itemId, status
        }
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/manufacturer/serviceprovider/update-product-order',
            { itemId, status },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_FAIL, payload: message })
    }
}
