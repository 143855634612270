import {
  ORGANISATION_SERVICE_CATEGORY_FAIL,
  ORGANISATION_SERVICE_CATEGORY_REQUEST,
  ORGANISATION_SERVICE_CATEGORY_SUCCESS,
  ORGANISATION_SERVICE_PROVIDER_CREATE_FAIL,
  ORGANISATION_SERVICE_PROVIDER_CREATE_REQUEST,
  ORGANISATION_SERVICE_PROVIDER_CREATE_RESET,
  ORGANISATION_SERVICE_PROVIDER_CREATE_SUCCESS,
  ORGANISATION_SERVICE_PROVIDER_UPDATE_FAIL,
  ORGANISATION_SERVICE_PROVIDER_UPDATE_REQUEST,
  ORGANISATION_SERVICE_PROVIDER_UPDATE_SUCCESS,
  SERVICE_PROVIDERS_FAIL,
  SERVICE_PROVIDERS_REQUEST,
  SERVICE_PROVIDERS_SUCCESS,
} from '../../../constants/manufacturerConstants/serviceProviderConstants'

export const allServiceProvidersReducer = (
  state = { loading: true, allSPs: [] },
  action,
) => {
  switch (action.type) {
    case SERVICE_PROVIDERS_REQUEST:
      return { loading: true }
    case SERVICE_PROVIDERS_SUCCESS:
      return { loading: false, allSPs: action.payload }
    case SERVICE_PROVIDERS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const organisationServiceCategoryReducer = (state = { categoryList: [] }, action) => {
  switch (action.type) {
    case ORGANISATION_SERVICE_CATEGORY_REQUEST:
      return { loading: true }
    case ORGANISATION_SERVICE_CATEGORY_SUCCESS:
      return { loading: false, categoryList: action.payload }
    case ORGANISATION_SERVICE_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const organisationAddServiceProducerReducer = (state = {}, action) => {
  switch (action.type) {
    case ORGANISATION_SERVICE_PROVIDER_CREATE_REQUEST:
      return { loading: true }
    case ORGANISATION_SERVICE_PROVIDER_CREATE_SUCCESS:
      return { loading: false, createdServiceProvider: action.payload }
    case ORGANISATION_SERVICE_PROVIDER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case ORGANISATION_SERVICE_PROVIDER_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const organisationUpdateServiceProducerReducer = (
  state = {},
  action,
) => {
  switch (action.type) {
    case ORGANISATION_SERVICE_PROVIDER_UPDATE_REQUEST:
      return { loading: true }
    case ORGANISATION_SERVICE_PROVIDER_UPDATE_SUCCESS:
      return { loading: false, updatedSP: action.payload }
    case ORGANISATION_SERVICE_PROVIDER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
