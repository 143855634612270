import React, { useState, useEffect } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MDBDataTableV5 } from 'mdbreact'
import { listFarmers } from '../../../actions/adminActions/farmersActions/listFarmers'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'

export default function AccountReportPage() {
  const [datatable, setDatatable] = useState({})

  const farmersList = useSelector((state) => state.farmersList)
  const { loading, error, farmers } = farmersList

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listFarmers())
  }, [dispatch])

  useEffect(() => {
    if (farmers) {
      setDatatable({
        columns: [
          {
            label: 'Unique ID',
            field: 'phone',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'phone',
            },
          },
          {
            label: 'Name',
            field: 'name',
            width: 200,
          },
          {
            label: 'Location',
            field: 'location',
            width: 400,
          },
          {
            label: 'Account Status',
            field: 'status',
          },
          {
            label: 'Date Joined',
            field: 'date',
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: farmers.map((item) => ({
          phone: item.phone ? `0${item.phone}` : '',
          name: `${item.firstName ? item.firstName : ''} ${
            item.lastName ? item.lastName : ''
          } `,
          location: item.state,
          date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
          status: item.monoId ? (
            <div className="badge badge-soft-success font-size-13">
              Account Connected
            </div>
          ) : (
            <div className="badge badge-soft-danger font-size-13">
              Not Connected
            </div>
          ),
          action: (
            <div>
              <a
                href={`/farmtrove/user/account-report/${item._id}`}
                style={{ marginRight: '15px' }}
                className="btn btn-sm btn-primary"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="View Report"
              >
                Manage
              </a>
            </div>
          ),
        })),
      })
    }
  }, [farmers])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Report</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmtrove</a>
                      </li>
                      <li className="breadcrumb-item active">Account Report</li>
                    </ol>
                  </div>
                </div>
              </div>

              {loading ? (
                <LoadingBox />
              ) : error ? (
                <MessageBox variant="danger">{error}</MessageBox>
              ) : (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive-xl">
                            <MDBDataTableV5
                              hover
                              entriesOptions={[5, 20, 25, 50]}
                              entries={10}
                              pagesAmount={4}
                              data={datatable}
                              searchTop
                              searchBottom={false}
                              barReverse
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
