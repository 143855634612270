import axios from 'axios'
import {
    FUND_USER_REQUEST,
    FUND_USER_SUCCESS,
    FUND_USER_FAIL,
} from '../../../constants/adminConstants/farmtrove/fundUserConstants'

export const fundAllocation = (amount, recipient, remark, password) => async (
    dispatch,
    getState,
) => {
    dispatch({
        type: FUND_USER_REQUEST,
        payload: { amount, recipient, remark, password },
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/admin/farmtrove/credit-wallet',
            { amount, recipient, remark, password },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: FUND_USER_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: FUND_USER_FAIL, payload: message })
    }
}
