import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { analysis } from '../../../actions/adminActions/dashboardActions/analysis'
import Chart from 'react-apexcharts'
import { Doughnut } from 'react-chartjs-2'
import 'chart.js/auto'
import { analyzeUserCreation, groupAndCountClusters } from '../../../components/functions'
import LoadingBox from '../../../components/LoadingBox'
import MessageBox from '../../../components/MessageBox'

export default function FarmclusterOverviewPage() {
    const [data, setData] = useState([])
    const [clusterData, setClusterData] = useState([])

    const dashboardAnalysis = useSelector((state) => state.dashboardAnalysis)
    const { loading, error, result } = dashboardAnalysis

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(analysis())
    }, [])

    useEffect(() => {
        if (result) {
            const analyzedData = analyzeUserCreation(result.farmer)
            setData(analyzedData)

            const refineData = groupAndCountClusters(result.farmer)
            setClusterData(refineData)
        }
    }, [result])


    const options = {
        chart: {
            id: "new-user-line-chart",
            type: "area",
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        colors: ["#008FFB", "#FF4560"],
        fill: {
            type: "gradient",
            opacity: 0.8,
            gradient: {
                shade: "light",
                type: "vertical",
                shadeIntensity: 0.5,
                gradientToColors: ["#008FFB", "#FF4560"],
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0.1,
                stops: [0, 100],
            },
        },
        stroke: {
            curve: "smooth",
            width: 3,
        },
        xaxis: {
            categories: [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ],
            color: 'black'
        },
        yaxis: {
            title: {
                text: "Transaction Amount",
                color: 'black'
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
        },
        dataLabels: {
            enabled: false, // Disable data labels
        },
    };

    const series = [
        {
            name: "New Farmer",
            data: data, // Data for each month
        },
    ];

    // Extract labels (cluster names) and data (counts)
    const labels = clusterData.map(item => item.name);
    const dataValues = clusterData.map(item => item.count);

    // Data for the Pie Chart
    const pieData = {
        labels: labels,
        datasets: [
            {
                label: '# of Users',
                data: dataValues,
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                    '#FF9F40',
                ],
                hoverOffset: 4,
            },
        ],
    };

    return (
        <div id="layout-wrapper">
            <Header />
            <RightSidebar />
            <LeftSidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {
                            loading ? <LoadingBox /> :
                                <>
                                    {error ? <MessageBox variant="danger">{error}</MessageBox> : null}
                                    {/* <!-- start page title --> */}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                                <h4 className="mb-sm-0">Overview</h4>

                                                <div className="page-title-right">
                                                    <ol className="breadcrumb m-0">
                                                        <li className="breadcrumb-item">
                                                            <a href="javascript: void(0);">Farmclusters</a>
                                                        </li>
                                                        <li className="breadcrumb-item active">
                                                            Overview
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <div className="flex-1 overflow-hidden">
                                                                    <p className="text-truncate font-size-14 mb-2">
                                                                        Clusters
                                                                    </p>
                                                                    <h4 className="mb-0">
                                                                        {result && result.clusters
                                                                            ? result.clusters
                                                                            : 0}
                                                                    </h4>
                                                                </div>
                                                                <div className="text-primary ms-auto">
                                                                    <i className="fal fa-users font-size-24"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body border-top py-3">
                                                            <div className="text-truncate">
                                                                <span className="text-muted ms-2 float-end">
                                                                    <a href="/farmcluster/all-clusters">View</a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <div className="flex-1 overflow-hidden">
                                                                    <p className="text-truncate font-size-14 mb-2">
                                                                        Cluster Category
                                                                    </p>
                                                                    <h4 className="mb-0">
                                                                        {result && result.categories
                                                                            ? result.categories
                                                                            : 0}
                                                                    </h4>
                                                                </div>
                                                                <div className="text-primary ms-auto">
                                                                    <i className="fal fa-chart-network font-size-24"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body border-top py-3">
                                                            <div className="text-truncate">
                                                                <span className="text-muted ms-2 float-end">
                                                                    <a href="/cluster-categories">View</a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <div className="flex-1 overflow-hidden">
                                                                    <p className="text-truncate font-size-14 mb-2">
                                                                        Farmers
                                                                    </p>
                                                                    <h4 className="mb-0">
                                                                        {result && result.farmer ? result.farmer.length : 0}
                                                                    </h4>
                                                                </div>
                                                                <div className="text-primary ms-auto">
                                                                    <i className="fal fa-id-badge font-size-24"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body border-top py-3">
                                                            <div className="text-truncate">
                                                                <span className="text-muted ms-2 float-end">
                                                                    <a href="/farmers">View</a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <div className="flex-1 overflow-hidden">
                                                                    <p className="text-truncate font-size-14 mb-2">
                                                                        Farm Cycle
                                                                    </p>
                                                                    <h4 className="mb-0">
                                                                        {result && result.farmcycles ? result.farmcycles.length : 0}
                                                                    </h4>
                                                                </div>
                                                                <div className="text-primary ms-auto">
                                                                    <i className="fal fa-tractor font-size-24"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body border-top py-3">
                                                            <div className="text-truncate">
                                                                <span className="text-muted ms-2 float-end">
                                                                    <a href="/all-farm-cycle">View</a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title mb-4">New Farmer Analytics</h4>
                                                    <div style={{ position: "relative" }}>
                                                        <Chart options={options} series={series} type="area" width={"100%"} height={500} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-5">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title mb-4">Clusters Analytics</h4>
                                                    <div id="donut-chart" style={{ minHeight: "230px" }}>
                                                        <div id="apexchartsq98thaef" style={{}}>
                                                            <Doughnut
                                                                width={500}
                                                                height={500}
                                                                options={{ maintainAspectRatio: false }}
                                                                data={pieData}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="resize-triggers">
                                                        <div className="expand-trigger">
                                                            <div style={{ width: "523px", height: "395px;" }}></div>
                                                        </div><div className="contract-trigger"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
