import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { farmCycleDetail } from '../../../actions/adminActions/farmcycleActions/farmCycleDetail'
import { LoadingBox, MessageBox } from '../../../components'
import { MDBDataTableV5 } from 'mdbreact'
import { farmcycleStatus, packageStatus } from '../../../components/functions'

function FarmCycleDetailPage(props) {
  const itemId = props.match.params.id
  const [datatable, setDatatable] = useState({})
  const [data, setData] = useState([])

  const cycleDetail = useSelector((state) => state.cycleDetail)
  const { loading, error, detail } = cycleDetail

  const dispatch = useDispatch()

  const handleRedirect = (item) => {
    props.history.push({
      pathname: `/farm-cycle/user/${item._id}`,
      state: { itemId },
    })
    window.location.reload()
  }

  useEffect(() => {
    dispatch(farmCycleDetail(itemId))
  }, [dispatch])

  useEffect(() => {
    if (detail) {
      setDatatable({
        columns: [
          {
            label: 'Unique ID',
            field: 'phone',
            width: 100,
          },
          {
            label: 'User Name',
            field: 'user',
            width: 100,
          },
          {
            label: 'Total Number of Farm(s)',
            field: 'numOfSub',
            width: 200,
          },
          {
            label: 'Pending',
            field: 'pending',
            width: 100,
          },
          {
            label: 'Ongoing',
            field: 'active',
            width: 100,
          },
          {
            label: 'Closed',
            field: 'closed',
            width: 100,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: data.map((item) => ({
          phone: item.uniqueId,
          user: item.name,
          numOfSub: item.count,
          pending: item.pending,
          active: item.active,
          closed: item.closed,
          action: (
            <button
              style={{ marginRight: '15px' }}
              className="btn btn-sm btn-success"
              data-bs-toggle="tooltip"
              data-placement="top"
              title="Manage"
              onClick={() => handleRedirect(item)}
            >
              Manage
            </button>
          ),
        })),
      })
    }
  }, [detail, data])

  useEffect(() => {
    if (detail) {
      setData(farmcycleStatus(detail))
    }
  }, [detail])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Farm Cycle Detail</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farm Cycle</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Farm Cycle Detail
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {error ? <MessageBox variant="danger">{error}</MessageBox> : null}
            {loading ? (
              <LoadingBox />
            ) : (
              <>
                {/* Section one starts here */}
                <section>
                  <div className="row">
                    <div className="col-md-3">
                      <div
                        className="card text-white bg-success"
                        style={{ borderRadius: '20px' }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                              <p className="text-truncate font-size-14 mb-2">
                                Number of Subscribers
                              </p>
                              <h4 className="mb-0 text-white">
                                {(detail && detail.length) || 0}
                              </h4>
                            </div>
                            <div className="text-white ms-auto">
                              <i className="ri-stack-line font-size-24"></i>
                            </div>
                          </div>
                        </div>

                        <div className="card-body border-top py-3">
                          <div className="float-end">
                            <button className="btn btn-sm btn-outline-light btn-custom-outline">
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div
                        className="card bg-info"
                        style={{ borderRadius: '20px' }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                              <p className="text-white font-size-14 mb-2">
                                Pending
                              </p>
                              <h4 className="mb-0 text-white">
                                {packageStatus(detail).pending}
                              </h4>
                            </div>
                            <div className="text-white ms-auto">
                              <i className="ri-stack-line font-size-24"></i>
                            </div>
                          </div>
                        </div>

                        <div className="card-body border-top py-3">
                          <div className="float-end">
                            <button className="btn btn-sm btn-outline-light btn-custom-outline">
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div
                        className="card bg-warning"
                        style={{ borderRadius: '20px' }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                              <p className="text-white font-size-14 mb-2">
                                On-going
                              </p>
                              <h4 className="mb-0 text-white">
                                {packageStatus(detail).active}
                              </h4>
                            </div>
                            <div className="text-white ms-auto">
                              <i className="ri-stack-line font-size-24"></i>
                            </div>
                          </div>
                        </div>

                        <div className="card-body border-top py-3">
                          <div className="float-end">
                            <button className="btn btn-sm btn-outline-light btn-custom-outline">
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div
                        className="card bg-danger"
                        style={{ borderRadius: '20px' }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                              <p className="text-white font-size-14 mb-2">
                                Closed
                              </p>
                              <h4 className="mb-0 text-white">
                                {packageStatus(detail).closed}
                              </h4>
                            </div>
                            <div className="text-white ms-auto">
                              <i className="ri-stack-line font-size-24"></i>
                            </div>
                          </div>
                        </div>

                        <div className="card-body border-top py-3">
                          <div className="float-end">
                            <button className="btn btn-sm btn-outline-light btn-custom-outline">
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* Section one end here */}

                {/* Setion two starts here */}
                <section>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title mb-4">
                            Farm Cycle Subscribers
                          </h4>

                          <div className="table-responsive-xl">
                            <MDBDataTableV5
                              hover
                              entriesOptions={[5, 10, 20, 25, 50]}
                              entries={10}
                              pagesAmount={10}
                              data={datatable}
                              searchTop
                              searchBottom={false}
                              barReverse
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* Setion two ends here */}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default FarmCycleDetailPage
