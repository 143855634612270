import axios from 'axios'
import {
    VERIFY_OTP_CODE_FAIL,
    VERIFY_OTP_CODE_REQUEST,
    VERIFY_OTP_CODE_SUCCESS,
} from '../../../constants/adminConstants/farmtrove/verifyAdminConstants'

const verifyAdminOtp = (code, phone) => async (dispatch, getState) => {
    dispatch({ type: VERIFY_OTP_CODE_REQUEST, payload: { code, phone } })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post('/api/admin/farmtrove/verify-otp', { code, phone }, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })
        dispatch({ type: VERIFY_OTP_CODE_SUCCESS, payload: data })

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: VERIFY_OTP_CODE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export default verifyAdminOtp
