import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Footer, Header, RightSidebar, LeftSidebar } from '../../partials'
import { LoadingBox, MessageBox } from '../../components'
import { profileDetail, update } from '../../actions/authActions/profile'
import states from '../../utilis'
import { USER_UPDATE_RESET } from '../../constants/authConstants/ProfileContants'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Axios from 'axios'
import getCroppedImg from '../../components/cropImage'
import NaijaStates from 'naija-state-local-government'
import SweetAlert from 'react-bootstrap-sweetalert'

function EditProfilePage() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [state, setState] = useState('')
  const [lga, setLga] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [companyState, setCompanyState] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [errorUpload, setErrorUpload] = useState('')
  const [alert, setAlert] = useState(null)

  // User detail state
  const userDetail = useSelector((state) => state.userDetail)
  const { loading, error, user } = userDetail

  // User update state
  const userProfileUpdate = useSelector((state) => state.userProfileUpdate)
  const {
    loading: updateLoading,
    error: updateError,
    userUpdate,
  } = userProfileUpdate

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const dispatch = useDispatch()

  const submitUpdateHandler = (e) => {
    e.preventDefault()
    if (companyName && companyAddress && companyState === '') {
      dispatch(
        update({
          firstName,
          lastName,
          phone,
          address,
          state,
          lga,
        }),
      )
    } else {
      dispatch(
        update({
          firstName,
          lastName,
          phone,
          address,
          state,
          lga,
          company: {
            name: companyName,
            address: companyAddress,
            state: companyState,
          },
        }),
      )
    }
  }

  const inputRef = React.useRef()

  const triggerFileSelectPopup = () => inputRef.current.click()

  const [image, setImage] = React.useState(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [cropedImage, setCropedImage] = useState('')
  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [zoom, setZoom] = React.useState(1)
  const [rotation, setRotation] = useState(0)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader()
      const fileSize = event.target.files[0].size / 1024 / 1024 // in MiB
      if (fileSize > 2) {
        setAlert(
          <SweetAlert
            warning
            confirmBtnText="Ok"
            confirmBtnBsStyle="danger"
            title="File too large"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            focusCancelBtn
          >
            File size cannot exceed more than 2MB
          </SweetAlert>,
        )
        event.target.value = ''
      } else {
        // Proceed further
        reader.readAsDataURL(event.target.files[0])
        reader.addEventListener('load', () => {
          setImage(reader.result)
        })
      }
    }
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
    setImage(null)
  }

  const uploadFileHandler = async () => {
    setLoadingUpload(true)
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation,
      )
      setCropedImage(croppedImage)
      if (croppedImage) {
        const { data } = await Axios.post(
          '/api/profile-image',
          { image: croppedImage },
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          },
        )

        if (data) {
          setImage(null)
          window.location.reload()
        }
        setLoadingUpload(false)
      }
    } catch (error) {
      setErrorUpload(error.message)
      setLoadingUpload(false)
    }
  }

  useEffect(() => {
    if (userUpdate) {
      setTimeout(() => {
        dispatch({ type: USER_UPDATE_RESET })
        window.location.reload()
      }, 2000)
    }

    if (!user) {
      dispatch(profileDetail())
    } else {
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setEmail(user.email)
      setPhone(user.phone)
      setAddress(user.address)
      setState(user.state)
      setLga(user.lga)
      if (user.company) {
        setCompanyName(user.company.name)
        setCompanyAddress(user.company.address)
        setCompanyState(user.company.state)
      }
    }

  }, [dispatch, userUpdate, user])

  return (
    <div id="layout-wrapper">
      <Header />
      <RightSidebar />
      <LeftSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="">
              <h5 className="">Manage Account</h5>
            </div>
            <div className="card">
              <div className="card-body">
                {/* <!-- Nav tabs --> */}
                <ul className="nav nav-tabs nav-tabs-custom " role="tablist">
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      data-bs-toggle="tab"
                      to="#home1"
                      role="tab"
                    >
                      <span className="d-block d-sm-none">
                        <i className="fa fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">Profile</span>
                    </Link>
                  </li>
                </ul>

                {/* <!-- Tab panes --> */}
                <div className="tab-content p-3 text-muted">
                  <div className="tab-pane active" id="home1" role="tabpanel">
                    {updateLoading && <LoadingBox />}
                    {loadingUpload && <LoadingBox />}
                    {loading ? (
                      <LoadingBox />
                    ) : (
                      <form onSubmit={submitUpdateHandler}>
                        <div className="row">
                          <div className="col-lg-3">
                            <h6>Profile</h6>
                            <p style={{ width: '80%' }}>
                              Your email address/Unique ID is your identity on
                              the Farmsured system and is used to log in.
                            </p>
                          </div>
                          <div className="col-md-1"></div>
                          <div className="col-lg-8">
                            <div className="row">
                              <div className="col-md-6"></div>
                              <div className="col-md-6"></div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                {errorUpload && (
                                  <MessageBox variant="danger">
                                    {errorUpload}
                                  </MessageBox>
                                )}
                                {updateError && (
                                  <MessageBox variant="danger">
                                    {updateError}
                                  </MessageBox>
                                )}
                                {userUpdate && (
                                  <MessageBox variant="success">
                                    {userUpdate.message}
                                  </MessageBox>
                                )}
                                {error && (
                                  <MessageBox variant="danger">
                                    {error}
                                  </MessageBox>
                                )}
                                <div className="center">
                                  <img
                                    alt="profile-picture"
                                    className="profile-image mb-3 "
                                    src={
                                      user && user.image
                                        ? user.image
                                        : 'assets/images/default-user.png'
                                    }
                                  />
                                  <i
                                    className="fa fa-camera cameraIcon"
                                    onClick={triggerFileSelectPopup}
                                  >
                                    <input
                                      type="file"
                                      accept="image/*"
                                      ref={inputRef}
                                      onChange={onSelectFile}
                                      style={{ display: 'none' }}
                                    />
                                  </i>
                                </div>
                                <div className="">
                                  <label htmlFor="email">First Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={firstName}
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-6"></div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <label htmlFor="email">Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="col-md-6"></div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <label htmlFor="email">Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  disabled={
                                    user &&
                                    user.account === 'farmer' &&
                                    !user.email
                                      ? false
                                      : true
                                  }
                                />
                              </div>
                              <div className="col-md-6"></div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <label htmlFor="phone">Phone</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                  required
                                  disabled={
                                    user &&
                                    user.account === 'farmer' &&
                                    !user.phone
                                      ? false
                                      : true
                                  }
                                />
                              </div>
                              <div className="col-md-6"></div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <label htmlFor="address">Address</label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="col-md-4"></div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-md-6">
                                <label htmlFor="state">State</label>
                                <select
                                  onChange={(e) => setState(e.target.value)}
                                  className="select2 form-select"
                                  value={state}
                                  required
                                >
                                  <option selected>Select</option>

                                  {states.map((item) => (
                                    <option value={item}>{item}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-6"></div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-md-6">
                                <label htmlFor="state">
                                  Local Government Area
                                </label>
                                <select
                                  className="form-select form-control select2"
                                  required
                                  name="lga"
                                  placeholder="Select a local government area"
                                  onChange={(e) => setLga(e.target.value)}
                                  value={lga}
                                >
                                  {state && state === 'Select a state' ? (
                                    <option>
                                      Select a local government area
                                    </option>
                                  ) : state ? (
                                    <>
                                      <option>
                                        Select a local government area
                                      </option>
                                      {NaijaStates.lgas(state).lgas.map(
                                        (item) => (
                                          <option value={item}>{item}</option>
                                        ),
                                      )}
                                    </>
                                  ) : (
                                    <option>
                                      Select a local government area
                                    </option>
                                  )}
                                </select>
                              </div>
                              <div className="col-md-6"></div>
                            </div>

                            {user.account === 'manufacturer' &&
                            user.company === null ? (
                              <>
                                <div className="row mt-3">
                                  <div className="col-md-6">
                                    <hr />
                                    <h5>Company Details</h5>

                                    <label htmlFor="email">Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={companyName}
                                      onChange={(e) =>
                                        setCompanyName(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-md-6"></div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-md-6">
                                    <label htmlFor="email">Address</label>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      value={companyAddress}
                                      onChange={(e) =>
                                        setCompanyAddress(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-md-6"></div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-md-6">
                                    <label htmlFor="email">State</label>
                                    <select
                                      onChange={(e) =>
                                        setCompanyState(e.target.value)
                                      }
                                      className="select2 form-select"
                                      value={companyState}
                                    >
                                      <option>Select</option>

                                      {states.map((item) => (
                                        <option value={item}>{item}</option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-md-6"></div>
                                </div>
                              </>
                            ) : null}
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <button
                                  type="submit"
                                  className="btn btn-sm btn-success mt-3"
                                  onSubmit={submitUpdateHandler}
                                >
                                  Save
                                </button>
                                <a
                                  href="/profile"
                                  type="submit"
                                  className="btn btn-sm btn-danger mt-3 float-end"
                                >
                                  Cancel
                                </a>
                              </div>
                              <div className="col-md-6"></div>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>

                  <div className="">
                    {image ? (
                      <Dialog
                        open
                        onClose={handleDialogClose}
                        aria-labelledby="form-dialog-title"
                        fullWidth
                        maxWidth={'lg'}
                      >
                        <>
                          <div className="modal-header">
                            <h5 className="modal-title">
                              Edit Profile Picture
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={handleDialogClose}
                            ></button>
                          </div>
                          <DialogContent>
                            <div className="image-container">
                              <div className="container-cropper">
                                <>
                                  <div className="cropper">
                                    <Cropper
                                      image={image}
                                      crop={crop}
                                      zoom={zoom}
                                      aspect={1}
                                      onCropChange={setCrop}
                                      onZoomChange={setZoom}
                                      onCropComplete={onCropComplete}
                                    />
                                  </div>

                                  <div className="slider">
                                    <Slider
                                      min={1}
                                      max={3}
                                      step={0.1}
                                      value={zoom}
                                      onChange={(e, zoom) => setZoom(zoom)}
                                    />
                                  </div>
                                </>
                              </div>
                            </div>
                          </DialogContent>
                          <DialogActions>
                            <button
                              className="btn btn-success btn-sm"
                              type="submit"
                              onClick={uploadFileHandler}
                            >
                              Upload
                            </button>
                            <button
                              className="btn btn-sm btn-secondary"
                              onClick={handleDialogClose}
                            >
                              Close
                            </button>
                          </DialogActions>
                        </>
                      </Dialog>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alert}
      <Footer />
    </div>
  )
}

export default EditProfilePage
