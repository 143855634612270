import axios from 'axios'
import {
    UPDATE_CLUSTER_REQUEST,
    UPDATE_CLUSTER_SUCCESS,
    UPDATE_CLUSTER_FAIL,
} from '../../../constants/adminConstants/farmcluster/clusterContants'

export const editCluster = (
    itemId, manufacturer, name, category, description
) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_CLUSTER_REQUEST,
        payload: {
            itemId, manufacturer, name, category, description
        },
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/admin/farmcluster/edit-cluster',
            {
                itemId, manufacturer, name, category, description
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: UPDATE_CLUSTER_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: UPDATE_CLUSTER_FAIL, payload: message })
    }
}
