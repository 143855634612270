import axios from 'axios'
import {
  TAGS_REQUEST_LIST_REQUEST,
  TAGS_REQUEST_LIST_SUCCESS,
  TAGS_REQUEST_LIST_FAIL,
  TAG_ORDER_DETAIL_REQUEST,
  TAG_ORDER_DETAIL_SUCCESS,
  TAG_ORDER_DETAIL_FAIL,
} from '../../../constants/adminConstants/TagsConstants'

// List of all tag batch on the system
export const getRequests = () => async (dispatch, getState) => {
  dispatch({ type: TAGS_REQUEST_LIST_REQUEST })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.get('/api/admin/get-requests', {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    })
    dispatch({ type: TAGS_REQUEST_LIST_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: TAGS_REQUEST_LIST_FAIL, payload: message })
  }
}

export const orderDetail = (itemId) => async (dispatch, getState) => {
  dispatch({ type: TAG_ORDER_DETAIL_REQUEST, payload: itemId })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/tag-request/detail',
      { itemId },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: TAG_ORDER_DETAIL_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: TAG_ORDER_DETAIL_FAIL, payload: message })
  }
}
