import {
  SERVICE_PROVIDER_CREATE_FAIL,
  SERVICE_PROVIDER_CREATE_REQUEST,
  SERVICE_PROVIDER_CREATE_RESET,
  SERVICE_PROVIDER_CREATE_SUCCESS,
  SERVICE_PROVIDER_LIST_FAIL,
  SERVICE_PROVIDER_LIST_REQUEST,
  SERVICE_PROVIDER_LIST_SUCCESS,
  UPDATE_SERVICE_PROVIDER_REQUEST,
  UPDATE_SERVICE_PROVIDER_SUCCESS,
  UPDATE_SERVICE_PROVIDER_FAIL,
  UPDATE_SERVICE_PROVIDER_RESET,
  SERVICE_PROVIDER_DELETE_REQUEST,
  SERVICE_PROVIDER_DELETE_SUCCESS,
  SERVICE_PROVIDER_DELETE_FAIL,
  SERVICE_PROVIDER_DELETE_RESET,
  ADD_SERVICE_COVERAGE_REQUEST,
  ADD_SERVICE_COVERAGE_SUCCESS,
  ADD_SERVICE_COVERAGE_FAIL,
  ADD_SERVICE_COVERAGE_RESET,
  SERVICE_COVERAGE_LIST_REQUEST,
  SERVICE_COVERAGE_LIST_SUCCESS,
  SERVICE_COVERAGE_LIST_FAIL,
  SERVICE_COVERAGE_DELETE_REQUEST,
  SERVICE_COVERAGE_DELETE_SUCCESS,
  SERVICE_COVERAGE_DELETE_FAIL,
  SERVICE_COVERAGE_DELETE_RESET,
  UPDATE_SERVICE_COVERAGE_REQUEST,
  UPDATE_SERVICE_COVERAGE_SUCCESS,
  UPDATE_SERVICE_COVERAGE_FAIL,
  UPDATE_SERVICE_COVERAGE_RESET,
  ALL_BENEFIT_CLAIMS_REQUEST,
  ALL_BENEFIT_CLAIMS_SUCCESS,
  ALL_BENEFIT_CLAIMS_FAIL,
  SERVICE_CLAIM_DELETE_REQUEST,
  SERVICE_CLAIM_DELETE_SUCCESS,
  SERVICE_CLAIM_DELETE_FAIL,
  SERVICE_CLAIM_DELETE_RESET,
  ASSIGN_SERVICE_CATEGORY_REQUEST,
  ASSIGN_SERVICE_CATEGORY_SUCCESS,
  ASSIGN_SERVICE_CATEGORY_FAIL,
  ASSIGN_SERVICE_CATEGORY_RESET,
  ORGANISATIONS_SERVICE_CATEGORY_REQUEST,
  ORGANISATIONS_SERVICE_CATEGORY_SUCCESS,
  ORGANISATIONS_SERVICE_CATEGORY_FAIL,
  EDIT_ASSIGNED_SERVICE_CATEGORY_REQUEST,
  EDIT_ASSIGNED_SERVICE_CATEGORY_SUCCESS,
  EDIT_ASSIGNED_SERVICE_CATEGORY_FAIL,
  EDIT_ASSIGNED_SERVICE_CATEGORY_RESET,
  DELETE_ASSIGNED_SERVICE_CATEGORY_FAIL,
  DELETE_ASSIGNED_SERVICE_CATEGORY_REQUEST,
  DELETE_ASSIGNED_SERVICE_CATEGORY_SUCCESS,
  DELETE_ASSIGNED_SERVICE_CATEGORY_RESET,
} from '../../../constants/adminConstants/serviceConstants'

export const allServiceReducer = (
  state = { loading: true, allservices: [] },
  action,
) => {
  switch (action.type) {
    case SERVICE_PROVIDER_LIST_REQUEST:
      return { loading: true }
    case SERVICE_PROVIDER_LIST_SUCCESS:
      return { loading: false, allservices: action.payload }
    case SERVICE_PROVIDER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const serviceCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICE_PROVIDER_CREATE_REQUEST:
      return { loading: true }
    case SERVICE_PROVIDER_CREATE_SUCCESS:
      return { loading: false, success: true, newService: action.payload }
    case SERVICE_PROVIDER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case SERVICE_PROVIDER_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const updateServiceProviderReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SERVICE_PROVIDER_REQUEST:
      return { loading: true }
    case UPDATE_SERVICE_PROVIDER_SUCCESS:
      return { loading: false, success: true }
    case UPDATE_SERVICE_PROVIDER_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_SERVICE_PROVIDER_RESET:
      return {}
    default:
      return state
  }
}

export const serviceProviderDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICE_PROVIDER_DELETE_REQUEST:
      return { loading: true }
    case SERVICE_PROVIDER_DELETE_SUCCESS:
      return { loading: false, serviceDeleted: action.payload }
    case SERVICE_PROVIDER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case SERVICE_PROVIDER_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const serviceCategoryListReducer = (
  state = { loading: true, categorylist: [] },
  action,
) => {
  switch (action.type) {
    case SERVICE_COVERAGE_LIST_REQUEST:
      return { loading: true }
    case SERVICE_COVERAGE_LIST_SUCCESS:
      return { loading: false, categorylist: action.payload }
    case SERVICE_COVERAGE_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addServiceCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_SERVICE_COVERAGE_REQUEST:
      return { loading: true }
    case ADD_SERVICE_COVERAGE_SUCCESS:
      return { loading: false, category: action.payload }
    case ADD_SERVICE_COVERAGE_FAIL:
      return { loading: false, error: action.payload }
    case ADD_SERVICE_COVERAGE_RESET:
      return {}
    default:
      return state
  }
}

export const assignServiceCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSIGN_SERVICE_CATEGORY_REQUEST:
      return { loading: true }
    case ASSIGN_SERVICE_CATEGORY_SUCCESS:
      return { loading: false, assigned: action.payload }
    case ASSIGN_SERVICE_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    case ASSIGN_SERVICE_CATEGORY_RESET:
      return {}
    default:
      return state
  }
}

export const updateAssignedServiceCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_ASSIGNED_SERVICE_CATEGORY_REQUEST:
      return { loading: true }
    case EDIT_ASSIGNED_SERVICE_CATEGORY_SUCCESS:
      return { loading: false, success: true, updated: action.payload }
    case EDIT_ASSIGNED_SERVICE_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    case EDIT_ASSIGNED_SERVICE_CATEGORY_RESET:
      return {}
    default:
      return state
  }
}

export const allOrganisationServiceCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case ORGANISATIONS_SERVICE_CATEGORY_REQUEST:
      return { loading: true }
    case ORGANISATIONS_SERVICE_CATEGORY_SUCCESS:
      return { loading: false, organsationCat: action.payload }
    case ORGANISATIONS_SERVICE_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const organisationServiceCategoryDeleteReducer = (
  state = {},
  action,
) => {
  switch (action.type) {
    case DELETE_ASSIGNED_SERVICE_CATEGORY_REQUEST:
      return { loading: true }
    case DELETE_ASSIGNED_SERVICE_CATEGORY_SUCCESS:
      return { loading: false, success: true }
    case DELETE_ASSIGNED_SERVICE_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    case DELETE_ASSIGNED_SERVICE_CATEGORY_RESET:
      return {}
    default:
      return state
  }
}

export const serviceCategoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICE_COVERAGE_DELETE_REQUEST:
      return { loading: true }
    case SERVICE_COVERAGE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case SERVICE_COVERAGE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case SERVICE_COVERAGE_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const updateServiceCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SERVICE_COVERAGE_REQUEST:
      return { loading: true }
    case UPDATE_SERVICE_COVERAGE_SUCCESS:
      return { loading: false, success: true }
    case UPDATE_SERVICE_COVERAGE_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_SERVICE_COVERAGE_RESET:
      return {}
    default:
      return state
  }
}

export const allBenefitClaimsReducer = (
  state = { loading: true, allclaims: [] },
  action,
) => {
  switch (action.type) {
    case ALL_BENEFIT_CLAIMS_REQUEST:
      return { loading: true }
    case ALL_BENEFIT_CLAIMS_SUCCESS:
      return { loading: false, allclaims: action.payload }
    case ALL_BENEFIT_CLAIMS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const serviceClaimDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICE_CLAIM_DELETE_REQUEST:
      return { loading: true }
    case SERVICE_CLAIM_DELETE_SUCCESS:
      return { loading: false, success: true }
    case SERVICE_CLAIM_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case SERVICE_CLAIM_DELETE_RESET:
      return {}
    default:
      return state
  }
}
