import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cycleDetail } from '../../../actions/farmerActions/farmcycleActions/cycleDetail'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { LoadingBox, MessageBox, AnalysisTyping } from '../../../components'
import moment from 'moment'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import LinearProgress from '@material-ui/core/LinearProgress'
import ReactCardFlip from 'react-card-flip'
import { QrReader } from 'react-qr-reader'
import { saveCompliance } from '../../../actions/farmerActions/farmcycleActions/saveCompliance'
import { verifyQRCode } from '../../../actions/farmerActions/farmcycleActions/verifyQRCode'
import { allCycles } from '../../../actions/farmerActions/farmcycleActions/allCycles'
import { TailSpin } from 'react-loading-icons'
import {
  SAVE_FARM_COMPLIANCE_RESET,
  SCAN_TAG_RESET,
  UPDATE_PACKAGE_EOP_RESET,
} from '../../../constants/farmerConstants/packagesConstants'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MDBDataTableV5 } from 'mdbreact'
import { scanActivities } from '../../../actions/farmerActions/activitiesActions/scanActivities'
import CountdownTimer, {
  CountdownStatus,
} from '../../../components/Counter/CountdownTimer'
import {
  checkBenefitStatus,
  filterActivitiesByTime,
} from '../../../components/functions'
import SweetAlert from 'react-bootstrap-sweetalert'
import { verifyTag } from '../../../actions/farmerActions/tagsActions/activateTag'
import Modal from 'react-modal'
import { FARMER_SCAN_TAG_RESET } from '../../../constants/farmerConstants/tagsConstants'
import states from '../../../utilis'
import NaijaStates from 'naija-state-local-government'
import { getServiceProviders } from '../../../actions/farmerActions/getServiceProvidersActions/getServiceProviders'
import { FARMER_CLAIM_BENEFIT_RESET } from '../../../constants/farmerConstants/claimBenefitConstants'
import { profileDetail } from '../../../actions/authActions/profile'
import { Editor } from '@tinymce/tinymce-react'
import { claimBenefit } from '../../../actions/farmerActions/claimActions/claimBenefit'
import parse from 'html-react-parser'
import { weatherAnalysis } from '../../../actions/farmerActions/farmcycleActions/weatherAnalysis'
import { AiLearnMore } from '../../../actions/farmerActions/farmcycleActions/AiLearnMore'
import { formatNumber } from '../../../components/functions'
import { updateEOP } from '../../../actions/farmerActions/farmcycleActions/updateEOP'

function CycleDetailPage(props) {
  const itemId = props.match.params.id

  const [alert, setAlert] = useState(null)
  const [datatable, setDatatable] = useState({})
  const [open, setOpen] = useState(false)
  const [setIsOpen] = useState(false)
  const [openValidateDialog, setOpenValidateDialog] = useState(false)
  const [setIsProductDialogOpen] = useState(false)
  const [benefitDialog, setBenefitDialog] = useState(false)
  const [codeViewOpen, setCodeViewOpen] = useState(false)
  const [viewActivity, setViewActivity] = useState(false)
  const [isFlipped, setIsFlipped] = useState(false)
  const [tag, setTag] = useState('')
  const [color, setColor] = useState('')
  const [activityId, setActivityId] = useState('')
  const [point, setPoint] = useState(0)
  const [farmActivity, setFarmActivity] = useState('')
  const [activity, setActivity] = useState('')
  const [tagColor, setTagColor] = useState('')
  const [task, setTask] = useState('')
  const [timeLineFrom, setTimelineFrom] = useState('')
  const [timeLineTo, setTimelineTo] = useState('')
  const [comment, setComment] = useState('')
  const [compliance, setCompliance] = useState(false)
  const [time, setTime] = useState(moment().format('LTS'))
  const [geolocation, setGeolocation] = useState({})
  const [geoError, setError] = useState(null)
  const [data, setData] = useState([])
  const [lat, setLat] = useState('')
  const [long, setLong] = useState('')
  const [levelPoint, setLevelPoint] = useState('')
  const [services, setServices] = useState([])
  const [SPLocation, setSPLocation] = useState([])
  const [mailOpen, setMailOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [serviceProvider, setServiceProvider] = useState('')
  const [show, setShow] = useState(false)
  const [currentState, setCurrentState] = useState('')
  const [benefitCategory, setBenefitCategory] = useState({})
  const [benefitName, setBenefitName] = useState({})
  const [lga, setLga] = useState('')
  const [expectedPoint, setExpectedPoint] = useState(0)
  const [date, setDate] = useState('')
  const [analysisDialog, setAnalysisDialog] = useState(false)
  const [learnMoreDialog, setLearnMoreDialog] = useState(false)
  const [viewCycleDetail, setViewCycleDetail] = useState(false)
  const [showEOPDialog, setShowEOPDialog] = useState(false)
  const [totalSum, setTotalSum] = useState(0)
  const [additionalEoPTotalSum, setAdditionalEoPTotalSum] = useState(0)
  const [getEopTotalSum, setGetEopTotalSum] = useState(0)
  const [productionSize, setProductionSize] = useState(0)
  const [eop, setEop] = useState([
    {
      EOPItem: '',
      quantity: '',
      selectedProduct: '',
      notes: '',
      price: 0,
      total: 0,
    },
  ])
  const [list, setinputList] = useState([
    {
      EOPItem: '',
      quantity: '',
      selectedProduct: '',
      EOPItemType: '',
      UoM: '',
      notes: '',
      price: 0,
      total: 0,
    },
  ])
  const [profit, setProfit] = useState(0)
  const [profitabilityROI, setProfitabilityROI] = useState(0)

  const editorRef = useRef(null)

  const userDetail = useSelector((state) => state.userDetail)
  const { user } = userDetail

  const farmcycleDetail = useSelector((state) => state.farmcycleDetail)
  const { loading, detail, error } = farmcycleDetail

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const activityCompliance = useSelector((state) => state.activityCompliance)
  const {
    loading: loadingCompliance,
    saved,
    error: complianceError,
  } = activityCompliance

  const listCycles = useSelector((state) => state.listCycles)
  const { loading: loadingCycles, cycles } = listCycles

  const scanResult = useSelector((state) => state.scanResult)
  const { loading: loadingScan, result, error: scanError } = scanResult

  const farmerScan = useSelector((state) => state.farmerScan)
  const { loading: loadingVerify, error: ErrScan, result: scan } = farmerScan

  const cycleScanActivities = useSelector((state) => state.cycleScanActivities)
  const {
    loading: loadingScanActivities,
    error: scanActivitiesError,
    cycleScans,
  } = cycleScanActivities

  const listProviders = useSelector((state) => state.listProviders)
  const { loading: serviceLoading, listService } = listProviders

  const benefitClaim = useSelector((state) => state.benefitClaim)
  const { loading: claimLoading, error: claimError, claim } = benefitClaim

  const analysisWeather = useSelector((state) => state.analysisWeather)
  const {
    loading: loadingAnalyses,
    error: analysesError,
    analyses,
  } = analysisWeather

  const analysisActivity = useSelector((state) => state.analysisActivity)
  const {
    loading: loadingReadMore,
    error: readMoreError,
    readMore,
  } = analysisActivity

  const EOPUpdate = useSelector((state) => state.EOPUpdate)
  const { loading: loadingUpdate, error: updateError, updated } = EOPUpdate

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(cycleDetail(itemId))
    dispatch(allCycles())
    dispatch(scanActivities(itemId))
    dispatch(profileDetail())

    if (result) {
      toast.success(result.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      setOpen(!open)
      setIsFlipped(!isFlipped)
      setTimeout(() => {
        dispatch({ type: SCAN_TAG_RESET })
      }, 2000)
    }

    if (saved) {
      toast.success(saved.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      setIsFlipped(!isFlipped)
      setTimeout(() => {
        dispatch({ type: SAVE_FARM_COMPLIANCE_RESET })
      }, 2000)
    }

    if (scan) {
      setOpen(false)
      setIsFlipped(false)
    }

    if (updated) {
      setShowEOPDialog(false)
      toast.success(updated.message, {
        position: toast.POSITION.TOP_RIGHT,
      })

      setTimeout(() => {
        dispatch({ type: UPDATE_PACKAGE_EOP_RESET })
      }, 2000)
    }
  }, [dispatch, result, saved, scan, updated])

  const handleComplianceDialog = (item) => {
    setIsFlipped(!isFlipped)
    setActivityId(item._id)
    setActivity(item.activity)
    setFarmActivity(item.farmActivity)
    setTask(item.expectedTask)
    setTimelineFrom(item.timeLineFrom)
    setTimelineTo(item.timeLineTo)
    setPoint(item.point)
    setComment(item.comment)
    setTagColor(item.tagColor)
    setCompliance(item.compliance)
    setDate(item.date)
  }

  const handleSave = () => {
    if (editorRef.current) {
      let comment = editorRef.current.getContent()
      dispatch(saveCompliance(activityId, itemId, comment, point))
    }
  }

  const generateGreetings = () => {
    var currentHour = moment().format('HH')

    if (currentHour >= 3 && currentHour < 12) {
      return 'Good Morning'
    } else if (currentHour >= 12 && currentHour < 15) {
      return 'Good Afternoon'
    } else if (currentHour >= 15 && currentHour < 24) {
      return 'Good Evening'
    } else if (currentHour >= 0 || currentHour < 3) {
      return 'Good Morning'
    } else {
      return 'Hello'
    }
  }

  useMemo(() => {
    const intervalID = setInterval(() => {
      setTime(moment().format('h:mm:ss a'))
    }, 1000)

    return () => clearInterval(intervalID)
  }, [])

  const onChange = ({ coords }) => {
    setGeolocation({
      lat: coords.latitude,
      lng: coords.longitude,
    })
  }

  const onError = (locaionError) => {
    setError(locaionError.message)
  }

  useEffect(() => {
    const geo = navigator.geolocation
    if (!geo) {
      setError('Geolocation is not supported')
      return
    }
    const watcher = geo.watchPosition(onChange, onError)
    return () => geo.clearWatch(watcher)
  }, [navigator.geolocation])

  useEffect(() => {
    const fetchData = async () => {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude)
        setLong(position.coords.longitude)
      })

      if (lat && long) {
        await fetch(
          `${process.env.REACT_APP_API_URL}/weather/?lat=${lat}&lon=${long}&units=metric&APPID=${process.env.REACT_APP_API_KEY}`,
        )
          .then((res) => res.json())
          .then((result) => {
            setData(result)
          })
      }
    }
    fetchData()
  }, [lat, long])

  useEffect(() => {
    if (scanError) {
      toast.error(scanError, {
        position: toast.POSITION.TOP_RIGHT,
      })
      setOpen(!open)
      setIsFlipped(!isFlipped)
      setTimeout(() => {
        dispatch({ type: SCAN_TAG_RESET })
      }, 2000)
    }

    if (complianceError) {
      toast.error(complianceError, {
        position: toast.POSITION.TOP_RIGHT,
      })
      setComment('')
      setIsFlipped(!isFlipped)
      setTimeout(() => {
        dispatch({ type: SAVE_FARM_COMPLIANCE_RESET })
      }, 2000)
    }

    if (updateError) {
      toast.error(updateError, {
        position: toast.POSITION.TOP_RIGHT,
      })
      setTimeout(() => {
        dispatch({ type: UPDATE_PACKAGE_EOP_RESET })
      }, 2000)
    }
  }, [scanError, complianceError, updateError])

  const countActivities = (activities) => {
    let count = 0
    activities.map((item) => {
      if (item.compliance) return count++
    })
    return count
  }

  const numberOfDays = (date) => {
    const today = moment() // today's date
    const otherDate = moment(date) // the other date

    // calculate the difference in days between the two dates
    const diffInDays = today.diff(otherDate, 'days') + 1

    // return the number of days
    return diffInDays
  }

  const checkDate = (startDate) => {
    // Convert the input string to a Date object
    var inputDate = new Date(startDate)

    // Get the current date
    var today = new Date()

    // Compare the input date with today's date
    if (inputDate >= today) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (detail && detail.point && expectedPoint) {
      setLevelPoint(Math.ceil((detail.point / expectedPoint) * 100))
    }
  }, [detail, expectedPoint])

  const dateTime = (item) => {
    const c =
      item.batch && item.batch.product && item.batch.product.category
        ? item.batch.product.category.cycleDuration
        : 0

    let hrs = c * 60 * 60 * 1000

    const d = new Date(item.redActivatedAt)
    let ms = d.getTime()
    return ms + hrs
  }

  useEffect(() => {
    if (cycleScans) {
      setDatatable({
        columns: [
          {
            label: 'Product',
            field: 'product',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'product',
            },
          },
          {
            label: 'Earned Points',
            field: 'earnedPoints',
            width: 200,
          },
          {
            label: 'Date Verified',
            field: 'dateVerified',
            width: 100,
          },
          {
            label: 'Date Activated',
            field: 'dateActivated',
            width: 100,
          },
          {
            label: 'Protection Timeline',
            field: 'timeline',
            width: 50,
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'disabled',
          },
        ],
        rows: cycleScans.map((item) => ({
          product: item.batch.product.name,
          earnedPoints:
            item.redActivatedAt && !item.greenActivatedAt
              ? item.points
              : item.redActivatedAt && item.greenActivatedAt
                ? item.points + item.points
                : null,

          dateVerified: item.redActivatedAt
            ? moment(item.redActivatedAt).format('Do MMMM YYYY,  h:mm a')
            : null,
          dateActivated: item.greenActivatedAt
            ? moment(item.greenActivatedAt).format('Do MMMM YYYY,  h:mm a')
            : null,
          timeline: <CountdownTimer targetDate={dateTime(item)} />,
          status: <CountdownStatus item={item} targetDate={dateTime(item)} />,
        })),
      })
    }
  }, [cycleScans])

  const handleScanSubmit = (e) => {
    e.preventDefault()

    if (!tag) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Input Tag Pin"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          A tag pin must be filled!!!
        </SweetAlert>,
      )
    } else if (!color) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Select Tag Color"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          A tag color must be selected!!!
        </SweetAlert>,
      )
    } else {
      dispatch(verifyTag(itemId, activityId, tag, color, geolocation))
      setColor('')
    }
  }

  const customStyles = {
    content: {
      width: '330px',
      margin: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
  }

  const resetHandler = (e) => {
    e.preventDefault()
    setOpenValidateDialog(false)
    setOpen(false)
    dispatch({ type: FARMER_SCAN_TAG_RESET })
  }

  const resetScanError = (e) => {
    e.preventDefault()
    setOpenValidateDialog(false)
    setOpen(false)
    dispatch({ type: FARMER_SCAN_TAG_RESET })
  }

  useEffect(() => {
    if (ErrScan) {
      setOpen(false)
    }
  }, [ErrScan])

  useMemo(() => {
    if (
      detail &&
      detail.productCategory &&
      detail.productCategory.expectedPoint
    ) {
      setExpectedPoint(detail.productCategory.expectedPoint * detail.packageNum)
    }
  }, [detail])

  //////////////////////////////////
  //////////////////////////////////
  // Claim Benefits logics /////////
  //////////////////////////////////
  //////////////////////////////////
  const handleMailOpen = () => {
    setMailOpen(true)
    setBenefitDialog(false)
  }

  const onClose = (e) => {
    e.preventDefault()
    dispatch({ type: FARMER_CLAIM_BENEFIT_RESET })
    setOpen(false)
    setShow(false)
    setBenefitDialog(false)
    setSPLocation([])
    setCurrentState('')
    setServices([])
    setServiceProvider(null)
  }

  const onCloseMail = (e) => {
    e.preventDefault()
    dispatch({ type: FARMER_CLAIM_BENEFIT_RESET })
    setOpen(false)
    setMailOpen(false)
    setShow(false)
    setSPLocation([])
    setCurrentState('')
    setServices([])
    setServiceProvider(null)
  }

  const handleBenefitDialog = (item) => {
    setBenefitDialog(true)
    setBenefitName(item.benefit && item.benefit.benefit)
    setBenefitCategory(
      item.benefit && item.benefit.category && item.benefit.category.name,
    )
  }

  useEffect(() => {
    if (claim) {
      setTimeout(() => {
        setMailOpen(false)
        setEmail('')
        setSPLocation([])
        setServices([])
        setServiceProvider('')
        setCurrentState('')
        setShow(false)
        dispatch({ type: FARMER_CLAIM_BENEFIT_RESET })
      }, 2100)
    }

    if (serviceProvider) {
      const SP = services.filter((item) => {
        if (item._id === serviceProvider) {
          return item
        }
        return false
      })

      if (SP) {
        setShow(true)
        setSPLocation(SP)
      }
    }
  }, [dispatch, claim, serviceProvider])

  useMemo(() => {
    if (listService) {
      setServices(listService)
    }
  }, [listService])

  const handleClaimSubmit = (e) => {
    e.preventDefault()
    if (editorRef.current) {
      let detail = editorRef.current.getContent()

      dispatch(
        claimBenefit(
          itemId,
          benefitName,
          currentState,
          lga,
          serviceProvider,
          detail,
        ),
      )
    }
  }

  //=====================================
  //=====================================
  //=========Weather Analysis============
  //=====================================
  //=====================================
  const handleAnalysis = (result) => {
    let message = `What is the effect of the weather having the following data; 1. Wind speed is ${result.wind.speed
      }m/s 2. Temperature is ${Math.ceil(result.main.temp)}°C 3. Pressure is ${result.main.pressure
      }hPa 4. Humidity is ${result.main.humidity} on my ${detail && detail.productCategory && detail.productCategory.name
      } farm.
    Note:  Make your analysis concise.`

    setAnalysisDialog(true)
    dispatch(weatherAnalysis(message))
  }

  const closeAnalysis = (e) => {
    e.preventDefault()
    setAnalysisDialog(false)
  }

  //=====================================
  //=====================================
  //========Learn More Analysis==========
  //=====================================
  //=====================================

  const handleLearnMore = () => {
    let message = `Explain in concise detail this activity based on the farm cycle:
    
    Type of activity: ${activity}
    Title of task: ${farmActivity}
    Expected task: ${task}
    Task timeframe: ${timeLineFrom} - ${timeLineTo} hrs
    Expect point: ${point}

    Note: Not more than 500 words
    `
    setLearnMoreDialog(true)
    dispatch(AiLearnMore(message))
  }

  const closeLearnMore = (e) => {
    e.preventDefault()
    setLearnMoreDialog(false)
  }

  //=====================================
  //=====================================
  //========== E.O.P Dialog Box==========
  //=====================================
  //=====================================

  const handleEOPDialog = () => {
    setShowEOPDialog(true)
  }

  useEffect(() => {
    if (
      detail &&
      detail.packageId &&
      detail.packageId.packageMinUnit &&
      detail.packageId.packageMinUnit.measuringUnit
    ) {
      let minUnit = Number(detail.packageId.packageMinUnit.minUnit)
      let packageNum = detail.packageNum

      setProductionSize(minUnit * packageNum)
    }
    if (detail) {
      setEop(() => [...detail.eop])
      setinputList(() => [...detail.additionalEop])
    }
  }, [detail])

  useEffect(() => {
    if (eop) {
      setTotalSum(sumTotal(eop))
    }

    if (list) {
      setAdditionalEoPTotalSum(sumTotal(list))
    }
  }, [eop, list])

  const handleinputchange = (e, index) => {
    const { value } = e
    const listItem = [...eop]

    if (value) {
      let qty = listItem[index].quantity
      const result = listItem[index].product.find((obj) => obj._id === value)
      if (result) {
        listItem[index]['selectedProduct'] = value
        listItem[index]['price'] = result.price
        listItem[index]['total'] = parseInt(result.price * qty)
        setEop(listItem)
        setTotalSum(sumTotal(eop))
      }
    }
  }

  function sumTotal(array) {
    let totalSum = 0

    array.forEach((item) => {
      if (item.total) {
        totalSum += item.total
      }
    })

    return totalSum
  }

  useEffect(() => {
    if (totalSum) {
      let sum = totalSum + additionalEoPTotalSum
      setGetEopTotalSum(sum)
    }
  }, [totalSum, additionalEoPTotalSum])

  const handleFormChange = (e, index) => {
    const { name, value } = e
    const listItem = [...list]
    listItem[index][name] = value

    if (name) {
      let price = listItem[index]['price']
      let qty = listItem[index]['quantity']

      listItem[index]['total'] = parseInt(price * qty)
      setinputList(listItem)
      setAdditionalEoPTotalSum(sumTotal(list))
    }
  }

  const handleremove = (index) => {
    const listItem = [...list]
    listItem.splice(index, 1)
    setAdditionalEoPTotalSum(sumTotal(listItem))
    setinputList(listItem)
  }

  const handleAddClick = (e) => {
    e.preventDefault()
    const emptyInput = list.some(
      (item) =>
        item.EOPItem === '' ||
        item.quantity === '' ||
        item.notes === '' ||
        item.EOPItemType === '' ||
        item.UoM === '',
    )

    if (!emptyInput) {
      setinputList((newInput) => [
        ...newInput,
        { EOPItem: '', quantity: '', notes: '', EOPItemType: '', UoM: '' },
      ])
    } else {
      // Handle case where empty fields exist in the existing items
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Field(s)"
          onConfirm={() => setAlert()}
          onCancel={() => setAlert()}
        >
          Please fill in all fields before adding a new item.
        </SweetAlert>,
      )
    }
  }

  useEffect(() => {
    if (detail && detail.totalProduceSold) {
      setProfit(detail.totalProduceSold - getEopTotalSum)
    }

    if (profit && getEopTotalSum) {
      let a = profit / getEopTotalSum
      let b = a * 100
      let c = b.toPrecision(3)
      setProfitabilityROI(c)
    }
  }, [getEopTotalSum, profit])

  const handleEOPUpdate = (e) => {
    e.preventDefault()

    dispatch(
      updateEOP(
        itemId,
        eop,
        list,
        totalSum,
        additionalEoPTotalSum,
        getEopTotalSum,
        profitabilityROI,
        profit
      ),
    )
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Farm Cycle Details</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farm Cycle</a>
                      </li>
                      <li className="breadcrumb-item active">Detail</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {claimLoading ? <LoadingBox /> : null}
            {scanActivitiesError ? (
              <MessageBox variant="danger">{scanActivitiesError}</MessageBox>
            ) : null}
            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-5">
                        <h5>
                          {generateGreetings()},{' '}
                          <span className="user-name">
                            {userInfo && userInfo.firstName
                              ? userInfo.firstName
                              : null}
                          </span>
                        </h5>
                        <p className="card-desc">Let’s check your farm today</p>

                        <div className="card">
                          <div className="card-body">
                            <h6>
                              <i className="fal fa-coins me-2"></i>Compliance
                              Level
                            </h6>

                            <div
                              className=""
                              style={{
                                maxWidth: 250,
                                maxHeight: 250,
                                margin: 'auto',
                                marginTop: '22px',
                              }}
                            >
                              <CircularProgressbar
                                value={levelPoint ? levelPoint : 0}
                                text={`${levelPoint ? levelPoint : 0}%`}
                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap="butt"
                                // Text size
                                textSize="18px"
                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration="0.5"
                                strokeWidth="14"
                                styles={buildStyles({
                                  textColor: `${levelPoint <= 20
                                    ? '#96c400'
                                    : levelPoint <= 40
                                      ? '#8bc34a'
                                      : levelPoint <= 60
                                        ? '#569e02'
                                        : '#1d6639'
                                    }`,
                                  pathColor: `${levelPoint <= 20
                                    ? '#96c400'
                                    : levelPoint <= 40
                                      ? '#8bc34a'
                                      : levelPoint <= 60
                                        ? '#569e02'
                                        : '#1d6639'
                                    }`,
                                })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="card">
                          <div className="card-body">
                            <div className="mb-4">
                              <p className="card-text p-0 m-0 ">
                                <i className="fal fa-tractor me-1"></i>Farm Name
                              </p>

                              <h6 className="card-title font-size-17  p-0  m-0 mt-1">
                                {detail && detail.name ? detail.name : ''}
                              </h6>
                            </div>

                            <div className="">
                              <p className="card-text p-0 m-0 ">
                                <i className="fal fa-id-badge me-1"></i>{' '}
                                Farmsured Package
                              </p>

                              <h6 className="card-title font-size-17  p-0  m-0 mt-1">
                                {detail &&
                                  detail.packageId &&
                                  detail.packageId.packageName
                                  ? detail.packageId.packageName
                                  : 'Package'}
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-8">
                            <div className="card mt-1">
                              <div className="card-body">
                                <div className="d-flex">
                                  <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">
                                      Start Date
                                    </p>
                                    <h5 className="mb-0">
                                      {' '}
                                      {detail && detail.startDate
                                        ? moment(detail.startDate).format(
                                          'DD MMM YYYY',
                                        )
                                        : moment().format('DD MMM YYYY')}
                                    </h5>
                                  </div>
                                  <div className="text-primary ms-auto">
                                    <i className="fal fa-calendar-day font-size-24"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-body">
                                <div className="d-flex">
                                  <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">
                                      End Date
                                    </p>
                                    <h5 className="mb-0">
                                      {detail && detail.endDate
                                        ? moment(detail.endDate).format(
                                          'DD MMM YYYY',
                                        )
                                        : moment().format('DD MMM YYYY')}
                                    </h5>
                                  </div>
                                  <div className="text-primary ms-auto">
                                    <i className="fal fa-calendar-day font-size-24"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="card"   >
                              <div className="card-body">
                                <div className="item-1">
                                  <p className=" mt-3">
                                    <i className="fal fa-calendar-day"></i>
                                  </p>
                                  <p className="m-0 mt-1 mb-2">
                                    Production Size
                                  </p>
                                  <p className="item-2 font-size-18 pb-4">
                                    <b>
                                      {`${productionSize ? productionSize : '1'
                                        } ${detail &&
                                          detail.packageId &&
                                          detail.packageId.packageMinUnit &&
                                          detail.packageId.packageMinUnit
                                            .measuringUnit
                                          ? detail.packageId.packageMinUnit
                                            .measuringUnit
                                          : 'unit'
                                        }`}
                                    </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-2">
                        <div className="card">
                          <div className="card-body">
                            <div className="item-1">
                              <p className="">
                                <i className="fal fa-calendar-day"></i>
                              </p>
                              <p className="m-0">Days</p>
                              <p className="item-2">
                                {detail && detail.startDate
                                  ? numberOfDays(detail.startDate)
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="card">
                          <div className="card-body">
                            <div className="float-end">
                              {typeof data.main != 'undefined' ? (
                                <button
                                  className="btn btn-sm btn-warning"
                                  onClick={() => handleAnalysis(data)}
                                >
                                  Analyse
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-warning"
                                  disabled
                                >
                                  Analyse
                                </button>
                              )}
                            </div>
                            <p className="card-title mb-2">
                              Effect of today weather on your farm
                            </p>
                            <div className="row">
                              <div className="col-lg-6">
                                <span className="font-size-18">
                                  <b
                                    className="card-title"
                                    style={{
                                      color: 'green',
                                      fontSize: '21px',
                                      fontWeight: '700',
                                    }}
                                  >
                                    {moment().format('dddd')},
                                  </b>{' '}
                                  <span className="font-size-14">
                                    {moment().format('Do MMMM YYYY')}
                                  </span>
                                </span>
                                <div className="">
                                  {typeof data.main != 'undefined' ? (
                                    <ul className="weather-detail">
                                      <li
                                        className=""
                                        style={{ marginRight: '15px' }}
                                      >
                                        <p>
                                          <i className="fal fa-humidity me-1 font-size-20"></i>
                                          {data.main.humidity}
                                        </p>
                                      </li>
                                      <li style={{ marginRight: '15px' }}>
                                        <p>
                                          <i className="fal fa-wind me-1 font-size-20"></i>
                                          {`${data.wind.speed}m/s`}
                                        </p>
                                      </li>
                                      <li>
                                        <p>
                                          <i className="fad fa-water-lower me-1 font-size-20"></i>
                                          {`${data.main.pressure}hPa`}
                                        </p>
                                      </li>
                                    </ul>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="">
                                  {typeof data.main != 'undefined' ? (
                                    <ul className="temp-detail">
                                      <li className="weather-icon">
                                        <img
                                          src={`${process.env.REACT_APP_ICON_URL}/${data.weather[0]['icon']}.png`}
                                          alt="weather-icon"
                                        />
                                      </li>

                                      <li>
                                        <h5
                                          className=""
                                          style={{
                                            fontSize: '50px',
                                            fontWeight: '700',
                                          }}
                                        >
                                          {Math.ceil(data.main.temp)} &#176;C
                                        </h5>
                                        <p className="center m-0">{time}</p>
                                      </li>
                                    </ul>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="card mb-0">
                          <div className="card-body">
                            <h6 className="card-title mb-1">
                              <i className="fal fa-coins me-2"></i>
                              Progress Report
                            </h6>
                            <div className="row">
                              <div className="col-md-4 p-1">
                                <div className="card mb-0 card-border-radius bg-dark text-light">
                                  <div className="card-body">
                                    <div className="item-1">
                                      <p className="m-0">
                                        <i className="fal fa-coins font-size-35"></i>
                                      </p>
                                      <p className="m-0 mb-1">Point</p>
                                      <p className="item-2">
                                        {detail && detail.point
                                          ? detail.point
                                          : 0}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 p-1">
                                <div className="card mb-0 card-border-radius bg-success text-light">
                                  <div className="card-body">
                                    <div className="item-1">
                                      <p className="m-0">
                                        <i className="fal fa-bullseye-arrow"></i>
                                      </p>
                                      <p className="m-0">Expected</p>
                                      <p className="item-2">
                                        {expectedPoint ? expectedPoint : 0}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 p-1">
                                <div className="card mb-0 card-border-radius text-white">
                                  <div className="card-body">
                                    <div className="item-1">
                                      <p className="m-0">
                                        <i className="fal fa-shield-check"></i>
                                      </p>
                                      <p className="m-0">Completed</p>
                                      <p className="item-2">
                                        {detail &&
                                          detail.activities &&
                                          detail.activities.length > 0
                                          ? countActivities(detail.activities)
                                          : 0}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* =====================================
=========Economics of Production=========
======================================*/}
                <section>
                  <div className="card">
                    <div className="card-body">
                      <div className="float-end">
                        <button
                          className="btn btn-sm btn-success"
                          onClick={handleEOPDialog}
                        >
                          View E.O.P
                        </button>
                      </div>
                      <h5 className="card-title mb-4">
                        Enterprise Economics of Production
                      </h5>

                      <div className="row">
                        <div className="col-md-3 mb-2">
                          <div
                            className="card mb-0 m-0 bg-soft-success"
                            style={{
                              borderRadius: '10px',
                            }}
                          >
                            <div className="card-body">
                              <i className="fal fa-cogs text-dark font-size-24 mb-2"></i>
                              <h4 className="text-dark">
                                <b>
                                  {detail && detail.totalSum
                                    ? `NGN ${formatNumber(detail.totalSum)}`
                                    : 'NGN 0'}
                                </b>
                              </h4>
                              <p className="text-dark">
                                Farmsured Enterprise E.O.P
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-2">
                          <div
                            className="card mb-0 m-0"
                            style={{
                              backgroundColor: '#fff3cb',
                              borderRadius: '10px',
                            }}
                          >
                            <div className="card-body">
                              <i className="fal fa-tools text-dark font-size-24 mb-2"></i>
                              <h4 className="text-dark">
                                <b>
                                  {detail && detail.additionalEoPTotalSum
                                    ? `NGN ${formatNumber(
                                      detail.additionalEoPTotalSum,
                                    )}`
                                    : 'NGN 0'}
                                </b>
                              </h4>
                              <p className="text-dark">
                                Additional Enterprise E.O.P{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-2">
                          <div
                            className="card mb-0 m-0"
                            style={{
                              backgroundColor: '#b2e37a',
                              borderRadius: '10px',
                            }}
                          >
                            <div className="card-body">
                              <i className="fal fa-money-bill text-dark font-size-24 mb-2"></i>
                              <h4 className="text-dark">
                                <b>
                                  {detail && detail.profit
                                    ? `NGN ${formatNumber(detail.profit)}`
                                    : 'NGN 0'}
                                </b>
                              </h4>
                              <p className="text-dark">Enterprise Profit</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-2">
                          <div
                            className="card mb-0 m-0"
                            style={{
                              backgroundColor: '#ffeb3b',
                              borderRadius: '10px',
                            }}
                          >
                            <div className="card-body">
                              <i className="fal fa-hand-holding-usd text-dark font-size-24 mb-2"></i>
                              <h4 className="text-dark">
                                <b>
                                  {detail && detail.profitabilityROI
                                    ? `${formatNumber(
                                      detail.profitabilityROI,
                                    )}%`
                                    : '0%'}
                                </b>
                              </h4>
                              <p className="text-dark">
                                Enterprise Profitability R.O.I (%)
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <h5 className="card-title">Benefits</h5>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="card">
                        <div className="card-body reward__container">
                          <img
                            src="assets/images/benefit_icon.png"
                            alt=""
                            style={{ maxWidth: '100%', margin: 'auto' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="card mb-0">
                        <div className="card-body scroll--yaxis">
                          {detail &&
                            detail.packageBenefits &&
                            detail.packageBenefits.length > 0 ? (
                            <div className="cycle__flex__container">
                              {detail.packageBenefits.map((item) => (
                                <div className="card__mouse">
                                  <div className="imgBox">
                                    <i className="fal fa-medkit"></i>
                                  </div>

                                  <div className="contentBox">
                                    <h5 className="card-title text-white">
                                      {item.benefit && item.benefit.benefit
                                        ? item.benefit.benefit
                                        : null}
                                    </h5>
                                    {checkBenefitStatus(
                                      item,
                                      detail.point,
                                      detail.packageNum,
                                      detail.startDate,
                                    ) ? (
                                      <div className="badge bg-success font-size-13 mt-1 mb-1 text-white">
                                        Unlocked
                                      </div>
                                    ) : (
                                      <div className="badge bg-danger font-size-13 mt-2">
                                        Locked
                                      </div>
                                    )}

                                    {checkBenefitStatus(
                                      item,
                                      detail.point,
                                      detail.packageNum,
                                      detail.startDate,
                                    ) ? (
                                      <button
                                        className="buy"
                                        onClick={() =>
                                          handleBenefitDialog(item)
                                        }
                                      >
                                        Claim Benefit
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="empty__card">
                              <div className="">
                                <i className="fal fa-times-circle"></i>
                                <p className="card-desc">No Benefit(s)</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <ReactCardFlip
                        isFlipped={isFlipped}
                        flipDirection="horizontal"
                      >
                        <div className="front">
                          <div className="row">
                            <div className="col-12">
                              <div className="d-sm-flex align-items-center justify-content-between">
                                <h5 className="card-title mb-sm-0 font-size-15">
                                  Farmsured Protocol Activities
                                </h5>

                                <h5 className="card-title mb-sm-0 font-size-15">
                                  Days into farmcycle ={' '}
                                  {detail && detail.startDate
                                    ? `${numberOfDays(detail.startDate)}`
                                    : 0}
                                </h5>

                                <div className="page-title-right">
                                  <button
                                    className="btn btn-info btn-sm me-3"
                                    onClick={() => setViewCycleDetail(true)}
                                  >
                                    Review Cycle Detail
                                  </button>

                                  <button
                                    className="btn btn-success btn-sm me-3"
                                    onClick={() => setViewActivity(true)}
                                  >
                                    View All
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {detail &&
                            detail.activities &&
                            detail.activities.length > 0 ? (
                            <div className="scroll">
                              {filterActivitiesByTime(
                                detail.startDate,
                                detail.activities,
                              ).map((item) => (
                                <div className="flex-contain mb-3">
                                  <div className="item color-bar p-4">
                                    <div className="d-flex">
                                      <div className="avatar-sm me-3">
                                        <span
                                          className={`avatar-title bg-light rounded-circle ${item.compliance
                                            ? 'text-primary'
                                            : 'text-danger'
                                            } font-size-24`}
                                        >
                                          <i
                                            className={`${item.compliance
                                              ? 'ri-checkbox-circle-line m-1'
                                              : 'ri-close-circle-line'
                                              }`}
                                          ></i>
                                        </span>
                                      </div>
                                      <div className="flex-1 align-self-center overflow-hidden">
                                        <h5 className="card-title">
                                          {item.activity}
                                        </h5>
                                        <p className="text-muted mb-0">
                                          {`${item.point} points`}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="item">
                                    <h5 className="card-title">
                                      <b>{item.farmActivity}</b>{' '}
                                    </h5>
                                    <p className="">
                                      <span className="me-2">
                                        <i className="fal fa-hourglass-half font-size-14 me-1"></i>{' '}
                                        {item.timeLineFrom} - {item.timeLineTo}{' '}
                                        Hrs
                                      </span>
                                    </p>
                                  </div>
                                  <div className="item">
                                    <h5 className="card-title mt-2">
                                      <b>
                                        {item.compliance
                                          ? '100% Completed'
                                          : '0% Completed'}
                                      </b>
                                    </h5>
                                    <LinearProgress
                                      variant="determinate"
                                      className="mt-2 p-1"
                                      color="primary"
                                      value={item.compliance ? 100 : 0}
                                    />
                                  </div>
                                  <div className="item">
                                    <div className="">
                                      <button
                                        className="btn btn-success"
                                        onClick={() =>
                                          handleComplianceDialog(item)
                                        }
                                      >
                                        <i className="fal fa-file me-2"></i>{' '}
                                        Details
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>

                        <div className="card">
                          <div className="card-body">
                            {loadingCompliance ? (
                              <div className="tailspin">
                                <TailSpin
                                  stroke="#98ff98"
                                  strokeWidth="3"
                                  color="#06bcee"
                                />
                              </div>
                            ) : (
                              <div>
                                <button
                                  className="btn btn-info btn-sm mb-3"
                                  onClick={() => (
                                    setIsFlipped(false), setComment('')
                                  )}
                                >
                                  Back
                                </button>
                                <div className="row mt-3">
                                  <div className="col-md-5">
                                    <div className="product-img">
                                      {activity === 'Scan Activity' ? (
                                        <div className="card_image_scan">
                                          <div
                                            className=""
                                            style={{ fontSize: '150px' }}
                                          >
                                            <i className="fa fa-qrcode img-fluid mx-auto d-block"></i>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="card_image_record">
                                          <div
                                            className=""
                                            style={{ fontSize: '150px' }}
                                          >
                                            <i className="fa fa-file img-fluid mx-auto d-block"></i>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-7 mt-2">
                                    {compliance ? (
                                      <>
                                        <div>
                                          <i className="me-2">👍</i>
                                          <p
                                            className="badge bg-success font-size-13"
                                            style={{
                                              borderRadius: '100px',
                                              padding: '5px 10px 5px 10px',
                                            }}
                                          >
                                            Complied
                                          </p>
                                          <p
                                            className="badge font-size-13"
                                            style={{
                                              borderRadius: '100px',
                                              padding: '5px 10px 5px 10px',
                                              background: 'green',
                                              color: 'white',
                                              marginLeft: '10px',
                                            }}
                                          >
                                            {moment(date).format(
                                              'Do MMM YYYY, h:mm a',
                                            )}
                                          </p>
                                        </div>
                                      </>
                                    ) : (
                                      <div className="">
                                        <i className="me-2">👎</i>
                                        <p
                                          className="badge bg-danger font-size-13"
                                          style={{
                                            borderRadius: '100px',
                                            padding: '5px 10px 5px 10px',
                                          }}
                                        >
                                          Not Complied
                                        </p>
                                      </div>
                                    )}
                                    <p className="mb-0">
                                      <a href="#" className="text-primary">
                                        {activity}
                                      </a>
                                    </p>

                                    <h4 className="card-title">
                                      <b>{farmActivity}</b>
                                    </h4>

                                    <p>{task}</p>

                                    <ul className="item-list">
                                      {tagColor ? (
                                        <li>
                                          <i className="fal fa-tags  me-1"></i>
                                          {tagColor}
                                        </li>
                                      ) : null}

                                      <li>
                                        <i className="fal fa-hourglass-half me-1"></i>{' '}
                                        {`${timeLineFrom} - ${timeLineTo} Hours`}
                                      </li>

                                      <li>
                                        <i className="fal fa-clock me-1"></i>
                                        {point} points
                                      </li>
                                    </ul>

                                    <div className="d-grid">
                                      <button
                                        className="btn btn-success"
                                        onClick={handleLearnMore}
                                      >
                                        Learn More About This Activity
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <hr className="my-4"></hr>

                                <div className="col-lg-12">
                                  {activity === 'Record Activity' &&
                                    compliance ? (
                                    <div className="card comment--card text-dark">
                                      <div className="card-body">
                                        <p className="card-title">
                                          <b>Activity Comment:</b>
                                        </p>
                                        <div className="card-desc">
                                          {parse(comment)}
                                        </div>
                                      </div>
                                    </div>
                                  ) : activity === 'Record Activity' ? (
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        forHtml="basicpill-address-input"
                                      >
                                        Comment
                                      </label>
                                      <Editor
                                        onInit={(evt, editor) =>
                                          (editorRef.current = editor)
                                        }
                                        initialValue=""
                                        init={{
                                          height: 200,
                                          menubar: false,
                                          plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount',
                                          ],
                                          toolbar:
                                            'undo redo | formatselect | ' +
                                            'bold italic backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                          content_style:
                                            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                  <div className="row mt-3">
                                    {activity === 'Scan Activity' ? (
                                      <div className="col-md-12  mb-1">
                                        <div className="d-grid">
                                          <button
                                            className="btn btn-warning btn-lg"
                                            onClick={() => setOpen(true)}
                                            disabled={compliance ? true : false}
                                          >
                                            Validate Tag
                                          </button>
                                        </div>
                                      </div>
                                    ) : null}

                                    {activity === 'Record Activity' &&
                                      !compliance ? (
                                      <div className="col-md-12 mb-1">
                                        <div className="d-grid">
                                          <button
                                            className="btn btn-success btn-lg"
                                            onClick={handleSave}
                                            disabled={compliance ? true : false}
                                          >
                                            Comply
                                          </button>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="mt-5">
                              <h5 className="card-title">
                                Activity Tutorial Videos{' '}
                              </h5>
                              <OwlCarousel
                                className="owl-theme"
                                loop
                                margin={10}
                                nav={true}
                                navElement
                                dots
                                dotsEach
                              >
                                {/* <div className="card">
                                <div className="card-body">
                                  <div className="ratio ratio-21x9">
                                    <iframe
                                      title="YouToube Video"
                                      src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                                      allowFullScreen
                                    ></iframe>
                                  </div>

                                  <h4 className="card-title mt-3">
                                    Ratio video 21:9
                                  </h4>
                                  <p className="card-title-desc">
                                    Aspect ratios can be customized with
                                    modifier classes.
                                  </p>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-body">
                                  <div className="ratio ratio-21x9">
                                    <iframe
                                      title="YouToube Video"
                                      src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                                      allowFullScreen
                                    ></iframe>
                                  </div>

                                  <h4 className="card-title mt-3">
                                    Ratio video 21:9
                                  </h4>
                                  <p className="card-title-desc">
                                    Aspect ratios can be customized with
                                    modifier classes.
                                  </p>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-body">
                                  <div className="ratio ratio-21x9">
                                    <iframe
                                      title="YouToube Video"
                                      src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                                      allowFullScreen
                                    ></iframe>
                                  </div>

                                  <h4 className="card-title mt-3">
                                    Ratio video 21:9
                                  </h4>
                                  <p className="card-title-desc">
                                    Aspect ratios can be customized with
                                    modifier classes.
                                  </p>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-body">
                                  <div className="ratio ratio-21x9">
                                    <iframe
                                      title="YouToube Video"
                                      src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                                      allowFullScreen
                                    ></iframe>
                                  </div>

                                  <h4 className="card-title">
                                    Ratio video 21:9
                                  </h4>
                                  <p className="card-title-desc">
                                    Aspect ratios can be customized with
                                    modifier classes.
                                  </p>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-body">
                                  <div className="ratio ratio-21x9">
                                    <iframe
                                      title="YouToube Video"
                                      src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                                      allowFullScreen
                                    ></iframe>
                                  </div>

                                  <h4 className="card-title">
                                    Ratio video 21:9
                                  </h4>
                                  <p className="card-title-desc">
                                    Aspect ratios can be customized with
                                    modifier classes.
                                  </p>
                                </div>
                              </div> */}
                              </OwlCarousel>
                            </div>
                          </div>
                        </div>
                      </ReactCardFlip>
                    </div>

                    <div className="col-md-4">
                      <h5 className="card-title mb-2">Other farm cycles</h5>
                      {loadingCycles ? (
                        <div className="tail-spin-cycle">
                          <TailSpin
                            stroke="#98ff98"
                            strokeWidth="3"
                            color="#06bcee"
                          />
                        </div>
                      ) : (
                        <div className="scroll">
                          {cycles && cycles.length === 0 ? (
                            <div className="empty-cycle-container">
                              <h5 className="card-title">No Cycles</h5>
                            </div>
                          ) : (
                            cycles.map((item) => (
                              <div className="course mb-3" key={item._id}>
                                <div className="course-preview">
                                  <h6 className="card-title text-white">
                                    {item.name}
                                  </h6>
                                  <h2 className="text-white">{`${item.point} points`}</h2>
                                </div>
                                <div className="course-info">
                                  <div className="progress-container">
                                    <div className="">
                                      <LinearProgress
                                        variant="determinate"
                                        className=""
                                        color="primary"
                                        value={
                                          item.point &&
                                            item.productCategory &&
                                            item.productCategory.expectedPoint
                                            ? (item.point /
                                              item.productCategory
                                                .expectedPoint) *
                                            100
                                            : 0
                                        }
                                      />
                                    </div>
                                  </div>
                                  <h6>
                                    {moment(item.startDate).format(
                                      'DD MMM YYYY',
                                    )}
                                  </h6>
                                  <h2>
                                    {item.packageId &&
                                      item.packageId.packageName
                                      ? item.packageId.packageName
                                      : ''}
                                  </h2>

                                  {checkDate(item.startDate) ? (
                                    <button
                                      className="btn btn-warning btn-sm cycle-btn"
                                      disabled
                                    >
                                      Yet to Start
                                    </button>
                                  ) : (
                                    <a
                                      href={`/user/farm-cycle/detail/${item._id}`}
                                      className="btn cycle-btn"
                                    >
                                      Details
                                    </a>
                                  )}
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </>
            )}

            <section>
              {loadingScanActivities ? (
                <div className="tailspin">
                  <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
                </div>
              ) : (
                <div className="row mt-4">
                  <div className="col-md-12">
                    <h5 className="card-title mb-2">Scanned Activities</h5>
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive-xl">
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 20, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
      <section>
        {/* QR CODE SCAN DIALOG START */}
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={'lg'}
        >
          <DialogContent>
            {codeViewOpen ? (
              <>
                {loadingScan ? (
                  <div className="tailspin">
                    <TailSpin
                      stroke="#98ff98"
                      strokeWidth="3"
                      color="#06bcee"
                    />
                  </div>
                ) : (
                  <center>
                    <img
                      src="assets/images/farmsured!.png"
                      alt="logo-dark"
                      height="60"
                      className="scanner-logo mb-1"
                    />

                    <p className="mt-4">
                      Ensure your QRcode surface is clear and point your camera
                      to focus on the QRCODE and <br /> wait for product
                      validation in less than 5 seconds or more{' '}
                    </p>

                    <QrReader
                      className="scanner-container"
                      onResult={(result, error) => {
                        if (!!result) {
                          dispatch(
                            verifyQRCode(
                              activityId,
                              itemId,
                              result?.text,
                              geolocation,
                            ),
                          )
                        }

                        if (!!error) {
                          // console.info(error)
                        }
                      }}
                      faceMode={'environment'}
                      containerStyle={{
                        maxWidth: '500px',
                        height: '80%',
                        paddingTop: '90%',
                      }}
                    />
                    <div className="m-0">
                      <p>OR</p>

                      <button
                        className="btn btn-warning btn-bg text-danger"
                        onClick={() => setCodeViewOpen(!codeViewOpen)}
                      >
                        SWITCH TO PIN CODE
                      </button>
                    </div>
                  </center>
                )}
                <DialogActions>
                  <button
                    type="button"
                    className="btn btn-light btn-sm waves-effect"
                    data-bs-dismiss="modal"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                </DialogActions>
              </>
            ) : (
              <>
                <form className="form" onSubmit={handleScanSubmit}>
                  <div
                    id="container"
                    style={{
                      minHeight: '75vh',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div className="text-center">
                      <p className="font-size-12">
                        Select the tag and input the pin code
                      </p>
                      <div
                        className="row"
                        onChange={(e) => setColor(e.target.value)}
                        required
                      >
                        <div className="col-lg-6">
                          <div>
                            <label className="form-label card-radio-label mb-3">
                              <input
                                type="radio"
                                name="pay-method"
                                id="pay-methodoption3"
                                className="card-radio-input"
                                value="red"
                              />

                              <img
                                src="assets/images/red_tag.png"
                                alt="red tag"
                                className="card-radio me-1"
                                style={{ width: ' 200px' }}
                              />
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div>
                            <label className="form-label card-radio-label mb-3">
                              <input
                                type="radio"
                                name="pay-method"
                                id="pay-methodoption1"
                                className="card-radio-input"
                                value="green"
                              />
                              <img
                                src="assets/images/green_tag.png"
                                className="card-radio me-1"
                                alt="green tag"
                                style={{ width: ' 200px' }}
                              />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="input-container mt-3">
                        <div className="container__item">
                          <input
                            type="number"
                            className="form-control form__field"
                            placeholder="Input pin number"
                            onChange={(e) => setTag(e.target.value)}
                            required
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn--primary mt-3 btn-block uppercase"
                        >
                          Validate
                        </button>
                      </div>
                      <div className="mt-3">
                        <p>OR</p>

                        <button
                          className="btn btn-warning btn-bg text-dark"
                          onClick={() => setCodeViewOpen(true)}
                        >
                          VALIDATE BY QRCODE
                        </button>
                      </div>
                    </div>
                  </div>
                  <DialogActions>
                    <button
                      type="button"
                      className="btn btn-light btn-sm waves-effect"
                      data-bs-dismiss="modal"
                      onClick={() => setOpen(false)}
                    >
                      Close
                    </button>
                  </DialogActions>
                </form>
              </>
            )}
          </DialogContent>
        </Dialog>
        {/* QR CODE SCAN DIALOG ENDS */}
      </section>

      <section>
        {/* VIEW ALL ACTIVITIES DIALOG START */}
        <Dialog
          open={viewActivity}
          onClose={() => setViewActivity(false)}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={'lg'}
        >
          <div className="modal-header">
            <h5 className="modal-title">Farm Cycle Activities</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setViewActivity(false)}
            ></button>
          </div>
          <DialogContent>
            <div className="row">
              {detail && detail.activities && detail.activities.length > 0
                ? detail.activities.map((item) => (
                  <div className="col-md-4">
                    <div className="container-card p-2">
                      <div className="row mt-2">
                        <div className="col-md-7">
                          <div className="container__text">
                            <h1 className="card-title">
                              {item.farmActivity}
                            </h1>

                            <p>
                              <span className="expected_task">
                                <b>Expected Task:</b>
                              </span>
                              <br />
                              {item.expectedTask}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-5 red">
                          {item.activity ? (
                            <div
                              className=""
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              {item.tagColor ? (
                                <div className="pt-1 container__text__timing_time font-size-12">
                                  {item.tagColor}
                                </div>
                              ) : null}
                              <div className="icon_container align">
                                {item.activity === 'Scan Activity' ? (
                                  <div
                                    style={{
                                      fontSize: '50px',
                                    }}
                                  >
                                    <i className="fa fa-qrcode"></i>
                                  </div>
                                ) : (
                                  <div
                                    className="mt-1"
                                    style={{
                                      fontSize: '50px',
                                    }}
                                  >
                                    <i className="fa fa-file"></i>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="container__text">
                        <div className="">
                          <div className="row">
                            <div className="col-lg-5">
                              <div className=" mr-2">
                                <h6>Timeline</h6>
                                <p>
                                  {item.timeLineFrom} - {item.timeLineTo} hrs
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <h6>Point</h6>
                              <p>{`${item.point} points`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : null}
            </div>
          </DialogContent>
        </Dialog>
        {/* VIEW ALL ACTIVITIES DIALOG ENDS */}
      </section>

      {/* ==========================================================
      ==============================================================
      =========== Product Validation Dialog Start Here =============
      ==============================================================
      =============================================================*/}
      {loadingVerify ? (
        <LoadingBox />
      ) : ErrScan ? (
        <div>
          <Modal
            isOpen
            onRequestClose={() => setIsProductDialogOpen(false ? true : false)}
            style={customStyles}
          >
            <div
              className="modal-header-false"
              style={{
                background: '#d13e3e',
                borderBottom: 'none',
                position: 'relative',
                textAlign: 'center',
                margin: '-20px -20px 0',
                borderRadius: '5px 5px 0 0',
                padding: '35px',
              }}
            >
              <div
                className="icon-box"
                style={{
                  color: '#fff',
                  textAlign: 'center',
                }}
              >
                <center>
                  <i
                    className="font-circle fa fa-times"
                    style={{
                      borderRadius: '60%',
                      display: 'inline-block',
                      zIndex: 9,
                      border: '5px solid #fff',
                      padding: '20px 30px 20px 30px',
                      fontSize: '70px',
                      margin: '-5px 0 0 -5px',
                      textAlign: 'center',
                    }}
                  ></i>
                </center>
              </div>
            </div>
            <div className="modal-body text-center">
              <p className="model-text">
                Hello <br />
              </p>
              <h4
                style={{
                  textAlign: 'center',
                  fontSize: '16.5px',
                  margin: '10px 0',
                }}
              >
                TRY AGAIN
              </h4>
              <h6
                style={{
                  textAlign: 'center',
                  fontSize: '16px',
                  margin: '10px 0',
                }}
              >
                {ErrScan}
              </h6>
              <br />
              <button
                className="btn btn-success"
                onClick={resetScanError}
                style={{
                  color: '#fff',
                  textDecoration: 'none',
                  transition: 'all 0.4s',
                  lineHeight: 'normal',
                  borderRadius: '30px',
                  marginTop: '10px',
                  padding: '6px 20px 10px ',
                  border: 'none',
                }}
              >
                Close
              </button>
            </div>
          </Modal>
        </div>
      ) : scan ? (
        <div>
          <Modal
            isOpen
            onRequestClose={() => setIsOpen(false)}
            style={customStyles}
            contentLabel="Modal"
          >
            <div
              className="modal-header-false"
              style={{
                background: '#47c9a2',
                borderBottom: 'none',
                position: 'relative',
                textAlign: 'center',
                margin: '-20px -20px 0',
                borderRadius: '5px 5px 0 0',
                padding: '35px',
              }}
            >
              <div
                className="icon-box"
                style={{
                  color: '#fff',
                  textAlign: 'center',
                }}
              >
                <center>
                  <i
                    className="font-circle fa fa-check"
                    style={{
                      borderRadius: '60%',
                      display: 'inline-block',
                      zIndex: 9,
                      border: '5px solid #fff',
                      padding: '20px 20px 20px 20px',
                      fontSize: '70px',
                      margin: '-5px 0 0 -5px',
                      textAlign: 'center',
                    }}
                  ></i>
                </center>
              </div>
            </div>
            <div className="modal-body text-center">
              <p className="model-text">
                Hello {userInfo.firstName} <br />
              </p>
              <h4
                style={{
                  textAlign: 'center',
                  fontSize: '18px',
                  margin: '10px 0',
                }}
              >
                {scan && scan.message ? scan.message : null}
              </h4>
              <button
                className="btn btn-success"
                onClick={resetHandler}
                style={{
                  color: '#fff',
                  textDecoration: 'none',
                  transition: 'all 0.4s',
                  lineHeight: 'normal',
                  borderRadius: '30px',
                  marginTop: '10px',
                  padding: '6px 20px 10px ',
                  border: 'none',
                }}
              >
                Close
              </button>
            </div>
          </Modal>
        </div>
      ) : null}
      {/* Product Validation Dialog End */}

      {/* ==========================================================
      ==============================================================
      ============= Benefits Dialog Start Here =====================
      ==============================================================
      =============================================================*/}
      <Dialog
        open={benefitDialog}
        onClose={() => setBenefitDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <>
          <div className="modal-header">
            <h5 className="modal-title">Benefit Package</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setBenefitDialog(false)}
            ></button>
          </div>

          <DialogContent>
            <div className="row">
              <div className="col-12">
                <div className="mb-2">
                  <label className="col-md-12 col-form-label">
                    Farmer's ID
                  </label>
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="User Phone Number"
                      id="example-text-input"
                      disabled
                      value={user && user.phone ? user.phone : null}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className=" mb-2">
                  <label className="col-md-12 col-form-label">
                    Product Category
                  </label>
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Product Category"
                      id="example-text-input"
                      disabled
                      value={
                        detail &&
                        detail.productCategory &&
                        detail.productCategory.name
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className=" mb-2">
                  <label className="col-md-12 col-form-label">
                    Benefit Name
                  </label>
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Benefit Name"
                      id="example-text-input"
                      disabled
                      value={benefitName}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-2">
                  <label className="col-md-12 col-form-label">
                    Benefit Category
                  </label>
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Benefit"
                      id="example-text-input"
                      disabled
                      value={benefitCategory}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-2">
                  <label className="col-md-12 col-form-label">State</label>
                  <div className="col-md-12">
                    <select
                      className="form-select form-control select2"
                      required
                      placeholder="Select a state"
                      onChange={(e) => setCurrentState(e.target.value)}
                    >
                      <option>Select a state</option>
                      {states.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className=" mb-2">
                  <label className="col-md-12 col-form-label">
                    Local Government Area
                  </label>
                  <div className="col-md-12">
                    <select
                      className="form-select form-control select2"
                      required
                      name="lga"
                      placeholder="Select a local government area"
                      onChange={(e) => (
                        setLga(e.target.value),
                        dispatch(
                          getServiceProviders(
                            benefitCategory,
                            currentState,
                            e.target.value,
                          ),
                        )
                      )}
                    >
                      {currentState && currentState === 'Select a state' ? (
                        <option>Select a local government area</option>
                      ) : currentState ? (
                        <>
                          <option>Select a local government area</option>
                          {NaijaStates.lgas(currentState).lgas.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </>
                      ) : (
                        <option>Select a local government area</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className=" mb-2">
                  <label className="col-md-12 col-form-label">
                    Service Provider
                  </label>
                  <div className="col-md-12">
                    <select
                      className="form-select form-control select2"
                      required
                      name="lga"
                      placeholder="Select a local government area"
                      onChange={(e) => setServiceProvider(e.target.value)}
                    >
                      {serviceLoading ? (
                        'Loading'
                      ) : listService ? (
                        <>
                          <option>Select a service provider</option>
                          {services.map((item) => (
                            <option value={item._id} key={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </>
                      ) : (
                        <option>Select a service provider</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              {show ? (
                <div>
                  <>
                    <div>
                      {SPLocation && SPLocation.length > 0 ? (
                        <>
                          <div className="card">
                            <div className="card-body ">
                              {SPLocation.map((item) => (
                                <>
                                  <h5 className="card-title">
                                    <b>{item.name}</b>
                                  </h5>
                                  <p className="m-0">
                                    <b>Phone:</b> {item.phone}
                                  </p>
                                  <p className="m-0">
                                    <b>Email:</b> {item.email}
                                  </p>
                                  <p className="m-0">
                                    <b>Address:</b> {item.address}
                                  </p>
                                  <p className="m-0">
                                    <b>Local Government Area:</b> {item.lga}
                                  </p>
                                  <p className="m-0">
                                    <b>State:</b> {item.state}
                                  </p>

                                  {item.locations &&
                                    item.locations.length > 0 ? (
                                    <div>
                                      <p className="m-0 mt-2">
                                        <b>Other Branches</b>
                                      </p>
                                      {item.locations.map((itm) => (
                                        <div className="m-2">
                                          <div className="mt-0">
                                            <b>Local Government Area: </b>
                                            {itm.lga}
                                          </div>
                                          <div className="">
                                            <b>Address: </b>
                                            {itm.details}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : null}

                                  <div>
                                    <button
                                      className="btn btn-sm btn-primary float-end me-2"
                                      onClick={() => (
                                        handleMailOpen(), setEmail(item.email)
                                      )}
                                    >
                                      <i className="fa fa-envelope me-1"></i>
                                      Email
                                    </button>
                                    <a
                                      href={`tel:+234${item.phone}`}
                                      className="btn btn-sm btn-success float-end me-2"
                                    >
                                      <i className="fa fa-phone-alt"></i> Call
                                    </a>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          <p className="bagde">
                            There are no service provider around this local
                            government. Kindly select the nearest local
                            government area
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                </div>
              ) : null}
            </div>
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              className="btn btn-light btn-sm waves-effect"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Close
            </button>
          </DialogActions>
        </>
      </Dialog>

      {/* ==========================================================
      ==============================================================
      =============== Mail Dialog Start Here =======================
      ==============================================================
      =============================================================*/}
      {mailOpen ? (
        <Dialog
          open={mailOpen}
          onClose={onCloseMail}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <>
            <div className="modal-header">
              <h5 className="modal-title">Claim Beneift</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onCloseMail}
              ></button>
            </div>

            <DialogContent>
              {claim ? (
                <MessageBox variant="success">{claim.message}</MessageBox>
              ) : null}
              {claimError ? (
                <MessageBox variant="danger">{claimError}</MessageBox>
              ) : null}

              <form onSubmit={handleClaimSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mt-3">
                      <input
                        className="form-control "
                        type="text"
                        placeholder="To"
                        id="example-text-input"
                        value={`${email} <${email}>`}
                        required
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mt-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="CC"
                        id="example-text-input"
                        required
                        value={'admin <farmsured>'}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mt-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Subject"
                        id="example-text-input"
                        value={benefitName}
                        required
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mt-3">
                      <label htmlFor="">
                        A well detailed explanation of what happened
                      </label>
                      <Editor
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue=""
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                          ],
                          toolbar:
                            'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                          content_style:
                            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    className="btn btn-secondary waves-effect"
                    data-bs-dismiss="modal"
                    onClick={onCloseMail}
                  >
                    Close
                  </button>
                  <button className="btn btn-success" type="submit">
                    Submit<i className="fab fa-telegram-plane ms-1"></i>
                  </button>
                </DialogActions>
              </form>
            </DialogContent>
          </>
        </Dialog>
      ) : null}

      {/* ==========================================================
      ==============================================================
      ============= Weather Analysis Dialog Box ====================
      ==============================================================
      =============================================================*/}
      <Dialog
        open={analysisDialog}
        onClose={closeAnalysis}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <>
          <div className="modal-header">
            <h5 className="modal-title">Learn More about Activity</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeAnalysis}
            ></button>
          </div>

          <DialogContent>
            {loadingAnalyses ? (
              <div className="tailspin">
                <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
              </div>
            ) : analysesError ? (
              <MessageBox variant="danger"> {analysesError} </MessageBox>
            ) : (
              <AnalysisTyping text={analyses} />
            )}

            <DialogActions>
              <button className="btn btn-md btn-info" onClick={closeAnalysis}>
                Close
              </button>
            </DialogActions>
          </DialogContent>
        </>
      </Dialog>

      {/* ==========================================================
      ==============================================================
      ============= Learn More Dialog Box Start Here ===============
      ==============================================================
      =============================================================*/}
      <Dialog
        open={learnMoreDialog}
        onClose={closeLearnMore}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <>
          <div className="modal-header">
            <h5 className="modal-title">Learn More About Activity</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeLearnMore}
            ></button>
          </div>

          <DialogContent>
            {loadingReadMore ? (
              <div className="tailspin">
                <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
              </div>
            ) : readMoreError ? (
              <MessageBox variant="danger"> {readMoreError} </MessageBox>
            ) : (
              <div className="">
                {readMore && <AnalysisTyping text={readMore} />}
              </div>
            )}

            <DialogActions>
              <button className="btn btn-md btn-info" onClick={closeLearnMore}>
                Close
              </button>
            </DialogActions>
          </DialogContent>
        </>
      </Dialog>

      {/* ==========================================================
      ==============================================================
      ============== Review Cycle Details Starts Here ==============
      ==============================================================
      =============================================================*/}
      <Dialog
        open={viewCycleDetail}
        onClose={() => setViewCycleDetail(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <>
          <div className="modal-header">
            <h5 className="modal-title">Review Farm Cycle Detail</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setViewCycleDetail(false)}
            ></button>
          </div>

          <DialogContent>
            {loading ? (
              <div className="tailspin">
                <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
              </div>
            ) : error ? (
              <MessageBox variant="danger"> {error} </MessageBox>
            ) : (
              <div className="">
                {detail && detail.aiReview && (
                  <AnalysisTyping text={detail.aiReview} />
                )}
              </div>
            )}

            <DialogActions>
              <button
                className="btn btn-md btn-info"
                onClick={() => setViewCycleDetail(false)}
              >
                Close
              </button>
            </DialogActions>
          </DialogContent>
        </>
      </Dialog>

      {/* ==========================================================
      ==============================================================
      ============= Enterprise EOP Dialog Starts Here ==============
      ==============================================================
      =============================================================*/}
      <Dialog
        open={showEOPDialog}
        onClose={() => setShowEOPDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xl"
      >
        <>
          <div className="modal-header">
            <h5 className="modal-title">
              Enterprise Economics of Production Detail
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowEOPDialog(false)}
            ></button>
          </div>

          <DialogContent>
            {loadingUpdate ? (
              <div className="tailspin" style={{ height: 'calc(100vh - 100px)' }}>
                <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
              </div>
            ) : (
              <div className="">
                {/* Standard Economics of Production */}
                <div className="card mt-5">
                  <div
                    className="card-body"
                    style={{
                      border: '1px solid #0d89c7',
                      borderRadius: '10px',
                    }}
                  >
                    <div className="standard-body">
                      Farmsured Standard Enterprise Economics Of Production
                    </div>

                    <section>
                      <div className="row mt-4">
                        {/* First Column */}
                        <div className="col-md-4">
                          <div
                            className="card"
                            style={{
                              backgroundColor: '#ff5722',
                              borderRadius: '10px',
                            }}
                          >
                            <div className="card-body">
                              <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                  <p className="text-white font-size-14 mb-2">
                                    Enterprise Name
                                  </p>
                                  <h5 className="mb-0 text-white">
                                    {detail &&
                                      detail.packageId &&
                                      detail.packageId.enterprise &&
                                      detail.packageId.enterprise.name
                                      ? detail.packageId.enterprise.name
                                      : 'Enterprise'}
                                  </h5>
                                </div>
                                <div className="text-white ms-auto">
                                  <i className="fal fa-building font-size-24"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Second Column */}
                        <div className="col-md-4">
                          <div
                            className="card"
                            style={{
                              backgroundColor: '#8bc34a',
                              borderRadius: '10px',
                            }}
                          >
                            <div className="card-body">
                              <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                  <p className="text-white font-size-14 mb-2">
                                    Package Name
                                  </p>
                                  <h5 className="mb-0 text-white">
                                    {detail &&
                                      detail.packageId &&
                                      detail.packageId.packageName
                                      ? detail.packageId.packageName
                                      : 'Package'}
                                  </h5>
                                </div>
                                <div className="text-white ms-auto">
                                  <i className="fal fa-tractor font-size-22"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Third Column */}
                        <div className="col-md-4">
                          <div
                            className="card"
                            style={{
                              backgroundColor: '#009688',
                              borderRadius: '10px',
                            }}
                          >
                            <div className="card-body">
                              <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                  <p className="text-white font-size-14 mb-2">
                                    Production Size
                                  </p>
                                  <h5 className="mb-0 text-white">
                                    {`${productionSize ? productionSize : '1'
                                      } ${detail &&
                                        detail.packageId &&
                                        detail.packageId.packageMinUnit &&
                                        detail.packageId.packageMinUnit
                                          .measuringUnit
                                        ? detail.packageId.packageMinUnit
                                          .measuringUnit
                                        : 'unit'
                                      }`}
                                  </h5>
                                </div>
                                <div className="text-white ms-auto">
                                  <i className="fal fa-building font-size-24"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <div className="">
                      <div className="table-responsive">
                        <table className="table table-centered mb-0 table-nowrap">
                          <thead className="bg-light">
                            <tr>
                              <th>Item</th>
                              <th className="text-center">Quantity</th>
                              <th>Unit of Measurement</th>
                              <th>Product</th>
                              <th className="text-center">Price</th>
                              <th>Note</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {eop.length > 0 ? (
                              <>
                                {eop.map((item, i) => (
                                  <tr>
                                    <td>{item.EOPItem}</td>
                                    <td className="text-center">
                                      {item.quantity}
                                    </td>
                                    <td>{item.UoM}</td>
                                    <td
                                      className="text-center"
                                      style={{
                                        width: '300px',
                                      }}
                                    >
                                      <select
                                        id="product"
                                        name="product"
                                        className="form-control form-select select2"
                                        required
                                        onChange={(e) =>
                                          handleinputchange(
                                            {
                                              name: e.target.name,
                                              value: e.target.value,
                                            },
                                            i,
                                          )
                                        }
                                        value={item.selectedProduct}
                                      >
                                        {item &&
                                          item.product &&
                                          item.product.length === 0 ? (
                                          <>
                                            <option>Select a product</option>
                                          </>
                                        ) : (
                                          <>
                                            <option value="">
                                              Select a product
                                            </option>
                                            {item &&
                                              item.product &&
                                              item.product.map((item) => (
                                                <option
                                                  value={item._id}
                                                  key={item._id}
                                                  disabled={!item.price}
                                                >
                                                  {item.name}
                                                </option>
                                              ))}
                                          </>
                                        )}
                                      </select>
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{
                                        width: '200px',
                                      }}
                                    >
                                      {item.price
                                        ? `NGN ${formatNumber(item.price)}`
                                        : null}
                                    </td>
                                    <td>{item.notes}</td>
                                    <td
                                      className=""
                                      style={{
                                        width: '200px',
                                      }}
                                    >
                                      {item.total
                                        ? `NGN ${formatNumber(item.total)}`
                                        : null}
                                    </td>
                                  </tr>
                                ))}
                                <tr className="bg-light">
                                  <th
                                    scope="row"
                                    colSpan="6"
                                    className="text-end"
                                  >
                                    Total :
                                  </th>
                                  <td className="bg-light text-center">
                                    <b>
                                      {totalSum
                                        ? `NGN ${formatNumber(totalSum)}`
                                        : 'NGN 0'}
                                    </b>
                                  </td>
                                </tr>
                              </>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3">
                    <div className="card">
                      <div className="card-body bg-soft-primary">
                        <p className="m-0">
                          <b>
                            Explore and enhance your Economics of Production
                            within our web app! You're invited to seamlessly
                            incorporate additional elements to optimize your
                            enterprise's production economics.
                          </b>
                        </p>

                        <p className="mt-2 mb-0">
                          <b>
                            Start enhancing your production economics by
                            integrating supplementary Economics of Production
                            components.
                          </b>
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-12 col-form-label"
                      >
                        Add any other Economics of Production to this
                        Enterprise:
                      </label>
                      {list.map((item, i) => {
                        return (
                          <div key={i}>
                            <div className="row">
                              <div className="form-group col-md-12 mb-2">
                                <div className="card bg-soft-info">
                                  <div className="card-body p-2">
                                    <p className="m-0 p-1">Item {i + 1}</p>
                                  </div>
                                </div>
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-12 col-form-label"
                                >
                                  EOP Item/Activity
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="EOPItem"
                                  placeholder="EOP Item/Activity"
                                  id="example-text-input"
                                  onChange={(e) =>
                                    handleFormChange(
                                      {
                                        name: e.target.name,
                                        value: e.target.value,
                                      },
                                      i,
                                    )
                                  }
                                  required
                                  value={item.EOPItem}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-6 mb-2">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-12 col-form-label"
                                >
                                  Quantity
                                </label>
                                <input
                                  className="form-control"
                                  name="quantity"
                                  type="number"
                                  placeholder="Quantity"
                                  id="example-text-input"
                                  onChange={(e) =>
                                    handleFormChange(
                                      {
                                        name: e.target.name,
                                        value: e.target.value,
                                      },
                                      i,
                                    )
                                  }
                                  value={item.quantity}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6 mb-2">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-12 col-form-label"
                                >
                                  Unit of Measurement
                                </label>
                                <select
                                  className="form-control form-select select2"
                                  name="UoM"
                                  placeholder="Unit of measurement"
                                  id="example-text-input"
                                  onChange={(e) =>
                                    handleFormChange(
                                      {
                                        name: e.target.name,
                                        value: e.target.value,
                                      },
                                      i,
                                    )
                                  }
                                  required
                                  value={item.UoM}
                                >
                                  <option>Select measuring unit</option>
                                  <option value="Birds">Birds</option>
                                  <option value="Box(es)">Box(es)</option>
                                  <option value="Kg">kg</option>
                                  <option value="Units">Units</option>
                                  <option value="Animals">Animals</option>
                                  <option value="Bags">Bags</option>
                                  <option value="Tonnes">Tonnes</option>
                                  <option value="Litres">Litres</option>
                                  <option value="Items">Items</option>
                                  <option value="Hectares">Hectares</option>
                                  <option value="Acre">Acre</option>
                                </select>
                              </div>
                              <div className="form-group col-md-6 mb-2">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-12 col-form-label"
                                >
                                  EOP Item/Activity Type
                                </label>
                                <select
                                  onChange={(e) =>
                                    handleFormChange(
                                      {
                                        name: e.target.name,
                                        value: e.target.value,
                                      },
                                      i,
                                    )
                                  }
                                  className="form-control form-select select-2"
                                  required
                                  name="EOPItemType"
                                  placeholder="Select product/service"
                                  value={item.EOPItemType}
                                >
                                  <option value="">
                                    Select a product/service
                                  </option>
                                  <option value="Product">Product</option>
                                  <option value="Service">Service</option>
                                </select>
                              </div>
                              <div className="form-group col-md-6 mb-2">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-12 col-form-label"
                                >
                                  Price
                                </label>
                                <input
                                  className="form-control"
                                  name="price"
                                  type="number"
                                  placeholder="Price"
                                  id="example-text-input"
                                  onChange={(e) =>
                                    handleFormChange(
                                      {
                                        name: e.target.name,
                                        value: e.target.value,
                                      },
                                      i,
                                    )
                                  }
                                  required
                                  value={item.price}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-9">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-12 col-form-label"
                                >
                                  Notes
                                </label>
                                <textarea
                                  type="text"
                                  name="notes"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleFormChange(
                                      {
                                        name: e.target.name,
                                        value: e.target.value,
                                      },
                                      i,
                                    )
                                  }
                                  placeholder="Notes"
                                  value={item.notes}
                                />
                              </div>

                              <div className="form-group col-md-3 mt-4">
                                {list.length !== 1 && (
                                  <button
                                    className="btn btn-danger mx-1"
                                    type="button"
                                    onClick={() => handleremove(i)}
                                  >
                                    Delete
                                  </button>
                                )}
                                {list.length - 1 === i && (
                                  <button
                                    className="btn btn-success m-2"
                                    onClick={handleAddClick}
                                  >
                                    Save
                                  </button>
                                )}
                              </div>
                              <hr className="mt-4 mb-3 my-4" />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  <div className="col-lg-9">
                    {/* Additional Economics of Production */}
                    <div className="card mt-5">
                      <div
                        className="card-body"
                        style={{
                          border: '1px solid #0d89c7',
                          borderRadius: '10px',
                        }}
                      >
                        <div className="standard-body">
                          Additional Economics Of Production
                        </div>

                        <div className="mt-4">
                          <div className="table-responsive">
                            <table className="table table-centered mb-0 table-nowrap">
                              <thead className="bg-light">
                                <tr>
                                  <th>Item</th>
                                  <th className="text-center">Quantity</th>
                                  <th>Unit of Measurement</th>
                                  <th>E.O.P Item Type</th>
                                  <th className="text-center">Price</th>
                                  <th>Note</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {list.length > 0 ? (
                                  <>
                                    {list.map((item, i) => (
                                      <tr>
                                        <td>{item.EOPItem}</td>
                                        <td className="text-center">
                                          {item.quantity}
                                        </td>
                                        <td>{item.UoM}</td>
                                        <td className="">{item.EOPItemType}</td>
                                        <td
                                          className="text-center"
                                          style={{
                                            width: '50px',
                                          }}
                                        >
                                          {item.price
                                            ? `NGN ${formatNumber(item.price)}`
                                            : null}
                                        </td>
                                        <td
                                          style={{
                                            width: '200px',
                                          }}
                                        >
                                          {item.notes}
                                        </td>
                                        <td
                                          className=""
                                          style={{
                                            width: '200px',
                                          }}
                                        >
                                          {item.total
                                            ? `NGN ${formatNumber(item.total)}`
                                            : null}
                                        </td>
                                      </tr>
                                    ))}
                                    <tr className="bg-light">
                                      <th
                                        scope="row"
                                        colSpan="6"
                                        className="text-end"
                                      >
                                        Total :
                                      </th>
                                      <td className="bg-light text-center">
                                        <b>
                                          {additionalEoPTotalSum
                                            ? `NGN ${formatNumber(
                                              additionalEoPTotalSum,
                                            )}`
                                            : 'NGN 0'}
                                        </b>
                                      </td>
                                    </tr>
                                  </>
                                ) : null}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body">
                        <div className="p-3 bg-light">
                          <h5 className="font-size-14 mb-0">
                            Economics of Production Summary
                          </h5>
                        </div>
                        <div className="table-responsive">
                          <table className="table table-centered mb-0 table-nowrap">
                            <thead>
                              <tr>
                                <th
                                  className="border-top-0"
                                  style={{ width: '100%' }}
                                >
                                  Item
                                </th>
                                <th
                                  className="border-top-0 text-end"
                                  style={{ width: '100%' }}
                                >
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <h5 className="font-size-14 text-truncate">
                                    Farmsured Enterprise Standard Economics of
                                    Production
                                  </h5>
                                </td>
                                <td className="text-end">
                                  {totalSum
                                    ? `NGN ${formatNumber(totalSum)}`
                                    : 'NGN 0'}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h5 className="font-size-14 text-truncate">
                                    Additional Economics of Production
                                  </h5>
                                </td>
                                <td className="text-end">
                                  {additionalEoPTotalSum
                                    ? `NGN ${formatNumber(
                                      additionalEoPTotalSum,
                                    )}`
                                    : 'NGN 0'}
                                </td>
                              </tr>
                              <tr className="bg-light">
                                <td colSpan="2">
                                  <h6 className="m-0 text-end">Sub Total:</h6>
                                </td>
                                <td>
                                  {getEopTotalSum
                                    ? `NGN ${formatNumber(getEopTotalSum)}`
                                    : 'NGN 0'}
                                </td>
                              </tr>
                              <tr className="bg-light">
                                <td colSpan="2">
                                  <h6 className="m-0 text-end">Total:</h6>
                                </td>
                                <td>
                                  {getEopTotalSum
                                    ? `NGN ${formatNumber(getEopTotalSum)}`
                                    : 'NGN 0'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <DialogActions>
              <button
                className="btn btn-md btn-success"
                onClick={handleEOPUpdate}
              >
                Update
              </button>
              <button
                className="btn btn-md btn-danger"
                onClick={() => setShowEOPDialog(false)}
              >
                Close
              </button>
            </DialogActions>
          </DialogContent>
        </>
      </Dialog>

      {alert}
      <ToastContainer />
      <Footer />
    </div>
  )
}

export default CycleDetailPage
