import React from 'react'
import { useSelector } from 'react-redux'
import Admin from '../../components/Dashbaords/Admin/index'
import Farmer from '../../components/Dashbaords/Farmer/index'
import Manufacturer from '../../components/Dashbaords/Manufacturer/index'
import ServiceProvider from '../../components/Dashbaords/ServiceProvider/index'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../partials'

function DashboardPage() {
  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  return (
    <div id="layout-wrapper">
      {/* <!-- Begin page --> */}
      <Header />
      <RightSidebar />
      <LeftSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/*  Admin Dashboard  */}
            {userInfo && userInfo.account === 'admin' ? <Admin /> : null}

            {/*  Manufacturer Dashboard  */}
            {userInfo && userInfo.account === 'manufacturer' ? (
              <Manufacturer />
            ) : null}

            {/* Farmer Dashboard */}
            {userInfo && userInfo.account === 'farmer' ? <Farmer /> : null}

            {/* Service Dashbaord */}
            {userInfo && userInfo.account === 'service' ? (
              <ServiceProvider />
            ) : null}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default DashboardPage
