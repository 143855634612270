import axios from 'axios'
import {
    SERVICE_PROVIDER_PLACE_ORDER_REQUEST,
    SERVICE_PROVIDER_PLACE_ORDER_SUCCESS,
    SERVICE_PROVIDER_PLACE_ORDER_FAIL,
} from '../../../constants/serviceProviderConstants/orderConstants'

export const placeOrder = (
    product, quantity, price, total, deliverymethod, timeLineFrom, timeLineTo, orderID, address, state, lga, walletPin
) => async (dispatch, getState) => {
    dispatch({
        type: SERVICE_PROVIDER_PLACE_ORDER_REQUEST,
        payload: {
            product, quantity, price, total, deliverymethod, timeLineFrom, timeLineTo, orderID, address, state, lga, walletPin
        },
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/service/place-order',
            { product, quantity, price, total, deliverymethod, timeLineFrom, timeLineTo, orderID, address, state, lga, walletPin },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: SERVICE_PROVIDER_PLACE_ORDER_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: SERVICE_PROVIDER_PLACE_ORDER_FAIL, payload: message })
    }
}
