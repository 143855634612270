import axios from 'axios'
import {
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
} from '../../../constants/adminConstants/TagsConstants'

export const updateOrder = (
  orderId,
  status,
  costPerRoll,
  shippingFee,
) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_ORDER_REQUEST,
    payload: orderId,
    status,
    costPerRoll,
    shippingFee,
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/tag-request/update',
      { orderId, status, costPerRoll, shippingFee },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: UPDATE_ORDER_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: UPDATE_ORDER_FAIL, payload: message })
  }
}
