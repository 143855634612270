import axios from 'axios'
import {
  USER_FARM_CYCLE_DETAIL_FAIL,
  USER_FARM_CYCLE_DETAIL_REQUEST,
  USER_FARM_CYCLE_DETAIL_SUCCESS,
} from '../../../constants/adminConstants/cycleContants'

export const userFarmCycleDetail = (itemId, packageId) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: USER_FARM_CYCLE_DETAIL_REQUEST,
    payload: { itemId, packageId },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/farm-cycle/user/detail',
      { itemId, packageId },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: USER_FARM_CYCLE_DETAIL_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: USER_FARM_CYCLE_DETAIL_FAIL,
      payload: message,
    })
  }
}
