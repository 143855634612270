import axios from 'axios'
import {
  ADMIN_DELETE_MESSAGE_FAIL,
  ADMIN_DELETE_MESSAGE_REQUEST,
  ADMIN_DELETE_MESSAGE_SUCCESS,
} from '../../../constants/adminConstants/notificationConstants'

export const deleteMessage = (messageId) => async (dispatch, getState) => {
  dispatch({ type: ADMIN_DELETE_MESSAGE_REQUEST, payload: messageId })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.post(
      '/api/admin/delete-message',
      { messageId },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      },
    )
    dispatch({ type: ADMIN_DELETE_MESSAGE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: ADMIN_DELETE_MESSAGE_FAIL, payload: message })
  }
}
