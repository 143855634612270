import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { Line, Pie } from 'react-chartjs-2'
import 'chart.js/auto'
import { productDetail } from '../../../actions/adminActions/productActions/productDetails'
import { LoadingBox, MessageBox } from '../../../components'
import {
  monthlygreen,
  weeklyRed,
  todaygreenanalysis,
  todayredanalysis,
  weeklyGreen,
  monthlyred,
  todayAuthentication,
  formatNumber,
  authentication,
  averageUsage,
  previousYear,
  comYear,
  thisYearProduct,
  comYearProduct,
  thisMonthProduct,
  comMonthProduct,
  farmerProductAnalysis,
  highestFarmer,
  biannualAverageUsage,
  quarterlyAverageUsage,
  geolocations,
} from '../../../components/functions.js'
import moment from 'moment'
import { MDBDataTableV5 } from 'mdbreact'
import { CSVLink } from 'react-csv'
import { exportComponentAsPNG } from 'react-component-export-image'
// import {
//   GoogleMap,
//   withGoogleMap,
//   withScriptjs,
//   OverlayView,
//   InfoWindow,
// } from 'react-google-maps'

import {
  APIProvider, Map, AdvancedMarker, Pin, InfoWindow
} from '@vis.gl/react-google-maps';

export default function ProductAnalysisPage(props) {
  const productId = props.match.params.id

  const [monthly, setMonth] = useState(false)
  const [today, setToday] = useState(false)
  const [weekly, setWeek] = useState(false)
  const [todayRed, setTodayRed] = useState([])
  const [todayGreen, setTodayGreen] = useState([])
  const [redWeekly, setWeeklyRed] = useState([])
  const [greenWeekly, setWeeklyGreen] = useState([])
  const [redMonthly, setMonthRed] = useState([])
  const [greenMonthly, setMonthGreen] = useState([])
  const [datatable, setDatatable] = useState({})
  const [locations, setLocations] = useState([])

  const [openRedDialog, setOpenRedDialog] = useState(false)
  const [openGreenDialog, setOpenGreenDialog] = useState(false)

  const [selectedRedMark, setSelectedRedMark] = useState(null)
  const [selectedGreenMark, setSelectedGreenMark] = useState(null)

  const componentRef = useRef()

  const adminProductAnalysis = useSelector(
    (state) => state.adminProductAnalysis,
  )
  const { loading, error, result } = adminProductAnalysis

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(productDetail(productId))
  }, [dispatch, productId])

  useEffect(() => {
    if (result && result.tags) {
      setDatatable({
        columns: [
          {
            label: 'Unique ID',
            field: 'phone',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'phone',
            },
          },
          {
            label: 'Pin',
            field: 'pin',
            width: 200,
          },
          {
            label: 'Batch No',
            field: 'points',
            width: 400,
          },
          {
            label: 'Verification Geo-Location',
            field: 'redlocation',
            width: 400,
          },
          {
            label: 'Activation Geo-Location',
            field: 'greenlocation',
            width: 400,
          },
          {
            label: 'Date Authenticated',
            field: 'date',
          },
        ],
        rows: farmerProductAnalysis(result.tags).map((item) => ({
          phone: (
            <a
              href={`/farmer/detail/${item.farmer._id}`}
              className="me-3 text-primary"
              data-bs-toggle="tooltip"
              data-placement="top"
              title="Manage"
              key={item.farmer._id}
            >
              {item.farmer && item.farmer.phone ? item.farmer.phone : ''}
            </a>
          ),
          pin: item.tag,
          points: item.batch ? item.batch.batch : '',
          redlocation: `${item.redlat ? item.redlat : ''} ${item.redlong ? item.redlong : ''
            }`,
          greenlocation: `${item.greenlat ? item.greenlat : ''} ${item.greenlong ? item.greenlong : ''
            }`,
          date: moment(item.redActivatedAt).format('Do MMMM YYYY,  h:mm a'),
        })),
      })
    }
  }, [result])

  useMemo(() => {
    if (result && result.tags) {
      setTodayRed(
        Object.entries(todayredanalysis(result.tags)).map((item) => {
          return item[1].length
        }),
      )

      setTodayGreen(
        Object.entries(todaygreenanalysis(result.tags)).map(
          (item) => item[1].length,
        ),
      )

      setWeeklyRed(
        Object.entries(weeklyRed(result.tags)).map((item) => item[1].length),
      )

      setWeeklyGreen(
        Object.entries(weeklyGreen(result.tags)).map((item) => item[1].length),
      )

      setMonthRed(
        Object.entries(monthlyred(result.tags)).map((item) => item[1].length),
      )

      setMonthGreen(
        Object.entries(monthlygreen(result.tags)).map((item) => item[1].length),
      )

      setLocations(geolocations(result.tags))
    }
  }, [result])

  const headers = [
    { label: 'Unique ID', key: 'farmer.phone' },
    { label: 'Pin', key: 'tag' },
    { label: 'Batch', key: 'batch.batch' },
    { label: 'Geo-Location', key: 'location' },
  ]

  // const RedMarker = ({ item }) => (
  //   <div className={item.red ? 'red_blink' : null}></div>
  // )

  // const GreenMarker = ({ item }) => (
  //   <div className={item.green ? 'green_blink' : null}></div>
  // )

  // const defaultProps = {
  //   center: {
  //     lat: 9.0845755,
  //     lng: 8.674252499999994,
  //   },
  //   zoom: 7,
  // }

  const fileName = `Geolocation Tag Analyis - ${Date.now()}`

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  })


  // function Map() {
  //   const [selectedRedMark, setSelectedRedMark] = useState(null)
  //   const [selectedGreenMark, setSelectedGreenMark] = useState(null)
  //   return (
  //     <GoogleMap
  //       defaultCenter={{ lat: 9.0845755, lng: 8.674252499999994 }}
  //       defaultZoom={7}
  //     >
  //       {locations &&
  //         locations.length > 0 &&
  //         locations.map((item) => (
  //           <OverlayView
  //             key={item._id}
  //             position={{
  //               lat: item.redlat,
  //               lng: item.redlong,
  //             }}
  //             mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //             getPixelPositionOffset={getPixelPositionOffset}
  //             onClick={() => {
  //               setSelectedRedMark(item)
  //             }}
  //           >
  //             <button
  //               className={item.red ? 'red_blink' : null}
  //               style={{
  //                 border: 'none',
  //               }}
  //               onClick={() => {
  //                 setSelectedRedMark(item)
  //               }}
  //             ></button>
  //           </OverlayView>
  //         ))}

  //       {locations &&
  //         locations.length > 0 &&
  //         locations.map((item) => (
  //           <OverlayView
  //             key={item._id}
  //             position={{
  //               lat: item.greenlat,
  //               lng: item.greenlong,
  //             }}
  //             mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //             getPixelPositionOffset={getPixelPositionOffset}
  //             onClick={() => {
  //               setSelectedGreenMark(item)
  //             }}
  //           >
  //             <button
  //               className={item.green ? 'green_blink' : null}
  //               style={{
  //                 border: 'none',
  //               }}
  //               onClick={() => {
  //                 setSelectedGreenMark(item)
  //               }}
  //             ></button>
  //           </OverlayView>
  //         ))}

  //       {selectedRedMark && (
  //         <InfoWindow
  //           position={{
  //             lat: selectedRedMark.redlat,
  //             lng: selectedRedMark.redlong,
  //           }}
  //           onCloseClick={() => {
  //             setSelectedRedMark(null)
  //           }}
  //         >
  //           <div>
  //             <img
  //               src="assets/images/red_tag.png"
  //               alt="red tag"
  //               style={{ width: '70px' }}
  //             />
  //             <h5 className="card-title mt-1">
  //               <b>{selectedRedMark.product.name}</b>
  //             </h5>
  //             <a
  //               href={`/farmer/detail/${selectedRedMark.farmer._id}`}
  //               style={{
  //                 marginBottom: '2px',
  //                 marginTop: '2px',
  //               }}
  //             >
  //               {selectedRedMark.farmer.phone}
  //             </a>
  //             <br />
  //             <div
  //               className=""
  //               style={{
  //                 marginBottom: '3px',
  //                 marginTop: '3px',
  //               }}
  //             >
  //               <span
  //                 className="card-desc"
  //                 style={{
  //                   textTransform: 'capitalize',
  //                 }}
  //               >
  //                 {selectedRedMark.farmer && selectedRedMark.farmer.firstName}
  //               </span>{' '}
  //               <span
  //                 className="card-desc"
  //                 style={{
  //                   textTransform: 'capitalize',
  //                 }}
  //               >
  //                 {selectedRedMark.farmer && selectedRedMark.farmer.lastName}
  //               </span>
  //             </div>

  //             <p
  //               className="card-desc"
  //               style={{ marginBottom: '2px', marginTop: '3px' }}
  //             >
  //               {moment(selectedRedMark.redActivatedAt).format(
  //                 'Do MMMM YYYY,  h:mm a',
  //               )}
  //             </p>
  //           </div>
  //         </InfoWindow>
  //       )}

  //       {selectedGreenMark && (
  //         <InfoWindow
  //           position={{
  //             lat: selectedGreenMark.greenlat,
  //             lng: selectedGreenMark.greenlong,
  //           }}
  //           onCloseClick={() => {
  //             setSelectedGreenMark(null)
  //           }}
  //         >
  //           <div>
  //             <img
  //               src="assets/images/green_tag.png"
  //               alt="red tag"
  //               style={{ width: '70px' }}
  //             />
  //             <h5 className="card-title mt-1">
  //               <b>{selectedGreenMark.product.name}</b>
  //             </h5>
  //             <a
  //               href={`/farmer/detail/${selectedGreenMark.farmer._id}`}
  //               style={{
  //                 marginBottom: '2px',
  //                 marginTop: '2px',
  //               }}
  //             >
  //               {selectedGreenMark.farmer.phone}
  //             </a>
  //             <br />
  //             <div
  //               className=""
  //               style={{
  //                 marginBottom: '3px',
  //                 marginTop: '3px',
  //               }}
  //             >
  //               <spam
  //                 className="card-desc"
  //                 style={{
  //                   textTransform: 'capitalize',
  //                 }}
  //               >
  //                 {selectedGreenMark.farmer &&
  //                   selectedGreenMark.farmer.firstName}
  //               </spam>{' '}
  //               <spam
  //                 className="card-desc"
  //                 style={{
  //                   textTransform: 'capitalize',
  //                 }}
  //               >
  //                 {selectedGreenMark.farmer &&
  //                   selectedGreenMark.farmer.lastName}
  //               </spam>
  //             </div>

  //             <p
  //               className="card-desc"
  //               style={{ marginBottom: '2px', marginTop: '3px' }}
  //             >
  //               {moment(selectedGreenMark.redActivatedAt).format(
  //                 'Do MMMM YYYY,  h:mm a',
  //               )}
  //             </p>
  //           </div>
  //         </InfoWindow>
  //       )}
  //     </GoogleMap>
  //   )
  // }

  // const WrappedMap = withScriptjs(withGoogleMap(Map))

  const openRed = (item) => {
    setSelectedRedMark(item)
    setOpenRedDialog(true)
  }

  const openGreen = (item) => {
    setSelectedGreenMark(item)
    setOpenGreenDialog(true)
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <RightSidebar />
      <LeftSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Product Analysis</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Products</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Product Analysis
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div>
                <div className="page-title-box">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex">
                        <div className="me-3">
                          <img
                            src="assets/images/farmsured_icon.png"
                            alt="logo-sm-light"
                            className="avatar-xs"
                          />
                        </div>
                        <div className="flex-1">
                          <h5>
                            {result && result.product
                              ? result.product.name
                              : null}
                          </h5>
                          <div className="">
                            Category:{' '}
                            {result && result.product && result.product.category
                              ? result.product.category.name
                              : null}
                          </div>
                          <div className="">
                            Manufacturer:{' '}
                            {result &&
                              result.product &&
                              result.product.manufacturer
                              ? result.product.manufacturer.name
                              : null}
                          </div>
                          <p className="mb-0">
                            Date created:{' '}
                            {result && result.product
                              ? moment(result.product.created_at).format(
                                'Do MMMM YYYY',
                              )
                              : null}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-1 overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">
                              Average Usage
                            </p>
                            <h4 className="mb-0">
                              {result && result.tags
                                ? formatNumber(averageUsage(result.tags))
                                : 0}
                            </h4>
                          </div>
                          <div className="text-primary ms-auto">
                            <i className="ri-stack-line font-size-24"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-1 overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">
                              Secured
                            </p>
                            <h4 className="mb-0">
                              {result && result.tags
                                ? formatNumber(result.tags.length)
                                : 0}
                            </h4>
                          </div>
                          <div className="text-primary ms-auto">
                            <i className="fal fa-shield-check font-size-24"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-1 overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">
                              Authenticated
                            </p>
                            <h4 className="mb-0">
                              {result && result.tags
                                ? formatNumber(authentication(result.tags))
                                : 0}
                            </h4>
                          </div>
                          <div className="text-primary ms-auto">
                            <i className="fal fa-badge-check  font-size-24"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-1 overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">
                              Today Authentication
                            </p>
                            <h4 className="mb-0">
                              {result && result.tags
                                ? formatNumber(todayAuthentication(result.tags))
                                : 0}
                            </h4>
                          </div>
                          <div className="text-primary ms-auto">
                            <i className="fal fa-calendar-day font-size-24"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-8">
                    <div className="card">
                      <div className="card-body">
                        <div className="float-end d-none d-md-inline-block">
                          <div className="btn-group mb-2">
                            <button
                              type="button"
                              className="btn btn-sm btn-light"
                              onClick={() => {
                                setToday(true)
                                setMonth(false)
                                setWeek(false)
                              }}
                            >
                              Today
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-light active"
                              onClick={() => {
                                setWeek(true)
                                setToday(false)
                                setMonth(false)
                              }}
                            >
                              Weekly
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-light"
                              onClick={() => {
                                setMonth(true)
                                setWeek(false)
                                setToday(false)
                              }}
                            >
                              Monthly
                            </button>
                          </div>
                        </div>
                        <h4 className="card-title mb-4">Product Analytics</h4>
                        <div>
                          {monthly ? (
                            <div id="line-column-chart" className="" dir="ltr">
                              <Line
                                data={{
                                  labels: [
                                    'Jan',
                                    'Feb',
                                    'Mar',
                                    'Apr',
                                    'May',
                                    'Jun',
                                    'Jul',
                                    'Aug',
                                    'Sept',
                                    'Oct',
                                    'Nov',
                                    'Dec',
                                  ],
                                  datasets: [
                                    {
                                      label: 'Verified Tags',
                                      data: redMonthly,
                                      backgroundColor: '#FF4E60',
                                      borderColor: '#FF4E60',
                                    },
                                    {
                                      label: 'Activated Tags',
                                      data: greenMonthly,
                                      backgroundColor: '#1CBB8C',
                                      borderColor: '#1CBB8C',
                                    },
                                  ],
                                }}
                              />
                            </div>
                          ) : today ? (
                            <div
                              id="line-column-chart"
                              className="bar-charts "
                              dir="ltr"
                            >
                              <Line
                                data={{
                                  labels: [
                                    '12:00 am',
                                    '2:00 am',
                                    '4:00 am',
                                    '6:00 am',
                                    '8:00 am',
                                    '10:00 am',
                                    '12:00 pm',
                                    '2:00 pm',
                                    '4:00 pm',
                                    '6:00 pm',
                                    '8:00 pm',
                                    '10:00 pm',
                                  ],
                                  datasets: [
                                    {
                                      label: 'Verified Tags',
                                      data: todayRed,
                                      backgroundColor: '#FF4E60',
                                      borderColor: '#FF4E60',
                                    },
                                    {
                                      label: 'Activated Tags',
                                      data: todayGreen,
                                      backgroundColor: '#1CBB8C',
                                      borderColor: '#1CBB8C',
                                    },
                                  ],
                                }}
                              />
                            </div>
                          ) : weekly ? (
                            <div
                              id="line-column-chart"
                              className="bar-charts "
                              dir="ltr"
                            >
                              <Line
                                data={{
                                  labels: [
                                    'Sunday',
                                    'Monday',
                                    'Tuesday',
                                    'Wednesday',
                                    'Thursday',
                                    'Friday',
                                    'Saturday',
                                  ],
                                  datasets: [
                                    {
                                      label: 'Verified Tags',
                                      data: redWeekly,
                                      backgroundColor: '#FF4E60',
                                      borderColor: '#FF4E60',
                                    },
                                    {
                                      label: 'Activated Tags',
                                      data: greenWeekly,
                                      backgroundColor: '#1CBB8C',
                                      borderColor: '#1CBB8C',
                                    },
                                  ],
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              id="line-column-chart"
                              className="bar-charts "
                              dir="ltr"
                            >
                              <Line
                                data={{
                                  labels: [
                                    'Sunday',
                                    'Monday',
                                    'Tuesday',
                                    'Wednesday',
                                    'Thursday',
                                    'Friday',
                                    'Saturday',
                                  ],
                                  datasets: [
                                    {
                                      label: 'Verified Tags',
                                      data: redWeekly,
                                      backgroundColor: '#FF4E60',
                                      borderColor: '#FF4E60',
                                    },
                                    {
                                      label: 'Activated Tags',
                                      data: greenWeekly,
                                      backgroundColor: '#1CBB8C',
                                      borderColor: '#1CBB8C',
                                    },
                                  ],
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="card-body border-top text-center">
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="d-inline-flex">
                              <h5 className="me-2">
                                {result && result.tags
                                  ? formatNumber(thisMonthProduct(result.tags))
                                  : 0}
                              </h5>
                              {result && result.tags ? (
                                comMonthProduct(result.tags) < 0 ? (
                                  <div className="text-danger">
                                    <i className="mdi mdi-menu-down font-size-14"></i>
                                    {Math.round(comMonthProduct(result.tags))} %
                                  </div>
                                ) : Number.isNaN(
                                  Math.round(comMonthProduct(result.tags)),
                                ) === true ? (
                                  <div className="text-danger">
                                    <i className="mdi mdi-menu-down font-size-14"></i>
                                    0 %
                                  </div>
                                ) : Number.isFinite(
                                  comMonthProduct(result.tags),
                                ) !== true ? (
                                  <div className="text-danger">
                                    <i className="mdi mdi-menu-down font-size-14"></i>
                                    0 %
                                  </div>
                                ) : (
                                  <div className="text-success">
                                    <i className="mdi mdi-menu-up font-size-14"></i>
                                    {Math.round(comMonthProduct(result.tags))} %
                                  </div>
                                )
                              ) : (
                                <div className="text-danger">
                                  <i className="mdi mdi-menu-down font-size-14"></i>
                                  0 %
                                </div>
                              )}
                            </div>
                            <p className="text-muted text-truncate mb-0">
                              This month
                            </p>
                          </div>

                          <div className="col-sm-4">
                            <div className="mt-5 mt-sm-0">
                              <p className="mb-2 text-muted text-truncate">
                                <i className="mdi mdi-circle text-primary font-size-10 me-1"></i>{' '}
                                This Year :
                              </p>
                              <div className="d-inline-flex">
                                <h5 className="mb-0 me-2">
                                  {result && result.tags
                                    ? thisYearProduct(result.tags)
                                    : 0}
                                </h5>
                                {result && result.tags ? (
                                  Number.isFinite(comYear(result.tags)) !==
                                    true ? (
                                    <div className="text-danger">
                                      <i className="mdi mdi-menu-down font-size-14"></i>
                                      0 %
                                    </div>
                                  ) : comYearProduct(result.tags) < 0 ? (
                                    <div className="text-danger">
                                      <i className="mdi mdi-menu-down font-size-14"></i>
                                      {formatNumber(
                                        Math.round(comYearProduct(result.tags)),
                                      )}{' '}
                                      %
                                    </div>
                                  ) : (
                                    <div className="text-success">
                                      <i className="mdi mdi-menu-up font-size-14"></i>
                                      {formatNumber(
                                        Math.round(comYearProduct(result.tags)),
                                      )}{' '}
                                      %
                                    </div>
                                  )
                                ) : (
                                  <div className="text-danger">
                                    <i className="mdi mdi-menu-down font-size-14"></i>
                                    0 %
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="mt-4 mt-sm-0">
                              <p className="mb-2 text-muted text-truncate">
                                <i className="mdi mdi-circle text-success font-size-10 me-1"></i>{' '}
                                Previous Year :
                              </p>
                              <div className="d-inline-flex">
                                <h5 className="mb-0">
                                  {result && result.tags
                                    ? formatNumber(previousYear(result.tags))
                                    : 0}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title mb-4">
                          Product Average Usage
                        </h4>

                        <div id="donut-chart" className="apex-charts">
                          <Pie
                            data={{
                              labels: ['Quarterly', 'Biannually', 'Yearly'],
                              datasets: [
                                {
                                  data: [
                                    result && result.tags
                                      ? formatNumber(
                                        quarterlyAverageUsage(result.tags),
                                      )
                                      : 0,
                                    result && result.tags
                                      ? formatNumber(
                                        biannualAverageUsage(result.tags),
                                      )
                                      : 0,
                                    result && result.tags
                                      ? formatNumber(averageUsage(result.tags))
                                      : 0,
                                  ],
                                  backgroundColor: [
                                    '#FF4E60',
                                    '#1CBC84',
                                    '#1CBB8C',
                                  ],
                                },
                              ],
                            }}
                          />
                        </div>

                        <div className="row">
                          <div className="col-4">
                            <div className="text-center mt-4">
                              <p className="mb-2 text-truncate">
                                <i className="mdi mdi-circle text-primary font-size-10 me-1"></i>
                                Quarterly
                              </p>
                              <h5>
                                {result && result.tags
                                  ? formatNumber(
                                    quarterlyAverageUsage(result.tags),
                                  )
                                  : 0}
                              </h5>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="text-center mt-4">
                              <p className="mb-2 text-truncate">
                                <i className="mdi mdi-circle text-success font-size-10 me-1"></i>
                                Biannually
                              </p>
                              <h5>
                                {result && result.tags
                                  ? formatNumber(
                                    biannualAverageUsage(result.tags),
                                  )
                                  : 0}
                              </h5>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="text-center mt-4">
                              <p className="mb-2 text-truncate">
                                <i className="mdi mdi-circle text-warning font-size-10 me-1"></i>
                                Yearly
                              </p>
                              <h5>
                                {result && result.tags
                                  ? formatNumber(averageUsage(result.tags))
                                  : 0}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-9">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-1 overflow-hidden">
                            <h4 className="card-title mb-4">
                              Farmer Analytics
                            </h4>
                          </div>
                          <div className="text-primary ms-auto">
                            <CSVLink
                              data={farmerProductAnalysis(result.tags)}
                              headers={headers}
                              filename={'Product Users.csv'}
                              className="btn"
                              target="_blank"
                            >
                              <i
                                className="fa fa-download me-2"
                                aria-hidden="true"
                              ></i>{' '}
                              Download
                            </CSVLink>
                          </div>
                        </div>
                        <div className="table-responsive-xl">
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 15, 20, 25, 50]}
                            entries={5}
                            pagesAmount={4}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card">
                      <div className="card-body" style={{ minHeight: '490px' }}>
                        <h4 className="card-title mb-4">
                          Highest Product Users
                        </h4>

                        <div data-simplebar style={{ maxHeight: '330px' }}>
                          <ul className="list-unstyled">
                            {result && result.tags ? (
                              Object.entries(highestFarmer(result.tags))
                                .length === 0 ? (
                                <div style={{ minHeight: '330px' }}>
                                  <p className="product-card">
                                    There are no scans yet
                                  </p>
                                </div>
                              ) : (
                                Object.entries(highestFarmer(result.tags)).map(
                                  (item) => (
                                    <li className="" key={item[0]}>
                                      <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                          <p className="font-size-14 mb-4 p-0">
                                            {item[0]}
                                          </p>
                                        </div>
                                        <div className="font-size-14 text-primary ms-auto text-muted mb-0">
                                          {item[1].length}
                                        </div>
                                      </div>
                                    </li>
                                  ),
                                )
                              )
                            ) : null}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <h4 className="card-title mb-4">
                          Scanning Activities by Locations
                        </h4>
                      </div>
                      <div className="text-primary ms-auto">
                        <button
                          className="btn"
                          type="button"
                          onClick={() =>
                            exportComponentAsPNG(componentRef, {
                              fileName,
                            })
                          }
                        >
                          <i
                            className="fa fa-download me-2"
                            aria-hidden="true"
                          ></i>{' '}
                          Download
                        </button>
                      </div>
                    </div>
                    <div
                      ref={componentRef}
                      style={{ height: '100vh', width: '100%' }}
                    >
                      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_KEY}>
                        <Map
                          style={{ width: '100%', height: '100vh' }}
                          defaultCenter={{ lat: 9.0845755, lng: 8.674252499999994 }}
                          defaultZoom={6}
                          gestureHandling={'greedy'}
                          disableDefaultUI={false}
                          mapId={process.env.REACT_APP_MAP_ID}
                        >
                          {locations && locations.length > 0
                            ? locations.map((item) => {
                              return (
                                <div>
                                  {
                                    item.redlat && item.redlong ?
                                      <AdvancedMarker position={{ lat: item.redlat, lng: item.redlong }} onClick={() => {
                                        openRed(item)
                                      }}>
                                        <Pin background={'red'} glyphColor={'white'} borderColor={'white'} />
                                      </AdvancedMarker>
                                      : null
                                  }

                                  {
                                    item.greenlat && item.greenlong ?
                                      <AdvancedMarker position={{ lat: item.greenlat, lng: item.greenlong }} onClick={() => {
                                        openGreen(item)
                                      }}>
                                        <Pin background={'green'} glyphColor={'white'} borderColor={'white'} />
                                      </AdvancedMarker>
                                      : null
                                  }
                                </div>
                              )
                            })
                            : null}

                          {openRedDialog && (
                            <InfoWindow
                              headerContent={
                                <h5 className="card-title">
                                  <b>{selectedRedMark.product.name}</b>
                                </h5>}
                              position={{
                                lat: selectedRedMark.redlat,
                                lng: selectedRedMark.redlong,
                              }}
                              onClose={() => {
                                setOpenRedDialog(false)
                              }}
                              onCloseClick={() => {
                                setOpenRedDialog(false)
                              }}
                            >
                              <div>
                                <img
                                  src="assets/images/red_tag.png"
                                  alt="red tag"
                                  style={{ width: '50px', marginBottom: '6px', }}
                                />
                                <br />
                                <a
                                  href={`/farmer/detail/${selectedRedMark.farmer._id}`}
                                  style={{
                                    marginBottom: '2px',
                                  }}
                                >
                                  {selectedRedMark.farmer.phone}
                                </a>
                                <br />
                                <div
                                  className=""
                                  style={{
                                    marginBottom: '3px',
                                    marginTop: '3px',
                                  }}
                                >
                                  <span
                                    className="card-desc"
                                    style={{
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {selectedRedMark.farmer && selectedRedMark.farmer.firstName}
                                  </span>{' '}
                                  <span
                                    className="card-desc"
                                    style={{
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {selectedRedMark.farmer && selectedRedMark.farmer.lastName}
                                  </span>
                                </div>

                                <p
                                  className="card-desc"
                                  style={{ marginBottom: '2px', marginTop: '3px' }}
                                >
                                  {moment(selectedRedMark.redActivatedAt).format(
                                    'Do MMMM YYYY,  h:mm a',
                                  )}
                                </p>
                              </div>
                            </InfoWindow>
                          )}

                          {openGreenDialog && (
                            <InfoWindow
                              headerContent={
                                <h5 className="card-title">
                                  <b>{selectedGreenMark.product.name}</b>
                                </h5>}
                              position={{
                                lat: selectedGreenMark.redlat,
                                lng: selectedGreenMark.redlong,
                              }}
                              onClose={() => {
                                setOpenGreenDialog(false)
                              }}
                              onCloseClick={() => {
                                setOpenGreenDialog(false)
                              }}
                            >
                              <div>
                                <img
                                  src="assets/images/green_tag.png"
                                  alt="red tag"
                                  style={{ width: '50px', marginBottom: '6px', }}
                                />
                                <br />
                                <a
                                  href={`/farmer/detail/${selectedGreenMark.farmer._id}`}
                                  style={{
                                    marginBottom: '2px',
                                  }}
                                >
                                  {selectedGreenMark.farmer.phone}
                                </a>
                                <br />
                                <div
                                  className=""
                                  style={{
                                    marginBottom: '3px',
                                    marginTop: '3px',
                                  }}
                                >
                                  <span
                                    className="card-desc"
                                    style={{
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {selectedGreenMark.farmer && selectedGreenMark.farmer.firstName}
                                  </span>{' '}
                                  <span
                                    className="card-desc"
                                    style={{
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {selectedGreenMark.farmer && selectedGreenMark.farmer.lastName}
                                  </span>
                                </div>

                                <p
                                  className="card-desc"
                                  style={{ marginBottom: '2px', marginTop: '3px' }}
                                >
                                  {moment(selectedGreenMark.redActivatedAt).format(
                                    'Do MMMM YYYY,  h:mm a',
                                  )}
                                </p>
                              </div>
                            </InfoWindow>
                          )}
                        </Map>
                      </APIProvider>
                      {/* <WrappedMap
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
                        loadingElement={<div style={{ height: '100%' }} />}
                        containerElement={<div style={{ height: '100%' }} />}
                        mapElement={<div style={{ height: '100%' }} />}
                      /> */}

                      {/* <GoogleMapReact
                        bootstrapURLKeys={{
                          key: process.env.REACT_APP_GOOGLE_KEY,
                        }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                      >
                        {locations && locations.length > 0
                          ? locations.map((item) => {
                              return (
                                <RedMarker
                                  key={item.tag}
                                  lat={item.redlat}
                                  lng={item.redlong}
                                  item={item}
                                />
                              )
                            })
                          : null}
                        {locations && locations.length > 0
                          ? locations.map((item) => {
                              return (
                                <GreenMarker
                                  key={item.tag}
                                  lat={item.greenlat}
                                  lng={item.greenlong}
                                  item={item}
                                />
                              )
                            })
                          : null}
                      </GoogleMapReact> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div >
  )
}
