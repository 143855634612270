import axios from 'axios'
import {
  SERVICE_PROVIDER_CREATE_FAIL,
  SERVICE_PROVIDER_CREATE_REQUEST,
  SERVICE_PROVIDER_CREATE_SUCCESS,
} from '../../../constants/adminConstants/serviceConstants'

export const createService = (
  uniqueCode,
  manufacturer,
  name,
  category,
  benefit,
  email,
  phone,
  address,
  origin,
  lga,
  password,
  inputList,
) => async (dispatch, getState) => {
  dispatch({
    type: SERVICE_PROVIDER_CREATE_REQUEST,
    payload: {
      uniqueCode,
      manufacturer,
      name,
      category,
      benefit,
      email,
      phone,
      address,
      origin,
      lga,
      password,
      inputList,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/new-service-provider',
      {
        uniqueCode,
        manufacturer,
        name,
        category,
        benefit,
        email,
        phone,
        address,
        origin,
        lga,
        password,
        inputList,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: SERVICE_PROVIDER_CREATE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: SERVICE_PROVIDER_CREATE_FAIL,
      payload: message,
    })
  }
}
