import {
  FARM_CYCLE_LIST_REQUEST,
  FARM_CYCLE_LIST_SUCCESS,
  FARM_CYCLE_LIST_FAIL,
  FARMER_FARM_CYCLE_DETAIL_REQUEST,
  FARMER_FARM_CYCLE_DETAIL_SUCCESS,
  FARMER_FARM_CYCLE_DETAIL_FAIL,
  USER_FARM_CYCLE_DETAIL_REQUEST,
  USER_FARM_CYCLE_DETAIL_SUCCESS,
  USER_FARM_CYCLE_DETAIL_FAIL,
  VIEW_FARM_CYCLE_DETAIL_REQUEST,
  VIEW_FARM_CYCLE_DETAIL_SUCCESS,
  VIEW_FARM_CYCLE_DETAIL_FAIL,
  USER_SCAN_ACTIVITIES_REQUEST,
  USER_SCAN_ACTIVITIES_SUCCESS,
  USER_SCAN_ACTIVITIES_FAIL,
} from '../../../constants/adminConstants/cycleContants'

export const allFarmCyclesReducer = (
  state = { loading: true, cycles: [] },
  action,
) => {
  switch (action.type) {
    case FARM_CYCLE_LIST_REQUEST:
      return { loading: true }
    case FARM_CYCLE_LIST_SUCCESS:
      return { loading: false, cycles: action.payload }
    case FARM_CYCLE_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const packageCycleDetailReducer = (
  state = { loading: true },
  action,
) => {
  switch (action.type) {
    case FARMER_FARM_CYCLE_DETAIL_REQUEST:
      return { loading: true }
    case FARMER_FARM_CYCLE_DETAIL_SUCCESS:
      return { loading: false, detail: action.payload }
    case FARMER_FARM_CYCLE_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userFarmCycleDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_FARM_CYCLE_DETAIL_REQUEST:
      return { loading: true }
    case USER_FARM_CYCLE_DETAIL_SUCCESS:
      return { loading: false, detail: action.payload }
    case USER_FARM_CYCLE_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const viewFarmCycleDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case VIEW_FARM_CYCLE_DETAIL_REQUEST:
      return { loading: true }
    case VIEW_FARM_CYCLE_DETAIL_SUCCESS:
      return { loading: false, detail: action.payload }
    case VIEW_FARM_CYCLE_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userScanActivitiesReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SCAN_ACTIVITIES_REQUEST:
      return { loading: true }
    case USER_SCAN_ACTIVITIES_SUCCESS:
      return { loading: false, cycleScans: action.payload }
    case USER_SCAN_ACTIVITIES_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
