import {
  EMAIL_PASSWORD_RESET,
  EMAIL_PASSWORD_RESET_FAIL,
  EMAIL_PASSWORD_RESET_REQUEST,
  EMAIL_PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_RESET,
  PASSWORD_RESET_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_RESET,
  RESET_PASSWORD_SUCCESS,
  RESET_TRASNACTION_PIN_FAIL,
  RESET_TRASNACTION_PIN_REQUEST,
  RESET_TRASNACTION_PIN_RESET,
  RESET_TRASNACTION_PIN_SUCCESS,
} from '../../constants/authConstants/resetPasswordContants'

export function userResetPasswordReducer(state = {}, action) {
  switch (action.type) {
    case PASSWORD_RESET_REQUEST:
      return { loading: true }
    case PASSWORD_RESET_SUCCESS:
      return { loading: false, userResetPassword: action.payload }
    case PASSWORD_RESET_FAIL:
      return { loading: false, error: action.payload }
    case PASSWORD_RESET_RESET:
      return {}
    default:
      return state
  }
}

export function resetUserPasswordReducer(state = {}, action) {
  switch (action.type) {
    case EMAIL_PASSWORD_RESET_REQUEST:
      return { loading: true }
    case EMAIL_PASSWORD_RESET_SUCCESS:
      return { loading: false, reset: action.payload }
    case EMAIL_PASSWORD_RESET_FAIL:
      return { loading: false, error: action.payload }
    case EMAIL_PASSWORD_RESET:
      return {}
    default:
      return state
  }
}

export function resetPasswordReducer(state = {}, action) {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { loading: true }
    case RESET_PASSWORD_SUCCESS:
      return { loading: false, update: action.payload }
    case RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload }
    case RESET_PASSWORD_RESET:
      return {}
    default:
      return state
  }
}

export function resetTransactionPINReducer(state = {}, action) {
  switch (action.type) {
    case RESET_TRASNACTION_PIN_REQUEST:
      return { loading: true }
    case RESET_TRASNACTION_PIN_SUCCESS:
      return { loading: false, pinReset: action.payload }
    case RESET_TRASNACTION_PIN_FAIL:
      return { loading: false, error: action.payload }
    case RESET_TRASNACTION_PIN_RESET:
      return {}
    default:
      return state
  }
}
