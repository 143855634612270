import axios from 'axios'
import {
    FUND_MAIN_WALLET_REQUEST,
    FUND_MAIN_WALLET_SUCCESS,
    FUND_MAIN_WALLET_FAIL,
} from '../../../constants/adminConstants/farmtrove/fundUserConstants'

export const fundAccount = (fundAmount, phone) => async (
    dispatch,
    getState,
) => {
    dispatch({
        type: FUND_MAIN_WALLET_REQUEST,
        payload: { fundAmount, phone },
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/admin/farmtrove/fund-main-wallet',
            { fundAmount, phone },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: FUND_MAIN_WALLET_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: FUND_MAIN_WALLET_FAIL, payload: message })
    }
}
