import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import { useDispatch, useSelector } from 'react-redux'
import { claimDetail } from '../../../actions/adminActions/benefitsActions/claimDetail'
import moment from 'moment'
import CountdownTimer, {
  CountdownStatus,
} from '../../../components/Counter/CountdownTimer'
import { editClaim } from '../../../actions/adminActions/benefitsActions/editClaim'
import { UPDATE_CLAIM_RESET } from '../../../constants/adminConstants/benefitContants'
import parse from 'html-react-parser'

export default function ClaimDetailPage(props) {
  const itemId = props.match.params.id
  const [status, setStatus] = useState('')

  const detailOfClaim = useSelector((state) => state.detailOfClaim)
  const { loading, claim, error } = detailOfClaim

  const updateClaim = useSelector((state) => state.updateClaim)
  const { loading: updateLoading, success, error: updateError } = updateClaim

  const dispatch = useDispatch()

  const updateHandle = (e) => {
    e.preventDefault()
    dispatch(editClaim(itemId, status))
  }

  useEffect(() => {
    dispatch(claimDetail(itemId))
  }, [dispatch, success])

  useEffect(() => {
    if (claim) {
      setStatus(claim.status)
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: UPDATE_CLAIM_RESET })
      }, 2100)
    }
  }, [dispatch, claim, success])

  const dateTime = (claim) => {
    const c =
      claim.batch && claim.batch.product && claim.batch.product.category
        ? claim.batch.product.category.cycleDuration
        : 0

    let hrs = c * 60 * 60 * 1000

    const d = new Date(claim.tag.redActivatedAt)
    let ms = d.getTime()
    return ms + hrs
  }

  const POQ = (claim) => {
    let expectedPoint = claim.batch.product
      ? claim.batch.product.category.expectedPoint
      : 0
    let earnedPoint =
      claim.tag.redActivatedAt && !claim.tag.greenActivatedAt
        ? claim.tag.points
        : claim.tag.redActivatedAt && claim.tag.greenActivatedAt
        ? claim.tag.points + claim.tag.points
        : 0
    return expectedPoint - earnedPoint
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Claim Management</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Claims</a>
                      </li>
                      <li className="breadcrumb-item active">Claim Details</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {updateLoading ? <LoadingBox /> : null}
            {updateError ? (
              <MessageBox variant="danger">{updateError}</MessageBox>
            ) : null}
            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div>
                <div className="page-title-box">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex">
                        <div className="me-3">
                          <img
                            src="assets/images/farmsured_icon.png"
                            alt="logo-sm-light"
                            className="avatar-xs img-fluid"
                          />
                        </div>
                        <div className="flex-1">
                          <h5>
                            Claim by{' '}
                            {claim.farmer
                              ? `${claim.farmer.firstName} ${claim.farmer.lastName}`
                              : null}
                          </h5>
                          <div className="">
                            <a href="javascript:void(0);" className="">
                              Request ID:{' '}
                              {claim.claimID ? `#${claim.claimID}` : null}
                            </a>
                          </div>
                          <p className="mb-0">
                            Date of Claim:{' '}
                            {moment(claim.created_at).format('Do MMMM YYYY')}
                          </p>
                          <span className={`badge badge-soft-${claim.status}`}>
                            {claim.status}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <div className="text-lg-end">
                          <div className="float-end">
                            <button
                              className=" btn btn-success"
                              type="submit"
                              onClick={updateHandle}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4 card-border">
                            <div className="text-center ">
                              <img
                                src={
                                  claim.farmer && claim.farmer.image
                                    ? claim.farmer.image
                                    : 'assets/images/default-user.png'
                                }
                                alt=""
                                className="user-image"
                              />
                              <h5 className="mt-3">
                                {claim.farmer && claim.farmer.firstName
                                  ? `${claim.farmer.firstName} ${claim.farmer.lastName}`
                                  : null}
                              </h5>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="info-box">
                                  <p className="">
                                    <i className="fal fa-phone-alt me-2"></i>
                                    {claim.farmer && claim.farmer.phone
                                      ? `0${claim.farmer.phone}`
                                      : null}
                                  </p>
                                  <p className="">
                                    <i className="fal fa-envelope me-2"></i>
                                    {claim.farmer && claim.farmer.email
                                      ? claim.farmer.email
                                      : 'Not FIlled Yet'}
                                  </p>
                                  <p className="">
                                    <i className="fal fa-atom-alt me-2"></i>
                                    {claim.farmer && claim.farmer.points
                                      ? claim.farmer.points
                                      : 0}{' '}
                                    Points
                                  </p>
                                  <p className="">
                                    <i className="fal fa-map-marker-alt me-2"></i>
                                    {claim.farmer && claim.farmer.state
                                      ? claim.farmer.state
                                      : 'Not FIlled Yet'}
                                  </p>
                                  <p className="">
                                    <i className="fal fa-city me-2"></i>
                                    {claim.farmer && claim.farmer.lga
                                      ? claim.farmer.lga
                                      : 'Not FIlled Yet'}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="">
                          <h6 className="font-size-14">Claim Status</h6>
                          <select
                            className="form-select form-control select2"
                            onChange={(e) => setStatus(e.target.value)}
                            value={status}
                          >
                            <option value="Reviewing">Reviewing</option>
                            <option value="Processing">Processing</option>
                            <option value="Open">Open</option>
                            <option value="Approved">Approved</option>
                            <option value="Declined">Declined</option>
                            <option value="Completed">Completed</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <h6 className="font-size-14">Service Provider</h6>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mt-1">
                              <h6 className="font-size-14">
                                <b>Service Code:</b>{' '}
                                {claim.serviceProvider &&
                                claim.serviceProvider.uniqueCode
                                  ? claim.serviceProvider.uniqueCode
                                  : null}
                              </h6>
                              <h6 className="font-size-14">
                                <b>Name:</b>{' '}
                                {claim.serviceProvider &&
                                claim.serviceProvider.name
                                  ? claim.serviceProvider.name
                                  : null}
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mt-1">
                              <h6 className="font-size-14">
                                <b>Email:</b>{' '}
                                {claim.serviceProvider &&
                                claim.serviceProvider.email
                                  ? claim.serviceProvider.email
                                  : null}
                              </h6>
                              <h6 className="font-size-14">
                                <b>Phone:</b>{' '}
                                {claim.serviceProvider &&
                                claim.serviceProvider.phone
                                  ? `0${claim.serviceProvider.phone}`
                                  : null}
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mt-1">
                              <h6 className="font-size-14">
                                <b>LGA:</b>{' '}
                                {claim.serviceProvider &&
                                claim.serviceProvider.lga
                                  ? claim.serviceProvider.lga
                                  : null}
                              </h6>
                              <h6 className="font-size-14">
                                <b>State:</b>{' '}
                                {claim.serviceProvider &&
                                claim.serviceProvider.state
                                  ? claim.serviceProvider.state
                                  : null}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-body">
                            <h6 className="font-size-16">Tag Details</h6>

                            <div className="mt-3">
                              <h6 className="font-size-14">Tag Pin</h6>
                              {claim.tag && claim.tag.tag
                                ? claim.tag.tag
                                : null}
                            </div>
                            <div className="mt-3">
                              <h6 className="font-size-14">Tag Batch</h6>
                              <p className="font-size-14 mt-0 mb-0">
                                {claim.batch && claim.batch.batch
                                  ? claim.batch.batch
                                  : null}
                              </p>
                            </div>
                            <div className="mt-3">
                              <h6 className="font-size-14">Date Verified</h6>
                              <p className="font-size-14 mt-0 mb-0">
                                {claim.tag && claim.tag.redActivatedAt
                                  ? moment(claim.tag.redActivatedAt).format(
                                      'Do MMMM YYYY hh:mm a',
                                    )
                                  : null}
                              </p>
                            </div>

                            <div className="mt-3">
                              <h6 className="font-size-14">Date Activated</h6>
                              <p className="font-size-14 mt-0 mb-0">
                                {claim.tag && claim.tag.greenActivatedAt
                                  ? moment(claim.tag.greenActivatedAt).format(
                                      'Do MMMM YYYY hh:mm a',
                                    )
                                  : null}
                              </p>
                            </div>

                            <div className="mt-3">
                              <h6 className="font-size-14">Date of Claim</h6>
                              <p className="font-size-14 mt-0 mb-0">
                                {claim
                                  ? moment(claim.created_at).format(
                                      'Do MMMM YYYY hh:mm a',
                                    )
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-body">
                            <h6 className="font-size-16">Product Detail</h6>
                            <div className="mt-3">
                              <h6 className="font-size-14">Product Name</h6>
                              <p className="mb-0 font-size-14">
                                {claim.batch &&
                                claim.batch.product &&
                                claim.batch.product.name
                                  ? claim.batch.product.name
                                  : null}
                              </p>
                            </div>
                            <div className="mt-3">
                              <h6 className="font-size-14">Product Category</h6>
                              <p className="mb-0 font-size-14">
                                {claim.batch &&
                                claim.batch.product &&
                                claim.batch.product.category &&
                                claim.batch.product.category.name
                                  ? claim.batch.product.category.name
                                  : null}
                              </p>
                            </div>
                            <div className="mt-3">
                              <h6 className="font-size-14">Expected Point</h6>
                              <p className="mb-0 font-size-14">
                                {claim.batch &&
                                claim.batch.product &&
                                claim.batch.product.category &&
                                claim.batch.product.category.expectedPoint
                                  ? claim.batch.product.category.expectedPoint
                                  : 0}{' '}
                                Points
                              </p>
                            </div>
                            <div className="mt-3">
                              <h6 className="font-size-14">Cycle Duration</h6>
                              <p className="mb-0 font-size-14">
                                {claim.batch &&
                                claim.batch.product &&
                                claim.batch.product.category &&
                                claim.batch.product.category.cycleDuration
                                  ? claim.batch.product.category.cycleDuration
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-body">
                            <div className="">
                              <h6 className="font-size-16 me-3">
                                Benefit Details
                              </h6>
                              <div className="mt-3">
                                <h6 className="font-size-14 mt-2">Tag Point</h6>

                                <p className="font-size-14 pt-0">
                                  {claim.tag && claim.tag.points
                                    ? claim.tag.points
                                    : null}
                                </p>
                              </div>
                              <div className="mt-3">
                                <h6 className="font-size-14">Earned Point</h6>
                                <p className="font-size-14 mb-0">
                                  {claim.tag ? POQ(claim) : null}
                                </p>
                              </div>
                              <div className="mt-3">
                                <h6 className="font-size-14">Timeline</h6>
                                {claim.tag ? (
                                  <CountdownTimer
                                    targetDate={dateTime(claim)}
                                  />
                                ) : null}
                              </div>
                              <div className="mt-3">
                                <h6 className="font-size-14">Status</h6>
                                {claim.tag ? (
                                  <CountdownStatus
                                    item={claim.tag}
                                    targetDate={dateTime(claim)}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <h6 className="font-size-16 me-3">Detail</h6>
                        <div className="border p-4 rounded">
                          <p className="text-muted mb-2">
                            {claim && claim.detail ? parse(claim.detail) : null}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
