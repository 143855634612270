import axios from 'axios'
import {
  UPDATE_WITHDRAWREQUEST_FAIL,
  UPDATE_WITHDRAWREQUEST_REQUEST,
  UPDATE_WITHDRAWREQUEST_SUCCESS,
} from '../../../constants/adminConstants/farmtrove/walletConstants'

export const updateWithdrawRequest = (itemId, status) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: UPDATE_WITHDRAWREQUEST_REQUEST,
    payload: { itemId, status },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/farmtrove/withdraw-request/update',
      { itemId, status },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: UPDATE_WITHDRAWREQUEST_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: UPDATE_WITHDRAWREQUEST_FAIL, payload: message })
  }
}
