import {
  LIST_SERVICE_PROVIDER_FAIL,
  LIST_SERVICE_PROVIDER_REQUEST,
  LIST_SERVICE_PROVIDER_SUCCESS,
} from '../../constants/authConstants/serviceConstants'

export const servicesListReducer = (
  state = { loading: true, allservices: [] },
  action,
) => {
  switch (action.type) {
    case LIST_SERVICE_PROVIDER_REQUEST:
      return { loading: true }
    case LIST_SERVICE_PROVIDER_SUCCESS:
      return { loading: false, allservices: action.payload }
    case LIST_SERVICE_PROVIDER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
