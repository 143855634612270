import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { listProducts } from '../../../actions/serviceproviderActions/productsAction/products'
import { LoadingBox, MessageBox } from '../../../components'
import NaijaStates from 'naija-state-local-government'
import states from '../../../utilis'
import { formatNumber } from '../../../components/functions'
import { placeOrder } from "../../../actions/serviceproviderActions/orderAction/placeOrder"
import { SERVICE_PROVIDER_PLACE_ORDER_RESET } from '../../../constants/serviceProviderConstants/orderConstants'
import moment from 'moment'


export default function NewOrderPage() {
    const [product, setProduct] = useState('')
    const [productDetail, setProductDetail] = useState()
    const [quantity, setQuantity] = useState(0)
    const [price, setPrice] = useState(0)
    const [total, setTotal] = useState(0)
    const [deliverymethod, setDeliveryMethod] = useState('')
    const [orderID, setOrderID] = useState('')
    const [walletPin, setWalletPin] = useState('')

    const [address, setAddress] = useState('')
    const [state, setState] = useState('')
    const [lga, setLga] = useState('')
    const [timeLineFrom, setTimeLineFrom] = useState('')
    const [timeLineTo, setTimeLineTo] = useState('')

    const dispatch = useDispatch()

    // All product State
    const serviceProducts = useSelector((state) => state.serviceProducts)
    const { loading: loadingProducts, products, error: productsError } = serviceProducts

    // Submit order State
    const servicePlaceOrder = useSelector((state) => state.servicePlaceOrder)
    const { loading, savedOrder, error } = servicePlaceOrder

    useEffect(() => {
        dispatch(listProducts())

        setOrderID(`FMSD-${Date.now()}`)

        if (savedOrder) {
            setProduct('')
            setProductDetail()
            setQuantity(0)
            setPrice(0)
            setTotal(0)
            setDeliveryMethod()
            setWalletPin('')
            setAddress('')
            setState('')
            setLga('')
            setTimeout(() => {
                dispatch({ type: SERVICE_PROVIDER_PLACE_ORDER_RESET })
            }, 2500)
        }

        if (error) {
            setWalletPin('')
            setTimeout(() => {
                dispatch({ type: SERVICE_PROVIDER_PLACE_ORDER_RESET })
            }, 2500)
        }
    }, [savedOrder, error])

    useEffect(() => {
        if (product) {
            const result = products && products.find((itm) => itm._id === product)
            setProductDetail(result)
            setPrice(result && result.price)

            setTotal(price * quantity)
        }
    }, [product, price, quantity])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!walletPin) {

        } else {
            dispatch(placeOrder(product, quantity, price, total, deliverymethod, timeLineFrom, timeLineTo, orderID, address, state, lga, walletPin))

        }
    }



    return (
        <div id="layout-wrapper">
            <Header />
            <LeftSidebar />
            <RightSidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">New Order</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Farmsured</a>
                                            </li>
                                            <li className="breadcrumb-item active">Orders</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            loading
                                ?
                                <LoadingBox />
                                :
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="card">
                                            <div className="card-body">
                                                {productsError ? <MessageBox variant='danger'>{productsError}</MessageBox> : null}
                                                {error ? <MessageBox variant='danger'>{error}</MessageBox> : null}
                                                {savedOrder ? <MessageBox variant='success'>{savedOrder.message}</MessageBox> : null}
                                                <h5 className="card-title">Order information</h5>
                                                <p className="card-title-desc">Order your products from your organisation</p>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label
                                                                className="form-label"
                                                                forHtml="contactPersonName"
                                                            >
                                                                Product
                                                            </label>
                                                            <select
                                                                onChange={(e) =>
                                                                    setProduct(e.target.value)
                                                                }
                                                                className="select2 form-select"
                                                                required
                                                                value={product}
                                                            >
                                                                <option>Select product</option>
                                                                {loadingProducts ? (
                                                                    'Loading...'
                                                                ) : products && products.length > 0 ? (
                                                                    <>
                                                                        {products.map((item) => (
                                                                            <option value={item._id} key={item._id}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                    </>
                                                                ) : (
                                                                    'there is no product category'
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label
                                                                className="form-label"
                                                                forHtml="ProductQuantity"
                                                            >
                                                                Product Quantity
                                                            </label>
                                                            <input
                                                                id="ProductQuantity"
                                                                name="ProductQuantity"
                                                                type="number"
                                                                className="form-control"
                                                                required
                                                                onChange={(e) => setQuantity(e.target.value)}
                                                                placeholder="Product Quantity"
                                                                value={quantity}
                                                            />
                                                        </div>
                                                    </div>



                                                    <h4 className='card-title font-size-15 mt-3' style={{ fontWeight: 700, textTransform: 'uppercase', marginBottom: "10px" }}>Delivery Details</h4>

                                                    <h5 className="font-size-14">Delivery method:</h5>
                                                    <div className="row" onChange={(e) => setDeliveryMethod(e.target.value)} value={deliverymethod}>

                                                        <div className="col-lg-4 col-sm-6">
                                                            <div>
                                                                <label className="form-label card-radio-label mb-3">
                                                                    <input type="radio" name="pay-method" id="pay-methodoption1" className="card-radio-input" value={'Self Pickup'} />

                                                                    <span className="card-radio">
                                                                        <i className="fas fa-dolly font-size-24 align-middle me-2"></i>
                                                                        <span>Self Pickup</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-sm-6">
                                                            <div>
                                                                <label className="form-label card-radio-label mb-3">
                                                                    <input type="radio" name="pay-method" id="pay-methodoption2" className="card-radio-input" value={'Request Delivery'} />

                                                                    <span className="card-radio">
                                                                        <i className="fas fa-truck font-size-24 align-middle me-2"></i>
                                                                        <span>Request Delivery</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        deliverymethod === "Request Delivery" ?
                                                            <div>
                                                                <div className="col-md-12 mb-3 mt-1">
                                                                    <label htmlFor="example-text-input8">
                                                                        Select State
                                                                    </label>
                                                                    <select
                                                                        className="form-select form-control select2"
                                                                        required
                                                                        placeholder="Select a state"
                                                                        onChange={(e) => setState(e.target.value)}
                                                                        value={state}
                                                                    >
                                                                        <option>Select a state</option>
                                                                        {states.map((item) => (
                                                                            <option value={item}>{item}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>

                                                                <div className="form-group col-md-12 mb-2">
                                                                    <label
                                                                        htmlFor="example-text-input"
                                                                        className="col-md-12 col-form-label"
                                                                    >
                                                                        Select Local Government Area
                                                                    </label>
                                                                    <select
                                                                        className="form-select form-control select2"
                                                                        required
                                                                        name="lga"
                                                                        placeholder="Select a local government area"
                                                                        onChange={(e) => setLga(e.target.value)}
                                                                        value={lga}
                                                                    >
                                                                        {state && state === 'Select a state' ? (
                                                                            <option>
                                                                                Select a local government area
                                                                            </option>
                                                                        ) : state ? (
                                                                            <>
                                                                                <option>
                                                                                    Select a local government area
                                                                                </option>
                                                                                {NaijaStates.lgas(state).lgas.map(
                                                                                    (item) => (
                                                                                        <option value={item}>{item}</option>
                                                                                    ),
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <option>
                                                                                Select a local government area
                                                                            </option>
                                                                        )}
                                                                    </select>
                                                                </div>

                                                                <div className="col-md-12  mb-3 mt-1">
                                                                    <label
                                                                        className="col-md-12 col-form-label"
                                                                        forHtml="address"
                                                                    >
                                                                        Address
                                                                    </label>
                                                                    <textarea className="form-control" id="billing-address" rows="3" placeholder="Enter full address" value={address} onChange={(e) => setAddress(e.target.value)}></textarea>
                                                                </div>

                                                                <div className="col-md-12  mb-3 mt-1">
                                                                    <label
                                                                        className="col-md-12 col-form-label"
                                                                        forHtml="address"
                                                                    >
                                                                        Delivery Timeframe
                                                                    </label>
                                                                    <div class="input-daterange input-group" id="datepicker6" data-date-format="dd M, yyyy" data-date-autoclose="true" data-provide="datepicker" data-date-container="#datepicker6">
                                                                        <input type="date" class="form-control" name="start" placeholder="Start Date" onChange={(e) => setTimeLineFrom(e.target.value)} value={timeLineFrom} />
                                                                        <input type="date" class="form-control" name="end" placeholder="End Date" onChange={(e) => setTimeLineTo(e.target.value)} min={moment(timeLineFrom).format('YYYY-MM-DD')} value={timeLineTo} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }


                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label" forhtml="remark">
                                                                Wallet PIN
                                                            </label>
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                id="userpassword"
                                                                placeholder="Enter wallet PIN"
                                                                required
                                                                onChange={(e) => setWalletPin(e.target.value)}
                                                                value={walletPin}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12">
                                                        <div className="d-grid">
                                                            <button
                                                                className="btn btn-success btn-bg"
                                                                onClick={handleSubmit}
                                                                type="submit"
                                                            >
                                                                {deliverymethod === "Request Delivery" ? "Submit Order Request" : "Place Order"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="card checkout-order-summary">
                                            <div className="card-body">
                                                <div className="p-3 bg-light mb-4">
                                                    <h5 className="font-size-14 mb-0">Order Summary <span className="float-end ms-2">#{orderID}</span></h5>
                                                </div>
                                                <div className="p-3" style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div className="">
                                                        <h5 className='card-title'>
                                                            Product
                                                        </h5>
                                                    </div>
                                                    <div className="">
                                                        <h5 className="font-size-14 text-truncate">{productDetail && productDetail.name ? productDetail.name : 'Name'}</h5>
                                                    </div>
                                                </div>

                                                <div className="p-3" style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div className="">
                                                        <h5 className='card-title'>
                                                            Quantity
                                                        </h5>
                                                    </div>
                                                    <div className="">
                                                        <p className="text-muted mb-0">{quantity ? quantity : 0}</p>
                                                    </div>
                                                </div>

                                                <div className="p-3" style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div className="">
                                                        <h5 className='card-title'>
                                                            Price
                                                        </h5>
                                                    </div>
                                                    <div className="">
                                                        <p className="text-muted mb-0">NGN {formatNumber(price)}</p>
                                                    </div>
                                                </div>

                                                <div className="p-3" style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', borderWidth: '2px', borderColor: 'red' }}>
                                                    <div className="" style={{ width: "65%" }}>
                                                        <h5 className='card-title text-end'>
                                                            Sub Total:
                                                        </h5>
                                                    </div>
                                                    <div className="" style={{ alignItems: 'center' }}>
                                                        <h5 className="text-muted font-size-15">NGN {formatNumber(price)}</h5>
                                                    </div>
                                                </div>

                                                <div className="bg-soft-primary p-3" style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div className="">
                                                        <h5 className="font-size-14 text-primary mb-0"><i className="fas fa-shipping-fast me-1"></i> Shipping </h5>
                                                    </div>
                                                    <div className="">
                                                        <span className="float-end">Free</span>
                                                    </div>
                                                </div>

                                                <div className="p-3 mt-3" style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', borderWidth: '2px', borderColor: 'red' }}>
                                                    <div className="" style={{ width: "60%" }}>
                                                        <h5 className='card-title text-end'>
                                                            <b>Total:</b>
                                                        </h5>
                                                    </div>
                                                    <div className="" style={{ alignItems: 'center' }}>
                                                        <h5 className="text-muted font-size-15"> NGN {formatNumber(total)}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}
