import axios from 'axios'
import {
  MANUFACTURER_READ_MESSAGE_FAIL,
  MANUFACTURER_READ_MESSAGE_REQUEST,
  MANUFACTURER_READ_MESSAGE_SUCCESS,
} from '../../../constants/manufacturerConstants/messageConstants'

export const manufacturerReadMessage = (itemId) => async (dispatch, getState) => {
  dispatch({
    type: MANUFACTURER_READ_MESSAGE_REQUEST,
    payload: { itemId },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/manufacturer/read-message',
      { itemId },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: MANUFACTURER_READ_MESSAGE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: MANUFACTURER_READ_MESSAGE_FAIL, payload: message })
  }
}
