export const EXCHANGE_TOKEN_REQUEST = 'EXCHANGE_TOKEN_REQUEST'
export const EXCHANGE_TOKEN_SUCCESS = 'EXCHANGE_TOKEN_SUCCESS'
export const EXCHANGE_TOKEN_FAIL = 'EXCHANGE_TOKEN_FAIL'
export const EXCHANGE_TOKEN_RESET = 'EXCHANGE_TOKEN_RESET'

export const ACCOUNT_INFORMATION_REQUEST = 'ACCOUNT_INFORMATION_REQUEST'
export const ACCOUNT_INFORMATION_SUCCESS = 'ACCOUNT_INFORMATION_SUCCESS'
export const ACCOUNT_INFORMATION_FAIL = 'ACCOUNT_INFORMATION_FAIL'

export const ACCOUNT_TRANSATIONS_REQUEST = 'ACCOUNT_TRANSATIONS_REQUEST'
export const ACCOUNT_TRANSATIONS_SUCCESS = 'ACCOUNT_TRANSATIONS_SUCCESS'
export const ACCOUNT_TRANSATIONS_FAIL = 'ACCOUNT_TRANSATIONS_FAIL'

export const ACCOUNT_INCOME_REQUEST = 'ACCOUNT_INCOME_REQUEST'
export const ACCOUNT_INCOME_SUCCESS = 'ACCOUNT_INCOME_SUCCESS'
export const ACCOUNT_INCOME_FAIL = 'ACCOUNT_INCOME_FAIL'

