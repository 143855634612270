export const PACKAGES_REQUEST = 'PACKAGES_REQUEST'
export const PACKAGES_SUCCESS = 'PACKAGES_SUCCESS'
export const PACKAGES_FAIL = 'PACKAGES_FAIL'

export const ADD_FARM_CYCLE_REQUEST = 'ADD_FARM_CYCLE_REQUEST'
export const ADD_FARM_CYCLE_SUCCESS = 'ADD_FARM_CYCLE_SUCCESS'
export const ADD_FARM_CYCLE_FAIL = 'ADD_FARM_CYCLE_FAIL'
export const ADD_FARM_CYCLE_RESET = 'ADD_FARM_CYCLE_RESET'

export const START_FARM_CYCLE_REQUEST = 'START_FARM_CYCLE_REQUEST'
export const START_FARM_CYCLE_SUCCESS = 'START_FARM_CYCLE_SUCCESS'
export const START_FARM_CYCLE_FAIL = 'START_FARM_CYCLE_FAIL'
export const START_FARM_CYCLE_RESET = 'START_FARM_CYCLE_RESET'

export const POSTPONE_FARM_CYCLE_REQUEST = 'POSTPONE_FARM_CYCLE_REQUEST'
export const POSTPONE_FARM_CYCLE_SUCCESS = 'POSTPONE_FARM_CYCLE_SUCCESS'
export const POSTPONE_FARM_CYCLE_FAIL = 'POSTPONE_FARM_CYCLE_FAIL'
export const POSTPONE_FARM_CYCLE_RESET = 'POSTPONE_FARM_CYCLE_RESET'

export const ALL_FARM_CYCLE_REQUEST = 'ALL_FARM_CYCLE_REQUEST'
export const ALL_FARM_CYCLE_SUCCESS = 'ALL_FARM_CYCLE_SUCCESS'
export const ALL_FARM_CYCLE_FAIL = 'ALL_FARM_CYCLE_FAIL'

export const FARM_CYCLE_DETAIL_REQUEST = 'FARM_CYCLE_DETAIL_REQUEST'
export const FARM_CYCLE_DETAIL_SUCCESS = 'FARM_CYCLE_DETAIL_SUCCESS'
export const FARM_CYCLE_DETAIL_FAIL = 'FARM_CYCLE_DETAIL_FAIL'

export const SAVE_FARM_COMPLIANCE_REQUEST = 'SAVE_FARM_COMPLIANCE_REQUEST'
export const SAVE_FARM_COMPLIANCE_SUCCESS = 'SAVE_FARM_COMPLIANCE_SUCCESS'
export const SAVE_FARM_COMPLIANCE_FAIL = 'SAVE_FARM_COMPLIANCE_FAIL'
export const SAVE_FARM_COMPLIANCE_RESET = 'SAVE_FARM_COMPLIANCE_RESET'

export const SCAN_TAG_REQUEST = 'SCAN_TAG_REQUEST'
export const SCAN_TAG_SUCCESS = 'SCAN_TAG_SUCCESS'
export const SCAN_TAG_FAIL = 'SCAN_TAG_FAIL'
export const SCAN_TAG_RESET = 'SCAN_TAG_RESET'

export const WEATHER_ANALYSIS_REQUEST = 'WEATHER_ANALYSIS_REQUEST'
export const WEATHER_ANALYSIS_SUCCESS = 'WEATHER_ANALYSIS_SUCCESS'
export const WEATHER_ANALYSIS_FAIL = 'WEATHER_ANALYSIS_FAIL'
export const WEATHER_ANALYSIS_RESET = 'WEATHER_ANALYSIS_RESET'

export const AI_LEARN_MORE_REQUEST = 'AI_LEARN_MORE_REQUEST'
export const AI_LEARN_MORE_SUCCESS = 'AI_LEARN_MORE_SUCCESS'
export const AI_LEARN_MORE_FAIL = 'AI_LEARN_MORE_FAIL'
export const AI_LEARN_MORE_RESET = 'AI_LEARN_MORE_RESET'

export const AI_REVIEW_CYCLE_REQUEST = 'AI_REVIEW_CYCLE_REQUEST'
export const AI_REVIEW_CYCLE_SUCCESS = 'AI_REVIEW_CYCLE_SUCCESS'
export const AI_REVIEW_CYCLE_FAIL = 'AI_REVIEW_CYCLE_FAIL'


export const UPDATE_PACKAGE_EOP_REQUEST = 'UPDATE_PACKAGE_EOP_REQUEST'
export const UPDATE_PACKAGE_EOP_SUCCESS = 'UPDATE_PACKAGE_EOP_SUCCESS'
export const UPDATE_PACKAGE_EOP_FAIL = 'UPDATE_PACKAGE_EOP_FAIL'
export const UPDATE_PACKAGE_EOP_RESET = 'UPDATE_PACKAGE_EOP_RESET'