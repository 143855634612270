import React from 'react'

function LoadingBox() {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <img
            className="preloader"
            src="/assets/images/icon.gif"
            alt="loader"
          />
        </div>
      </div>
    </div>
  )
}

export default LoadingBox
