export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET'

export const ADMIN_RESET_USER_PASSWORD_REQUEST =
  'ADMIN_RESET_USER_PASSWORD_REQUEST'
export const ADMIN_RESET_USER_PASSWORD_SUCCESS =
  'ADMIN_RESET_USER_PASSWORD_SUCCESS'
export const ADMIN_RESET_USER_PASSWORD_FAIL = 'ADMIN_RESET_USER_PASSWORD_FAIL'
export const ADMIN_RESET_USER_PASSWORD_RESET = 'ADMIN_RESET_USER_PASSWORD_RESET'
