export const SERVICE_PROVIDER_LIST_REQUEST = 'SERVICE_PROVIDER_LIST_REQUEST'
export const SERVICE_PROVIDER_LIST_SUCCESS = 'SERVICE_PROVIDER_LIST_SUCCESS'
export const SERVICE_PROVIDER_LIST_FAIL = 'SERVICE_PROVIDER_LIST_FAIL'

export const SERVICE_PROVIDER_CREATE_REQUEST = 'SERVICE_PROVIDER_CREATE_REQUEST'
export const SERVICE_PROVIDER_CREATE_SUCCESS = 'SERVICE_PROVIDER_CREATE_SUCCESS'
export const SERVICE_PROVIDER_CREATE_FAIL = 'SERVICE_PROVIDER_CREATE_FAIL'
export const SERVICE_PROVIDER_CREATE_RESET = 'SERVICE_PROVIDER_CREATE_RESET'

export const UPDATE_SERVICE_PROVIDER_REQUEST = 'UPDATE_SERVICE_PROVIDER_REQUEST'
export const UPDATE_SERVICE_PROVIDER_SUCCESS = 'UPDATE_SERVICE_PROVIDER_SUCCESS'
export const UPDATE_SERVICE_PROVIDER_FAIL = 'UPDATE_SERVICE_PROVIDER_FAIL'
export const UPDATE_SERVICE_PROVIDER_RESET = 'UPDATE_SERVICE_PROVIDER_RESET'

export const SERVICE_PROVIDER_DELETE_REQUEST = 'SERVICE_PROVIDER_DELETE_REQUEST'
export const SERVICE_PROVIDER_DELETE_SUCCESS = 'SERVICE_PROVIDER_DELETE_SUCCESS'
export const SERVICE_PROVIDER_DELETE_FAIL = 'SERVICE_PROVIDER_DELETE_FAIL'
export const SERVICE_PROVIDER_DELETE_RESET = 'SERVICE_PROVIDER_DELETE_RESET'

export const SERVICE_COVERAGE_LIST_REQUEST = 'SERVICE_COVERAGE_LIST_REQUEST'
export const SERVICE_COVERAGE_LIST_SUCCESS = 'SERVICE_COVERAGE_LIST_SUCCESS'
export const SERVICE_COVERAGE_LIST_FAIL = 'SERVICE_COVERAGE_LIST_FAIL'

export const ADD_SERVICE_COVERAGE_REQUEST = 'ADD_SERVICE_COVERAGE_REQUEST'
export const ADD_SERVICE_COVERAGE_SUCCESS = 'ADD_SERVICE_COVERAGE_SUCCESS'
export const ADD_SERVICE_COVERAGE_FAIL = 'ADD_SERVICE_COVERAGE_FAIL'
export const ADD_SERVICE_COVERAGE_RESET = 'ADD_SERVICE_COVERAGE_RESET'

export const SERVICE_COVERAGE_DELETE_REQUEST = 'SERVICE_COVERAGE_DELETE_REQUEST'
export const SERVICE_COVERAGE_DELETE_SUCCESS = 'SERVICE_COVERAGE_DELETE_SUCCESS'
export const SERVICE_COVERAGE_DELETE_FAIL = 'SERVICE_COVERAGE_DELETE_FAIL'
export const SERVICE_COVERAGE_DELETE_RESET = 'SERVICE_COVERAGE_DELETE_RESET'

export const UPDATE_SERVICE_COVERAGE_REQUEST = 'UPDATE_SERVICE_COVERAGE_REQUEST'
export const UPDATE_SERVICE_COVERAGE_SUCCESS = 'UPDATE_SERVICE_COVERAGE_SUCCESS'
export const UPDATE_SERVICE_COVERAGE_FAIL = 'UPDATE_SERVICE_COVERAGE_FAIL'
export const UPDATE_SERVICE_COVERAGE_RESET = 'UPDATE_SERVICE_COVERAGE_RESET'

export const ALL_BENEFIT_CLAIMS_REQUEST = 'ALL_BENEFIT_CLAIMS_REQUEST'
export const ALL_BENEFIT_CLAIMS_SUCCESS = 'ALL_BENEFIT_CLAIMS_SUCCESS'
export const ALL_BENEFIT_CLAIMS_FAIL = 'ALL_BENEFIT_CLAIMS_FAIL'

export const SERVICE_CLAIM_DELETE_REQUEST = 'SERVICE_CLAIM_DELETE_REQUEST'
export const SERVICE_CLAIM_DELETE_SUCCESS = 'SERVICE_CLAIM_DELETE_SUCCESS'
export const SERVICE_CLAIM_DELETE_FAIL = 'SERVICE_CLAIM_DELETE_FAIL'
export const SERVICE_CLAIM_DELETE_RESET = 'SERVICE_CLAIM_DELETE_RESET'

export const ASSIGN_SERVICE_CATEGORY_REQUEST = 'ASSIGN_SERVICE_CATEGORY_REQUEST'
export const ASSIGN_SERVICE_CATEGORY_SUCCESS = 'ASSIGN_SERVICE_CATEGORY_SUCCESS'
export const ASSIGN_SERVICE_CATEGORY_FAIL = 'ASSIGN_SERVICE_CATEGORY_FAIL'
export const ASSIGN_SERVICE_CATEGORY_RESET = 'ASSIGN_SERVICE_CATEGORY_RESET'

export const EDIT_ASSIGNED_SERVICE_CATEGORY_REQUEST =
  'EDIT_ASSIGNED_SERVICE_CATEGORY_REQUEST'
export const EDIT_ASSIGNED_SERVICE_CATEGORY_SUCCESS =
  'EDIT_ASSIGNED_SERVICE_CATEGORY_SUCCESS'
export const EDIT_ASSIGNED_SERVICE_CATEGORY_FAIL =
  'EDIT_ASSIGNED_SERVICE_CATEGORY_FAIL'
export const EDIT_ASSIGNED_SERVICE_CATEGORY_RESET =
  'EDIT_ASSIGNED_SERVICE_CATEGORY_RESET'

export const DELETE_ASSIGNED_SERVICE_CATEGORY_REQUEST =
  'DELETE_ASSIGNED_SERVICE_CATEGORY_REQUEST'
export const DELETE_ASSIGNED_SERVICE_CATEGORY_SUCCESS =
  'DELETE_ASSIGNED_SERVICE_CATEGORY_SUCCESS'
export const DELETE_ASSIGNED_SERVICE_CATEGORY_FAIL =
  'DELETE_ASSIGNED_SERVICE_CATEGORY_FAIL'
export const DELETE_ASSIGNED_SERVICE_CATEGORY_RESET =
  'DELETE_ASSIGNED_SERVICE_CATEGORY_RESET'

export const ORGANISATIONS_SERVICE_CATEGORY_REQUEST =
  'ORGANISATIONS_SERVICE_CATEGORY_REQUEST'
export const ORGANISATIONS_SERVICE_CATEGORY_SUCCESS =
  'ORGANISATIONS_SERVICE_CATEGORY_SUCCESS'
export const ORGANISATIONS_SERVICE_CATEGORY_FAIL =
  'ORGANISATIONS_SERVICE_CATEGORY_FAIL'
