import axios from 'axios'
import {
  APPLICATION_FAIL,
  APPLICATION_REQUEST,
  APPLICATION_SUCCESS,
} from '../../constants/authConstants/applicationContants'

const register = (
  changedNumber,
  reason,
  surname,
  firstName,
  otherName,
  email,
  phone,
  batch,
  IDCard,
  NCareBeneficiary,
  NCareSupport,
  ownABusiness,
  typeOfBusiness,
  activityAfterYeap,
  revenue,
  haveStaff,
  soloOrGroupBusiness,
  websiteAddress,
  isYourBusinessRegistered,
  joinAnAgribusiness,
  specifyAgribusiness,
  needsLoan,
  enterpriseType,
  cassavaEnterprise,
  poultryEnterprise,
  largeRuminantEnterprise,
  smallRuminantEnterprise,
  aquacultureEnterprise,
  monogastricsEnterprise,
  cropEnterprise,
  vegetableEnterprise,
  serviceProviderEnterprise,
  hasExperience,
  experienceExplanation,
  supportOptions,
  assetSupport,
  assetSupportType,
  comments,
  isDeclarationChecked,
) => async (dispatch) => {
  dispatch({
    type: APPLICATION_REQUEST,
    payload: {
      changedNumber,
      reason,
      surname,
      firstName,
      otherName,
      email,
      phone,
      batch,
      IDCard,
      NCareBeneficiary,
      NCareSupport,
      ownABusiness,
      typeOfBusiness,
      activityAfterYeap,
      revenue,
      haveStaff,
      soloOrGroupBusiness,
      websiteAddress,
      isYourBusinessRegistered,
      joinAnAgribusiness,
      specifyAgribusiness,
      needsLoan,
      enterpriseType,
      cassavaEnterprise,
      poultryEnterprise,
      largeRuminantEnterprise,
      smallRuminantEnterprise,
      aquacultureEnterprise,
      monogastricsEnterprise,
      cropEnterprise,
      vegetableEnterprise,
      serviceProviderEnterprise,
      hasExperience,
      experienceExplanation,
      supportOptions,
      assetSupport,
      assetSupportType,
      comments,
      isDeclarationChecked,
    },
  })
  try {
    const { data } = await axios.post('/api/submit-application', {
      changedNumber,
      reason,
      surname,
      firstName,
      otherName,
      email,
      phone,
      batch,
      IDCard,
      NCareBeneficiary,
      NCareSupport,
      ownABusiness,
      typeOfBusiness,
      activityAfterYeap,
      revenue,
      haveStaff,
      soloOrGroupBusiness,
      websiteAddress,
      isYourBusinessRegistered,
      joinAnAgribusiness,
      specifyAgribusiness,
      needsLoan,
      enterpriseType,
      cassavaEnterprise,
      poultryEnterprise,
      largeRuminantEnterprise,
      smallRuminantEnterprise,
      aquacultureEnterprise,
      monogastricsEnterprise,
      cropEnterprise,
      vegetableEnterprise,
      serviceProviderEnterprise,
      hasExperience,
      experienceExplanation,
      supportOptions,
      assetSupport,
      assetSupportType,
      comments,
      isDeclarationChecked,
    })
    dispatch({ type: APPLICATION_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: APPLICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export default register
