import axios from 'axios'
import {
  TAG_GENERATION_FAIL,
  TAG_GENERATION_REQUEST,
  TAG_GENERATION_SUCCESS,
} from '../../../constants/adminConstants/TagsConstants'

// Generate Tags Action
export const createTag = (category, quantity, points) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: TAG_GENERATION_REQUEST,
    payload: { category, quantity, points },
  })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.post(
      '/api/admin/tag-generation',
      { category, quantity, points },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      },
    )
    dispatch({ type: TAG_GENERATION_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: TAG_GENERATION_FAIL, payload: message })
  }
}
