import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddProduct } from '../../../actions/adminActions/productActions/createProduct'
import { allProductCategory } from '../../../actions/adminActions/productActions/productCategory'
import { Header, LeftSidebar, RightSidebar, Footer } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import { listManufacuturers } from '../../../actions/adminActions/manufactuerActions/listManufacturers'
import { ADD_PRODUCT_RESET } from '../../../constants/adminConstants/productConstants'

function CreateProductPage() {
  const [productName, setProductName] = useState('')
  const [category, setProductCategory] = useState('')
  const [productDescription, setProductDescription] = useState('')
  const [manufacturer, setManufacturer] = useState('')

  const dispatch = useDispatch()

  const productCategory = useSelector((state) => state.productCategory)
  const { loading: loadingCategory, categorylist } = productCategory

  const newProduct = useSelector((state) => state.newProduct)
  const { loading, error, product } = newProduct

  const manufacturersList = useSelector((state) => state.manufacturersList)
  const { loading: loadingMaufacturer, manufacturers } = manufacturersList

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      AddProduct(productName, category, productDescription, manufacturer),
    )
  }

  useEffect(() => {
    dispatch(allProductCategory())
    dispatch(listManufacuturers())

    if (product) {
      setTimeout(() => {
        dispatch({ type: ADD_PRODUCT_RESET })
      }, 2100)
    }
  }, [dispatch, product])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Products & Services</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Product & Services</a>
                      </li>
                      <li className="breadcrumb-item active">Create</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {product ? (
              <MessageBox variant="success">{product.message}</MessageBox>
            ) : null}
            {error ? <MessageBox variant="danger">{error}</MessageBox> : null}
            {loading ? (
              <LoadingBox />
            ) : (
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div>
                      <h4 className="card-title">Basic Information</h4>
                      <p className="card-title-desc">
                        Enter product & service information below
                      </p>
                      <div className="row">
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label" forHtml="ProductName">
                              Select Organization
                            </label>
                            <select
                              onChange={(e) => setManufacturer(e.target.value)}
                              className="select2 form-select"
                              required
                            >
                              <option>Select</option>
                              {loadingMaufacturer ? (
                                'Loading...'
                              ) : manufacturers.length > 0 ? (
                                <>
                                  {manufacturers.map((item) => (
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </>
                              ) : (
                                'There is no organization'
                              )}
                            </select>
                          </div>
                        </div>
                      </div>

                      <form onSubmit={submitHandler}>
                        <div className="row mt-3">
                          <h3 className="card-title">Product / Service Details</h3>
                          <div className="col-12">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                forHtml="ProductName"
                              >
                                Name
                              </label>
                              <input
                                id="ProductName"
                                name="ProductName"
                                type="text"
                                className="form-control"
                                required
                                onChange={(e) => setProductName(e.target.value)}
                                placeholder="Name"
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                forHtml="contactPersonName"
                              >
                                Category
                              </label>
                              <select
                                onChange={(e) =>
                                  setProductCategory(e.target.value)
                                }
                                className="select2 form-select"
                                required
                              >
                                <option>Select</option>
                                {loadingCategory ? (
                                  'Loading...'
                                ) : categorylist.length > 0 ? (
                                  <>
                                    {categorylist.map((item) => (
                                      <option value={item._id} key={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </>
                                ) : (
                                  'there is no product category'
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                forHtml="ProductDescription"
                              >
                                Description
                              </label>
                              <textarea
                                id="ProductDescription"
                                name="ProductDescription"
                                type="text"
                                className="form-control"
                                placeholder="Description"
                                required
                                onChange={(e) =>
                                  setProductDescription(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <button
                          className="btn btn-success mt-3"
                          type="submit"
                          onSubmit={submitHandler}
                        >
                          Add New
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CreateProductPage
