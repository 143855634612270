import {
  ALL_APPLICATION_FAIL,
  ALL_APPLICATION_REQUEST,
  ALL_APPLICATION_SUCCESS,
  SAVE_DATE_FAIL,
  SAVE_DATE_REQUEST,
  SAVE_DATE_RESET,
  SAVE_DATE_SUCCESS,
} from '../../../constants/adminConstants/applicationContants'

export const allApplicationsReducer = (
  state = { applications: [] },
  action,
) => {
  switch (action.type) {
    case ALL_APPLICATION_REQUEST:
      return { loading: true }
    case ALL_APPLICATION_SUCCESS:
      return { loading: false, applications: action.payload }
    case ALL_APPLICATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const saveDateReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_DATE_REQUEST:
      return { loading: true }
    case SAVE_DATE_SUCCESS:
      return { loading: false, saved: action.payload }
    case SAVE_DATE_FAIL:
      return { loading: false, error: action.payload }
    case SAVE_DATE_RESET:
      return {}
    default:
      return state
  }
}
