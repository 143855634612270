import axios from 'axios'
import {
  DELETE_ASSIGNED_SERVICE_CATEGORY_FAIL,
  DELETE_ASSIGNED_SERVICE_CATEGORY_REQUEST,
  DELETE_ASSIGNED_SERVICE_CATEGORY_SUCCESS,
} from '../../../constants/adminConstants/serviceConstants'

export const deleteAssignedServiceCategory = (serviceId) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: DELETE_ASSIGNED_SERVICE_CATEGORY_REQUEST,
    payload: serviceId,
  })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.post(
      `/api/admin/service-category/delete-assigned-category`,
      { serviceId },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      },
    )
    dispatch({ type: DELETE_ASSIGNED_SERVICE_CATEGORY_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: DELETE_ASSIGNED_SERVICE_CATEGORY_FAIL, payload: message })
  }
}