import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MDBDataTableV5 } from 'mdbreact'
import { listManufacuturers } from '../../../actions/adminActions/manufactuerActions/listManufacturers'
import { Editor } from '@tinymce/tinymce-react'
import { createLoan } from '../../../actions/adminActions/farmtroveActions/createLoan'
import { updateLoan } from '../../../actions/adminActions/farmtroveActions/updateLoan'
import { listLoans } from '../../../actions/adminActions/farmtroveActions/listLoans'
import { TailSpin } from 'react-loading-icons'
import { MessageBox, LoadingBox } from '../../../components'
import { LOAN_CREATE_RESET, UPDATE_LOAN_RESET } from '../../../constants/adminConstants/farmtrove/loanConstants'
import { formatNumber } from '../../../components/functions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

export default function LoanProviderPage() {
  const [datatable, setDatatable] = useState({})
  const [show, setShow] = useState(false)  
  const [open, setOpen] = useState(false)
  const [itemId, setItemId] = useState('')
  const [name, setName] = useState('')
  const [manufacturer, setManufacturer] = useState('')
  const [maxAmount, setMaxAmount] = useState('')
  const [duration, setDuration] = useState('')
  const [interest, setInterest] = useState('')
  const [moratoriumPeriod, setMoratoriumPeriod] = useState('')
  const [description, setDescription] = useState('')
  const [termsAndCondition, setTermsAndCondition] = useState('')
  const editorRef = useRef(null)


  const manufacturersList = useSelector((state) => state.manufacturersList)
  const { loading: loadingMaufacturer, manufacturers } = manufacturersList

  const addLoan = useSelector((state) => state.addLoan)
  const { loading, newLoan, error } = addLoan

  // Edit Loan Provider State
  const updateLoanProvider = useSelector((state) => state.updateLoanProvider)
  const {
    loading: loadingUpdate,
    error: updateError,
    update,
    updatedLoan
  } = updateLoanProvider

  const listAllLoans = useSelector((state) => state.listAllLoans)
  const { loading: loadingLoans, allLoans, error: loanError } = listAllLoans

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listManufacuturers())
    dispatch(listLoans())

    if (newLoan) {
      setTimeout(() => {
        setShow(false)
        dispatch({ type: LOAN_CREATE_RESET })
      }, 2500)
    }

    if (update) {
      setOpen(false)
      setTimeout(() => {
        dispatch({ type: UPDATE_LOAN_RESET })
      }, 2500)
    }
  }, [dispatch, newLoan, update])

  useEffect(() => {
    if (allLoans) {
      setDatatable({
        columns: [
          {
            label: 'Name',
            field: 'name',
            width: 50,
          },
          {
            label: 'Organization',
            field: 'org',
            width: 100,
          },
          {
            label: 'Loan Duration',
            field: 'duration',
            width: 200,
          },
          {
            label: 'Interest Rate',
            field: 'interest',
            width: 50,
          },
          {
            label: 'Status',
            field: 'status',
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: allLoans.map((item) => ({
          name: item.name,
          org:
            item.organization && item.organization.name
              ? item.organization.name
              : '',
          amount: item.maxAmount ? `NGN ${formatNumber(item.maxAmount)}` : 0,
          duration: item.duration ? `${item.duration} month(s)` : '',
          interest: item.interest ? `${item.interest}% per month` : '',
          status: (
            <div
              className={`badge badge-soft-${item.status ? 'success' : 'danger'
                } font-size-13`}
            >
              {item.status ? 'Active' : 'Inactive'}
            </div>
          ),
          action: <button className="btn btn-sm btn-success" onClick={() => editProvider(item)}>Details</button>,
        })),
      })
    }
  }, [allLoans])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (editorRef.current) {
      let TandC = editorRef.current.getContent()
      dispatch(
        createLoan(
          name,
          manufacturer,
          maxAmount,
          moratoriumPeriod,
          duration,
          interest,
          description,
          TandC,
        ),
      )
    }
  }

  const editProvider = (item) => {
    setOpen(true)
    setItemId(item._id)
    setName(item.name)
    setManufacturer(item.organization && item.organization._id)
    setMaxAmount(item.maxAmount)
    setMoratoriumPeriod(item.moratoriumPeriod)
    setDuration(item.duration)
    setInterest(item.interest)
    setDescription(item.description)
    setTermsAndCondition(item.termsAndConditions)
  }

  const handleEditSubmit = (e) => {
    e.preventDefault()
    if (editorRef.current) {
      let TandC = editorRef.current.getContent()

      console.log(TandC)
      dispatch(
        updateLoan(
          itemId,
          name,
          manufacturer,
          maxAmount,
          moratoriumPeriod,
          duration,
          interest,
          description,
          TandC,
        ),
      )
    }
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Loan Provider</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmtrove</a>
                      </li>
                      <li className="breadcrumb-item active">Loans</li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => setShow(!show)}
                  >
                    <i className="fal fa-plus"></i>{' '}
                    {show ? 'Hide Form' : 'Add Loan Provider'}
                  </button>
                </div>

                {loadingUpdate ? <LoadingBox /> : null}
                {updateError ? (
                  <MessageBox variant="danger">{updateError}</MessageBox>
                ) : null}

                {show && (
                  <div className="card">
                    <div className="card-body">
                      {error && (
                        <MessageBox variant="danger">{error}</MessageBox>
                      )}
                      {newLoan && (
                        <MessageBox variant="success">
                          {newLoan.message}
                        </MessageBox>
                      )}
                      {loading ? (
                        <LoadingBox />
                      ) : (
                        <div className="" id="basic-info">
                          <h4 className="card-title">Basic Information</h4>
                          <p className="card-title-desc">
                            Fill all information below
                          </p>

                          <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                forhtml="productname"
                              >
                                Loan Title
                              </label>
                              <input
                                id="productname"
                                name="productname"
                                type="text"
                                className="form-control"
                                placeholder="Loan title"
                                required
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    forhtml="manufacturername"
                                  >
                                    Organisation / Manufacturer Name
                                  </label>
                                  <select
                                    onChange={(e) =>
                                      setManufacturer(e.target.value)
                                    }
                                    className="select2 form-select"
                                    required
                                  >
                                    <option>Select</option>
                                    {loadingMaufacturer ? (
                                      'Loading...'
                                    ) : manufacturers.length > 0 ? (
                                      <>
                                        {manufacturers &&
                                          manufacturers.map((item) => (
                                            <option
                                              key={item._id}
                                              value={item._id}
                                            >
                                              {item.name}
                                            </option>
                                          ))}
                                      </>
                                    ) : (
                                      'There is no organization'
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label className="form-label" forhtml="price">
                                    Maximum Loan Amount
                                  </label>
                                  <input
                                    id="price"
                                    name="price"
                                    type="number"
                                    className="form-control"
                                    placeholder="Maximum loan amount"
                                    required
                                    onChange={(e) =>
                                      setMaxAmount(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">Duration</label>
                                  <input
                                    className="form-control"
                                    type="number"
                                    placeholder="Loan duration (Months)"
                                    required
                                    onChange={(e) =>
                                      setDuration(e.target.value)
                                    }
                                  />
                                  <small>Duration in month</small>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Interest Rate (%)
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Interest rate per annum"
                                    required
                                    onChange={(e) =>
                                      setInterest(e.target.value)
                                    }
                                  />
                                  <small>Interest rate per annum</small>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Moratorium Period (months)
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Moratorium Period (Months)"
                                    required
                                    onChange={(e) =>
                                      setMoratoriumPeriod(e.target.value)
                                    }
                                  />
                                  <small>Moratorium Period (months)</small>
                                </div>
                              </div>
                            </div>

                            <div className="mb-3">
                              <label
                                className="form-label"
                                forhtml="productdesc"
                              >
                                Loan Description
                              </label>
                              <textarea
                                className="form-control"
                                id="productdesc"
                                rows="3"
                                required
                                onChange={(e) => setDescription(e.target.value)}
                              ></textarea>
                            </div>

                            <div className="col-md-12">
                              <label
                                htmlFor="example-text-input"
                                className="col-md-12 col-form-label"
                              >
                                Terms and Conditions
                              </label>
                              <Editor
                                onInit={(evt, editor) =>
                                  (editorRef.current = editor)
                                }
                                initialValue=""
                                init={{
                                  height: 500,
                                  menubar: false,
                                  plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount',
                                  ],
                                  toolbar:
                                    'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                  content_style:
                                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                }}
                              />
                            </div>

                            <div className="mt-3">
                              <button
                                className="btn btn-sm btn-success"
                                type="submit"
                              >
                                Create Loan
                              </button>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {updatedLoan ? (
                      <MessageBox variant="success">
                        {updatedLoan.message}
                      </MessageBox>
                    ) : null}
                    {loanError && (
                      <MessageBox variant="danger">{loanError}</MessageBox>
                    )}
                    {loadingLoans ? (
                      <div
                        className="tailspin"
                        style={{ height: 'calc(50vh - 50px)' }}
                      >
                        <TailSpin
                          stroke="#98ff98"
                          strokeWidth="3"
                          color="#06bcee"
                        />
                      </div>
                    ) : (
                      <div className="table-responsive-xl">
                        <MDBDataTableV5
                          hover
                          entriesOptions={[5, 10, 20, 25, 50]}
                          entries={10}
                          pagesAmount={4}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                          barReverse
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />


      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <>
          <div className="modal-header">
            <h5 className="modal-title">Edit Loan Provider</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setOpen(false)}
            ></button>
          </div>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label
                  className="form-label"
                  forhtml="productname"
                >
                  Loan Title
                </label>
                <input
                  id="productname"
                  name="productname"
                  type="text"
                  className="form-control"
                  placeholder="Loan title"
                  required
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label
                      className="form-label"
                      forhtml="manufacturername"
                    >
                      Organisation / Manufacturer Name
                    </label>
                    <select
                      onChange={(e) =>
                        setManufacturer(e.target.value)
                      }
                      className="select2 form-select"
                      required
                      value={manufacturer}
                      disabled
                    >
                      <option>Select</option>
                      {loadingMaufacturer ? (
                        'Loading...'
                      ) : manufacturers.length > 0 ? (
                        <>
                          {manufacturers &&
                            manufacturers.map((item) => (
                              <option
                                key={item._id}
                                value={item._id}
                              >
                                {item.name}
                              </option>
                            ))}
                        </>
                      ) : (
                        'There is no organization'
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label" forhtml="price">
                      Maximum Loan Amount
                    </label>
                    <input
                      id="price"
                      name="price"
                      type="number"
                      className="form-control"
                      placeholder="Maximum loan amount"
                      required
                      onChange={(e) =>
                        setMaxAmount(e.target.value)
                      }
                      value={maxAmount}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label className="form-label">Duration</label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Loan duration (Months)"
                      required
                      onChange={(e) =>
                        setDuration(e.target.value)
                      }
                      value={duration}
                    />
                    <small>Duration in month</small>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label className="form-label">
                      Interest Rate (%)
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Interest rate per annum"
                      required
                      onChange={(e) =>
                        setInterest(e.target.value)
                      }
                      value={interest}
                    />
                    <small>Interest rate per annum</small>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label className="form-label">
                      Moratorium Period (months)
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Moratorium Period (Months)"
                      required
                      onChange={(e) =>
                        setMoratoriumPeriod(e.target.value)
                      }
                      value={moratoriumPeriod}
                    />
                    <small>Moratorium Period (months)</small>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label
                  className="form-label"
                  forhtml="productdesc"
                >
                  Loan Description
                </label>
                <textarea
                  className="form-control"
                  id="productdesc"
                  rows="3"
                  required
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                ></textarea>
              </div>

              <div className="col-md-12">
                <label
                  htmlFor="example-text-input"
                  className="col-md-12 col-form-label"
                >
                  Terms and Conditions
                </label>
                <Editor
                  onInit={(evt, editor) =>
                    (editorRef.current = editor)
                  }
                  initialValue={termsAndCondition}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                />
              </div>
              <DialogActions className='mt-4 mb-4'>
                <button
                  type="button"
                  className="btn btn-light btn-sm waves-effect"
                  data-bs-dismiss="modal"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-success btn-sm"
                  type="submit"
                  onClick={handleEditSubmit}
                >
                  Update
                </button>
              </DialogActions>
            </form>
          </DialogContent>
        </>
      </Dialog>
    </div>
  )
}
