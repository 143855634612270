import {
  MANUFACTURERS_LIST_FAIL,
  MANUFACTURERS_LIST_REQUEST,
  MANUFACTURERS_LIST_SUCCESS,
  ADD_MANUFACTURER_FAIL,
  ADD_MANUFACTURER_REQUEST,
  ADD_MANUFACTURER_RESET,
  ADD_MANUFACTURER_SUCCESS,
  DELETE_MANUFACTURER_REQUEST,
  DELETE_MANUFACTURER_SUCCESS,
  DELETE_MANUFACTURER_FAIL,
  DELETE_MANUFACTURER_RESET,
  UPDATE_MANUFACTURER_REQUEST,
  UPDATE_MANUFACTURER_SUCCESS,
  UPDATE_MANUFACTURER_FAIL,
  UPDATE_MANUFACTURER_RESET,
} from '../../../constants/adminConstants/manufacturersConstants'

export const manufacturersListReducer = (
  state = { manufacturers: [] },
  action,
) => {
  switch (action.type) {
    case MANUFACTURERS_LIST_REQUEST:
      return { loading: true }
    case MANUFACTURERS_LIST_SUCCESS:
      return { loading: false, manufacturers: action.payload }
    case MANUFACTURERS_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addManufacturerReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_MANUFACTURER_REQUEST:
      return { loading: true }
    case ADD_MANUFACTURER_SUCCESS:
      return { loading: false, result: action.payload }
    case ADD_MANUFACTURER_FAIL:
      return { loading: false, error: action.payload }
    case ADD_MANUFACTURER_RESET:
      return {}
    default:
      return state
  }
}

export const updateManufacturerReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MANUFACTURER_REQUEST:
      return { loading: true }
    case UPDATE_MANUFACTURER_SUCCESS:
      return { loading: false, result: action.payload }
    case UPDATE_MANUFACTURER_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_MANUFACTURER_RESET:
      return {}
    default:
      return state
  }
}

export const deleteManufacturerReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_MANUFACTURER_REQUEST:
      return { loading: true }
    case DELETE_MANUFACTURER_SUCCESS:
      return { loading: false, success: true }
    case DELETE_MANUFACTURER_FAIL:
      return { loading: false, error: action.payload }
    case DELETE_MANUFACTURER_RESET:
      return {}
    default:
      return state
  }
}
