import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MDBDataTableV5 } from 'mdbreact'
import SweetAlert from 'react-bootstrap-sweetalert'
import { verifyIdentity } from '../../../actions/farmerActions/farmtroveAction/verifyIdentity'
import { loanApplication } from '../../../actions/farmerActions/farmtroveAction/loanApplication'
import { getAllLoans } from '../../../actions/farmerActions/farmtroveAction/getAllLoans'
import {
  formatNumber,
  loanRepayment,
  checkLoanApproveAndStartDate,
} from '../../../components/functions'
import ReactCardFlip from 'react-card-flip'
import { TailSpin } from 'react-loading-icons'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { LoadingBox, MessageBox } from '../../../components'
import { getLoanProviders } from '../../../actions/farmerActions/farmtroveAction/getLoanProviders'
import parse from 'html-react-parser'
import {
  ACCEPT_LOAN_OFFER_RESET,
  LOAN_APPLICATION_RESET,
  PAY_LOAN_RESET,
} from '../../../constants/farmerConstants/farmtrove/loanConstants'
import { acceptoffer } from '../../../actions/farmerActions/farmtroveAction/acceptOffer'
import { allCycles } from '../../../actions/farmerActions/farmcycleActions/allCycles'
import { getWalletBalance } from '../../../actions/authActions/farmtroveAction/getWallet'
import { payLoan } from '../../../actions/farmerActions/farmtroveAction/payLoan'

export default function LoanApplicationPage() {
  const [datatable, setDatatable] = useState({})
  const [alert, setAlert] = useState(null)
  const [show, setShow] = useState(true)
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const [isFlipped, setIsFlipped] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [amount, setAmount] = useState(0)
  const [duration, setDuration] = useState('')
  const [moratoriumPeriod, setMoratoriumPeriod] = useState('')
  const [idType, setIdType] = useState('')
  const [idNumber, setIdNumber] = useState('')
  const [provider, setProvider] = useState('')
  const [providerDetail, setProviderDetail] = useState({})
  const [monthlyPayment, setMonthlyPayment] = useState(0)
  const [repaymentSchedule, setRepaymentSchedule] = useState([])
  const [startDate, setStartDate] = useState()
  const [dueDate, setDueDate] = useState('')
  const [open, setOpen] = useState(false)
  const [openLoanDialog, setOpenLoanDialog] = useState(false)
  const [detail, setDetail] = useState()
  const [selectedEnterprise, setSelectedEnterprise] = useState('')
  const [enterpriseDetails, setEnterpriseDetail] = useState({})
  const [training, setTraining] = useState('')
  const [experience, setExperience] = useState('')
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false)
  const [walletDialog, setWalletDialog] = useState('')
  const [repayAmount, setRepayAmount] = useState('')
  const [loanRepaymentID, setLoanRepaymentId] = useState('')
  const [loanApplicationID, setLoanApplicationID] = useState('')



  // const verify = useSelector((state) => state.verify)
  // const { loading: loadingVerify, verified, error: verifyError } = verify

  const applyLoan = useSelector((state) => state.applyLoan)
  const { loading, application, error } = applyLoan

  const allLoans = useSelector((state) => state.allLoans)
  const { loading: loadingLoans, loans, error: loansError } = allLoans

  const loanProviders = useSelector((state) => state.loanProviders)
  const {
    loading: loadingProviders,
    providers,
    error: providerError,
  } = loanProviders

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const loanOfferStatus = useSelector((state) => state.loanOfferStatus)
  const { loading: loadingOffer, result, error: offerError } = loanOfferStatus

  const listCycles = useSelector((state) => state.listCycles)
  const { loading: loadingEnterprise, cycles: enterprises, error: enterpriseError } = listCycles

  const walletBalance = useSelector((state) => state.walletBalance)
  const { loading: loadingWallet, wallet, error: walletErr } = walletBalance

  const repayLoan = useSelector((state) => state.repayLoan)
  const { loading: loadingPayment, repaid, error: paymentErr } = repayLoan

  const dispatch = useDispatch()

  useEffect(() => {
    setStartDate(moment().format('YYYY-MM-DD'))
    dispatch(getAllLoans())
    dispatch(getLoanProviders())
    dispatch(allCycles())
    dispatch(getWalletBalance())

    if (application) {
      setTimeout(() => {
        setShow(true)
        setShow1(false)
        setShow2(false)
        setShowInfo(false)
        setIsFlipped(false)
        setSelectedEnterprise('')
        setEnterpriseDetail({})
        setExperience('')
        setTraining('')
        setProvider('')
        setProviderDetail({})
        setDetail('')
        setDueDate('')
        setIdNumber('')
        setIdType('')
        setIsDeclarationChecked(false)
        dispatch({ type: LOAN_APPLICATION_RESET })
      }, 2500)
    }

    if (result) {
      setTimeout(() => {
        setOpen(false)
        dispatch({ type: ACCEPT_LOAN_OFFER_RESET })
      }, 2500)
    }

    if (repaid) {
      setTimeout(() => {
        setOpenLoanDialog(false)
        setWalletDialog(false)
        setRepayAmount()
        dispatch({ type: PAY_LOAN_RESET })
      }, 2500)
    }
  }, [dispatch, application, result, repaid])

  useEffect(() => {
    if (loans) {
      setDatatable({
        columns: [
          {
            label: 'Application Date',
            field: 'date',
            width: 50,
          },
          {
            label: 'Amount',
            field: 'amount',
            width: 100,
          },
          {
            label: 'Offered Amount',
            field: 'offerAmount',
            width: 100,
          },
          {
            label: 'Enterprise',
            field: 'enterprise',
            width: 200,
          },
          {
            label: 'Status',
            field: 'status',
            width: 50,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: loans.map((item) => ({
          date: moment(item.applicationDate).format('DD MMM YYYY, hh:mm a'),
          amount: item.loanAmount ? `NGN ${formatNumber(item.loanAmount)}` : 0,
          offerAmount: item.offerAmount
            ? `NGN ${formatNumber(item.offerAmount)}`
            : null,
          enterprise: item.farmCycle && item.farmCycle.name ? item.farmCycle.name : '',
          status: (
            <div
              className={`badge badge-soft-${item.status === 'Pending'
                ? 'primary'
                : item.status === 'Approved'
                  ? 'success'
                  : item.status === 'Reviewing'
                    ? 'warning'
                    : item.status === 'Reject'
                      ? 'danger'
                      : item.status === 'Active'
                        ? 'secondary'
                        : item.status === 'Complete'
                          ? 'secondary'
                          : ''
                } font-size-13`}
            >
              {item.status}
            </div>
          ),
          action: (
            <>
              {item.status === 'Active' &&
                item.offerAmount &&
                item.userAcceptOffer ? (
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => handleLoanDialog(item)}
                >
                  Repayment
                </button>
              ) : (
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => openDialog(item)}
                  disabled={
                    item.status === 'Approved'
                      ? false
                      : item.status === 'Reject'
                        ? false
                        : true
                  }
                >
                  Details
                </button>
              )}
            </>
          ),
        })),
      })
    }
  }, [loans])

  const handleCancel = (e) => {
    e.preventDefault()

    setSelectedEnterprise('')
    setEnterpriseDetail({})
    setTraining('')
    setExperience('')
    setProvider('')
    setProviderDetail({})
    setDetail('')
    setDueDate('')
    setIdNumber('')
    setIdType('')
    setShow(true)
    setShow1(false)
    setShow2(false)
    setShowInfo(false)
    setIsFlipped(false)
    setIsDeclarationChecked(false)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setIdNumber(value)
    if (/^\d{11}$/.test(value)) {
      dispatch(verifyIdentity(idType, value)) // Replace 'YOUR_ACTION_TYPE' with the actual action type.
    } else if (value.length > 10) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Invalid ID Number"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Identification Number can't be more than 10 digits.
        </SweetAlert>,
      )
    } else {
      setIdNumber(value)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(
      loanApplication(selectedEnterprise, amount, duration, idType, idNumber, provider),
    )
  }

  useEffect(() => {
    if (selectedEnterprise) {
      if (enterprises) {
        const result = enterprises.find((item) => item._id === selectedEnterprise)
        setEnterpriseDetail(result)
      }
    }
  }, [selectedEnterprise])


  useEffect(() => {
    if (provider) {
      if (providers && providers.length > 0) {
        const result = providers.find((item) => item._id === provider)
        setProviderDetail(result)
        setAmount(result.maxAmount)
        setDuration(result.duration)
        setMoratoriumPeriod(result.moratoriumPeriod)
        setShowInfo(true)
      }
    }
  }, [provider])


  useEffect(() => {
    if (amount && providerDetail.interest && providerDetail.duration) {
      const result = loanRepayment(amount, providerDetail.interest, providerDetail.duration, startDate, moratoriumPeriod)

      if (result) {
        setMonthlyPayment(result.monthlyPayment)
        setRepaymentSchedule(result.repaymentSchedule)
      }
    }

    if (detail && detail.loanProvider && detail.status === "Approved") {
      const result = loanRepayment(
        detail.offerAmount,
        detail.loanProvider.interest,
        detail.loanProvider.duration,
        startDate,
        detail.loanProvider.moratoriumPeriod
      )

      if (result) {
        setMonthlyPayment(result.monthlyPayment)
        setRepaymentSchedule(result.repaymentSchedule)
        setDueDate(result.dueDate)
      }
    }

    if (detail && detail.loanProvider && detail.status === "Reject") {
      const result = loanRepayment(
        detail.loanProvider.maxAmount,
        detail.loanProvider.interest,
        detail.loanProvider.duration,
        startDate,
        detail.loanProvider.moratoriumPeriod
      )

      if (result) {
        setMonthlyPayment(result.monthlyPayment)
        setRepaymentSchedule(result.repaymentSchedule)
        setDueDate(result.dueDate)
      }
    }
  }, [amount, providerDetail, detail])

  const openDialog = (item) => {
    setDetail(item)
    setOpen(true)
  }

  const handleAcceptOffer = (e) => {
    e.preventDefault()

    const itemId = detail._id
    const status = 'Accept'
    dispatch(acceptoffer(itemId, status, startDate, dueDate, repaymentSchedule))
  }

  const handleDeclineOffer = (e) => {
    e.preventDefault()

    setOpen(false)
    const itemId = detail._id
    const status = 'Reject'

    dispatch(acceptoffer(itemId, status))
  }

  const handleLoanDialog = (item) => {
    setDetail(item)
    setOpenLoanDialog(true)
  }

  function getApprovedLoanOfferAndCount(loans) {
    const activeLoans = loans.filter(
      (loan) =>
        loan.status === 'Active' &&
        loan.startDate &&
        loan.userAcceptOffer &&
        loan.offerAmount &&
        loan.dueDate,
    )

    const activeLoanCount = activeLoans.length

    if (activeLoanCount > 0) {
      const { offerAmount, dueDate } = activeLoans[0] // Retrieving offerAmount and dueDate of the first active loan
      return { offerAmount, dueDate, activeLoanCount }
    } else {
      return { offerAmount: 'No active loan', dueDate: null, activeLoanCount }
    }
  }

  const openWalletDialog = (item) => {
    setOpenLoanDialog(false)
    setWalletDialog(true)
    setLoanRepaymentId(item._id)
    setRepayAmount(item.payment)
    setLoanApplicationID(detail._id)
  }

  const handlePayNow = (e) => {
    e.preventDefault()

    dispatch(payLoan(repayAmount, loanRepaymentID, loanApplicationID))
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-md-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Loan</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmtrove</a>
                      </li>
                      <li className="breadcrumb-item active">Loans</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <div
                      className="card bg-soft-success"
                      style={{ borderRadius: '15px' }}
                    >
                      <div className="card-body">
                        <h3 className="card-title font-size-24">
                          <b>Hello {userInfo.firstName}</b>
                        </h3>
                        <p className="mt-3">
                          Get access to affordable loan on the farmtrove benefit
                          system
                        </p>

                        <p className="">
                          Access the financial support you need to grow your
                          farm operations with ease
                        </p>

                        {loadingLoans ? (
                          ''
                        ) : (
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => setIsFlipped(true)}
                            disabled={
                              checkLoanApproveAndStartDate(loans) ? true : false
                            }
                          >
                            Take a loan
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card" style={{ borderRadius: '15px' }}>
                      {loadingLoans ? (
                        <div
                          className="tailspin"
                          style={{ height: 'calc(15vh - 15px)' }}
                        >
                          <TailSpin
                            stroke="#98ff98"
                            strokeWidth="3"
                            color="#06bcee"
                          />
                        </div>
                      ) : (
                        <div>
                          {loans &&
                            getApprovedLoanOfferAndCount(loans).offerAmount ===
                            'No active loan' ? (
                            <>
                              <div className="card-body">
                                <div className="d-flex justify-content-between">
                                  <div className="flex-1 overflow-hidden">
                                    <div className="">
                                      <p className="text-truncate font-size-14 mb-3">
                                        Active Loan
                                      </p>
                                      <h4 className="mb-2">
                                        {loans &&
                                          getApprovedLoanOfferAndCount(loans)
                                            .offerAmount}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="text-primary ms-auto">
                                    <i className="fal fa-money-check-alt font-size-24"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                  <span className=" font-size-14">
                                    Start your application
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="card-body">
                                <div className="d-flex justify-content-between">
                                  <div className="flex-1 overflow-hidden">
                                    <div
                                      className=""
                                      style={{
                                        height: '60px',
                                        borderRight: '2px solid #f0f0f0',
                                        marginRight: '20px',
                                      }}
                                    >
                                      <p className="text-truncate font-size-14 mb-3">
                                        Active Loan
                                      </p>
                                      <h4 className="mb-2">
                                        {loans &&
                                          getApprovedLoanOfferAndCount(loans)
                                            .activeLoanCount}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-3">
                                      You owe
                                    </p>
                                    <h4 className="mb-2">
                                      NGN{' '}
                                      {loans &&
                                        formatNumber(
                                          getApprovedLoanOfferAndCount(loans)
                                            .offerAmount,
                                        )}
                                    </h4>
                                  </div>

                                  <div className="text-primary ms-auto">
                                    <i className="fal fa-money-check-alt font-size-24"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                  <span className=" font-size-14">
                                    Due Date:
                                  </span>
                                  <span className="text-muted float-end ms-2">
                                    {loans &&
                                      moment(
                                        getApprovedLoanOfferAndCount(loans)
                                          .dueDate,
                                      ).format('DD MMM YYYY')}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-8">
                    <ReactCardFlip
                      isFlipped={isFlipped}
                      flipDirection="horizontal"
                    >
                      <div className="front">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <h5 className="card-title">Loan History</h5>
                                {loansError && (
                                  <MessageBox variant="danger">
                                    {loansError}
                                  </MessageBox>
                                )}
                                {loadingLoans ? (
                                  <div
                                    className="tailspin"
                                    style={{ height: 'calc(50vh - 50px)' }}
                                  >
                                    <TailSpin
                                      stroke="#98ff98"
                                      strokeWidth="3"
                                      color="#06bcee"
                                    />
                                  </div>
                                ) : (
                                  <div className="table-responsive-xl">
                                    <MDBDataTableV5
                                      hover
                                      entriesOptions={[5, 10, 20, 25, 50]}
                                      entries={10}
                                      pagesAmount={4}
                                      data={datatable}
                                      searchTop
                                      searchBottom={false}
                                      barReverse
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        {error && (
                          <MessageBox variant="danger">{error}</MessageBox>
                        )}
                        {loading ? (
                          <div className="card">
                            <div className="card-body">
                              <div
                                className="tailspin"
                                style={{ height: 'calc(50vh - 50px)' }}
                              >
                                <TailSpin
                                  stroke="#98ff98"
                                  strokeWidth="3"
                                  color="#06bcee"
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="">
                            {show && (
                              <div className="card">
                                <div className="card-body">
                                  <div className="">
                                    {providerError && (
                                      <MessageBox variant="danger">
                                        {providerError}
                                      </MessageBox>
                                    )}

                                    <h5 className="card-title">
                                      Get started with your loan!
                                    </h5>
                                    <p className="">
                                      Select an enterprise to get
                                      started in your loan application
                                    </p>
                                    <div className="mb-3 mt-4">
                                      <label
                                        className="form-label"
                                        forhtml="provider"
                                      >
                                        Select an Enterprise
                                      </label>
                                      <select
                                        id="provider"
                                        className="select2 form-select"
                                        onChange={(e) =>
                                          setSelectedEnterprise(e.target.value)
                                        }
                                        placeholder="Select an enterprise?"
                                        value={selectedEnterprise}
                                      >
                                        <option value="">
                                          Select enterprise package
                                        </option>
                                        {loadingEnterprise ? (
                                          'Loading...'
                                        ) : enterprises &&
                                          enterprises.length > 0 ? (
                                          <>
                                            {enterprises &&
                                              enterprises.map((item) => (
                                                <option
                                                  key={item._id}
                                                  value={item._id}
                                                >
                                                  {item.name}
                                                </option>
                                              ))}
                                          </>
                                        ) : (
                                          'There is no loan provider'
                                        )}
                                      </select>
                                    </div>
                                    <div className="mb-3 mt-4">
                                      <label
                                        className="form-label"
                                        forhtml="provider"
                                      >
                                        Do you have any previous training on the
                                        above enterprise?
                                      </label>
                                      <select
                                        id="provider"
                                        className="select2 form-select"
                                        onChange={(e) =>
                                          setTraining(e.target.value)
                                        }
                                        placeholder="Select an enterprise?"
                                        value={training}
                                      >
                                        <option value="">
                                          Select your response
                                        </option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                    </div>

                                    <div className="mb-3 mt-4">
                                      <label
                                        className="form-label"
                                        forhtml="provider"
                                      >
                                        Do you have experience in the
                                        agribusiness you are applying?
                                      </label>
                                      <select
                                        id="provider"
                                        className="select2 form-select"
                                        onChange={(e) =>
                                          setExperience(e.target.value)
                                        }
                                        placeholder="Select an enterprise package?"
                                        value={experience}
                                      >
                                        <option value="">
                                          Select your response
                                        </option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                    </div>

                                    <div className="mb-2 mt-4">
                                      <label
                                        className="form-label"
                                        forhtml="loanAmount"
                                      >
                                        Verification Type (BVN/NIN)
                                      </label>
                                      <select
                                        onChange={(e) =>
                                          setIdType(e.target.value)
                                        }
                                        className="select2 form-select"
                                        required
                                        value={idType}
                                      >
                                        <option selected>
                                          Select verification type
                                        </option>
                                        <option value="bvn">
                                          Bank Verification Number (BVN)
                                        </option>
                                        <option value="nin">
                                          National Identification Number (NIN)
                                        </option>
                                      </select>
                                    </div>

                                    <div className="mb-2 mt-4">
                                      <label
                                        className="form-label"
                                        forhtml="verificationNumber"
                                      >
                                        Verification Number
                                      </label>
                                      <input
                                        id="verificationNumber"
                                        name="verificationNumber"
                                        type="number"
                                        className="form-control"
                                        onChange={handleInputChange}
                                        placeholder="Verifiation Number (BVN/NIN)"
                                        required
                                        value={idNumber}
                                      />
                                    </div>

                                    <div className="mb-3 mt-4">
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={handleCancel}
                                      >
                                        Back
                                      </button>
                                      <div className="float-end">
                                        <button
                                          className="btn btn-sm btn-success"
                                          onClick={() => (
                                            setShow(false), setShow1(true)
                                          )}
                                          disabled={
                                            !selectedEnterprise ||
                                              !training ||
                                              !experience
                                              ? true
                                              : false
                                          }
                                        >
                                          Proceed
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {show1 && (
                              <div className="card">
                                <div className="card-body">
                                  <div className="mb-3">
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => (
                                        setShow(true),
                                        setShow1(false),
                                        setProvider(''),
                                        setShowInfo(false)
                                      )}
                                    >
                                      Back
                                    </button>
                                  </div>

                                  <div className="mb-3 mt-4">
                                    <label
                                      className="form-label"
                                      forhtml="provider"
                                    >
                                      Select Provider
                                    </label>
                                    <select
                                      id="provider"
                                      className="select2 form-select"
                                      onChange={(e) =>
                                        setProvider(e.target.value)
                                      }
                                      placeholder="Select loan provider?"
                                      value={provider}
                                    >
                                      <option value="">
                                        Select loan provider
                                      </option>
                                      {loadingProviders ? (
                                        'Loading...'
                                      ) : providers && providers.length > 0 ? (
                                        <>
                                          {providers &&
                                            providers.map((item) => (
                                              <option
                                                key={item._id}
                                                value={item._id}
                                              >
                                                {item.name}
                                              </option>
                                            ))}
                                        </>
                                      ) : (
                                        'There is no loan provider'
                                      )}
                                    </select>
                                  </div>

                                  {showInfo ? (
                                    <div className="">
                                      <div className="row">
                                        <div className="col-sm-3">
                                          <div
                                            className="card-body bg-soft-secondary"
                                            style={{ borderRadius: '10px' }}
                                          >
                                            <div className="d-flex">
                                              <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-13 mb-2">
                                                  Loan Title
                                                </p>
                                                <h4 className="mb-0 font-size-17">
                                                  {providerDetail &&
                                                    providerDetail.name
                                                    ? providerDetail.name
                                                    : 'Loan Title'}
                                                </h4>
                                              </div>
                                              <div className="text-truncate ms-auto">
                                                <i className="ri-stack-line font-size-24"></i>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-3">
                                          <div
                                            className="card-body bg-soft-warning"
                                            style={{ borderRadius: '10px' }}
                                          >
                                            <div className="d-flex">
                                              <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-13 mb-2">
                                                  Provider
                                                </p>
                                                <h4 className="mb-0 font-size-17">
                                                  {providerDetail &&
                                                    providerDetail.organization &&
                                                    providerDetail.organization
                                                      .name
                                                    ? providerDetail
                                                      .organization.name
                                                    : 'Organization name'}
                                                </h4>
                                              </div>
                                              <div className="text-truncate ms-auto">
                                                <i className="ri-stack-line font-size-24"></i>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-3">
                                          <div
                                            className="card-body bg-soft-info"
                                            style={{ borderRadius: '10px' }}
                                          >
                                            <div className="d-flex">
                                              <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-13 mb-2">
                                                  Duration
                                                </p>
                                                <h4 className="mb-0 font-size-17">
                                                  {providerDetail &&
                                                    providerDetail.duration
                                                    ? `${providerDetail.duration} month(s)`
                                                    : '0 months'}
                                                </h4>
                                              </div>
                                              <div className="text-truncate ms-auto">
                                                <i className="ri-stack-line font-size-24"></i>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-3">
                                          <div
                                            className="card-body bg-soft-success"
                                            style={{ borderRadius: '10px' }}
                                          >
                                            <div className="d-flex">
                                              <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-13 mb-2">
                                                  Interest Rate
                                                </p>
                                                <h4 className="mb-0 font-size-17">
                                                  {providerDetail &&
                                                    providerDetail.interest
                                                    ? `${providerDetail.interest}%`
                                                    : '0%'}
                                                </h4>
                                              </div>
                                              <div className="text-truncate ms-auto">
                                                <i className="ri-stack-line font-size-24"></i>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card bg-soft-danger mt-3">
                                        <div className="card-body">
                                          <h5 className="card-title">
                                            Terms and conditions
                                          </h5>

                                          {providerDetail &&
                                            providerDetail.termsAndConditions
                                            ? parse(
                                              providerDetail.termsAndConditions,
                                            )
                                            : 'Terms and conditions'}
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        {/* Declaration */}
                                        <div className="mt-3 mb-3">
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="flexCheckDefault6"
                                              checked={isDeclarationChecked}
                                              onChange={() =>
                                                setIsDeclarationChecked(!isDeclarationChecked)
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="flexCheckDefault6"
                                            >
                                              I agree to the terms and conditions of this loan
                                            </label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mb-3 mt-4">
                                        <div className="float-start">
                                          <button
                                            className="btn btn-sm btn-success"
                                            onClick={() => (
                                              setShow1(false), setShow2(true)
                                            )}
                                            disabled={!isDeclarationChecked}
                                          >
                                            Proceed
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )}

                            {show2 && (
                              <div className="card">
                                <div className="card-body">
                                  {application && (
                                    <MessageBox variant="success">
                                      {application.message}
                                    </MessageBox>
                                  )}
                                  <div className="mb-5">
                                    <h5 className="card-title">
                                      Review application
                                    </h5>

                                    <p>Let's review your application </p>
                                  </div>

                                  <div className="card">
                                    <div
                                      className="card-body"
                                      style={{
                                        border: '1px solid #0d89c7',
                                        borderRadius: '10px',
                                      }}
                                    >
                                      <div className="standard-body">
                                        Application Details
                                      </div>

                                      <div className="mt-4">
                                        <div className="row">
                                          <div className="col-sm-3">
                                            <div
                                              className=""
                                              style={{
                                                marginTop: '12px',
                                              }}
                                            >
                                              <p className="mb-1 card-title font-size-14">
                                                <i className="fal fa-money-bill-wave me-2"></i>
                                                Enterprise Name
                                              </p>
                                              <p className="m-0 font-size-16 card-desc">
                                                <b>
                                                  {enterpriseDetails && enterpriseDetails.name ? enterpriseDetails.name : null}
                                                </b>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-3">
                                            <div
                                              className=""
                                              style={{
                                                marginTop: '12px',
                                              }}
                                            >
                                              <p className="mb-1 card-title font-size-14">
                                                <i className="fal fa-lightbulb me-2"></i>
                                                Enterprise E.O.P
                                              </p>
                                              <p className="m-0 font-size-16 card-desc">
                                                NGN
                                                <b>{enterpriseDetails && enterpriseDetails.eopTotalSum ? formatNumber(enterpriseDetails.eopTotalSum) : 0}</b>.00
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-3">
                                            <div
                                              className=""
                                              style={{
                                                marginTop: '12px',
                                              }}
                                            >
                                              <p className="mb-1 card-title font-size-14">
                                                <i className="fal fa-calendar me-2"></i>
                                                Enterprise duration
                                              </p>
                                              <p className="m-0 font-size-16 card-desc">
                                                <b>{enterpriseDetails && moment(enterpriseDetails.endDate).diff(moment(enterpriseDetails.startDate), 'days', true)} Days</b>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-3"></div>
                                        </div>

                                        <hr className="my-3" />

                                        <div className="">
                                          <div className="row">
                                            <div className="col-sm-4">
                                              <div className="mt-3">
                                                <p className="card-desc font-size-14 m-0 mb-2">
                                                  Name:
                                                </p>
                                                <h5 className="card-title m-0">
                                                  {userInfo &&
                                                    userInfo.firstname &&
                                                    userInfo.lastname
                                                    ? `${userInfo.firstName} ${userInfo.lastName}`
                                                    : 'Null'}
                                                </h5>
                                              </div>
                                            </div>
                                            <div className="col-sm-4">
                                              <div className="mt-3">
                                                <p className="card-desc font-size-14 m-0 mb-2">
                                                  Email:
                                                </p>
                                                <h5 className="card-title m-0">
                                                  {userInfo.email || 'Null'}
                                                </h5>
                                              </div>
                                            </div>
                                            <div className="col-md-4"></div>
                                          </div>

                                          <div className="row mb-4">
                                            <div className="col-sm-4">
                                              <div className="mt-3">
                                                <p className="card-desc font-size-14 m-0 mb-2">
                                                  Phone Number:
                                                </p>
                                                <h5 className="card-title m-0">
                                                  {userInfo.phone || 'Null'}
                                                </h5>
                                              </div>
                                            </div>
                                            <div className="col-sm-4">
                                              <div className="mt-3">
                                                <p className="card-desc font-size-14 m-0 mb-2">
                                                  Address:
                                                </p>
                                                <h5 className="card-title m-0">
                                                  {userInfo.address || 'Null'}
                                                </h5>
                                              </div>
                                            </div>
                                            <div className="col-md-4"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="card bg-soft-info"
                                    style={{ borderRadius: '10px' }}
                                  >
                                    <div className="card-body">
                                      <h5 className="card-title">
                                        Loan Repayment Plan
                                      </h5>
                                      <div className="mt-4">
                                        <div className="row">
                                          <div className="col-sm-3">
                                            <div
                                              className=""
                                              style={{
                                                marginTop: '10px',
                                              }}
                                            >
                                              <p className="mb-1 card-title font-size-14">
                                                <i className="fal fa-money-bill-wave me-2"></i>
                                                Principal Amount
                                              </p>
                                              <p className="m-0 font-size-16 card-desc">
                                                <b>
                                                  NGN{' '}
                                                  {amount &&
                                                    formatNumber(amount)}
                                                  .00
                                                </b>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-3">
                                            <div
                                              className=""
                                              style={{
                                                marginTop: '10px',
                                              }}
                                            >
                                              <p className="mb-1 card-title font-size-14">
                                                <i className="fal fa-lightbulb me-2"></i>
                                                Interest rate
                                              </p>
                                              <p className="m-0 font-size-16 card-desc">
                                                <b>{`${providerDetail &&
                                                  providerDetail.interest
                                                  }%`}</b>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-3">
                                            <div
                                              className=""
                                              style={{
                                                marginTop: '10px',
                                              }}
                                            >
                                              <p className="mb-1 card-title font-size-16">
                                                <i className="fal fa-calendar me-2"></i>
                                                Loan Term
                                              </p>
                                              <p className="m-0 font-size-16 card-desc">
                                                <b>{`${duration} month(s)`}</b>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-3">
                                            <div
                                              className=""
                                              style={{
                                                marginTop: '10px',
                                              }}
                                            >
                                              <p className="mb-1 card-title font-size-16">
                                                <i className="fal fa-credit-card me-2"></i>
                                                Moratorium Period
                                              </p>
                                              <p className="m-0 font-size-16 card-desc">
                                                <b>{moratoriumPeriod ? moratoriumPeriod : 0} Month(s)</b>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-3"></div>
                                        </div>

                                        <hr className="my-3" />
                                      </div>
                                      <h6 className="font-size-14">
                                        Repayment Schedule
                                      </h6>
                                      {repaymentSchedule &&
                                        repaymentSchedule.length > 0
                                        ? repaymentSchedule.map((item, i) => (
                                          <div className="card task-box" key={1}>
                                            <div
                                              className="progress progress-sm animated-progess"
                                              style={{ height: '3px' }}
                                            >
                                              <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: '72%' }}
                                                aria-valuenow="72"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              ></div>
                                            </div>
                                            <div className="card-body">
                                              <div className="mb-3">
                                                <a href="#" className="">
                                                  Repayment
                                                </a>
                                              </div>
                                              <div className="row">
                                                <div className="col-md-3">
                                                  <p className="mb-1 font-size-13">
                                                    Month
                                                  </p>
                                                  <h5 className="font-size-14">
                                                    Month {item.month}
                                                  </h5>
                                                </div>
                                                <div className="col-md-3">
                                                  <p className="mb-1 font-size-13">
                                                    Principal Payment
                                                  </p>
                                                  <h5 className="font-size-14">
                                                    {item.principal &&
                                                      `NGN ${formatNumber(
                                                        item.principal,
                                                      )}`}
                                                  </h5>
                                                </div>
                                                <div className="col-md-3">
                                                  <p className="mb-1 font-size-13">
                                                    Interest Payment
                                                  </p>
                                                  <h5 className="font-size-14">
                                                    {item.interest &&
                                                      `NGN ${formatNumber(
                                                        item.interest,
                                                      )}`}
                                                  </h5>
                                                </div>
                                                <div className="col-md-3">
                                                  <p className="mb-1 font-size-13">
                                                    Total Repayment
                                                  </p>
                                                  <h5 className="font-size-14">
                                                    {item.payment &&
                                                      `NGN ${formatNumber(
                                                        item.payment,
                                                      )}`}
                                                  </h5>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))
                                        : null}
                                    </div>
                                  </div>


                                  <div className='float-end'>
                                    <button
                                      className="btn btn-sm btn-danger me-4"
                                      onClick={handleCancel}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="btn btn-sm btn-success"
                                      onClick={handleSubmit}
                                    >
                                      Submit Application
                                    </button>
                                  </div>

                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </ReactCardFlip>
                  </div>

                  <div className="col-md-4">
                    <div
                      className="card bg-soft-info"
                      style={{ borderRadius: '15px' }}
                    >
                      <div className="card-body">
                        <i
                          className="fal fa-tachometer mb-4"
                          style={{ fontSize: '40px' }}
                        ></i>

                        <h2 className="card-title font-size-24 font-bold">
                          Get your credit score for free
                        </h2>
                        <p>You are just one step from getting your score</p>

                        <button className="btn btn-sm btn-success mt-4">
                          Get and track your Credit Score
                        </button>
                      </div>
                    </div>

                    <div className="">
                      <img
                        src="assets/images/Frame_2.png"
                        alt="banner"
                        className="farmtrove-banner"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title">Loan Application Details</h5>
        </div>
        {loadingOffer ? (
          <div className="tailspin" style={{ height: 'calc(50vh - 50px)' }}>
            <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
          </div>
        ) : (
          <DialogContent>
            {offerError && (
              <MessageBox variant="danger">{offerError}</MessageBox>
            )}
            {result && (
              <MessageBox variant="success">{result.message}</MessageBox>
            )}
            {detail && detail.offerAmount ? (
              <div className="">
                <div className="mb-5">
                  <h1 className="display-6 fw-bold">Congratulations!</h1>
                  <p className="col-md-8 fs-5 card-desc font-size-15">
                    You have been approved for a loan offer of{' '}
                    {detail && detail.loanAmount
                      ? `NGN ${formatNumber(detail.offerAmount)}`
                      : 'NGN 0.00'}
                  </p>

                  <div className="">
                    <button
                      type="button"
                      className="btn btn-success me-2"
                      onClick={handleAcceptOffer}
                    >
                      Accept
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger me-2"
                      onClick={handleDeclineOffer}
                    >
                      Reject
                    </button>
                  </div>
                </div>

                <div className="card mt-3">
                  <div
                    className="card-body"
                    style={{
                      border: '1px solid #0d89c7',
                      borderRadius: '10px',
                    }}
                  >
                    <div className="standard-body">Application Details</div>

                    <div className="mt-4">
                      <div className="row">
                        <div className="col-sm-2">
                          <div
                            className=""
                            style={{
                              marginTop: '12px',
                            }}
                          >
                            <p className="mb-1 card-title font-size-14">
                              <i className="fal fa-money-bill-wave me-2"></i>
                              Loan Amount
                            </p>
                            <p className="m-0 font-size-16 card-desc">
                              <b>
                                NGN{' '}
                                {detail &&
                                  detail.offerAmount &&
                                  formatNumber(detail.offerAmount)}
                                .00
                              </b>
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div
                            className=""
                            style={{
                              marginTop: '12px',
                            }}
                          >
                            <p className="mb-1 card-title font-size-14">
                              <i className="fal fa-calendar me-2"></i>
                              Loan duration
                            </p>
                            <p className="m-0 font-size-16 card-desc">
                              <b>{`${detail && detail.loanProvider.duration && detail.loanProvider.duration} month(s)`}</b>
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div
                            className=""
                            style={{
                              marginTop: '12px',
                            }}
                          >
                            <p className="mb-1 card-title font-size-14">
                              <i className="fal fa-lightbulb me-2"></i>
                              Enterprise Name
                            </p>
                            <p className="m-0 font-size-16 card-desc">
                              <b>{detail && detail.farmCycle && detail.farmCycle.name}</b>
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div
                            className=""
                            style={{
                              marginTop: '12px',
                            }}
                          >
                            <p className="mb-1 card-title font-size-14">
                              <i className="fal fa-calendar me-2"></i>
                              Start Date
                            </p>
                            <p className="m-0 font-size-16 card-desc">
                              <b>{moment().format('DD MMM YYYY')}</b>
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div
                            className=""
                            style={{
                              marginTop: '12px',
                            }}
                          >
                            <p className="mb-1 card-title font-size-14">
                              <i className="fal fa-calendar me-2"></i>
                              Estimated payoff date:
                            </p>
                            <p className="m-0 font-size-16 card-desc">
                              <b>
                                {dueDate
                                  ? moment(dueDate).format('DD MMM YYYY')
                                  : 'Date'}
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card bg-soft-info"
                  style={{ borderRadius: '10px' }}
                >
                  <div className="card-body">
                    <h5 className="card-title">Loan Repayment Plan</h5>
                    <p>
                      <b>Note:</b> The actual cost is subject to the transation
                      data
                    </p>

                    <div className="mt-4">
                      <div className="row">
                        <div className="col-sm-3">
                          <div
                            className=""
                            style={{
                              marginTop: '10px',
                            }}
                          >
                            <p className="mb-1 card-title font-size-14">
                              <i className="fal fa-money-bill-wave me-2"></i>
                              Principal Amount
                            </p>
                            <p className="m-0 font-size-16 card-desc">
                              <b>
                                NGN{' '}
                                {detail &&
                                  detail.offerAmount &&
                                  formatNumber(detail.offerAmount)}
                                .00
                              </b>
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div
                            className=""
                            style={{
                              marginTop: '10px',
                            }}
                          >
                            <p className="mb-1 card-title font-size-14">
                              <i className="fal fa-lightbulb me-2"></i>
                              Interest rate
                            </p>
                            <p className="m-0 font-size-16 card-desc">
                              <b>{`${detail &&
                                detail.loanProvider &&
                                detail.loanProvider.interest
                                ? detail.loanProvider.interest
                                : '0'
                                }%`}</b>
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div
                            className=""
                            style={{
                              marginTop: '10px',
                            }}
                          >
                            <p className="mb-1 card-title font-size-16">
                              <i className="fal fa-calendar me-2"></i>
                              Loan duration
                            </p>
                            <p className="m-0 font-size-16 card-desc">
                              <b>{`${detail && detail.loanProvider.duration} month(s)`}</b>
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div
                            className=""
                            style={{
                              marginTop: '10px',
                            }}
                          >
                            <p className="mb-1 card-title font-size-16">
                              <i className="fal fa-credit-card me-2"></i>
                              Moratorium Period
                            </p>
                            <p className="m-0 font-size-16 card-desc">
                              <b>
                                {detail && detail.loanProvider.moratoriumPeriod} Month(s)
                              </b>
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-3"></div>
                      </div>

                      <hr className="my-3" />
                    </div>
                    <h6 className="font-size-14">Repayment Schedule</h6>
                    {repaymentSchedule && repaymentSchedule.length > 0
                      ? repaymentSchedule.map((item, i) => (
                        <div className="card task-box" key={i}>
                          <div
                            className="progress progress-sm animated-progess"
                            style={{ height: '3px' }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: '72%' }}
                              aria-valuenow="72"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div className="card-body">
                            <div className="float-end ms-2">
                              <div>
                                <p>
                                  <b>Due Date: </b>
                                  {moment(item.date).format('DD MMM YYYY')}
                                </p>
                              </div>
                            </div>
                            <div className="mb-3">
                              <a href="#" className="">
                                Repayment {item.month}/
                                {repaymentSchedule.length}
                              </a>
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                <p className="mb-1 font-size-13">Month</p>
                                <h5 className="font-size-14">
                                  Month {item.month}
                                </h5>
                              </div>
                              <div className="col-md-3">
                                <p className="mb-1 font-size-13">
                                  Principal Payment
                                </p>
                                <h5 className="font-size-14">
                                  {`NGN ${formatNumber(item.principal)}`}
                                </h5>
                              </div>
                              <div className="col-md-3">
                                <p className="mb-1 font-size-13">
                                  Interest Payment
                                </p>
                                <h5 className="font-size-14">
                                  {`NGN ${formatNumber(item.interest)}`}
                                </h5>
                              </div>
                              <div className="col-md-3">
                                <p className="mb-1 font-size-13">
                                  Total Repayment
                                </p>
                                <h5 className="font-size-14">
                                  {`NGN ${formatNumber(item.payment)}`}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                      : null}
                  </div>
                </div>
              </div>
            ) : null}

            {detail && detail.status === 'Reject' ? (
              <div className="">
                <div className="mb-5">
                  <h4 className="display-6 fw-bold">We are sorry!</h4>
                  <p className="col-md-8 fs-5 card-desc font-size-15">
                    {detail.userAcceptOffer === 'Reject'
                      ? 'You rejected the loan amount offered'
                      : 'Your loan application has been rejected'}
                  </p>

                  <p>
                    As responsible lender we want to make sure that you are able
                    to easily repay the loan you applied for. Based on the
                    information you provided us with we are afraid that this
                    loan could cause your problem.
                  </p>

                  <h6>
                    <b> Most common reasons are </b>
                  </h6>
                  <p className="m-0">- Low credit score</p>
                  <p className="m-0">- Be included in delinguency files</p>
                  <p className="m-0">
                    - Have some other loans open at this time
                  </p>
                  <p className="mt-3">
                    {' '}
                    We would like to offer you a loan in the future. so do not
                    hesitate to contact us again after a while. Or if you have
                    had an error you have corrected later, we would like to know
                  </p>
                  {detail && detail.rejectionReason ? (
                    <>
                      <p>Reason</p>
                      <div className="card">
                        <div className="card-body bg-soft-danger">
                          <p className="">{detail && detail.rejectionReason}</p>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="card mt-5">
                    <div
                      className="card-body"
                      style={{
                        border: '1px solid #0d89c7',
                        borderRadius: '10px',
                      }}
                    >
                      <div className="standard-body">Application Details</div>

                      <div className="mt-4">
                        <div className="row">
                          <div className="col-sm-3">
                            <div
                              className=""
                              style={{
                                marginTop: '12px',
                              }}
                            >
                              <p className="mb-1 card-title font-size-14">
                                <i className="fal fa-money-bill-wave me-2"></i>
                                Loan Amount
                              </p>
                              <p className="m-0 font-size-16 card-desc">
                                <b>
                                  NGN{' '}
                                  {detail &&
                                    detail.loanAmount &&
                                    formatNumber(detail.loanAmount)}
                                  .00
                                </b>
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className=""
                              style={{
                                marginTop: '12px',
                              }}
                            >
                              <p className="mb-1 card-title font-size-14">
                                <i className="fal fa-lightbulb me-2"></i>
                                Enterprise
                              </p>
                              <p className="m-0 font-size-16 card-desc">
                                <b>{detail && detail.farmCycle && detail.farmCycle.name ? detail.farmCycle.name : ""}</b>
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className=""
                              style={{
                                marginTop: '12px',
                              }}
                            >
                              <p className="mb-1 card-title font-size-14">
                                <i className="fal fa-calendar me-2"></i>
                                Loan duration
                              </p>
                              <p className="m-0 font-size-16 card-desc">
                                <b>{`${detail && detail.loanProvider.duration} month(s)`}</b>
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div
                              className=""
                              style={{
                                marginTop: '10px',
                              }}
                            >
                              <p className="mb-1 card-title font-size-14">
                                <i className="fal fa-lightbulb me-2"></i>
                                Interest rate
                              </p>
                              <p className="m-0 font-size-16 card-desc">
                                <b>{`${detail &&
                                  detail.loanProvider &&
                                  detail.loanProvider.interest
                                  }%`}</b>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="float-end ">
                    <button
                      type="button"
                      className="btn btn-danger mb-4"
                      onClick={() => setOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </DialogContent>
        )}
      </Dialog>

      {/* Repayment Plan Dialog */}
      <Dialog
        open={openLoanDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title">Repayment plan</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setOpenLoanDialog(false)}
          ></button>
        </div>
        {loadingOffer ? (
          <div className="tailspin" style={{ height: 'calc(50vh - 50px)' }}>
            <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
          </div>
        ) : (
          <DialogContent>
            <div className="card mt-5">
              <div
                className="card-body"
                style={{
                  border: '1px solid #0d89c7',
                  borderRadius: '10px',
                }}
              >
                <div className="standard-body">Application Details</div>

                <div className="mt-4">
                  <div className="row">
                    <div className="col-sm-2">
                      <div
                        className=""
                        style={{
                          marginTop: '12px',
                        }}
                      >
                        <p className="mb-1 card-title font-size-14">
                          <i className="fal fa-money-bill-wave me-2"></i>
                          Loan Amount
                        </p>
                        <p className="m-0 font-size-16 card-desc">
                          <b>
                            NGN{' '}
                            {detail &&
                              detail.offerAmount &&
                              formatNumber(detail.offerAmount)}
                            .00
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div
                        className=""
                        style={{
                          marginTop: '12px',
                        }}
                      >
                        <p className="mb-1 card-title font-size-14">
                          <i className="fal fa-lightbulb me-2"></i>
                          Enterprise
                        </p>
                        <p className="m-0 font-size-16 card-desc">
                          <b>{detail && detail.farmCycle && detail.farmCycle.name ? detail.farmCycle.name : ""}</b>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div
                        className=""
                        style={{
                          marginTop: '12px',
                        }}
                      >
                        <p className="mb-1 card-title font-size-14">
                          <i className="fal fa-calendar me-2"></i>
                          Loan duration
                        </p>
                        <p className="m-0 font-size-16 card-desc">
                          <b>{`${detail && detail.loanProvider.duration} month(s)`}</b>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div
                        className=""
                        style={{
                          marginTop: '12px',
                        }}
                      >
                        <p className="mb-1 card-title font-size-14">
                          <i className="fal fa-calendar me-2"></i>
                          Start Date
                        </p>
                        <p className="m-0 font-size-16 card-desc">
                          <b>
                            {detail &&
                              detail.startDate &&
                              moment(detail.startDate).format('DD MMM YYYY')}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div
                        className=""
                        style={{
                          marginTop: '12px',
                        }}
                      >
                        <p className="mb-1 card-title font-size-14">
                          <i className="fal fa-calendar me-2"></i>
                          Estimated payoff date:
                        </p>
                        <p className="m-0 font-size-16 card-desc">
                          <b>
                            {detail &&
                              detail.dueDate &&
                              moment(detail.dueDate).format('DD MMM YYYY')}
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card bg-soft-info" style={{ borderRadius: '10px' }}>
              <div className="card-body">
                <h5 className="card-title">Loan Repayment Plan</h5>
                <p>
                  <b>Note:</b> The actual cost is subject to the transation data
                </p>

                <div className="mt-4">
                  <div className="row">
                    <div className="col-sm-3">
                      <div
                        className=""
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        <p className="mb-1 card-title font-size-14">
                          <i className="fal fa-money-bill-wave me-2"></i>
                          Principal Amount
                        </p>
                        <p className="m-0 font-size-16 card-desc">
                          <b>
                            NGN{' '}
                            {detail &&
                              detail.offerAmount &&
                              formatNumber(detail.offerAmount)}
                            .00
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div
                        className=""
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        <p className="mb-1 card-title font-size-14">
                          <i className="fal fa-lightbulb me-2"></i>
                          Interest rate
                        </p>
                        <p className="m-0 font-size-16 card-desc">
                          <b>{`${detail &&
                            detail.loanProvider &&
                            detail.loanProvider.interest
                            ? detail.loanProvider.interest
                            : '0'
                            }%`}</b>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div
                        className=""
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        <p className="mb-1 card-title font-size-16">
                          <i className="fal fa-calendar me-2"></i>
                          Loan duration
                        </p>
                        <p className="m-0 font-size-16 card-desc">
                          <b>{`${detail && detail.loanProvider.duration} month(s)`}</b>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>

                  <hr className="my-3" />
                </div>
                <h6 className="font-size-14">Repayment Schedule</h6>
                {detail &&
                  detail.repaymentSchedule &&
                  detail.repaymentSchedule.length > 0
                  ? detail.repaymentSchedule.map((item, i) => (
                    <div className="card task-box" key={i}>
                      <div
                        className="progress progress-sm animated-progess"
                        style={{ height: '3px' }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: '72%' }}
                          aria-valuenow="72"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="card-body">
                        <div className="float-end ms-2">
                          <div>
                            <p>
                              <b>Due Date: </b>
                              {moment(item.date).format('DD MMM YYYY')}
                            </p>
                          </div>
                        </div>
                        <div className="mb-3">
                          <a href="#" className="">
                            Repayment {item.month}/{repaymentSchedule.length}
                          </a>
                        </div>
                        <div className="row">
                          <div className="col-md-1">
                            <p className="mb-1 font-size-13">Month</p>
                            <h5 className="font-size-14">
                              Month {item.month}
                            </h5>
                          </div>
                          <div className="col-md-3">
                            <p className="mb-1 font-size-13">
                              Principal Payment
                            </p>
                            <h5 className="font-size-14">
                              {item.principal &&
                                `NGN ${formatNumber(item.principal)}`}
                            </h5>
                          </div>
                          <div className="col-md-3">
                            <p className="mb-1 font-size-13">
                              Interest Payment
                            </p>
                            <h5 className="font-size-14">
                              {item.interest &&
                                `NGN ${formatNumber(item.interest)}`}
                            </h5>
                          </div>
                          <div className="col-md-3">
                            <p className="mb-1 font-size-13">Amount Due</p>
                            <h5 className="font-size-14">
                              {item.payment && `NGN ${formatNumber((item.payment).toFixed(2))}`}
                            </h5>
                          </div>
                          <div className="col-md-2">
                            {
                              item.payment !== 0 ?
                                <button className="btn btn-sm btn-success" onClick={() => openWalletDialog(item)} disabled={item.status === 'Paid' ? true : false}>
                                  Repay Now
                                </button>
                                : null
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                  : null}
              </div>
            </div>
            <DialogActions>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => setOpenLoanDialog(false)}
              >
                Close
              </button>
            </DialogActions>
          </DialogContent>
        )}
      </Dialog>

      <Dialog
        open={walletDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title">Repay Loan</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setWalletDialog(false)}
          ></button>
        </div>
        {loadingPayment ? <LoadingBox /> : null}
        <DialogContent>
          {paymentErr && <MessageBox variant="danger">{paymentErr} </MessageBox>}
          {repaid && <MessageBox variant="success">{repaid.message} </MessageBox>}
          <div>
            <div className="row">
              <div className="col-md-6">
                <div
                  className="card bg-soft-success"
                  style={{ borderRadius: '1rem' }}
                >
                  <div className="card-body">
                    {loadingWallet ? (
                      <div
                        className="tailspin"
                        style={{ height: 'calc(20vh - 20px)' }}
                      >
                        <TailSpin
                          stroke="#98ff98"
                          strokeWidth="3"
                          color="white"
                        />
                      </div>
                    ) : (
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <h5 className="card-title balance-title">
                            Current Balance
                          </h5>

                          <div>
                            <h4 className="wallet-bal">
                              ₦
                              {wallet ? formatNumber(wallet.balance) : '0.00'}
                            </h4>
                          </div>
                        </div>

                        <div className="ms-auto">
                          <i className="fal fa-money-bill-alt font-size-24"></i>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-1 mt-1">
                  <label className="form-label" forhtml="amount">
                    Amount
                  </label>
                  <input
                    id="amount"
                    name="amount"
                    type="number"
                    className="form-control"
                    required
                    disabled
                    onChange={(e) => setRepayAmount(e.target.value)}
                    value={repayAmount}
                    placeholder="NGN 200.00 - 5,000,000.00"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-2 mb-4">
              <div className="d-grid">
                <button
                  className="btn btn-md btn-success mt-2"
                  type="submit"
                  onClick={handlePayNow}
                >
                  <i className="fal fa-paper-plane me-3"></i> PAY NOW
                </button>
              </div>
            </div>
          </div>


        </DialogContent>
      </Dialog>

      {alert}
      <Footer />
    </div>
  )
}
