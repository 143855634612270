import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { validate } from '../../actions/authActions/validate'
import { LoadingBox, MessageBox } from '../../components'
import { STATUS_VALIDATION_RESET } from '../../constants/authConstants/statusConstants'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import moment from 'moment'
import { CountdownStatus } from '../../components/Counter/CountdownTimer'
import { report } from '../../actions/authActions/report'
import { REPORT_SUBMISSION_RESET } from '../../constants/authConstants/reportConstants'
import { allService } from '../../actions/authActions/allServices'
import { benefitStatus } from '../../components/functions'

export default function StatusPage() {
  const [tag, setTag] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [openUserDialog, setOpenUserDialog] = useState(false)
  const [openReportDialog, setOpenReportDialog] = useState(false)
  const [uniqueID, setUniqueID] = useState('')
  const [dateOfReport, setDateOfReport] = useState('')
  const [category, setCategory] = useState('')
  const [feature, setFeature] = useState('')
  const [message, setMessage] = useState('')
  const [bsp, setBSP] = useState('')
  const [bspName, setBSPName] = useState('')
  const [bspCode, setBSPCode] = useState('')
  const [tagPin, setTagPIn] = useState('')
  const [services, setServices] = useState([])
  const [Items, setItems] = useState([])

  const dispatch = useDispatch()

  const validateStatus = useSelector((state) => state.validateStatus)
  const { loading, error, result } = validateStatus

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const sentReport = useSelector((state) => state.sentReport)
  const {
    loading: loadingReport,
    error: errorReporting,
    submittedReport,
  } = sentReport

  const listAllServices = useSelector((state) => state.listAllServices)
  const { allservices, error: servicesError } = listAllServices

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(validate(tag))
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
    setOpenUserDialog(false)
    dispatch({ type: STATUS_VALIDATION_RESET })
  }

  const handleReport = () => {
    setOpenReportDialog(true)
    setOpenDialog(false)
    setOpenUserDialog(false)
    if (result.user) {
      setUniqueID(result && result.user.phone ? result.user.phone : null)
    }
    if (result.tag) {
      setUniqueID(
        result && result.tag && result.tag.farmer
          ? result.tag.farmer.phone
          : null,
      )
      setTagPIn(result && result.tag && result.tag.tag ? result.tag.tag : null)
    }

    setDateOfReport(moment().format('DD-MM-YYYY hh:mm a'))
  }

  const handleReportDialogClose = () => {
    setOpenReportDialog(false)
    dispatch({ type: STATUS_VALIDATION_RESET })
  }

  const handleReportSubmit = (e) => {
    e.preventDefault()

    dispatch(
      report(uniqueID, dateOfReport, category, message, bsp, bspCode, tagPin),
    )
  }

  const dateTime = (item) => {
    const c =
      item.batch && item.batch.product && item.batch.product.category
        ? item.batch.product.category.cycleDuration
        : 0

    let hrs = c * 60 * 60 * 1000

    const d = new Date(item.redActivatedAt)
    let ms = d.getTime()
    return ms + hrs
  }

  const date = new Date().getFullYear()

  useEffect(() => {
    if (result && result.tag) {
      setOpenDialog(true)
    }
    if (result && result.labels) {
      setOpenUserDialog(true)
    }

    if (submittedReport) {
      dispatch({ type: STATUS_VALIDATION_RESET })
      setOpenReportDialog(false)
      setOpenDialog(false)
      setOpenUserDialog(false)
      setTimeout(() => {
        dispatch({ type: REPORT_SUBMISSION_RESET })
      }, 2500)
    }
  }, [result, submittedReport])

  useEffect(() => {
    dispatch(allService())
  }, [dispatch])

  useMemo(() => {
    if (allservices && userInfo) {
      const sp = allservices.find((item) => item.email === userInfo.email)
      if (sp) {
        setBSPName(sp.name ? sp.name : '')
        setBSP(sp._id)
        setBSPCode(sp.uniqueCode)
        setServices(sp.benefit)
      }
    }
  }, [allservices])

  useEffect(() => {
    if (category) {
      const item = services.find((item) => item.benefit === category)
      if (item) return setItems(item.features)
    }
  }, [category])

  return (
    <div className="auth-body-bg">
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col-lg-4">
            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div className="w-100">
                {error ? (
                  <MessageBox variant="danger">{error}</MessageBox>
                ) : null}

                {servicesError ? (
                  <MessageBox variant="danger">{servicesError}</MessageBox>
                ) : null}
                {submittedReport ? (
                  <MessageBox variant="success">
                    {submittedReport.message}
                  </MessageBox>
                ) : null}
                {loadingReport ? <LoadingBox /> : null}
                <div className="row justify-content-center">
                  <div className="w-100">
                    <div className="row justify-content-center">
                      <div className="col-lg-9">
                        <div>
                          <div className="text-center">
                            <div>
                              <a href="/" className="">
                                <img
                                  src="/assets/images/farmtrove_benefits.png"
                                  alt=""
                                  height="50"
                                  className="auth-logo farmtrove-logo logo-dark mx-auto"
                                />
                                <img
                                  src="/assets/images/farmtrove_benefits.png"
                                  alt=""
                                  height="50"
                                  className="auth-logo farmtrove-logo logo-light mx-auto"
                                />
                              </a>
                            </div>

                            <h4 className="font-size-14 mt-4">
                              <b>
                               Verification Portal
                              </b>
                            </h4>
                            <h4 className="text-muted status-text">
                              ENTER USER ID / PINCODE TO VERIFY BENEFITS STATUS
                            </h4>
                          </div>

                          <div className="p-2 mt-2">
                            {loading ? (
                              <LoadingBox />
                            ) : (
                              <form className="" onSubmit={handleSubmit}>
                                <div className="mb-3 auth-form-group-custom mb-4">
                                  <i className="fal fa-id-card auti-custom-input-icon"></i>
                                  <label htmlFor="pin">
                                    Unique ID / Pin Code
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="pin"
                                    placeholder="Enter Unique ID / Pin Code"
                                    required
                                    onChange={(e) => setTag(e.target.value)}
                                  />
                                </div>

                                <div className="mt-4 text-center check">
                                  <button
                                    className="btn btn-success  w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    Check
                                  </button>
                                </div>
                              </form>
                            )}
                          </div>

                          <div className="mt-3 text-center">
                            <p>
                              © {date} Farmsured
                              <sup>
                                <small>TM</small>
                              </sup>
                              {''} A product of AgDyna
                              <sup>
                                <i className="fal fa-registered font-size-12"></i>
                              </sup>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="status-bg">
              <div className="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>

      {result && result.tag ? (
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={'md'}
        >
          <div className="modal-header mb-3">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleDialogClose}
            ></button>
          </div>
          <DialogContent>
            <div className="row">
              <div className="col-md-6">
                <header className="text-center">
                  <img
                    src={
                      result &&
                      result.tag &&
                      result.tag.farmer &&
                      result.tag.farmer.image
                        ? result.tag.farmer.image
                        : 'assets/images/default-user.png'
                    }
                    alt="user-image"
                    className="user-image-status"
                    style={{ maxWidth: '70%' }}
                  />

                  <h5 className="mt-3">
                    {result && result.tag.farmer && result.tag.farmer.firstName
                      ? result.tag.farmer.firstName
                      : ' '}{' '}
                    {result && result.tag.farmer && result.tag.farmer.lastName
                      ? result.tag.farmer.lastName
                      : ' '}
                  </h5>

                  <h6>{result && result.tag.tag ? result.tag.tag : null}</h6>
                  <h6>{result && !result.tag.tag ? result.code : null}</h6>

                  <p>
                    Protection Status <br />
                    <CountdownStatus
                      item={result.tag}
                      targetDate={dateTime(result.tag)}
                    />
                  </p>
                </header>
              </div>
              <div className="col-md-6">
                <div className="mt-2">
                  <div className="info">
                    <h5 className="font-size-15">INFORMATON</h5>
                    <div className="row">
                      <div className="col-sm-6">
                        <h6>Famer ID</h6>
                        <p>
                          {result && result.tag.farmer
                            ? `0${result.tag.farmer.phone}`
                            : null}
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <h6>Email</h6>
                        <p>
                          {result &&
                          result.tag.farmer &&
                          result.tag.farmer.email
                            ? result.tag.farmer.email
                            : 'Not Filled'}
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <h6>Date Verified</h6>
                        <p>
                          {result && result.tag.redActivatedAt ? (
                            moment(result.tag.redActivatedAt).format(
                              'Do MMMM YYYY,  h:mm a',
                            )
                          ) : (
                            <div
                              className={`badge badge-soft-danger font-size-12`}
                            >
                              Not Verified
                            </div>
                          )}
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <h6>Date Activated</h6>
                        <p>
                          {result && result.tag.greenActivatedAt ? (
                            moment(result.tag.greenActivatedAt).format(
                              'Do MMMM YYYY,  h:mm a',
                            )
                          ) : (
                            <div
                              className={`badge badge-soft-danger font-size-12`}
                            >
                              Not Activated
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="projects">
                    <h5 className="font-size-15">PRODUCT DETAILS</h5>
                    <div className="row">
                      <div className="col-sm-6">
                        <h6>Product Name</h6>
                        <p>
                          {result &&
                          result.tag.batch.product &&
                          result.tag.batch.product.name
                            ? result.tag.batch.product.name
                            : null}
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <h6>Product Category</h6>
                        <p>
                          {result &&
                          result.tag.batch &&
                          result.tag.batch.product &&
                          result.tag.batch.product.category &&
                          result.tag.batch.product.category.name
                            ? result.tag.batch.product.category.name
                            : null}
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <h6>Manufacturer</h6>
                        <p>
                          {result &&
                          result.tag.batch &&
                          result.tag.batch.assignedTo &&
                          result.tag.batch.assignedTo.name
                            ? result.tag.batch.assignedTo.name
                            : null}
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <h6>Tag Number</h6>
                        <p>
                          {result && result.tag.tag ? result.tag.tag : null}
                        </p>
                        <p>{result && !result.tag.tag ? result.code : null}</p>
                      </div>
                      <div className="col-sm-6">
                        <h6>Batch Number</h6>
                        <p>
                          {result && result.tag.batch
                            ? result.tag.batch.batch
                            : null}
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <h6>Expected Point</h6>
                        <p>
                          {result &&
                          result.tag.batch &&
                          result.tag.batch.product &&
                          result.tag.batch.product.category
                            ? result.tag.batch.product.category.expectedPoint
                            : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DialogActions>
              {userInfo && userInfo.account === 'service' ? (
                <button
                  type="submit"
                  className="btn btn-success btn-sm waves-effect"
                  onClick={handleReport}
                >
                  Submit Report
                </button>
              ) : null}

              {(userInfo && userInfo.account === 'admin') ||
              (userInfo && userInfo.account === 'manufacturer') ? (
                <button
                  type="button"
                  className="btn btn-info btn-sm waves-effect"
                  data-bs-dismiss="modal"
                  onClick={handleDialogClose}
                >
                  Close
                </button>
              ) : null}

              {/* {!userInfo ? (
                <a
                  href="/"
                  className="btn btn-info btn-sm waves-effect"
                  data-bs-dismiss="modal"
                  onClick={handleDialogClose}
                >
                  Login to submit report
                </a>
              ) : null} */}
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : null}

      {result && result.labels ? (
        <Dialog
          open={openUserDialog}
          onClose={handleDialogClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={'lg'}
        >
          <div className="modal-header mb-3">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleDialogClose}
            ></button>
          </div>
          <DialogContent>
            <div className="row">
              <div className="col-md-4">
                <header className="text-center">
                  <img
                    src={
                      result && result.user && result.user.image
                        ? result.user.image
                        : 'assets/images/default-user.png'
                    }
                    alt="user-image"
                    className="user-image-status"
                  />

                  <h5 className="mt-3">
                    {result && result.user && result.user.firstName
                      ? result.user.firstName
                      : ' '}{' '}
                    {result && result.user && result.user.lastName
                      ? result.user.lastName
                      : ' '}
                  </h5>

                  <h6>
                    {result && result.user.phone
                      ? `0${result.user.phone}`
                      : null}
                  </h6>
                </header>
              </div>
              <div className="col-md-7">
                <div className="info">
                  <h5 className="card-title font-size-15">INFORMATON</h5>
                  <div className="row">
                    <div className="col-sm-5">
                      <h6>Name</h6>
                      <p>
                        {result &&
                        result.user &&
                        result.user.firstName &&
                        result.user.lastName
                          ? `${result.user.firstName} ${result.user.lastName}`
                          : 'Not Filled'}
                      </p>
                    </div>
                    <div className="col-sm-3">
                      <h6>User ID</h6>
                      <p>
                        {result && result.user
                          ? `0${result.user.phone}`
                          : 'Not Filled'}
                      </p>
                    </div>
                    <div className="col-sm-4">
                      <h6>Email</h6>
                      <p>
                        {result && result.user && result.user.email
                          ? result.user.email
                          : 'Not Filled'}
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <h6>Address</h6>
                      <p>
                        {result && result.user && result.user.address
                          ? result.user.address
                          : 'Not Filled'}
                      </p>
                    </div>
                    <div className="col-sm-3">
                      <h6>City</h6>
                      <p>
                        {result && result.user && result.user.city
                          ? result.user.city
                          : 'Not Filled'}
                      </p>
                    </div>
                    <div className="col-sm-3">
                      <h6>State</h6>
                      <p>
                        {result && result.user && result.user.state
                          ? result.user.state
                          : 'Not Filled'}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="">
                  <h5 className="card-title font-size-15">
                    FARMTROVE BENEFITS STATUS
                  </h5>
                  <div className="row">
                    {result && result.labels
                      ? Object.entries(benefitStatus(result.labels)).map(
                          (item) => (
                            // <div className="row">
                            //   <div className="col-md-6">
                            //     <div className="flex-1 overflow-hidden">
                            //       <p
                            //         className="font-size-15 mb-4 p-0"
                            //         style={{ color: 'red' }}
                            //       >
                            //         {item[0]}
                            //       </p>
                            //     </div>
                            //   </div>
                            //   <div className="col-md-3">
                            //     <div className="font-size-15 text-primary ms-auto text-muted mb-0">
                            //       {item[1].length > 1 ? (
                            //         `${item[1].length} Activities`
                            //       ) : (
                            //         <CountdownStatus
                            //           item={item[1][0]}
                            //           targetDate={dateTime(
                            //             item[1][0].redActivatedAt,
                            //           )}
                            //         />
                            //       )}
                            //     </div>
                            //   </div>
                            // </div>

                            <div className="col-md-6">
                              <div className="status-benefit-card">
                                <div className="benefit-card-body">
                                  <b>{item[0]}</b>
                                  <img
                                    src="assets/images/farmsured_icon.png"
                                    alt=""
                                    className="farmsured-benefit-icon"
                                  />
                                </div>
                              </div>
                            </div>
                          ),
                        )
                      : null}
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>

            <DialogActions>
              {userInfo && userInfo.account === 'service' ? (
                <button
                  type="button"
                  onClick={handleReport}
                  className="btn btn-success btn-sm waves-effect"
                >
                  Submit Report
                </button>
              ) : null}

              {(userInfo && userInfo.account === 'admin') ||
              (userInfo && userInfo.account === 'manufacturer') ? (
                <button
                  type="button"
                  className="btn btn-info btn-sm waves-effect"
                  data-bs-dismiss="modal"
                  onClick={handleDialogClose}
                >
                  Close
                </button>
              ) : null}
              {!userInfo ? (
                <a
                  href="/"
                  className="btn btn-info btn-sm waves-effect"
                  data-bs-dismiss="modal"
                  onClick={handleDialogClose}
                >
                  Login to submit report
                </a>
              ) : null}
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : null}

      {/* Report Dialog */}
      <Dialog
        open={openReportDialog}
        onClose={handleReportDialogClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'md'}
      >
        <DialogContent>
          {errorReporting ? (
            <MessageBox variant="danger">{errorReporting}</MessageBox>
          ) : null}
          <form>
            <div className="row">
              <div className="col-md-12">
                <div className=" mb-3">
                  <label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                  >
                    Unique ID
                  </label>
                  <div className="">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Unique ID"
                      id="example-text-input"
                      disabled
                      required
                      value={uniqueID}
                      onChange={(e) => setUniqueID(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className=" mb-3">
                  <label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                  >
                    Date/Time of Claim
                  </label>
                  <div className="">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Location"
                      id="example-text-input"
                      disabled
                      required
                      value={dateOfReport}
                      onChange={(e) => setDateOfReport(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {result && result.tag ? (
                <div className="col-md-12 mb-3">
                  <label className="form-label">Farmsured Secured Tag</label>
                  <div className="">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="PinCode"
                      id="example-text-input"
                      disabled
                      required
                      value={tagPin}
                      onChange={(e) => setTagPIn(e.target.value)}
                    />
                  </div>
                </div>
              ) : null}

              <div className="col-md-12 mb-3">
                <label className="form-label">Benefit Service Provider</label>
                <div className="">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Service Provider"
                    id="example-text-input"
                    value={bspName}
                    required
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className=" mb-3">
                  <label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                  >
                    Farmtrove Benefit Service Provider Code
                  </label>
                  <div className="">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Service Provider Code"
                      id="example-text-input"
                      onChange={(e) => setBSPCode(e.target.value)}
                      value={bspCode}
                      required
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="example-text-input" className=" col-form-label">
                  Nature of Service to Beneficiary
                </label>
                <select
                  onChange={(e) => setCategory(e.target.value)}
                  className="select2 form-select"
                  required
                >
                  <option>Select service to beneficiary</option>
                  {services && services.length > 0
                    ? services.map((item) => (
                        <option value={item.benefit}> {item.benefit}</option>
                      ))
                    : null}
                </select>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="example-text-input" className=" col-form-label">
                  Service Feature
                </label>
                <select
                  onChange={(e) => setFeature(e.target.value)}
                  className="select2 form-select"
                  required
                >
                  <option>Select service feature</option>
                  {Items && Items.length > 0
                    ? Items.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))
                    : null}
                </select>
              </div>

              <div className="col-md-12">
                <div className=" mb-3">
                  <label
                    htmlFor="example-text-input"
                    className=" col-form-label"
                  >
                    Nature of Service Rendered to the Beneficiary
                  </label>
                  <div className="">
                    <textarea
                      className="form-control"
                      type="text"
                      placeholder="Message"
                      id="example-text-input"
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    />
                  </div>
                  <small className="mt-4" style={{ color: 'red' }}>
                    <b>(!) Warning to BSP</b> (Please DO NOT render services
                    outside the qualified benefits)
                  </small>
                </div>
              </div>
            </div>
          </form>
          <DialogActions>
            <button
              type="button"
              className="btn btn-success btn-sm waves-effect"
              onClick={handleReportSubmit}
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-info btn-sm waves-effect"
              data-bs-dismiss="modal"
              onClick={handleReportDialogClose}
            >
              Close
            </button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}
