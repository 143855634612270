import axios from 'axios'
import {
  ENTERPRISE_CREATE_FAIL,
  ENTERPRISE_CREATE_REQUEST,
  ENTERPRISE_CREATE_SUCCESS,
} from '../../../constants/adminConstants/enterpriseConstant'

export const createEnterprise = (name, duration, category, inputList) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: ENTERPRISE_CREATE_REQUEST,
    payload: { name, duration, category, inputList },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/add-new-enterprise',
      { name, duration, category, inputList },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: ENTERPRISE_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ENTERPRISE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
