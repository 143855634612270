import axios from 'axios'
import {
  CLAIM_LIST_FAIL,
  CLAIM_LIST_REQUEST,
  CLAIM_LIST_SUCCESS,
} from '../../../constants/adminConstants/benefitContants'

export const allClaims = () => async (dispatch, getState) => {
  dispatch({ type: CLAIM_LIST_REQUEST })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/admin/all-claim', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: CLAIM_LIST_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: CLAIM_LIST_FAIL,
      payload: message,
    })
  }
}
