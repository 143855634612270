import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import requestResetPassword from '../../actions/authActions/passwordResetEmail'
import { LoadingBox, MessageBox } from '../../components'
import { EMAIL_PASSWORD_RESET } from '../../constants/authConstants/resetPasswordContants'

function RecoverpwPage() {
  const [email, setEmail] = useState('')

  const userPasswordReset = useSelector((state) => state.userPasswordReset)
  const { loading, reset, error } = userPasswordReset

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(requestResetPassword(email))
  }

  useEffect(() => {
    if (reset) {
      setTimeout(() => {
        dispatch({ type: EMAIL_PASSWORD_RESET })
      }, 2500)
    }
  }, [reset])

  const date = new Date().getFullYear()

  return (
    <div className="container-fluid p-0">
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <div className="row g-0">
            <div className="col-lg-4">
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <div className="row justify-content-center">
                    {error ? (
                      <MessageBox variant="danger">{error}</MessageBox>
                    ) : null}

                    {reset ? (
                      <MessageBox variant="success">{reset.message}</MessageBox>
                    ) : null}
                    <div className="col-lg-9">
                      <div>
                        <div className="text-center">
                          <div>
                            <a href="/" className="">
                              <img
                                src="assets/images/farmsured!.png"
                                alt=""
                                height="50"
                                className="auth-logo logo-dark mx-auto"
                              />
                              <img
                                src="assets/images/farmsured!.png"
                                alt=""
                                height="50"
                                className="auth-logo logo-light mx-auto"
                              />
                            </a>
                          </div>

                          <h4 className="font-size-18 mt-4">Reset Password</h4>
                          <p className="text-muted">
                            Reset your password to Farmsured.
                          </p>
                        </div>

                        <div className="">
                          <div className="text-center mb-3">
                            Enter your Email and instructions will be sent to
                            you!
                          </div>
                          <form className="" onSubmit={submitHandler}>
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-mail-line auti-custom-input-icon"></i>
                              <label htmlFor="useremail">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                id="useremail"
                                placeholder="Enter email"
                                required
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>

                            <div className="mt-4 text-center">
                              <button
                                className="btn btn-success w-md waves-effect waves-light"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>

                        <div className="mt-5 text-center">
                          <p>
                            Don't have an account?{' '}
                            <a href="/" className="fw-medium text-primary">
                              Log in
                            </a>
                          </p>
                          <p>
                            © {date} Farmsured{' '}
                            <sup>
                              <small>TM</small>
                            </sup>
                            {''} A product of AgDyna
                            <sup>
                              <i className="fal fa-registered font-size-12"></i>
                            </sup>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default RecoverpwPage
