export const GET_WITHDRAWREQUEST_REQUEST = 'GET_WITHDRAWREQUEST_REQUEST'
export const GET_WITHDRAWREQUEST_SUCCESS = 'GET_WITHDRAWREQUEST_SUCCESS'
export const GET_WITHDRAWREQUEST_FAIL = 'GET_WITHDRAWREQUEST_FAIL'

export const UPDATE_WITHDRAWREQUEST_REQUEST = 'UPDATE_WITHDRAWREQUEST_REQUEST'
export const UPDATE_WITHDRAWREQUEST_SUCCESS = 'UPDATE_WITHDRAWREQUEST_SUCCESS'
export const UPDATE_WITHDRAWREQUEST_FAIL = 'UPDATE_WITHDRAWREQUEST_FAIL'
export const UPDATE_WITHDRAWREQUEST_RESET = 'UPDATE_WITHDRAWREQUEST_RESET'

export const USER_WALLET_BALANCE_REQUEST = 'USER_WALLET_BALANCE_REQUEST'
export const USER_WALLET_BALANCE_SUCCESS = 'USER_WALLET_BALANCE_SUCCESS'
export const USER_WALLET_BALANCE_FAIL = 'USER_WALLET_BALANCE_FAIL'


export const USER_WALLET_TRANSACTIONS_REQUEST = 'USER_WALLET_TRANSACTIONS_REQUEST'
export const USER_WALLET_TRANSACTIONS_SUCCESS = 'USER_WALLET_TRANSACTIONS_SUCCESS'
export const USER_WALLET_TRANSACTIONS_FAIL = 'USER_WALLET_TRANSACTIONS_FAIL'