import axios from 'axios'
import {
  DASHBOARD_ANALYSIS_REQUEST,
  DASHBOARD_ANALYSIS_SUCCESS,
  DASHBOARD_ANALYSIS_FAIL,
} from '../../../constants/adminConstants/dashboardConstants'

export const analysis = () => async (dispatch, getState) => {
  dispatch({ type: DASHBOARD_ANALYSIS_REQUEST })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get(`/api/admin/dashboard`, {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: DASHBOARD_ANALYSIS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: DASHBOARD_ANALYSIS_FAIL, payload: message })
  }
}
