import axios from 'axios'
import {
    VALIDATE_SERVICE_FAIL,
    VALIDATE_SERVICE_REQUEST,
    VALIDATE_SERVICE_SUCCESS,
} from '../../../constants/authConstants/walletConstants'

export const validateStatus = (itemId) => async (dispatch, getState) => {
    dispatch({
        type: VALIDATE_SERVICE_REQUEST,
        payload: itemId,
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/wallet/escrow-transaction/validate',
            { itemId },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: VALIDATE_SERVICE_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: VALIDATE_SERVICE_FAIL, payload: message })
    }
}
