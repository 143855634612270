import React, { useEffect, useState, useRef } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { sendFarmGptMessage } from '../../../actions/farmerActions/messageActions/sendFarmGptMessage'
import { getFarmGptMessage } from '../../../actions/farmerActions/messageActions/getFarmGptMessage'
import { MessageBox } from '../../../components'

export default function FarmGPTPage() {
  const [conversation, setConversation] = useState([])
  const [message, setMessage] = useState('')
  const [dotsVisible, setDotsVisible] = useState('')
  const [selectedSuggestion, setSelectedSuggestion] = useState('')
  const [suggestions, setSuggestions] = useState([
    `Tell me about farm equipment.`,
    `How can I improve crop yield?`,
    `What's the weather forecast for tomorrow?`,
  ])

  const chatMessagesRef = useRef(null) // Create a ref for chat messages

  const aiChat = useSelector((state) => state.aiChat)
  const { loading, error, response } = aiChat

  const aiChatHistory = useSelector((state) => state.aiChatHistory)
  const {
    loading: loadingHistory,
    error: historyError,
    chatHistory,
  } = aiChatHistory

  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()

    if (message === '') {
      return
    } else {
      setConversation((prevChatLog) => [
        ...prevChatLog,
        { role: 'user', content: message },
      ])
      dispatch(sendFarmGptMessage(message))
      setMessage('')
    }
  }

  const handleSuggestionClick = (message) => {
    // Set the selected suggestion and send it as a message
    setSelectedSuggestion(message)
    setConversation((prevChatLog) => [
      ...prevChatLog,
      { role: 'user', content: message },
    ])
    dispatch(sendFarmGptMessage(message))
  }

  useEffect(() => {
    if (response && Array.isArray(response)) {
      setConversation((prevChatLog) => [...prevChatLog, ...response])
    } else if (response) {
      // If response is not an array, consider handling it differently
      setConversation((prevChatLog) => [...prevChatLog, response])
    }
  }, [response])

  useEffect(() => {
    if (
      chatHistory &&
      chatHistory.messages &&
      Array.isArray(chatHistory.messages)
    ) {
      setConversation((prevChatLog) => [
        ...prevChatLog,
        ...chatHistory.messages,
      ])
    }
  }, [chatHistory])

  useEffect(() => {
    dispatch(getFarmGptMessage())
  }, [dispatch])

  useEffect(() => {
    // Scroll to the bottom of the chat messages when it updates
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight
    }
  }, [conversation])

  useEffect(() => {
    const interval = setInterval(() => {
      // Toggle the visibility of dots by adding/removing them
      setDotsVisible((prev) => (prev === '...' ? '' : '...'))
    }, 500) // Change the interval as needed

    return () => {
      clearInterval(interval) // Clear the interval on component unmount
    }
  }, [])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-md-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">FarmGPT</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">FarmGPT</a>
                      </li>
                      <li className="breadcrumb-item active">My messages</li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                {}
                <div className="card">
                  <div className="card-body border-bottom">
                    <div className="user-chat-border">
                      <div className="row">
                        <div className="col-md-5 col-9">
                          <h5 className="font-size-15 mb-1">FarmGPT</h5>
                          <p className="text-muted mb-0">
                            <i className="mdi mdi-circle text-success align-middle me-1"></i>
                            Active now
                          </p>
                        </div>
                        <div className="col-md-7 col-3">
                          <ul className="list-inline user-chat-nav text-end mb-0">
                            {/* <li className="list-inline-item d-none d-sm-inline-block">
                              <div className="dropdown">
                                <button
                                  className="btn nav-btn dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-cog"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a
                                    className="dropdown-item"
                                    href="javascript: void(0);"
                                  >
                                    Clear chat
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    href="javascript: void(0);"
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </li>

                            <li className="list-inline-item">
                              <div className="dropdown">
                                <button
                                  className="btn nav-btn dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-dots-horizontal"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a className="dropdown-item" href="#">
                                    Action
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    Another action
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    Something else
                                  </a>
                                </div>
                              </div>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="chat-widget">
                      <div
                        className="chat-conversation"
                        data-simplebar="init"
                        style={{ maxHeight: '55vh' }}
                      >
                        <div
                          className="simplebar-wrapper"
                          style={{ margin: '0px' }}
                        >
                          <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer"></div>
                          </div>
                          <div className="simplebar-mask">
                            <div
                              className="simplebar-offset"
                              style={{
                                right: '-18.8889px',
                                bottom: '0px',
                              }}
                            >
                              <div
                                className="simplebar-content-wrapper"
                                style={{
                                  height: 'auto',
                                  overflow: 'hidden scroll',
                                }}
                                ref={chatMessagesRef}
                              >
                                <div
                                  className="simplebar-content"
                                  style={{ padding: '0px' }}
                                >
                                  <ul className="list-unstyled mb-0 pe-3">
                                    <li>
                                      <div className="chat-day-title">
                                        <span className="title">Today</span>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="conversation-list">
                                        <div className="chat-avatar">
                                          <img
                                            src="assets/images/farmsured_icon.png"
                                            alt="logo"
                                          />
                                        </div>
                                        <div className="ctext-wrap">
                                          <div className="conversation-name">
                                            FarmGPT
                                          </div>
                                          <div
                                            className="ctext-wrap-content"
                                            style={{
                                              maxWidth: '80%',
                                              borderRadius:
                                                '0px 25px 25px 25px',
                                              backgroundColor: '#1d6639',
                                            }}
                                          >
                                            <p className="mb-0 ">
                                              Hey! you can ask me anything
                                              related to your farm and i will do
                                              my best to give you the answer
                                              that is best to my knowlegde.
                                              <br />
                                              <br />
                                              Capabilities:
                                              <br />
                                              <br />
                                              Remembers what user said earlier
                                              in the conversation.
                                              <br />
                                              Allows user to provide follow-up
                                              corrections
                                              <br />
                                              Trained to decline inappropriate
                                              Requests
                                              <br />
                                              <br />
                                              Limitations
                                              <br />
                                              May occasionally generate
                                              incorrect information
                                              <br />
                                              <br />
                                              NOTE: You only have 10 questions
                                              per day, so use them wisely. This
                                              feature is powered by OpenAI's
                                              ChapGPT 3.5
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    {loadingHistory ? (
                                      <div className="text-center">
                                        <p
                                          style={{
                                            width: '40%',
                                            margin: 'auto',
                                            marginTop: '20px',
                                            borderRadius: '10px',
                                            backgroundColor: 'grey',
                                            padding: '10px',
                                            color: '#fff',
                                          }}
                                          className="loading-chat-history"
                                        >
                                          Loading chat history
                                        </p>
                                      </div>
                                    ) : historyError ? (
                                      <MessageBox variant="danger">
                                        {historyError}
                                      </MessageBox>
                                    ) : (
                                      <>
                                        {conversation.map((item, index) =>
                                          item.role === 'assistant' ? (
                                            <li key={index}>
                                              <div className="conversation-list">
                                                <div className="chat-avatar">
                                                  <img
                                                    src="assets/images/farmsured_icon.png"
                                                    alt="logo"
                                                  />
                                                </div>
                                                <div className="ctext-wrap">
                                                  <div className="conversation-name">
                                                    FarmGPT
                                                  </div>
                                                  <div
                                                    className="ctext-wrap-content"
                                                    style={{
                                                      maxWidth: '90%',
                                                      borderRadius:
                                                        '0px 25px 25px 25px',
                                                      backgroundColor:
                                                        '#1d6639',
                                                    }}
                                                  >
                                                    <p className="mb-0">
                                                      <pre
                                                        style={{
                                                          maxWidth: '100%',
                                                          textWrap: 'balance',
                                                          fontFamily:
                                                            'Nunito, sans-serif',
                                                          color: 'white',
                                                          fontSize: '15px'
                                                        }}
                                                      >
                                                        {item.content}
                                                      </pre>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          ) : (
                                            <li className="right" key={index}>
                                              <div className="conversation-list">
                                                <div className="ctext-wrap">
                                                  <div className="conversation-name">
                                                    Me
                                                  </div>
                                                  <div
                                                    className="ctext-wrap-content"
                                                    style={{
                                                      borderRadius:
                                                        '25px 0px 25px 25px',
                                                    }}
                                                  >
                                                    <p className="mb-0">
                                                      {item.content}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          ),
                                        )}
                                      </>
                                    )}

                                    <li>
                                      {historyError && error ? null : error ? (
                                        <MessageBox
                                          variant="danger"
                                          className="font-size-12"
                                        >
                                          {error}
                                        </MessageBox>
                                      ) : null}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="simplebar-placeholder"
                            style={{ width: 'auto', height: '887px' }}
                          ></div>
                        </div>
                        <div
                          className="simplebar-track simplebar-horizontal"
                          style={{ visibility: 'hidden' }}
                        >
                          <div
                            className="simplebar-scrollbar"
                            style={{
                              transform: 'translate3d(0px, 0px, 0px)',
                              display: 'none',
                            }}
                          ></div>
                        </div>
                        <div
                          className="simplebar-track simplebar-vertical"
                          style={{ visibility: 'visible' }}
                        >
                          <div
                            className="simplebar-scrollbar"
                            style={{
                              height: '98px',
                              transform: 'translate3d(0px, 36px, 0px)',
                              display: 'block',
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 chat-input-section border-top">
                    {conversation.length === 0 && ( // Conditionally render suggestions if conversation is empty
                      <div className="suggestion-buttons mb-3 text-center">
                        {/* Render suggestion buttons */}
                        {suggestions.map((suggestion, index) => (
                          <button
                            key={index}
                            className={`btn btn-secondary suggestion-button me-3 m-2 ${
                              selectedSuggestion === suggestion ? 'active' : ''
                            }`}
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion}
                          </button>
                        ))}
                      </div>
                    )}
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col">
                          <div>
                            <input
                              type="text"
                              className="form-control chat-input ps-3"
                              placeholder="Enter Message..."
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              disabled={loading ? true : false}
                            />
                          </div>
                        </div>
                        <div className="col-auto">
                          <button
                            type="submit"
                            className="btn btn-success chat-send w-md waves-effect waves-light"
                            disabled={loading ? true : false}
                          >
                            {loading ? (
                              <>
                                <div className="thinking-dots d-none d-sm-inline-block me-2">
                                  <span>Typing{dotsVisible}</span>
                                </div>
                              </>
                            ) : (
                              <>
                                <span className="d-none d-sm-inline-block me-2">
                                  Send
                                </span>
                                <i className="mdi mdi-send"></i>
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
