import axios from 'axios'
import {
    CLUSTER_DELETE_FAIL,
    CLUSTER_DELETE_REQUEST,
    CLUSTER_DELETE_SUCCESS,

} from '../../../constants/adminConstants/farmcluster/clusterContants'

export const deleteCluster = (itemId) => async (
    dispatch,
    getState,
) => {
    dispatch({ type: CLUSTER_DELETE_REQUEST, payload: itemId })
    const {
        userSignin: { userInfo },
    } = getState()
    try {
        const { data } = await axios.delete(
            `/api/admin/farmcluster/cluster/${itemId}`,
            {
                headers: { Authorization: `Bearer ${userInfo.token}` },
            },
        )
        dispatch({ type: CLUSTER_DELETE_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: CLUSTER_DELETE_FAIL, payload: message })
    }
}
