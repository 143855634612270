export const MANUFACTURERS_LIST_REQUEST = 'MANUFACTURERS_LIST_REQUEST'
export const MANUFACTURERS_LIST_SUCCESS = 'MANUFACTURERS_LIST_SUCCESS'
export const MANUFACTURERS_LIST_FAIL = 'MANUFACTURERS_LIST_FAIL'

export const ADD_MANUFACTURER_REQUEST = 'ADD_MANUFACTURER_REQUEST'
export const ADD_MANUFACTURER_SUCCESS = 'ADD_MANUFACTURER_SUCCESS'
export const ADD_MANUFACTURER_FAIL = 'ADD_MANUFACTURER_FAIL'
export const ADD_MANUFACTURER_RESET = 'ADD_MANUFACTURER_RESET'

export const UPDATE_MANUFACTURER_REQUEST = 'UPDATE_MANUFACTURER_REQUEST'
export const UPDATE_MANUFACTURER_SUCCESS = 'UPDATE_MANUFACTURER_SUCCESS'
export const UPDATE_MANUFACTURER_FAIL = 'UPDATE_MANUFACTURER_FAIL'
export const UPDATE_MANUFACTURER_RESET = 'UPDATE_MANUFACTURER_RESET'

export const DELETE_MANUFACTURER_REQUEST = 'DELETE_MANUFACTURER_REQUEST'
export const DELETE_MANUFACTURER_SUCCESS = 'DELETE_MANUFACTURER_SUCCESS'
export const DELETE_MANUFACTURER_FAIL = 'DELETE_MANUFACTURER_FAIL'
export const DELETE_MANUFACTURER_RESET = 'DELETE_MANUFACTURER_RESET'
