export const FARMERS_LIST_REQUEST = 'FARMERS_LIST_REQUEST'
export const FARMERS_LIST_SUCCESS = 'FARMERS_LIST_SUCCESS'
export const FARMERS_LIST_FAIL = 'FARMERS_LIST_FAIL'

export const FARMER_DETAIL_REQUEST = 'FARMER_DETAIL_REQUEST'
export const FARMER_DETAIL_SUCCESS = 'FARMER_DETAIL_SUCCESS'
export const FARMER_DETAIL_FAIL = 'FARMER_DETAIL_FAIL'

export const DELETE_FARMER_REQUEST = 'DELETE_FARMER_REQUEST'
export const DELETE_FARMER_SUCCESS = 'DELETE_FARMER_SUCCESS'
export const DELETE_FARMER_FAIL = 'DELETE_FARMER_FAIL'
export const DELETE_FARMER_RESET = 'DELETE_FARMER_RESET'

export const FARMER_SCAN_DETAIL_REQUEST = 'FARMER_SCAN_DETAIL_REQUEST'
export const FARMER_SCAN_DETAIL_SUCCESS = 'FARMER_SCAN_DETAIL_SUCCESS'
export const FARMER_SCAN_DETAIL_FAIL = 'FARMER_SCAN_DETAIL_FAIL'
