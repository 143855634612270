import axios from 'axios'
import {
  ALL_LOANS_APPLICATION_FAIL,
  ALL_LOANS_APPLICATION_REQUEST,
  ALL_LOANS_APPLICATION_SUCCESS,
} from '../../../constants/farmerConstants/farmtrove/loanConstants'

export const getAllLoans = () => async (dispatch, getState) => {
  dispatch({
    type: ALL_LOANS_APPLICATION_REQUEST,
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/farmer/farmtrove/loans', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: ALL_LOANS_APPLICATION_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: ALL_LOANS_APPLICATION_FAIL, payload: message })
  }
}
