import React, { useEffect, useState } from 'react'
import { Header, LeftSidebar, RightSidebar, Footer } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { myClusters } from '../../../actions/manufacturerActions/farmclusterActions/myClusters'
import { requestQuote } from '../../../actions/manufacturerActions/farmclusterActions/requestQuote'
import { LoadingBox, MessageBox } from '../../../components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { REQUEST_QUOTE_RESET } from '../../../constants/manufacturerConstants/farmClusterConstants'

export default function MyClustersPage() {
  const [openModel, setOpenModel] = useState(false)
  const [cluster, setCluster] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [checkboxes, setCheckboxes] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    state: false,
    localGovernmentArea: false,
    address: false,
  });


  const dispatch = useDispatch()

  const myClusterList = useSelector((state) => state.myClusterList)
  const { loading, clusters, error } = myClusterList

  const quoteRequested = useSelector((state) => state.quoteRequested)
  const { loading: loadingRequest, requested, error: requestError } = quoteRequested

  useEffect(() => {
    dispatch(myClusters())
  }, [])

  useEffect(() => {
    if (requested) {
      setTimeout(() => {
        dispatch({ type: REQUEST_QUOTE_RESET })
        setOpenModel(false)
        setCheckboxes({
          firstName: false,
          lastName: false,
          email: false,
          phoneNumber: false,
          state: false,
          localGovernmentArea: false,
          address: false,
        })
      }, 5000)
    }
  }, [requested])


  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes({
      ...checkboxes,
      [name]: checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(requestQuote(cluster, name, email, phone, checkboxes))
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">All Clusters</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmclusters</a>
                      </li>
                      <li className="breadcrumb-item active">All Clusters</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* start page title ends  */}

            {/* card column starts here */}
            {loading ? <LoadingBox />
              : error ? <MessageBox variant='danger'>{error}</MessageBox>
                : clusters && clusters.length > 0
                  ? <div className="row">
                    {clusters.map(item => (
                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="border-right">
                                <h4 className="mb-0 font-size-14">{item.name}</h4>
                              </div>
                              <div className="text-primary ms-auto">
                                <i className="ri-team-fill font-size-24"></i>
                              </div>
                            </div>
                            <div className="flex-1 overflow-hidden mt-1" >
                              <h4 className="mb-0 font-size-24"><b>{item.userCount}</b> <span className="text-truncate font-size-14 mb-0">Farmers</span></h4>
                            </div>
                          </div>

                          <div className="card-body border-top py-3">
                            <div className="text-truncate">
                              <span className="font-size-13">{item.category && item.category.name}</span>
                              <button className="btn btn-success btn-sm float-end font-size-10" onClick={() => (setOpenModel(true), setCluster(item.name))}>View Insights</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  :
                  "There is no clusters"
            }

          </div>
        </div>
      </div>

      <Dialog
        open={openModel}
        onClose={() => setOpenModel(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <>
          <div className="modal-header">
            <h5 className="modal-title" style={{ textAlign: 'center' }}>
              Data Insights
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setOpenModel(false)}
            ></button>
          </div>
          {loadingRequest ? <LoadingBox /> :
            <DialogContent>
              {requestError && <MessageBox variant='danger'>{requestError}</MessageBox>}
              {requested && <MessageBox variant='success'>{requested.message}</MessageBox>}
              <h5 className="" style={{ textAlign: 'center', fontSize: 27, fontWeight: 600 }}>
                Data Insights
              </h5>
              <p style={{ textAlign: 'center', fontSize: 14, width: '90%', margin: "auto" }}>
                Gather valuable insights through advanced data analytics tools that inform strategic decisions and improve operations. Access detailed profiles and personalized dashboards for insights that drive growth and better decision-making.
              </p>
              <div className="" style={{ width: '90%', margin: "auto", marginTop: 20 }}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="invalidCheck1"
                        name="firstName"
                        checked={checkboxes.firstName}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="invalidCheck1">
                        First Name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="invalidCheck2"
                        name="lastName"
                        checked={checkboxes.lastName}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="invalidCheck2">
                        Last Name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="invalidCheck3"
                        name="email"
                        checked={checkboxes.email}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="invalidCheck3">
                        Email
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="invalidCheck4"
                        name="phoneNumber"
                        checked={checkboxes.phoneNumber}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="invalidCheck4">
                        Phone Number
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="invalidCheck5"
                        name="state"
                        checked={checkboxes.state}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="invalidCheck5">
                        State
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="invalidCheck6"
                        name="localGovernmentArea"
                        checked={checkboxes.localGovernmentArea}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="invalidCheck6">
                        Local Government Area
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="invalidCheck7"
                        name="address"
                        checked={checkboxes.address}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="invalidCheck7">
                        Address
                      </label>
                    </div>
                  </div>
                </div>
              </div>


              <h5 className="" style={{ textAlign: 'center', fontSize: 17, fontWeight: 500 }}>
                Request a quote
              </h5>

              <div className="mb-3 mt-2">
                <label className="form-label" for="cluster">
                  Cluster
                </label>
                <input
                  className="form-control"
                  id="cluster"
                  type="text"
                  placeholder='Cluster'
                  onChange={(e) => setCluster(e.target.value)}
                  disabled
                  value={cluster}
                  required
                ></input>
              </div>

              <div className="mb-3">
                <label className="form-label" for="name">
                  Name
                </label>
                <input
                  className="form-control"
                  id="name"
                  type="text"
                  placeholder='Name'
                  onChange={(e) => setName(e.target.value)}
                  required
                ></input>
              </div>

              <div className="mb-3">
                <label className="form-label" for="email">
                  Email
                </label>
                <input
                  className="form-control"
                  id="email"
                  type="email"
                  placeholder='Email'
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></input>
              </div>

              <div className="mb-3 mt-2">
                <label className="form-label" for="phone">
                  Phone Number
                </label>
                <input
                  className="form-control"
                  id="phone"
                  type="number"
                  placeholder='Phone Number'
                  onChange={(e) => setPhone(e.target.value)}
                  required
                ></input>
              </div>

              <div className="d-grid">
                <button className='btn btn-success btn-md mb-3' onClick={handleSubmit} disabled={!name || !email || !phone}>Submit</button>
              </div>
            </DialogContent>
          }

        </>
      </Dialog>
      <Footer />
    </div >
  )
}


