import axios from 'axios'
import {
 STATUS_VALIDATION_FAIL,
 STATUS_VALIDATION_REQUEST,
 STATUS_VALIDATION_SUCCESS,
} from '../../constants/authConstants/statusConstants'

// Validate Tags Action
export const validate = (tag) => async (dispatch) => {
  dispatch({ type:STATUS_VALIDATION_REQUEST, payload: { tag } })

  try {
    const { data } = await axios.post('/api/status', { tag })
    dispatch({ type:STATUS_VALIDATION_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type:STATUS_VALIDATION_FAIL, payload: message })
  }
}
