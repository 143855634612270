export const ENTERPRISE_LIST_REQUEST = 'ENTERPRISE_LIST_REQUEST'
export const ENTERPRISE_LIST_SUCCESS = 'ENTERPRISE_LIST_SUCCESS'
export const ENTERPRISE_LIST_FAIL = 'ENTERPRISE_LIST_FAIL'

export const ENTERPRISE_CREATE_REQUEST = 'ENTERPRISE_CREATE_REQUEST'
export const ENTERPRISE_CREATE_SUCCESS = 'ENTERPRISE_CREATE_SUCCESS'
export const ENTERPRISE_CREATE_FAIL = 'ENTERPRISE_CREATE_FAIL'
export const ENTERPRISE_CREATE_RESET = 'ENTERPRISE_CREATE_RESET'

export const UPDATE_ENTERPRISE_REQUEST = 'UPDATE_ENTERPRISE_REQUEST'
export const UPDATE_ENTERPRISE_SUCCESS = 'UPDATE_ENTERPRISE_SUCCESS'
export const UPDATE_ENTERPRISE_FAIL = 'UPDATE_ENTERPRISE_FAIL'
export const UPDATE_ENTERPRISE_RESET = 'UPDATE_ENTERPRISE_RESET'