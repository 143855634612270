import axios from 'axios'
import {
    SEND_PUSH_NOTIFICATION_FAIL,
    SEND_PUSH_NOTIFICATION_REQUEST,
    SEND_PUSH_NOTIFICATION_SUCCESS,
} from '../../../constants/adminConstants/notificationConstants'

export const sendPushNotification = (messageTitle, messageBody, messageData, itemId) => async (dispatch, getState) => {
    dispatch({ type: SEND_PUSH_NOTIFICATION_REQUEST, payload: messageTitle, messageBody, messageData, itemId })
    try {
        const {
            userSignin: { userInfo },
        } = getState()

        const { data } = await axios.post('/api/admin/push-notification',
            { messageTitle, messageBody, messageData, itemId },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            })
        dispatch({ type: SEND_PUSH_NOTIFICATION_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: SEND_PUSH_NOTIFICATION_FAIL, payload: message })
    }
}
