export const VERIFY_IDENTITY_REQUEST = 'VERIFY_IDENTITY_REQUEST'
export const VERIFY_IDENTITY_SUCCESS = 'VERIFY_IDENTITY_SUCCESS'
export const VERIFY_IDENTITY_FAIL = 'VERIFY_IDENTITY_FAIL'
export const VERIFY_IDENTITY_RESET = 'VERIFY_IDENTITY_RESET'

export const LOAN_APPLICATION_REQUEST = 'LOAN_APPLICATION_REQUEST'
export const LOAN_APPLICATION_SUCCESS = 'LOAN_APPLICATION_SUCCESS'
export const LOAN_APPLICATION_FAIL = 'LOAN_APPLICATION_FAIL'
export const LOAN_APPLICATION_RESET = 'LOAN_APPLICATION_RESET'

export const ALL_LOANS_APPLICATION_REQUEST = 'ALL_LOANS_APPLICATION_REQUEST'
export const ALL_LOANS_APPLICATION_SUCCESS = 'ALL_LOANS_APPLICATION_SUCCESS'
export const ALL_LOANS_APPLICATION_FAIL = 'ALL_LOANS_APPLICATION_FAIL'

export const LOAN_PROVIDERS_REQUEST = 'LOAN_PROVIDERS_REQUEST'
export const LOAN_PROVIDERS_SUCCESS = 'LOAN_PROVIDERS_SUCCESS'
export const LOAN_PROVIDERS_FAIL = 'LOAN_PROVIDERS_FAIL'

export const ACCEPT_LOAN_OFFER_REQUEST = 'ACCEPT_LOAN_OFFER_REQUEST'
export const ACCEPT_LOAN_OFFER_SUCCESS = 'ACCEPT_LOAN_OFFER_SUCCESS'
export const ACCEPT_LOAN_OFFER_FAIL = 'ACCEPT_LOAN_OFFER_FAIL'
export const ACCEPT_LOAN_OFFER_RESET = 'ACCEPT_LOAN_OFFER_RESET'

export const PAY_LOAN_REQUEST = 'PAY_LOAN_REQUEST'
export const PAY_LOAN_SUCCESS = 'PAY_LOAN_SUCCESS'
export const PAY_LOAN_FAIL = 'PAY_LOAN_FAIL'
export const PAY_LOAN_RESET = 'PAY_LOAN_RESET'
