import axios from 'axios'
import {
  MANUFACTURER_ORDER_LIST_FAIL,
  MANUFACTURER_ORDER_LIST_REQUEST,
  MANUFACTURER_ORDER_LIST_SUCCESS,
} from '../../../constants/manufacturerConstants/orderConstants'

export const listOrders = () => async (dispatch, getState) => {
  dispatch({ type: MANUFACTURER_ORDER_LIST_REQUEST })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/manufacturer/all-orders', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: MANUFACTURER_ORDER_LIST_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: MANUFACTURER_ORDER_LIST_FAIL, payload: message })
  }
}
