import {
  ADD_PRODUCT_CATEGORY_FAIL,
  ADD_PRODUCT_CATEGORY_REQUEST,
  ADD_PRODUCT_CATEGORY_RESET,
  ADD_PRODUCT_CATEGORY_SUCCESS,
  PRODUCT_CATEGORY_LIST_FAIL,
  PRODUCT_CATEGORY_LIST_REQUEST,
  PRODUCT_CATEGORY_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  ADD_PRODUCT_RESET,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_RESET,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_RESET,
  UPDATE_PRODUCT_CATEGORY_REQUEST,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,
  UPDATE_PRODUCT_CATEGORY_FAIL,
  UPDATE_PRODUCT_CATEGORY_RESET,
  DELETE_PRODUCT_CATEGORY_REQUEST,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  DELETE_PRODUCT_CATEGORY_FAIL,
  DELETE_PRODUCT_CATEGORY_RESET,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  ORGANISATION_PRODUCT_LIST_REQUEST,
  ORGANISATION_PRODUCT_LIST_SUCCESS,
  ORGANISATION_PRODUCT_LIST_FAIL,
  ASSIGN_PRODUCT_CATEGORY_POINT_REQUEST,
  ASSIGN_PRODUCT_CATEGORY_POINT_SUCCESS,
  ASSIGN_PRODUCT_CATEGORY_POINT_FAIL,
  ASSIGN_PRODUCT_CATEGORY_POINT_RESET,
  ASSIGNED_PRODUCT_CATEGORY_POINT_REQUEST,
  ASSIGNED_PRODUCT_CATEGORY_POINT_SUCCESS,
  ASSIGNED_PRODUCT_CATEGORY_POINT_FAIL,
} from '../../../constants/adminConstants/productConstants'

export const productListReducer = (
  state = { loading: true, products: [] },
  action,
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true }
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, products: action.payload }
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true }
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, result: action.payload }
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addProductReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_PRODUCT_REQUEST:
      return { loading: true }
    case ADD_PRODUCT_SUCCESS:
      return { loading: false, product: action.payload }
    case ADD_PRODUCT_FAIL:
      return { loading: false, error: action.payload }
    case ADD_PRODUCT_RESET:
      return {}
    default:
      return state
  }
}

export const updateProductReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_REQUEST:
      return { loading: true }
    case UPDATE_PRODUCT_SUCCESS:
      return { loading: false, updatedProduct: action.payload }
    case UPDATE_PRODUCT_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_PRODUCT_RESET:
      return {}
    default:
      return state
  }
}

export const deleteProductReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_REQUEST:
      return { loading: true }
    case DELETE_PRODUCT_SUCCESS:
      return { loading: false, success: true }
    case DELETE_PRODUCT_FAIL:
      return { loading: false, error: action.payload }
    case DELETE_PRODUCT_RESET:
      return {}
    default:
      return state
  }
}

export const organisationProductsReducer = (state = {}, action) => {
  switch (action.type) {
    case ORGANISATION_PRODUCT_LIST_REQUEST:
      return { loading: true }
    case ORGANISATION_PRODUCT_LIST_SUCCESS:
      return { loading: false, products: action.payload }
    case ORGANISATION_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addProductCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_PRODUCT_CATEGORY_REQUEST:
      return { loading: true }
    case ADD_PRODUCT_CATEGORY_SUCCESS:
      return { loading: false, category: action.payload }
    case ADD_PRODUCT_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    case ADD_PRODUCT_CATEGORY_RESET:
      return {}
    default:
      return state
  }
}

export const productCategoryListReducer = (
  state = { loading: true, categorylist: [] },
  action,
) => {
  switch (action.type) {
    case PRODUCT_CATEGORY_LIST_REQUEST:
      return { loading: true }
    case PRODUCT_CATEGORY_LIST_SUCCESS:
      return { loading: false, categorylist: action.payload }
    case PRODUCT_CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateProductCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_CATEGORY_REQUEST:
      return { loading: true }
    case UPDATE_PRODUCT_CATEGORY_SUCCESS:
      return { loading: false, update: action.payload }
    case UPDATE_PRODUCT_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_PRODUCT_CATEGORY_RESET:
      return {}
    default:
      return state
  }
}

export const deleteProductCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_CATEGORY_REQUEST:
      return { loading: true }
    case DELETE_PRODUCT_CATEGORY_SUCCESS:
      return { loading: false, success: true }
    case DELETE_PRODUCT_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    case DELETE_PRODUCT_CATEGORY_RESET:
      return {}
    default:
      return state
  }
}

export const assignProductCategoryPointReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSIGN_PRODUCT_CATEGORY_POINT_REQUEST:
      return { loading: true }
    case ASSIGN_PRODUCT_CATEGORY_POINT_SUCCESS:
      return { loading: false, saved: action.payload }
    case ASSIGN_PRODUCT_CATEGORY_POINT_FAIL:
      return { loading: false, error: action.payload }
    case ASSIGN_PRODUCT_CATEGORY_POINT_RESET:
      return {}
    default:
      return state
  }
}

export const assignedPCPointReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSIGNED_PRODUCT_CATEGORY_POINT_REQUEST:
      return { loading: true }
    case ASSIGNED_PRODUCT_CATEGORY_POINT_SUCCESS:
      return { loading: false, assignedPC: action.payload }
    case ASSIGNED_PRODUCT_CATEGORY_POINT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

