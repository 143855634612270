import axios from 'axios'
import {
    USER_WALLET_BALANCE_FAIL,
    USER_WALLET_BALANCE_REQUEST,
    USER_WALLET_BALANCE_SUCCESS,
} from '../../../constants/adminConstants/farmtrove/walletConstants'

export const getUserWalletBalance = (itemId) => async (dispatch, getState) => {
    dispatch({
        type: USER_WALLET_BALANCE_REQUEST, payload: itemId,
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post('/api/admin/farmtrove/user-wallet-balance', { itemId }, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })
        dispatch({ type: USER_WALLET_BALANCE_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: USER_WALLET_BALANCE_FAIL, payload: message })
    }
}
