import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listProducts } from '../../../actions/adminActions/productActions/allProduct'
import { LoadingBox, MessageBox } from '../../../components'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import moment from 'moment'
import { allProductCategory } from '../../../actions/adminActions/productActions/productCategory'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { updateProduct } from '../../../actions/adminActions/productActions/updateProduct'
import {
  DELETE_PRODUCT_RESET,
  UPDATE_PRODUCT_RESET,
} from '../../../constants/adminConstants/productConstants'
import { deleteProduct } from '../../../actions/adminActions/productActions/deleteProduct'
import SweetAlert from 'react-bootstrap-sweetalert'
import { MDBDataTableV5 } from 'mdbreact'
import { allAssignedPC } from '../../../actions/adminActions/productActions/allAssignedPC'

function AllProductPage() {
  const [alert, setAlert] = useState(null)
  const [datatable, setDatatable] = useState({})
  const [open, setOpen] = useState(false)
  const [itemId, setItemId] = useState('')
  const [name, setName] = useState('')
  const [manufacturer, setManufacturer] = useState('')
  const [category, setCategory] = useState('')
  const [description, setDescription] = useState('')

  const productsList = useSelector((state) => state.productsList)
  const { loading, error, products } = productsList

  const productCategory = useSelector((state) => state.productCategory)
  const { loading: loadingCategory, categorylist } = productCategory

  const productUpdate = useSelector((state) => state.productUpdate)
  const {
    loading: loadingUpdate,
    error: updateError,
    updatedProduct,
  } = productUpdate

  const productDelete = useSelector((state) => state.productDelete)
  const { loading: loadingDelete, error: deleteError, success } = productDelete

  const allProductCategoryPoint = useSelector(
    (state) => state.allProductCategoryPoint,
  )
  const { assignedPC } = allProductCategoryPoint

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listProducts())
    dispatch(allProductCategory())
    dispatch(allAssignedPC())

    if (updatedProduct) {
      setTimeout(() => {
        dispatch({ type: UPDATE_PRODUCT_RESET })
      }, 3500)
    }

    if (success) {
      setAlert(null)
      setTimeout(() => {
        dispatch({ type: DELETE_PRODUCT_RESET })
      }, 3500)
    }
  }, [dispatch, updatedProduct, success])

  const editProduct = (item) => {
    setOpen(true)
    setItemId(item._id)
    setName(item.name)
    setCategory(item.category._id)
    setManufacturer(item.manufacturer ? item.manufacturer.name : null)
    setDescription(item.description)
  }

  const updateProductHandler = (e) => {
    e.preventDefault()

    dispatch(updateProduct(itemId, name, category, description))
    setOpen(false)
  }

  const deleteHandler = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deleteProduct(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  const productPoint = (data, key) => {
    if (data && key) {
      const object = data.filter((obj) => obj.category._id === key)
      if (object[0] && object[0].point) return object[0].point
    }
  }

  useEffect(() => {
    if (products) {
      setDatatable({
        columns: [
          {
            label: 'Name',
            field: 'name',
            width: 200,
          },
          {
            label: 'Organization',
            field: 'company',
            width: 200,
          },
          {
            label: 'Category',
            field: 'category',
            width: 100,
          },
          {
            label: 'Expected Point',
            field: 'expected',
            width: 100,
          },
          {
            label: 'Point',
            field: 'point',
            width: 100,
          },
          {
            label: 'Date Created',
            field: 'date',
            width: 100,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: products.map((item) => ({
          name: item.name,
          company:
            item.manufacturer && item.manufacturer.name
              ? item.manufacturer.name
              : '',
          category:
            item.category && item.category.name ? item.category.name : '',
          expected:
            item.category && item.category.expectedPoint
              ? item.category.expectedPoint
              : '',
          point: productPoint(assignedPC, item.category._id),
          date: moment(item.created_at).format('Do MMMM YYYY,  h:mm a'),
          action: (
            <>
              <a
                href={`/product/analysis/${item._id}`}
                style={{ marginRight: '15px' }}
                className="btn btn-sm btn-warning"
                data-bs-container="#tooltip-container1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Analysis"
              >
                Analysis
              </a>
              <button
                className="btn btn-sm btn-primary"
                style={{ marginRight: '15px' }}
                data-bs-container="#tooltip-container1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit"
                onClick={() => editProduct(item)}
              >
                Edit
              </button>
              <button
                className="btn btn-sm p-0 m-0 text-danger"
                data-bs-container="#tooltip-container1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Delete"
                onClick={() => deleteHandler(item)}
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </button>
            </>
          ),
        })),
      })
    }
  }, [products, assignedPC])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Product & Service</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Products & Services</a>
                      </li>
                      <li className="breadcrumb-item active">
                        All Product & Service
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {loadingUpdate ? <LoadingBox /> : null}
            {updateError ? (
              <MessageBox variant="danger">{updateError}</MessageBox>
            ) : null}
            {loadingDelete ? <LoadingBox /> : null}
            {deleteError ? (
              <MessageBox variant="danger">{deleteError}</MessageBox>
            ) : null}
            {updatedProduct ? (
              <MessageBox variant="success">
                {updatedProduct.message}
              </MessageBox>
            ) : null}
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className=" mt-1">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <div>
                          <h4 className="card-title font-size-13">
                            All Product & Services
                          </h4>
                        </div>
                      </div>

                      {loading ? (
                        <LoadingBox />
                      ) : error ? (
                        <MessageBox variant="danger">{error}</MessageBox>
                      ) : (
                        <div className="table-responsive-xl">
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 10, 20, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Edit Modal */}
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth="sm"
            >
              <>
                <div className="modal-header">
                  <h5 className="modal-title">Edit product & service</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setOpen(false)}
                  ></button>
                </div>
                <DialogContent>
                  <div className="">
                    <form onSubmit={updateProductHandler}>
                      <div className="row">
                        <div className="col-12">
                          <div className=" mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Name
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Product Name"
                                id="example-text-input"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className=" mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Organization
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Company Name"
                                id="example-text-input"
                                disabled
                                value={manufacturer}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className=" mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Category
                            </label>
                            <div className="col-md-12">
                              <select
                                onChange={(e) => setCategory(e.target.value)}
                                className="select2 form-select"
                                value={category}
                              >
                                {loadingCategory ? (
                                  'Loading...'
                                ) : categorylist.length > 0 ? (
                                  <>
                                    {categorylist ? (
                                      <>
                                        {categorylist.map((item) => (
                                          <option value={item._id}>
                                            {item.name}
                                          </option>
                                        ))}
                                      </>
                                    ) : null}
                                  </>
                                ) : (
                                  'there is no category'
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className=" mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Description
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Description"
                                id="example-text-input"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <DialogActions>
                        <button
                          type="button"
                          className="btn btn-light btn-sm waves-effect"
                          data-bs-dismiss="modal"
                          onClick={() => setOpen(false)}
                        >
                          Close
                        </button>
                        <button
                          className="btn btn-success btn-sm"
                          type="submit"
                          onClick={updateProductHandler}
                        >
                          Update
                        </button>
                      </DialogActions>
                    </form>
                  </div>
                </DialogContent>
              </>
            </Dialog>
          </div>
        </div>
        {alert}
      </div>
      <Footer />
    </div>
  )
}

export default AllProductPage
