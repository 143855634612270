import {
  ADD_ORDER_RESET,
  ADD_ORDER_FAIL,
  ADD_ORDER_REQUEST,
  ADD_ORDER_SUCCESS,
  SERVICEPROVIDERS_ORDER_LIST_REQUEST,
  SERVICEPROVIDERS_ORDER_LIST_SUCCESS,
  SERVICEPROVIDERS_ORDER_LIST_FAIL,
  SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_REQUEST,
  SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_SUCCESS,
  SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_FAIL,
  SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_RESET,
} from '../../../constants/manufacturerConstants/orderConstants'

import {
  MANUFACTURER_ORDER_LIST_FAIL,
  MANUFACTURER_ORDER_LIST_REQUEST,
  MANUFACTURER_ORDER_LIST_SUCCESS,
} from '../../../constants/manufacturerConstants/orderConstants'

export const manufacturerOrderListReducer = (
  state = { loading: true, orders: [] },
  action,
) => {
  switch (action.type) {
    case MANUFACTURER_ORDER_LIST_REQUEST:
      return { loading: true }
    case MANUFACTURER_ORDER_LIST_SUCCESS:
      return { loading: false, orders: action.payload }
    case MANUFACTURER_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const newOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ORDER_REQUEST:
      return { loading: true }
    case ADD_ORDER_SUCCESS:
      return { loading: false, order: action.payload }
    case ADD_ORDER_FAIL:
      return { loading: false, error: action.payload }
    case ADD_ORDER_RESET:
      return {}
    default:
      return state
  }
}

export const serviceProviderOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICEPROVIDERS_ORDER_LIST_REQUEST:
      return { loading: true }
    case SERVICEPROVIDERS_ORDER_LIST_SUCCESS:
      return { loading: false, orders: action.payload }
    case SERVICEPROVIDERS_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateProviderOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_REQUEST:
      return { loading: true }
    case SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_SUCCESS:
      return { loading: false, updated: action.payload }
    case SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_FAIL:
      return { loading: false, error: action.payload }
    case SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_RESET:
      return {}
    default:
      return state
  }
}