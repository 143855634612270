import axios from 'axios'
import {
  ALL_FARM_CYCLE_FAIL,
  ALL_FARM_CYCLE_REQUEST,
  ALL_FARM_CYCLE_SUCCESS,
} from '../../../constants/farmerConstants/packagesConstants'

export const allCycles = () => async (dispatch, getState) => {
  dispatch({ type: ALL_FARM_CYCLE_REQUEST })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/farmer/all-cycles', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: ALL_FARM_CYCLE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: ALL_FARM_CYCLE_FAIL,
      payload: message,
    })
  }
}
