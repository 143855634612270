import {
  DELETE_FARMER_FAIL,
  DELETE_FARMER_REQUEST,
  DELETE_FARMER_RESET,
  DELETE_FARMER_SUCCESS,
  FARMERS_LIST_FAIL,
  FARMERS_LIST_REQUEST,
  FARMERS_LIST_SUCCESS,
  FARMER_DETAIL_FAIL,
  FARMER_DETAIL_REQUEST,
  FARMER_DETAIL_SUCCESS,
  FARMER_SCAN_DETAIL_FAIL,
  FARMER_SCAN_DETAIL_REQUEST,
  FARMER_SCAN_DETAIL_SUCCESS,
} from '../../../constants/adminConstants/farmerConstants'

export const farmersListReducer = (state = { farmers: [] }, action) => {
  switch (action.type) {
    case FARMERS_LIST_REQUEST:
      return { loading: true }
    case FARMERS_LIST_SUCCESS:
      return { loading: false, farmers: action.payload }
    case FARMERS_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const farmerDetailReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case FARMER_DETAIL_REQUEST:
      return { loading: true }
    case FARMER_DETAIL_SUCCESS:
      return { loading: false, detail: action.payload }
    case FARMER_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deleteFarmerReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_FARMER_REQUEST:
      return { loading: true }
    case DELETE_FARMER_SUCCESS:
      return { loading: false, success: true }
    case DELETE_FARMER_FAIL:
      return { loading: false, error: action.payload }
    case DELETE_FARMER_RESET:
      return {}
    default:
      return state
  }
}

export const farmersScanReducer = (state = { scans: [] }, action) => {
  switch (action.type) {
    case FARMER_SCAN_DETAIL_REQUEST:
      return { loading: true }
    case FARMER_SCAN_DETAIL_SUCCESS:
      return { loading: false, scans: action.payload }
    case FARMER_SCAN_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
