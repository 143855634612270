import {
  ALL_LOANS_FAIL,
  ALL_LOANS_REQUEST,
  ALL_LOANS_SUCCESS,
  LOANS_APPLICATION_FAIL,
  LOANS_APPLICATION_REQUEST,
  LOANS_APPLICATION_SUCCESS,
  LOAN_RISK_CHECK_FAIL,
  LOAN_APPLICATION_DETAIL_REQUEST,
  LOAN_APPLICATION_DETAIL_SUCCESS,
  LOAN_CREATE_FAIL,
  LOAN_CREATE_REQUEST,
  LOAN_CREATE_RESET,
  LOAN_CREATE_SUCCESS,
  LOAN_RISK_CHECK_REQUEST,
  LOAN_RISK_CHECK_SUCCESS,
  LOAN_APPLICATION_DETAIL_FAIL,
  UPDATE_LOAN_APPLICATION_REQUEST,
  UPDATE_LOAN_APPLICATION_SUCCESS,
  UPDATE_LOAN_APPLICATION_FAIL,
  UPDATE_LOAN_APPLICATION_RESET,
  UPDATE_LOAN_REQUEST,
  UPDATE_LOAN_SUCCESS,
  UPDATE_LOAN_FAIL,
  UPDATE_LOAN_RESET,
} from '../../../constants/adminConstants/farmtrove/loanConstants'

export const allUserLoansReducer = (state = {}, action) => {
  switch (action.type) {
    case LOANS_APPLICATION_REQUEST:
      return { loading: true }
    case LOANS_APPLICATION_SUCCESS:
      return { loading: false, loans: action.payload }
    case LOANS_APPLICATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const loanCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAN_CREATE_REQUEST:
      return { loading: true }
    case LOAN_CREATE_SUCCESS:
      return { loading: false, success: true, newLoan: action.payload }
    case LOAN_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case LOAN_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const updateLoanReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_LOAN_REQUEST:
      return { loading: true }
    case UPDATE_LOAN_SUCCESS:
      return { loading: false, update: true, updatedLoan: action.payload }
    case UPDATE_LOAN_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_LOAN_RESET:
      return {}
    default:
      return state
  }
}

export const allLoansTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_LOANS_REQUEST:
      return { loading: true }
    case ALL_LOANS_SUCCESS:
      return { loading: false, allLoans: action.payload }
    case ALL_LOANS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const loanApplicationDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAN_APPLICATION_DETAIL_REQUEST:
      return { loading: true }
    case LOAN_APPLICATION_DETAIL_SUCCESS:
      return { loading: false, detail: action.payload }
    case LOAN_APPLICATION_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateLoanApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_LOAN_APPLICATION_REQUEST:
      return { loading: true }
    case UPDATE_LOAN_APPLICATION_SUCCESS:
      return { loading: false, updated: action.payload }
    case UPDATE_LOAN_APPLICATION_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_LOAN_APPLICATION_RESET:
      return {}
    default:
      return state
  }
}

export const loanRiskCheckReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAN_RISK_CHECK_REQUEST:
      return { loading: true }
    case LOAN_RISK_CHECK_SUCCESS:
      return { loading: false, result: action.payload }
    case LOAN_RISK_CHECK_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
