import {
  AI_CHAT_FAIL,
  AI_CHAT_HISTORY_FAIL,
  AI_CHAT_HISTORY_REQUEST,
  AI_CHAT_HISTORY_SUCCESS,
  AI_CHAT_REQUEST,
  AI_CHAT_RESET,
  AI_CHAT_SUCCESS,
  ALL_USER_MESSAGE_FAIL,
  ALL_USER_MESSAGE_REQUEST,
  ALL_USER_MESSAGE_SUCCESS,
  USER_READ_MESSAGE_FAIL,
  USER_READ_MESSAGE_REQUEST,
  USER_READ_MESSAGE_SUCCESS,
  USER_SEND_MESSAGE_FAIL,
  USER_SEND_MESSAGE_REQUEST,
  USER_SEND_MESSAGE_RESET,
  USER_SEND_MESSAGE_SUCCESS,
} from '../../../constants/farmerConstants/messageConstants'

export const userMessagesReducer = (
  state = { loading: true, messages: [] },
  action,
) => {
  switch (action.type) {
    case ALL_USER_MESSAGE_REQUEST:
      return { loading: true }
    case ALL_USER_MESSAGE_SUCCESS:
      return {
        loading: false,
        messages: action.payload,
      }
    case ALL_USER_MESSAGE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userReadMessageReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case USER_READ_MESSAGE_REQUEST:
      return { loading: true }
    case USER_READ_MESSAGE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      }
    case USER_READ_MESSAGE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userSendMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SEND_MESSAGE_REQUEST:
      return { loading: true }
    case USER_SEND_MESSAGE_SUCCESS:
      return {
        loading: false,
        sent: action.payload,
      }
    case USER_SEND_MESSAGE_FAIL:
      return { loading: false, error: action.payload }
    case USER_SEND_MESSAGE_RESET:
      return {}
    default:
      return state
  }
}

export const farmGPTMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case AI_CHAT_REQUEST:
      return { loading: true }
    case AI_CHAT_SUCCESS:
      return {
        loading: false,
        response: action.payload,
      }
    case AI_CHAT_FAIL:
      return { loading: false, error: action.payload }
    case AI_CHAT_RESET:
      return {}
    default:
      return state
  }
}

export const getFarmGPTMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case AI_CHAT_HISTORY_REQUEST:
      return { loading: true }
    case AI_CHAT_HISTORY_SUCCESS:
      return {
        loading: false,
        chatHistory: action.payload,
      }
    case AI_CHAT_HISTORY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
