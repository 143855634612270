import axios from 'axios'
import {
    UPDATE_ENTERPRISE_FAIL,
    UPDATE_ENTERPRISE_REQUEST,
    UPDATE_ENTERPRISE_SUCCESS,
} from '../../../constants/adminConstants/enterpriseConstant'

export const updateEnterprise = (itemId, name, duration, category, inputList) => async (
    dispatch,
    getState,
) => {
    dispatch({
        type: UPDATE_ENTERPRISE_REQUEST,
        payload: { itemId, name, duration, category, inputList },
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/admin/update-enterprise',
            { itemId, name, duration, category, inputList },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: UPDATE_ENTERPRISE_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: UPDATE_ENTERPRISE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
