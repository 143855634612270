import axios from 'axios'
import {
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAIL,
} from '../../../constants/manufacturerConstants/productConstants'

export const updateProduct = (itemId, name, description, price, serviceCategory) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: EDIT_PRODUCT_REQUEST,
    payload: {
      itemId, name, description, price, serviceCategory
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/manufacturer/update-product',
      { itemId, name, description, price, serviceCategory },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: EDIT_PRODUCT_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: EDIT_PRODUCT_FAIL, payload: message })
  }
}
