import axios from 'axios'
import {
  TAG_STATUS_VALIDATION_FAIL,
  TAG_STATUS_VALIDATION_REQUEST,
  TAG_STATUS_VALIDATION_SUCCESS,
} from '../../../constants/adminConstants/TagsConstants'

// Validate Tags Action
export const validate = (tag) => async (dispatch, getState) => {
  dispatch({ type: TAG_STATUS_VALIDATION_REQUEST, payload: { tag } })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.post(
      '/api/admin/tag-status',
      { tag },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      },
    )
    dispatch({ type: TAG_STATUS_VALIDATION_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: TAG_STATUS_VALIDATION_FAIL, payload: message })
  }
}
