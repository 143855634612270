import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/authActions'
import { myMessages } from '../actions/farmerActions/messageActions/myMessage'
import moment from 'moment'
import { allAdminMessage } from '../actions/adminActions/notificationActions/allAdminMessage'
import { maufacturerMessages } from '../actions/manufacturerActions/messageActions/myMessage'
import { inbox } from '../components/functions'

function Header() {
  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const userMessages = useSelector((state) => state.userMessages)
  const { loading, error, messages } = userMessages

  const adminListNotification = useSelector(
    (state) => state.adminListNotification,
  )
  const { loading: MessageLoading, sentMessages } = adminListNotification

  const manufacturerMessagesList = useSelector(
    (state) => state.manufacturerMessagesList,
  )
  const {
    loading: manuMessageLoading,
    messages: manuMessages,
  } = manufacturerMessagesList

  const dispatch = useDispatch()
  const signoutHandler = () => {
    dispatch(logout())
  }

  useEffect(() => {
    if (userInfo) {
      if (userInfo.account === 'admin') {
        dispatch(allAdminMessage())
      } else if (userInfo.account === 'farmer') {
        dispatch(myMessages())
      } else if (userInfo.account === 'manufacturer') {
        dispatch(maufacturerMessages())
      }
    }
  }, [dispatch, userInfo])

  return (
    <div>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/* <!-- LOGO --> */}
            <div className="navbar-brand-box">
              <a href="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img
                    src="assets/images/farmsured_icon.png"
                    alt="logo-sm-dark"
                    height="35"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src="assets/images/farmsured!.png"
                    alt="logo-dark"
                    height="42"
                  />
                </span>
              </a>

              <a href="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img
                    src="assets/images/farmsured_icon.png"
                    alt="logo-sm-light"
                    height="35"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src="assets/images/farmsured!.png"
                    alt="logo-light"
                    height="42"
                  />
                </span>
              </a>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="ri-menu-2-line align-middle"></i>
            </button>

            {/* <!-- App Search--> */}
            <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <span className="ri-search-line"></span>
              </div>
            </form>
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="ri-search-line"></i>
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="mb-3 m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="ri-search-line"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="ri-fullscreen-line"></i>
              </button>
            </div>

            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-notifications-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ri-notification-3-line"></i>
                <span className="noti-dot"></span>
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown"
              >
                {userInfo && userInfo.account === 'farmer' ? (
                  loading ? (
                    <div className="m-3">Loading</div>
                  ) : error ? (
                    <div className="m-3">There was an error</div>
                  ) : (
                    <>
                      <div className="p-3">
                        <div className="row align-items-center">
                          <div className="col">
                            <h6 className="m-0"> Notifications </h6>
                          </div>
                          <div className="col-auto">
                            <a href="/user/messages" className="small">
                              {' '}
                              View All
                            </a>
                          </div>
                        </div>
                      </div>
                      <div data-simplebar style={{ maxHeight: '230px' }}>
                        {messages && messages.length > 0 ? (
                          messages.map((item) => (
                            <a
                              href={`/user/message/${item._id}`}
                              className="text-reset notification-item"
                            >
                              <div className="d-flex">
                                <div className="avatar-xs me-3">
                                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                                    <i className="fal fa-exclamation"></i>
                                  </span>
                                </div>
                                <div className="flex-1">
                                  <h6 className="mb-1">{item.subject}</h6>
                                  <div className="font-size-12 text-muted">
                                    <p className="mb-1 message-text">
                                      {item.message}
                                    </p>
                                    <p className="mb-0">
                                      <i className="mdi mdi-clock-outline"></i>{' '}
                                      {moment(item.created_at).fromNow()}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          ))
                        ) : (
                          <div className="m-3">No Message</div>
                        )}
                      </div>
                      <div className="p-2 border-top">
                        <div className="d-grid">
                          <a
                            className="btn btn-sm btn-link font-size-14 text-center"
                            href="/user/messages"
                          >
                            <i className="mdi mdi-arrow-right-circle me-1"></i>{' '}
                            View More..
                          </a>
                        </div>
                      </div>
                    </>
                  )
                ) : null}

                {userInfo && userInfo.account === 'manufacturer' ? (
                  manuMessageLoading ? (
                    <div className="m-3">Loading</div>
                  ) : (
                    <>
                      <div className="p-3">
                        <div className="row align-items-center">
                          <div className="col">
                            <h6 className="m-0"> Notifications </h6>
                          </div>
                          <div className="col-auto">
                            <a href="/manufacturer/messages" className="small">
                              {' '}
                              View All
                            </a>
                          </div>
                        </div>
                      </div>
                      <div data-simplebar style={{ maxHeight: '230px' }}>
                        {manuMessages && manuMessages.length > 0 ? (
                          manuMessages.map((item) => (
                            <a
                              href={`/manufacturer/message/${item._id}`}
                              className="text-reset notification-item"
                            >
                              <div className="d-flex">
                                <div className="avatar-xs me-3">
                                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                                    <i className="fal fa-exclamation"></i>
                                  </span>
                                </div>
                                <div className="flex-1">
                                  <h6 className="mb-1">{item.subject}</h6>
                                  <div className="font-size-12 text-muted">
                                    <p className="mb-1 message-text">
                                      {item.message}
                                    </p>
                                    <p className="mb-0">
                                      <i className="mdi mdi-clock-outline"></i>{' '}
                                      {moment(item.created_at).fromNow()}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          ))
                        ) : (
                          <div className="m-3">No Message</div>
                        )}
                      </div>
                      <div className="p-2 border-top">
                        <div className="d-grid">
                          <a
                            className="btn btn-sm btn-link font-size-14 text-center"
                            href="/manufacturer/messages"
                          >
                            <i className="mdi mdi-arrow-right-circle me-1"></i>{' '}
                            View More..
                          </a>
                        </div>
                      </div>
                    </>
                  )
                ) : null}

                {userInfo && userInfo.account === 'admin' ? (
                  MessageLoading ? (
                    <div className="m-3">Loading</div>
                  ) : (
                    <>
                      <div className="p-3">
                        <div className="row align-items-center">
                          <div className="col">
                            <h6 className="m-0"> Notifications </h6>
                          </div>
                          <div className="col-auto">
                            <a href="/inbox" className="small">
                              {' '}
                              View All
                            </a>
                          </div>
                        </div>
                      </div>
                      <div data-simplebar style={{ maxHeight: '230px' }}>
                        {sentMessages &&
                          sentMessages.length > 0 &&
                          (inbox(sentMessages).length > 0 ? (
                            inbox(sentMessages).map((item) => (
                              <a
                                href={`/message/${item._id}`}
                                className="text-reset notification-item"
                              >
                                <div className="d-flex">
                                  <div className="avatar-xs me-3">
                                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                                      <i className="fal fa-exclamation"></i>
                                    </span>
                                  </div>
                                  <div className="flex-1">
                                    <h6 className="mb-1">{item.subject}</h6>
                                    <div className="font-size-12 text-muted">
                                      <p className="mb-1 message-text">
                                        {item.message}
                                      </p>
                                      <p className="mb-0">
                                        <i className="mdi mdi-clock-outline"></i>{' '}
                                        {moment(item.created_at).fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            ))
                          ) : (
                            <div className="m-3">No Message</div>
                          ))}
                      </div>
                      <div className="p-2 border-top">
                        <div className="d-grid">
                          <a
                            className="btn btn-sm btn-link font-size-14 text-center"
                            href="/inbox"
                          >
                            <i className="mdi mdi-arrow-right-circle me-1"></i>{' '}
                            View More..
                          </a>
                        </div>
                      </div>
                    </>
                  )
                ) : null}
              </div>
            </div>

            <div className="dropdown d-inline-block user-dropdown">
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fal fa-user font-size-18"></i>
                <span className="d-none d-xl-inline-block ms-1">
                  {userInfo.firstName}
                </span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <!-- item--> */}
                <a className="dropdown-item" href="/profile">
                  <i className="ri-user-line align-middle me-1"></i> Profile
                </a>
                <a className="dropdown-item" href="/passwords">
                  <i className="ri-lock-password-line align-middle me-1"></i> Passwords
                </a>
                <a className="dropdown-item d-block" href="javascript: void(0);">
                  <i className="ri-settings-2-line align-middle me-1"></i>
                  Settings
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className="dropdown-item text-danger"
                  href={signoutHandler}
                  onClick={signoutHandler}
                >
                  <i className="ri-shut-down-line align-middle me-1 text-danger"></i>
                  Logout
                </a>
              </div>
            </div>

            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="ri-settings-2-line"></i>
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Header
