import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBox, MessageBox } from '../../../components'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import moment from 'moment'
import { farmerDetail } from '../../../actions/adminActions/farmersActions/farmerDetail'
import { farmerScans } from '../../../actions/adminActions/farmersActions/farmerScans'
import { MDBDataTableV5 } from 'mdbreact'
import { checkStatus } from '../../../components/functions'
import LinearProgress from '@material-ui/core/LinearProgress'
import CountdownTimer, {
  CountdownStatus,
} from '../../../components/Counter/CountdownTimer'
import { TailSpin } from 'react-loading-icons'

function FarmerDetailPage(props) {
  const farmId = props.match.params.id
  const [datatable, setDatatable] = useState({})
  const [scandatatable, setScanDatatable] = useState({})

  const farmersInfo = useSelector((state) => state.farmersInfo)
  const { loading, error, detail } = farmersInfo

  const farmerScanActivities = useSelector(
    (state) => state.farmerScanActivities,
  )
  const {
    loading: loadingScans,
    error: productScannedErr,
    scans,
  } = farmerScanActivities

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(farmerDetail(farmId))
    dispatch(farmerScans(farmId))
  }, [dispatch])

  const dateTime = (item) => {
    const c =
      item.batch && item.batch.product && item.batch.product.category
        ? item.batch.product.category.cycleDuration
        : 0

    let hrs = c * 60 * 60 * 1000

    const d = new Date(item.redActivatedAt)
    let ms = d.getTime()
    return ms + hrs
  }

  useEffect(() => {
    if (detail && detail.result) {
      setDatatable({
        columns: [
          {
            label: 'Date Created',
            field: 'date',
            width: 50,
          },
          {
            label: 'Farm Cycle Name',
            field: 'name',
            width: 100,
          },
          {
            label: 'Package',
            field: 'package',
            width: 100,
          },
          {
            label: 'Point',
            field: 'point',
          },
          {
            label: 'Progress',
            field: 'chart',
            width: 200,
          },
          {
            label: 'Start Date',
            field: 'startDate',
            width: 100,
          },
          {
            label: 'Status',
            field: 'status',
            width: 50,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: detail.result.map((item) => ({
          date: moment(item.create_at),
          name: item.name,
          package:
            item.packageId && item.packageId.packageName
              ? item.packageId.packageName
              : '',
          point: item.point,
          chart: (
            <LinearProgress
              variant="determinate"
              className="mt-2 p-1"
              color="primary"
              value={
                item.point &&
                item.productCategory.expectedPoint &&
                item.packageNum
                  ? (item.point /
                      (item.productCategory.expectedPoint * item.packageNum)) *
                    100
                  : 0
              }
            />
          ),
          status: checkStatus(item.startDate, item.endDate),
          startDate: moment(item.startDate).format('DD MMM YYYY'),
          date: moment(item.createdAt).format('Do MMM YYYY, h:mm a'),
          action: (
            <a
              href={`/farm-cycle/${item._id}`}
              style={{ marginRight: '15px' }}
              className="btn btn-sm btn-success"
              data-bs-toggle="tooltip"
              data-placement="top"
              title="Manage"
            >
              Manage
            </a>
          ),
        })),
      })
    }
  }, [detail])

  useEffect(() => {
    if (scans) {
      setScanDatatable({
        columns: [
          {
            label: 'Product',
            field: 'product',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'product',
            },
          },
          {
            label: 'Earned Points',
            field: 'earnedPoints',
            width: 200,
          },
          {
            label: 'Date Verified',
            field: 'dateVerified',
            width: 100,
          },
          {
            label: 'Date Activated',
            field: 'dateActivated',
            width: 100,
          },
          {
            label: 'Protection Timeline',
            field: 'timeline',
            width: 50,
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'disabled',
          },
        ],
        rows: scans.map((item) => ({
          product: item.batch.product.name,
          earnedPoints:
            item.redActivatedAt && !item.greenActivatedAt
              ? item.points
              : item.redActivatedAt && item.greenActivatedAt
              ? item.points + item.points
              : null,

          dateVerified: item.redActivatedAt
            ? moment(item.redActivatedAt).format('Do MMMM YYYY,  h:mm a')
            : null,
          dateActivated: item.greenActivatedAt
            ? moment(item.greenActivatedAt).format('Do MMMM YYYY,  h:mm a')
            : null,
          timeline: <CountdownTimer targetDate={dateTime(item)} />,
          status: <CountdownStatus item={item} targetDate={dateTime(item)} />,
        })),
      })
    }
  }, [scans])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">My Activity</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmsured</a>
                      </li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {error ? <MessageBox variant="danger">{error}</MessageBox> : null}
            {loading ? (
              <LoadingBox />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={
                            detail && detail.user && detail.user.image
                              ? detail.user.image
                              : 'assets/images/farmsured_icon.png'
                          }
                          alt="logo-sm-light"
                          className="avatar-xs"
                          style={{
                            width: '5rem',
                            height: '5rem',
                            borderRadius: '50%',
                          }}
                        />
                      </div>
                      <div className="flex-1">
                        <h5>{detail && detail.user && detail.user.phone}</h5>
                        <h6 className="text-capitalize mb-0">
                          {detail && detail.user && detail.user.firstName}{' '}
                          {detail && detail.user && detail.user.lastName}
                        </h6>

                        <p className="card-desc mb-0 pb-0">
                          {detail && detail.user && detail.user.email
                            ? detail.user.email
                            : 'Not FIlled Yet'}
                        </p>

                        <p className="pb-0 mb-0 ">
                          {`${
                            detail && detail.user && detail.user.lga
                              ? detail.user.lga
                              : 'LGA'
                          }, ${
                            detail && detail.user && detail.user.state
                              ? detail.user.state
                              : 'State'
                          }`}
                        </p>

                        <span
                          className={`badge badge-soft-${
                            detail && detail.user && detail.user.status
                              ? 'success'
                              : 'danger'
                          }`}
                        >
                          {detail && detail.user && detail.user.status
                            ? 'Active'
                            : 'Inactive'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div
                      className="card"
                      style={{
                        borderRadius: '15px',
                        background: '#e0ffd7',
                        color: '#fff',
                      }}
                    >
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-1 overflow-hidden">
                            <div className="text-primary mb-0">
                              <i className="fas fa-coins font-size-24"></i>
                            </div>
                            <h4
                              className="center"
                              style={{
                                fontSize: '38px',
                                fontWeight: 700,
                                marginTop: '11px',
                                height: 50,
                                color: 'black',
                              }}
                            >
                              {detail && detail.user ? detail.user.points : 0}
                            </h4>
                            <p
                              className="float-end text-truncate font-size-14 mb-2"
                              style={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: 'black',
                              }}
                            >
                              Compliance Points
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="card-title mb-2">Farm Cycles</h5>
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive-lg">
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 20, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <section>
              {loadingScans ? (
                <div className="tailspin">
                  <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
                </div>
              ) : productScannedErr ? (
                <MessageBox variant="danger">{productScannedErr}</MessageBox>
              ) : (
                <div className="row mt-4">
                  <div className="col-md-12">
                    <h5 className="card-title mb-2">USSD Scan Activities</h5>
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive-xl">
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 20, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={scandatatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default FarmerDetailPage
