import axios from 'axios'
import {
  FARMER_SCAN_DETAIL_FAIL,
  FARMER_SCAN_DETAIL_REQUEST,
  FARMER_SCAN_DETAIL_SUCCESS,
} from '../../../constants/adminConstants/farmerConstants'

export const farmerScans = (farmId) => async (dispatch, getState) => {
  dispatch({
    type: FARMER_SCAN_DETAIL_REQUEST,
    payload: farmId,
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/farmer-scans',
      { farmId },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: FARMER_SCAN_DETAIL_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: FARMER_SCAN_DETAIL_FAIL, payload: message })
  }
}
