import axios from 'axios'
import {
    CLUSTER_LIST_FAIL,
    CLUSTER_LIST_REQUEST,
    CLUSTER_LIST_SUCCESS,
} from '../../../constants/adminConstants/farmcluster/clusterContants'


export const allCluster = () => async (dispatch, getState) => {
    dispatch({
        type: CLUSTER_LIST_REQUEST,
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.get('/api/admin/farmcluster/all-cluster', {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })
        dispatch({ type: CLUSTER_LIST_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: CLUSTER_LIST_FAIL, payload: message })
    }
}