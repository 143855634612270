import axios from 'axios'
import {
  AI_CHAT_HISTORY_FAIL,
  AI_CHAT_HISTORY_REQUEST,
  AI_CHAT_HISTORY_SUCCESS,
} from '../../../constants/farmerConstants/messageConstants'

export const getFarmGptMessage = () => async (dispatch, getState) => {
  dispatch({
    type: AI_CHAT_HISTORY_REQUEST,
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/farmer/farm-gpt/messages', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: AI_CHAT_HISTORY_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: AI_CHAT_HISTORY_FAIL, payload: message })
  }
}
