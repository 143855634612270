import axios from 'axios'
import {
  LOANS_APPLICATION_FAIL,
  LOANS_APPLICATION_REQUEST,
  LOANS_APPLICATION_SUCCESS,
} from '../../../constants/adminConstants/farmtrove/loanConstants'

export const getAllUserLoans = () => async (dispatch, getState) => {
  dispatch({
    type: LOANS_APPLICATION_REQUEST,
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/admin/farmtrove/loans', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: LOANS_APPLICATION_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: LOANS_APPLICATION_FAIL, payload: message })
  }
}
