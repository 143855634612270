import axios from 'axios'
import {
  ALL_USER_MESSAGE_FAIL,
  ALL_USER_MESSAGE_REQUEST,
  ALL_USER_MESSAGE_SUCCESS,
} from '../../../constants/farmerConstants/messageConstants'

export const myMessages = () => async (dispatch, getState) => {
  dispatch({ type: ALL_USER_MESSAGE_REQUEST })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/farmer/my-messages', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: ALL_USER_MESSAGE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: ALL_USER_MESSAGE_FAIL, payload: message })
  }
}
