export const TAG_REPORT_LIST_REQUEST = 'TAG_REPORT_LIST_REQUEST'
export const TAG_REPORT_LIST_SUCCESS = 'TAG_REPORT_LIST_SUCCESS'
export const TAG_REPORT_LIST_FAIL = 'TAG_REPORT_LIST_FAIL'

export const TAG_REPORT_DETAILS_REQUEST = 'TAG_REPORT_DETAILS_REQUEST'
export const TAG_REPORT_DETAILS_SUCCESS = 'TAG_REPORT_DETAILS_SUCCESS'
export const TAG_REPORT_DETAILS_FAIL = 'TAG_REPORT_DETAILS_FAIL'

export const TAG_REPORT_UPDATE_REQUEST = ' TAG_REPORT_UPDATE_REQUEST'
export const TAG_REPORT_UPDATE_SUCCESS = ' TAG_REPORT_UPDATE_SUCCESS'
export const TAG_REPORT_UPDATE_FAIL = ' TAG_REPORT_UPDATE_FAIL'
export const TAG_REPORT_UPDATE_RESET = ' TAG_REPORT_UPDATE_RESET'

export const TAG_GENERATION_REQUEST = ' TAG_GENERATION_REQUEST'
export const TAG_GENERATION_SUCCESS = ' TAG_GENERATION_SUCCESS'
export const TAG_GENERATION_FAIL = ' TAG_GENERATION_FAIL'
export const TAG_GENERATION_RESET = ' TAG_GENERATION_RESET'

export const TAG_LIST_REQUEST = 'TAG_LIST_REQUEST'
export const TAG_LIST_SUCCESS = 'TAG_LIST_SUCCESS'
export const TAG_LIST_FAIL = 'TAG_LIST_FAIL'

export const TAGS_DOWNLOAD_REQUEST = 'TAGS_DOWNLOAD_REQUEST'
export const TAGS_DOWNLOAD_SUCCESS = 'TAGS_DOWNLOAD_SUCCESS'
export const TAGS_DOWNLOAD_FAIL = 'TAGS_DOWNLOAD_FAIL'

export const TAGS_PDF_DOWNLOAD_REQUEST = 'TAGS_PDF_DOWNLOAD_REQUEST'
export const TAGS_PDF_DOWNLOAD_SUCCESS = 'TAGS_PDF_DOWNLOAD_SUCCESS'
export const TAGS_PDF_DOWNLOAD_FAIL = 'TAGS_PDF_DOWNLOAD_FAIL'
export const TAGS_PDF_DOWNLOAD_RESET = 'TAGS_PDF_DOWNLOAD_RESET'

export const BATCH_LIST_REQUEST = 'BATCH_LIST_REQUEST'
export const BATCH_LIST_SUCCESS = 'BATCH_LIST_SUCCESS'
export const BATCH_LIST_FAIL = 'BATCH_LIST_FAIL'

export const ASSIGN_BATCH_REQUEST = 'ASSIGN_BATCH_REQUEST'
export const ASSIGN_BATCH_SUCCESS = 'ASSIGN_BATCH_SUCCESS'
export const ASSIGN_BATCH_FAIL = 'ASSIGN_BATCH_FAIL'
export const ASSIGN_BATCH_RESET = 'ASSIGN_BATCH_RESET'

export const BATCH_NOT_ASSIGNED_LIST_REQUEST = 'BATCH_NOT_ASSIGNED_LIST_REQUEST'
export const BATCH_NOT_ASSIGNED_LIST_SUCCESS = 'BATCH_NOT_ASSIGNED_LIST_SUCCESS'
export const BATCH_NOT_ASSIGNED_LIST_FAIL = 'BATCH_NOT_ASSIGNED_LIST_FAIL'
export const BATCH_NOT_ASSIGNED_LIST_RESET = 'BATCH_NOT_ASSIGNED_LIST_RESET'

export const TAGS_REQUEST_LIST_REQUEST = 'TAGS_REQUEST_LIST_REQUEST'
export const TAGS_REQUEST_LIST_SUCCESS = 'TAGS_REQUEST_LIST_SUCCESS'
export const TAGS_REQUEST_LIST_FAIL = 'TAGS_REQUEST_LIST_FAIL'

export const TAG_ORDER_DETAIL_REQUEST = 'TAG_ORDER_DETAIL_REQUEST'
export const TAG_ORDER_DETAIL_SUCCESS = 'TAG_ORDER_DETAIL_SUCCESS'
export const TAG_ORDER_DETAIL_FAIL = 'TAG_ORDER_DETAIL_FAIL'

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL'
export const UPDATE_ORDER_RESET = 'UPDATE_ORDER_RESET'

export const ORDER_DELETE_REQUEST = 'ORDER_DELETE_REQUEST'
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS'
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL'
export const ORDER_DELETE_RESET = 'ORDER_DELETE_RESET'

export const TAG_STATUS_VALIDATION_REQUEST = 'TAG_STATUS_VALIDATION_REQUEST'
export const TAG_STATUS_VALIDATION_SUCCESS = 'TAG_STATUS_VALIDATION_SUCCESS'
export const TAG_STATUS_VALIDATION_FAIL = 'TAG_STATUS_VALIDATION_FAIL'
export const TAG_STATUS_VALIDATION_RESET = 'TAG_STATUS_VALIDATION_RESET'

export const SEND_INVOICE_REQUEST = 'SEND_INVOICE_REQUEST'
export const SEND_INVOICE_SUCCESS = 'SEND_INVOICE_SUCCESS'
export const SEND_INVOICE_FAIL = 'SEND_INVOICE_FAIL'
export const SEND_INVOICE_RESET = 'SEND_INVOICE_RESET'