import React, { useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useSelector, useDispatch } from 'react-redux'
import { LoadingBox, MessageBox } from '../../index.js'
import { analysis } from '../../../actions/manufacturerActions/dashboardActions/analysis.js'
import { tagAnalysis } from '../../../actions/manufacturerActions/dashboardActions/tagAnalysis.js'
import {
  manufacturerRecentOrder,
  manufacturertodaygreenanalysis,
  manufacturertodayredanalysis,
  formatNumber,
  weeklyRed,
  weeklyGreen,
  monthlyred,
  monthlygreen,
} from '../../functions.js'
import moment from 'moment'

const Manufacturer = () => {
  const [monthly, setMonth] = useState(false)
  const [today, setToday] = useState(false)
  const [weekly, setWeek] = useState(false)
  const [todayRed, setTodayRed] = useState([])
  const [todayGreen, setTodayGreen] = useState([])
  const [redWeekly, setWeeklyRed] = useState([])
  const [greenWeekly, setWeeklyGreen] = useState([])
  const [redMonthly, setMonthRed] = useState([])
  const [greenMonthly, setMonthGreen] = useState([])
  const manufacturerDashboardAnalysis = useSelector(
    (state) => state.manufacturerDashboardAnalysis,
  )
  const { loading, error, result } = manufacturerDashboardAnalysis

  const productAnalysis = useSelector((state) => state.productAnalysis)
  const { loading: loadingTags, error: tagsErr, tags } = productAnalysis

  const dispatch = useDispatch()

  const tagChart = (productId) => {
    dispatch(tagAnalysis(productId))
  }
  useEffect(() => {
    dispatch(analysis())
  }, [dispatch])

  let num = 1

  let noOfPair = process.env.REACT_PAIR_NUMBER || 1000

  useMemo(() => {
    if (tags) {
      setTodayRed(
        Object.entries(manufacturertodayredanalysis(tags)).map((item) => {
          return item[1].length
        }),
      )

      setTodayGreen(
        Object.entries(manufacturertodaygreenanalysis(tags)).map(
          (item) => item[1].length,
        ),
      )

      setWeeklyRed(
        Object.entries(weeklyRed(tags)).map((item) => item[1].length),
      )

      setWeeklyGreen(
        Object.entries(weeklyGreen(tags)).map((item) => item[1].length),
      )

      setMonthRed(
        Object.entries(monthlyred(tags)).map((item) => item[1].length),
      )

      setMonthGreen(
        Object.entries(monthlygreen(tags)).map((item) => item[1].length),
      )
    }
  }, [tags])

  return (
    <div className="">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">Dashboard</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="javascript: void(0);">Farmsured</a>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {tagsErr ? <MessageBox variant="danger">{tagsErr}</MessageBox> : null}
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div className="">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Products
                          </p>
                          <h4 className="mb-0">
                            {result && result.product
                              ? result.product.length
                              : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-box-check font-size-24"></i>
                        </div>
                      </div>
                    </div>

                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/manufacturer/products">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Tag Order
                          </p>
                          <h4 className="mb-0">
                            {result && result.order ? result.order.length : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-tags font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/manufacturer/tag-request">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Secured Products
                          </p>
                          <h4 className="mb-0">
                            {result && result.batch && result.batch.length > 0
                              ? result.batch.length * noOfPair
                              : 0}
                          </h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="fal fa-shield-check font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="text-muted ms-2 float-end">
                          <a href="/manufacturer/products">View</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card" style={{ minHeight: '630px' }}>
                <div className="card-body">
                  <div className="float-end d-none d-md-inline-block">
                    <div className="btn-group mb-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-light"
                        onClick={() => {
                          setToday(true)
                          setMonth(false)
                          setWeek(false)
                        }}
                      >
                        Today
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-light active"
                        onClick={() => {
                          setWeek(true)
                          setToday(false)
                          setMonth(false)
                        }}
                      >
                        Weekly
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-light"
                        onClick={() => {
                          setMonth(true)
                          setWeek(false)
                          setToday(false)
                        }}
                      >
                        Monthly
                      </button>
                    </div>
                  </div>
                  <h4 className="card-title mb-4">Product Trend</h4>

                  <div className="mb-3">
                    <label htmlFor="" className="text-muted">
                      Select Product
                    </label>
                    <select
                      className="form-select form-control select2"
                      onChange={(e) => tagChart(e.target.value)}
                      placeholder="Select a product"
                    >
                      <option>Select a Product</option>
                      {result && result.product
                        ? result.product.map((item) => (
                            <option value={item._id}>{item.name}</option>
                          ))
                        : null}
                    </select>
                  </div>

                  <div>
                    {loadingTags ? (
                      <div id="pluswrap">
                        <div className="plus">
                          <div className="spinner">
                            <i className="ri-loader-line spin-icon"></i>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {monthly ? (
                          <div id="line-column-chart" className=" " dir="ltr">
                            <Bar
                              height={120}
                              data={{
                                labels: [
                                  'Jan',
                                  'Feb',
                                  'Mar',
                                  'Apr',
                                  'May',
                                  'Jun',
                                  'Jul',
                                  'Aug',
                                  'Sept',
                                  'Oct',
                                  'Nov',
                                  'Dec',
                                ],
                                datasets: [
                                  {
                                    label: 'Verified Tags',
                                    data: redMonthly,
                                    backgroundColor: '#FF4E60',
                                  },
                                  {
                                    label: 'Activated Tags',
                                    data: greenMonthly,
                                    backgroundColor: '#1CBB8C',
                                  },
                                ],
                              }}
                            />
                          </div>
                        ) : today ? (
                          <div
                            id="line-column-chart"
                            className="bar-charts "
                            dir="ltr"
                          >
                            <Bar
                              height={120}
                              data={{
                                labels: [
                                  '12:00 am',
                                  '2:00 am',
                                  '4:00 am',
                                  '6:00 am',
                                  '8:00 am',
                                  '10:00 am',
                                  '12:00 pm',
                                  '2:00 pm',
                                  '4:00 pm',
                                  '6:00 pm',
                                  '8:00 pm',
                                  '10:00 pm',
                                ],
                                datasets: [
                                  {
                                    label: 'Verified Tags',
                                    data: todayRed,
                                    backgroundColor: '#FF4E60',
                                  },
                                  {
                                    label: 'Activated Tags',
                                    data: todayGreen,
                                    backgroundColor: '#1CBB8C',
                                  },
                                ],
                              }}
                            />
                          </div>
                        ) : weekly ? (
                          <div
                            id="line-column-chart"
                            className="bar-charts "
                            dir="ltr"
                          >
                            <Bar
                              height={120}
                              data={{
                                labels: [
                                  'Sunday',
                                  'Monday',
                                  'Tuesday',
                                  'Wednesday',
                                  'Thursday',
                                  'Friday',
                                  'Saturday',
                                ],
                                datasets: [
                                  {
                                    label: 'Verified Tags',
                                    data: redWeekly,
                                    backgroundColor: '#FF4E60',
                                  },
                                  {
                                    label: 'Activated Tags',
                                    data: greenWeekly,
                                    backgroundColor: '#1CBB8C',
                                  },
                                ],
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            id="line-column-chart"
                            className="bar-charts "
                            dir="ltr"
                          >
                            <Bar
                              height={120}
                              data={{
                                labels: [
                                  'Sunday',
                                  'Monday',
                                  'Tuesday',
                                  'Wednesday',
                                  'Thursday',
                                  'Friday',
                                  'Saturday',
                                ],
                                datasets: [
                                  {
                                    label: 'Verified Tags',
                                    data: redMonthly,
                                    backgroundColor: '#FF4E60',
                                  },
                                  {
                                    label: 'Activated Tags',
                                    data: greenMonthly,
                                    backgroundColor: '#1CBB8C',
                                  },
                                ],
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div
                className="card"
                style={{
                  minHeight: '419px',
                }}
              >
                <div className="card-body">
                  <h4 className="card-title mb-3">Product Batch Analysis</h4>
                  {result && result.batch.length === 0 ? (
                    <div style={{ minHeight: '250px' }}>
                      <p className="claim-card">There are no product </p>
                    </div>
                  ) : (
                    <table
                      className="table table-centered` dt-responsive nowrap"
                      style={{
                        borderCollapse: 'collapse',
                        borderSpacing: 0,
                        width: '100',
                      }}
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>S/N</th>
                          <th>Batch No</th>
                          <th>Product</th>
                          <th>Points</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      {result && result.batch ? (
                        <>
                          <tbody>
                            {result.batch.map((item) => (
                              <tr key={item._id}>
                                <td>
                                  <a
                                    href="javascript: void(0);"
                                    className="text-dark fw-bold"
                                  >
                                    #{num++}
                                  </a>
                                </td>
                                <td>{item.batch}</td>
                                <td>
                                  {item.product && item.product
                                    ? item.product.name
                                    : null}
                                </td>
                                <td>{item.points}</td>

                                <td>
                                  {item.status && item.status === true ? (
                                    <div
                                      className={`badge badge-soft-success font-size-12`}
                                    >
                                      Activated
                                    </div>
                                  ) : (
                                    <div
                                      className={`badge badge-soft-pending font-size-12`}
                                    >
                                      Not Activated
                                    </div>
                                  )}
                                </td>
                                <td></td>
                              </tr>
                            ))}
                          </tbody>
                        </>
                      ) : null}
                    </table>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="card"
                style={{
                  minHeight: '419px',
                }}
              >
                <div className="card-body">
                  <h4 className="card-title mb-4">Recent Tag Order</h4>
                  <div className="table-responsive">
                    {result && result.order.length === 0 ? (
                      <div style={{ minHeight: '250px' }}>
                        <p className="claim-card">
                          There are no recent orders{' '}
                        </p>
                      </div>
                    ) : (
                      <table
                        className="table table-centered` dt-responsive nowrap"
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: 0,
                          width: '100',
                        }}
                      >
                        <thead className="thead-light">
                          <tr>
                            <th>Order ID</th>
                            <th>Product</th>
                            <th>Amount</th>
                            <th>Date Requested</th>
                            <th>Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {result &&
                            manufacturerRecentOrder(result.order).map(
                              (item) => (
                                <tr key={item._id}>
                                  <td>{item.orderID}</td>
                                  <td>{item.product && item.product.name}</td>
                                  <td>
                                    {item.amount
                                      ? formatNumber(item.amount)
                                      : null}
                                  </td>
                                  <td>
                                    {moment(item.created_at).format(
                                      'DD MMM, YYYY',
                                    )}
                                  </td>
                                  <td>
                                    <div
                                      className={`badge badge-soft-${item.status} font-size-11`}
                                    >
                                      {item.status}
                                    </div>
                                  </td>
                                </tr>
                              ),
                            )}
                        </tbody>
                      </table>
                    )}

                    {result && result.order.length > 0 ? (
                      <div className="text-center mt-4">
                        <a
                          href="/manufacturer/tag-request"
                          className="btn btn-success btn-sm"
                        >
                          View more
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Manufacturer
