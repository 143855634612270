import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import { MDBDataTableV5 } from 'mdbreact'
import { formatNumber } from '../../../components/functions'
import { useDispatch, useSelector } from 'react-redux'
import { allServiceProviderOrders } from '../../../actions/manufacturerActions/serviceProviderOrderActions/allServiceProviderOrders'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { updateProductOrder } from '../../../actions/manufacturerActions/serviceProviderOrderActions/updateProductOrder'
import { SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_RESET } from '../../../constants/manufacturerConstants/orderConstants'

export default function OrdersPage() {
    const [datatable, setDatatable] = useState({})
    const [model, setModel] = useState(false)
    const [detail, setDetails] = useState()
    const [status, setStatus] = useState('')
    const [itemId, setItemId] = useState('')

    const dispatch = useDispatch()

    const serviceProviderProductOrder = useSelector((state) => state.serviceProviderProductOrder)
    const { loading, orders, error } = serviceProviderProductOrder

    const updatedOrder = useSelector((state) => state.updatedOrder)
    const { loading: loadingUpdate, updated, error: updateError } = updatedOrder

    useEffect(() => {
        dispatch(allServiceProviderOrders())
    }, [updated])


    useEffect(() => {
        if (updated) {
            setTimeout(() => {
                dispatch({ type: SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_RESET })
                setModel(false)
            }, 5000);
        }


        if (updateError) {
            setTimeout(() => {
                dispatch({ type: SERVICEPROVIDERS_UPDATE_PRODUCT_ORDER_RESET })
            }, 5000);
        }
    }, [updated, updateError])

    useEffect(() => {
        if (orders) {
            setDatatable({
                columns: [
                    {
                        label: 'Order ID',
                        field: 'orderID',
                        width: 150,
                        attributes: {
                            'aria-controls': 'DataTable',
                            'aria-label': 'orderID',
                        },
                    },
                    {
                        label: 'Date',
                        field: 'date',
                        width: 200,
                    },

                    {
                        label: 'Product',
                        field: 'product',
                        width: 100,
                    },
                    {
                        label: 'Quantity',
                        field: 'amount',
                        width: 100,
                    },
                    {
                        label: 'Amount',
                        field: 'price',
                        width: 200,
                    },
                    {
                        label: 'Total',
                        field: 'totalPrice',
                        width: 200,
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        width: 100,
                        sort: 'disabled',
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        sort: 'disabled',
                    },
                ],
                rows: orders.map((item) => ({
                    orderID: item.orderID,
                    date: moment(item.created_at).format('Do MMMM YYYY,  h:mm a'),
                    product: item.product && item.product.name,
                    amount: item.quantity,
                    status: (
                        <div className={`badge badge-soft-${item.status} font-size-11`}>
                            {item.status}
                        </div>
                    ),
                    price: `NGN ${formatNumber(item.price)}`,
                    totalPrice: `NGN ${formatNumber(item.total)}`,
                    action: (
                        <>
                            <button
                                className="btn btn-sm btn-primary"
                                style={{ marginRight: '15px' }}
                                data-bs-container="#tooltip-container1"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Edit"
                                onClick={() => editOrder(item)}
                            >
                                Detail
                            </button>
                        </>
                    ),
                })),
            })
        }
    }, [orders])

    const editOrder = (item) => {
        setModel(true)
        setDetails(item)
        setItemId(item._id)
        setStatus(item.status)
    }

    const updateStatus = (e) => {
        e.preventDefault()
        dispatch(updateProductOrder(itemId, status))
    }


    return (
        <div id="layout-wrapper">
            <Header />
            <LeftSidebar />
            <RightSidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">All Orders</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Farmsured</a>
                                            </li>
                                            <li className="breadcrumb-item active">Orders</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {
                            loading
                                ?
                                <LoadingBox />
                                :
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                {error ? <MessageBox variant='danger'>{error}</MessageBox> : null}
                                                <MDBDataTableV5
                                                    hover
                                                    entriesOptions={[5, 10, 20, 25, 50]}
                                                    entries={10}
                                                    pagesAmount={4}
                                                    data={datatable}
                                                    searchTop
                                                    searchBottom={false}
                                                    barReverse
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                    </div>
                </div>

                {/* ==========================================================
                ==============================================================
                ============= Benefits Dialog Start Here =====================
                ==============================================================
                =============================================================*/}
                <Dialog
                    open={model}
                    onClose={() => setModel(false)}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md"
                >
                    <>
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Order</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setModel(false)}
                            ></button>
                        </div>

                        {
                            loadingUpdate ? <LoadingBox /> :
                                <DialogContent>
                                    {updated && <MessageBox variant="success">{updated.message}</MessageBox>}
                                    {updateError && <MessageBox variant="danger">{updateError}</MessageBox>}
                                    <div className="flex" style={{ flexDirection: 'column', justifyContent: 'space-between', alignItems: "center" }}>
                                        <div className="mb-3 mt-3">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className='card-title font-size-18 mb-0'>
                                                        <b>Order #{detail && detail.orderID}</b>
                                                    </p>
                                                    <div className={`badge badge-soft-${detail && detail.status} font-size-12`}>
                                                        {detail && detail.status}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <span className='float-end'>Order placed {moment(detail && detail.created_at).format('DD MMMM, YYYY')}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className='card-title font-size-17 mb-4'>Order Detail</h4>
                                                    <div className="mb-3 mt-3">
                                                        <span className='card-title'><b><i className="fas fa-credit-card me-1"></i> Payment Method</b></span>
                                                        <span className='float-end'>Esrow Transfer</span>
                                                        <hr style={{ marginBottom: ".45rem" }} />
                                                    </div>
                                                    <div className="mt-3">
                                                        <span className='card-title'><b><i className="fas fa-shipping-fast me-1"></i> Shipping Method</b></span>
                                                        <span className='float-end'>{detail && detail.deliverymethod}</span>
                                                        <hr style={{ marginBottom: ".45rem" }} />
                                                    </div>
                                                    <div className="mt-3">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h6 className="font-size-14">Status</h6>
                                                                <select
                                                                    className="form-select form-control select2 float-end"
                                                                    onChange={(e) => setStatus(e.target.value)}
                                                                    value={status}
                                                                >
                                                                    <option value="Order_Received">
                                                                        Order Received
                                                                    </option>
                                                                    <option value="Request_Sent">
                                                                        Request_Sent
                                                                    </option>
                                                                    <option value="Reviewing_Order">
                                                                        Reviewing Order
                                                                    </option>
                                                                    <option value="Awaiting_Payment">
                                                                        Awaiting Payment
                                                                    </option>
                                                                    <option value="Payment_Confirmed">
                                                                        Payment Confirmed
                                                                    </option>
                                                                    <option value="Order_in_Transit">
                                                                        Order in Transit
                                                                    </option>
                                                                    <option value="Delivered">Delivered</option>
                                                                    <option value="Declined">Declined</option>
                                                                </select>
                                                            </div>
                                                        </div>


                                                        <hr style={{ marginBottom: ".45rem" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className='card-title font-size-17 mb-4'>Customer Details</h4>
                                                    <div className="mb-3 mt-3">
                                                        <span className='card-title'><b><i className="fas fa-user me-1"></i> Name</b></span>
                                                        <span className='float-end'>{detail && detail.createdBy && detail.createdBy.firstName} {detail && detail.createdBy && detail.createdBy.lastName}</span>
                                                        <hr style={{ marginBottom: ".45rem" }} />
                                                    </div>
                                                    <div className="mb-3 mt-3">
                                                        <span className='card-title'><b><i className="fas fa-user me-1"></i> User ID</b></span>
                                                        <span className='float-end'>{detail && detail.createdBy && detail.createdBy.phone}</span>
                                                        <hr style={{ marginBottom: ".45rem" }} />
                                                    </div>
                                                    <div className="mt-3">
                                                        <span className='card-title'><b><i className="fas fa-envelope me-1"></i> Email</b></span>
                                                        <span className='float-end'>{detail && detail.createdBy && detail.createdBy.email}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            detail && detail.deliverymethod !== 'Self Pickup' &&
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className='card-title font-size-17 mb-4'>Shipping Address</h4>

                                                        <div className="mt-3">
                                                            <span className='card-title'>{detail && detail.createdBy && detail.createdBy.email}</span>
                                                            <span className='float-end' style={{ opacity: 5 }}><i className="fas fa-shipping-fast font-size-50" style={{ fontSize: "7rem", color: "#f1f1f1" }}></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div class="table-responsive">
                                                        <table class="table table-centered mb-0 table-nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <th class="border-top-0" style={{ width: "250px" }} scope="col">Product</th>
                                                                    <th class="border-top-0" scope="col">Product Desc</th>
                                                                    <th class="border-top-0" scope="col">Price</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        <h5 class="font-size-14 text-truncate">{detail && detail.product && detail.product.name}</h5>
                                                                    </th>
                                                                    <td>
                                                                        <h5 class="font-size-14 text-truncate">{detail && detail.product && detail.product.category ? detail.product.category.name : ''}</h5>
                                                                        <p class="text-muted mb-0">₦ {detail && detail.price ? formatNumber(detail.price) : 0} x {detail && detail.quantity}</p>
                                                                    </td>
                                                                    <td >₦ {detail && detail.total ? formatNumber(detail.total) : 0}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <h6 class="m-0 text-end">Sub Total:</h6>
                                                                    </td>
                                                                    <td>
                                                                        ₦ {detail && detail.total ? formatNumber(detail.total) : 0}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <h6 class="m-0 text-end">Total:</h6>
                                                                    </td>
                                                                    <td>
                                                                        ₦ {detail && detail.total ? formatNumber(detail.total) : 0}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DialogContent>
                        }

                        <DialogActions>
                            <button
                                type="button"
                                className="btn btn-success btn-sm waves-effect me-1 mb-4"
                                data-bs-dismiss="modal"
                                onClick={updateStatus}
                            >
                                Update
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger btn-sm waves-effect me-3 mb-4"
                                data-bs-dismiss="modal"
                                onClick={() => setModel(false)}
                            >
                                Close
                            </button>
                        </DialogActions>
                    </>
                </Dialog>
            </div>
            <Footer />
        </div>
    )
}
