import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import submitApplication from '../../actions/authActions/submitApplication'
import { LoadingBox } from '../../components'
import { APPLICATION_RESET } from '../../constants/authConstants/applicationContants'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import Axios from 'axios'
import { TailSpin } from 'react-loading-icons'

export default function OnboardingPage() {
  const [alert, setAlert] = useState(false)
  const [step, setStep] = useState(1)
  const [changedNumber, setChangedNumber] = useState('')
  const [reason, setReason] = useState('')
  const [surname, setSurname] = useState('')
  const [firstName, setFirstName] = useState('')
  const [otherName, setOtherName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [batch, setBatch] = useState('')
  const [IDCard, setIDCard] = useState('')
  const [NCareBeneficiary, setNCareBeneficiary] = useState('')
  const [NCareSupport, setNCareSupport] = useState('')
  const [ownABusiness, setOwnABusiness] = useState('')
  const [typeOfBusiness, setTypeOfBusiness] = useState('')
  const [activityAfterYeap, setActivityAfterYeap] = useState('')
  const [revenue, setRevenue] = useState('')
  const [haveStaff, setHaveStaff] = useState('')
  const [soloOrGroupBusiness, setSoloOrGroupBusiness] = useState('')
  const [websiteAddress, setWebsiteAddress] = useState('')
  const [isYourBusinessRegistered, setIsYourBusinessRegistered] = useState('')
  const [joinAnAgribusiness, setJoinAnAgribusiness] = useState('')
  const [specifyAgribusiness, setSpecifyAgribusiness] = useState('')
  const [needsLoan, setNeedsLoan] = useState('')
  const [enterpriseType, setEnterpriseType] = useState('')
  const [cassavaEnterprise, setCassavaEnterprise] = useState('')
  const [poultryEnterprise, setPoultryEnterprise] = useState('')
  const [largeRuminantEnterprise, setLargeRuminantEnterprise] = useState('')
  const [smallRuminantEnterprise, setSmallRuminantEnterprise] = useState('')
  const [aquacultureEnterprise, setAquacultureEnterprise] = useState('')
  const [monogastricsEnterprise, setMonogastricsEnterprise] = useState('')
  const [cropEnterprise, setCropEnterprise] = useState('')
  const [vegetableEnterprise, setVegetableEnterprise] = useState('')
  const [serviceProviderEnterprise, setServiceProviderEnterprise] = useState('')
  const [hasExperience, setHasExperience] = useState('')
  const [experienceExplanation, setExperienceExplanation] = useState('')
  const [supportOptions, setSupportOptions] = useState({
    land: false,
    facilities: false,
    mentorship: false,
    markets: false,
    otherOption: false,
    other: '',
  })
  const [assetSupport, setAssetSupport] = useState('')
  const [assetSupportType, setTypeAssetSupportType] = useState('')
  const [comments, setComments] = useState('')
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [loadingDate, setLoadingDate] = useState('')
  const [DateError, setDateError] = useState('')

  const applicationSubmission = useSelector(
    (state) => state.applicationSubmission,
  )
  const { loading, error, application } = applicationSubmission

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    if (isDeclarationChecked !== true) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Terms & Conditions"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Please confirm your acceptance of the terms and conditions.
        </SweetAlert>,
      )
    } else
      dispatch(
        submitApplication(
          changedNumber,
          reason,
          surname,
          firstName,
          otherName,
          email,
          phone,
          batch,
          IDCard,
          NCareBeneficiary,
          NCareSupport,
          ownABusiness,
          typeOfBusiness,
          activityAfterYeap,
          revenue,
          haveStaff,
          soloOrGroupBusiness,
          websiteAddress,
          isYourBusinessRegistered,
          joinAnAgribusiness,
          specifyAgribusiness,
          needsLoan,
          enterpriseType,
          cassavaEnterprise,
          poultryEnterprise,
          largeRuminantEnterprise,
          smallRuminantEnterprise,
          aquacultureEnterprise,
          monogastricsEnterprise,
          cropEnterprise,
          vegetableEnterprise,
          serviceProviderEnterprise,
          hasExperience,
          experienceExplanation,
          supportOptions,
          assetSupport,
          assetSupportType,
          comments,
          isDeclarationChecked,
        ),
      )
  }

  useEffect(() => {
    if (application)
      setTimeout(() => {
        dispatch({ type: APPLICATION_RESET })
        window.location.reload()
      }, 2500)
  }, [dispatch, application])

  useEffect(() => {
    if (error)
      setTimeout(() => {
        dispatch({ type: APPLICATION_RESET })
      }, 2500)
  }, [dispatch, error])

  const handleBack = () => {
    setStep(step - 1)
  }

  const checkStep = (e) => {
    e.preventDefault()
    if (
      !changedNumber ||
      !surname ||
      !firstName ||
      !email ||
      !phone ||
      !batch
    ) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Fields"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Kindly fill all fields!!!
        </SweetAlert>,
      )
    } else {
      setStep(step + 1)
    }
  }

  const checkStep1 = (e) => {
    e.preventDefault()
    if (!IDCard || !NCareBeneficiary) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Fields"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Kindly tick the term and condition!!!
        </SweetAlert>,
      )
    } else if (NCareBeneficiary === 'Yes' && !NCareSupport) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Fields"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Kindly fill all fields!!!
        </SweetAlert>,
      )
    } else {
      setStep(step + 1)
    }
  }

  const checkStep2 = (e) => {
    e.preventDefault()
    if (!ownABusiness) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Fields"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Kindly fill all fields!!!
        </SweetAlert>,
      )
    } else if (
      ownABusiness === 'Yes' &&
      !typeOfBusiness &&
      !isYourBusinessRegistered
    ) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Fields"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Kindly fill all fields!!
        </SweetAlert>,
      )
    } else if (ownABusiness === 'No' && !joinAnAgribusiness) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Fields"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Kindly fill all fields!!!
        </SweetAlert>,
      )
    } else {
      setStep(step + 1)
    }
  }

  const checkDate = (startDate, endDate) => {
    const start = moment(startDate) // Replace with your start date
    const end = moment(endDate) // Replace with your end date\

    const currentDate = moment()
    return currentDate.isBetween(start, end, null, '[]') // '[]' includes the start and end dates
  }

  useEffect(() => {
    setLoadingDate(true)
    async function getDate() {
      try {
        const { data } = await Axios.post('/api/get-dates', {
          name: 'YEAP-SAfER',
        })

        if (data) {
          setStartDate(data.startDate)
          setEndDate(data.endDate)
        }
        setLoadingDate(false)
      } catch (error) {
        setDateError(error)
      }
    }

    getDate()
  }, [])

  return (
    <div>
      <div className="container-fluid p-0">
        {loading ? (
          <LoadingBox />
        ) : (
          <div className="row g-0">
            <div className="col-lg-3">
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100 container-height">
                <div className="w-100">
                  <div className="row justify-content-center">
                    <div className="col-lg-11">
                      {error
                        ? toast.error(error, {
                            position: toast.POSITION.TOP_RIGHT,
                          })
                        : null}

                      {application
                        ? toast.success(application.message, {
                            position: toast.POSITION.TOP_RIGHT,
                          })
                        : null}

                      {DateError
                        ? toast.error(DateError, {
                            position: toast.POSITION.TOP_RIGHT,
                          })
                        : null}
                      <div className="">
                        <img
                          src="assets/images/YEAP-SAfER_Loan_Badge.png"
                          alt=""
                          className="auth-logo logo-dark mx-auto"
                          style={{ width: '50%' }}
                        />
                        <img
                          src="assets/images/YEAP-SAfER_Loan_Badge.png"
                          alt=""
                          className="auth-logo logo-light mx-auto yeap-safer-logo"
                          style={{ width: '50%' }}
                        />
                      </div>

                      <div className="card mt-3">
                        <div className="card-body">
                          {loadingDate ? (
                            <div className="tailspin">
                              <TailSpin
                                stroke="#98ff98"
                                strokeWidth="3"
                                color="#06bcee"
                              />
                            </div>
                          ) : (
                            <div>
                              {checkDate(startDate, endDate) ? (
                                <form onSubmit={submitHandler}>
                                  {/* Step Starts Here */}
                                  {step === 1 && (
                                    <div className="">
                                      <h4 className="font-size-20">
                                        YEAP-SAfER AGRIBUSINESS ENTERPRISE
                                        SUPPORT
                                      </h4>
                                      <h4 className="font-size-18">
                                        Personal Information
                                      </h4>

                                      <p
                                        style={{
                                          backgroundColor: 'green',
                                          padding: '10px',
                                          borderRadius: '5px',
                                          color: 'white',
                                          width: '100%',
                                        }}
                                      >
                                        Ensure you use the same data used in
                                        your YEAP Application. Enter your
                                        details correctly, and ensure all fields
                                        are filled!
                                      </p>

                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="mb-3">
                                            <label
                                              className="form-label"
                                              forhtml="surname"
                                            >
                                              Have you changed your phone number
                                              and email address used for YEAP
                                              registration?
                                            </label>
                                            <select
                                              onChange={(e) =>
                                                setChangedNumber(e.target.value)
                                              }
                                              className="select2 form-select"
                                              required
                                              placeholder="Have you received your YEAP Biometric ID Card?"
                                              value={changedNumber}
                                            >
                                              <option>Select an option</option>
                                              <option value={'Yes'}>Yes</option>
                                              <option value={'No'}>No</option>
                                            </select>
                                          </div>
                                        </div>

                                        {changedNumber === 'Yes' && (
                                          <div className="col-md-12">
                                            <div className="mb-3">
                                              <label
                                                className="form-label"
                                                forhtml="reasonForNewNumber"
                                              >
                                                Give reason why you changed your
                                                phone and/or email?
                                              </label>
                                              <textarea
                                                id="reasonForNewNumber"
                                                name="reasonForNewNumber"
                                                type="text"
                                                className="form-control"
                                                placeholder="Give reason why you changed your phone and/or email?"
                                                required
                                                value={reason}
                                                onChange={(e) =>
                                                  setReason(e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}

                                        <div className="col-md-4">
                                          <div className="mb-3">
                                            <label
                                              className="form-label"
                                              forhtml="surname"
                                            >
                                              Surname/Last Name
                                            </label>
                                            <input
                                              id="surname"
                                              name="surname"
                                              type="text"
                                              className="form-control"
                                              placeholder="Surname"
                                              required
                                              value={surname}
                                              onChange={(e) =>
                                                setSurname(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="mb-3">
                                            <label
                                              className="form-label"
                                              forhtml="otherName"
                                            >
                                              First Name
                                            </label>
                                            <input
                                              id="firstName"
                                              name="firstName"
                                              type="text"
                                              className="form-control"
                                              placeholder="First Name"
                                              required
                                              onChange={(e) =>
                                                setFirstName(e.target.value)
                                              }
                                              value={firstName}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="mb-3">
                                            <label
                                              className="form-label"
                                              forhtml="otherName"
                                            >
                                              Other Names
                                            </label>
                                            <input
                                              id="otherName"
                                              name="otherName"
                                              type="text"
                                              className="form-control"
                                              placeholder="Other Names"
                                              required
                                              onChange={(e) =>
                                                setOtherName(e.target.value)
                                              }
                                              value={otherName}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-9">
                                          <div className="mb-3">
                                            <label
                                              className="form-label"
                                              forhtml="email"
                                            >
                                              Email
                                            </label>
                                            <input
                                              id="email"
                                              name="email"
                                              type="email"
                                              className="form-control"
                                              placeholder="Email"
                                              required
                                              onChange={(e) =>
                                                setEmail(e.target.value)
                                              }
                                              value={email}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-9">
                                          <div className="mb-3">
                                            <label
                                              className="form-label"
                                              forhtml="email"
                                            >
                                              Phone Number
                                            </label>
                                            <input
                                              id="phone"
                                              name="phone"
                                              type="number"
                                              className="form-control"
                                              placeholder="Phone Number"
                                              required
                                              onChange={(e) =>
                                                setPhone(e.target.value)
                                              }
                                              value={phone}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-9">
                                          <div className="mb-3">
                                            <label
                                              className="form-label"
                                              forhtml="batch"
                                            >
                                              YEAP Training Batch
                                            </label>
                                            <select
                                              onChange={(e) =>
                                                setBatch(e.target.value)
                                              }
                                              className="select2 form-select"
                                              required
                                              placeholder="YEAP Training Batch"
                                              value={batch}
                                            >
                                              <option>Select an option</option>
                                              <option value={'Batch 1'}>
                                                Batch 1
                                              </option>
                                              <option value={'Batch 2'}>
                                                Batch 2
                                              </option>
                                              <option value={'Batch 3'}>
                                                Batch 3
                                              </option>
                                              <option value={'Batch 4'}>
                                                Batch 4
                                              </option>
                                              <option value={'Batch 5'}>
                                                Batch 5
                                              </option>
                                              <option value={'Batch 6'}>
                                                Batch 6
                                              </option>
                                              <option value={'Batch 7'}>
                                                Batch 7
                                              </option>
                                              <option value={'Batch 8'}>
                                                Batch 8
                                              </option>
                                              <option value={'Batch 9'}>
                                                Batch 9
                                              </option>
                                              <option value={'Batch 10'}>
                                                Batch 10
                                              </option>
                                              <option value={'Batch 11'}>
                                                Batch 11
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="float-end mt-3">
                                        <button
                                          className="btn btn-success"
                                          onClick={checkStep}
                                        >
                                          Next
                                        </button>
                                      </div>
                                    </div>
                                  )}

                                  {/* Step One Starts Here */}
                                  {step === 2 && (
                                    <div className="">
                                      <h4 className="font-size-18">
                                        YEAP Program Details
                                      </h4>
                                      <div className="mb-3">
                                        Enter your details correctly, and ensure
                                        all fields are filled!
                                      </div>

                                      <div className="row">
                                        <div className="col-md-9">
                                          <div className="mb-3">
                                            <label
                                              className="form-label"
                                              forhtml="batch"
                                            >
                                              Have you received your YEAP
                                              Biometric ID Card?
                                            </label>
                                            <select
                                              onChange={(e) =>
                                                setIDCard(e.target.value)
                                              }
                                              className="select2 form-select"
                                              required
                                              placeholder="Have you received your YEAP Biometric ID Card?"
                                              value={IDCard}
                                            >
                                              <option>Select an option</option>
                                              <option value={'Yes'}>Yes</option>
                                              <option value={'No'}>No</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className="col-md-9">
                                          <div className="mb-3">
                                            <label
                                              className="form-label"
                                              forhtml="batch"
                                            >
                                              Have you received any enterprise
                                              support from Oyo State Government,
                                              e.g (NCARES, etc)?
                                            </label>
                                            <select
                                              onChange={(e) =>
                                                setNCareBeneficiary(
                                                  e.target.value,
                                                )
                                              }
                                              className="select2 form-select"
                                              required
                                              placeholder="Are you an NCARES Beneficiary?"
                                              value={NCareBeneficiary}
                                            >
                                              <option>Select an option</option>
                                              <option value={'Yes'}>Yes</option>
                                              <option value={'No'}>No</option>
                                            </select>
                                          </div>
                                        </div>
                                        {NCareBeneficiary === 'Yes' ? (
                                          <div className="col-md-9">
                                            <div className="mb-3">
                                              <label
                                                className="form-label"
                                                forhtml="batch"
                                              >
                                                If YES, please specify the
                                                support received:
                                              </label>
                                              <input
                                                id="support"
                                                name="support"
                                                type="text"
                                                className="form-control"
                                                placeholder="Please specify the support received"
                                                required
                                                onChange={(e) =>
                                                  setNCareSupport(
                                                    e.target.value,
                                                  )
                                                }
                                                value={NCareSupport}
                                              />
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>

                                      <div className="mt-3">
                                        <button
                                          className="btn btn-success"
                                          onClick={handleBack}
                                        >
                                          Previous
                                        </button>
                                        <button
                                          className="btn btn-success float-end"
                                          onClick={checkStep1}
                                        >
                                          Next
                                        </button>
                                      </div>
                                    </div>
                                  )}

                                  {/* Step Two Starts Here */}
                                  {step === 3 && (
                                    <div className="">
                                      <h4 className="font-size-18">
                                        Agribusiness Background
                                      </h4>
                                      <div className="mb-3">
                                        Enter your details correctly, and ensure
                                        all fields are filled!
                                      </div>
                                      <div className="row">
                                        <div className="col-md-9">
                                          <div className="mb-3">
                                            <label
                                              className="form-label"
                                              forhtml="batch"
                                            >
                                              Do you currently own or operate an
                                              agribusiness?
                                            </label>
                                            <select
                                              onChange={(e) =>
                                                setOwnABusiness(e.target.value)
                                              }
                                              className="select2 form-select"
                                              required
                                              placeholder="Do you currently own or operate an agribusiness?"
                                              value={ownABusiness}
                                            >
                                              <option>Select an option</option>
                                              <option value={'Yes'}>Yes</option>
                                              <option value={'No'}>No</option>
                                            </select>
                                          </div>
                                        </div>

                                        {/* If the user answers YES */}
                                        {ownABusiness === 'Yes' && (
                                          <div className="">
                                            <div className="col-md-9">
                                              <div className="mb-3">
                                                <label
                                                  className="form-label"
                                                  forhtml="batch"
                                                >
                                                  If YES, please explain the
                                                  type of agribusiness you are
                                                  involved in:
                                                </label>
                                                <textarea
                                                  id="typeOfAgribusiness"
                                                  name="typeOfAgribusiness"
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Please explain the type of Agribusiness"
                                                  required
                                                  onChange={(e) =>
                                                    setTypeOfBusiness(
                                                      e.target.value,
                                                    )
                                                  }
                                                  value={typeOfBusiness}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-md-9">
                                              <div className="mb-3">
                                                <label
                                                  className="form-label"
                                                  forhtml="revenue"
                                                >
                                                  If YES, what is your
                                                  agribusiness monthly revenue
                                                </label>
                                                <input
                                                  id="revenue"
                                                  name="revenue"
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="what is your agribusiness monthly revenue"
                                                  required
                                                  onChange={(e) =>
                                                    setRevenue(e.target.value)
                                                  }
                                                  value={revenue}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-md-9">
                                              <div className="mb-3">
                                                <label
                                                  className="form-label"
                                                  forhtml="setHaveStaff"
                                                >
                                                  Do you currently have
                                                  staff/employees?
                                                </label>
                                                <select
                                                  onChange={(e) =>
                                                    setHaveStaff(e.target.value)
                                                  }
                                                  className="select2 form-select"
                                                  required
                                                  placeholder="Do you currently have staff/employees? "
                                                  value={haveStaff}
                                                >
                                                  <option>
                                                    Select an option
                                                  </option>
                                                  <option value={'Yes'}>
                                                    Yes
                                                  </option>
                                                  <option value={'No'}>
                                                    No
                                                  </option>
                                                </select>
                                              </div>
                                            </div>

                                            <div className="col-md-9">
                                              <div className="mb-3">
                                                <label
                                                  className="form-label"
                                                  forhtml="setHaveStaff"
                                                >
                                                  Is the agribusiness a solo or
                                                  group business?
                                                </label>
                                                <select
                                                  onChange={(e) =>
                                                    setSoloOrGroupBusiness(
                                                      e.target.value,
                                                    )
                                                  }
                                                  className="select2 form-select"
                                                  required
                                                  placeholder="Is the agribusiness a solo or group business?"
                                                  value={soloOrGroupBusiness}
                                                >
                                                  <option>
                                                    Select an option
                                                  </option>
                                                  <option
                                                    value={'Solo business'}
                                                  >
                                                    Solo business
                                                  </option>
                                                  <option
                                                    value={'Group business'}
                                                  >
                                                    Group business
                                                  </option>
                                                </select>
                                              </div>
                                            </div>

                                            <div className="col-md-9">
                                              <div className="mb-3">
                                                <label
                                                  className="form-label"
                                                  forhtml="websiteAddress"
                                                >
                                                  Provide your website address
                                                  link if any
                                                </label>
                                                <input
                                                  id="websiteAddress"
                                                  name="websiteAddress"
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Provide your website address link?"
                                                  required
                                                  onChange={(e) =>
                                                    setWebsiteAddress(
                                                      e.target.value,
                                                    )
                                                  }
                                                  value={websiteAddress}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-md-9">
                                              <div className="mb-3">
                                                <label
                                                  className="form-label"
                                                  forhtml="isYourBusinessRegistered"
                                                >
                                                  Is your current agribusiness
                                                  registered?
                                                </label>
                                                <select
                                                  onChange={(e) =>
                                                    setIsYourBusinessRegistered(
                                                      e.target.value,
                                                    )
                                                  }
                                                  className="select2 form-select"
                                                  required
                                                  placeholder="Are you an NCARES Beneficiary?"
                                                  value={
                                                    isYourBusinessRegistered
                                                  }
                                                >
                                                  <option>
                                                    Select an option
                                                  </option>
                                                  <option value={'Yes'}>
                                                    Yes
                                                  </option>
                                                  <option value={'No'}>
                                                    No
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                        {/* If the user answers NO */}
                                        {ownABusiness === 'No' && (
                                          <div className="">
                                            <div className="col-md-9">
                                              <div className="mb-3">
                                                <label
                                                  className="form-label"
                                                  forhtml="activityAfterYeap"
                                                >
                                                  If NO, what have you been
                                                  engaged in after the YEAP
                                                  Training?
                                                </label>
                                                <textarea
                                                  id="activityAfterYeap"
                                                  name="activityAfterYeap"
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="If NO, what have you been engaged in after the YEAP Training?"
                                                  required
                                                  value={activityAfterYeap}
                                                  onChange={(e) =>
                                                    setActivityAfterYeap(
                                                      e.target.value,
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="col-md-9">
                                              <div className="mb-3">
                                                <label
                                                  className="form-label"
                                                  forhtml="batch"
                                                >
                                                  If NO, are you willing to join
                                                  an agribusiness to gain
                                                  experience before starting
                                                  your own?
                                                </label>
                                                <select
                                                  onChange={(e) =>
                                                    setJoinAnAgribusiness(
                                                      e.target.value,
                                                    )
                                                  }
                                                  className="select2 form-select"
                                                  required
                                                  placeholder="Are you an NCARES Beneficiary?"
                                                  value={joinAnAgribusiness}
                                                >
                                                  <option>
                                                    Select an option
                                                  </option>
                                                  <option value={'Yes'}>
                                                    Yes
                                                  </option>
                                                  <option value={'No'}>
                                                    No
                                                  </option>
                                                </select>
                                              </div>
                                            </div>

                                            {/* If users input for the above is YES */}
                                            {joinAnAgribusiness === 'Yes' && (
                                              <div className="col-md-9">
                                                <div className="mb-3">
                                                  <label
                                                    className="form-label"
                                                    forhtml="agribusinessInterest"
                                                  >
                                                    If YES, please specify the
                                                    type of agribusiness you are
                                                    interested in:
                                                  </label>
                                                  <input
                                                    id="agribusinessInterest"
                                                    name="agribusinessInterest"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Please specify the type of
                                        agribusiness you are interested in"
                                                    required
                                                    value={specifyAgribusiness}
                                                    onChange={(e) =>
                                                      setSpecifyAgribusiness(
                                                        e.target.value,
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}

                                        <div className="mt-3">
                                          <button
                                            className="btn btn-success"
                                            onClick={handleBack}
                                          >
                                            Previous
                                          </button>
                                          <button
                                            className="btn btn-success float-end"
                                            onClick={checkStep2}
                                          >
                                            Next
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {/* Step Three Starts Here */}
                                  {step === 4 && (
                                    <div className="">
                                      <div className="row">
                                        <div className="col-md-9">
                                          <div className="mb-3">
                                            <label>
                                              Do you need enterprise support in
                                              the form of a loan?
                                            </label>
                                            <select
                                              value={needsLoan}
                                              className="select2 form-select"
                                              onChange={(e) =>
                                                setNeedsLoan(e.target.value)
                                              }
                                            >
                                              <option value="">
                                                Select an option
                                              </option>
                                              <option value="Yes">Yes</option>
                                              <option value="No">No</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                      {/* Question 2 */}
                                      {needsLoan === 'Yes' && (
                                        <div className="col-md-9">
                                          <div className="mb-3">
                                            <label>
                                              If YES, please specify the type of
                                              agribusiness enterprise you plan
                                              to use the loan for:
                                            </label>
                                            <select
                                              value={enterpriseType}
                                              className="select2 form-select"
                                              onChange={(e) =>
                                                setEnterpriseType(
                                                  e.target.value,
                                                )
                                              }
                                            >
                                              <option value="">
                                                Select an option
                                              </option>
                                              <option value="Cassava Agribusiness">
                                                Cassava Agribusiness
                                              </option>
                                              <option value="Poultry Agribusiness">
                                                Poultry Agribusiness
                                              </option>
                                              <option value="Large Ruminant Agribusiness">
                                                Large Ruminant Agribusiness
                                              </option>
                                              <option value="Small Ruminant Agribusiness">
                                                Small Ruminant Agribusiness
                                              </option>
                                              <option value="Aquaculture">
                                                Aquaculture
                                              </option>
                                              <option value="Monogastrics">
                                                Monogastrics
                                              </option>
                                              <option value="Microlivestock (e.g., rabbits, grass cutters, snails, etc.)">
                                                Microlivestock (e.g., rabbits,
                                                grass cutters, snails, etc.)
                                              </option>
                                              <option value="Other Crop Agribusiness">
                                                Other Crop Agribusiness
                                              </option>
                                              <option value="Crop processing and value addition">
                                                Crop processing and value
                                                addition
                                              </option>
                                              <option value="Vegetable Agribusiness">
                                                Vegetable Agribusiness
                                              </option>
                                              <option value="Service Provision">
                                                Service Provision
                                              </option>
                                              <option value="Other Agribusiness">
                                                Other Agribusiness
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      )}
                                      {/* Cassava Enterprise */}
                                      {needsLoan === 'Yes' &&
                                        enterpriseType ===
                                          'Cassava Agribusiness' && (
                                          <div className="col-md-9">
                                            <div className="mb-3">
                                              <label>
                                                Cassava Agribusiness
                                              </label>
                                              <select
                                                value={cassavaEnterprise}
                                                className="select2 form-select"
                                                onChange={(e) =>
                                                  setCassavaEnterprise(
                                                    e.target.value,
                                                  )
                                                }
                                              >
                                                <option>
                                                  Select an option
                                                </option>
                                                <option value="Stem multiplication, tuber production, and trade">
                                                  Stem multiplication, tuber
                                                  production, and trade
                                                </option>
                                                <option value="Tuber processing into flakes (e.g., gari, chips, starch, etc.)">
                                                  Tuber processing into flakes
                                                  (e.g., gari, chips, starch,
                                                  etc.)
                                                </option>
                                                <option value="High-quality Cassava Peels (HQCP) Mash Production">
                                                  High-quality Cassava Peels
                                                  (HQCP) Mash Production
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        )}

                                      {/* Poultry Enterprise */}
                                      {needsLoan === 'Yes' &&
                                        enterpriseType ===
                                          'Poultry Agribusiness' && (
                                          <div className="col-md-9">
                                            <div className="mb-3">
                                              <label>
                                                Poultry Agribusiness
                                              </label>
                                              <select
                                                value={poultryEnterprise}
                                                className="select2 form-select"
                                                onChange={(e) =>
                                                  setPoultryEnterprise(
                                                    e.target.value,
                                                  )
                                                }
                                              >
                                                <option>
                                                  Select an option
                                                </option>
                                                <option value="Broiler production">
                                                  Broiler production
                                                </option>
                                                <option value="Egg production and layer management">
                                                  Egg production and layer
                                                  management
                                                </option>
                                                <option value="Chicken processing and egg packaging">
                                                  Chicken processing and egg
                                                  packaging
                                                </option>
                                                <option value="Dual-purpose birds or others">
                                                  Dual-purpose birds or others
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        )}

                                      {/* Large Ruminant Agribusiness: */}
                                      {needsLoan === 'Yes' &&
                                        enterpriseType ===
                                          'Large Ruminant Agribusiness' && (
                                          <div className="col-md-9">
                                            <div className="mb-3">
                                              <label>
                                                Large Ruminant Agribusiness
                                              </label>
                                              <select
                                                value={largeRuminantEnterprise}
                                                className="select2 form-select"
                                                onChange={(e) =>
                                                  setLargeRuminantEnterprise(
                                                    e.target.value,
                                                  )
                                                }
                                              >
                                                <option>
                                                  Select an option
                                                </option>
                                                <option value="Cattle breeding and dairy production ">
                                                  Cattle breeding and dairy
                                                  production
                                                </option>
                                                <option value="Cattle fattening and beef processing">
                                                  Cattle fattening and beef
                                                  processing
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        )}

                                      {/* Small Ruminant Agribusiness */}
                                      {needsLoan === 'Yes' &&
                                        enterpriseType ===
                                          'Small Ruminant Agribusiness' && (
                                          <div className="col-md-9">
                                            <div className="mb-3">
                                              <label>
                                                Small Ruminant Agribusiness
                                              </label>
                                              <select
                                                value={smallRuminantEnterprise}
                                                className="select2 form-select"
                                                onChange={(e) =>
                                                  setSmallRuminantEnterprise(
                                                    e.target.value,
                                                  )
                                                }
                                              >
                                                <option>
                                                  Select an option
                                                </option>
                                                <option value="Goat breeding">
                                                  Goat breeding
                                                </option>
                                                <option value="Goat fattening and value addition">
                                                  Goat fattening and value
                                                  addition
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        )}

                                      {/*Aquaculture */}
                                      {needsLoan === 'Yes' &&
                                        enterpriseType === 'Aquaculture' && (
                                          <div className="col-md-9">
                                            <div className="mb-3">
                                              <label>Aquaculture</label>
                                              <select
                                                value={aquacultureEnterprise}
                                                className="select2 form-select"
                                                onChange={(e) =>
                                                  setAquacultureEnterprise(
                                                    e.target.value,
                                                  )
                                                }
                                              >
                                                <option>
                                                  Select an option
                                                </option>
                                                <option value="Fish farming">
                                                  Fish farming
                                                </option>
                                                <option value="Fish processing and value addition">
                                                  Fish processing and value
                                                  addition
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        )}

                                      {/*Monogastrics */}
                                      {needsLoan === 'Yes' &&
                                        enterpriseType === 'Monogastrics' && (
                                          <div className="col-md-9">
                                            <div className="mb-3">
                                              <label>Monogastrics</label>
                                              <select
                                                value={monogastricsEnterprise}
                                                className="select2 form-select"
                                                onChange={(e) =>
                                                  setMonogastricsEnterprise(
                                                    e.target.value,
                                                  )
                                                }
                                              >
                                                <option>
                                                  Select an option
                                                </option>
                                                <option value="Pig Farming and value addition">
                                                  Pig Farming and value addition
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        )}

                                      {/*Other Crop Agribusiness */}
                                      {needsLoan === 'Yes' &&
                                        enterpriseType ===
                                          'Other Crop Agribusiness' && (
                                          <div className="col-md-9">
                                            <div className="mb-3">
                                              <label>
                                                Other Crop Agribusiness
                                              </label>
                                              <select
                                                value={cropEnterprise}
                                                className="select2 form-select"
                                                onChange={(e) =>
                                                  setCropEnterprise(
                                                    e.target.value,
                                                  )
                                                }
                                              >
                                                <option>
                                                  Select an option
                                                </option>
                                                <option value="Maize farming and trade">
                                                  Maize farming and trade
                                                </option>
                                                <option value="Other cereals (e.g., soybean, beans, nuts, etc.)">
                                                  Other cereals (e.g., soybean,
                                                  beans, nuts, etc.)
                                                </option>
                                                <option value="Mushroom farming and value addition">
                                                  Mushroom farming and value
                                                  addition
                                                </option>
                                                <option value="Pasture Production and Fodder Marketing">
                                                  Pasture Production and Fodder
                                                  Marketing
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        )}

                                      {/*Vegetable Agribusiness */}
                                      {needsLoan === 'Yes' &&
                                        enterpriseType ===
                                          'Vegetable Agribusiness' && (
                                          <div className="col-md-9">
                                            <div className="mb-3">
                                              <label>
                                                Vegetable Agribusiness
                                              </label>
                                              <select
                                                value={vegetableEnterprise}
                                                className="select2 form-select"
                                                onChange={(e) =>
                                                  setVegetableEnterprise(
                                                    e.target.value,
                                                  )
                                                }
                                              >
                                                <option>
                                                  Select an option
                                                </option>
                                                <option value="Tomato and pepper">
                                                  Tomato and pepper
                                                </option>
                                                <option value="Vegetable Production in Greenhouses and Hydroponics">
                                                  Vegetable Production in
                                                  Greenhouses and Hydroponics
                                                </option>
                                                <option value="Other vegetables">
                                                  Other vegetables
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        )}

                                      {/*Service Provision */}
                                      {needsLoan === 'Yes' &&
                                        enterpriseType ===
                                          'Service Provision' && (
                                          <div className="col-md-9">
                                            <div className="mb-3">
                                              <label>Service Provision</label>
                                              <select
                                                value={
                                                  serviceProviderEnterprise
                                                }
                                                className="select2 form-select"
                                                onChange={(e) =>
                                                  setServiceProviderEnterprise(
                                                    e.target.value,
                                                  )
                                                }
                                              >
                                                <option>
                                                  Select an option
                                                </option>
                                                <option value="Sales of Farm Inputs">
                                                  Sales of Farm Inputs
                                                </option>
                                                <option value="Sales of Poultry and Livestock Inputs">
                                                  Sales of Poultry and Livestock
                                                  Inputs
                                                </option>
                                                <option value="Agro-products trade">
                                                  Agro-products trade
                                                </option>
                                                <option value="Digital Agriculture and Agribusiness">
                                                  Digital Agriculture and
                                                  Agribusiness
                                                </option>
                                                <option value="Farm machinery and Equipment Sales and Leasing">
                                                  Farm machinery and Equipment
                                                  Sales and Leasing
                                                </option>
                                                <option value="Other Services">
                                                  Other Services
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        )}

                                      {/*Other Agribusiness*/}
                                      {needsLoan === 'Yes' &&
                                        enterpriseType ===
                                          'Other Agribusiness' && (
                                          <div className="col-md-9">
                                            <div className="mb-3">
                                              <div className="col-md-9">
                                                <div className="mb-3">
                                                  <label>
                                                    Do you have experience in
                                                    this business?
                                                  </label>
                                                  <select
                                                    value={hasExperience}
                                                    className="select2 form-select"
                                                    onChange={(e) =>
                                                      setHasExperience(
                                                        e.target.value,
                                                      )
                                                    }
                                                  >
                                                    <option>
                                                      Select an option
                                                    </option>
                                                    <option value="Yes">
                                                      Yes
                                                    </option>
                                                    <option value="No">
                                                      No
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                      {/* Question 4 */}
                                      {hasExperience === 'Yes' && (
                                        <div className="col-md-9">
                                          <div className="mb-3">
                                            <label>
                                              If YES, please explain your
                                              experience and how you intend to
                                              use the loan profitably and ensure
                                              timely repayment:
                                            </label>
                                            <textarea
                                              value={experienceExplanation}
                                              className="form-control"
                                              onChange={(e) =>
                                                setExperienceExplanation(
                                                  e.target.value,
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}

                                      {/* Question 5 */}
                                      <div className="row">
                                        <div className="col-md-9">
                                          <div className="mb-3">
                                            <label>
                                              Which other forms of enterprise
                                              support do you need? (Check all
                                              that apply)
                                            </label>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="flexCheckDefault1"
                                                checked={supportOptions.land}
                                                onChange={(e) =>
                                                  setSupportOptions({
                                                    ...supportOptions,
                                                    land: e.target.checked,
                                                  })
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexCheckDefault1"
                                              >
                                                Access to Land
                                              </label>
                                            </div>

                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="flexCheckDefault2"
                                                checked={
                                                  supportOptions.facilities
                                                }
                                                onChange={(e) =>
                                                  setSupportOptions({
                                                    ...supportOptions,
                                                    facilities:
                                                      e.target.checked,
                                                  })
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexCheckDefault2"
                                              >
                                                Access to Facilities and
                                                Equipment
                                              </label>
                                            </div>

                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="flexCheckDefault3"
                                                checked={
                                                  supportOptions.mentorship
                                                }
                                                onChange={(e) =>
                                                  setSupportOptions({
                                                    ...supportOptions,
                                                    mentorship:
                                                      e.target.checked,
                                                  })
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexCheckDefault3"
                                              >
                                                Access to Mentorship
                                              </label>
                                            </div>

                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="flexCheckDefault4"
                                                checked={supportOptions.markets}
                                                onChange={(e) =>
                                                  setSupportOptions({
                                                    ...supportOptions,
                                                    markets: e.target.checked,
                                                  })
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexCheckDefault4"
                                              >
                                                Access to Markets
                                              </label>
                                            </div>

                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="flexCheckDefault5"
                                                checked={
                                                  supportOptions.otherOption
                                                }
                                                onChange={(e) =>
                                                  setSupportOptions({
                                                    ...supportOptions,
                                                    otherOption:
                                                      e.target.checked,
                                                  })
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexCheckDefault5"
                                              >
                                                Other:
                                              </label>
                                            </div>
                                            {supportOptions.otherOption && (
                                              <div className="col-md-9">
                                                <div className="mb-3 mt-2">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckDefault5"
                                                  >
                                                    If Other (Please specify):
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    value={supportOptions.other}
                                                    onChange={(e) =>
                                                      setSupportOptions({
                                                        ...supportOptions,
                                                        other: e.target.value,
                                                      })
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      {/* Question 6 */}
                                      <div className="col-md-9">
                                        <div className="mb-3">
                                          <label>
                                            Do you have any assets to provide in
                                            support of your agribusiness (e.g
                                            Land, Equipment)?
                                          </label>
                                          <select
                                            onChange={(e) =>
                                              setAssetSupport(e.target.value)
                                            }
                                            className="select2 form-select"
                                            required
                                            placeholder="Are you an NCARES Beneficiary?"
                                            value={assetSupport}
                                          >
                                            <option>Select an option</option>
                                            <option value={'Yes'}>Yes</option>
                                            <option value={'No'}>No</option>
                                          </select>
                                        </div>
                                      </div>

                                      {assetSupport === 'Yes' && (
                                        <div className="col-md-9">
                                          <div className="mb-3">
                                            <label>
                                              If YES, explain in detail your
                                              type of asset(s)
                                            </label>
                                            <textarea
                                              value={assetSupportType}
                                              className="form-control"
                                              onChange={(e) =>
                                                setTypeAssetSupportType(
                                                  e.target.value,
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}

                                      {/* Question 7 */}
                                      <div className="col-md-9">
                                        <div className="mb-3">
                                          <label>
                                            Any other comments or additional
                                            information you would like to
                                            provide:
                                          </label>
                                          <textarea
                                            value={comments}
                                            className="form-control"
                                            onChange={(e) =>
                                              setComments(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>

                                      {/* Declaration */}
                                      <div className="mt-3 mb-3">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexCheckDefault6"
                                            checked={isDeclarationChecked}
                                            onChange={() =>
                                              setIsDeclarationChecked(
                                                !isDeclarationChecked,
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexCheckDefault6"
                                          >
                                            I hereby confirm the accuracy of my
                                            responses and agree that providing
                                            false information may lead to
                                            disqualification.
                                          </label>
                                        </div>
                                      </div>

                                      {/* Submit Button */}
                                      <div className="text-center mt-4">
                                        <button
                                          className="btn btn-success "
                                          type="submit"
                                        >
                                          Sumbit for Review
                                        </button>
                                      </div>

                                      <div className="mt-3">
                                        <button
                                          className="btn btn-success"
                                          onClick={handleBack}
                                        >
                                          Previous
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </form>
                              ) : (
                                <div className="text-center">
                                  <img
                                    src="assets/images/download.png"
                                    alt=""
                                    className="mb-4"
                                    style={{ width: '10%' }}
                                  />
                                  <h4
                                    className="card-title"
                                    style={{ color: '#333' }}
                                  >
                                    Form Closed
                                  </h4>
                                  <p
                                    style={{
                                      color: '#e74c3c',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    The form is now closed.
                                  </p>
                                  <p>We will contact successful applicants shortly.</p>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <ToastContainer />
                      {alert}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
