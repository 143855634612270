import React from 'react'

function Footer() {
  const date = new Date().getFullYear()
  return (
    <div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              {date} © Farmsured
              <sup>
                <small>TM</small>
              </sup>
            </div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                A Product of AgDyna
                <sup>
                  <i className="fal fa-registered font-size-12"></i>
                </sup>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
