import {
  SEND_OTP_FAIL,
  SEND_OTP_REQUEST,
  SEND_OTP_RESET,
  SEND_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_RESET,
  VERIFY_OTP_SUCCESS,
} from '../../constants/authConstants/otpConstants'

export function userSendOtpReducer(state = {}, action) {
  switch (action.type) {
    case SEND_OTP_REQUEST:
      return { loading: true }
    case SEND_OTP_SUCCESS:
      return { loading: false, result: action.payload }
    case SEND_OTP_FAIL:
      return { loading: false, error: action.payload }
    case SEND_OTP_RESET:
      return {}
    default:
      return state
  }
}

export function userVerifyOtpReducer(state = {}, action) {
  switch (action.type) {
    case VERIFY_OTP_REQUEST:
      return { loading: true }
    case VERIFY_OTP_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case VERIFY_OTP_FAIL:
      return { loading: false, error: action.payload }
    case VERIFY_OTP_RESET:
      return {}
    default:
      return state
  }
}
