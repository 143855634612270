import React, { useState, useEffect } from 'react'

function AnalysisTyping({ text }) {
  const [typedText, setTypedText] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (currentIndex < text.length) {
      setTimeout(() => {
        setTypedText((prevTypedText) => prevTypedText + text[currentIndex])
        setCurrentIndex((prevIndex) => prevIndex + 1)
      }, 15) // Adjust the typing speed as needed
    }
  }, [currentIndex])

  return (
    <div>
      <pre
        className="card-title"
        style={{
          maxWidth: '100%',
          textWrap: 'balance',
          fontFamily: 'Nunito, sans-serif',
        }}
      >
        {typedText}
      </pre>
    </div>
  )
}

export default AnalysisTyping
