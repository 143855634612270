import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listFarmers } from '../../../actions/adminActions/farmersActions/listFarmers'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import { formatNumber } from '../../../components/functions'
import { MDBDataTableV5 } from 'mdbreact'
import { deleteFarmer } from '../../../actions/adminActions/farmersActions/deleteFarmer'
import SweetAlert from 'react-bootstrap-sweetalert'

export default function FarmersPage() {
  const [datatable, setDatatable] = useState({})
  const [alert, setAlert] = useState(null)

  const farmersList = useSelector((state) => state.farmersList)
  const { loading, error, farmers } = farmersList

  const farmerDelete = useSelector((state) => state.farmerDelete)

  const { loading: deleteLoading, error: deleteErr, success } = farmerDelete

  const dispatch = useDispatch()

  useEffect(() => {
    if (success) {
      setAlert(null)
      window.location.reload()
    }
    dispatch(listFarmers())
  }, [dispatch, success])

  const deleteHandle = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deleteFarmer(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  useEffect(() => {
    if (farmers) {
      setDatatable({
        columns: [
          {
            label: 'Unique ID',
            field: 'phone',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'phone',
            },
          },
          {
            label: 'Name',
            field: 'name',
            width: 200,
          },
          {
            label: 'Earned Points',
            field: 'points',
            width: 400,
          },
          {
            label: 'Location',
            field: 'location',
            width: 400,
          },

          {
            label: 'Date Joined',
            field: 'date',
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: farmers.map((item) => ({
          phone: item.phone ? `0${item.phone}` : '',
          name: `${item.firstName ? item.firstName : ''} ${
            item.lastName ? item.lastName : ''
          } `,
          points: formatNumber(item.points),
          location: item.state,
          date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
          action: (
            <div>
              <a
                href={`/farmer/detail/${item._id}`}
                style={{ marginRight: '15px' }}
                className="btn btn-sm btn-primary"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Manage"
              >
                Manage
              </a>
              <button
                className="btn btn-sm m-0 p-0 text-danger"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Delete"
                onClick={() => deleteHandle(item)}
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </button>
            </div>
          ),
        })),
      })
    }
  }, [farmers])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Farmers</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmsured</a>
                      </li>
                      <li className="breadcrumb-item active">Farmers</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}
            {deleteLoading ? <LoadingBox /> : null}
            {deleteErr ? (
              <MessageBox variant="danger">{deleteErr}</MessageBox>
            ) : null}

            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive-xl">
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 20, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {alert}
      </div>

      <Footer />
    </div>
  )
}
