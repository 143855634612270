import { FUND_ALLOCATION_TRANSACTION_FAIL, FUND_ALLOCATION_TRANSACTION_REQUEST, FUND_ALLOCATION_TRANSACTION_SUCCESS, FUND_USER_FAIL, FUND_USER_REQUEST, FUND_USER_RESET, FUND_USER_SUCCESS, MAIN_WALLET_BALANCE_FAIL, MAIN_WALLET_BALANCE_REQUEST, MAIN_WALLET_BALANCE_SUCCESS } from "../../../constants/adminConstants/farmtrove/fundUserConstants"

export const fundAllocationReducer = (state = {}, action) => {
    switch (action.type) {
        case FUND_USER_REQUEST:
            return { loading: true }
        case FUND_USER_SUCCESS:
            return { loading: false, sent: action.payload }
        case FUND_USER_FAIL:
            return { loading: false, error: action.payload }
        case FUND_USER_RESET:
            return {}
        default:
            return state
    }
}

export const fundAllocationHistoryReducer = (state = {}, action) => {
    switch (action.type) {
        case FUND_ALLOCATION_TRANSACTION_REQUEST:
            return { loading: true }
        case FUND_ALLOCATION_TRANSACTION_SUCCESS:
            return { loading: false, transactions: action.payload }
        case FUND_ALLOCATION_TRANSACTION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const mainWalletBalanceReducer = (state = {}, action) => {
    switch (action.type) {
        case MAIN_WALLET_BALANCE_REQUEST:
            return { loading: true }
        case MAIN_WALLET_BALANCE_SUCCESS:
            return { loading: false, wallet: action.payload }
        case MAIN_WALLET_BALANCE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}