import axios from 'axios'
import {
  SERVICE_PROVIDER_DELETE_FAIL,
  SERVICE_PROVIDER_DELETE_REQUEST,
  SERVICE_PROVIDER_DELETE_SUCCESS,
} from '../../../constants/adminConstants/serviceConstants'

export const deleteService = (serviceId) => async (dispatch, getState) => {
  dispatch({ type: SERVICE_PROVIDER_DELETE_REQUEST, payload: serviceId })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.post(
      `/api/admin/service/delete`,
      { serviceId },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      },
    )
    dispatch({ type: SERVICE_PROVIDER_DELETE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: SERVICE_PROVIDER_DELETE_FAIL, payload: message })
  }
}
