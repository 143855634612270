import axios from 'axios'
import {
  LOAN_CREATE_FAIL,
  LOAN_CREATE_REQUEST,
  LOAN_CREATE_SUCCESS,
} from '../../../constants/adminConstants/farmtrove/loanConstants'

export const createLoan = (
  name,
  manufacturer,
  maxAmount,
  moratoriumPeriod,
  duration,
  interest,
  description,
  TandC,
) => async (dispatch, getState) => {
  dispatch({
    type: LOAN_CREATE_REQUEST,
    payload: {
      name,
      manufacturer,
      maxAmount,
      moratoriumPeriod,
      duration,
      interest,
      description,
      TandC,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/farmtrove/add-new-loan',
      {
        name,
        manufacturer,
        maxAmount,
        moratoriumPeriod,
        duration,
        interest,
        description,
        TandC,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: LOAN_CREATE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: LOAN_CREATE_FAIL,
      payload: message,
    })
  }
}
