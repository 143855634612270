import {
  ENTERPRISE_LIST_FAIL,
  ENTERPRISE_LIST_REQUEST,
  ENTERPRISE_LIST_SUCCESS,
  ENTERPRISE_CREATE_FAIL,
  ENTERPRISE_CREATE_REQUEST,
  ENTERPRISE_CREATE_RESET,
  ENTERPRISE_CREATE_SUCCESS,
  UPDATE_ENTERPRISE_REQUEST,
  UPDATE_ENTERPRISE_SUCCESS,
  UPDATE_ENTERPRISE_FAIL,
  UPDATE_ENTERPRISE_RESET,
} from '../../../constants/adminConstants/enterpriseConstant'

export const allEnterpriseReducer = (state = { enterprises: [] }, action) => {
  switch (action.type) {
    case ENTERPRISE_LIST_REQUEST:
      return { loading: true }
    case ENTERPRISE_LIST_SUCCESS:
      return { loading: false, enterprises: action.payload }
    case ENTERPRISE_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const enterpriseCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ENTERPRISE_CREATE_REQUEST:
      return { loading: true }
    case ENTERPRISE_CREATE_SUCCESS:
      return { loading: false, newEnterprise: action.payload }
    case ENTERPRISE_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case ENTERPRISE_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const enterpriseUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ENTERPRISE_REQUEST:
      return { loading: true }
    case UPDATE_ENTERPRISE_SUCCESS:
      return { loading: false, updatedEnterprise: action.payload }
    case UPDATE_ENTERPRISE_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_ENTERPRISE_RESET:
      return {}
    default:
      return state
  }
}
