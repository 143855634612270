import axios from 'axios'
import {
  FARMER_ACTIVITIES_REQUEST,
  FARMER_ACTIVITIES_SUCCESS,
  FARMER_ACTIVITIES_FAIL,
} from '../../../constants/farmerConstants/activitiesConstants'

export const farmerActivities = () => async (dispatch, getState) => {
  dispatch({
    type: FARMER_ACTIVITIES_REQUEST,
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/farmer/my-activities', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: FARMER_ACTIVITIES_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: FARMER_ACTIVITIES_FAIL, payload: message })
  }
}
