import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { farmtroveDashboard } from '../../../actions/adminActions/farmtroveActions/farmtroveDashboard'
import { Doughnut } from 'react-chartjs-2'
import 'chart.js/auto'
import {
  calculateInstantVsEscrow,
  calculateMonthToMonthDifference,
  calculateYearTotal,
  checkNumberofActiveLoans,
  countTodayTransactions,
  formatNumber,
  getPreviousMonthTotal,
  getThisMonthTotal,
  getTotalTransferVolume,
  getTotalVolumeOfTodayTransactions,
  getUserMonthlyTransactions,
  groupTransactionsByMonth,
} from '../../../components/functions'
import Chart from 'react-apexcharts'
import { MDBDataTableV5 } from 'mdbreact'
import moment from 'moment'
import { TailSpin } from 'react-loading-icons'
import LoadingBox from '../../../components/LoadingBox'
import MessageBox from '../../../components/MessageBox'

export default function FarmtroveOverviewPage() {
  const [monthlyData, setMonthlyData] = useState([]);
  const [transactionPercentageData, setTransactionPercentageData] = useState([]);
  const [datatable, setDatatable] = useState({})
  const [datatable2, setDatatable2] = useState({})
  const [topUser, setTopUser] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(moment().format('M'));

  const farmtroveOverview = useSelector((state) => state.farmtroveOverview)
  const { loading, details, error } = farmtroveOverview

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(farmtroveDashboard())
  }, [])

  useEffect(() => {
    // Group transactions by month and set it in the state
    if (details) {
      const groupedData = groupTransactionsByMonth(details.transactions);
      setMonthlyData(groupedData);

      if (selectedMonth) {
        // Find the top user for the selected month (August by default)
        const monthlyTransactions = getUserMonthlyTransactions(details.transactions, selectedMonth);
        setTopUser(monthlyTransactions);
      }

      const transactionPercentage = calculateInstantVsEscrow(details.transactions, details.escrow)
      setTransactionPercentageData([transactionPercentage.totalInstant, transactionPercentage.totalEscrow])
    }
  }, [details, selectedMonth]);


  const options = {
    chart: {
      id: "transaction-line-chart",
      type: "area",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors: ["#008FFB", "#FF4560"],
    fill: {
      type: "gradient",
      opacity: 0.8,
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#008FFB", "#FF4560"],
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0.1,
        stops: [0, 100],
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      categories: [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ],
      color: 'black'
    },
    yaxis: {
      title: {
        text: "Transaction Amount",
        color: 'black'
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    dataLabels: {
      enabled: false, // Disable data labels
    },
  };
  const series = [
    {
      name: "Transaction Volume",
      data: monthlyData, // Data for each month
    },
  ];

  // DataTable for transactions
  useEffect(() => {
    if (details) {
      setDatatable({
        columns: [
          {
            label: 'Transaction ID',
            field: 'transactionID',
          },
          {
            label: 'Date',
            field: 'date',
            width: 200,
          },
          {
            label: 'User ID',
            field: 'user',
            width: 100,
          },
          {
            label: 'Amount',
            field: 'amount',
            width: 100,
          },
          {
            label: 'Status',
            field: 'status',
            width: 100,
          },
        ],
        rows: details.transactions.map((item) => ({
          transactionID: item.reference,
          user: item.user && item.user.phone,
          amount:
            item.amount
              ? `₦ ${formatNumber(item.amount)}`
              : "₦ 0",
          date: moment(item.timestamp).format('DD MMM YYYY,  h:mm a'),
          status: (
            <div
              className={`badge badge-soft-${item.status === 'pending'
                ? 'warning'
                : item.status === 'approved'
                  ? 'secondary'
                  : item.status === 'active'
                    ? 'primary'
                    : item.status === 'completed'
                      ? 'success'
                      : item.status === 'reject'
                        ? 'danger'
                        : 'primary'

                } font-size-13`}
            >
              {item.status}
            </div>
          ),
        })),
      })
    }
  }, [details])


  // DataTable for Escrow Payments
  useEffect(() => {
    if (details) {
      setDatatable2({
        columns: [
          {
            label: 'Transaction ID',
            field: 'transactionID',
          },
          {
            label: 'Date',
            field: 'date',
            width: 200,
          },
          {
            label: 'Sender',
            field: 'sender',
            width: 100,
          },
          {
            label: 'Receiver',
            field: 'receiver',
            width: 100,
          },
          {
            label: 'Amount',
            field: 'amount',
            width: 100,
          },
          {
            label: 'Status',
            field: 'status',
            width: 100,
          },
        ],
        rows: details.escrow.map((item) => ({
          transactionID: item.transactionId,
          sender: item.sender && item.sender.phone,
          receiver: item.receiver && item.receiver.phone,
          amount:
            item.amount
              ? `₦ ${formatNumber(item.amount)}`
              : "₦ 0",
          date: moment(item.timestamp).format('DD MMM, YYYY,  h:mm a'),
          status: (
            <div
              className={`badge badge-soft-${item.status === 'pending'
                ? 'warning'
                : item.status === 'approved'
                  ? 'secondary'
                  : item.status === 'active'
                    ? 'primary'
                    : item.status === 'completed'
                      ? 'success'
                      : item.status === 'reject'
                        ? 'danger'
                        : 'primary'

                } font-size-13`}
            >
              {item.status}
            </div>
          ),
        })),
      })
    }
  }, [details])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Overview</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farmtrove</a>
                      </li>
                      <li className="breadcrumb-item active">Overview</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {
              loading ? <LoadingBox /> :
                error ? <MessageBox variant='danger'>{error}</MessageBox>
                  : <>
                    <div className="row">
                      <div className="col-xl-8">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="card">
                              <div className="card-body">
                                <div className="d-flex">
                                  <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">All Time Transfer</p>
                                    <h4 className="mb-0">{details && details.transactions ? details.transactions.length : 0}</h4>
                                  </div>
                                  <div className="text-primary ms-auto">
                                    <i className="fal fa-credit-card font-size-24"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="card">
                              <div className="card-body">
                                <div className="d-flex">
                                  <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">All Time Transfer Volume</p>
                                    <h4 className="mb-0">₦ {details && details.transactions ? formatNumber(getTotalTransferVolume(details.transactions).toFixed(0)) : 0}</h4>
                                  </div>
                                  <div className="text-primary ms-auto">
                                    <i className="fal fa-exchange-alt font-size-24"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="card">
                              <div className="card-body">
                                <div className="d-flex">
                                  <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Today's Transfer Volume</p>
                                    <h4 className="mb-0">₦ {details && details.transactions ? formatNumber(getTotalVolumeOfTodayTransactions(details.transactions)) : 0}</h4>
                                  </div>
                                  <div className="text-primary ms-auto">
                                    <i className="fal fa-money-bill-wave-alt font-size-24"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="card">
                              <div className="card-body">
                                <div className="d-flex">
                                  <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Today's Transactions</p>
                                    <h4 className="mb-0">{details && details.transactions ? countTodayTransactions(details.transactions) : 0}</h4>
                                  </div>
                                  <div className="text-primary ms-auto">
                                    <i className="ri-stack-line font-size-24"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="card">
                              <div className="card-body">
                                <div className="d-flex">
                                  <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Number of Active Loan</p>
                                    <h4 className="mb-0">{details && details.loans ? checkNumberofActiveLoans(details.loans) : 0}</h4>
                                  </div>
                                  <div className="text-primary ms-auto">
                                    <i className="ri-stack-line font-size-24"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="card">
                              <div className="card-body">
                                <div className="d-flex">
                                  <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Loan Application</p>
                                    <h4 className="mb-0">{details && details.loans ? details.loans.length : 0}</h4>
                                  </div>
                                  <div className="text-primary ms-auto">
                                    <i className="fal fa-money-check font-size-24"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body">
                            <h4 className="card-title mb-4">Transaction Volume by Month</h4>
                            {
                              loading ?
                                <div
                                  className="tailspin"
                                  style={{ height: 'calc(50vh - 50px)' }}
                                >
                                  <TailSpin
                                    stroke="#98ff98"
                                    strokeWidth="3"
                                    color="#06bcee"
                                  />
                                </div>
                                :
                                <div style={{ position: "relative" }}>
                                  <Chart options={options} series={series} type="area" width={"100%"} height={500} />
                                </div>
                            }
                          </div>

                          <div className="card-body border-top text-center">
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="d-inline-flex">
                                  <h5 className="me-2">₦ {loading ? "Loading" : details && details.transactions ? formatNumber(calculateYearTotal(details.transactions)) : 0} </h5>
                                  {/* <div className="text-success">
                            <i className="mdi mdi-menu-up font-size-14"> </i>2.2 %
                          </div> */}
                                </div>
                                <p className="text-muted text-truncate mb-0">This Year</p>
                              </div>

                              <div className="col-sm-4">
                                <div className="mt-4 mt-sm-0">
                                  <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> This Month :</p>
                                  <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">₦ {loading ? "Loading" : details && details.transactions ? formatNumber(getThisMonthTotal(details.transactions)) : 0}</h5>
                                    <div className="text-success">
                                      <i className="mdi mdi-menu-up font-size-14"> </i>{loading ? 'loading' : details && details.transactions ? calculateMonthToMonthDifference(details.transactions) : 0}%
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="mt-4 mt-sm-0">
                                  <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i> Previous Month :</p>
                                  <div className="d-inline-flex">
                                    <h5 className="mb-0">₦ {loading ? "Loading" : details && details.transactions ? formatNumber(getPreviousMonthTotal(details.transactions)) : 0}</h5>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="card">
                          <div className="card-body" style={{ position: "relative" }}>
                            <h4 className="card-title mb-4">Transactions Analytics</h4>
                            {
                              loading ?
                                <div
                                  className="tailspin"
                                  style={{ height: 'calc(50vh - 50px)' }}
                                >
                                  <TailSpin
                                    stroke="#98ff98"
                                    strokeWidth="3"
                                    color="#06bcee"
                                  />
                                </div>
                                :
                                <>
                                  <div id="donut-chart" style={{ minHeight: "228.7px" }}>
                                    <div id="apexchartsq98thaef" style={{}}>
                                      <Doughnut
                                        width={400}
                                        height={400}
                                        options={{ maintainAspectRatio: false }}
                                        data={{
                                          labels: ['Instant', 'Escrow'],
                                          datasets: [
                                            {
                                              label: '# of claims',
                                              data: transactionPercentageData,
                                              backgroundColor: [
                                                '#1CBB8C',
                                                '#fcb92c',
                                              ],
                                              hoverOffset: 10
                                            },
                                          ],

                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-6">
                                      <div className="text-center mt-4">
                                        <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i> Instant Transfer</p>
                                        <h5>{details && details.transactions && details.escrow ? calculateInstantVsEscrow(details.transactions, details.escrow).percentageInstantToday : 0} %</h5>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="text-center mt-4">
                                        <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-warning font-size-10 me-1"></i> Escrow Transfer</p>
                                        <h5>{details && details.transactions && details.escrow ? calculateInstantVsEscrow(details.transactions, details.escrow).percentageEscrowToday : 0} %</h5>
                                      </div>
                                    </div>
                                  </div>
                                </>
                            }

                          </div>
                        </div>

                        <div className="card">

                          <div className="card-body">
                            <div className="float-end">
                              <select
                                className="form-select form-select-sm"
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                              >
                                <option value={1}>Jan</option>
                                <option value={2}>Feb</option>
                                <option value={3}>Mar</option>
                                <option value={4}>Apr</option>
                                <option value={5}>May</option>
                                <option value={6}>Jun</option>
                                <option value={7}>Jul</option>
                                <option value={8}>Aug</option>
                                <option value={9}>Sept</option>
                                <option value={10}>Oct</option>
                                <option value={11}>Nov</option>
                                <option value={12}>Dec</option>
                              </select>
                            </div>

                            <h4 className="card-title mb-4">Top User by Transfers</h4>

                            {
                              loading ?
                                <div
                                  className="tailspin"
                                  style={{ height: 'calc(50vh - 50px)' }}
                                >
                                  <TailSpin
                                    stroke="#98ff98"
                                    strokeWidth="3"
                                    color="#06bcee"
                                  />
                                </div>
                                :
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <thead className="table-light">
                                      <tr>
                                        <th>#</th>
                                        <th>User ID</th>
                                        <th>Total Amount</th>
                                        <th>Transactions</th>
                                      </tr>
                                    </thead>
                                    {topUser ?
                                      topUser.slice(0, 6).map((itm, index) => (
                                        <tbody>
                                          <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>{itm && itm.user && itm.user.phone}</td>
                                            <td>₦ {itm && itm.totalAmount ? `${formatNumber(itm.totalAmount)}` : 0}</td>
                                            <td>{itm && itm.transactionCount}</td>
                                          </tr>
                                        </tbody>
                                      ))
                                      : null
                                    }
                                  </table>
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="card">
                          <div className="card-body">
                            <h4 className="card-title mb-4">Latest Transactions</h4>

                            {
                              loading
                                ?
                                <div
                                  className="tailspin"
                                  style={{ height: 'calc(50vh - 50px)' }}
                                >
                                  <TailSpin
                                    stroke="#98ff98"
                                    strokeWidth="3"
                                    color="#06bcee"
                                  />
                                </div>
                                :
                                <MDBDataTableV5
                                  hover
                                  entriesOptions={[5, 10,]}
                                  entries={5}
                                  pagesAmount={4}
                                  data={datatable}
                                  searchTop
                                  searchBottom={false}
                                  barReverse
                                />
                            }

                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="card">
                          <div className="card-body">
                            <h4 className="card-title mb-4">Transactions in Escrow</h4>

                            {
                              loading
                                ?
                                <div
                                  className="tailspin"
                                  style={{ height: 'calc(50vh - 50px)' }}
                                >
                                  <TailSpin
                                    stroke="#98ff98"
                                    strokeWidth="3"
                                    color="#06bcee"
                                  />
                                </div>
                                :
                                <MDBDataTableV5
                                  hover
                                  entriesOptions={[5, 10,]}
                                  entries={5}
                                  pagesAmount={4}
                                  data={datatable2}
                                  searchTop
                                  searchBottom={false}
                                  barReverse
                                />
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </>
            }

          </div>
        </div>
      </div>
      <Footer />
    </div >
  )
}
