import axios from 'axios'
import {
  DELETE_FARMER_FAIL,
  DELETE_FARMER_REQUEST,
  DELETE_FARMER_SUCCESS,
} from '../../../constants/adminConstants/farmerConstants'

export const deleteFarmer = (itemId) => async (dispatch, getState) => {
  dispatch({ type: DELETE_FARMER_REQUEST, payload: { itemId } })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/delete-farmer',
      { itemId },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: DELETE_FARMER_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: DELETE_FARMER_FAIL, payload: message })
  }
}
