import axios from 'axios'
import {
  SAVE_DATE_REQUEST,
  SAVE_DATE_SUCCESS,
  SAVE_DATE_FAIL,
} from '../../../constants/adminConstants/applicationContants'

export const saveDate = (startDate, endDate) => async (dispatch, getState) => {
  dispatch({ type: SAVE_DATE_REQUEST, payload: { startDate, endDate } })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.post(
      '/api/admin/yeap-safer/application-period',
      { startDate, endDate },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: SAVE_DATE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: SAVE_DATE_FAIL, payload: message })
  }
}
