import React, { useEffect, useMemo, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import { useDispatch, useSelector } from 'react-redux'
import { getRequests } from '../../../actions/adminActions/tagActions/tagRequest'
import moment from 'moment'
import { formatNumber } from '../../../components/functions'
import { deleteTagOrder } from '../../../actions/adminActions/tagActions/deleteOrder'
import { MDBDataTableV5 } from 'mdbreact'
import SweetAlert from 'react-bootstrap-sweetalert'

function TagRequestsPage() {
  const [datatable, setDatatable] = useState({})
  const [alert, setAlert] = useState(null)

  const tagsRequests = useSelector((state) => state.tagsRequests)
  const { loading, error, requests } = tagsRequests

  const deleteOrder = useSelector((state) => state.deleteOrder)
  const { loading: deleteLoading, error: deleteError, success } = deleteOrder

  const dispatch = useDispatch()

  const deleteHandler = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deleteTagOrder(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  useEffect(() => {
    dispatch(getRequests())

    if (success) {
      setAlert(null)
    }
  }, [dispatch, success])

  useEffect(() => {
    if (requests) {
      setDatatable({
        columns: [
          {
            label: 'Order ID',
            field: 'orderID',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'orderID',
            },
          },
          {
            label: 'Date Requested',
            field: 'date',
            width: 200,
          },
          {
            label: 'Manufacturer Name',
            field: 'company',
            width: 200,
          },
          {
            label: 'Product',
            field: 'product',
            width: 100,
          },
          {
            label: 'No. of Packages',
            field: 'amount',
            width: 100,
          },
          {
            label: 'Assigned',
            field: 'assign',
            width: 100,
            sort: 'disabled',
          },
          {
            label: 'Status',
            field: 'status',
            width: 100,
            sort: 'disabled',
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: requests.map((item) => ({
          orderID: item.orderID,
          date: moment(item.created_at).format('Do MMMM YYYY,  h:mm a'),
          company: item.manufacturer && item.manufacturer.name,
          product: item.product && item.product.name,
          amount: item.amount ? formatNumber(item.amount) : '',
          assign: item.batch ? (
            item.batch && item.batch.length > 0 ? (
              <div className={`badge badge-soft-success font-size-12`}>
                Assigned
              </div>
            ) : (
              <div className={`badge badge-soft-open font-size-12`}>Open</div>
            )
          ) : (
            ''
          ),
          status: (
            <div className={`badge badge-soft-${item.status} font-size-11`}>
              {item.status}
            </div>
          ),
          action: (
            <div>
              <a
                href={`/request/${item._id}`}
                className="btn btn-sm btn-primary"
                style={{ marginTop: '0px', marginRight: '15px' }}
                data-bs-container="#tooltip-container1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit"
              >
                Edit
              </a>
              <a
                href="javascript:void(0);"
                className="text-danger"
                data-bs-container="#tooltip-container1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Delete"
                onClick={() => deleteHandler(item)}
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </a>
            </div>
          ),
        })),
      })
    }
  }, [requests])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Tag Requests</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Tags</a>
                      </li>
                      <li className="breadcrumb-item active">Tag Requests</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {deleteLoading ? <LoadingBox /> : null}
            {deleteError && (
              <MessageBox variant="danger">{deleteError}</MessageBox>
            )}
            {error && <MessageBox variant="danger">{error}</MessageBox>}
            <div className="card">
              <div className="card-body">
                {loading ? (
                  <LoadingBox />
                ) : (
                  <div className="table-responsive-xl">
                    <MDBDataTableV5
                      hover
                      entriesOptions={[5, 20, 25, 50]}
                      entries={10}
                      pagesAmount={4}
                      data={datatable}
                      searchTop
                      searchBottom={false}
                      barReverse
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {alert}
      </div>
      <Footer />
    </div>
  )
}

export default TagRequestsPage
