import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import register from '../../actions/authActions/register'
import { LoadingBox, MessageBox } from '../../components'
import { USER_REGISTER_RESET } from '../../constants/authConstants/RegisterContants'
import Select from 'react-select'
import { allCluster } from '../../actions/authActions/farmclusterAction/allClusters'
import { allClusterCategory } from '../../actions/authActions/farmclusterAction/allClusterCategory'
import states from '../../utilis'
import NaijaStates from 'naija-state-local-government'

function RegisterPage(props) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [clusterGroup, setClusterGroup] = useState('')
  const [clusters, setClusters] = useState([])
  const [userRole, setUserRole] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [personName, setContactPersonName] = useState('')
  const [address, setAddress] = useState('')
  const [lga, setLga] = useState('')
  const [state, setState] = useState('')
  const [uniqueCode, setUniqueCode] = useState('')
  const [name, setName] = useState('')

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, userInfo, error } = userRegister

  const clusterList = useSelector((state) => state.clusterList)
  const { loading: loadingClusters, clusters: clusterArray } = clusterList

  const clusterCategory = useSelector((state) => state.clusterCategory)
  const { loading: loadingCategory, categories } = clusterCategory

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(allCluster())
    dispatch(allClusterCategory())
  }, [])


  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(register({ firstName, lastName, email, phone, password, clusters, userRole, companyName, personName, address, state, lga, uniqueCode, name }))
  }

  useEffect(() => {
    if (userInfo) {
      setTimeout(() => {
        props.history.push('/')
        dispatch({ type: USER_REGISTER_RESET })
      }, 5000)
    }
    if (error) {
      setTimeout(() => {
        dispatch({ type: USER_REGISTER_RESET })
      }, 5000)
    }
  }, [userInfo, error, props.history, dispatch])

  useEffect(() => {
    if (userRole === 'service') {
      setUniqueCode(`FBSP-${Math.floor(Math.random() * 9000) + 1000}`)

    }
  }, [userRole])


  const date = new Date().getFullYear()
  return (
    <div>
      <div className="container-fluid p-0">
        {loading ? (
          <LoadingBox />
        ) : (
          <div className="row g-0">
            <div className="col-lg-6">
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <div className="row justify-content-center">
                    <div className="col-lg-9">
                      <div>
                        {error && <MessageBox variant="danger">{error}</MessageBox>}
                        {userInfo && (
                          <MessageBox variant="success">
                            {userInfo.message}
                          </MessageBox>
                        )}
                        <div className="text-center">
                          <div>
                            <a href="/" className="">
                              <img
                                src="assets/images/farmsured!.png"
                                alt=""
                                height="50"
                                className="auth-logo logo-dark mx-auto"
                              />
                              <img
                                src="assets/images/farmsured!.png"
                                alt=""
                                height="50"
                                className="auth-logo logo-light mx-auto"
                              />
                            </a>
                          </div>

                          <h4 className="font-size-18 mt-4">
                            Register account
                          </h4>
                          <p className="text-muted">
                            Get unboarded on the Farmsured account now.
                          </p>
                        </div>

                        <div className="p-2 mt-2">
                          <form className="" onSubmit={submitHandler}>
                            <div className="mb-3">
                              <label className="form-label" forHtml="userrole">
                                Select User Role
                              </label>
                              <select
                                onChange={(e) => setUserRole(e.target.value)}
                                className="select2 form-select"
                                required
                              >
                                <option>Select role</option>
                                <option value={'farmer'}>Farmer</option>
                                <option value={'manufacturer'}>Agro-Input Manufacturer</option>
                                <option value={'service'}>Service Provider</option>
                              </select>
                            </div>


                            {/* ==================================
                              ============== Farmer ==============
                              ===================================*/}
                            {
                              userRole === 'farmer'
                              &&
                              <div>
                                <div className="mb-3">
                                  <label className="form-label" forHtml="userrole">
                                    Cluster Group
                                  </label>
                                  <select
                                    onChange={(e) => setClusterGroup(e.target.value)}
                                    className="select2 form-select"
                                    required
                                  >
                                    <option>Select cluster group</option>

                                    {loadingCategory ? <option></option> :
                                      categories && categories.map(item => (
                                        <option value={item._id}>{item.name}</option>
                                      ))
                                    }
                                  </select>
                                </div>

                                <div className="mb-3">
                                  {/* <i className="ri-phone-line auti-custom-input-icon"></i> */}
                                  <label htmlFor="name">Select your Cluster(s)</label>
                                  <Select
                                    onChange={(opt) => setClusters(opt)}
                                    required
                                    isMulti
                                    components={{ LoadingIndicator: loadingClusters ? true : false }}
                                    options={loadingClusters ? [] :
                                      clusters.length === 5 ? []
                                        : clusterArray.length === 0 ? []
                                          : clusterArray.map((opt) => ({
                                            label: opt.name,
                                            value: opt._id,
                                          }))}
                                    noOptionsMessage={() => {
                                      return clusters.length === 5 ? "You've reached the max options value" : 'No options available'
                                    }}
                                    defaultValue={clusters.map((opt) => ({
                                      label: opt.name,
                                      value: opt._id,
                                    }))}
                                  />
                                </div>

                                <div className="row">
                                  <div className="col-6">
                                    <div className="mb-3">
                                      {/* <i className="ri-user-2-line auti-custom-input-icon"></i> */}
                                      <label htmlFor="name">First Name</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        placeholder="Enter first name"
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className=" mb-3">
                                      {/* <i className="ri-user-2-line auti-custom-input-icon"></i> */}
                                      <label htmlFor="name">Last Name</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        placeholder="Enter last name"
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className=" mb-3">
                                      {/* <i className="ri-phone-line auti-custom-input-icon"></i> */}
                                      <label htmlFor="name">Phone</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="phone"
                                        placeholder="Enter phone"
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className=" mb-3">
                                      {/* <i className="ri-mail-line auti-custom-input-icon"></i> */}
                                      <label htmlFor="useremail">Email</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="useremail"
                                        placeholder="Enter email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>


                              </div>
                            }

                            {/* ==================================
                              ============ Manufacturer ==========
                              ===================================*/}
                            {
                              userRole === 'manufacturer' &&
                              <div className="">
                                <div className="row">
                                  <div className="col-6">
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        forHtml="companyName"
                                      >
                                        Company Name
                                      </label>
                                      <input
                                        id="companyName"
                                        name="companyName"
                                        type="text"
                                        className="form-control"
                                        required
                                        placeholder='Company Name'
                                        onChange={(e) =>
                                          setCompanyName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        forHtml="contactPersonName"
                                      >
                                        Contact Person Name
                                      </label>
                                      <input
                                        id="contactPersonName"
                                        name="contactPersonName"
                                        type="text"
                                        className="form-control"
                                        placeholder='Contact Person Name'
                                        required
                                        onChange={(e) =>
                                          setContactPersonName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className=" mb-3">
                                      {/* <i className="ri-phone-line auti-custom-input-icon"></i> */}
                                      <label htmlFor="name">Phone</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="phone"
                                        placeholder="Enter phone"
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className=" mb-3">
                                      {/* <i className="ri-mail-line auti-custom-input-icon"></i> */}
                                      <label htmlFor="useremail">Email</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="useremail"
                                        placeholder="Enter email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <label className="form-label" forHtml="address">
                                      Address
                                    </label>
                                    <input
                                      id="address"
                                      name="address"
                                      type="text"
                                      className="form-control"
                                      placeholder='Address'
                                      required
                                      onChange={(e) => setAddress(e.target.value)}
                                    />
                                  </div>
                                  <div className="col-12 mt-3 mb-3">
                                    <div className="row">
                                      <div className="col-6">
                                        <label
                                          className="form-label"
                                          forHtml="address"
                                        >
                                          State
                                        </label>
                                        <select
                                          onChange={(e) => setState(e.target.value)}
                                          className="form-select form-control select2"
                                          required
                                        >
                                          <option>Select</option>
                                          {states.map((item) => (
                                            <option value={item}>{item}</option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className="col-6">
                                        <label className="form-label" forHtml="lga">
                                          Local Government Area
                                        </label>
                                        <select
                                          className="form-select form-control select2"
                                          required
                                          name="lga"
                                          placeholder="Select a local government area"
                                          onChange={(e) => setLga(e.target.value)}
                                        >
                                          {state && state === 'Select a state' ? (
                                            <option>Select a local government area</option>
                                          ) : state ? (
                                            <>
                                              <option>
                                                Select a local government area
                                              </option>
                                              {NaijaStates.lgas(state).lgas.map((item) => (
                                                <option value={item}>{item}</option>
                                              ))}
                                            </>
                                          ) : (
                                            <option>Select a local government area</option>
                                          )}
                                        </select>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                            {/* ==================================
                              ======== Service Provider ==========
                              ===================================*/}
                            {
                              userRole === 'service' &&
                              <div className="">
                                <div className="row">
                                  <div className="col-md-12 mb-3">
                                    <label
                                      htmlFor="example-text-input2"
                                      className="col-md-12 col-form-label"
                                    >
                                      Name
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="Name"
                                      id="example-text-input2"
                                      onChange={(e) => setName(e.target.value)}
                                      required
                                    />
                                  </div>
                                  <div className="col-6">
                                    <div className=" mb-3">
                                      {/* <i className="ri-phone-line auti-custom-input-icon"></i> */}
                                      <label htmlFor="name">Phone</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="phone"
                                        placeholder="Enter phone"
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className=" mb-3">
                                      {/* <i className="ri-mail-line auti-custom-input-icon"></i> */}
                                      <label htmlFor="useremail">Email</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="useremail"
                                        placeholder="Enter email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 mb-3">
                                  <div className="row">
                                    <div className="col-6">
                                      <label
                                        className="form-label"
                                        forHtml="address"
                                      >
                                        State
                                      </label>
                                      <select
                                        onChange={(e) => setState(e.target.value)}
                                        className="select2 form-select form-control"
                                        required
                                      >
                                        <option>Select</option>
                                        {states.map((item) => (
                                          <option value={item}>{item}</option>
                                        ))}
                                      </select>
                                    </div>
                                    <div className="col-6">
                                      <label className="form-label" forHtml="lga">
                                        Local Government Area
                                      </label>
                                      <select
                                        className="form-select form-control select2"
                                        required
                                        name="lga"
                                        placeholder="Select a local government area"
                                        onChange={(e) => setLga(e.target.value)}
                                      >
                                        {state && state === 'Select a state' ? (
                                          <option>Select a local government area</option>
                                        ) : state ? (
                                          <>
                                            <option>
                                              Select a local government area
                                            </option>
                                            {NaijaStates.lgas(state).lgas.map((item) => (
                                              <option value={item}>{item}</option>
                                            ))}
                                          </>
                                        ) : (
                                          <option>Select a local government area</option>
                                        )}
                                      </select>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            }

                            {
                              userRole === 'farmer' || userRole === 'service' || userRole === 'manufacturer' ?
                                <div className="mb-3">
                                  {/* <i className="ri-lock-2-line auti-custom-input-icon"></i> */}
                                  <label htmlFor="userpassword">Password</label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="userpassword"
                                    placeholder="Enter password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                  />
                                </div>
                                : null
                            }

                            <div className="text-center">
                              <button
                                className="btn btn-success w-md waves-effect waves-light"
                                type="submit"
                              >
                                Register
                              </button>
                            </div>

                            <div className="mt-3 text-center">
                              <p className="mb-0">
                                By registering you agree to the Farmsured{' '}
                                <a href="#" className="text-primary">
                                  Terms of Use
                                </a>
                              </p>
                            </div>
                          </form>
                        </div>

                        <div className="mt-4 text-center">
                          <p>
                            Already have an account?{' '}
                            <a href="/" className="fw-medium text-primary">
                              {' '}
                              Login
                            </a>{' '}
                          </p>
                          <p>
                            © {date} Farmsured
                            <sup>
                              <small>TM</small>
                            </sup>
                            {''} A product of AgDyna
                            <sup>
                              <i className="fal fa-registered font-size-12"></i>
                            </sup>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="authentication-bg position-relative">
                <div className="bg-overlay"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default RegisterPage
