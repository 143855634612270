import React from 'react'

function MessageBox(props) {
  return (
    <>
      <div
        className={`alert alert-${props.variant || 'info'} alert-dismissible font-size-14`}
        role="alert"
      >
        {props.children}
        {/* <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button> */}
      </div>
    </>
  )
}

export default MessageBox
