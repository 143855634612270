import axios from 'axios'
import {
  SEND_OTP_FAIL,
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
} from '../../constants/authConstants/otpConstants'

const sendOtp = (phone) => async (dispatch) => {
  dispatch({ type: SEND_OTP_REQUEST, payload: { phone } })
  try {
    const { data } = await axios.post('/api/send-otp', { phone })
    dispatch({ type: SEND_OTP_SUCCESS, payload: data })

  } catch (error) {
    dispatch({
      type: SEND_OTP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export default sendOtp
