import axios from 'axios'
import {
  VERIFY_ACCOUNT_FAIL,
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
} from '../../../constants/authConstants/walletConstants'

export const verifyAccountNumber = (account, bankCode) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: VERIFY_ACCOUNT_REQUEST,
    payload: { account, bankCode },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/wallet/verify/account',
      { account, bankCode },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: VERIFY_ACCOUNT_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: VERIFY_ACCOUNT_FAIL, payload: message })
  }
}
