import axios from 'axios'
import {
  WALLET_FUND_FAIL,
  WALLET_FUND_REQUEST,
  WALLET_FUND_SUCCESS,
} from '../../../constants/authConstants/walletConstants'

export const addFund = (amount) => async (dispatch, getState) => {
  dispatch({
    type: WALLET_FUND_REQUEST,
    payload: amount,
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/wallet/addfund',
      { amount },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: WALLET_FUND_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: WALLET_FUND_FAIL, payload: message })
  }
}
