import {
  REPORT_SUBMISSION_FAIL,
  REPORT_SUBMISSION_REQUEST,
  REPORT_SUBMISSION_RESET,
  REPORT_SUBMISSION_SUCCESS,
} from '../../constants/authConstants/reportConstants'

export const reportSubmissionReducer = (state = {}, action) => {
  switch (action.type) {
    case REPORT_SUBMISSION_REQUEST:
      return { loading: true }
    case REPORT_SUBMISSION_SUCCESS:
      return { loading: false, submittedReport: action.payload }
    case REPORT_SUBMISSION_FAIL:
      return { loading: false, error: action.payload }
    case REPORT_SUBMISSION_RESET:
      return {}
    default:
      return state
  }
}
