import { FUND_MAIN_WALLET_FAIL, FUND_MAIN_WALLET_REQUEST, FUND_MAIN_WALLET_RESET, FUND_MAIN_WALLET_SUCCESS } from '../../../constants/adminConstants/farmtrove/fundUserConstants'
import {
  USER_ACCOUNT_IDENTITY_FAIL,
  USER_ACCOUNT_IDENTITY_REQUEST,
  USER_ACCOUNT_IDENTITY_SUCCESS,
  USER_ACCOUNT_INCOME_FAIL,
  USER_ACCOUNT_INCOME_REQUEST,
  USER_ACCOUNT_INCOME_SUCCESS,
  USER_ACCOUNT_INFORMATION_FAIL,
  USER_ACCOUNT_INFORMATION_REQUEST,
  USER_ACCOUNT_INFORMATION_SUCCESS,
  USER_ACCOUNT_TRANSATIONS_FAIL,
  USER_ACCOUNT_TRANSATIONS_REQUEST,
  USER_ACCOUNT_TRANSATIONS_SUCCESS,
} from '../../../constants/adminConstants/farmtrove/userAccountConstants'
import {
  GET_WITHDRAWREQUEST_FAIL,
  GET_WITHDRAWREQUEST_REQUEST,
  GET_WITHDRAWREQUEST_SUCCESS,
  UPDATE_WITHDRAWREQUEST_FAIL,
  UPDATE_WITHDRAWREQUEST_REQUEST,
  UPDATE_WITHDRAWREQUEST_RESET,
  UPDATE_WITHDRAWREQUEST_SUCCESS,
} from '../../../constants/adminConstants/farmtrove/walletConstants'

export const userAccountInformationReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACCOUNT_INFORMATION_REQUEST:
      return { loading: true }
    case USER_ACCOUNT_INFORMATION_SUCCESS:
      return { loading: false, details: action.payload }
    case USER_ACCOUNT_INFORMATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userAccountTransactionsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACCOUNT_TRANSATIONS_REQUEST:
      return { loading: true }
    case USER_ACCOUNT_TRANSATIONS_SUCCESS:
      return { loading: false, history: action.payload }
    case USER_ACCOUNT_TRANSATIONS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userAccountIncomeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACCOUNT_INCOME_REQUEST:
      return { loading: true }
    case USER_ACCOUNT_INCOME_SUCCESS:
      return { loading: false, incomes: action.payload }
    case USER_ACCOUNT_INCOME_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userIdentityReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACCOUNT_IDENTITY_REQUEST:
      return { loading: true }
    case USER_ACCOUNT_IDENTITY_SUCCESS:
      return { loading: false, identity: action.payload }
    case USER_ACCOUNT_IDENTITY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const allWithdrawRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WITHDRAWREQUEST_REQUEST:
      return { loading: true }
    case GET_WITHDRAWREQUEST_SUCCESS:
      return { loading: false, requests: action.payload }
    case GET_WITHDRAWREQUEST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateWithdrawRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_WITHDRAWREQUEST_REQUEST:
      return { loading: true }
    case UPDATE_WITHDRAWREQUEST_SUCCESS:
      return { loading: false, updated: action.payload }
    case UPDATE_WITHDRAWREQUEST_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_WITHDRAWREQUEST_RESET:
      return {}
    default:
      return state
  }
}

export const fundAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case FUND_MAIN_WALLET_REQUEST:
      return { loading: true }
    case FUND_MAIN_WALLET_SUCCESS:
      return { loading: false, funded: action.payload }
    case FUND_MAIN_WALLET_FAIL:
      return { loading: false, error: action.payload }
    case FUND_MAIN_WALLET_RESET:
      return {}
    default:
      return state
  }
}
