import {
  ALL_PACKAGES_FAIL,
  ALL_PACKAGES_REQUEST,
  ALL_PACKAGES_SUCCESS,
  CREATE_PACKAGE_CATEGORY_FAIL,
  CREATE_PACKAGE_CATEGORY_REQUEST,
  CREATE_PACKAGE_CATEGORY_RESET,
  CREATE_PACKAGE_CATEGORY_SUCCESS,
  PACKAGES_CATEGORY_LIST_FAIL,
  PACKAGES_CATEGORY_LIST_REQUEST,
  PACKAGES_CATEGORY_LIST_SUCCESS,
  PACKAGE_CATEGORY_DELETE_FAIL,
  PACKAGE_CATEGORY_DELETE_REQUEST,
  PACKAGE_CATEGORY_DELETE_RESET,
  PACKAGE_CATEGORY_DELETE_SUCCESS,
  PACKAGE_CREATE_FAIL,
  PACKAGE_CREATE_REQUEST,
  PACKAGE_CREATE_RESET,
  PACKAGE_CREATE_SUCCESS,
  PACKAGE_DELETE_FAIL,
  PACKAGE_DELETE_REQUEST,
  PACKAGE_DELETE_RESET,
  PACKAGE_DELETE_SUCCESS,
  PACKAGE_DETAILS_FAIL,
  PACKAGE_DETAILS_REQUEST,
  PACKAGE_DETAILS_SUCCESS,
  UPDATE_PACKAGE_CATEGORY_FAIL,
  UPDATE_PACKAGE_CATEGORY_REQUEST,
  UPDATE_PACKAGE_CATEGORY_RESET,
  UPDATE_PACKAGE_CATEGORY_SUCCESS,
  UPDATE_PACKAGE_DETAILS_FAIL,
  UPDATE_PACKAGE_DETAILS_REQUEST,
  UPDATE_PACKAGE_DETAILS_RESET,
  UPDATE_PACKAGE_DETAILS_SUCCESS,
  UPDATE_PROTOCOL_ACTIVITY_FAIL,
  UPDATE_PROTOCOL_ACTIVITY_REQUEST,
  UPDATE_PROTOCOL_ACTIVITY_RESET,
  UPDATE_PROTOCOL_ACTIVITY_SUCCESS,
  UPDATE_PROTOCOL_BENEFIT_FAIL,
  UPDATE_PROTOCOL_BENEFIT_REQUEST,
  UPDATE_PROTOCOL_BENEFIT_RESET,
  UPDATE_PROTOCOL_BENEFIT_SUCCESS,
} from '../../../constants/adminConstants/packageConstants'

export const allPackagesReducer = (state = { packages: [] }, action) => {
  switch (action.type) {
    case ALL_PACKAGES_REQUEST:
      return { loading: true }
    case ALL_PACKAGES_SUCCESS:
      return { loading: false, packages: action.payload }
    case ALL_PACKAGES_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const packageDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PACKAGE_DELETE_REQUEST:
      return { loading: true }
    case PACKAGE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PACKAGE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case PACKAGE_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const packageCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PACKAGE_CREATE_REQUEST:
      return { loading: true }
    case PACKAGE_CREATE_SUCCESS:
      return { loading: false, success: true, newPackage: action.payload }
    case PACKAGE_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case PACKAGE_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const packageDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case PACKAGE_DETAILS_REQUEST:
      return { loading: true }
    case PACKAGE_DETAILS_SUCCESS:
      return { loading: false, packageDetail: action.payload }
    case PACKAGE_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updatePackageDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PACKAGE_DETAILS_REQUEST:
      return { loading: true }
    case UPDATE_PACKAGE_DETAILS_SUCCESS:
      return { loading: false, updatedDetail: action.payload }
    case UPDATE_PACKAGE_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_PACKAGE_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const updatePackageActivityReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROTOCOL_ACTIVITY_REQUEST:
      return { loading: true }
    case UPDATE_PROTOCOL_ACTIVITY_SUCCESS:
      return { loading: false, updatedActivity: action.payload }
    case UPDATE_PROTOCOL_ACTIVITY_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_PROTOCOL_ACTIVITY_RESET:
      return {}
    default:
      return state
  }
}

export const updatePackageBenefitReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROTOCOL_BENEFIT_REQUEST:
      return { loading: true }
    case UPDATE_PROTOCOL_BENEFIT_SUCCESS:
      return { loading: false, updatedBenefit: action.payload }
    case UPDATE_PROTOCOL_BENEFIT_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_PROTOCOL_BENEFIT_RESET:
      return {}
    default:
      return state
  }
}

export const addPackagesCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PACKAGE_CATEGORY_REQUEST:
      return { loading: true }
    case CREATE_PACKAGE_CATEGORY_SUCCESS:
      return { loading: false, category: action.payload }
    case CREATE_PACKAGE_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    case CREATE_PACKAGE_CATEGORY_RESET:
      return {}
    default:
      return state
  }
}

export const packageCategoryListReducer = (
  state = { categoryList: [] },
  action,
) => {
  switch (action.type) {
    case PACKAGES_CATEGORY_LIST_REQUEST:
      return { loading: true }
    case PACKAGES_CATEGORY_LIST_SUCCESS:
      return { loading: false, categoryList: action.payload }
    case PACKAGES_CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updatePackagesCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PACKAGE_CATEGORY_REQUEST:
      return { loading: true }
    case UPDATE_PACKAGE_CATEGORY_SUCCESS:
      return { loading: false, updated: action.payload }
    case UPDATE_PACKAGE_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_PACKAGE_CATEGORY_RESET:
      return {}
    default:
      return state
  }
}

export const packageCategoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PACKAGE_CATEGORY_DELETE_REQUEST:
      return { loading: true }
    case PACKAGE_CATEGORY_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PACKAGE_CATEGORY_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case PACKAGE_CATEGORY_DELETE_RESET:
      return {}
    default:
      return state
  }
}
