import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import {
  AddProductCategory,
  allProductCategory,
} from '../../../actions/adminActions/productActions/productCategory'
import {
  ADD_PRODUCT_CATEGORY_RESET,
  ASSIGN_PRODUCT_CATEGORY_POINT_RESET,
  UPDATE_PRODUCT_CATEGORY_RESET,
} from '../../../constants/adminConstants/productConstants'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { updateProductCategory } from '../../../actions/adminActions/productActions/updateProductCategory'
import { deleteProductCategory } from '../../../actions/adminActions/productActions/deleteProductCategory'
import SweetAlert from 'react-bootstrap-sweetalert'
import { MDBDataTableV5 } from 'mdbreact'
import { assignProductCategoryPoint } from '../../../actions/adminActions/productActions/productCategoryPoint'
import { allAssignedPC } from '../../../actions/adminActions/productActions/allAssignedPC'

function ProductCategoryPage() {
  const [alert, setAlert] = useState(null)
  const initialState = ''
  const [open, setOpen] = useState(false)
  const [itemId, setItemId] = useState(initialState)
  const [name, setName] = useState(initialState)
  const [description, setDescription] = useState(initialState)
  const [expectedPoint, setExpectedPoint] = useState(initialState)
  const [cycleDuration, setCycleDuration] = useState(initialState)
  const [categoryType, setCategoryType] = useState(initialState)
  const [datatable, setDatatable] = useState({})
  const [productCategoryId, setProductCategoryId] = useState('')
  const [point, setPoint] = useState('')

  const newProductCategory = useSelector((state) => state.newProductCategory)
  const {
    loading: addCatLoading,
    error: addCatError,
    category,
  } = newProductCategory

  const productCategory = useSelector((state) => state.productCategory)
  const { loading, error, categorylist } = productCategory

  const updateCategory = useSelector((state) => state.updateCategory)
  const { loading: updateLoading, error: updateError, update } = updateCategory

  const deleteCategory = useSelector((state) => state.deleteCategory)
  const { loading: deleteLoading, error: deleteError, success } = deleteCategory

  const productCategoryPoint = useSelector(
    (state) => state.productCategoryPoint,
  )
  const {
    loading: loadingAssign,
    error: assignError,
    saved,
  } = productCategoryPoint

  const allProductCategoryPoint = useSelector(
    (state) => state.allProductCategoryPoint,
  )
  const {
    loading: loadingAssigned,
    error: assignedError,
    assignedPC,
  } = allProductCategoryPoint

  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(
      AddProductCategory(
        name,
        description,
        expectedPoint,
        categoryType,
        cycleDuration,
      ),
    )
  }

  const handleSave = (e) => {
    e.preventDefault()

    dispatch(assignProductCategoryPoint(productCategoryId, point))
  }

  const deleteHandler = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deleteProductCategory(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  const handleClickOpen = (item) => {
    setOpen(true)
    setItemId(item._id)
    setName(item.name)
    setCycleDuration(item.cycleDuration)
    setCategoryType(item.categoryType)
    setExpectedPoint(item.expectedPoint)
    setDescription(item.description)
  }

  const handleEditSubmit = (e) => {
    e.preventDefault()
    dispatch(
      updateProductCategory(
        itemId,
        name,
        description,
        expectedPoint,
        categoryType,
        cycleDuration,
      ),
    )
    setOpen(false)
  }

  useEffect(() => {
    dispatch(allProductCategory())
    if (category) {
      setTimeout(() => {
        dispatch({ type: ADD_PRODUCT_CATEGORY_RESET })
      }, 2000)
    }
    if (update) {
      setTimeout(() => {
        dispatch({ type: UPDATE_PRODUCT_CATEGORY_RESET })
      }, 2000)
    }

    if (success) {
      setAlert(null)
    }
  }, [dispatch, category, update, success])

  useEffect(() => {
    dispatch(allAssignedPC())
    if (saved) {
      setTimeout(() => {
        dispatch({ type: ASSIGN_PRODUCT_CATEGORY_POINT_RESET })
      }, 2000)
    }

    if (assignError) {
      setTimeout(() => {
        dispatch({ type: ASSIGN_PRODUCT_CATEGORY_POINT_RESET })
      }, 2000)
    }
  }, [saved, assignError, dispatch])

  useEffect(() => {
    if (assignedPC) {
      setDatatable({
        columns: [
          {
            label: 'Category Name',
            field: 'name',
            width: 400,
          },
          {
            label: 'Point Assigned ',
            field: 'point',
            width: 400,
          },
          {
            label: 'Date Assigned',
            field: 'date',
          },
        ],
        rows: assignedPC.map((item) => ({
          name: item.category && item.category.name ? item.category.name : '',
          point: item.point,
          date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
        })),
      })
    }
  }, [assignedPC])

  return (
    <div id="layout-wrapper">
      <Header />
      <RightSidebar />
      <LeftSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Product Categories</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Products</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Product Categories
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      {/* Add New Product Category */}
                      <div className="col-md-4">
                        {category && (
                          <MessageBox variant="success">
                            {category.message}
                          </MessageBox>
                        )}
                        {addCatError && (
                          <MessageBox variant="danger">
                            {addCatError}
                          </MessageBox>
                        )}

                        {updateLoading ? <LoadingBox /> : null}
                        {updateError ? (
                          <MessageBox variant="danger">
                            {updateError}
                          </MessageBox>
                        ) : null}
                        <div className="mt-2 mb-2">
                          <h4 className="card-title">Add New Category</h4>
                        </div>
                        <div className="mb-3">
                          <small className="font-size-13">
                            Product categories for the Farmsured system can be
                            managed here.
                          </small>
                        </div>

                        {addCatLoading ? (
                          <LoadingBox />
                        ) : (
                          <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <label className="form-label" forHtml="name">
                                Name
                              </label>
                              <input
                                id="name"
                                name="name"
                                type="text"
                                className="form-control form-control"
                                required
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label" forHtml="point">
                                Expected Point
                              </label>
                              <input
                                id="point"
                                name="point"
                                type="number"
                                className="form-control form-control"
                                required
                                onChange={(e) =>
                                  setExpectedPoint(e.target.value)
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label" forHtml="point">
                                Category Type
                              </label>
                              <select
                                onChange={(e) =>
                                  setCategoryType(e.target.value)
                                }
                                className="select2 form-select"
                                required
                              >
                                <option>Select category type</option>
                                <option value="Product">Product</option>
                                <option value="Service">Service</option>
                              </select>
                            </div>
                            <div className="mb-3">
                              <label className="form-label" forHtml="point">
                                Cycle Duration (Hrs)
                              </label>
                              <input
                                onChange={(e) =>
                                  setCycleDuration(e.target.value)
                                }
                                type="number"
                                className="form-control form-control"
                                required
                              />
                              {/* <option>Select duration</option>
                                <option value="0.0833333">5 Minutes</option>
                                <option value="0.333333">20 Minutes</option>
                                <option value="0.5">30 Minutes</option>
                                <option value="24">24 hrs</option>
                                <option value="48">48 hrs</option>
                                <option value="72">72 hrs</option>
                                <option value="96">96 hrs</option>
                                <option value="120">120 hrs</option>
                                <option value="144">144 hrs</option>
                                <option value="2190">3 Months</option>
                                <option value="4380">6 Months</option>
                                <option value="8760">1 Year</option> */}
                            </div>
                            <div className="mb-3">
                              <label className="form-label" forHtml="name">
                                Description
                              </label>
                              <textarea
                                id="description"
                                name="description"
                                type="text"
                                className="form-control form-control"
                                required
                                onChange={(e) => setDescription(e.target.value)}
                              />
                              <small>
                                The description is not prominent by default.
                              </small>
                            </div>

                            <div className="mt-2">
                              <button
                                className="btn btn-sm btn-success"
                                type="submit"
                              >
                                Add new category
                              </button>
                            </div>
                          </form>
                        )}
                      </div>

                      {/* Product Category Table */}
                      <div className="col-md-8">
                        <div className="table-responsive mt-4">
                          {deleteLoading ? <LoadingBox /> : null}
                          {deleteError ? (
                            <MessageBox variant="danger">
                              {deleteError}
                            </MessageBox>
                          ) : null}

                          {update ? (
                            <MessageBox variant="success">
                              {update.message}
                            </MessageBox>
                          ) : null}
                          {error ? (
                            <MessageBox variant="danger">{error}</MessageBox>
                          ) : null}
                          {loading ? (
                            <LoadingBox />
                          ) : (
                            <table
                              className="table table-centered datatable dt-responsive nowrap"
                              style={{
                                borderCollapse: 'collapse',
                                borderSpacing: 0,
                                // width: '100%',
                              }}
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th style={{ width: '180px' }}>
                                    Category Name
                                  </th>
                                  <th>Category</th>
                                  <th style={{ width: '140px' }}>
                                    Expected Point
                                  </th>
                                  <th style={{ width: '140px' }}>
                                    Cycle Duration
                                  </th>
                                  <th>Description</th>
                                  <th>Date Created</th>
                                  <th style={{ width: '120px' }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {categorylist.length === 0 ? (
                                  <div className="d-sm-flex align-items-center justify-content-between">
                                    <p className="card-title-desc">
                                      There is no product categories data
                                    </p>
                                  </div>
                                ) : (
                                  categorylist.map((item) => (
                                    <tr key={item._id}>
                                      <td>{item.name}</td>
                                      <td>{item.categoryType}</td>
                                      <td>{item.expectedPoint}</td>
                                      <td>{item.cycleDuration}</td>
                                      <td>{item.description}</td>
                                      <td>
                                        {moment(item.createdAt).format(
                                          'Do MMMM YYYY',
                                        )}
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-sm btn-primary text-edit"
                                          style={{ marginRight: '15px' }}
                                          data-bs-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit"
                                          onClick={() => handleClickOpen(item)}
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="btn btn-sm p-0 text-danger"
                                          data-bs-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete"
                                          onClick={() => deleteHandler(item)}
                                        >
                                          <i className="mdi mdi-trash-can font-size-18"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-5">
                        {saved ? (
                          <MessageBox variant="success">
                            {saved.message}
                          </MessageBox>
                        ) : null}
                        {assignError ? (
                          <MessageBox variant="danger">
                            {assignError}
                          </MessageBox>
                        ) : null}
                        {loadingAssign ? (
                          'loading'
                        ) : (
                          <>
                            <div className="mt-2 mb-2">
                              <h4 className="card-title">Assign Tag Point</h4>
                            </div>
                            <div className="mb-3">
                              <small className="font-size-13">
                                Assigned tag point to product categories for the
                                Farmsured system can be managed here.
                              </small>
                            </div>
                            <form onSubmit={handleSave}>
                              <div className="row mb-3">
                                <div className="col-md-12 mb-3">
                                  <label htmlFor="product-category">
                                    Select Product Category
                                  </label>
                                  <select
                                    className="select2 form-select"
                                    required
                                    onChange={(e) =>
                                      setProductCategoryId(e.target.value)
                                    }
                                  >
                                    {loading ? (
                                      <option>Select product category</option>
                                    ) : categorylist &&
                                      categorylist.length > 0 ? (
                                      <>
                                        <option>Select product category</option>
                                        {categorylist.map((item) => (
                                          <option value={item._id}>
                                            {item.name}
                                          </option>
                                        ))}
                                      </>
                                    ) : (
                                      <option>Select product category</option>
                                    )}
                                  </select>
                                </div>
                                <div className="col-md-12">
                                  <label htmlFor="point">
                                    Product Category Tag Point
                                  </label>
                                  <input
                                    className="form-control"
                                    type="number"
                                    placeholder="Product Category Tag Point"
                                    name="point"
                                    id="example-text-input"
                                    onChange={(e) => setPoint(e.target.value)}
                                    required
                                  />
                                </div>

                                <div className="mt-4">
                                  <button
                                    className="btn btn-sm btn-success"
                                    type="submit"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </form>
                          </>
                        )}
                      </div>
                      <div className="col-sm-7">
                        {loadingAssigned ? (
                          'Loading'
                        ) : (
                          <div className="table-responsive-xl">
                            {assignedError ? (
                              <MessageBox variant="danger">
                                {assignError}
                              </MessageBox>
                            ) : null}
                            <MDBDataTableV5
                              hover
                              entriesOptions={[5, 15, 20, 25, 50]}
                              entries={5}
                              pagesAmount={4}
                              data={datatable}
                              searchTop
                              searchBottom={false}
                              barReverse
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Edit Modal */}
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="form-dialog-title"
            >
              <>
                <div className="modal-header">
                  <h5 className="modal-title">Edit product category</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setOpen(false)}
                  ></button>
                </div>
                <DialogContent className="dialog_content">
                  <div className="">
                    <form>
                      <div className="row mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-md-12">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Name"
                            id="example-text-input"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Expected Point
                        </label>
                        <div className="col-md-12">
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Expected Point"
                            id="example-text-input"
                            value={expectedPoint}
                            onChange={(e) => setExpectedPoint(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" forHtml="point">
                          Category Type
                        </label>
                        <select
                          onChange={(e) => setCategoryType(e.target.value)}
                          className="select2 form-select"
                          value={categoryType}
                          required
                        >
                          <option>Select category type</option>
                          <option value="Product">Product</option>
                          <option value="Service">Service</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" forHtml="point">
                          Cycle Duration (Hrs)
                        </label>
                         <input
                                onChange={(e) =>
                                  setCycleDuration(e.target.value)
                                }
                                type="number"
                                className="form-control form-control"
                                required
                                value={cycleDuration}
                              />
                        {/* <select
                          onChange={(e) => setCycleDuration(e.target.value)}
                          className="select2 form-select"
                          value={cycleDuration}
                          required
                        >
                          <option>Select duration</option>
                          <option value="0.0833333">5 Minutes</option>
                          <option value="0.333333">20 Minutes</option>
                          <option value="0.5">30 Minutes</option>
                          <option value="24">24 hrs</option>
                          <option value="48">48 hrs</option>
                          <option value="72">72 hrs</option>
                          <option value="96">96 hrs</option>
                          <option value="120">120 hrs</option>
                          <option value="144">144 hrs</option>
                          <option value="2190">3 Months</option>
                          <option value="4380">6 Months</option>
                          <option value="8760">1 Year</option>
                        </select> */}
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Description
                        </label>
                        <div className="col-md-12">
                          <textarea
                            className="form-control"
                            type="text"
                            placeholder="Description"
                            id="example-text-input"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </div>

                      <DialogActions>
                        <button
                          type="button"
                          className="btn btn-light btn-sm waves-effect"
                          data-bs-dismiss="modal"
                          onClick={() => setOpen(false)}
                        >
                          Close
                        </button>
                        <button
                          className="btn btn-success btn-sm"
                          type="submit"
                          onClick={handleEditSubmit}
                        >
                          Update
                        </button>
                      </DialogActions>
                    </form>
                  </div>
                </DialogContent>
              </>
            </Dialog>
          </div>
          {alert}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ProductCategoryPage
