import axios from 'axios'
import {
  UPDATE_MANUFACTURER_FAIL,
  UPDATE_MANUFACTURER_REQUEST,
  UPDATE_MANUFACTURER_SUCCESS,
} from '../../../constants/adminConstants/manufacturersConstants'

export const adminUpdateManufacturer = (
  itemId,
  companyName,
  contactperson,
  phone,
  email,
  address,
  city,
  state,
) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_MANUFACTURER_REQUEST,
    payload: {
      itemId,
      companyName,
      contactperson,
      phone,
      email,
      address,
      city,
      state,
    },
  })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.post(
      '/api/admin/update-manufacturer',
      {
        itemId,
        companyName,
        contactperson,
        phone,
        email,
        address,
        city,
        state,
      },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      },
    )
    dispatch({ type: UPDATE_MANUFACTURER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: UPDATE_MANUFACTURER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
