import {
  ACTIVITY_ADD_ITEM,
  ACTIVITY_EMPTY,
  ACTIVITY_REMOVE_ITEM,
  ADD_BENEFIT_REQUEST,
  BULK_ACTIVITY_ADD_ITEM,
  CLEAR_ACTIVITIES,
} from '../../../constants/adminConstants/activityContants'

export const activityReducer = (
  state = { activityItems: [], benefitsItems: [] },
  action,
) => {
  switch (action.type) {
    case ACTIVITY_ADD_ITEM:
      const item = action.payload
      const existItem = state.activityItems.find((x) => x._id === item._id)
      if (existItem) {
        return {
          ...state,
          error: '',
          activityItems: state.activityItems.map((x) =>
            x._id === existItem._id ? item : x,
          ),
        }
      } else {
        return {
          ...state,
          error: '',
          activityItems: [...state.activityItems, item],
        }
      }

    case BULK_ACTIVITY_ADD_ITEM:
      const tasks = action.payload
      const existTask = state.activityItems.find((x) => x._id === tasks._id)
      if (existTask) {
        return {
          ...state,
          error: '',
          activityItems: state.activityItems.map((x) =>
            x._id === existTask._id ? tasks : x,
          ),
        }
      } else {
        return {
          ...state,
          error: '',
          activityItems: tasks,
        }
      }

    case ACTIVITY_REMOVE_ITEM:
      return {
        ...state,
        error: '',
        activityItems: state.activityItems.filter(
          (x) => x._id !== action.payload,
        ),
      }
    case ADD_BENEFIT_REQUEST:
      return {
        ...state,
        error: '',
        benefitsItems: [...state.benefitsItems, action.payload],
      }
    case ACTIVITY_EMPTY:
      return { ...state, activityItems: [], benefitsItems: [] }
    case CLEAR_ACTIVITIES:
      return {
        ...state,
        activityItems: [],
      }
    default:
      return state
  }
}
