import React, { useState, useEffect } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MDBDataTableV5 } from 'mdbreact'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { allClaims } from '../../../actions/adminActions/benefitsActions/allClaims'
import { LoadingBox, MessageBox } from '../../../components'
import { deleteClaim } from '../../../actions/adminActions/benefitsActions/deleteClaim'
import { CLAIM_DELETE_RESET } from '../../../constants/adminConstants/benefitContants'
import SweetAlert from 'react-bootstrap-sweetalert'

export default function ClaimPage() {
  const [datatable, setDatatable] = useState({})
  const [alert, setAlert] = useState(null)

  const claimList = useSelector((state) => state.claimList)
  const { loading, error, claims } = claimList

  const claimDelete = useSelector((state) => state.claimDelete)
  const { loading: loadingDelete, error: deleteError, deleted } = claimDelete

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(allClaims())

    if (deleted) {
      setAlert(null)
      setTimeout(() => {
        dispatch({ type: CLAIM_DELETE_RESET })
      }, 2500)
    }
  }, [dispatch, deleted])

  useEffect(() => {
    if (claims) {
      setDatatable({
        columns: [
          {
            label: 'Claim ID',
            field: 'claimID',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'phone',
            },
          },
          {
            label: 'Farmer',
            field: 'name',
            width: 200,
          },
          {
            label: 'Service Provider',
            field: 'service',
            width: 100,
          },
          {
            label: 'Claim Date',
            field: 'date',
            width: 100,
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'disabled',
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: claims.map((item) => ({
          claimID: item.claimID,
          name: `${item.farmer.firstName ? item.farmer.firstName : ''} ${
            item.farmer.lastName ? item.farmer.lastName : ''
          } `,
          service: item.serviceProvider && item.serviceProvider.name,
          date: moment(item.created_at).format('Do MMMM YYYY,  h:mm a'),
          status: (
            <div className={`badge badge-soft-${item.status} font-size-11`}>
              {item.status}
            </div>
          ),
          action: (
            <div>
              <a
                href={`/claim/detail/${item._id}`}
                className="btn btn-sm btn-primary"
                style={{ marginRight: '15px' }}
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Edit"
              >
                Edit
              </a>
              <button
                className="btn p-0 m-0 text-danger"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Delete"
                onClick={() => deleteHandle(item)}
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </button>
            </div>
          ),
        })),
      })
    }
  }, [claims])

  const deleteHandle = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deleteClaim(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }
  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Claims Management</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Claims</a>
                      </li>
                      <li className="breadcrumb-item active">All Claims</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {loadingDelete ? <LoadingBox /> : null}
            {deleteError ? (
              <MessageBox variant="danger">{deleteError}</MessageBox>
            ) : null}
            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive-lg">
                    <MDBDataTableV5
                      hover
                      entriesOptions={[5, 20, 25, 50]}
                      entries={10}
                      pagesAmount={4}
                      data={datatable}
                      searchTop
                      searchBottom={false}
                      barReverse
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {alert}
        </div>
      </div>
      <Footer />
    </div>
  )
}
