import axios from 'axios'
import {
  PACKAGE_CREATE_FAIL,
  PACKAGE_CREATE_REQUEST,
  PACKAGE_CREATE_SUCCESS,
} from '../../../constants/adminConstants/packageConstants'

export const createPackage = (activity) => async (dispatch, getState) => {
  dispatch({ type: PACKAGE_CREATE_REQUEST, payload: activity })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post('/api/admin/add-new-package', activity, {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: PACKAGE_CREATE_SUCCESS, payload: data.newPackage })
    localStorage.removeItem('activityItems')
    localStorage.removeItem('benefitsItems')
  } catch (error) {
    dispatch({
      type: PACKAGE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
