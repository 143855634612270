import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import passwordReset from '../../actions/authActions/passwordReset'
import { LoadingBox, MessageBox } from '../../components'
import { RESET_PASSWORD_RESET } from '../../constants/authConstants/resetPasswordContants'
import SweetAlert from 'react-bootstrap-sweetalert'

function ResetPasswordPage(props) {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [alert, setAlert] = useState(false)

  const query = new URLSearchParams(useLocation().search)
  const token = query.get('token')
  const id = query.get('id')

  const updatePassword = useSelector((state) => state.updatePassword)
  const { loading, update, error } = updatePassword

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()

    if (password !== confirmPassword) {
      setAlert(true)
    } else {
      dispatch(passwordReset(token, id, password))
    }
  }

  useEffect(() => {
    if (update) {
      setTimeout(() => {
        props.history.push('/')
        dispatch({ type: RESET_PASSWORD_RESET })
      }, 2500)
    }
  }, [update])

  const date = new Date().getFullYear()

  return (
    <div className="container-fluid p-0">
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <div className="row g-0">
            <div className="col-lg-4">
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <div className="row justify-content-center">
                    {error ? (
                      <MessageBox variant="danger">{error}</MessageBox>
                    ) : null}

                    {update ? (
                      <MessageBox variant="success">
                        {update.message}
                      </MessageBox>
                    ) : null}
                    <div className="col-lg-9">
                      <div>
                        <div className="text-center">
                          <div>
                            <a href="/" className="">
                              <img
                                src="assets/images/farmsured!.png"
                                alt=""
                                height="50"
                                className="auth-logo logo-dark mx-auto"
                              />
                              <img
                                src="assets/images/farmsured!.png"
                                alt=""
                                height="50"
                                className="auth-logo logo-light mx-auto"
                              />
                            </a>
                          </div>

                          <h4 className="font-size-18 mt-4">Reset Password</h4>
                          <p className="text-muted">
                            Reset your password to Farmsured.
                          </p>
                        </div>

                        <div className="">
                          <form className="" onSubmit={submitHandler}>
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-password-line auti-custom-input-icon"></i>
                              <label htmlFor="useremail">Password</label>
                              <input
                                type="password"
                                className="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                required
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>

                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-password-line auti-custom-input-icon"></i>
                              <label htmlFor="useremail">
                                Confirm Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="userconfirmpassword"
                                placeholder="Enter confirm password"
                                required
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                            </div>

                            <div className="mt-4 text-center">
                              <button
                                className="btn btn-success w-md waves-effect waves-light"
                                type="submit"
                              >
                                Reset
                              </button>
                            </div>
                          </form>
                        </div>

                        <div className="mt-5 text-center">
                          <p>
                            Don't have an account?{' '}
                            <a href="/" className="fw-medium text-primary">
                              Log in
                            </a>
                          </p>
                          <p>
                            © {date} Farmsured{' '}
                            <sup>
                              <small>TM</small>
                            </sup>
                            {''} A product of AgDyna
                            <sup>
                              <i className="fal fa-registered font-size-12"></i>
                            </sup>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </div>
          </div>
          {alert ? (
            <SweetAlert
              warning
              confirmBtnText="Ok"
              style={{ fontSize: '14px' }}
              title="Does Not Match"
              onConfirm={() => setAlert(false)}
              onCancel={() => setAlert(false)}
              focusCancelBtn
            >
              Password and Confirm Password does not match
            </SweetAlert>
          ) : null}
        </>
      )}
    </div>
  )
}

export default ResetPasswordPage
