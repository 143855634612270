import axios from 'axios'
import {
  FARMER_CLAIM_BENEFIT_REQUEST,
  FARMER_CLAIM_BENEFIT_SUCCESS,
  FARMER_CLAIM_BENEFIT_FAIL,
} from '../../../constants/farmerConstants/claimBenefitConstants'

export const claimBenefit = (
  itemId,
  benefitName,
  currentState,
  lga,
  serviceProvider,
  detail,
) => async (dispatch, getState) => {
  dispatch({
    type: FARMER_CLAIM_BENEFIT_REQUEST,
    payload: {
      itemId,
      benefitName,
      currentState,
      lga,
      serviceProvider,
      detail,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/farmer/claim-benefit',
      {itemId, benefitName, currentState, lga, serviceProvider, detail },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: FARMER_CLAIM_BENEFIT_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: FARMER_CLAIM_BENEFIT_FAIL, payload: message })
  }
}
