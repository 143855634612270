import {
    REQUEST_QUOTE_FAIL,
    REQUEST_QUOTE_REQUEST,
    REQUEST_QUOTE_RESET,
    REQUEST_QUOTE_SUCCESS,
    USER_CLUSTER_LIST_FAIL,
    USER_CLUSTER_LIST_REQUEST,
    USER_CLUSTER_LIST_SUCCESS
} from '../../../constants/manufacturerConstants/farmClusterConstants'

export const myClustersReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_CLUSTER_LIST_REQUEST:
            return { loading: true }
        case USER_CLUSTER_LIST_SUCCESS:
            return { loading: false, clusters: action.payload }
        case USER_CLUSTER_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const requestQuoteReducer = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_QUOTE_REQUEST:
            return { loading: true }
        case REQUEST_QUOTE_SUCCESS:
            return { loading: false, requested: action.payload }
        case REQUEST_QUOTE_FAIL:
            return { loading: false, error: action.payload }
        case REQUEST_QUOTE_RESET:
            return {}
        default:
            return state
    }
}