import axios from 'axios'
import {
    VERIFY_CODE_FAIL,
    VERIFY_CODE_REQUEST,
    VERIFY_CODE_SUCCESS,
} from '../../../constants/authConstants/walletConstants'

export const verifyCode = (itemId, code) => async (dispatch, getState) => {
    dispatch({
        type: VERIFY_CODE_REQUEST,
        payload: { itemId, code }
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/wallet/escrow-transaction/claim-fund',
            { itemId, code },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: VERIFY_CODE_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: VERIFY_CODE_FAIL, payload: message })
    }
}
