import React, { useState, useEffect } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MDBDataTableV5 } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { allServiceProviders } from '../../../actions/manufacturerActions/serviceProviderActions/allServiceProviders'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import Select from 'react-select'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { organisationServiceCategory } from '../../../actions/manufacturerActions/serviceProviderActions/organisationCategory'
import { updateSP } from '../../../actions/manufacturerActions/serviceProviderActions/updateServiceProvider'
import { ORGANISATION_SERVICE_PROVIDER_UPDATE_RESET } from '../../../constants/manufacturerConstants/serviceProviderConstants'
import NaijaStates from 'naija-state-local-government'
import states from '../../../utilis'

function AllServiceProviderPage() {
  const [datatable, setDatatable] = useState({})
  const [options, setOptions] = useState([])
  const [itemId, setItemId] = useState('')
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [name, setName] = useState('')
  const [category, setCategory] = useState([])
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [lga, setLga] = useState('')
  const [state, setState] = useState('')

  // All Service State
  const serviceProviders = useSelector((state) => state.serviceProviders)
  const { loading, allSPs, error } = serviceProviders

  const organisationCategory = useSelector(
    (state) => state.organisationCategory,
  )
  const {
    loading: loadingCategory,
    error: categoryError,
    categoryList,
  } = organisationCategory

  const updateServiceProvider = useSelector(
    (state) => state.updateServiceProvider,
  )
  const {
    loading: loadingUpdate,
    error: updateError,
    updatedSP,
  } = updateServiceProvider

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(allServiceProviders())
    dispatch(organisationServiceCategory())

    if (updatedSP) {
      setTimeout(() => {
        setOpenEditDialog(false)
        dispatch({ type: ORGANISATION_SERVICE_PROVIDER_UPDATE_RESET })
      }, 2500)
    }
  }, [dispatch, updatedSP])

  useEffect(() => {
    if (allSPs) {
      setDatatable({
        columns: [
          {
            label: 'Unique Code',
            field: 'uniqueCode',
            width: 50,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'uniqueCode',
            },
          },
          {
            label: 'Name',
            field: 'name',
            width: 300,
          },
          {
            label: 'Category',
            field: 'category',
            sort: 'disabled',
            width: 200,
          },
          {
            label: 'Service',
            field: 'service',
            sort: 'disabled',
            width: 200,
          },
          {
            label: 'Email',
            field: 'email',
            width: 200,
          },
          {
            label: 'Phone',
            field: 'phone',
            width: 100,
          },
          {
            label: 'Date Joined',
            field: 'date',
            width: 100,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
            width: 50,
          },
        ],
        rows: allSPs.map((item) => ({
          uniqueCode: item.uniqueCode,
          name: item.name,
          category:
            item.category && item.category.length < 0 ? (
              <div>There is no category</div>
            ) : (
              item.category.map((item) => (
                <div className={`badge badge-soft-success font-size-12 m-1`}>
                  {item.name}
                </div>
              ))
            ),
          service:
            item.benefit && item.benefit.length < 0 ? (
              <div>There is no benefit</div>
            ) : (
              item.benefit.map((item) => (
                <div className={`badge badge-soft-success font-size-12 m-1`}>
                  {item.benefit}
                </div>
              ))
            ),
          email: item.email,
          phone: item.phone ? `0${item.phone}` : '',
          date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
          action: (
            <>
              <button
                onClick={() => handleEditOpen(item)}
                style={{ marginRight: '15px' }}
                className="btn btn-sm m-0 btn-primary"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Manage"
              >
                Manage
              </button>
            </>
          ),
        })),
      })
    }
  }, [allSPs])

  const handleEditOpen = (item) => {
    setOpenEditDialog(true)
    setItemId(item._id)
    setName(item.name)
    setEmail(item.email)
    setPhone(item.phone)
    setCategory(item.category)
    setAddress(item.address)
    setState(item.state)
    setLga(item.lga)
  }

  const handleEditDialogClose = () => {
    setOpenEditDialog(false)
  }

  const handleEditSubmit = (e) => {
    e.preventDefault()

    dispatch(
      updateSP(itemId, name, email, phone, category, address, state, lga),
    )
  }

  useEffect(() => {
    if (
      categoryList &&
      categoryList.category &&
      categoryList.category.length > 0
    ) {
      setOptions(categoryList.category)
    }
  }, [categoryList])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0"> All Service Provider</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Service Providers</a>
                      </li>
                      <li className="breadcrumb-item active">
                        All Service Provider
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {loading ? <LoadingBox /> : null}
            {error ? <MessageBox variant="danger">{error}</MessageBox> : null}
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive-xl">
                      <MDBDataTableV5
                        hover
                        entriesOptions={[5, 20, 25, 50]}
                        entries={10}
                        pagesAmount={4}
                        data={datatable}
                        searchTop
                        searchBottom={false}
                        barReverse
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Dialog
              open={openEditDialog}
              onClose={handleEditDialogClose}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth={'md'}
            >
              <div className="modal-header">
                <h5 className="modal-title">Edit Service Provider</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleEditDialogClose}
                ></button>
              </div>
              <DialogContent>
                {categoryError ? (
                  <MessageBox variant="danger">{categoryError}</MessageBox>
                ) : null}
                {updateError ? (
                  <MessageBox variant="danger">{updateError}</MessageBox>
                ) : null}
                {updatedSP ? (
                  <MessageBox variant="success">{updatedSP.message}</MessageBox>
                ) : null}
                {loadingUpdate ? (
                  <LoadingBox />
                ) : (
                  <form onSubmit={handleEditSubmit}>
                    <div className="col-md-12">
                      <label
                        htmlFor="example-text-input2"
                        className="col-md-12 col-form-label"
                      >
                        Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Name"
                        id="example-text-input2"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="example-text-input3"
                        className="col-md-12 col-form-label"
                      >
                        Email
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        id="example-text-input3"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="example-text-input4"
                        className="col-md-12 col-form-label"
                      >
                        Phone
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Phone"
                        value={phone}
                        id="example-text-input4"
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="col-md-12 col-form-label">
                        Service Category
                      </label>
                      <Select
                        onChange={(opt) => setCategory(opt)}
                        defaultValue={category.map((opt) => ({
                          label: opt.name,
                          value: opt._id,
                        }))}
                        required
                        isMulti
                        placeholder="Select category"
                        options={
                          loadingCategory
                            ? 'Loading...'
                            : options.map((opt) => ({
                              label: opt.name,
                              value: opt._id,
                            }))
                        }
                      />
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="example-text-input5"
                        className="col-md-12 col-form-label"
                      >
                        Address
                      </label>
                      <input
                        className="form-control"
                        type="address"
                        placeholder="Address"
                        value={address}
                        id="example-text-input5"
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="example-text-input5"
                        className="col-md-12 col-form-label"
                      >
                        State
                      </label>
                      <select
                        className="form-select form-control select2"
                        required
                        placeholder="Select a state"
                        onChange={(e) => setState(e.target.value)}
                        value={state}
                      >
                        <option>Select a state</option>
                        {states.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label
                          htmlFor="example-text-input5"
                          className="col-md-12 col-form-label"
                        >
                          Local Government Area
                        </label>
                        <select
                          className="form-select form-control select2"
                          required
                          name="lga"
                          placeholder="Select a local government area"
                          onChange={(e) => setLga(e.target.value)}
                          value={lga}
                        >
                          {state && state === 'Select a state' ? (
                            <option>Select a local government area</option>
                          ) : state ? (
                            <>
                              <option>Select a local government area</option>
                              {NaijaStates.lgas(state).lgas.map((item) => (
                                <option value={item}>{item}</option>
                              ))}
                            </>
                          ) : (
                            <option>Select a local government area</option>
                          )}
                        </select>
                      </div>

                    </div>

                    <DialogActions className="mt-3">
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={handleEditDialogClose}
                      >
                        Close
                      </button>
                      <button className="btn btn-success btn-sm" type="submit">
                        Save
                      </button>
                    </DialogActions>
                  </form>
                )}
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AllServiceProviderPage
