import React ,{ useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userReadMessage } from '../../../actions/farmerActions/messageActions/readMessage'
import LoadingBox from '../../../components/LoadingBox'
import MessageBox from '../../../components/MessageBox'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import moment from 'moment'

export default function MessagePage(props) {
  const messageId = props.match.params.id

  const readUserMessage = useSelector((state) => state.readUserMessage)
  const { loading, error, message } = readUserMessage

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userReadMessage(messageId))
  }, [dispatch])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Inbox</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Messages</a>
                      </li>
                      <li className="breadcrumb-item active">Read Message</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="email mb-3">
                        <div className="card">
                          <div className="card-body">
                            <h4 className="font-size-20 mb-4">
                              <b>
                                {message && message.subject
                                  ? message.subject
                                  : null}
                              </b>
                            </h4>
                            <div className="d-flex mb-4">
                              <img
                                className="me-3 rounded-circle avatar-sm"
                                src="assets/images/default-user.png"
                                alt="user image"
                              />
                              <div className="flex-1">
                                <h5 className="font-size-14 my-1">
                                  {message &&
                                  message.sender &&
                                  message.sender.account === 'admin'
                                    ? message.sender.account
                                    : null}
                                </h5>
                                <small className="text-muted">
                                  {message &&
                                  message.sender &&
                                  message.sender.account === 'admin'
                                    ? message.sender.email
                                    : null}
                                </small>
                              </div>
                              <div className="float-end">
                                <span className="date font-size-12">
                                  {moment(message.created_at).format(
                                    'DD MMM YYYY, h:mm a',
                                  )}
                                </span>
                              </div>
                            </div>

                            <p>
                              {message && message.message
                                ? message.message
                                : null}
                            </p>
                            <hr />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
