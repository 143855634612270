export const FARM_CYCLE_LIST_REQUEST = 'FARM_CYCLE_LIST_REQUEST'
export const FARM_CYCLE_LIST_SUCCESS = 'FARM_CYCLE_LIST_SUCCESS'
export const FARM_CYCLE_LIST_FAIL = 'FARM_CYCLE_LIST_FAIL'

export const FARMER_FARM_CYCLE_DETAIL_REQUEST =
  'FARMER_FARM_CYCLE_DETAIL_REQUEST'
export const FARMER_FARM_CYCLE_DETAIL_SUCCESS =
  'FARMER_FARM_CYCLE_DETAIL_SUCCESS'
export const FARMER_FARM_CYCLE_DETAIL_FAIL = 'FARMER_FARM_CYCLE_DETAIL_FAIL'

export const USER_FARM_CYCLE_DETAIL_REQUEST = 'USER_FARM_CYCLE_DETAIL_REQUEST'
export const USER_FARM_CYCLE_DETAIL_SUCCESS = 'USER_FARM_CYCLE_DETAIL_SUCCESS'
export const USER_FARM_CYCLE_DETAIL_FAIL = 'USER_FARM_CYCLE_DETAIL_FAIL'

export const VIEW_FARM_CYCLE_DETAIL_REQUEST = 'VIEW_FARM_CYCLE_DETAIL_REQUEST'
export const VIEW_FARM_CYCLE_DETAIL_SUCCESS = 'VIEW_FARM_CYCLE_DETAIL_SUCCESS'
export const VIEW_FARM_CYCLE_DETAIL_FAIL = 'VIEW_FARM_CYCLE_DETAIL_FAIL'

export const USER_SCAN_ACTIVITIES_REQUEST = 'USER_SCAN_ACTIVITIES_REQUEST'
export const USER_SCAN_ACTIVITIES_SUCCESS = 'USER_SCAN_ACTIVITIES_SUCCESS'
export const USER_SCAN_ACTIVITIES_FAIL = 'USER_SCAN_ACTIVITIES_FAIL'
