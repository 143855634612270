import axios from 'axios'
import {
  ADD_MANUFACTURER_FAIL,
  ADD_MANUFACTURER_REQUEST,
  ADD_MANUFACTURER_SUCCESS,
} from '../../../constants/adminConstants/manufacturersConstants'

export const adminAddManufacturer = ({
  companyName,
  personName,
  phone,
  email,
  address,
  city,
  state,
  account,
  password,
}) => async (dispatch, getState) => {
  dispatch({
    type: ADD_MANUFACTURER_REQUEST,
    payload: {
      companyName,
      personName,
      phone,
      email,
      address,
      city,
      state,
      account,
      password,
    },
  })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.post(
      '/api/admin/add-new-manufacturer',
      {
        companyName,
        personName,
        phone,
        email,
        address,
        city,
        state,
        account,
        password,
      },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      },
    )
    dispatch({ type: ADD_MANUFACTURER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ADD_MANUFACTURER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
