import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { getPackages } from '../../../actions/farmerActions/farmcycleActions/getPackages'
import { allProductService } from '../../../actions/farmerActions/productActions/allProductService'
import moment from 'moment'
import { calDuration, cycleActivities } from '../../../components/functions'
import { LoadingBox, MessageBox, AnalysisTyping } from '../../../components'
import { newCycle } from '../../../actions/farmerActions/farmcycleActions/newCycle'
import { reviewCycle } from '../../../actions/farmerActions/farmcycleActions/reviewCycle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { ADD_FARM_CYCLE_RESET } from '../../../constants/farmerConstants/packagesConstants'
import { TailSpin } from 'react-loading-icons'
import { formatNumber } from '../../../components/functions'
import SweetAlert from 'react-bootstrap-sweetalert'
import NaijaStates from 'naija-state-local-government'
import states from '../../../utilis'

function CreateFarmCyclePage() {
  const [alert, setAlert] = useState()
  const [packageId, setPackageId] = useState('')
  const [productCategoryId, setProductCategoryId] = useState('')
  const [name, setName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [packageNum, setPackageNum] = useState(0)
  const [endDate, setEndDate] = useState('')
  const [terms, setTerms] = useState(false)
  const [reviewTerms, setReviewTerms] = useState(false)
  const [packageTerms, setPackageTerms] = useState(false)
  const [cycleTerms, setCycleTerms] = useState(false)
  const [obj, setObj] = useState({})
  const [activities, setActivities] = useState([])
  const [show, setShow] = useState(false)
  const [showActivities, setShowActivities] = useState(false)
  const [showCountDown, setShowCountDown] = useState(false)
  const [showEOP, setShowEOP] = useState(false)
  const [eopSummary, setEopSummary] = useState(false)
  const [open, setOpen] = useState(false)
  const [expectedPoint, setExpectedPoint] = useState(0)
  const [recordActivityCount, setRecordActivityCount] = useState('')
  const [activateLabelCount, setActivateLabelCount] = useState('')
  const [verifyLabelCount, setVerifyLabelCount] = useState('')
  const [scanPoint, setScanPoint] = useState('')
  const [reviewCycleDialog, setReviewCycleDialog] = useState(false)
  const [eop, setEop] = useState([
    {
      EOPItem: '',
      quantity: '',
      selectedProduct: '',
      notes: '',
      price: 0,
      total: 0,
    },
  ])
  const [list, setinputList] = useState([
    {
      EOPItem: '',
      quantity: '',
      selectedProduct: '',
      EOPItemType: '',
      UoM: '',
      notes: '',
      price: 0,
      total: 0,
    },
  ])
  const [totalSum, setTotalSum] = useState(0)
  const [additionalEoPTotalSum, setAdditionalEoPTotalSum] = useState(0)
  const [getEopTotalSum, setGetEopTotalSum] = useState(0)
  const [sellAmount, setSellAmount] = useState(0)
  const [sellUoS, setSellUoS] = useState('')
  const [lossAmount, setLossAmount] = useState(0)
  const [lossUoM, setLossUoM] = useState('')
  const [productionSize, setProductionSize] = useState(0)
  const [productionTimeline, setProductionTimeline] = useState(0)
  const [produceAfterLoss, setProduceAfterLoss] = useState(0)
  const [totalProduceSold, setTotalProduceSold] = useState(0)
  const [profit, setProfit] = useState(0)
  const [profitabilityROI, setProfitabilityROI] = useState(0)
  const [state, setState] = useState('')
  const [lga, setLga] = useState('')
  const [productList, setProductList] = useState([])
  const [logisticsPrice, setLogisticsPrice] = useState(0)

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const listPackages = useSelector((state) => state.listPackages)
  const { loading: loadingPackage, packages } = listPackages

  const addCycle = useSelector((state) => state.addCycle)
  const { loading, createdCycle, error } = addCycle

  const aiCycleReview = useSelector((state) => state.aiCycleReview)
  const { loading: loadingReview, review, error: reviewError } = aiCycleReview

  const productServiceList = useSelector((state) => state.productServiceList)
  const { products } = productServiceList

  const dispatch = useDispatch()

  // create a new moment object for the current date
  const today = moment()

  const handleSave = (e) => {
    e.preventDefault()

    const updatedEop = eop.map((item) => {
      if (item.quantity) {
        return { ...item, quantity: item.quantity * packageNum };
      }
      return item;
    })

    dispatch(
      newCycle({
        packageId,
        packageNum,
        productCategoryId,
        name,
        startDate,
        endDate,
        terms,
        activities,
        eop: updatedEop,
        list,
        totalSum,
        additionalEoPTotalSum,
        getEopTotalSum,
        sellAmount,
        sellUoS,
        lossAmount,
        lossUoM,
        produceAfterLoss,
        totalProduceSold,
        profit,
        profitabilityROI,
      }),
    )
  }

  useEffect(() => {
    dispatch(getPackages())
    dispatch(allProductService())

    if (createdCycle) {
      setOpen(false)
      dispatch(reviewCycle(createdCycle.itemId))
      setTimeout(() => {
        dispatch({ type: ADD_FARM_CYCLE_RESET })
      }, 2000)

      setTimeout(() => {
        setReviewCycleDialog(true)
        dispatch(reviewCycle(createdCycle.itemId))
      }, 2100)
    }
  }, [dispatch, createdCycle])

  const verifyTab = () => {
    if (
      packageId === '' ||
      name === '' ||
      startDate === '' ||
      packageNum === ''
    ) {
      return setShow(false)
    }
    return setShow(true)
  }

  const handleChange = (event) => {
    setTerms(event.target.checked)
  }

  const handlePackageChange = (event) => {
    setPackageTerms(event.target.checked)
  }

  const handleCycleChange = (event) => {
    setCycleTerms(event.target.checked)
  }

  const handleReviewTermsChange = (event) => {
    setReviewTerms(event.target.checked)
  }

  useEffect(() => {
    if (packages && packageId) {
      const object = packages.find((obj) => obj._id === packageId);
      setObj(object);
      setEop(object.enterprise?.EOP || []);
    }
  }, [packageId, packages]);


  useMemo(() => {
    if (startDate && obj && obj.productCategory) {
      setProductCategoryId(obj.productCategory._id)
      setEndDate(calDuration(startDate, obj.productCategory.cycleDuration))
    }

    if (obj && obj.activities && obj.activities.length > 0) {
      const analysisActivities = cycleActivities(obj.activities, packageNum)
      setActivities(analysisActivities.result)
      setRecordActivityCount(analysisActivities.recordActivityCount)
      setActivateLabelCount(analysisActivities.activateLabelCount)
      setVerifyLabelCount(analysisActivities.verifyLabelCount)
      setScanPoint(analysisActivities.scanActivityPoints)
    }

    if (obj && obj.productCategory && obj.productCategory.expectedPoint) {
      setExpectedPoint(obj.productCategory.expectedPoint * packageNum)
    }
  }, [obj, calDuration, startDate, packageNum])

  const Countdown = ({ date }) => {
    const [remainingTime, setRemainingTime] = useState(getRemainingTime(date))

    useEffect(() => {
      const timer = setTimeout(() => {
        setRemainingTime(getRemainingTime(date))
      }, 1000)

      return () => clearTimeout(timer)
    })

    const { days, hours, minutes, seconds } = remainingTime

    return (
      <div>
        {days} days, {hours} hours, {minutes} minutes, {seconds} seconds
      </div>
    )
  }

  function getRemainingTime(endTime) {
    const total = Date.parse(endTime) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
    const days = Math.floor(total / (1000 * 60 * 60 * 24))

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    }
  }

  const handleinputchange = (e, index) => {
    const { value } = e
    const listItem = [...eop]

    if (value) {
      let qty = listItem[index].quantity
      const result = products && products.find((obj) => obj._id === value)
      if (result) {
        listItem[index]['selectedProduct'] = value
        listItem[index]['price'] = result.product && result.product.price * (result.priceMarkup / 100) + result.product.price
        listItem[index]['total'] = parseInt((listItem[index]['price'] * (qty * packageNum)) + logisticsPrice)
        setEop(listItem)
        setTotalSum(sumTotal(eop))
      }
    }
  }

  useEffect(() => {
    
  }, [packageNum])


  function sumTotal(array) {
    let totalSum = 0

    array.forEach((item) => {
      if (item.total) {
        totalSum += item.total
      }
    })

    return totalSum
  }

  useEffect(() => {
    if (totalSum) {
      let sum = totalSum + additionalEoPTotalSum
      setGetEopTotalSum(sum)
    }
  }, [totalSum, additionalEoPTotalSum])

  const handleFormChange = (e, index) => {
    const { name, value } = e
    const listItem = [...list]
    listItem[index][name] = value

    if (name) {
      let price = listItem[index]['price']
      let qty = listItem[index]['quantity']

      listItem[index]['total'] = parseInt(price * qty)
      setinputList(listItem)
      setAdditionalEoPTotalSum(sumTotal(list))
    }
  }

  const handleremove = (index) => {
    const listItem = [...list]
    listItem.splice(index, 1)
    setAdditionalEoPTotalSum(sumTotal(listItem))
    setinputList(listItem)
  }

  const handleAddClick = (e) => {
    e.preventDefault()
    const emptyInput = list.some(
      (item) =>
        item.EOPItem === '' ||
        item.quantity === '' ||
        item.notes === '' ||
        item.EOPItemType === '' ||
        item.UoM === '',
    )

    if (!emptyInput) {
      setinputList((newInput) => [
        ...newInput,
        { EOPItem: '', quantity: '', notes: '', EOPItemType: '', UoM: '' },
      ])
    } else {
      // Handle case where empty fields exist in the existing items
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="success"
          style={{ fontSize: '14px' }}
          title="Empty Field(s)"
          onConfirm={() => setAlert()}
          onCancel={() => setAlert()}
        >
          Please fill in all fields before adding a new item.
        </SweetAlert>,
      )
    }
  }

  useEffect(() => {
    if (packageNum && obj && obj.packageMinUnit && obj.packageMinUnit.minUnit) {
      let minUnit = Number(obj.packageMinUnit.minUnit)

      setProductionSize(minUnit * packageNum)
    }

    if (obj && obj.productCategory && obj.productCategory.cycleDuration) {
      setProductionTimeline(obj.productCategory.cycleDuration / 24)
    }

    if (productionSize && lossAmount) {
      setProduceAfterLoss(productionSize - lossAmount)
    }

    if (sellAmount && produceAfterLoss) {
      setTotalProduceSold(sellAmount * produceAfterLoss)
    }

    if (totalProduceSold) {
      setProfit(totalProduceSold - getEopTotalSum)
    }

    if (profit && getEopTotalSum) {
      let a = profit / getEopTotalSum
      let b = a * 100
      let c = b.toPrecision(3)
      setProfitabilityROI(c)
    }
  }, [
    obj,
    packageNum,
    productionSize,
    lossAmount,
    sellAmount,
    produceAfterLoss,
    totalProduceSold,
    getEopTotalSum,
    profit,
  ])

  function filterDataByStateAndLGA(data, state, lga) {
    const filteredData = data.filter(itm => {
      if (itm.state === state) {
        const matchingLogistic = itm.logistics.find(logistic => logistic.lga === lga);
        if (matchingLogistic) {
          setLogisticsPrice(matchingLogistic.price);
          return true;
        }
      }
      return false;
    });

    return filteredData;
  }

  useEffect(() => {
    if (products && state && lga) {
      const result = filterDataByStateAndLGA(products, state, lga)
      setProductList(result)
    }
  }, [products, state, lga])


  function filterDataByServiceCategory(data, category) {
    let array = [];
    data.forEach(item => {
      if (item.serviceCategory._id === category) {
        array.push(item);
      }
    });
    return array;
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Add Farm Cycle</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Farm Cycle</a>
                      </li>
                      <li className="breadcrumb-item active">New Farm Cycle</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <LoadingBox />
            ) : (
              <div className="">
                {show ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div>
                            <h5>
                              Hello,{' '}
                              {userInfo && userInfo.firstName
                                ? userInfo.firstName
                                : null}
                            </h5>
                            <p>Welcome, let's do some review before starting</p>
                          </div>

                          <div className="card">
                            <div className="card-body">
                              {/* Section 1: Card  */}
                              <section>
                                <div className="row">
                                  {/* First Column */}
                                  <div className="col-md-3">
                                    <div
                                      className="card"
                                      style={{
                                        backgroundColor: '#00b050',
                                        borderRadius: '10px',
                                      }}
                                    >
                                      <div className="card-body">
                                        <div className="d-flex">
                                          <div className="flex-1 overflow-hidden">
                                            <p className="text-white font-size-14 mb-2">
                                              Farmsured Protocol Package
                                            </p>
                                            <h5 className="mb-0 text-white">
                                              {obj && obj.packageName
                                                ? obj.packageName
                                                : 'Select farmsured protocol package'}
                                            </h5>
                                          </div>
                                          <div className="text-white ms-auto">
                                            <i className="fal fa-id-badge font-size-22"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Secondt Column */}
                                  <div className="col-md-3">
                                    <div
                                      className="card"
                                      style={{
                                        backgroundColor: '#ff5722',
                                        borderRadius: '10px',
                                      }}
                                    >
                                      <div className="card-body">
                                        <div className="d-flex">
                                          <div className="flex-1 overflow-hidden">
                                            <p className="text-white font-size-14 mb-2">
                                              Farmcycle Name
                                            </p>
                                            <h5 className="mb-0 text-white">
                                              {name
                                                ? name
                                                : 'Name your farmcycle'}
                                            </h5>
                                          </div>
                                          <div className="text-white ms-auto">
                                            <i className="fal fa-tractor font-size-24"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Third Column */}
                                  <div className="col-md-3">
                                    <div
                                      className="card"
                                      style={{
                                        backgroundColor: '#92d050',
                                        borderRadius: '10px',
                                      }}
                                    >
                                      <div className="card-body">
                                        <div className="d-flex">
                                          <div className="flex-1 overflow-hidden">
                                            <p className="text-white font-size-14 mb-2">
                                              Start Date
                                            </p>
                                            <h5 className="mb-0 text-white">
                                              {startDate
                                                ? moment(startDate).format(
                                                  'DD MMM YYYY',
                                                )
                                                : 'Select start date'}
                                            </h5>
                                          </div>
                                          <div className="text-white ms-auto">
                                            <i className="fal fa-calendar-day font-size-24"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Fourth Column */}
                                  <div className="col-md-3">
                                    <div
                                      className="card"
                                      style={{
                                        backgroundColor: '#386de8',
                                        borderRadius: '10px',
                                      }}
                                    >
                                      <div className="card-body">
                                        <div className="d-flex">
                                          <div className="flex-1 overflow-hidden">
                                            <p className="text-white font-size-14 mb-2">
                                              End Date
                                            </p>
                                            <h5 className="mb-0 text-white">
                                              {endDate
                                                ? endDate
                                                : 'Input Start Date'}
                                            </h5>
                                          </div>
                                          <div className="text-white ms-auto">
                                            <i className="fal fa-calendar-day font-size-24"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>




                              {/* Economies of Production (E.O.P) */}
                              <section>
                                <div className="card bg-soft-warning">
                                  <div className="card-body">
                                    <p className="bold">
                                      <b>Select State and Local government area to get service providers near you.</b>
                                    </p>
                                    <div className="row">
                                      <div className="col-md-3">
                                        <label
                                          className="form-label"
                                          forHtml="ProductName"
                                        >
                                          Select your state
                                        </label>
                                        <select
                                          id="product"
                                          name="product"
                                          className="form-control form-select select2"
                                          required
                                          placeholder='Select your location'
                                          onChange={(e) => setState(e.target.value)}
                                          value={state}

                                        >
                                          <option>Select State</option>
                                          {states.map((item) => (
                                            <option value={item}>{item}</option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className="col-md-3">
                                        <label
                                          className="form-label"
                                          forHtml="ProductName"
                                        >
                                          Select your lga
                                        </label>
                                        <select
                                          id="product"
                                          name="product"
                                          className="form-control form-select select2"
                                          required
                                          placeholder='Select your location'
                                          onChange={(e) => setLga(e.target.value)}
                                          value={lga}
                                        >
                                          <option>Select LGA</option>
                                          {state && NaijaStates.lgas(state).lgas.map(
                                            (item) => (
                                              <option value={item}>{item}</option>
                                            ),
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="">
                                  <p className="mt-4 mb-4">
                                    Inline with the industry standards, lets us
                                    update your Economics of Production
                                  </p>
                                  <div className="card">
                                    <div
                                      className="card-body"
                                      style={{
                                        border: '1px solid #0d89c7',
                                        borderRadius: '10px',
                                      }}
                                    >
                                      <div className="standard-body">
                                        Economics Of Production
                                      </div>

                                      <div className="mt-4">
                                        <div className="table-responsive">
                                          <table className="table table-centered mb-0 table-nowrap">
                                            <thead className="bg-light">
                                              <tr>
                                                <th>Item</th>
                                                <th className="text-center">
                                                  Quantity
                                                </th>
                                                <th>Unit of Measurement</th>
                                                <th>Product</th>
                                                <th className="text-center">
                                                  Price
                                                </th>
                                                <th>Note</th>
                                                <th>Total(Logistics included)</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {eop.length > 0 ? (
                                                <>
                                                  {eop.map((item, i) => (
                                                    <tr>
                                                      <td>{item.EOPItem}</td>
                                                      <td className="text-center">
                                                        {item.quantity * packageNum}
                                                      </td>
                                                      <td>{item.UoM}</td>
                                                      <td
                                                        className="text-center"
                                                        style={{
                                                          width: '300px',
                                                        }}
                                                      >
                                                        <select
                                                          id="product"
                                                          name="product"
                                                          className="form-control form-select select2"
                                                          required
                                                          onChange={(e) =>
                                                            handleinputchange(
                                                              {
                                                                name:
                                                                  e.target.name,
                                                                value:
                                                                  e.target
                                                                    .value,
                                                              },
                                                              i,
                                                            )
                                                          }
                                                          value={
                                                            item.selectedProduct
                                                          }
                                                        >
                                                          {
                                                            productList &&
                                                              productList
                                                                .length === 0 ? (
                                                              <>
                                                                <option>
                                                                  Select a product
                                                                </option>
                                                              </>
                                                            ) : (
                                                              <>
                                                                <option value="">
                                                                  Select a product
                                                                </option>
                                                                {productList &&
                                                                  filterDataByServiceCategory(productList, item.serviceCategory).map(
                                                                    (item) => (
                                                                      <option
                                                                        value={
                                                                          item._id
                                                                        }
                                                                        key={
                                                                          item._id
                                                                        }
                                                                        disabled={
                                                                          !item.priceMarkup
                                                                        }
                                                                      >
                                                                        {
                                                                          item.name
                                                                        }
                                                                      </option>
                                                                    ),
                                                                  )}
                                                              </>
                                                            )}
                                                        </select>
                                                      </td>
                                                      <td
                                                        className="text-center"
                                                        style={{
                                                          width: '200px',
                                                        }}
                                                      >
                                                        {item.price
                                                          ? `NGN ${formatNumber(
                                                            item.price,
                                                          )}`
                                                          : null}
                                                      </td>
                                                      <td>{item.notes}</td>
                                                      <td
                                                        className=""
                                                        style={{
                                                          width: '200px',
                                                        }}
                                                      >
                                                        {item.total
                                                          ? `NGN ${formatNumber(
                                                            item.total,
                                                          )}`
                                                          : null}
                                                      </td>
                                                    </tr>
                                                  ))}
                                                  <tr className="bg-light">
                                                    <th
                                                      scope="row"
                                                      colSpan="6"
                                                      className="text-end"
                                                    >
                                                      Total :
                                                    </th>
                                                    <td className="bg-light text-center">
                                                      <b>
                                                        {totalSum
                                                          ? `NGN ${formatNumber(
                                                            totalSum,
                                                          )}`
                                                          : 'NGN 0'}
                                                      </b>
                                                    </td>
                                                  </tr>
                                                </>
                                              ) : null}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              {/* Standards Activities and protocols   */}
                              <section>
                                <div>
                                  <p>
                                    Read and agree to the Standards for this
                                    cycle
                                  </p>

                                  <div className="mt-4">
                                    <div className="card">
                                      <div
                                        className="card-body"
                                        style={{
                                          border: '1px solid #0d89c7',
                                          borderRadius: '10px',
                                        }}
                                      >
                                        <div className="standard-body">
                                          Farmsured Package Standard
                                        </div>

                                        <div className="row mt-4">
                                          <div className="col-md-3 mb-1">
                                            <div
                                              className="d-flex p-3 mt-4 mt-md-0"
                                              style={{
                                                borderRadius: '20px',
                                                backgroundColor: '#ff9800',
                                              }}
                                            >
                                              <div className="avatar-sm me-2">
                                                <span className="avatar-title bg-transparent rounded-circle text-white font-size-20">
                                                  <i className="fal fa-coins"></i>
                                                </span>
                                              </div>
                                              <div className="flex-1 align-self-center overflow-hidden ">
                                                <h6 className="text-white">
                                                  Expected Point
                                                </h6>
                                                <p className="text-white mb-0">
                                                  {expectedPoint ||
                                                    'Expected Point'}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-3 mb-1">
                                            <div
                                              className="d-flex bg-light p-3 mt-4 mt-md-0"
                                              style={{ borderRadius: '20px' }}
                                            >
                                              <div className="avatar-sm me-2">
                                                <span className="avatar-title bg-light rounded-circle text-success font-size-20">
                                                  <i className="fal fa-clock"></i>
                                                </span>
                                              </div>
                                              <div className="flex-1 align-self-center overflow-hidden">
                                                <h6>Cycle Duration</h6>
                                                <p className="text-muted mb-0">
                                                  {obj &&
                                                    obj.productCategory &&
                                                    obj.productCategory
                                                      .cycleDuration
                                                    ? `${obj.productCategory.cycleDuration} hrs`
                                                    : 'Cycle duration'}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <section>
                                          <div className="mt-2">
                                            <div className="row">
                                              <div className="col-md-4">
                                                <div className="container-card p-2">
                                                  <div className="row mt-2">
                                                    <div className="col-md-7">
                                                      <div className="container__text">
                                                        <h1 className="card-title">
                                                          RECORD ACTIVITY
                                                        </h1>

                                                        <p>
                                                          <span className="expected_task">
                                                            <b>
                                                              Expected Task:
                                                            </b>
                                                          </span>
                                                          <br />
                                                          You are expected to
                                                          meticulously document
                                                          the results of your
                                                          task while adhering to
                                                          compliance standards.
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-5 red">
                                                      <div
                                                        className=""
                                                        style={{
                                                          textAlign: 'center',
                                                        }}
                                                      >
                                                        <div className="icon_container align">
                                                          <div
                                                            style={{
                                                              fontSize: '50px',
                                                            }}
                                                          >
                                                            <i className="fa fa-file"></i>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="container__text">
                                                    <div className="">
                                                      <div className="row">
                                                        <div className="col-lg-4">
                                                          <h6>Occurence</h6>
                                                          <p>
                                                            {
                                                              recordActivityCount
                                                            }{' '}
                                                            Steps
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-md-4">
                                                <div
                                                  className="container-card p-2 text-white"
                                                  style={{
                                                    backgroundColor: '#505d69',
                                                  }}
                                                >
                                                  <div className="row mt-2">
                                                    <div className="col-md-6">
                                                      <div className="container__text">
                                                        <h1 className="card-title text-white">
                                                          SCAN ACTIVITY
                                                        </h1>

                                                        <p>
                                                          <span className="expected_task">
                                                            <b>
                                                              Expected Task:
                                                            </b>
                                                          </span>
                                                          <br />
                                                          You are expected to
                                                          validate the red tags
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6 red">
                                                      <div
                                                        className=""
                                                        style={{
                                                          textAlign: 'center',
                                                        }}
                                                      >
                                                        <div className="icon_container align">
                                                          <img
                                                            src="/assets/images/red_tag.png"
                                                            className="me-1"
                                                            alt="red tag"
                                                            style={{
                                                              width: '200px',
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="container__text">
                                                    <div className="row mt-3">
                                                      <div className="col-lg-5">
                                                        <h6 className="text-white">
                                                          Point per tag
                                                        </h6>
                                                        <p>
                                                          {scanPoint} points
                                                        </p>
                                                      </div>
                                                      <div className="col-lg-6">
                                                        <h6 className="text-white">
                                                          Verification Occurence
                                                        </h6>
                                                        <p>
                                                          {verifyLabelCount}{' '}
                                                          Tag(s)
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-md-4">
                                                <div
                                                  className="container-card p-2 text-white"
                                                  style={{
                                                    backgroundColor: '#505d69',
                                                  }}
                                                >
                                                  <div className="row mt-2">
                                                    <div className="col-md-6">
                                                      <div className="container__text">
                                                        <h1 className="card-title text-white">
                                                          SCAN ACTIVITY
                                                        </h1>

                                                        <p>
                                                          <span className="expected_task">
                                                            <b>
                                                              Expected Task:
                                                            </b>
                                                          </span>
                                                          <br />
                                                          You are expected to
                                                          validate the green
                                                          tags
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6 red">
                                                      <div
                                                        className=""
                                                        style={{
                                                          textAlign: 'center',
                                                        }}
                                                      >
                                                        <div className="icon_container align">
                                                          <img
                                                            src="assets/images/green_tag.png"
                                                            className="me-1"
                                                            alt="green tag"
                                                            style={{
                                                              width: '200px',
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="container__text">
                                                    <div className="row mt-3">
                                                      <div className="col-lg-5">
                                                        <h6 className="text-white">
                                                          Point per tag
                                                        </h6>
                                                        <p>
                                                          {scanPoint} points
                                                        </p>
                                                      </div>
                                                      <div className="col-lg-6">
                                                        <h6 className="text-white">
                                                          Activation Occurence
                                                        </h6>
                                                        <p>
                                                          {activateLabelCount}{' '}
                                                          Tag(s)
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-12">
                                              <div className="float-end mt-2">
                                                <button
                                                  className="btn btn-success"
                                                  onClick={() =>
                                                    setShowActivities(
                                                      !showActivities,
                                                    )
                                                  }
                                                >
                                                  {showActivities
                                                    ? `Hide Activities`
                                                    : `View Activities`}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </section>

                                        {showActivities ? (
                                          <div className="row">
                                            {activities && activities.length > 0
                                              ? activities.map((item) => (
                                                <div
                                                  className="col-md-4"
                                                  key={item._id}
                                                >
                                                  <div className="container-card p-2">
                                                    <div className="row mt-2">
                                                      <div className="col-md-7">
                                                        <div className="container__text">
                                                          <h1 className="card-title">
                                                            {
                                                              item.farmActivity
                                                            }
                                                          </h1>

                                                          <p>
                                                            <span className="expected_task">
                                                              <b>
                                                                Expected Task:
                                                              </b>
                                                            </span>
                                                            <br />
                                                            {
                                                              item.expectedTask
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="col-md-5 red">
                                                        {item.activity ? (
                                                          <div
                                                            className=""
                                                            style={{
                                                              textAlign:
                                                                'center',
                                                            }}
                                                          >
                                                            {item.tagColor ? (
                                                              <div className="pt-1 container__text__timing_time font-size-12">
                                                                {
                                                                  item.tagColor
                                                                }
                                                              </div>
                                                            ) : null}
                                                            <div className="icon_container align">
                                                              {item.activity ===
                                                                'Scan Activity' ? (
                                                                <div
                                                                  style={{
                                                                    fontSize:
                                                                      '50px',
                                                                  }}
                                                                >
                                                                  <i className="fa fa-qrcode"></i>
                                                                </div>
                                                              ) : (
                                                                <div
                                                                  className="mt-1"
                                                                  style={{
                                                                    fontSize:
                                                                      '50px',
                                                                  }}
                                                                >
                                                                  <i className="fa fa-file"></i>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                    <div className="container__text">
                                                      <div className="">
                                                        <div className="row">
                                                          <div className="col-lg-4">
                                                            <div className=" mr-2">
                                                              <h6>
                                                                Timeline
                                                              </h6>
                                                              <p>
                                                                {
                                                                  item.timeLineFrom
                                                                }{' '}
                                                                -{' '}
                                                                {
                                                                  item.timeLineTo
                                                                }{' '}
                                                                hrs
                                                              </p>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-4">
                                                            <h6>
                                                              Expected Point
                                                            </h6>
                                                            <p>
                                                              {`${item.point} points`}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ))
                                              : null}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <section>
                                  <div className="form-check mb-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="invalidCheck1"
                                      name="packageterms"
                                      required
                                      checked={packageTerms}
                                      onChange={handlePackageChange}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="invalidCheck1"
                                    >
                                      I confirm that {packageNum} package(s) is
                                      equal (=){' '}
                                      {`${obj.packageMinUnit &&
                                        obj.packageMinUnit.minUnit
                                        ? packageNum *
                                        obj.packageMinUnit.minUnit
                                        : 0
                                        } ${obj &&
                                          obj.packageMinUnit &&
                                          obj.packageMinUnit.measuringUnit
                                          ? obj.packageMinUnit.measuringUnit
                                          : 'unit'
                                        }`}
                                    </label>
                                  </div>

                                  <div className="form-check mb-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="invalidCheck2"
                                      name="cycleTerms"
                                      required
                                      checked={cycleTerms}
                                      onChange={handleCycleChange}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="invalidCheck2"
                                    >
                                      I am starting a farmcycle of{' '}
                                      {`${obj.packageMinUnit &&
                                        obj.packageMinUnit.minUnit
                                        ? packageNum *
                                        obj.packageMinUnit.minUnit
                                        : 0
                                        } ${obj &&
                                          obj.packageMinUnit &&
                                          obj.packageMinUnit.measuringUnit
                                          ? obj.packageMinUnit.measuringUnit
                                          : 'unit'
                                        }`}
                                    </label>
                                  </div>

                                  <div className="form-check mb-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="invalidCheck"
                                      name="terms"
                                      required
                                      checked={terms}
                                      onChange={handleChange}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="invalidCheck"
                                    >
                                      I agree to follow the above farm package
                                      standards and other terms and conditions
                                    </label>
                                  </div>
                                </section>
                              </section>

                              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <button
                                  className="btn btn-success"
                                  onClick={() => setShow(false)}
                                >
                                  Previous
                                </button>

                                <div className="page-title-right">
                                  <button
                                    className="btn btn-success float-end"
                                    onClick={() => (
                                      setShowEOP(true), setShow(false)
                                    )}
                                    disabled={
                                      !terms || !cycleTerms || !packageTerms
                                    }
                                  >
                                    Proceed
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : showEOP ? (
                  <section>
                    <div className="card">
                      <div className="card-body">
                        {/* Standard Economics of Production */}
                        <div className="card mt-5">
                          <div
                            className="card-body"
                            style={{
                              border: '1px solid #0d89c7',
                              borderRadius: '10px',
                            }}
                          >
                            <div className="standard-body">
                              Farmsured Standard Enterprise Economics Of
                              Production
                            </div>

                            <section>
                              <div className="row mt-4">
                                {/* First Column */}
                                <div className="col-md-3">
                                  <div
                                    className="card"
                                    style={{
                                      backgroundColor: '#ff5722',
                                      borderRadius: '10px',
                                    }}
                                  >
                                    <div className="card-body">
                                      <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                          <p className="text-white font-size-14 mb-2">
                                            Enterprise Name
                                          </p>
                                          <h5 className="mb-0 text-white">
                                            {obj &&
                                              obj.enterprise &&
                                              obj.enterprise.name
                                              ? obj.enterprise.name
                                              : 'Select farmsured enterprise'}
                                          </h5>
                                        </div>
                                        <div className="text-white ms-auto">
                                          <i className="fal fa-building font-size-24"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Second Column */}
                                <div className="col-md-3">
                                  <div
                                    className="card"
                                    style={{
                                      backgroundColor: '#8bc34a',
                                      borderRadius: '10px',
                                    }}
                                  >
                                    <div className="card-body">
                                      <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                          <p className="text-white font-size-14 mb-2">
                                            Package Name
                                          </p>
                                          <h5 className="mb-0 text-white">
                                            {obj && obj.packageName
                                              ? obj.packageName
                                              : 'Select farmsured package'}
                                          </h5>
                                        </div>
                                        <div className="text-white ms-auto">
                                          <i className="fal fa-tractor font-size-22"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Third Column */}
                                <div className="col-md-3">
                                  <div
                                    className="card"
                                    style={{
                                      backgroundColor: '#009688',
                                      borderRadius: '10px',
                                    }}
                                  >
                                    <div className="card-body">
                                      <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                          <p className="text-white font-size-14 mb-2">
                                            Production Size
                                          </p>
                                          <h5 className="mb-0 text-white">
                                            {productionSize}{' '}
                                            {obj &&
                                              obj.packageMinUnit &&
                                              obj.packageMinUnit.measuringUnit
                                              ? obj.packageMinUnit.measuringUnit
                                              : 'unit'}
                                          </h5>
                                        </div>
                                        <div className="text-white ms-auto">
                                          <i className="fal fa-building font-size-24"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>

                            <div className="">
                              <div className="table-responsive">
                                <table className="table table-centered mb-0 table-nowrap">
                                  <thead className="bg-light">
                                    <tr>
                                      <th>Item</th>
                                      <th className="text-center">Quantity</th>
                                      <th>Unit of Measurement</th>
                                      <th>Product</th>
                                      <th className="text-center">Price</th>
                                      <th>Note</th>
                                      <th>Total (Logistics included)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {eop.length > 0 ? (
                                      <>
                                        {eop.map((item, i) => (
                                          <tr>
                                            <td>{item.EOPItem}</td>
                                            <td className="text-center">
                                              {item.quantity * packageNum}
                                            </td>
                                            <td>{item.UoM}</td>
                                            <td
                                              className="text-center"
                                              style={{
                                                width: '300px',
                                              }}
                                            >
                                              <select
                                                id="product"
                                                name="product"
                                                className="form-control form-select select2"
                                                required
                                                onChange={(e) =>
                                                  handleinputchange(
                                                    {
                                                      name: e.target.name,
                                                      value: e.target.value,
                                                    },
                                                    i,
                                                  )
                                                }
                                                value={item.selectedProduct}
                                              >
                                                {productList &&
                                                  productList.length === 0 ? (
                                                  <>
                                                    <option>
                                                      Select a product
                                                    </option>
                                                  </>
                                                ) : (
                                                  <>
                                                    <option value="">
                                                      Select a product
                                                    </option>
                                                    {
                                                      productList &&
                                                      filterDataByServiceCategory(productList, item.serviceCategory).map(
                                                        (item) => (
                                                          <option
                                                            value={item._id}
                                                            key={item._id}
                                                            disabled={
                                                              !item.price
                                                            }
                                                          >
                                                            {item.name}
                                                          </option>
                                                        ),
                                                      )}
                                                  </>
                                                )}
                                              </select>
                                            </td>
                                            <td
                                              className="text-center"
                                              style={{
                                                width: '200px',
                                              }}
                                            >
                                              {item.price
                                                ? `NGN ${formatNumber(
                                                  item.price,
                                                )}`
                                                : null}
                                            </td>
                                            <td>{item.notes}</td>
                                            <td
                                              className=""
                                              style={{
                                                width: '200px',
                                              }}
                                            >
                                              {item.total
                                                ? `NGN ${formatNumber(
                                                  item.total,
                                                )}`
                                                : null}
                                            </td>
                                          </tr>
                                        ))}
                                        <tr className="bg-light">
                                          <th
                                            scope="row"
                                            colSpan="6"
                                            className="text-end"
                                          >
                                            Total :
                                          </th>
                                          <td className="bg-light text-center">
                                            <b>
                                              {totalSum
                                                ? `NGN ${formatNumber(
                                                  totalSum,
                                                )}`
                                                : 'NGN 0'}
                                            </b>
                                          </td>
                                        </tr>
                                      </>
                                    ) : null}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-3">
                            <div className="card">
                              <div className="card-body bg-soft-primary">
                                <p className="m-0">
                                  <b>
                                    Explore and enhance your Economics of
                                    Production within our web app! You're
                                    invited to seamlessly incorporate additional
                                    elements to optimize your enterprise's
                                    production economics.
                                  </b>
                                </p>

                                <p className="mt-2 mb-0">
                                  <b>
                                    Start enhancing your production economics by
                                    integrating supplementary Economics of
                                    Production components.
                                  </b>
                                </p>
                              </div>
                            </div>

                            <div className="col-lg-12 mb-3">
                              <label
                                htmlFor="example-text-input"
                                className="col-md-12 col-form-label"
                              >
                                Add any other Economics of Production to this
                                Enterprise:
                              </label>
                              {list.map((item, i) => {
                                return (
                                  <div key={i}>
                                    <div className="row">
                                      <div className="form-group col-md-12 mb-2">
                                        <div className="card bg-soft-info">
                                          <div className="card-body p-2">
                                            <p className="m-0 p-1">
                                              Item {i + 1}
                                            </p>
                                          </div>
                                        </div>
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-12 col-form-label"
                                        >
                                          EOP Item/Activity
                                        </label>
                                        <input
                                          className="form-control"
                                          type="text"
                                          name="EOPItem"
                                          placeholder="EOP Item/Activity"
                                          id="example-text-input"
                                          onChange={(e) =>
                                            handleFormChange(
                                              {
                                                name: e.target.name,
                                                value: e.target.value,
                                              },
                                              i,
                                            )
                                          }
                                          required
                                          value={item.EOPItem}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="form-group col-md-6 mb-2">
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-12 col-form-label"
                                        >
                                          Quantity
                                        </label>
                                        <input
                                          className="form-control"
                                          name="quantity"
                                          type="number"
                                          placeholder="Quantity"
                                          id="example-text-input"
                                          onChange={(e) =>
                                            handleFormChange(
                                              {
                                                name: e.target.name,
                                                value: e.target.value,
                                              },
                                              i,
                                            )
                                          }
                                          required
                                        />
                                      </div>
                                      <div className="form-group col-md-6 mb-2">
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-12 col-form-label"
                                        >
                                          Unit of Measurement
                                        </label>
                                        <select
                                          className="form-control form-select select2"
                                          name="UoM"
                                          placeholder="Unit of measurement"
                                          id="example-text-input"
                                          onChange={(e) =>
                                            handleFormChange(
                                              {
                                                name: e.target.name,
                                                value: e.target.value,
                                              },
                                              i,
                                            )
                                          }
                                          required
                                          value={item.UoM}
                                        >
                                          <option>Select measuring unit</option>
                                          <option value="Birds">Birds</option>
                                          <option value="Box(es)">
                                            Box(es)
                                          </option>
                                          <option value="Kg">kg</option>
                                          <option value="Units">Units</option>
                                          <option value="Animals">
                                            Animals
                                          </option>
                                          <option value="Bags">Bags</option>
                                          <option value="Tonnes">Tonnes</option>
                                          <option value="Litres">Litres</option>
                                          <option value="Items">Items</option>
                                          <option value="Hectares">
                                            Hectares
                                          </option>
                                          <option value="Acre">Acre</option>
                                        </select>
                                      </div>
                                      <div className="form-group col-md-6 mb-2">
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-12 col-form-label"
                                        >
                                          EOP Item/Activity Type
                                        </label>
                                        <select
                                          onChange={(e) =>
                                            handleFormChange(
                                              {
                                                name: e.target.name,
                                                value: e.target.value,
                                              },
                                              i,
                                            )
                                          }
                                          className="form-control form-select select-2"
                                          required
                                          name="EOPItemType"
                                          placeholder="Select product/service"
                                          value={item.EOPItemType}
                                        >
                                          <option value="">
                                            Select a product/service
                                          </option>
                                          <option value="Product">
                                            Product
                                          </option>
                                          <option value="Service">
                                            Service
                                          </option>
                                        </select>
                                      </div>
                                      <div className="form-group col-md-6 mb-2">
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-12 col-form-label"
                                        >
                                          Price
                                        </label>
                                        <input
                                          className="form-control"
                                          name="price"
                                          type="number"
                                          placeholder="Price"
                                          id="example-text-input"
                                          onChange={(e) =>
                                            handleFormChange(
                                              {
                                                name: e.target.name,
                                                value: e.target.value,
                                              },
                                              i,
                                            )
                                          }
                                          required
                                          value={item.price}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="form-group col-md-9">
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-12 col-form-label"
                                        >
                                          Notes
                                        </label>
                                        <textarea
                                          type="text"
                                          name="notes"
                                          className="form-control"
                                          onChange={(e) =>
                                            handleFormChange(
                                              {
                                                name: e.target.name,
                                                value: e.target.value,
                                              },
                                              i,
                                            )
                                          }
                                          placeholder="Notes"
                                          value={item.notes}
                                        />
                                      </div>

                                      <div className="form-group col-md-3 mt-4">
                                        {list.length !== 1 && (
                                          <button
                                            className="btn btn-danger mx-1"
                                            type="button"
                                            onClick={() => handleremove(i)}
                                          >
                                            Delete
                                          </button>
                                        )}
                                        {list.length - 1 === i && (
                                          <button
                                            className="btn btn-success m-2"
                                            onClick={handleAddClick}
                                          >
                                            Save
                                          </button>
                                        )}
                                      </div>
                                      <hr className="mt-4 mb-3 my-4" />
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                          <div className="col-lg-9">
                            {/* Additional Economics of Production */}
                            <div className="card mt-5">
                              <div
                                className="card-body"
                                style={{
                                  border: '1px solid #0d89c7',
                                  borderRadius: '10px',
                                }}
                              >
                                <div className="standard-body">
                                  Additional Economics Of Production
                                </div>

                                <div className="mt-4">
                                  <div className="table-responsive">
                                    <table className="table table-centered mb-0 table-nowrap">
                                      <thead className="bg-light">
                                        <tr>
                                          <th>Item</th>
                                          <th className="text-center">
                                            Quantity
                                          </th>
                                          <th>Unit of Measurement</th>
                                          <th>E.O.P Item Type</th>
                                          <th className="text-center">Price</th>
                                          <th>Note</th>
                                          <th>Total</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {list.length > 0 ? (
                                          <>
                                            {list.map((item, i) => (
                                              <tr>
                                                <td>{item.EOPItem}</td>
                                                <td className="text-center">
                                                  {item.quantity}
                                                </td>
                                                <td>{item.UoM}</td>
                                                <td className="">
                                                  {item.EOPItemType}
                                                </td>
                                                <td
                                                  className="text-center"
                                                  style={{
                                                    width: '50px',
                                                  }}
                                                >
                                                  {item.price
                                                    ? `NGN ${formatNumber(
                                                      item.price,
                                                    )}`
                                                    : null}
                                                </td>
                                                <td
                                                  style={{
                                                    width: '200px',
                                                  }}
                                                >
                                                  {item.notes}
                                                </td>
                                                <td
                                                  className=""
                                                  style={{
                                                    width: '200px',
                                                  }}
                                                >
                                                  {item.total
                                                    ? `NGN ${formatNumber(
                                                      item.total,
                                                    )}`
                                                    : null}
                                                </td>
                                              </tr>
                                            ))}
                                            <tr className="bg-light">
                                              <th
                                                scope="row"
                                                colSpan="6"
                                                className="text-end"
                                              >
                                                Total :
                                              </th>
                                              <td className="bg-light text-center">
                                                <b>
                                                  {additionalEoPTotalSum
                                                    ? `NGN ${formatNumber(
                                                      additionalEoPTotalSum,
                                                    )}`
                                                    : 'NGN 0'}
                                                </b>
                                              </td>
                                            </tr>
                                          </>
                                        ) : null}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="card">
                              <div className="card-body">
                                <div className="p-3 bg-light">
                                  <h5 className="font-size-14 mb-0">
                                    Economics of Production Summary
                                  </h5>
                                </div>
                                <div className="table-responsive">
                                  <table className="table table-centered mb-0 table-nowrap">
                                    <thead>
                                      <tr>
                                        <th
                                          className="border-top-0"
                                          style={{ width: '100%' }}
                                        >
                                          Item
                                        </th>
                                        <th className="border-top-0 text-end">
                                          Total
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <h5 className="font-size-14 text-truncate">
                                            Farmsured Enterprise Standard
                                            Economics of Production
                                          </h5>
                                        </td>
                                        <td className="text-end">
                                          {totalSum
                                            ? `NGN ${formatNumber(totalSum)}`
                                            : 'NGN 0'}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <h5 className="font-size-14 text-truncate">
                                            Additional Economics of Production
                                          </h5>
                                        </td>
                                        <td className="text-end">
                                          {additionalEoPTotalSum
                                            ? `NGN ${formatNumber(
                                              additionalEoPTotalSum,
                                            )}`
                                            : 'NGN 0'}
                                        </td>
                                      </tr>
                                      <tr className="bg-light">
                                        <td colSpan="2">
                                          <h6 className="m-0 text-end">
                                            Sub Total:
                                          </h6>
                                        </td>
                                        <td>
                                          {getEopTotalSum
                                            ? `NGN ${formatNumber(
                                              getEopTotalSum,
                                            )}`
                                            : 'NGN 0'}
                                        </td>
                                      </tr>
                                      <tr className="bg-light">
                                        <td colSpan="2">
                                          <h6 className="m-0 text-end">
                                            Total:
                                          </h6>
                                        </td>
                                        <td>
                                          {getEopTotalSum
                                            ? `NGN ${formatNumber(
                                              getEopTotalSum,
                                            )}`
                                            : 'NGN 0'}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                          <button
                            className="btn btn-success"
                            onClick={() => (setShow(true), setShowEOP(false))}
                          >
                            Previous
                          </button>

                          <div className="page-title-right">
                            <button
                              className="btn btn-success float-end"
                              onClick={() => (
                                setEopSummary(true), setShowEOP(false)
                              )}
                              disabled={!terms || !cycleTerms || !packageTerms}
                            >
                              Proceed
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : eopSummary ? (
                  <div className="card">
                    <div className="card-body">
                      <div className="card mt-4">
                        <div
                          className="card-body"
                          style={{
                            border: '1px solid #0d89c7',
                            borderRadius: '10px',
                          }}
                        >
                          <div className="standard-body">
                            Enterprise Breakdown
                          </div>

                          <section>
                            <div className="row mt-4">
                              {/* First Column */}
                              <div className="col-md-3">
                                <div
                                  className="card"
                                  style={{
                                    backgroundColor: '#9e9e9e',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <div className="flex-1 overflow-hidden">
                                        <p className="text-white font-size-14 mb-2">
                                          Enterprise Name
                                        </p>
                                        <h5 className="mb-0 text-white">
                                          {obj &&
                                            obj.enterprise &&
                                            obj.enterprise.name
                                            ? obj.enterprise.name
                                            : 'Select farmsured enterprise'}
                                        </h5>
                                      </div>
                                      <div className="text-white ms-auto">
                                        <i className="fal fa-building font-size-24"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Second Column */}
                              <div className="col-md-3">
                                <div
                                  className="card"
                                  style={{
                                    backgroundColor: '#8A2BE2',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <div className="flex-1 overflow-hidden">
                                        <p className="text-white font-size-14 mb-2">
                                          Production Size
                                        </p>
                                        <h5 className="mb-0 text-white">
                                          {productionSize}{' '}
                                          {obj &&
                                            obj.packageMinUnit &&
                                            obj.packageMinUnit.measuringUnit
                                            ? obj.packageMinUnit.measuringUnit
                                            : 'unit'}
                                        </h5>
                                      </div>
                                      <div className="text-white ms-auto">
                                        <i className="fal fa-cube font-size-24"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Third Column */}
                              <div className="col-md-3">
                                <div
                                  className="card"
                                  style={{
                                    backgroundColor: '#3498db',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <div className="flex-1 overflow-hidden">
                                        <p className="text-white font-size-14 mb-2">
                                          Production Timeline
                                        </p>
                                        <h5 className="mb-0 text-white">
                                          {productionTimeline
                                            ? `${productionTimeline} Day(s)`
                                            : '0 Day(s)'}
                                        </h5>
                                      </div>
                                      <div className="text-white ms-auto">
                                        <i className="fal fa-calendar-alt font-size-22"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Fourth Column */}
                              <div className="col-md-3">
                                <div
                                  className="card"
                                  style={{
                                    backgroundColor: '#009688',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <div className="flex-1 overflow-hidden">
                                        <p className="text-white font-size-14 mb-2">
                                          Enterprise Profitability R.O.I (%)
                                        </p>
                                        <h5 className="mb-0 text-white">
                                          {profitabilityROI
                                            ? `${profitabilityROI}%`
                                            : '0%'}
                                        </h5>
                                      </div>
                                      <div className="text-white ms-auto">
                                        <i className="fal fa-hand-holding-usd font-size-22"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          <div className="card">
                            <div className="card-body bg-soft-primary">
                              <h5 className="card-title">
                                Enterprise breakdown based on your Economics of
                                Production
                              </h5>

                              <p className="mb-1">
                                <b>
                                  Explore a comprehensive enterprise breakdown,
                                  leveraging the Economics of Production. <br />
                                  Gain insights, optimize processes, and drive
                                  efficiency in your business with data-driven
                                  analysis.
                                </b>
                              </p>
                            </div>
                          </div>

                          <div className="">
                            <div className="row">
                              <div className="col-md-4">
                                <div
                                  className="card"
                                  style={{
                                    backgroundColor: '#ff5722',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <div className="card-body">
                                    <div className="d-flex">
                                      <div className="flex-1 overflow-hidden">
                                        <p className="text-white font-size-14 mb-2">
                                          Aver. Percentage Loss(%)
                                        </p>
                                        <h5 className="mb-0 text-white">
                                          {obj &&
                                            obj.averageLoss &&
                                            obj.averageLoss.qty
                                            ? obj.averageLoss.qty
                                            : 'One'}{' '}
                                          {obj &&
                                            obj.averageLoss &&
                                            obj.averageLoss.measuringUnit
                                            ? obj.averageLoss.measuringUnit
                                            : 'unit'}
                                        </h5>
                                      </div>
                                      <div className="text-white ms-auto">
                                        <i className="fal fa-chart-line font-size-24"></i>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="card-body border-top py-3">
                                    <div className="">
                                      <span className="text-white font-size-12">
                                        This is the recommended industry
                                        standard of produces loss/mortality
                                        during the production.
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="">
                              <p className="mb-0 font-size-16">
                                <b>
                                  What is your projected quantity/mortality of
                                  loss
                                </b>
                              </p>
                              <div className="row">
                                <div className="form-group col-md-4 mb-2">
                                  <label
                                    htmlFor="example-text-input"
                                    className="col-md-12 col-form-label"
                                  >
                                    Quantity/Mortality of Loss
                                  </label>
                                  <input
                                    className="form-control"
                                    name="sellAmount"
                                    type="number"
                                    placeholder="Quantity/Mortality of Loss"
                                    id="example-text-input"
                                    onChange={(e) =>
                                      setLossAmount(e.target.value)
                                    }
                                    required
                                    value={lossAmount}
                                  />
                                </div>
                                <div className="form-group col-md-4 mb-2">
                                  <label
                                    htmlFor="example-text-input"
                                    className="col-md-12 col-form-label"
                                  >
                                    Unit of Measurement
                                  </label>
                                  <select
                                    className="form-control form-select select2"
                                    name="UoM"
                                    placeholder="Unit of measurement"
                                    id="example-text-input"
                                    onChange={(e) => setLossUoM(e.target.value)}
                                    value={lossUoM}
                                    required
                                  >
                                    <option>Select measuring unit</option>
                                    <option value="Birds">Birds</option>
                                    <option value="Box(es)">Box(es)</option>
                                    <option value="Kg">kg</option>
                                    <option value="Units">Units</option>
                                    <option value="Animals">Animals</option>
                                    <option value="Bags">Bags</option>
                                    <option value="Tonnes">Tonnes</option>
                                    <option value="Litres">Litres</option>
                                    <option value="Items">Items</option>
                                    <option value="Hectares">Hectares</option>
                                    <option value="Acre">Acre</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="mt-3">
                              <p className="mb-0 font-size-16">
                                <b>
                                  At harvest, How much would you sell your final
                                  produces?
                                </b>
                              </p>

                              <div className="row">
                                <div className="form-group col-md-4 mb-2 mt-0">
                                  <label
                                    htmlFor="example-text-input"
                                    className="col-md-12 col-form-label"
                                  >
                                    Amount
                                  </label>
                                  <input
                                    className="form-control"
                                    name="sellAmount"
                                    type="number"
                                    placeholder="Sell Amount"
                                    id="example-text-input"
                                    onChange={(e) =>
                                      setSellAmount(e.target.value)
                                    }
                                    value={sellAmount}
                                    required
                                  />
                                </div>
                                <div className="form-group col-md-4 mb-2">
                                  <label
                                    htmlFor="example-text-input"
                                    className="col-md-12 col-form-label"
                                  >
                                    Unit of Sale (per)
                                  </label>
                                  <select
                                    className="form-control form-select select2"
                                    name="UoM"
                                    placeholder="Unit of measurement"
                                    id="example-text-input"
                                    onChange={(e) => setSellUoS(e.target.value)}
                                    value={sellUoS}
                                    required
                                  >
                                    <option>Select measuring unit</option>
                                    <option value="Birds">Birds</option>
                                    <option value="Box(es)">Box(es)</option>
                                    <option value="Kg">kg</option>
                                    <option value="Units">Units</option>
                                    <option value="Animals">Animals</option>
                                    <option value="Bags">Bags</option>
                                    <option value="Tonnes">Tonnes</option>
                                    <option value="Litres">Litres</option>
                                    <option value="Items">Items</option>
                                    <option value="Hectares">Hectares</option>
                                    <option value="Acre">Acre</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="mt-3">
                              <h6>
                                No of produce ={' '}
                                {`${productionSize} ${obj &&
                                  obj.packageMinUnit &&
                                  obj.packageMinUnit.measuringUnit
                                  ? obj.packageMinUnit.measuringUnit
                                  : 'unit'
                                  }`}{' '}
                              </h6>
                              <h6>
                                % of produce/mortality loss ={' '}
                                {`${lossAmount} ${lossUoM ? lossUoM : 'unit'}`}
                              </h6>
                              <span className="m-0">
                                -----------------------------------------
                              </span>
                              <h6 className="m-0 font-size-15">
                                Produce after loss ={' '}
                                {produceAfterLoss
                                  ? formatNumber(produceAfterLoss)
                                  : '0'}
                              </h6>
                              <span className="m-0">
                                -----------------------------------------
                              </span>
                              <h4 className="mt-4 font-size-15">
                                Your sell price ={' '}
                                {sellAmount
                                  ? `NGN ${formatNumber(sellAmount)} ${sellUoS}`
                                  : 'NGN 0'}
                              </h4>
                              <h4 className="font-size-15">
                                Total sell of produces ={' '}
                                {totalProduceSold
                                  ? `NGN ${formatNumber(totalProduceSold)}`
                                  : 'NGN 0'}
                              </h4>

                              <h4 className="mt-3 font-size-15">
                                Production Cost ={' '}
                                {getEopTotalSum
                                  ? `NGN ${formatNumber(getEopTotalSum)}`
                                  : 'NGN 0'}
                              </h4>

                              <span className="m-0">
                                -----------------------------------------
                              </span>
                              <h4 className="m-0 font-size-18">
                                <b>
                                  Profit ={' '}
                                  {profit
                                    ? `NGN ${formatNumber(profit)}`
                                    : 'NGN 0'}
                                </b>
                              </h4>
                              <span className="m-0">
                                ------------------------------------------------------------------
                              </span>
                              <h4 className="m-0 font-size-18">
                                <b>
                                  Enterprise Profitability R.O.I (%) ={' '}
                                  {profitabilityROI
                                    ? `${profitabilityROI}%`
                                    : '0%'}
                                </b>
                              </h4>
                              <span className="m-0">
                                ------------------------------------------------------------------
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <button
                          className="btn btn-success"
                          onClick={() => (
                            setShowEOP(true), setEopSummary(false)
                          )}
                        >
                          Previous
                        </button>

                        <div className="page-title-right">
                          <button
                            className="btn btn-success float-end"
                            onClick={() => (
                              setShowCountDown(true), setEopSummary(false)
                            )}
                            disabled={!terms || !cycleTerms || !packageTerms}
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : showCountDown ? (
                  <div className="card">
                    <div className="card-body">
                      {createdCycle ? (
                        <MessageBox variant="success">
                          {createdCycle.message}
                        </MessageBox>
                      ) : null}
                      {error ? (
                        <MessageBox variant="danger">{error}</MessageBox>
                      ) : null}
                      <div className="mb-4">
                        <div className="row">
                          <div className="col-md-4"></div>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-4">
                                {obj && obj.packageImg ? (
                                  <div className="count-image">
                                    <img
                                      src={obj.packageImg}
                                      alt=""
                                      className="count-down-image"
                                      style={{
                                        maxWidth: '100%',
                                        margin: 'auto',
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="img-placeholder">
                                    <img
                                      src="/assets/images/farmsured_icon.png"
                                      alt=""
                                      className="count-down-image"
                                      style={{
                                        maxWidth: '100%',
                                        margin: 'auto',
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="col-md-8 mt-5">
                                <div className="card">
                                  <div
                                    className="card-body"
                                    style={{
                                      border: '1px solid #0d89c7',
                                      borderRadius: '10px',
                                    }}
                                  >
                                    <div className="standard-body">
                                      Countdown to Start
                                    </div>

                                    <div className="mt-4">
                                      <div className="row">
                                        {/* First Column */}
                                        <div className="col-md-2">
                                          <div className="card">
                                            <div className="card-body">
                                              <i
                                                className="fal fa-id-card"
                                                style={{
                                                  fontSize: '34px',
                                                  width: '100%',
                                                  margin: 'auto',
                                                  textAlign: 'center',
                                                  padding: '12px',
                                                }}
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="card">
                                            <div className="card-body">
                                              <p className="font-size-13">
                                                Cycle Name
                                              </p>

                                              <h4 className="card-title font-size-16">
                                                {name
                                                  ? name
                                                  : 'Name your farmcycle'}
                                              </h4>
                                            </div>
                                          </div>
                                        </div>

                                        {/* Second column */}
                                        <div className="col-md-2">
                                          <div className="card">
                                            <div className="card-body">
                                              <i
                                                className="fal fa-building"
                                                style={{
                                                  fontSize: '39px',
                                                  width: '100%',
                                                  margin: 'auto',
                                                  textAlign: 'center',
                                                  padding: '12px',
                                                }}
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="card">
                                            <div className="card-body">
                                              <p className="font-size-13">
                                                Enterprise Package Name
                                              </p>

                                              <h4 className="card-title font-size-16">
                                                {obj &&
                                                  obj.enterprise &&
                                                  obj.enterprise.name
                                                  ? obj.enterprise.name
                                                  : 'Select farmsured package'}
                                              </h4>
                                            </div>
                                          </div>
                                        </div>

                                        {/* Third column */}
                                        <div className="col-md-2">
                                          <div className="card">
                                            <div className="card-body">
                                              <i
                                                className="fal fa-badge-check"
                                                style={{
                                                  fontSize: '39px',
                                                  width: '100%',
                                                  margin: 'auto',
                                                  textAlign: 'center',
                                                  padding: '12px',
                                                }}
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="card">
                                            <div className="card-body">
                                              <p className="font-size-13">
                                                Package Name
                                              </p>

                                              <h4 className="card-title font-size-16">
                                                {obj && obj.packageName
                                                  ? obj.packageName
                                                  : 'Select farmsured package'}
                                              </h4>
                                            </div>
                                          </div>
                                        </div>

                                        {/* Fourth Column */}
                                        <div className="col-md-2">
                                          <div className="card">
                                            <div className="card-body">
                                              <i
                                                className="fal fa-hourglass-start"
                                                style={{
                                                  fontSize: '39px',
                                                  width: '100%',
                                                  margin: 'auto',
                                                  textAlign: 'center',
                                                  padding: '12px',
                                                }}
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="card">
                                            <div className="card-body">
                                              <p className="font-size-13">
                                                Countdown
                                              </p>

                                              <h4 className="card-title font-size-16">
                                                {today.isSame(
                                                  moment(startDate),
                                                  'day',
                                                ) ? (
                                                  'Today'
                                                ) : (
                                                  <Countdown
                                                    date={`${startDate}T00:00:00`}
                                                  />
                                                )}
                                              </h4>
                                            </div>
                                          </div>
                                        </div>

                                        {/* Fourth Column */}
                                        <div className="col-md-12">
                                          <div className="d-grid mb-1">
                                            <button
                                              className="btn btn-success btn-lg"
                                              onClick={handleSave}
                                            >
                                              Create Farm Cycle
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <button
                          className="btn btn-success"
                          onClick={() => (
                            setShowCountDown(false), setEopSummary(true)
                          )}
                        >
                          Previous
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-10">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  forHtml="ProductName"
                                >
                                  Farmsured Enterprise Package
                                </label>
                                <select
                                  id="package"
                                  name="package"
                                  className="form-control select2"
                                  required
                                  onChange={(e) => setPackageId(e.target.value)}
                                  value={packageId}
                                >
                                  {loadingPackage ? (
                                    <>Loading...</>
                                  ) : packages && packages.length === 0 ? (
                                    <>
                                      <option>
                                        Select a Farmsured Enterprise Package
                                      </option>
                                    </>
                                  ) : (
                                    <>
                                      <option value="">
                                        Select a Farmsured Enterprise Package
                                      </option>
                                      {packages &&
                                        packages.map((item) => (
                                          <option
                                            value={item._id}
                                            key={item._id}
                                          >
                                            {item.enterprise &&
                                              item.enterprise.name}
                                          </option>
                                        ))}
                                    </>
                                  )}
                                </select>
                                <small>
                                  A Farmsured Enterprise Package is a industry
                                  vetted Economics of Production
                                </small>
                              </div>
                            </div>

                            <div className="col-10">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  forHtml="ProductName"
                                >
                                  Farmsured Protocol Package
                                </label>
                                <input
                                  id="packageName"
                                  name="packageName"
                                  className="form-control select2"
                                  required
                                  value={obj && obj.packageName}
                                  placeholder="Package Name"
                                  disabled
                                />
                              </div>
                            </div>

                            <div className="col-10">
                              <div
                                className="bg-warning p-3"
                                style={{ borderRadius: '12px' }}
                              >
                                <p className="text-dark mb-0">
                                  Note: A Farmsured Enterprise Package for{' '}
                                  {obj && obj.enterprise && obj.enterprise.name
                                    ? obj.enterprise.name
                                    : 'farmsured enterprise'}{' '}
                                  equals (=){' '}
                                  {obj &&
                                    obj.packageMinUnit &&
                                    obj.packageMinUnit.minUnit
                                    ? obj.packageMinUnit.minUnit
                                    : 'One'}{' '}
                                  {obj &&
                                    obj.packageMinUnit &&
                                    obj.packageMinUnit.measuringUnit
                                    ? obj.packageMinUnit.measuringUnit
                                    : 'unit'}
                                </p>

                                <p className="text-dark mb-0 mt-3">
                                  The following are your economics of production
                                  (E.O.P){' '}
                                </p>

                                <div className="mt-3">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="table-responsive-xl">
                                        <table className="table table-centered mb-0 table-nowrap">
                                          <thead className="bg-light">
                                            <tr>
                                              <th>Item </th>
                                              <th>Quantity</th>
                                              <th>Unit of Measurement</th>
                                              <th>Note</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {obj &&
                                              obj.enterprise &&
                                              obj.enterprise.EOP &&
                                              obj.enterprise.EOP.length > 0 ? (
                                              <>
                                                {obj.enterprise.EOP.map(
                                                  (item) => (
                                                    <tr>
                                                      <td>{item.EOPItem}</td>
                                                      <td>{item.quantity}</td>
                                                      <td>{item.UoM}</td>
                                                      <td>{item.notes}</td>
                                                    </tr>
                                                  ),
                                                )}
                                              </>
                                            ) : null}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-10">
                              <div className="mb-3 mt-3">
                                <label
                                  className="form-label"
                                  forHtml="ProductName"
                                >
                                  Inline with the above, How many Farmsured
                                  Enterprise Package unit(s) of{' '}
                                  {obj && obj.enterprise && obj.enterprise.name
                                    ? obj.enterprise.name
                                    : 'farmsured enterprise'}{' '}
                                  do you want to subscribe to? (E.g 1 ={' '}
                                  {obj &&
                                    obj.packageMinUnit &&
                                    obj.packageMinUnit.minUnit
                                    ? obj.packageMinUnit.minUnit
                                    : 'One'}{' '}
                                  {obj &&
                                    obj.packageMinUnit &&
                                    obj.packageMinUnit.measuringUnit
                                    ? obj.packageMinUnit.measuringUnit
                                    : 'unit'}
                                  )
                                </label>
                                <input
                                  id="packageNum"
                                  name="packageNum"
                                  type="number"
                                  className="form-control"
                                  required
                                  onChange={(e) =>
                                    setPackageNum(e.target.value)
                                  }
                                  placeholder="Number of packages"
                                  value={packageNum}
                                />
                              </div>
                            </div>

                            <div className="col-10">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  forHtml="ProductName"
                                >
                                  Farm Cycle Name
                                </label>
                                <input
                                  id="name"
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  required
                                  onChange={(e) => setName(e.target.value)}
                                  placeholder="Name"
                                  value={name}
                                />
                                <small>
                                  Give this cycle a name for identification
                                </small>
                              </div>
                            </div>

                            <div className="col-10">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  forHtml="ProductName"
                                >
                                  Pick a starting date
                                </label>
                                <input
                                  id="date"
                                  name="date"
                                  type="date"
                                  className="form-control"
                                  required
                                  min={moment().format('YYYY-MM-DD')}
                                  onChange={(e) => setStartDate(e.target.value)}
                                  placeholder="Date"
                                  value={startDate}
                                />
                                <small>
                                  Select a date you want to start your Cycle
                                </small>
                              </div>
                            </div>
                          </div>
                          <div className="float-end">
                            <button
                              className="btn btn-success"
                              onClick={verifyTab}
                              disabled={
                                !packageId || !name || !startDate || !packageNum
                              }
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {alert}
      </div>
      <Footer />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <div className="modal-header">
          <h5 className="modal-title">Postpone Farm Cycle</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setOpen(false)}
          ></button>
        </div>
        <DialogContent className="dialog_content">
          <div className="">
            <form onSubmit={handleSave}>
              <div className="row mb-3">
                <label
                  htmlFor="example-text-input"
                  className="col-md-12 col-form-label"
                >
                  Start Date
                </label>
                <div className="col-md-12">
                  <input
                    id="date"
                    name="date"
                    type="date"
                    className="form-control"
                    min={moment().format('YYYY-MM-DD')}
                    required
                    onChange={(e) => setStartDate(e.target.value)}
                    placeholder="Date"
                    value={startDate}
                  />
                </div>
              </div>

              <DialogActions>
                <button
                  className="btn btn-danger btn-bg"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button className="btn btn-success btn-bg" type="submit">
                  Save
                </button>
              </DialogActions>
            </form>
          </div>
        </DialogContent>
      </Dialog>

      {/* ========================================
      ============================================
      ==========Review Cycle Dialog ==============
      ============================================
      ======================================== */}
      <Dialog
        open={reviewCycleDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title">Review Farm Cycle Detail</h5>
        </div>
        <DialogContent>
          {loadingReview ? (
            <div className="tailspin" style={{ height: 'calc(40vh - 50px)' }}>
              <TailSpin stroke="#98ff98" strokeWidth="3" color="#06bcee" />
            </div>
          ) : reviewError ? (
            <MessageBox variant="danger">{reviewError} </MessageBox>
          ) : (
            <div className="">
              {review && <AnalysisTyping text={review} />}

              <div className="card" style={{ background: '#0e331c' }}>
                <div className="card-body">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="invalidCheck"
                      name="terms"
                      required
                      checked={reviewTerms}
                      onChange={handleReviewTermsChange}
                    />
                    <label
                      className="form-check-label text-white"
                      htmlFor="invalidCheck"
                    >
                      I agree to follow the above farm package standards and
                      other terms and conditions
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
          <DialogActions>
            {reviewTerms ? (
              <a href="/user/all-farm-cycle" className="btn btn-success btn-bg">
                Proceed
              </a>
            ) : (
              <button className="btn btn-success btn-bg" disabled>
                Proceed
              </button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CreateFarmCyclePage
