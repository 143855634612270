import {
  APPLICATION_FAIL,
  APPLICATION_REQUEST,
  APPLICATION_SUCCESS,
  APPLICATION_RESET,
} from '../../constants/authConstants/applicationContants'

function applicationReducer(state = {}, action) {
  switch (action.type) {
    case APPLICATION_REQUEST:
      return { loading: true }
    case APPLICATION_SUCCESS:
      return { loading: false, application: action.payload }
    case APPLICATION_FAIL:
      return { loading: false, error: action.payload }
    case APPLICATION_RESET:
      return {}
    default:
      return state
  }
}

export default applicationReducer
