import {
  ADD_FARM_CYCLE_FAIL,
  ADD_FARM_CYCLE_REQUEST,
  ADD_FARM_CYCLE_RESET,
  ADD_FARM_CYCLE_SUCCESS,
  AI_LEARN_MORE_FAIL,
  AI_LEARN_MORE_REQUEST,
  AI_LEARN_MORE_RESET,
  AI_LEARN_MORE_SUCCESS,
  AI_REVIEW_CYCLE_FAIL,
  AI_REVIEW_CYCLE_REQUEST,
  AI_REVIEW_CYCLE_SUCCESS,
  ALL_FARM_CYCLE_FAIL,
  ALL_FARM_CYCLE_REQUEST,
  ALL_FARM_CYCLE_SUCCESS,
  FARM_CYCLE_DETAIL_FAIL,
  FARM_CYCLE_DETAIL_REQUEST,
  FARM_CYCLE_DETAIL_SUCCESS,
  PACKAGES_FAIL,
  PACKAGES_REQUEST,
  PACKAGES_SUCCESS,
  POSTPONE_FARM_CYCLE_FAIL,
  POSTPONE_FARM_CYCLE_REQUEST,
  POSTPONE_FARM_CYCLE_RESET,
  POSTPONE_FARM_CYCLE_SUCCESS,
  SAVE_FARM_COMPLIANCE_FAIL,
  SAVE_FARM_COMPLIANCE_REQUEST,
  SAVE_FARM_COMPLIANCE_RESET,
  SAVE_FARM_COMPLIANCE_SUCCESS,
  SCAN_TAG_FAIL,
  SCAN_TAG_REQUEST,
  SCAN_TAG_RESET,
  SCAN_TAG_SUCCESS,
  START_FARM_CYCLE_FAIL,
  START_FARM_CYCLE_REQUEST,
  START_FARM_CYCLE_RESET,
  START_FARM_CYCLE_SUCCESS,
  UPDATE_PACKAGE_EOP_FAIL,
  UPDATE_PACKAGE_EOP_REQUEST,
  UPDATE_PACKAGE_EOP_RESET,
  UPDATE_PACKAGE_EOP_SUCCESS,
  WEATHER_ANALYSIS_FAIL,
  WEATHER_ANALYSIS_REQUEST,
  WEATHER_ANALYSIS_RESET,
  WEATHER_ANALYSIS_SUCCESS,
} from '../../../constants/farmerConstants/packagesConstants'

export const packageListReducer = (state = { packages: [] }, action) => {
  switch (action.type) {
    case PACKAGES_REQUEST:
      return { loading: true }
    case PACKAGES_SUCCESS:
      return { loading: false, packages: action.payload }
    case PACKAGES_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const newCycleReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_FARM_CYCLE_REQUEST:
      return { loading: true }
    case ADD_FARM_CYCLE_SUCCESS:
      return { loading: false, createdCycle: action.payload }
    case ADD_FARM_CYCLE_FAIL:
      return { loading: false, error: action.payload }
    case ADD_FARM_CYCLE_RESET:
      return {}
    default:
      return state
  }
}

export const allCycleReducer = (
  state = { loading: true, cycles: [] },
  action,
) => {
  switch (action.type) {
    case ALL_FARM_CYCLE_REQUEST:
      return { loading: true }
    case ALL_FARM_CYCLE_SUCCESS:
      return { loading: false, cycles: action.payload }
    case ALL_FARM_CYCLE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const startCycleReducer = (state = {}, action) => {
  switch (action.type) {
    case START_FARM_CYCLE_REQUEST:
      return { loading: true }
    case START_FARM_CYCLE_SUCCESS:
      return { loading: false, updatedCycle: action.payload }
    case START_FARM_CYCLE_FAIL:
      return { loading: false, error: action.payload }
    case START_FARM_CYCLE_RESET:
      return {}
    default:
      return state
  }
}

export const postponeCycleReducer = (state = {}, action) => {
  switch (action.type) {
    case POSTPONE_FARM_CYCLE_REQUEST:
      return { loading: true }
    case POSTPONE_FARM_CYCLE_SUCCESS:
      return { loading: false, postedCycle: action.payload }
    case POSTPONE_FARM_CYCLE_FAIL:
      return { loading: false, error: action.payload }
    case POSTPONE_FARM_CYCLE_RESET:
      return {}
    default:
      return state
  }
}

export const cycleDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case FARM_CYCLE_DETAIL_REQUEST:
      return { loading: true }
    case FARM_CYCLE_DETAIL_SUCCESS:
      return { loading: false, detail: action.payload }
    case FARM_CYCLE_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const saveComplianceReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_FARM_COMPLIANCE_REQUEST:
      return { loading: true }
    case SAVE_FARM_COMPLIANCE_SUCCESS:
      return { loading: false, saved: action.payload }
    case SAVE_FARM_COMPLIANCE_FAIL:
      return { loading: false, error: action.payload }
    case SAVE_FARM_COMPLIANCE_RESET:
      return {}
    default:
      return state
  }
}

export const complianceTagReducer = (state = {}, action) => {
  switch (action.type) {
    case SCAN_TAG_REQUEST:
      return { loading: true }
    case SCAN_TAG_SUCCESS:
      return { loading: false, result: action.payload }
    case SCAN_TAG_FAIL:
      return { loading: false, error: action.payload }
    case SCAN_TAG_RESET:
      return {}
    default:
      return state
  }
}

export const weatherAnalysisReducer = (state = {}, action) => {
  switch (action.type) {
    case WEATHER_ANALYSIS_REQUEST:
      return { loading: true }
    case WEATHER_ANALYSIS_SUCCESS:
      return { loading: false, analyses: action.payload }
    case WEATHER_ANALYSIS_FAIL:
      return { loading: false, error: action.payload }
    case WEATHER_ANALYSIS_RESET:
      return {}
    default:
      return state
  }
}

export const aiLearnMoresReducer = (state = {}, action) => {
  switch (action.type) {
    case AI_LEARN_MORE_REQUEST:
      return { loading: true }
    case AI_LEARN_MORE_SUCCESS:
      return { loading: false, readMore: action.payload }
    case AI_LEARN_MORE_FAIL:
      return { loading: false, error: action.payload }
    case AI_LEARN_MORE_RESET:
      return {}
    default:
      return state
  }
}

export const aiCycleReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case AI_REVIEW_CYCLE_REQUEST:
      return { loading: true }
    case AI_REVIEW_CYCLE_SUCCESS:
      return { loading: false, review: action.payload }
    case AI_REVIEW_CYCLE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateEOPReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PACKAGE_EOP_REQUEST:
      return { loading: true }
    case UPDATE_PACKAGE_EOP_SUCCESS:
      return { loading: false, updated: action.payload }
    case UPDATE_PACKAGE_EOP_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_PACKAGE_EOP_RESET:
      return {}
    default:
      return state
  }
}