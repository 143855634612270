import axios from 'axios'
import {
  UPDATE_PACKAGE_EOP_FAIL,
  UPDATE_PACKAGE_EOP_REQUEST,
  UPDATE_PACKAGE_EOP_SUCCESS,
} from '../../../constants/farmerConstants/packagesConstants'

export const updateEOP = (
  itemId,
  eop,
  list,
  totalSum,
  additionalEoPTotalSum,
  getEopTotalSum,
  profitabilityROI,
  profit
) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_PACKAGE_EOP_REQUEST,
    payload: {
      itemId,
      eop,
      list,
      totalSum,
      additionalEoPTotalSum,
      getEopTotalSum,
      profitabilityROI,
      profit
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/farmer/update-eop',
      {
        itemId,
        eop,
        list,
        totalSum,
        additionalEoPTotalSum,
        getEopTotalSum,
        profitabilityROI,
        profit
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: UPDATE_PACKAGE_EOP_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: UPDATE_PACKAGE_EOP_FAIL,
      payload: message,
    })
  }
}
