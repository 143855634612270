import React, { useEffect, useState } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { MDBDataTableV5 } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { allCluster } from '../../../actions/adminActions/farmclusterActions/allCluster'
import { listManufacuturers } from '../../../actions/adminActions/manufactuerActions/listManufacturers'
import { allClusterCategory } from '../../../actions/adminActions/farmclusterActions/allClusterCategory'
import { editCluster } from '../../../actions/adminActions/farmclusterActions/editCluster'
import { deleteCluster } from '../../../actions/adminActions/farmclusterActions/deleteCluster'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import SweetAlert from 'react-bootstrap-sweetalert'
import { CLUSTER_DELETE_RESET, UPDATE_CLUSTER_RESET } from '../../../constants/adminConstants/farmcluster/clusterContants'

export default function AllClusterPage() {
    const [datatable, setDatatable] = useState({})
    const [open, setOpen] = useState(false)
    const [itemId, setItemId] = useState('')
    const [manufacturer, setManufacturer] = useState('')
    const [name, setClusterName] = useState('')
    const [category, setClusterCategory] = useState('')
    const [description, setClusterDescription] = useState('')
    const [alert, setAlert] = useState(null)

    const listCluster = useSelector((state) => state.listCluster)
    const { loading, clusters, error } = listCluster

    const manufacturersList = useSelector((state) => state.manufacturersList)
    const { loading: loadingMaufacturer, manufacturers } = manufacturersList

    const clusterCategoryList = useSelector((state) => state.clusterCategoryList)
    const { loading: loadingCategory, categorylist, error: categoryError } = clusterCategoryList

    const updateCluster = useSelector((state) => state.updateCluster)
    const {
        loading: updateLoading,
        error: updateError,
        updateSuccess,
    } = updateCluster

    const removeCluster = useSelector(
        (state) => state.removeCluster,
    )
    const {
        loading: loadingDelete,
        error: errorDelete,
        success,
    } = removeCluster

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(allCluster())
        dispatch(listManufacuturers())
        dispatch(allClusterCategory())

        if (updateSuccess) {
            setOpen(false)
            setTimeout(() => {
                dispatch({ type: UPDATE_CLUSTER_RESET })
            }, 5000)
        }


        if (success) {
            setTimeout(() => {
                dispatch({ type: CLUSTER_DELETE_RESET })
            }, 5000)
        }
    }, [updateSuccess, success])

    const handleClose = () => {
        setOpen(false)
    }

    const handleClickOpen = (item) => {
        setOpen(true)
        setItemId(item._id)
        setManufacturer(item.organization._id)
        setClusterName(item.name)
        setClusterCategory(item.category._id)
        setClusterDescription(item.description)
    }

    const handleEditSubmit = (e) => {
        e.preventDefault()

        dispatch(editCluster(itemId, manufacturer, name, category, description))
    }


    const deleteHandler = (item) => {
        setAlert(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={() => (setAlert(null), dispatch(deleteCluster(item._id)))}
                onCancel={() => setAlert(null)}
                focusCancelBtn
            >
                You can not undo this!!!
            </SweetAlert >,
        )
    }


    useEffect(() => {
        if (clusters) {
            setDatatable({
                columns: [

                    {
                        label: 'Cluster Name',
                        field: 'name',
                        width: 100,
                    },
                    {
                        label: 'Organization',
                        field: 'company',
                        width: 100,
                    },
                    {
                        label: 'Category',
                        field: 'category',
                    },
                    {
                        label: 'Date Created',
                        field: 'date',
                        width: 50,
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        sort: 'disabled',
                    },
                ],
                rows: clusters.map((item) => ({
                    name: item.name,
                    company: item.organization && item.organization.name,
                    category: item.category && item.category.name,
                    date: moment(item.createdAt).format('Do MMM YYYY, h:mm a'),
                    action: (
                        <>
                            <button
                                className="btn btn-sm btn-primary text-edit"
                                style={{ marginRight: '15px' }}
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="Edit"
                                onClick={() => handleClickOpen(item)}
                            >
                                Edit
                            </button>
                            <button
                                className="btn btn-sm text-danger p-0 m-0"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                onClick={() => deleteHandler(item)}
                            >
                                <i className="mdi mdi-trash-can font-size-18"></i>
                            </button>
                        </>


                    ),
                })),
            })
        }
    }, [clusters])



    return (
        <div id="layout-wrapper">
            <Header />
            <LeftSidebar />
            <RightSidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">All Clusters</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Farmcluster</a>
                                            </li>
                                            <li className="breadcrumb-item active">All Clusters</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end page title --> */}

                        <div className="card">
                            <div className="card-body">
                                {error ? <MessageBox variant='danger'>{error}</MessageBox> : null}
                                {updateLoading && <LoadingBox />}
                                {updateSuccess && (
                                    <MessageBox variant="success">
                                        {updateSuccess.message}
                                    </MessageBox>
                                )}
                                {loadingDelete && <LoadingBox />}
                                {success && (
                                    <MessageBox variant="success">
                                        Cluster deleted successfully
                                    </MessageBox>
                                )}
                                {errorDelete && (
                                    <MessageBox variant="danger">
                                        {errorDelete}
                                    </MessageBox>
                                )}
                                {
                                    loading ? <LoadingBox /> :
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive-lg">
                                                    <MDBDataTableV5
                                                        hover
                                                        entriesOptions={[5, 20, 25, 50]}
                                                        entries={10}
                                                        pagesAmount={4}
                                                        data={datatable}
                                                        searchTop
                                                        searchBottom={false}
                                                        barReverse
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <>
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Edit cluster
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    <DialogContent className="dialog_content">
                        {updateError && (
                            <MessageBox variant="danger">
                                {updateError}
                            </MessageBox>
                        )}
                        <div className="">
                            <form>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label className="form-label" forHtml="ProductName">
                                                Select Organization
                                            </label>
                                            <select
                                                onChange={(e) => setManufacturer(e.target.value)}
                                                className="select2 form-select"
                                                required
                                                value={manufacturer}
                                            >
                                                <option>Select</option>
                                                {loadingMaufacturer ? (
                                                    'Loading...'
                                                ) : manufacturers.length > 0 ? (
                                                    <>
                                                        {manufacturers.map((item) => (
                                                            <option value={item._id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : (
                                                    'There is no organization'
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label
                                                className="form-label"
                                                forHtml="ClusterName"
                                            >
                                                Name
                                            </label>
                                            <input
                                                id="ClusterName"
                                                name="ClusterName"
                                                type="text"
                                                className="form-control"
                                                required
                                                onChange={(e) => setClusterName(e.target.value)}
                                                value={name}
                                                placeholder="Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label
                                                className="form-label"
                                                forHtml="contactPersonName"
                                            >
                                                Category
                                            </label>
                                            <select
                                                onChange={(e) =>
                                                    setClusterCategory(e.target.value)
                                                }
                                                className="select2 form-select"
                                                required
                                                value={category}
                                            >
                                                <option>Select</option>
                                                {loadingCategory ? (
                                                    'Loading...'
                                                ) : categorylist.length > 0 ? (
                                                    <>
                                                        {categorylist.map((item) => (
                                                            <option value={item._id} key={item._id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : (
                                                    'There is no Cluster category'
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label
                                                className="form-label"
                                                forHtml="ClusterDescription"
                                            >
                                                Description
                                            </label>
                                            <textarea
                                                id="ClusterDescription"
                                                name="ClusterDescription"
                                                type="text"
                                                className="form-control"
                                                placeholder="Description"
                                                required
                                                onChange={(e) =>
                                                    setClusterDescription(e.target.value)
                                                }
                                                value={description}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <DialogActions>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        type="close"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="btn btn-success btn-sm"
                                        type="submit"
                                        onClick={handleEditSubmit}
                                    >
                                        Update
                                    </button>
                                </DialogActions>
                            </form>
                        </div>
                    </DialogContent>
                </>
            </Dialog>

            {alert}
            <Footer />
        </div>
    )
}
