import axios from 'axios'
import {
    UPDATE_FARMER_PRODUCT_ORDER_REQUEST,
    UPDATE_FARMER_PRODUCT_ORDER_SUCCESS,
    UPDATE_FARMER_PRODUCT_ORDER_FAIL,
} from '../../../constants/serviceProviderConstants/orderConstants'

export const updateOrder = (itemId, status) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_FARMER_PRODUCT_ORDER_REQUEST, payload: itemId, status
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post(
            '/api/service/update-farmer-order',
            { itemId, status },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: UPDATE_FARMER_PRODUCT_ORDER_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: UPDATE_FARMER_PRODUCT_ORDER_FAIL, payload: message })
    }
}
