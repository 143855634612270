import axios from 'axios'
import {
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
} from '../../constants/authConstants/resetPasswordContants'

const resetPassword = (oldPassword, newPassword) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: PASSWORD_RESET_REQUEST,
    payload: { oldPassword, newPassword },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/password-reset',
      {
        oldPassword,
        newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: PASSWORD_RESET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PASSWORD_RESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export default resetPassword
