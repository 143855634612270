import axios from 'axios'
import {
    MAIN_WALLET_BALANCE_FAIL,
    MAIN_WALLET_BALANCE_REQUEST,
    MAIN_WALLET_BALANCE_SUCCESS,
} from '../../../constants/adminConstants/farmtrove/fundUserConstants'

export const getMainWalletBalance = () => async (dispatch, getState) => {
    dispatch({
        type: MAIN_WALLET_BALANCE_REQUEST,
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.get('/api/admin/farmtrove/get-main-wallet', {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })
        dispatch({ type: MAIN_WALLET_BALANCE_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: MAIN_WALLET_BALANCE_FAIL, payload: message })
    }
}
