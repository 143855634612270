import axios from 'axios'
import {
  PRODUCT_CATEGORY_LIST_REQUEST,
  PRODUCT_CATEGORY_LIST_SUCCESS,
  PRODUCT_CATEGORY_LIST_FAIL,
  ADD_PRODUCT_CATEGORY_REQUEST,
  ADD_PRODUCT_CATEGORY_SUCCESS,
  ADD_PRODUCT_CATEGORY_FAIL,
} from '../../../constants/adminConstants/productConstants'

export const AddProductCategory = (
  name,
  description,
  expectedPoint,
  categoryType,
  cycleDuration,
) => async (dispatch, getState) => {
  dispatch({
    type: ADD_PRODUCT_CATEGORY_REQUEST,
    payload: {
      name,
      description,
      expectedPoint,
      categoryType,
      cycleDuration,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/add-product-category',
      { name, description, expectedPoint, categoryType, cycleDuration },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: ADD_PRODUCT_CATEGORY_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: ADD_PRODUCT_CATEGORY_FAIL, payload: message })
  }
}

export const allProductCategory = () => async (dispatch, getState) => {
  dispatch({
    type: PRODUCT_CATEGORY_LIST_REQUEST,
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/admin/product-category', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: PRODUCT_CATEGORY_LIST_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: PRODUCT_CATEGORY_LIST_FAIL, payload: message })
  }
}
