import axios from 'axios'
import {
  TAGS_DOWNLOAD_REQUEST,
  TAGS_DOWNLOAD_SUCCESS,
  TAGS_DOWNLOAD_FAIL,
} from '../../../constants/adminConstants/TagsConstants'

export const downloadTags = (itemId) => async (dispatch, getState) => {
  dispatch({ type: TAGS_DOWNLOAD_REQUEST, payload: itemId })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.post(
      '/api/admin/download-tags',
      {
        batch: itemId,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: TAGS_DOWNLOAD_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: TAGS_DOWNLOAD_FAIL, payload: message })
  }
}
