export const ALL_INBOX_MESSAGE_REQUEST = 'ALL_INBOX_MESSAGE_REQUEST'
export const ALL_INBOX_MESSAGE_SUCCESS = 'ALL_INBOX_MESSAGE_SUCCESS'
export const ALL_INBOX_MESSAGE_FAIL = 'ALL_INBOX_MESSAGE_FAIL'

export const ALL_SENT_MESSAGE_REQUEST = 'ALL_SENT_MESSAGE_REQUEST'
export const ALL_SENT_MESSAGE_SUCCESS = 'ALL_SENT_MESSAGE_SUCCESS'
export const ALL_SENT_MESSAGE_FAIL = 'ALL_SENT_MESSAGE_FAIL'

export const ADMIN_READ_MESSAGE_REQUEST = 'ADMIN_READ_MESSAGE_REQUEST'
export const ADMIN_READ_MESSAGE_SUCCESS = 'ADMIN_READ_MESSAGE_SUCCESS'
export const ADMIN_READ_MESSAGE_FAIL = 'ADMIN_READ_MESSAGE_FAIL'
export const ADMIN_READ_MESSAGE_RESET = 'ADMIN_READ_MESSAGE_RESET'

export const ADMIN_DELETE_MESSAGE_REQUEST = 'ADMIN_DELETE_MESSAGE_REQUEST'
export const ADMIN_DELETE_MESSAGE_SUCCESS = 'ADMIN_DELETE_MESSAGE_SUCCESS'
export const ADMIN_DELETE_MESSAGE_FAIL = 'ADMIN_DELETE_MESSAGE_FAIL'
export const ADMIN_DELETE_MESSAGE_RESET = 'ADMIN_DELETE_MESSAGE_RESET'

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL'
export const SEND_MESSAGE_RESET = 'SEND_MESSAGE_RESET'

export const SEND_PUSH_NOTIFICATION_REQUEST = 'SEND_PUSH_NOTIFICATION_REQUEST'
export const SEND_PUSH_NOTIFICATION_SUCCESS = 'SEND_PUSH_NOTIFICATION_SUCCESS'
export const SEND_PUSH_NOTIFICATION_FAIL = 'SEND_PUSH_NOTIFICATION_FAIL'
export const SEND_PUSH_NOTIFICATION_RESET = 'SEND_PUSH_NOTIFICATION_RESET'

export const ALL_PUSH_NOTIFICATION_REQUEST = 'ALL_PUSH_NOTIFICATION_REQUEST'
export const ALL_PUSH_NOTIFICATION_SUCCESS = 'ALL_PUSH_NOTIFICATION_SUCCESS'
export const ALL_PUSH_NOTIFICATION_FAIL = 'ALL_PUSH_NOTIFICATION_FAIL'
