import axios from 'axios'
import {
  ALL_BENEFIT_CLAIMS_FAIL,
  ALL_BENEFIT_CLAIMS_REQUEST,
  ALL_BENEFIT_CLAIMS_SUCCESS,
} from '../../../constants/adminConstants/serviceConstants'

export const allBenefitClaims = () => async (dispatch, getState) => {
  dispatch({ type: ALL_BENEFIT_CLAIMS_REQUEST })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/admin/all-benefit-claims', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: ALL_BENEFIT_CLAIMS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: ALL_BENEFIT_CLAIMS_FAIL,
      payload: message,
    })
  }
}