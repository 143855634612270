import axios from 'axios'
import {
  LOAN_APPLICATION_FAIL,
  LOAN_APPLICATION_REQUEST,
  LOAN_APPLICATION_SUCCESS,
} from '../../../constants/farmerConstants/farmtrove/loanConstants'

export const loanApplication = (
  selectedEnterprise, amount, duration, idType, idNumber, provider
) => async (dispatch, getState) => {
  dispatch({
    type: LOAN_APPLICATION_REQUEST,
    payload: { selectedEnterprise, amount, duration, idType, idNumber, provider },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/farmer/farmtrove/loan-application',
      { selectedEnterprise, amount, duration, idType, idNumber, provider },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: LOAN_APPLICATION_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: LOAN_APPLICATION_FAIL, payload: message })
  }
}
