import axios from 'axios'
import {
  DELETE_PRODUCT_CATEGORY_REQUEST,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  DELETE_PRODUCT_CATEGORY_FAIL,
} from '../../../constants/adminConstants/productConstants'

export const deleteProductCategory = (itemId) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_PRODUCT_CATEGORY_REQUEST,
    payload: {
      itemId,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/delete-product-category',
      { itemId },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: DELETE_PRODUCT_CATEGORY_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: DELETE_PRODUCT_CATEGORY_FAIL, payload: message })
  }
}
