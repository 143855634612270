export const MANUFACTURER_PRODUCT_LIST_REQUEST = 'MANUFACTURER_PRODUCT_LIST_REQUEST'
export const MANUFACTURER_PRODUCT_LIST_SUCCESS = 'MANUFACTURER_PRODUCT_LIST_SUCCESS'
export const MANUFACTURER_PRODUCT_LIST_FAIL = 'MANUFACTURER_PRODUCT_LIST_FAIL'

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST'
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS'
export const ADD_PRODUCT_FAIL = 'ADD_PRODUCT_FAIL'
export const ADD_PRODUCT_RESET = 'ADD_PRODUCT_RESET'

export const EDIT_PRODUCT_REQUEST = 'EDIT_PRODUCT_REQUEST'
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS'
export const EDIT_PRODUCT_FAIL = 'EDIT_PRODUCT_FAIL'
export const EDIT_PRODUCT_RESET = 'EDIT_PRODUCT_RESET'