import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { getBatch } from '../../../actions/adminActions/tagActions/getTagBatch'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import { downloadTags } from '../../../actions/adminActions/tagActions/downloadTag'
import { MDBDataTableV5 } from 'mdbreact'
import { formatNumber } from '../../../components/functions'

function TagAnalysisPage() {
  const [datatable, setDatatable] = useState({})
  const [batch, setBatch] = useState('')

  let num = 1

  // Batch State
  const allBatchs = useSelector((state) => state.allBatchs)
  const { loading, lists, error } = allBatchs

  // Download Tag State
  const downloadTag = useSelector((state) => state.downloadTag)
  const { loading: loadingdownloadTags, error: dwlodError, tags } = downloadTag

  // Generate Tag State
  const downloadPDF = useSelector((state) => state.downloadPDF)
  const {
    loading: loadingpdfdownload,
    error: pdfDwlodError,
    codes,
  } = downloadPDF

  const fileTitle = `Farmsured-${batch}-${Date.now()}`

  const exportedFileName = fileTitle + '.csv' || 'export.csv'

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBatch())

    if (tags) {
      const blob = new Blob([tags], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFileName)
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', exportedFileName)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          setBatch('')
        }
      }
    }
  }, [dispatch, tags])

  useEffect(() => {
    if (lists) {
      setDatatable({
        columns: [
          {
            label: 'S/N',
            field: 'serial',
            width: 10,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 's/n',
            },
          },
          {
            label: 'Batch No',
            field: 'batch',
            width: 200,
          },
          {
            label: 'Points',
            field: 'points',
            width: 200,
          },
          {
            label: 'Date Created',
            field: 'date',
            width: 100,
          },
          {
            label: 'Product Category',
            field: 'category',
            width: 100,
          },
          {
            label: 'Assigned To',
            field: 'name',
            width: 100,
          },
          {
            label: 'Date Activated',
            field: 'dateActivated',
            width: 100,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows: lists.map((item) => ({
          serial: (
            <a href="javascript: void(0);" className="text-dark fw-bold">
              #{num++}
            </a>
          ),
          batch: item.batch,
          points: formatNumber(item.points),
          date: moment(item.created_at).format('Do MMMM YYYY,  h:mm a'),
          category:
            item.product_category && item.product_category.name
              ? item.product_category.name
              : '',
          name: item.assignedTo ? item.assignedTo.name : '',
          dateActivated: item.dateActivated ? (
            moment(item.dateActivated).format('Do MMMM YYYY,  h:mm a')
          ) : (
            <div className={`badge badge-soft-pending font-size-12`}>
              Not Activated
            </div>
          ),
          action: (
            <div>
              <button
                className="text btn btn-primary btn-sm me-3"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Download Excel"
                disabled={item.status ? true : false}
                onClick={() => {
                  handleDownload(item)
                }}
              >
                Download Excel
              </button>
              {/* <button
                className="text btn btn-success btn-sm"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Download PDF"
                disabled={item.status ? true : false}
                onClick={() => {
                  handlePDFDownload(item)
                }}
              >
                Generate PDF
              </button> */}
            </div>
          ),
        })),
      })
    }
  }, [lists])

  const handleDownload = (item) => {
    dispatch(downloadTags(item._id))
    setBatch(item.batch)
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Tag Analysis</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Tags</a>
                      </li>
                      <li className="breadcrumb-item active">Tag Analysis</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {loadingdownloadTags ? <LoadingBox /> : null}
            {/* {loadingpdfdownload ? <LoadingBox /> : null} */}
            {error && <MessageBox variant="danger">{error}</MessageBox>}
            {dwlodError && (
              <MessageBox variant="danger">{dwlodError}</MessageBox>
            )}
            {pdfDwlodError && (
              <MessageBox variant="danger">{pdfDwlodError}</MessageBox>
            )}
            {loading ? (
              <LoadingBox />
            ) : (
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive-xl">
                    <MDBDataTableV5
                      hover
                      entriesOptions={[5, 20, 25, 50]}
                      entries={10}
                      pagesAmount={4}
                      data={datatable}
                      searchTop
                      searchBottom={false}
                      barReverse
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TagAnalysisPage
