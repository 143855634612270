import React, { useState, useEffect, useMemo } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBox, MessageBox } from '../../../components'
import Select from 'react-select'
import { organisationServiceCategory } from '../../../actions/manufacturerActions/serviceProviderActions/organisationCategory'
import NaijaStates from 'naija-state-local-government'
import states from '../../../utilis'
import { generatePassword } from '../../../components/functions'
import { addServiceProvider } from '../../../actions/manufacturerActions/serviceProviderActions/addServiceProvider'
import { ORGANISATION_SERVICE_PROVIDER_CREATE_RESET } from '../../../constants/manufacturerConstants/serviceProviderConstants'

export default function AddServiceProviderPage() {
  const [uniqueCode, setUniqueCode] = useState('')
  const [name, setName] = useState('')
  const [category, setCategory] = useState([])
  const [option, setOptions] = useState([])
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [state, setState] = useState('')
  const [origin, setOrigin] = useState('')
  const [lga, setLga] = useState('')
  const [inputList, setinputList] = useState([{ lga: '', details: '' }])
  const [password, setPassword] = useState('')

  const organisationCategory = useSelector(
    (state) => state.organisationCategory,
  )
  const {
    loading: loadingCategory,
    error: assignError,
    categoryList,
  } = organisationCategory

  const newServiceProvider = useSelector((state) => state.newServiceProvider)
  const { loading, error, createdServiceProvider } = newServiceProvider

  const handleinputchange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    list[index]["state"] = state
    setinputList(list)
  }

  const handleremove = (index) => {
    const list = [...inputList]
    list.splice(index, 1)
    setinputList(list)
  }

  const handleaddclick = () => {
    setinputList([...inputList, { lga: '', details: '' }])
  }

  const dispatch = useDispatch()

  const HandleSubmit = (e) => {
    e.preventDefault()

    if (inputList && inputList[0] && inputList[0].lga === '') {
      dispatch(
        addServiceProvider(
          uniqueCode,
          name,
          category,
          email,
          phone,
          address,
          origin,
          lga,
          password,
        ),
      )
      setPassword('')
    } else {
      dispatch(
        addServiceProvider(
          uniqueCode,
          name,
          category,
          email,
          phone,
          address,
          origin,
          lga,
          password,
          inputList,
        ),
      )
      setPassword('')
    }
  }

  const myFunction = () => {
    var x = document.getElementById('visible')
    var y = document.getElementById('visibleText')
    if (x.type === 'password') {
      x.type = 'text'
      y.textContent = 'Hide Password'
    } else {
      x.type = 'password'
      y.textContent = 'Show Password'
    }
  }

  const handlePassword = (e) => {
    e.preventDefault()
    setPassword(generatePassword())
  }

  useEffect(() => {
    dispatch(organisationServiceCategory())
  }, [dispatch])

  useEffect(() => {
    if (createdServiceProvider) {
      setUniqueCode(`FBSP-${Math.floor(Math.random() * 9000) + 1000}`)
      setinputList([{ state: '', lga: '', details: '' }])
      setTimeout(() => {
        dispatch({ type: ORGANISATION_SERVICE_PROVIDER_CREATE_RESET })
      }, 2500)
    }
    if (
      categoryList &&
      categoryList.category &&
      categoryList.category.length > 0
    ) {
      setOptions(categoryList.category)
    }
  }, [categoryList, createdServiceProvider])

  useMemo(() => {
    setUniqueCode(`FBSP-${Math.floor(Math.random() * 9000) + 1000}`)
  }, [])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">New Service Provider</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Service Providers</a>
                      </li>
                      <li className="breadcrumb-item active">
                        New Service Provider
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <LoadingBox />
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  {createdServiceProvider ? (
                    <MessageBox variant="success">
                      {createdServiceProvider.message}
                    </MessageBox>
                  ) : null}
                  {error ? (
                    <MessageBox variant="danger">{error}</MessageBox>
                  ) : null}
                  {assignError ? (
                    <MessageBox variant="danger">{assignError}</MessageBox>
                  ) : null}
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={HandleSubmit}>
                        <div className="row mb-3">
                          <div className="col-md-12">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Unique Code
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Unique Code"
                              id="example-text-input"
                              onChange={(e) => setUniqueCode(e.target.value)}
                              value={uniqueCode}
                              required
                              disabled
                            />
                          </div>

                          <div className="col-md-12">
                            <label
                              htmlFor="example-text-input2"
                              className="col-md-12 col-form-label"
                            >
                              Name
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Name"
                              id="example-text-input2"
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </div>

                          <div className="col-md-12 mt-2">
                            <div className="row">
                              <div className="col-md-6">
                                <label
                                  className="form-label"
                                  forHtml="ProductName"
                                >
                                  Service Category
                                </label>

                                <Select
                                  onChange={(opt) => setCategory(opt)}
                                  required
                                  isMulti
                                  options={
                                    loadingCategory
                                      ? 'loading...'
                                      : option.map((opt) => ({
                                        label: opt.name,
                                        value: opt._id,
                                      }))
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input4"
                              className="col-md-12 col-form-label"
                            >
                              Email
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              placeholder="Email"
                              id="example-text-input4"
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>

                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input5"
                              className="col-md-12 col-form-label"
                            >
                              Phone
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Phone"
                              id="example-text-input5"
                              onChange={(e) => setPhone(e.target.value)}
                              required
                            />
                          </div>

                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input6"
                              className="col-md-12 col-form-label"
                            >
                              Select a state
                            </label>
                            <select
                              className="form-select form-control select2"
                              required
                              placeholder="Select a state"
                              onChange={(e) => setOrigin(e.target.value)}
                            >
                              <option>Select a state</option>
                              {states.map((item) => (
                                <option value={item}>{item}</option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input7"
                              className="col-form-label"
                            >
                              Select a local government area
                            </label>
                            <select
                              className="form-select form-control select2"
                              required
                              name="lga"
                              placeholder="Select a local government area"
                              onChange={(e) => setLga(e.target.value)}
                            >
                              {origin && origin === 'Select a state' ? (
                                <option>Select a local government area</option>
                              ) : origin ? (
                                <>
                                  <option>
                                    Select a local government area
                                  </option>
                                  {NaijaStates.lgas(origin).lgas.map((item) => (
                                    <option value={item}>{item}</option>
                                  ))}
                                </>
                              ) : (
                                <option>Select a local government area</option>
                              )}
                            </select>
                          </div>

                          <div className="col-md-12">
                            <label
                              className="col-md-12 col-form-label"
                              forHtml="address"
                            >
                              Address
                            </label>
                            <input
                              id="address"
                              name="address"
                              type="text"
                              className="form-control form-control"
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-4">
                            <div className="mb-3">
                              <label className="form-label" htmlFor="password">
                                Password
                              </label>
                              <input
                                id="visible"
                                name="password"
                                type="password"
                                className="form-control"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 ">
                            <span
                              className="form-control btn btn-sm btn-password toggleText"
                              id="visibleText"
                              onClick={myFunction}
                            >
                              Show Password
                            </span>

                            <div className="mt-2">
                              <button
                                className="form-control btn btn-sm btn-success"
                                onClick={handlePassword}
                              >
                                Generate Password
                              </button>
                            </div>
                          </div>
                        </div>

                        <h4 className="card-title">SERVICE COVERAGE</h4>

                        <div className="col-md-6 mb-3 mt-1">
                          <label htmlFor="example-text-input8">
                            Select a state
                          </label>
                          <select
                            className="form-select form-control select2"
                            required
                            placeholder="Select a state"
                            onChange={(e) => setState(e.target.value)}
                          >
                            <option>Select a state</option>
                            {states.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        </div>

                        {inputList.map((x, i) => {
                          return (
                            <div className="row mb-3">
                              <div className="form-group col-md-8 mb-2">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-12 col-form-label"
                                >
                                  Select a local government area
                                </label>
                                <select
                                  className="form-select form-control select2"
                                  required
                                  name="lga"
                                  placeholder="Select a local government area"
                                  onChange={(e) => handleinputchange(e, i)}
                                >
                                  {state && state === 'Select a state' ? (
                                    <option>
                                      Select a local government area
                                    </option>
                                  ) : state ? (
                                    <>
                                      <option>
                                        Select a local government area
                                      </option>
                                      {NaijaStates.lgas(state).lgas.map(
                                        (item) => (
                                          <option value={item}>{item}</option>
                                        ),
                                      )}
                                    </>
                                  ) : (
                                    <option>
                                      Select a local government area
                                    </option>
                                  )}
                                </select>
                              </div>
                              <div className="form-group col-md-8">
                                <textarea
                                  type="text"
                                  name="details"
                                  className="form-control"
                                  onChange={(e) => handleinputchange(e, i)}
                                  placeholder="Address"
                                />
                              </div>
                              <div className="form-group col-md-4 mt-4">
                                {inputList.length !== 1 && (
                                  <button
                                    className="btn btn-danger mx-1"
                                    type="button"
                                    onClick={() => handleremove(i)}
                                  >
                                    -
                                  </button>
                                )}
                                {inputList.length - 1 === i && (
                                  <button
                                    className="btn btn-success"
                                    onClick={handleaddclick}
                                  >
                                    +
                                  </button>
                                )}
                              </div>
                            </div>
                          )
                        })}

                        <button
                          className="btn btn-success btn-bg"
                          type="submit"
                        >
                          Create Service Prodvider
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
