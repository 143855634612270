import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { orderDetail } from '../../../actions/adminActions/tagActions/tagRequest'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import { formatNumber } from '../../../components/functions'
import moment from 'moment'
import { getNotAssignedBatch } from '../../../actions/adminActions/tagActions/getBatchs'
import { assignTag } from '../../../actions/adminActions/tagActions/assignBatch'
import {
  ASSIGN_BATCH_RESET,
  BATCH_NOT_ASSIGNED_LIST_RESET,
  SEND_INVOICE_RESET,
} from '../../../constants/adminConstants/TagsConstants'
import { updateOrder } from '../../../actions/adminActions/tagActions/updateOrder'
import Select from 'react-select'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Invoice from '../../../components/Invoice/Invoice'
import { sendClientInvoice } from '../../../actions/adminActions/tagActions/sendClientInvoice'
import { allProductCategory } from '../../../actions/adminActions/productActions/productCategory'
import SweetAlert from 'react-bootstrap-sweetalert'

function TagOrderPage(props) {
  const orderId = props.match.params.id
  const [alert, setAlert] = useState(null)
  const [company, setCompanyId] = useState('')
  const [batch, setBatch] = useState('')
  const [status, setStatus] = useState('')
  const [productId, setProductId] = useState('')
  const [dollarRate, setDollarRate] = useState(0)
  const [costPerRoll, setCostPerRoll] = useState(0)
  const [shippingFee, setShippingFee] = useState(0)
  const [accountName, setAccountName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [bankName, setBankName] = useState('')
  const [category, setProductCategory] = useState('')

  const handleAssign = (e) => {
    e.preventDefault()
    if (batch === '') {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="danger"
          title="No Batch Number"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Select a batch number!!!
        </SweetAlert>,
      )
    } else dispatch(assignTag(company, batch, orderId, productId))
  }

  const updateHandle = (e) => {
    e.preventDefault()

    dispatch(updateOrder(orderId, status, costPerRoll, shippingFee))
  }

  const invoice = (e) => {
    e.preventDefault()

    dispatch(
      sendClientInvoice(
        orderId,
        costPerRoll,
        shippingFee,
        dollarRate,
        accountName,
        accountNumber,
        bankName,
      ),
    )
  }

  const dispatch = useDispatch()

  const tagRequestDetail = useSelector((state) => state.tagRequestDetail)
  const { loading, error, detail } = tagRequestDetail

  const notAssignedBatchs = useSelector((state) => state.notAssignedBatchs)
  const { loading: loadingLists, error: listError, lists } = notAssignedBatchs

  const assign = useSelector((state) => state.assign)
  const { loading: loadingAssign, error: assignError, result } = assign

  const tagRequestUpdate = useSelector((state) => state.tagRequestUpdate)
  const {
    loading: loadingUpdate,
    error: updateError,
    success,
  } = tagRequestUpdate

  const sendInvoice = useSelector((state) => state.sendInvoice)
  const {
    loading: invoiceSendingLoading,
    error: invoiceSendingError,
    sent,
  } = sendInvoice

  const productCategory = useSelector((state) => state.productCategory)
  const { loading: loadingCategory, categorylist } = productCategory

  useEffect(() => {
    dispatch(orderDetail(orderId))
    dispatch(allProductCategory())

    if (result) {
      dispatch({ type: BATCH_NOT_ASSIGNED_LIST_RESET })
      setTimeout(() => {
        dispatch({ type: ASSIGN_BATCH_RESET })
      }, 2100)
    }

    if (sent) {
      setTimeout(() => {
        dispatch({ type: SEND_INVOICE_RESET })
      }, 2100)
    }
  }, [dispatch, result, success, sent])

  // useEffect(() => {
  //   if (category === 'Select a category' || category === '') {
  //     setAlert(
  //       <SweetAlert
  //         warning
  //         confirmBtnText="Okay"
  //         confirmBtnBsStyle="danger"
  //         title="Category is empty"
  //         onConfirm={() => setAlert(null)}
  //         onCancel={() => setAlert(null)}
  //         focusCancelBtn
  //       >
  //         Category field can not be empty!!!
  //       </SweetAlert>,
  //     )
  //   } else if (category) dispatch(getNotAssignedBatch(category))
  // }, [dispatch, category])

  const handleCategoryChange = (value) => {
    console.log(value)
    setProductCategory(value)

    if (value === 'Select a category' || value === '') {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Okay"
          confirmBtnBsStyle="danger"
          title="Category is empty"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          Category field can not be empty!!!
        </SweetAlert>,
      )
    } else if (value) dispatch(getNotAssignedBatch(value))
  }

  useEffect(() => {
    if (detail && detail.manufacturer) {
      setCompanyId(detail.manufacturer._id)
    }

    if (detail && detail.product) {
      setProductId(detail.product._id)
    }

    if (detail && detail.status) {
      setStatus(detail.status)
    }

    if (detail && detail.shippingFee) {
      setShippingFee(detail.shippingFee)
    }

    if (detail && detail.costPerRoll) {
      setCostPerRoll(detail.costPerRoll)
    }
  }, [detail])

  const costOfRoll = (costPerRoll, amount) => {
    return parseInt(costPerRoll) * parseInt(amount)
  }

  const subTotal = (amountOfRolls) => {
    let a = parseInt(costPerRoll) * parseInt(amountOfRolls)

    return parseInt(a) + parseInt(shippingFee)
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Tag Request Details</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Tags</a>
                      </li>
                      <li className="breadcrumb-item active">Tag Request</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {result ? (
              <MessageBox variant="success">{result.message}</MessageBox>
            ) : null}

            {assignError ? (
              <MessageBox variant="danger">{assignError}</MessageBox>
            ) : null}

            {updateError ? (
              <MessageBox variant="danger">{updateError}</MessageBox>
            ) : null}

            {listError ? (
              <MessageBox variant="danger">{listError}</MessageBox>
            ) : null}

            {invoiceSendingError ? (
              <MessageBox variant="danger">{invoiceSendingError}</MessageBox>
            ) : null}

            {sent ? (
              <MessageBox variant="success">{sent.message}</MessageBox>
            ) : null}
            {loadingUpdate ? <LoadingBox /> : null}

            {invoiceSendingLoading ? <LoadingBox /> : null}

            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div className="">
                <div className="page-title-box ">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex">
                        <div className="me-3">
                          <img
                            src="assets/images/farmsured_icon.png"
                            alt="logo-sm-light"
                            className="avatar-xs"
                          />
                        </div>
                        <div className="flex-1">
                          <h5>
                            Request by{' '}
                            {detail.manufacturer
                              ? detail.manufacturer.name
                              : null}
                          </h5>
                          <div className="">
                            <a href="javascript:void(0);" className="">
                              Request ID: #{detail.orderID}
                            </a>
                          </div>
                          <p className="mb-0">
                            Date of Request:{' '}
                            {moment(detail.created_at).format('Do MMMM YYYY')}
                          </p>
                          <span className={`badge badge-soft-${detail.status}`}>
                            {detail.status}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <div className="text-lg-end">
                          <div className="float-end">
                            <button
                              className=" btn btn-success"
                              type="submit"
                              onClick={updateHandle}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-9">
                    <div className="card">
                      <div className="card-body">
                        <h6 className="font-size-16">
                          Request Details - Section 1
                        </h6>

                        <div className="row mt-3">
                          <div className="col-md-4">
                            <h6 className="font-size-15">General</h6>
                            <div className="mt-3">
                              <h6 className="font-size-14">Date Requested</h6>
                              <p className="mb-0 font-size-13">
                                {moment(detail.created_at).format(
                                  'Do MMMM YYYY',
                                )}
                              </p>
                            </div>

                            <div className="mt-3 mb-3">
                              <h6 className="font-size-14">
                                Farmsured Label Package
                              </h6>
                              <p className="mb-0 font-size-13">
                                {detail.amount
                                  ? `${formatNumber(
                                    detail.amount,
                                  )} Farmsured Label Package`
                                  : null}
                              </p>
                            </div>

                            <div className="mt-3 mb-3">
                              <h6 className="font-size-14">No of Product</h6>
                              <p className="mb-0 font-size-13">
                                {detail.amount
                                  ? `${formatNumber(
                                    detail.amount,
                                  )} package(s) will cover ${formatNumber(
                                    detail.amount * 1000,
                                  )} product(s)`
                                  : null}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <h6 className="font-size-15">Company details</h6>

                            <div className="mt-3">
                              <h6 className="font-size-14">
                                {detail.manufacturer
                                  ? detail.manufacturer.name
                                  : null}
                              </h6>
                              {detail.manufacturer ? (
                                <>
                                  <p className="font-size-14 mb-0">
                                    {detail.manufacturer
                                      ? detail.manufacturer.address
                                      : null}
                                    ,
                                  </p>
                                  <p className="font-size-14 mt-0">
                                    {detail.manufacturer
                                      ? detail.manufacturer.city
                                      : null}
                                    ,{' '}
                                    {detail.manufacturer
                                      ? detail.manufacturer.state
                                      : null}{' '}
                                    State.
                                  </p>
                                </>
                              ) : null}

                              <h6 className="font-size-15">Contact Person</h6>
                              <p className="font-size-14 mt-0 mb-0">
                                {detail.manufacturer
                                  ? detail.manufacturer.contactPerson
                                  : null}
                              </p>
                              <p className="font-size-14 mt-0 mb-0">
                                {detail.manufacturer
                                  ? `0${detail.manufacturer.phone}`
                                  : null}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="">
                              <h6 className="font-size-15">Product details</h6>
                              <div className="mt-3">
                                <h6 className="font-size-14">
                                  {detail.product ? detail.product.name : null}
                                </h6>
                                <p className="font-size-14 mb-0">
                                  {detail.product
                                    ? detail.product.category.name
                                    : null}
                                  ,
                                </p>
                                <h6 className="font-size-14 mt-2">
                                  Date Created
                                </h6>

                                <p className="font-size-14 pt-0">
                                  {detail.product
                                    ? moment(detail.product.created_at).format(
                                      'Do MMMM YYYY',
                                    )
                                    : null}
                                </p>
                              </div>
                            </div>

                            <div className="mt-3">
                              <h6 className="font-size-15">Shipping details</h6>
                              <div className="mt-3">
                                <h6 className="font-size-14">
                                  {detail.manufacturer
                                    ? detail.manufacturer.name
                                    : null}
                                </h6>
                                <p className="font-size-14 mb-0">
                                  {detail.manufacturer
                                    ? detail.manufacturer.address
                                    : null}
                                  ,
                                </p>
                                <p className="font-size-14 mt-0">
                                  {detail.manufacturer
                                    ? detail.manufacturer.city
                                    : null}
                                  ,{' '}
                                  {detail.manufacturer
                                    ? detail.manufacturer.state
                                    : null}{' '}
                                  State.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body">
                        <h6 className="font-size-16">
                          Farmsured Secured Tags - Section 2
                        </h6>
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th>Item</th>
                                <th>Qty</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr key={1}>
                                <td>
                                  <p>
                                    Farmsured Protection Package
                                  </p>
                                </td>
                                <td>
                                  {detail.amount
                                    ? formatNumber(detail.amount)
                                    : null}
                                </td>
                                <td>
                                  {detail.amount
                                    ? formatNumber(detail.amount)
                                    : null}
                                </td>
                              </tr>
                              <tr key={2}>
                                <td>
                                  <p>Farmsured Covers Product(s)</p>
                                </td>
                                <td>
                                  {detail.amount
                                    ? formatNumber(detail.amount * 1000)
                                    : null}
                                </td>
                                <td>
                                  {detail.amount
                                    ? formatNumber(detail.amount * 1000)
                                    : null}
                                </td>
                              </tr>
                              <tr></tr>
                            </tbody>
                          </table>
                          <div className="totals mt-3">
                            <div className="totals-item">
                              <label>Cost of Farmsured Package</label>
                              <div className="totals-value" id="cart-subtotal">
                                <b>
                                  $
                                  {detail.amount
                                    ? formatNumber(
                                      costOfRoll(costPerRoll, detail.amount),
                                    )
                                    : 0 || 0}
                                </b>
                              </div>
                            </div>

                            <div className="totals-item">
                              <label>Shipping</label>
                              <div className="totals-value" id="cart-shipping">
                                <b>${formatNumber(shippingFee) || 0}</b>
                              </div>
                            </div>
                            <div className="totals-item">
                              <label>Subtotal</label>
                              <div className="totals-value" id="cart-subtotal">
                                <b>
                                  $
                                  {detail &&
                                    detail.amount &&
                                    isNaN(subTotal(detail.amount)) === true
                                    ? 0
                                    : formatNumber(subTotal(detail.amount))}
                                </b>
                              </div>
                            </div>
                            <div className="totals-item totals-item-total">
                              <label>Grand Total</label>
                              <div
                                className="totals-value grand-total"
                                id="cart-total"
                              >
                                <b>
                                  {' '}
                                  $
                                  {detail &&
                                    detail.amount &&
                                    isNaN(subTotal(detail.amount)) === true
                                    ? 0
                                    : formatNumber(subTotal(detail.amount))}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body">
                        <h6 className="font-size-16">
                          Payment Account Detail - Section 3
                        </h6>

                        <div className="">
                          <label htmlFor="" className="font-size-13 mt-2">
                            Account Name
                          </label>
                          <input
                            className="form-control m-0"
                            type="text"
                            value={accountName}
                            onChange={(e) => setAccountName(e.target.value)}
                          />
                        </div>

                        <div className="">
                          <label htmlFor="" className="font-size-13 mt-2">
                            Account Number
                          </label>
                          <input
                            className="form-control m-0"
                            type="number"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                          />
                        </div>

                        <div className="">
                          <label htmlFor="" className="font-size-13 mt-2">
                            Bank Name
                          </label>
                          <input
                            className="form-control m-0"
                            type="text"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-3">
                          <h6 className="font-size-14">Tags Request Status</h6>
                            <select
                              className="form-select form-control select2"
                              onChange={(e) => setStatus(e.target.value)}
                              value={status}
                            >
                              <option value="Order_Received">
                                Order Received
                              </option>
                              <option value="Reviewing_Order">
                                Reviewing Order
                              </option>
                              <option value="Awaiting_Payment">
                                Awaiting Payment
                              </option>
                              <option value="Payment_Confirmed">
                                Payment Confirmed
                              </option>
                              <option value="Order_in_Transit">
                                Order in Transit
                              </option>
                              <option value="Delivered">Delivered</option>
                              <option value="Declined">Declined</option>
                            </select>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              forhtml="contactPersonName"
                            >
                              Product Category
                            </label>
                            <select
                              onChange={(e) =>
                                handleCategoryChange(e.target.value)
                              }
                              className="select2 form-select"
                              required
                            >
                              {loadingCategory ? (
                                'Loading...'
                              ) : categorylist.length > 0 ? (
                                <>
                                  <option>Select a category</option>
                                  {categorylist.map((item) => (
                                    <option value={item._id} key={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </>
                              ) : (
                                'there is no product category'
                              )}
                            </select>
                          </div>
                        </div>

                        <div className="">
                          <h5 className="font-size-14">Assign Batch</h5>
                          {detail ? (
                            detail.batch && detail.batch.length > 0 ? (
                              <div className="mb-3">
                                {detail.batch.map((item) => (
                                  <div
                                    className={`badge badge-soft-success font-size-12 m-1`} key={item._id}
                                  >
                                    {item.batchId.batch} - Assigned
                                  </div>
                                ))}
                              </div>
                            ) : null
                          ) : null}
                          <div className="row">
                            <div className="col-md-12">
                              {loadingLists ? (
                                'Loading...'
                              ) : lists && lists.length > 0 ? (
                                <>
                                  <Select
                                    onChange={(opt) => setBatch(opt.value)}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    required
                                    options={lists.map((opt) => ({
                                      label: opt.batch,
                                      value: opt._id,
                                    }))}
                                  />
                                  <button
                                    className="btn btn-light mt-2  w-100"
                                    type="submit"
                                    onClick={handleAssign}
                                  >
                                    {loadingAssign ? 'loading...' : 'Assign'}
                                  </button>
                                </>
                              ) : (
                                'There are no batch available'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body" style={{ height: '200px' }}>
                        <div className="mb-3">
                          <h6 className="font-size-14">Any other message</h6>
                          <p className="">{detail.description}</p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body">
                        <div className="">
                          <h6 className="font-size-14">Cost</h6>

                          <div className="">
                            <label htmlFor="" className="font-size-13 mt-3">
                              Cost of Farmsured Package
                            </label>
                            <input
                              className="form-control mt-0"
                              type="number"
                              value={costPerRoll}
                              onChange={(e) => setCostPerRoll(e.target.value)}
                            />
                          </div>
                          <div className="">
                            <label htmlFor="" className="font-size-13 mt-3">
                              Shipping Fee
                            </label>
                            <input
                              className="form-control mt-0"
                              type="number"
                              onChange={(e) => setShippingFee(e.target.value)}
                              value={shippingFee}
                            />
                          </div>
                          <div className="">
                            <label htmlFor="" className="font-size-13 mt-3">
                              Dollar Conversion Rate
                            </label>
                            <input
                              className="form-control m-0"
                              type="number"
                              value={dollarRate}
                              onChange={(e) => setDollarRate(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <button
                        className="btn btn-info btn-block d-grid mb-2 onHover"
                        style={{ width: '100%' }}
                        disabled={
                          !costPerRoll ||
                            !shippingFee ||
                            !dollarRate ||
                            !accountName ||
                            !accountNumber ||
                            !bankName
                            ? true
                            : false
                        }
                      >
                        <PDFDownloadLink
                          document={
                            <Invoice
                              detail={detail}
                              shippingFee={formatNumber(shippingFee)}
                              costOfRoll={formatNumber(
                                costOfRoll(costPerRoll, detail.amount),
                              )}
                              subTotal={subTotal(detail.amount)}
                              costPerRoll={costPerRoll}
                              accountName={accountName}
                              accountNumber={accountNumber}
                              bankName={bankName}
                              dollarRate={dollarRate}
                            />
                          }
                          fileName={`Invoice-${detail.orderID}.pdf`}
                        >
                          {({ loading }) =>
                            loading ? 'Loading...' : 'Download Invoice'
                          }
                        </PDFDownloadLink>
                      </button>

                      <button
                        className="btn btn-success btn-shadow btn-block d-grid mt-1 mb-1"
                        onClick={invoice}
                        disabled={
                          !costPerRoll ||
                            !shippingFee ||
                            !dollarRate ||
                            !accountName ||
                            !accountNumber ||
                            !bankName
                            ? true
                            : false
                        }
                        style={{ width: '100%' }}
                      >
                        Send Invoice
                      </button>
                    </div>
                  </div>

                  {/* <!-- end row --> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {alert}
      <Footer />
    </div>
  )
}

export default TagOrderPage
