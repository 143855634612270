import axios from 'axios'
import {
  ORGANISATION_SERVICE_PROVIDER_CREATE_FAIL,
  ORGANISATION_SERVICE_PROVIDER_CREATE_REQUEST,
  ORGANISATION_SERVICE_PROVIDER_CREATE_SUCCESS,
} from '../../../constants/manufacturerConstants/serviceProviderConstants'

export const addServiceProvider = (
  uniqueCode,
  name,
  category,
  email,
  phone,
  address,
  origin,
  lga,
  password,
  inputList,
) => async (dispatch, getState) => {
  dispatch({
    type: ORGANISATION_SERVICE_PROVIDER_CREATE_REQUEST,
    payload: {
      uniqueCode,
      name,
      category,
      email,
      phone,
      address,
      origin,
      lga,
      password,
      inputList,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/manufacturer/new-service-provider',
      {
        uniqueCode,
        name,
        category,
        email,
        phone,
        address,
        origin,
        lga,
        password,
        inputList,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({
      type: ORGANISATION_SERVICE_PROVIDER_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: ORGANISATION_SERVICE_PROVIDER_CREATE_FAIL,
      payload: message,
    })
  }
}
