import axios from 'axios'
import {
  MANUFACTURERS_LIST_FAIL,
  MANUFACTURERS_LIST_REQUEST,
  MANUFACTURERS_LIST_SUCCESS,
} from '../../../constants/adminConstants/manufacturersConstants'

export const listManufacuturers = () => async (dispatch, getState) => {
  dispatch({ type: MANUFACTURERS_LIST_REQUEST })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/admin/all-manufacturers', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type: MANUFACTURERS_LIST_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: MANUFACTURERS_LIST_FAIL, payload: message })
  }
}
