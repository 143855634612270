import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { verifyTag } from '../../../actions/farmerActions/tagsActions/activateTag'
import { LoadingBox } from '../../../components'
import { FARMER_SCAN_TAG_RESET } from '../../../constants/farmerConstants/tagsConstants'
import { Header, LeftSidebar, RightSidebar, Footer } from '../../../partials'
import Modal from 'react-modal'
import SweetAlert from 'react-bootstrap-sweetalert'

function VerifyTagPage(props) {
  const [setIsOpen] = useState(false)
  const [alert, setAlert] = useState(null)
  const [tag, setTag] = useState('')
  const [color, setColor] = useState('')
  const [geolocation, setGeolocation] = useState({})
  const [locationError, setError] = useState(null)

  const farmerScan = useSelector((state) => state.farmerScan)
  const { loading, error, result } = farmerScan

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const dispatch = useDispatch()

  const resetHandler = (e) => {
    e.preventDefault()
    dispatch({ type: FARMER_SCAN_TAG_RESET })
    props.history.push('/dashboard')
  }

  const resetError = (e) => {
    e.preventDefault()

    dispatch({ type: FARMER_SCAN_TAG_RESET })
  }

  const customStyles = {
    content: {
      width: '400px',
      margin: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },

    modalConfirm: {
      color: '#434e65',
      maxWidth: '100%',
    },
  }

  const onChange = ({ coords }) => {
    setGeolocation({
      lat: coords.latitude,
      lng: coords.longitude,
    })
  }
  const onError = (locaionError) => {
    setError(locaionError.message)
  }
  useEffect(() => {
    const geo = navigator.geolocation
    if (!geo) {
      setError('Geolocation is not supported')
      return
    }
    const watcher = geo.watchPosition(onChange, onError)
    return () => geo.clearWatch(watcher)
  }, [navigator.geolocation])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!tag) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Input Tag Pin"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          A tag pin must be filled!!!
        </SweetAlert>,
      )
    } else if (!color) {
      setAlert(
        <SweetAlert
          warning
          confirmBtnText="Ok"
          confirmBtnBsStyle="danger"
          title="Select Tag Color"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          focusCancelBtn
        >
          A tag color must be selected!!!
        </SweetAlert>,
      )
    } else {
      dispatch(verifyTag(tag, color, geolocation))
      setColor('')
    }
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Validate Product</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Tags Management</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Validate Product
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {error ? (
              <div>
                <Modal
                  isOpen
                  onRequestClose={() => setIsOpen(false)}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div
                    className="modal-header-false"
                    style={{
                      background: '#d13e3e',
                      borderBottom: 'none',
                      position: 'relative',
                      textAlign: 'center',
                      margin: '-20px -20px 0',
                      borderRadius: '5px 5px 0 0',
                      padding: '35px',
                    }}
                  >
                    <div
                      className="icon-box"
                      style={{
                        color: '#fff',
                        textAlign: 'center',
                      }}
                    >
                      <center>
                        <i
                          className="font-circle fa fa-times"
                          style={{
                            borderRadius: '60%',
                            display: 'inline-block',
                            zIndex: 9,
                            border: '5px solid #fff',
                            padding: '20px 30px 20px 30px',
                            fontSize: '70px',
                            margin: '-5px 0 0 -5px',
                            textAlign: 'center',
                          }}
                        ></i>
                      </center>
                    </div>
                  </div>
                  <div className="modal-body text-center">
                    <p className="model-text">
                      Hello <br />
                    </p>
                    <h4
                      style={{
                        textAlign: 'center',
                        fontSize: '16.5px',
                        margin: '10px 0',
                      }}
                    >
                      TRY AGAIN
                    </h4>
                    <h6
                      style={{
                        textAlign: 'center',
                        fontSize: '16px',
                        margin: '10px 0',
                      }}
                    >
                      {error}
                    </h6>
                    <br />
                    <button
                      className="btn btn-success"
                      onClick={resetError}
                      style={{
                        color: '#fff',
                        borderRadius: '4px',
                        textDecoration: 'none',
                        transition: 'all 0.4s',
                        lineHeight: 'normal',
                        borderRadius: '30px',
                        marginTop: '10px',
                        padding: '6px 20px 10px ',
                        border: 'none',
                      }}
                    >
                      Close
                    </button>
                  </div>
                </Modal>
              </div>
            ) : null}

            {result ? (
              <div>
                <Modal
                  isOpen
                  onRequestClose={() => setIsOpen(false)}
                  style={customStyles}
                  contentLabel="Modal"
                >
                  <div
                    className="modal-header-false"
                    style={{
                      background: '#47c9a2',
                      borderBottom: 'none',
                      position: 'relative',
                      textAlign: 'center',
                      margin: '-20px -20px 0',
                      borderRadius: '5px 5px 0 0',
                      padding: '35px',
                    }}
                  >
                    <div
                      className="icon-box"
                      style={{
                        color: '#fff',
                        textAlign: 'center',
                      }}
                    >
                      <center>
                        <i
                          className="font-circle fa fa-check"
                          style={{
                            borderRadius: '60%',
                            display: 'inline-block',
                            zIndex: 9,
                            border: '5px solid #fff',
                            padding: '20px 20px 20px 20px',
                            fontSize: '70px',
                            margin: '-5px 0 0 -5px',
                            textAlign: 'center',
                          }}
                        ></i>
                      </center>
                    </div>
                  </div>
                  <div className="modal-body text-center">
                    <p className="model-text">
                      Hello {userInfo.firstName} <br />
                    </p>
                    <h4
                      style={{
                        textAlign: 'center',
                        fontSize: '18px',
                        margin: '10px 0',
                      }}
                    >
                      {result && result.message ? result.message : null}
                    </h4>

                    <button
                      className="btn btn-success"
                      onClick={resetHandler}
                      style={{
                        color: '#fff',
                        borderRadius: '4px',
                        textDecoration: 'none',
                        transition: 'all 0.4s',
                        lineHeight: 'normal',
                        borderRadius: '30px',
                        marginTop: '10px',
                        padding: '6px 20px 10px ',
                        border: 'none',
                      }}
                    >
                      Earn Benefits
                    </button>
                  </div>
                </Modal>
              </div>
            ) : null}

            <div className="card">
              <div className="card-body ">
                {loading ? (
                  <LoadingBox />
                ) : (
                  <form className="form" onSubmit={handleSubmit}>
                    <div
                      id="container"
                      style={{
                        minHeight: '75vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div className="text-center">
                        <p className="font-size-12">
                          Select tag and input the code
                        </p>
                        <div
                          className="row "
                          onChange={(e) => setColor(e.target.value)}
                          required
                        >
                          <div className="col-lg-6">
                            <div>
                              <label className="form-label card-radio-label mb-3">
                                <input
                                  type="radio"
                                  name="pay-method"
                                  id="pay-methodoption3"
                                  className="card-radio-input"
                                  value="red"
                                />

                                <img
                                  src="assets/images/red_tag.png"
                                  alt="red tag"
                                  className="card-radio me-1"
                                  style={{ width: ' 200px' }}
                                />
                              </label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div>
                              <label className="form-label card-radio-label mb-3">
                                <input
                                  type="radio"
                                  name="pay-method"
                                  id="pay-methodoption1"
                                  className="card-radio-input"
                                  value="green"
                                />
                                <img
                                  src="assets/images/green_tag.png"
                                  className="card-radio me-1"
                                  alt="green tag"
                                  style={{ width: ' 200px' }}
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="input-container mt-3">
                          <div className="container__item">
                            <input
                              type="number"
                              className="form-control form__field"
                              placeholder="Tag Number"
                              onChange={(e) => setTag(e.target.value)}
                              required
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn btn--primary mt-3 btn-block uppercase"
                          >
                            Validate
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
          {alert}
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default VerifyTagPage
