import React, { useState, useEffect, useRef } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  UPDATE_BENEFIT_RESET,
  BENEFIT_DELETE_RESET,
} from '../../../constants/adminConstants/benefitContants'
import { AllBenefits } from '../../../actions/adminActions/benefitsActions/allBenefits'
import { editBenefit } from '../../../actions/adminActions/benefitsActions/editBenefit'
import { allBenefitCategory } from '../../../actions/adminActions/benefitsActions/allBenefitCategory'
import { deleteBenefit } from '../../../actions/adminActions/benefitsActions/deleteBenefit'
import parse from 'html-react-parser'
import { Editor } from '@tinymce/tinymce-react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { MDBDataTableV5 } from 'mdbreact'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

function BenefitPage() {
  const [datatable, setDatatable] = useState({})
  const [alert, setAlert] = useState(null)
  const [itemId, setItemId] = useState('')
  const [open, setOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  const [description, setDescription] = useState('')
  const [tags, setTags] = useState([])
  const [TermsandConditions, setTandC] = useState('')
  const [scroll] = React.useState('paper')

  const descriptionElementRef = useRef(null)

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const editorRef = useRef(null)

  const dispatch = useDispatch()

  const handleOpen = (item) => {
    setOpen(true)
    setTandC(item)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  // All Benefit State
  const benefitsList = useSelector((state) => state.benefitsList)
  const { loading, allbenefits, error } = benefitsList

  // Delete Category State
  const adminBenefitDelete = useSelector((state) => state.adminBenefitDelete)
  const {
    loading: loadingDelete,
    error: deleteErr,
    benefitDeleted,
  } = adminBenefitDelete

  // Benefit Category State
  const benefitCategory = useSelector((state) => state.benefitCategory)
  const {
    loading: loadingCategory,
    error: categoryErr,
    categorylist,
  } = benefitCategory

  // Edit Benefit State
  const updateBenefit = useSelector((state) => state.updateBenefit)
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateErr,
  } = updateBenefit

  const handleEditOpen = (item) => {
    setOpenDialog(true)
    setItemId(item._id)
    setName(item.benefit)
    setCategory(item.category)
    setDescription(item.description)
    setTags(item.features)
    setTandC(item.termsAndConditions)
  }

  const updateHandler = (e) => {
    e.preventDefault()
    if (editorRef.current) {
      let TandC = editorRef.current.getContent()
      dispatch(editBenefit(itemId, name, category, description, tags, TandC))
    }
    setOpenDialog(false)
  }

  useEffect(() => {
    dispatch(AllBenefits())
    dispatch(allBenefitCategory())

    if (benefitDeleted) {
      setTimeout(() => {
        dispatch({ type: BENEFIT_DELETE_RESET })
      }, 2500)
    }

    if (updateSuccess) {
      setTimeout(() => {
        dispatch({ type: UPDATE_BENEFIT_RESET })
      }, 2500)
    }
  }, [dispatch, benefitDeleted, updateSuccess])

  const deleteHandler = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deleteBenefit(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  useEffect(() => {
    if (allbenefits) {
      setDatatable({
        columns: [
          {
            label: 'Benefit',
            field: 'benefit',
          },
          {
            label: 'Category',
            field: 'category',
          },
          {
            label: 'Terms and Conditions',
            field: 'teamsAndConditions',
            sortable: 'disabled',
          },
          {
            label: 'Date created',
            field: 'date',
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
            width: 50,
          },
        ],
        rows: allbenefits.map((item) => ({
          benefit: item.benefit,
          category: item.category ? item.category.name : '',
          teamsAndConditions: (
            <button
              className="btn btn-sm"
              onClick={() => handleOpen(item.termsAndConditions)}
            >
              View
            </button>
          ),
          date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
          action: (
            <>
              <button
                className="btn btn-sm btn-primary"
                style={{ marginRight: '20px' }}
                data-bs-container="#tooltip-container1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Edit"
                onClick={() => handleEditOpen(item)}
              >
                Edit
              </button>
              <button
                className="btn btn-sm text-danger m-0 p-0"
                data-bs-container="#tooltip-container1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Delete"
                onClick={() => deleteHandler(item)}
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </button>
            </>
          ),
        })),
      })
    }
  }, [allbenefits])

  const handleChange = (newTags) => {
    setTags(newTags)
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Benefits</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Points & Benefits</a>
                      </li>
                      <li className="breadcrumb-item active">All Benefits</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {updateLoading && <LoadingBox />}
            {updateErr && <MessageBox variant="danger">{updateErr}</MessageBox>}
            {updateSuccess && (
              <MessageBox variant="success">Benefit success updated</MessageBox>
            )}
            {loadingDelete && <LoadingBox />}
            {benefitDeleted && (
              <MessageBox variant="success">
                {benefitDeleted.message}
              </MessageBox>
            )}
            {deleteErr && <MessageBox variant="danger">{deleteErr}</MessageBox>}
            {categoryErr && (
              <MessageBox variant="danger">{categoryErr}</MessageBox>
            )}
            {/* <!-- end page title --> */}
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive-xl">
                          <MDBDataTableV5
                            hover
                            entriesOptions={[5, 20, 25, 50]}
                            entries={10}
                            pagesAmount={4}
                            data={datatable}
                            searchTop
                            searchBottom={false}
                            barReverse
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {loadingCategory ? (
                  <LoadingBox />
                ) : (
                  <>
                    {/* Update Benefit */}
                    <Dialog
                      open={openDialog}
                      onClose={handleDialogClose}
                      aria-labelledby="form-dialog-title"
                      fullWidth
                      maxWidth={'lg'}
                    >
                      <>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            Edit Famrsured Benefit
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleDialogClose}
                          ></button>
                        </div>
                        <DialogContent>
                          <div className="">
                            <form onSubmit={updateHandler}>
                              <div className="row mb-3">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-12 col-form-label"
                                >
                                  Benefit Name
                                </label>
                                <div className="col-md-12">
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Farmsured Benefit Name"
                                    value={name}
                                    id="example-text-input"
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-12 col-form-label"
                                >
                                  Benefit Category
                                </label>
                                <div className="col-md-12">
                                  <select
                                    className="form-select form-control select2"
                                    onChange={(e) =>
                                      setCategory(e.target.value)
                                    }
                                    value={category._id}
                                  >
                                    <option>
                                      Select the benefit categories
                                    </option>
                                    {categorylist.length === 0 ? (
                                      <>
                                        <option>Please create a benefit</option>
                                      </>
                                    ) : (
                                      categorylist.map((item) => (
                                        <option value={item._id}>
                                          {item.name}
                                        </option>
                                      ))
                                    )}
                                  </select>
                                </div>

                                <div className="col-md-12">
                                  <label className="col-md-12 col-form-label">
                                    Description
                                  </label>
                                  <textarea
                                    id="description"
                                    name="description"
                                    type="text"
                                    className="form-control"
                                    required
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                  <small>
                                    The description is not prominent by default.
                                  </small>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="col-md-12 col-form-label">
                                    Service Benefit Features
                                  </label>

                                  <TagsInput
                                    value={tags}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                </div>

                                <div className="col-md-12">
                                  <label
                                    htmlFor="example-text-input"
                                    className="col-md-12 col-form-label"
                                  >
                                    Terms and Conditions
                                  </label>
                                  <Editor
                                    onInit={(evt, editor) =>
                                      (editorRef.current = editor)
                                    }
                                    initialValue={TermsandConditions}
                                    init={{
                                      height: 500,
                                      menubar: false,
                                      plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount',
                                      ],
                                      toolbar:
                                        'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                      content_style:
                                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    }}
                                  />
                                </div>
                              </div>

                              <DialogActions>
                                <button
                                  className="btn btn-danger btn-sm me-2"
                                  type="button"
                                  onClick={handleDialogClose}
                                >
                                  Close
                                </button>
                                <button
                                  className="btn btn-success btn-sm"
                                  type="submit"
                                >
                                  Update
                                </button>
                              </DialogActions>
                            </form>
                          </div>
                        </DialogContent>
                      </>
                    </Dialog>
                  </>
                )}

                <Dialog
                  open={open}
                  onClose={() => setOpen(false)}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  fullWidth
                  maxWidth={'lg'}
                >
                  <DialogTitle id="scroll-dialog-title">
                    Terms and Conditions
                  </DialogTitle>
                  <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                      id="scroll-dialog-description"
                      ref={descriptionElementRef}
                      tabIndex={-1}
                    >
                      {parse(TermsandConditions)}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)}>Close</Button>
                  </DialogActions>
                </Dialog>
              </div>
            )}
          </div>
          {/* <!-- container-fluid --> */}
        </div>
        {alert}
      </div>
      <Footer />
    </div>
  )
}

export default BenefitPage
