import axios from 'axios'
import {
   ALL_INBOX_MESSAGE_FAIL,
   ALL_INBOX_MESSAGE_REQUEST,
   ALL_INBOX_MESSAGE_SUCCESS,
} from '../../../constants/adminConstants/notificationConstants'

export const allAdminMessage = () => async (dispatch, getState) => {
  dispatch({ type:  ALL_INBOX_MESSAGE_REQUEST })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.get('/api/admin/all-messages', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    })
    dispatch({ type:  ALL_INBOX_MESSAGE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type:  ALL_INBOX_MESSAGE_FAIL, payload: message })
  }
}
