import axios from 'axios'
import {
  ORGANISATION_PRODUCT_LIST_FAIL,
  ORGANISATION_PRODUCT_LIST_REQUEST,
  ORGANISATION_PRODUCT_LIST_SUCCESS,
} from '../../../constants/adminConstants/productConstants'

export const organisationProducts = (organisationId) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: ORGANISATION_PRODUCT_LIST_REQUEST,
    payload: { organisationId },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/organisation-product',
      { organisationId },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: ORGANISATION_PRODUCT_LIST_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: ORGANISATION_PRODUCT_LIST_FAIL, payload: message })
  }
}
