import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { LoadingBox, MessageBox } from '../../../components'
import moment from 'moment'
import { listManufacuturers } from '../../../actions/adminActions/manufactuerActions/listManufacturers'
import { deleteManufacuturer } from '../../../actions/adminActions/manufactuerActions/deleteManufacuturer'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import states from '../../../utilis.js'
import { adminUpdateManufacturer } from '../../../actions/adminActions/manufactuerActions/updateManufacturer'
import { UPDATE_MANUFACTURER_RESET } from '../../../constants/adminConstants/manufacturersConstants'
import SweetAlert from 'react-bootstrap-sweetalert'
import { MDBDataTableV5 } from 'mdbreact'

function AllManufacturerPage() {
  const [alert, setAlert] = useState(null)
  const [datatable, setDatatable] = useState({})
  const [open, setOpen] = useState(false)
  const [itemId, setItemId] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [contactperson, setContactPerson] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')

  const manufacturersList = useSelector((state) => state.manufacturersList)
  const { loading, error, manufacturers } = manufacturersList

  const manufacturerDelete = useSelector((state) => state.manufacturerDelete)
  const {
    loading: deleteLoading,
    error: deleteError,
    success,
  } = manufacturerDelete

  const manufacturerUpdate = useSelector((state) => state.manufacturerUpdate)
  const {
    loading: updateLoading,
    error: updateError,
    result,
  } = manufacturerUpdate

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listManufacuturers())

    if (result) {
      setTimeout(() => {
        dispatch({ type: UPDATE_MANUFACTURER_RESET })
      }, 2100)
    }

    if (success) {
      setAlert(null)
    }
  }, [dispatch, success, result])

  const deleteHandler = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => dispatch(deleteManufacuturer(item._id))}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        You can not undo this!!!
      </SweetAlert>,
    )
  }

  const editManufacturer = (item) => {
    setOpen(true)
    setItemId(item._id)
    setCompanyName(item.name)
    setEmail(item.email)
    setContactPerson(item.contactPerson)
    setPhone(item.phone)
    setAddress(item.address)
    setCity(item.city)
    setState(item.state)
  }

  const updateManufacturer = (e) => {
    e.preventDefault()

    dispatch(
      adminUpdateManufacturer(
        itemId,
        companyName,
        contactperson,
        phone,
        email,
        address,
        city,
        state,
      ),
    )
    setOpen(false)
  }

  useEffect(() => {
    if (manufacturers) {
      setDatatable({
        columns: [
          {
            label: 'Company Phone',
            field: 'companyPhone',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'phone',
            },
          },
          {
            label: 'Company Name',
            field: 'name',
            width: 400,
          },
          {
            label: 'Company Email',
            field: 'email',
            width: 200,
          },
          {
            label: 'Contact Name',
            field: 'contactName',
            width: 400,
          },
          {
            label: 'State',
            field: 'location',
            width: 400,
          },

          {
            label: 'Registration Date',
            field: 'date',
            // width: 100,
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'disabled',
          },
        ],
        rows:
          manufacturers &&
          manufacturers.length > 0 &&
          manufacturers.map((item) => ({
            companyPhone: item.phone ? `0${item.phone}` : '',
            email: item.email,
            name: item.name,
            location: item.state,
            contactName: item.contactPerson,
            date: moment(item.createdAt).format('Do MMMM YYYY,  h:mm a'),
            action: (
              <div>
                <button
                  className="btn btn-sm btn-primary"
                  style={{ marginRight: '15px' }}
                  data-bs-container="#tooltip-container1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                  onClick={() => editManufacturer(item)}
                >
                  Edit
                </button>

                <button
                  className="btn btn-sm m-0 p-0 text-danger"
                  data-bs-toggle="tooltip"
                  data-placement="top"
                  title="Delete"
                  onClick={() => deleteHandler(item)}
                >
                  <i className="mdi mdi-trash-can font-size-18"></i>
                </button>
              </div>
            ),
          })),
      })
    }
  }, [manufacturers])

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Manufacturers</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Brand</a>
                      </li>
                      <li className="breadcrumb-item active">
                        All Manufacturers
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            {deleteLoading ? <LoadingBox /> : null}
            {deleteError ? (
              <MessageBox variant="danger">{deleteError}</MessageBox>
            ) : null}
            {updateLoading ? <LoadingBox /> : null}
            {updateError ? (
              <MessageBox variant="danger">{updateError}</MessageBox>
            ) : null}

            {result ? (
              <MessageBox variant="success">{result.message}</MessageBox>
            ) : null}

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <div>
                        <a
                          href="/create-manufacturer"
                          className="btn btn-success btn-sm mb-2"
                        >
                          <i className="mdi mdi-plus me-2"></i> Add Manufacturer
                        </a>
                      </div>
                    </div>

                    {loading ? (
                      <LoadingBox />
                    ) : error ? (
                      <MessageBox variant="danger">{error}</MessageBox>
                    ) : (
                      <div className="table-responsive-x1">
                        <MDBDataTableV5
                          hover
                          entriesOptions={[5, 20, 25, 50]}
                          entries={10}
                          pagesAmount={4}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                          barReverse
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- end row --> */}

            {/* Edit Modal */}
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth="md"
            >
              <>
                <div className="modal-header">
                  <h5 className="modal-title">Edit manufacturer</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setOpen(false)}
                  ></button>
                </div>
                <DialogContent>
                  <div className="">
                    <form onSubmit={updateManufacturer}>
                      <div className="row">
                        <div className="col-6">
                          <div className=" mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Company Name
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Company Name"
                                id="example-text-input"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className=" mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Company Email
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="email"
                                placeholder="Company Email"
                                id="example-text-input"
                                disabled
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className=" mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Contact Person
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Contact Person"
                                id="example-text-input"
                                value={contactperson}
                                onChange={(e) =>
                                  setContactPerson(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className=" mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Phone
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Phone"
                                id="example-text-input"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className=" mb-3">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                          >
                            Address
                          </label>
                          <div className="col-md-12">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Address"
                              id="example-text-input"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className=" mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              City
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="City"
                                id="example-text-input"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className=" mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              State
                            </label>
                            <div className="col-md-12">
                              <select
                                onChange={(e) => setState(e.target.value)}
                                className="select2 form-select"
                                value={state}
                              >
                                {states.map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <DialogActions>
                        <button
                          type="button"
                          className="btn btn-light btn-sm waves-effect"
                          data-bs-dismiss="modal"
                          onClick={() => setOpen(false)}
                        >
                          Close
                        </button>
                        <button
                          className="btn btn-success btn-sm"
                          type="submit"
                          onClick={updateManufacturer}
                        >
                          Update
                        </button>
                      </DialogActions>
                    </form>
                  </div>
                </DialogContent>
              </>
            </Dialog>
          </div>
          {/* <!-- container-fluid --> */}
          {alert}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AllManufacturerPage
