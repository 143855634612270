import axios from 'axios'
import {
  UPDATE_PROTOCOL_BENEFIT_FAIL,
  UPDATE_PROTOCOL_BENEFIT_REQUEST,
  UPDATE_PROTOCOL_BENEFIT_SUCCESS,
} from '../../../constants/adminConstants/packageConstants'

export const editPackageBenefit = (
  itemId,
  benefitId,
  benefit,
  benefitFrom,
  benefitTo,
  minPointToQualify,
) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_PROTOCOL_BENEFIT_REQUEST,
    payload: {
      itemId,
      benefitId,
      benefit,
      benefitFrom,
      benefitTo,
      minPointToQualify,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/protocol-benefit/update',
      {
        itemId,
        benefitId,
        benefit,
        benefitFrom,
        benefitTo,
        minPointToQualify,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: UPDATE_PROTOCOL_BENEFIT_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: UPDATE_PROTOCOL_BENEFIT_FAIL, payload: message })
  }
}
