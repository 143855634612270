import axios from 'axios'
import {
  CLAIM_DELETE_FAIL,
  CLAIM_DELETE_REQUEST,
  CLAIM_DELETE_SUCCESS,
} from '../../../constants/adminConstants/benefitContants'

export const deleteClaim = (claimId) => async (dispatch, getState) => {
  dispatch({ type: CLAIM_DELETE_REQUEST, payload: claimId })
  const {
    userSignin: { userInfo },
  } = getState()
  try {
    const { data } = await axios.post(
      `/api/admin/delete-claim`,
      { claimId },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      },
    )
    dispatch({ type: CLAIM_DELETE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: CLAIM_DELETE_FAIL, payload: message })
  }
}
