import axios from 'axios'
import {
  REPORT_SUBMISSION_FAIL,
  REPORT_SUBMISSION_REQUEST,
  REPORT_SUBMISSION_SUCCESS,
} from '../../constants/authConstants/reportConstants'

export const report = (
  uniqueID,
  dateOfReport,
  category,
  message,
  bsp,
  bspCode,
  tagPin,
  feature,
) => async (dispatch) => {
  dispatch({
    type: REPORT_SUBMISSION_REQUEST,
    payload: {
      uniqueID,
      dateOfReport,
      category,
      message,
      bsp,
      bspCode,
      tagPin,
      feature,
    },
  })

  try {
    const { data } = await axios.post('/api/report', {
      uniqueID,
      dateOfReport,
      category,
      message,
      bsp,
      bspCode,
      tagPin,
      feature,
    })
    dispatch({ type: REPORT_SUBMISSION_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: REPORT_SUBMISSION_FAIL, payload: message })
  }
}
