import React, { useEffect, useState } from 'react'
import { Header, LeftSidebar, RightSidebar, Footer } from '../../../partials'


import { MDBDataTableV5 } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { allServiceProduct } from '../../../actions/serviceproviderActions/productsAction/allServiceProduct'
import { updateServiceProduct } from '../../../actions/serviceproviderActions/productsAction/updateServiceProduct'
import LoadingBox from '../../../components/LoadingBox'
import { formatNumber } from '../../../components/functions'
import MessageBox from '../../../components/MessageBox'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import NaijaStates from 'naija-state-local-government'
import states from '../../../utilis'
import { listProducts } from '../../../actions/serviceproviderActions/productsAction/products'
import { EDIT_SERVICE_PRODUCT_RESET } from '../../../constants/serviceProviderConstants/productConstants'

export default function ProductServicePage() {
    const [datatable, setDatatable] = useState({})
    const [open, setOpen] = useState(false)

    const [itemId, setItemId] = useState('')
    const [product, setProduct] = useState('')
    const [price, setPrice] = useState(0)
    const [markup, setMarkup] = useState(0)
    const [total, setTotal] = useState(0)
    const [state, setState] = useState('')
    const [inputList, setinputList] = useState([{ lga: '', price: '' }])


    const dispatch = useDispatch()

    // Service Product List State
    const allServices = useSelector((state) => state.allServices)
    const { loading, result, error } = allServices

    // All product State
    const serviceProducts = useSelector((state) => state.serviceProducts)
    const { loading: loadingProducts, products, error: productsError } = serviceProducts

    const editServiceProduct = useSelector((state) => state.editServiceProduct)
    const { loading: loadingUpdate, updated, error: updateError } = editServiceProduct

    useEffect(() => {
        dispatch(allServiceProduct())
        dispatch(listProducts())
        if (updated) {
            setOpen(false)
            setTimeout(() => {
                dispatch({ type: EDIT_SERVICE_PRODUCT_RESET })
            }, 2500)
        }
    }, [updated])

    useEffect(() => {
        if (result) {
            setDatatable({
                columns: [
                    {
                        label: 'Product/Service Name',
                        field: 'name',
                        width: "200px",
                    },
                    {
                        label: 'Service Category',
                        field: 'category',
                        width: 100,
                    },
                    {
                        label: 'Product Price',
                        field: 'price',
                        width: 100,
                    },
                    {
                        label: 'Price Markup (%)',
                        field: 'markup',
                        width: 100,
                    },
                    {
                        label: 'Total Price',
                        field: 'totalPrice',
                        width: 100,
                    },
                    {
                        label: 'Logistics',
                        field: 'logistics',
                        maxWidth: 100,
                        wrap: true
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        sort: 'disabled',
                    },
                ],
                rows: result && result.data.map((item) => ({
                    name: item.name,
                    category:
                        item.serviceCategory && item.serviceCategory.name ? item.serviceCategory.name : '',
                    description: item.description,
                    price: item.product.price ? `NGN ${formatNumber(item.product.price)}` : '',
                    markup: item.priceMarkup,
                    totalPrice: `NGN ${formatNumber(item.product.price * (item.priceMarkup / 100) + item.product.price)}`,
                    logistics: item.logistics.map((itm) => (<div className="badge badge-soft-success font-size-10 me-1">
                        {itm.state}-{itm.lga}
                    </div>)),
                    action: (
                        <>
                            <button
                                className="btn btn-sm btn-primary"
                                style={{ marginRight: '15px' }}
                                data-bs-container="#tooltip-container1"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Edit"
                                onClick={() => editProduct(item)}
                            >
                                Update
                            </button>
                        </>
                    ),
                })),
            })
        }
    }, [result])


    const handleinputchange = (e, index) => {
        const { name, value } = e.target
        const list = [...inputList]
        list[index][name] = value
        list[index]["state"] = state
        setinputList(list)
    }

    const handleremove = (index) => {
        const list = [...inputList]
        list.splice(index, 1)
        setinputList(list)
    }

    const handleaddclick = () => {
        setinputList([...inputList, { lga: '', price: '' }])
    }

    useEffect(() => {
        if (product) {
            const result = products && products.find((itm) => itm._id === product)
            setPrice(result && result.price)
        }

        if (product && markup) {
            const result = (price * (markup / 100) + price)
            setTotal(result)
        }
    }, [product, markup])

    const editProduct = (item) => {
        setOpen(true)
        setItemId(item._id)
        setProduct(item.product._id)
        setPrice(item.product.price)
        setMarkup(item.priceMarkup)
        setTotal(item.product.price * (item.priceMarkup / 100) + item.product.price)
        setState(item.state)
        setinputList(item.logistics)
    }

    const handleEditDialogClose = () => {
        setOpen(false)
        setProduct()
        setPrice()
        setMarkup()
        setTotal()
        setState()
    }

    const handleEditSubmit = (e) => {
        e.preventDefault()

        dispatch(updateServiceProduct(itemId, total, markup, state, inputList))
    }


    return (
        <div id="layout-wrapper">
            <Header />
            <LeftSidebar />
            <RightSidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Products/Service</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Farmsured</a>
                                            </li>
                                            <li className="breadcrumb-item active">Product/Service</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='card'>
                            <div className='card-body'>
                                {error ? <MessageBox variant='danger'>{error}</MessageBox> : null}
                                {updated ? <MessageBox variant='success'>{updated.message}</MessageBox> : null}
                                {loadingUpdate ? <LoadingBox /> : null}
                                <div className='row'>
                                    <div className='col-2'>
                                        <label htmlFor="example-text-input8">
                                            Unique Code
                                        </label>
                                        <h4 className="card-title">{result && result.service && result.service.uniqueCode}</h4>
                                    </div>
                                    {/* <div className="col-2">
                                        <div className="mb-3">
                                            <label
                                                className="form-label"
                                                forHtml="contactPersonName"
                                            >
                                                Select Assigned State
                                            </label>
                                            <select
                                                // onChange={(e) =>
                                                //     setProductCategory(e.target.value)
                                                // }
                                                className="select2 form-select"
                                                required
                                            >
                                                <option>Select</option>
                                                {
                                                    result && result.service && result.service.locations.map((itm) => <option value={itm.state}>{itm.state}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-2">
                                        <div className="mb-3">
                                            <label
                                                className="form-label"
                                                forHtml="contactPersonName"
                                            >
                                                Select Assigned LGA
                                            </label>
                                            <select
                                                // onChange={(e) =>
                                                //     setProductCategory(e.target.value)
                                                // }
                                                className="select2 form-select"
                                                required
                                            >
                                                <option>Select</option>
                                                {
                                                    result && result.service && result.service.locations.map((itm) => <option value={itm.lga}>{itm.lga}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div> */}
                                </div>
                                {
                                    loading ? <LoadingBox /> :
                                        <MDBDataTableV5
                                            hover
                                            entriesOptions={[5, 10, 20, 25, 50]}
                                            entries={10}
                                            pagesAmount={4}
                                            data={datatable}
                                            searchTop
                                            searchBottom={false}
                                            barReverse
                                        />
                                }


                            </div>
                        </div>

                    </div>


                    {/* Edit Modal */}
                    <Dialog
                        open={open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="form-dialog-title"
                        fullWidth
                        maxWidth="md"
                    >
                        <>
                            <div className="modal-header">
                                <h5 className="modal-title">Update product & service information</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => setOpen(false)}
                                ></button>
                            </div>
                            <DialogContent>
                                <div className='row'>
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label
                                                className="form-label"
                                                forHtml="contactPersonName"
                                            >
                                                Product
                                            </label>
                                            <select
                                                onChange={(e) =>
                                                    setProduct(e.target.value)
                                                }
                                                className="select2 form-select"
                                                required
                                                disabled
                                                value={product}
                                            >
                                                <option>Select</option>
                                                {loadingProducts ? (
                                                    'Loading...'
                                                ) : products && products.length > 0 ? (
                                                    <>
                                                        {products.map((item) => (
                                                            <option value={item._id} key={item._id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : (
                                                    'there is no product category'
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label
                                                className="form-label"
                                                forHtml="ProductPrice"
                                            >
                                                Price
                                            </label>
                                            <input
                                                id="ProductPrice"
                                                name="ProductPrice"
                                                type="number"
                                                className="form-control"
                                                required
                                                value={price}
                                                placeholder="Product Price"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label
                                                className="form-label"
                                                forHtml="ProductPrice"
                                            >
                                                Price Markup
                                            </label>
                                            <input
                                                id="ProductPrice"
                                                name="ProductPrice"
                                                type="number"
                                                className="form-control"
                                                required
                                                onChange={(e) => setMarkup(e.target.value)}
                                                value={markup}
                                                placeholder="Product Price (%)"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label
                                                className="form-label"
                                                forHtml="ProductPrice"
                                            >
                                                Total Price
                                            </label>
                                            <input
                                                id="ProductPrice"
                                                name="ProductPrice"
                                                type="number"
                                                className="form-control"
                                                required
                                                value={total}
                                                placeholder="Product Price"
                                                disabled
                                            />
                                        </div>
                                    </div>


                                    <h4 className="card-title">Logistics</h4>

                                    <div className="col-md-8 mb-3 mt-1">
                                        <label htmlFor="example-text-input8">
                                            Select assigned state
                                        </label>
                                        <select
                                            className="form-select form-control select2"
                                            required
                                            placeholder="Select a state"
                                            onChange={(e) => setState(e.target.value)}
                                            value={state}
                                        >
                                            <option>Select a state</option>
                                            {states.map((item) => (
                                                <option value={item}>{item}</option>
                                            ))}
                                        </select>
                                    </div>

                                    {inputList.map((x, i) => {
                                        return (
                                            <div className="row mb-3">
                                                <div className="form-group col-md-8 mb-2">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-12 col-form-label"
                                                    >
                                                        Select assigned local government area
                                                    </label>
                                                    <select
                                                        className="form-select form-control select2"
                                                        required
                                                        name="lga"
                                                        placeholder="Select a local government area"
                                                        onChange={(e) => handleinputchange(e, i)}
                                                        value={x.lga}
                                                    >
                                                        {state && state === 'Select a state' ? (
                                                            <option>
                                                                Select a local government area
                                                            </option>
                                                        ) : state ? (
                                                            <>
                                                                <option>
                                                                    Select a local government area
                                                                </option>
                                                                {NaijaStates.lgas(state).lgas.map(
                                                                    (item) => (
                                                                        <option value={item}>{item}</option>
                                                                    ),
                                                                )}
                                                            </>
                                                        ) : (
                                                            <option>
                                                                Select a local government area
                                                            </option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-8">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-12 col-form-label"
                                                    >
                                                        Delivery price
                                                    </label>
                                                    <input
                                                        id="price"
                                                        name="price"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Price"
                                                        required
                                                        onChange={(e) => handleinputchange(e, i)}
                                                        value={x.price}
                                                    />
                                                </div>
                                                <div className="form-group col-md-4 mt-4">
                                                    {inputList.length !== 1 && (
                                                        <button
                                                            className="btn btn-danger mx-1"
                                                            type="button"
                                                            onClick={() => handleremove(i)}
                                                        >
                                                            -
                                                        </button>
                                                    )}
                                                    {inputList.length - 1 === i && (
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={handleaddclick}
                                                        >
                                                            +
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}

                                    <DialogActions className="mt-3">
                                        <button
                                            type="button"
                                            className="btn btn-danger btn-sm"
                                            onClick={handleEditDialogClose}
                                        >
                                            Close
                                        </button>
                                        <button className="btn btn-success btn-sm" type="submit" onClick={handleEditSubmit}>
                                            Save
                                        </button>
                                    </DialogActions>
                                </div>
                            </DialogContent>
                        </>
                    </Dialog>
                </div>
            </div>
            <Footer />
        </div>
    )
}
