import axios from 'axios'
import {
    FUND_ALLOCATION_TRANSACTION_FAIL,
    FUND_ALLOCATION_TRANSACTION_REQUEST,
    FUND_ALLOCATION_TRANSACTION_SUCCESS,
} from '../../../constants/adminConstants/farmtrove/fundUserConstants'

export const getFundAllocationHistory = () => async (dispatch, getState) => {
    dispatch({
        type: FUND_ALLOCATION_TRANSACTION_REQUEST,
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.get('/api/admin/farmtrove/fund-allocation/transactions', {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })
        dispatch({ type: FUND_ALLOCATION_TRANSACTION_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: FUND_ALLOCATION_TRANSACTION_FAIL, payload: message })
    }
}
