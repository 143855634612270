import axios from 'axios'
import {
    ALL_PUSH_NOTIFICATION_FAIL,
    ALL_PUSH_NOTIFICATION_REQUEST,
    ALL_PUSH_NOTIFICATION_SUCCESS,
} from '../../../constants/adminConstants/notificationConstants'

export const allPushNotifications = () => async (dispatch, getState) => {
    dispatch({ type: ALL_PUSH_NOTIFICATION_REQUEST })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.get('/api/admin/all-notifications', {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        })
        dispatch({ type: ALL_PUSH_NOTIFICATION_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: ALL_PUSH_NOTIFICATION_FAIL, payload: message })
    }
}
