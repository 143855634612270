import axios from 'axios'
import {
  WITHDRAW_FUND_FAIL,
  WITHDRAW_FUND_REQUEST,
  WITHDRAW_FUND_SUCCESS,
} from '../../../constants/authConstants/walletConstants'

export const submitWithdrawRequest = (
  bankCode,
  account,
  accountName,
  withdrawAmount,
  withdrawRemark,
  withdrawPIN
) => async (dispatch, getState) => {
  dispatch({
    type: WITHDRAW_FUND_REQUEST,
    payload: { bankCode, account, accountName, withdrawAmount, withdrawRemark, withdrawPIN },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/wallet/withdraw',
      { bankCode, account, accountName, withdrawAmount, withdrawRemark, withdrawPIN },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: WITHDRAW_FUND_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: WITHDRAW_FUND_FAIL, payload: message })
  }
}
