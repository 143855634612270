import axios from 'axios'
import {
  WEATHER_ANALYSIS_FAIL,
  WEATHER_ANALYSIS_REQUEST,
  WEATHER_ANALYSIS_SUCCESS,
} from '../../../constants/farmerConstants/packagesConstants'

export const weatherAnalysis = (message) => async (dispatch, getState) => {
  dispatch({
    type: WEATHER_ANALYSIS_REQUEST,
    payload: { message },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/farmer/weather-analysis',
      { message },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: WEATHER_ANALYSIS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: WEATHER_ANALYSIS_FAIL,
      payload: message,
    })
  }
}
