import axios from 'axios'
import {
  MANUFACTURER_PRODUCT_ANALYSIS_REQUEST,
  MANUFACTURER_PRODUCT_ANALYSIS_SUCCESS,
  MANUFACTURER_PRODUCT_ANALYSIS_FAIL,
} from '../../../constants/manufacturerConstants/dashboardConstants'

export const tagAnalysis = (itemId) => async (dispatch, getState) => {
    dispatch({
      type: MANUFACTURER_PRODUCT_ANALYSIS_REQUEST,
      payload: itemId,
    })
    try {
      const {
        userSignin: { userInfo },
      } = getState()
      const { data } = await axios.post(
        '/api/manufacturer/product-analysis',
        { itemId },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        },
      )
      dispatch({ type: MANUFACTURER_PRODUCT_ANALYSIS_SUCCESS, payload: data })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      dispatch({ type: MANUFACTURER_PRODUCT_ANALYSIS_FAIL, payload: message })
    }
  }
