import React, { useState, useEffect, useRef } from 'react'
import { Footer, Header, LeftSidebar, RightSidebar } from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import { createBenefit } from '../../../actions/adminActions/benefitsActions/createBenefit'
import { allBenefitCategory } from '../../../actions/adminActions/benefitsActions/allBenefitCategory'
import { BENEFITS_CREATE_RESET } from '../../../constants/adminConstants/benefitContants'
import { LoadingBox, MessageBox } from '../../../components'
import { Editor } from '@tinymce/tinymce-react'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

function CreateBenefitPage() {
  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  const [description, setDescription] = useState('')
  const [tags, setTags] = useState([])
  const editorRef = useRef(null)

  const dispatch = useDispatch()

  // Benefit Category State
  const benefitCategory = useSelector((state) => state.benefitCategory)
  const {
    loading: loadingCategory,
    error: categoryErr,
    categorylist,
  } = benefitCategory

  // New Benefit State
  const benefitsCreate = useSelector((state) => state.benefitsCreate)
  const {
    loading: loadingCreate,
    newBenefit,
    error: errorCreate,
  } = benefitsCreate

  const createBenefitHandler = (e) => {
    e.preventDefault()
    if (editorRef.current) {
      let TandC = editorRef.current.getContent()
      dispatch(createBenefit(name, category, description, tags, TandC))
    }
  }

  useEffect(() => {
    dispatch(allBenefitCategory())

    if (errorCreate) {
      setTags([])
    }

    if (newBenefit) {
      setTags([])
      setTimeout(() => {
        dispatch({ type: BENEFITS_CREATE_RESET })
      }, 2500)
    }
  }, [dispatch, newBenefit, errorCreate])

  const handleChange = (newTags) => {
    setTags(newTags)
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Benefits</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Benefits</a>
                      </li>
                      <li className="breadcrumb-item active">New Benefit</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {loadingCreate ? (
              <LoadingBox />
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  {newBenefit ? (
                    <MessageBox variant="success">
                      {newBenefit.message}
                    </MessageBox>
                  ) : null}
                  {errorCreate ? (
                    <MessageBox variant="danger">{errorCreate}</MessageBox>
                  ) : null}
                  {categoryErr ? (
                    <MessageBox variant="danger">{categoryErr}</MessageBox>
                  ) : null}
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={createBenefitHandler}>
                        <div className="row mb-3">
                          <div className="col-md-12">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Benefit Name
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Farmsured Benefit Name"
                              id="example-text-input"
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </div>

                          <div className="col-md-12">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Benefit Category
                            </label>
                            <select
                              className="form-select form-control select2"
                              required
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <option>Select a benefit category</option>
                              {loadingCategory ? (
                                'Loading...'
                              ) : categorylist.length === 0 ? (
                                <>
                                  <option>
                                    Please create a benefit category
                                  </option>
                                </>
                              ) : (
                                categorylist.map((item) => (
                                  <option value={item._id}>{item.name}</option>
                                ))
                              )}
                            </select>
                          </div>

                          <div className="col-md-12">
                            <label
                              className="col-md-12 col-form-label"
                            >
                              Description
                            </label>
                            <textarea
                              id="description"
                              name="description"
                              type="text"
                              className="form-control"
                              required
                              onChange={(e) => setDescription(e.target.value)}
                            />
                            <small>
                              The description is not prominent by default.
                            </small>
                          </div>

                          <div className="col-md-12 mb-3">
                            <label
                              className="col-md-12 col-form-label"
                            >
                              Service Benefit Features
                            </label>

                            <TagsInput
                              value={tags}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </div>

                          <div className="col-md-12">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                            >
                              Terms and Conditions
                            </label>
                            <Editor
                              onInit={(evt, editor) =>
                                (editorRef.current = editor)
                              }
                              initialValue=""
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount',
                                ],
                                toolbar:
                                  'undo redo | formatselect | ' +
                                  'bold italic backcolor | alignleft aligncenter ' +
                                  'alignright alignjustify | bullist numlist outdent indent | ' +
                                  'removeformat | help',
                                content_style:
                                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                              }}
                            />
                          </div>
                        </div>

                        <button
                          className="btn btn-success btn-bg"
                          type="submit"
                          onSubmit={createBenefitHandler}
                        >
                          Create Benefit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CreateBenefitPage
