import moment from 'moment'

//  Analysis on Green Tags Scanned Today
export const todaygreenanalysis = (data) => {
  let date = moment().format('YYYY-MM-DD')

  const byTwoHours = Array(12)
    .fill(0)
    .map((_, index) => `${String(index * 2).padStart(2, '')}`)

  const byTwoHoursLookup = byTwoHours.reduce(
    (acc, range) => ({ ...acc, [range]: [] }),
    {},
  )

  data &&
    data.length > 0 &&
    data
      .filter((item) => {
        const d = moment(item.greenActivatedAt).format('YYYY-MM-DD')
        if (d === date && item.green !== false) return item
        return false
      })
      .forEach((item) => {
        const hour = moment(item.greenActivatedAt).hour()
        for (let i = byTwoHours.length - 1; i >= 0; i--) {
          if (hour >= byTwoHours[i]) {
            byTwoHoursLookup[byTwoHours[i]].push(item)
            break
          }
        }
      })

  return byTwoHoursLookup
}

// Analysis on Red Tags Scanned Today
export const todayredanalysis = (data) => {
  let date = moment().format('YYYY-MM-DD')

  const byTwoHours = Array(12)
    .fill(0)
    .map((_, index) => `${String(index * 2).padStart(2, '')}`)

  const byTwoHoursLookup = byTwoHours.reduce(
    (acc, range) => ({ ...acc, [range]: [] }),
    {},
  )

  data &&
    data.length > 0 &&
    data
      .filter((item) => {
        const d = moment(item.redActivatedAt).format('YYYY-MM-DD')
        if (d === date && item.red !== false) return item
        return false
      })
      .forEach((item) => {
        const hour = moment(item.redActivatedAt).hour()

        for (let i = byTwoHours.length - 1; i >= 0; i--) {
          if (hour >= byTwoHours[i]) {
            byTwoHoursLookup[byTwoHours[i]].push(item)
            break
          }
        }
      })
  return byTwoHoursLookup
}

export const weeklyRed = (array) => {
  const d = new Date()
  let year = d.getFullYear()

  let date = moment().format('YYYY-MM')
  const byWeekly = Array(7)
    .fill(0)
    .map((_, index) => `${year}-${String(index * 1).padStart(2, '')}`)

  const byWeeklyLookup = byWeekly.reduce(
    (acc, range) => ({ ...acc, [range]: [] }),
    {},
  )

  array
    .filter((item) => {
      const d = moment(item.redActivatedAt).format('YYYY-MM')
      if (d === date && item.red !== false) return item
      return false
    })
    .forEach((item) => {
      // create a composed key: 'year-week'
      const yearWeek = `${moment(item.redActivatedAt).year()}-${moment(
        item.redActivatedAt,
      ).weekday()}`

      for (let i = byWeekly.length - 1; i >= 0; i--) {
        if (yearWeek === byWeekly[i]) {
          byWeeklyLookup[byWeekly[i]].push(item)
          break
        }
      }
    })
  return byWeeklyLookup
}

export const weeklyGreen = (analysis) => {
  const d = new Date()
  let year = d.getFullYear()

  let date = moment().format('YYYY-MM')
  const byWeekly = Array(7)
    .fill(0)
    .map((_, index) => `${year}-${String(index * 1).padStart(2, '')}`)

  const byWeeklyLookup = byWeekly.reduce(
    (acc, range) => ({ ...acc, [range]: [] }),
    {},
  )

  analysis
    .filter((item) => {
      const d = moment(item.greenActivatedAt).format('YYYY-MM')
      if (d === date && item.green !== false) {
        return item
      }
      return false
    })
    .forEach((item) => {
      // create a composed key: 'year-week'
      const yearWeek = `${moment(item.greenActivatedAt).year()}-${moment(
        item.greenActivatedAt,
      ).weekday()}`

      for (let i = byWeekly.length - 1; i >= 0; i--) {
        if (yearWeek === byWeekly[i]) {
          byWeeklyLookup[byWeekly[i]].push(item)
          break
        }
      }
    })
  return byWeeklyLookup
}

export const monthlyred = (analysis) => {
  const d = new Date()
  let year = d.getFullYear()

  const byMonth = Array(12)
    .fill(0)
    .map((_, index) => `${year}-${String(index * 1).padStart(2, '')}`)

  const byMonthLookup = byMonth.reduce(
    (acc, range) => ({ ...acc, [range]: [] }),
    {},
  )

  analysis
    .filter((item) => {
      if (item.red !== false) {
        return item
      }
      return false
    })
    .forEach((item) => {
      // create a composed key: 'year-month'
      const yearMonth = `${moment(item.redActivatedAt).year()}-${moment(
        item.redActivatedAt,
      ).month()}`

      for (let i = byMonth.length - 1; i >= 0; i--) {
        if (yearMonth === byMonth[i]) {
          byMonthLookup[byMonth[i]].push(item)
          break
        }
      }
    })
  return byMonthLookup
}

export const monthlygreen = (analysis) => {
  const d = new Date()
  let year = d.getFullYear()

  const byMonth = Array(12)
    .fill(0)
    .map((_, index) => `${year}-${String(index * 1).padStart(2, '')}`)

  const byMonthLookup = byMonth.reduce(
    (acc, range) => ({ ...acc, [range]: [] }),
    {},
  )

  analysis
    .filter((item) => {
      if (item.green !== false) {
        return item
      }
      return false
    })
    .forEach((item) => {
      // create a composed key: 'year-month'
      const yearMonth = `${moment(item.greenActivatedAt).year()}-${moment(
        item.greenActivatedAt,
      ).month()}`

      for (let i = byMonth.length - 1; i >= 0; i--) {
        if (yearMonth === byMonth[i]) {
          byMonthLookup[byMonth[i]].push(item)
          break
        }
      }
    })
  return byMonthLookup
}

export const openReport = (data, status) => {
  return (
    data &&
    data.filter((item) => {
      if (item.status === status) {
        return item
      }
      return false
    }).length
  )
}

export const openRequest = (data, status) => {
  return (
    data &&
    data.filter((item) => {
      if (item.status === status) {
        return item
      }
      return false
    }).length
  )
}

export const dailyRequest = (analysis) => {
  let date = moment(new Date()).format('DD/MM/YYYY')
  let result =
    analysis &&
    analysis.tagsRequest
      .filter((item) => {
        if (moment(item.modified_at).format('DD/MM/YYYY') === date) {
          return item
        }
        return false
      })
      .map((item) => item.quantity)
      .reduce((prev, curr) => prev + curr, 0)
  return result
}

export const monthlyRequest = (analysis) => {
  let date = moment(new Date()).format('MM/YYYY')
  let result =
    analysis &&
    analysis.tagsRequest
      .filter((item) => {
        if (moment(item.modified_at).format('MM/YYYY') === date) {
          return item
        }
        return false
      })
      .map((item) => item.quantity)
      .reduce((prev, curr) => prev + curr, 0)
  return result
}

export const yearlyRequest = (analysis) => {
  let date = moment(new Date()).format('YYYY')
  let result =
    analysis &&
    analysis.tagsRequest
      .filter((item) => {
        if (moment(item.modified_at).format('YYYY') === date) {
          return item
        }
        return false
      })
      .map((item) => item.quantity)
      .reduce((prev, curr) => prev + curr, 0)
  return result
}

export const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const earnPointsAnalysis = (data) => {
  return data.reduce(function (sum, current) {
    return sum + current.points
  }, 0)
}

export const generatePassword = () => {
  let len = 12

  let length = len ? len : 10
  let string = 'abcdefghijklmnopqrstuvwxyz' //to upper
  let numeric = '0123456789'
  let punctuation = '!@#$%^&*()_+~`|}{[]:;?><,./-='
  let password = ''
  let character = ''
  let crunch = true
  while (password.length < length) {
    const entity1 = Math.ceil(string.length * Math.random() * Math.random())
    const entity2 = Math.ceil(numeric.length * Math.random() * Math.random())
    const entity3 = Math.ceil(
      punctuation.length * Math.random() * Math.random(),
    )
    let hold = string.charAt(entity1)
    hold = password.length % 2 === 0 ? hold.toUpperCase() : hold
    character += hold
    character += numeric.charAt(entity2)
    character += punctuation.charAt(entity3)
    password = character
  }
  password = password
    .split('')
    .sort(function () {
      return 0.5 - Math.random()
    })
    .join('')
  return password.substr(0, len)
}

export const qualifiedList = (data) => {
  let result = data.filter((item) => {
    const time = item.batch.product.category.cycleDuration

    let hours = moment().diff(moment(item.redActivatedAt), 'hours', true)

    if (hours < time) {
      return item
    }

    return false
  })

  return result
}

export const claim = (data, status) => {
  return data.claim.filter((item) => {
    if (item.status === status) {
      return item
    }
    return false
  }).length
}

export const highestProducts = (array) => {
  let date = moment(new Date()).format('DD/MM/YYYY')
  return array
    .filter((item) => {
      if (
        item.red !== false &&
        moment(item.redActivatedAt).format('DD/MM/YYYY') === date
      ) {
        return item
      }
      return false
    })
    .reduce((group, item) => {
      const { name } = item.batch.product
      group[name] = group[name] ?? []
      group[name].push(item)
      return group
    }, {})
}

export const recentOrder = (array) => {
  return array
    .filter((item) => {
      if (
        item.status === 'Pending' ||
        item.status === 'Reviewing_Order' ||
        item.status === 'Open' ||
        item.status === 'Request_Sent'
      ) {
        return item
      }
      return false
    })
    .sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at)
    })
    .slice(0, 5)
}

export const thisMonth = (array) => {
  let date = moment().format('YYYY-MM')
  return array.filter((item) => {
    if (moment(item.redActivatedAt).format('YYYY-MM') === date) return item
    return false
  }).length
}

export const thisYear = (array) => {
  let date = moment().format('YYYY')
  return array.filter((item) => {
    if (moment(item.redActivatedAt).format('YYYY') === date) return item
    return false
  }).length
}

export const previousYear = (array) => {
  let date = moment().format('YYYY') - 1

  return array.filter((item) => {
    if (moment(item.redActivatedAt).format('YYYY') === date) return item
    return false
  }).length
}

export const comMonth = (array) => {
  const present = array.filter((item) => {
    const date = `${moment(Date.now()).format('YYYY')}-${moment(
      Date.now(),
    ).format('M')}`

    let itemDate = moment(item.redActivatedAt).format('YYYY-M')
    if (itemDate === date) return item
    return false
  }).length

  const previous = array.filter((item) => {
    const date = `${moment(Date.now()).format('YYYY')}-${moment(Date.now()).format('M') - 1
      }`

    let itemDate = moment(item.redActivatedAt).format('YYYY-M')
    if (itemDate === date) return item
    return false
  }).length

  let a = present - previous
  return (a / previous) * 100
}

export const comYear = (array) => {
  const present = array.filter((item) => {
    const date = moment(Date.now()).format('YYYY')

    let itemDate = moment(item.redActivatedAt).format('YYYY')
    if (itemDate === date) return item
    return false
  }).length

  const previous = array.filter((item) => {
    const date = moment(Date.now()).format('YYYY') - 1

    let itemDate = moment(item.redActivatedAt).format('YYYY')
    if (itemDate === date) return item
    return false
  }).length

  let a = present - previous
  return (a / previous) * 100
}

//  Analysis on Green Tags Scanned Today
export const manufacturertodaygreenanalysis = (data) => {
  let date = moment().format('YYYY-MM-DD')

  const byTwoHours = Array(12)
    .fill(0)
    .map((_, index) => `${String(index * 2).padStart(2, '')}`)

  const byTwoHoursLookup = byTwoHours.reduce(
    (acc, range) => ({ ...acc, [range]: [] }),
    {},
  )
  data &&
    data.length > 0 &&
    data
      .filter((item) => {
        const d = moment(item.greenActivatedAt).format('YYYY-MM-DD')
        if (d === date && item.green !== false) return item
        return false
      })
      .forEach((item) => {
        const hour = moment(item.greenActivatedAt).hour()
        for (let i = byTwoHours.length - 1; i >= 0; i--) {
          if (hour >= byTwoHours[i]) {
            byTwoHoursLookup[byTwoHours[i]].push(item)
            break
          }
        }
      })

  return byTwoHoursLookup
}

// Analysis on Red Tags Scanned Today
export const manufacturertodayredanalysis = (data) => {
  let date = moment().format('YYYY-MM-DD')

  const byTwoHours = Array(12)
    .fill(0)
    .map((_, index) => `${String(index * 2).padStart(2, '')}`)

  const byTwoHoursLookup = byTwoHours.reduce(
    (acc, range) => ({ ...acc, [range]: [] }),
    {},
  )

  data &&
    data.length > 0 &&
    data
      .filter((item) => {
        const d = moment(item.redActivatedAt).format('YYYY-MM-DD')
        if (d === date && item.red !== false) return item
        return false
      })
      .forEach((item) => {
        const hour = moment(item.redActivatedAt).hour()

        for (let i = byTwoHours.length - 1; i >= 0; i--) {
          if (hour >= byTwoHours[i]) {
            byTwoHoursLookup[byTwoHours[i]].push(item)
            break
          }
        }
      })
  return byTwoHoursLookup
}

export const manufacturerRecentOrder = (array) => {
  return array
    .filter((item) => {
      if (item.status === 'Pending' || item.status === 'Open') {
        return item
      }
      return false
    })
    .sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at)
    })
    .slice(0, 5)
}

export const activityFeed = (array) => {
  return array.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt)
  })
}

export const farmersRegisteredToday = (array) => {
  let today = moment().format('YYYY-MM-DD')
  return array.filter((item) => {
    const d = moment(item.createdAt).format('YYYY-MM-DD')
    if (d === today) return item
    return false
  })
}

export const outbox = (array) => {
  return array.filter((item) => {
    if (item.sender.account === 'admin') return item
    return false
  })
}

export const inbox = (array) => {
  return array.filter((item) => {
    if (
      item.sender.account === 'manufacturer' ||
      item.sender.account === 'farmer'
    )
      return item
    return false
  })
}
// =========================================
// ======Product Analysis Dashboard Page====
// =========================================

export const averageUsage = (array) => {
  let year = moment().format('YYYY')

  return Math.round(
    (array.filter((item) => {
      const d = moment(item.redActivatedAt).format('YYYY')
      if (item.red === true && d === year) return item
      return false
    }).length +
      Number.EPSILON) /
    365,
  )
}

export const todayAuthentication = (array) => {
  let today = moment().format('YYYY-MM-DD')
  return array.filter((item) => {
    const d = moment(item.redActivatedAt).format('YYYY-MM-DD')
    if (item.red === true && d === today) return item
    return false
  }).length
}

export const authentication = (array) => {
  return array.filter((item) => {
    if (item.red === true) return item
    return false
  }).length
}

export const thisYearProduct = (array) => {
  let date = moment().format('YYYY')
  return array.filter((item) => {
    if (
      moment(item.red === true && item.redActivatedAt).format('YYYY') === date
    )
      return item
    return false
  }).length
}

export const comYearProduct = (array) => {
  const present = array.filter((item) => {
    const date = moment(Date.now()).format('YYYY')

    let itemDate = moment(item.redActivatedAt).format('YYYY')
    if (item.red === true && itemDate === date) return item
    return false
  }).length

  const previous = array.filter((item) => {
    const date = moment(Date.now()).format('YYYY') - 1

    let itemDate = moment(item.redActivatedAt).format('YYYY')
    if (item.red === true && itemDate === date) return item
    return false
  }).length

  let a = present - previous
  return (a / previous) * 100
}

export const thisMonthProduct = (array) => {
  let date = moment().format('YYYY-MM')
  return array.filter((item) => {
    if (
      item.red === true &&
      moment(item.redActivatedAt).format('YYYY-MM') === date
    )
      return item
    return false
  }).length
}

export const comMonthProduct = (array) => {
  const present = array.filter((item) => {
    const date = `${moment(Date.now()).format('YYYY')}-${moment(
      Date.now(),
    ).format('M')}`

    let itemDate = moment(item.redActivatedAt).format('YYYY-M')
    if (item.red === true && itemDate === date) return item
    return false
  }).length

  const previous = array.filter((item) => {
    const date = `${moment(Date.now()).format('YYYY')}-${moment(Date.now()).format('M') - 1
      }`

    let itemDate = moment(item.redActivatedAt).format('YYYY-M')
    if (item.red === true && itemDate === date) return item
    return false
  }).length

  let a = present - previous
  return (a / previous) * 100
}

export const previousYearProduct = (array) => {
  let date = moment().format('YYYY') - 1

  return array.filter((item) => {
    if (
      item.red === true &&
      moment(item.redActivatedAt).format('YYYY') === date
    )
      return item
    return false
  }).length
}

export const farmerProductAnalysis = (array) => {
  return array
    .filter((item) => {
      if (item.red === true && item.farmer) return item
      return false
    })
    .sort((a, b) => {
      return new Date(b.redActivatedAt) - new Date(a.redActivatedAt)
    })
}

export const highestFarmer = (array) => {
  return array && array
    .filter((item) => item.red !== false)
    .reduce((group, item) => {
      if (item.farmer && item.farmer.phone) {
        const { phone } = item.farmer;
        group[phone] = group[phone] ?? [];
        group[phone].push(item);
      }
      return group;
    }, {});
}


export const biannualAverageUsage = (array) => {
  let year = moment().format('YYYY')

  return Math.round(
    (array.filter((item) => {
      const d = moment(item.redActivatedAt).format('YYYY')
      if (item.red === true && d === year) return item
      return false
    }).length +
      Number.EPSILON) /
    183,
  )
}

export const quarterlyAverageUsage = (array) => {
  let year = moment().format('YYYY')

  return Math.round(
    (array.filter((item) => {
      const d = moment(item.redActivatedAt).format('YYYY')
      if (item.red === true && d === year) return item
      return false
    }).length +
      Number.EPSILON) /
    90,
  )
}

export const geolocations = (array) => {
  let date = moment().format('YYYY-MM')

  return array.filter((item) => {
    const d = moment(item.redActivatedAt).format('YYYY-MM')
    if (item.redlat && item.redlong && d === date) return item

    return false
  })
}

export const benefitStatus = (array) => {
  return array.reduce((group, item) => {
    if (
      item.product.category &&
      item.product.category.benefitCategory &&
      item.product.category.benefitCategory.name
    ) {
      const { name } = item.product.category.benefitCategory

      console.log(item.product.category.benefitCategory)
      group[name] = group[name] ?? []
      group[name].push(item)
      return group
    }
  }, {})
}

export const productCategory = (array, id, keys) => {
  if (array && id) {
    const object = array.filter((obj) => obj._id === id)

    if (object.length === 0 || !object[0].category) {
      return null
    }
    return keys.reduce((result, key) => {
      result[key] = object[0].category[key]
      return result
    }, {})
  }
}

export const tagPointConfig = (data, key) => {
  if (data && key) {
    const object = data.filter((obj) => obj.category._id === key)
    if (object[0] && object[0].point) return object[0].point
  }
}

export const protocolPoint = (array) => {
  const sum = array.reduce((accumulator, currentValue) => {
    return accumulator + parseInt(currentValue.point)
  }, 0)

  return sum
}

export const protocolDuration = (array) => {
  let highestNumber = 0

  for (let i = 0; i < array.length; i++) {
    const timeLineTo = parseInt(array[i].timeLineTo)
    if (timeLineTo > highestNumber) {
      highestNumber = timeLineTo
    }
  }
  return highestNumber
}

export const calDuration = (startDate, duration) => {
  const start = moment(startDate)
  const hoursToAdd = duration

  const endDate = start.add(hoursToAdd, 'hours')
  return endDate.format('DD MMM YYYY')
}

export const checkStatus = (start, end) => {
  // Set the start and end dates
  const startDate = moment(start)
  const endDate = moment(end)

  // Get the current date
  const currentDate = moment()

  // Check if start date is greater than or equal to today's date
  if (currentDate.isBefore(startDate, 'day')) {
    return <div className={`badge bg-warning font-size-13`}>Pending</div>
  } // Check if the current date is between start and end date
  else if (currentDate.isBetween(startDate, endDate)) {
    return <div className={`badge bg-success font-size-13`}>Active</div>
  } else {
    return <div className={`badge bg-danger font-size-13`}>Closed</div>
  }
}

export const checkBenefitStatus = (item, point, packageNum, date) => {
  // Set the benefit time from
  const timeLineFrom = item.benefitFrom

  // Set the benefit time from
  const timeLineTo = item.benefitTo

  // let benefitTargetPoint = packageNum * point
  let benefitTargetPoint = point

  const isPointValid = point >= benefitTargetPoint

  // get the current date
  const todayDate = moment()

  // set the start date
  const providedDate = moment(date)

  const hours = todayDate.diff(providedDate, 'hours')

  const isDateValid = hours >= timeLineFrom && hours <= timeLineTo

  return isPointValid && isDateValid
}

export const cycleActivities = (activities, packageNum) => {
  // // Step 1: Filter "Record Activity" entries
  // const recordActivities = activities.filter(
  //   (entry) => entry.activity === 'Record Activity',
  // )

  // // Step 2: Duplicate non-"Record Activity" entries
  // const duplicatedEntries = activities
  //   .filter((entry) => entry.activity !== 'Record Activity')
  //   .flatMap((entry) =>
  //     Array.from({ length: packageNum }, () => ({ ...entry })),
  //   )

  // Declare an empty array
  const duplicatedData = []

  // Step 1: Duplicate non-"Record Activity" entries
  activities.forEach((item, index) => {
    duplicatedData.push(item)

    if (item.activity === 'Scan Activity') {
      for (let i = 1; i < packageNum; i++) {
        duplicatedData.push({ ...item })
      }
    }
  })

  // Step 2: Multiply the "point" field of "Record Activity" objects by packageNum
  const updatedActivities = duplicatedData.map((entry) => {
    if (entry.activity === 'Record Activity') {
      return { ...entry, point: entry.point * packageNum }
    } else {
      return entry
    }
  })

  const result = [...updatedActivities]

  // Declare a count value for each of the fields
  let recordActivityCount = 0
  let activateLabelCount = 0
  let verifyLabelCount = 0

  result.forEach((item) => {
    if (item.activity === 'Record Activity') {
      // Step 1: Increment count
      recordActivityCount++
    } else {
      // Step 2: Otherwise, increment count for respective field
      if (
        item.activity === 'Scan Activity' &&
        item.tagColor === 'Activate (GREEN Label)'
      ) {
        activateLabelCount++
      } else if (
        item.activity === 'Scan Activity' &&
        item.tagColor === 'Verify (RED Label)'
      ) {
        verifyLabelCount++
      }
    }
  })

  function findPointsForActivity(result) {
    for (const item of result) {
      if (item.activity === 'Scan Activity') {
        return item.point
      }
    }

    return 0 // Return 0 if the activity is not found
  }

  let scanActivityPoints = findPointsForActivity(result)

  // Step 3: Combine the results and return
  return {
    result,
    recordActivityCount,
    activateLabelCount,
    verifyLabelCount,
    scanActivityPoints,
  }
}

export const groupPackage = (data) => {
  if (data) {
    const packageMap = new Map()
    for (const item of data) {
      const packageId = item.packageId._id
      const existingPackage = packageMap.get(packageId)
      if (existingPackage) {
        existingPackage.numSubscribers++
      } else {
        packageMap.set(packageId, {
          _id: packageId,
          name: item.packageId.packageName,
          numSubscribers: 1,
        })
      }
    }
    return Array.from(packageMap.values())
  }
}
export const packageStatus = (data) => {
  let pending = 0
  let active = 0
  let closed = 0
  if (data && data.length > 0) {
    data.map((item) => {
      // Set the start and end dates
      const startDate = moment(item.startDate)
      const endDate = moment(item.endDate)

      // Get the current date
      const currentDate = moment()

      // Check if start date is greater than or equal to today's date
      if (currentDate.isBefore(startDate, 'day')) {
        pending++
      } // Check if the current date is between start and end date
      else if (currentDate.isBetween(startDate, endDate)) {
        active++
      } else {
        closed++
      }
    })
  }

  return { pending, active, closed }
}

export const farmcycleStatus = (data) => {
  const users = {}
  for (const item of data) {
    const userId = item.user._id
    // Set the start and end dates
    const startDate = moment(item.startDate)
    const endDate = moment(item.endDate)

    // Get the current date
    const currentDate = moment()

    if (users[userId]) {
      // Check if start date is greater than or equal to today's date
      if (currentDate.isBefore(startDate, 'day')) {
        users[userId].pending++
      } // Check if the current date is between start and end date
      else if (currentDate.isBetween(startDate, endDate)) {
        users[userId].active++
      } else {
        users[userId].closed++
      }
      users[userId].count++
    } else {
      users[userId] = {
        _id: userId,
        uniqueId: item.user.phone,
        name: item.user.firstName + ' ' + item.user.lastName,
        count: 1,
        pending: 0,
        closed: 0,
        active: 0,
      }
    }
  }
  return Object.values(users)
}

export const filterActivitiesByTime = (startDate, activities) => {
  // set the start date
  const date1 = moment(startDate, 'YYYY-MM-DD')

  // get the current date
  const date2 = moment().startOf('day')

  // output: number of hours between cycle start date and the present day
  const diffHours = date2.diff(date1, 'hours')

  // Add 24 hrs to the diffHours to get the max time in hrs
  const next24Hrs = diffHours + 24

  return activities.filter((obj) => {
    const timeLineFrom = obj.timeLineFrom
    const timeLineTo = obj.timeLineTo

    return diffHours >= timeLineFrom && next24Hrs <= timeLineTo
  })
}

export const calculateMoneyFlow = (data) => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1 // JavaScript months are zero-based
  const currentYear = currentDate.getFullYear()

  let inflowMonth = 0
  let outflowMonth = 0
  let inflow = 0
  let outflow = 0

  data.forEach((transaction) => {
    const transactionDate = new Date(transaction.timestamp)
    const transactionMonth = transactionDate.getMonth() + 1
    const transactionYear = transactionDate.getFullYear()

    if (transactionYear === currentYear) {
      if (transactionMonth === currentMonth) {
        if (
          transaction.type === 'credit' &&
          transaction.status === 'successful'
        ) {
          inflowMonth += transaction.amount
        } else if (
          transaction.type === 'debit' &&
          transaction.status === 'successful'
        ) {
          outflowMonth += transaction.amount
        }
      }

      if (
        transaction.type === 'credit' &&
        transaction.status === 'successful'
      ) {
        inflow += transaction.amount
      } else if (
        transaction.type === 'debit' &&
        transaction.status === 'successful'
      ) {
        outflow += transaction.amount
      }
    }
  })

  return {
    inflowMonth,
    outflowMonth,
    inflow,
    outflow,
  }
}


/**
 * Calculates the loan repayment details with interest calculated on the initial principal and divided equally across months.
 * 
 * @param {number} principal - The initial amount of the loan.
 * @param {number} yearlyInterestRate - The yearly interest rate in percentage.
 * @param {number} numberOfMonths - The total number of months for loan repayment.
 * @param {Date} startDate - The start date of the loan.
 * @param {number} moratoriumPeriod - The moratorium period of the loan.
 * @returns {Object} An object containing the monthly payment amount, due date and the schedule of payments.
 */
export const loanRepayment = (
  principal,
  yearlyInterestRate,
  numberOfMonths,
  startDate,
  moratoriumPeriod
) => {
  // Calculate interest per month without moratorium
  const monthlyInterestRate = (yearlyInterestRate / 100) / 12;

  // Calculate total interest excluding moratorium period
  const baseInterest = principal * monthlyInterestRate * (numberOfMonths - moratoriumPeriod);

  // Calculate additional interest during moratorium (simple interest)
  const moratoriumInterest = principal * monthlyInterestRate * moratoriumPeriod;

  // Total interest including moratorium
  const totalInterest = baseInterest + moratoriumInterest;

  const totalPayment = principal + totalInterest;
  const monthlyPayment = totalPayment / (numberOfMonths - moratoriumPeriod);
  const monthlyPrincipalPayment = principal / (numberOfMonths - moratoriumPeriod);

  let balance = principal; // Initial balance before any calculations
  const paymentSchedule = [];
  let currentDate = new Date(startDate);

  const dueDate = moment(startDate)
    .add(numberOfMonths, 'months')
    .format('MMMM DD, YYYY');

  for (let i = 0; i < numberOfMonths; i++) {
    if (i < moratoriumPeriod) {
      // Do not add moratorium interest to the balance during moratorium (without affecting principal)
      paymentSchedule.push({
        month: i + 1,
        date: currentDate.toISOString().slice(0, 10),
        payment: "0.00", // No payment during moratorium
        principal: "0.00",
        interest: monthlyInterestRate * principal.toFixed(2), // Moratorium interest
        balance: balance.toFixed(2) // Balance reflects only interest accumulation
      });
    } else {
      // Apply principal payment and calculate interest after moratorium ends
      balance -= monthlyPrincipalPayment;
      const monthlyInterestPayment = monthlyInterestRate * balance;
      paymentSchedule.push({
        month: i + 1,
        date: currentDate.toISOString().slice(0, 10),
        payment: monthlyPayment.toFixed(2),
        principal: monthlyPrincipalPayment.toFixed(2),
        interest: monthlyInterestPayment.toFixed(2),
        balance: balance.toFixed(2)
      });
    }
    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return {
    monthlyPayment: monthlyPayment.toFixed(2),
    dueDate,
    repaymentSchedule: paymentSchedule
  };
}


export const checkLoanApproveAndStartDate = (loanArray) => {
  // Check if any loan is both "Approved" and has a start date
  const approvedLoanWithStartDate =
    loanArray &&
    loanArray.find((loan) => {
      return loan.status === 'Active' && loan.startDate
    })

  return !!approvedLoanWithStartDate // Convert to boolean
}


export const getTotalVolumeOfTodayTransactions = (transactions) => {
  // Get today's date in the format YYYY-MM-DD
  const today = new Date().toISOString().split('T')[0];

  // Filter transactions that occurred today and sum their amounts
  const totalVolume = transactions
    .filter(transaction => transaction.timestamp.startsWith(today))
    .reduce((sum, transaction) => sum + transaction.amount, 0);

  return totalVolume;
}

export const getTotalTransferVolume = (transactions) => {
  // Sum the amount of all transactions
  const totalVolume = transactions.reduce((sum, transaction) => sum + transaction.amount, 0);

  return totalVolume;
}

export const countTodayTransactions = (transactions) => {
  // Get today's date in the format YYYY-MM-DD
  const today = new Date().toISOString().split('T')[0];

  // Filter transactions that occurred today and count them
  const todayTransactionsCount = transactions.filter(transaction =>
    transaction.timestamp.startsWith(today)
  ).length;

  return todayTransactionsCount;
}

export const getPreviousMonthTotal = (transactions) => {
  // Get the current date
  const currentDate = new Date();

  // Get the previous month and year
  let previousMonth = currentDate.getMonth() - 1;
  let year = currentDate.getFullYear();

  // Handle year roll-over (if previous month is December of the last year)
  if (previousMonth < 0) {
    previousMonth = 11;
    year -= 1;
  }

  // Filter transactions from the previous month and sum the amounts
  const totalVolume = transactions
    .filter(transaction => {
      const transactionDate = new Date(transaction.timestamp);
      return (
        transactionDate.getFullYear() === year &&
        transactionDate.getMonth() === previousMonth
      );
    })
    .reduce((sum, transaction) => sum + transaction.amount, 0);

  return totalVolume;
}

export const getThisMonthTotal = (transactions) => {
  // Get the current date
  const currentDate = new Date();

  // Get this month and year
  const thisMonth = currentDate.getMonth();
  const year = currentDate.getFullYear();

  // Filter transactions from this month and sum the amounts
  const totalVolume = transactions
    .filter(transaction => {
      const transactionDate = new Date(transaction.timestamp);
      return (
        transactionDate.getFullYear() === year &&
        transactionDate.getMonth() === thisMonth
      );
    })
    .reduce((sum, transaction) => sum + transaction.amount, 0);

  return totalVolume;
}

export const calculateMonthToMonthDifference = (transactions) => {
  // Helper function to get the total for the previous month
  function getPreviousMonthTotal(transactions) {
    const currentDate = new Date();
    let previousMonth = currentDate.getMonth() - 1;
    let year = currentDate.getFullYear();

    if (previousMonth < 0) {
      previousMonth = 11;
      year -= 1;
    }

    const totalVolume = transactions
      .filter(transaction => {
        const transactionDate = new Date(transaction.timestamp);
        return (
          transactionDate.getFullYear() === year &&
          transactionDate.getMonth() === previousMonth
        );
      })
      .reduce((sum, transaction) => sum + transaction.amount, 0);

    return totalVolume;
  }

  // Helper function to get the total for this month
  function getThisMonthTotal(transactions) {
    const currentDate = new Date();
    const thisMonth = currentDate.getMonth();
    const year = currentDate.getFullYear();

    const totalVolume = transactions
      .filter(transaction => {
        const transactionDate = new Date(transaction.timestamp);
        return (
          transactionDate.getFullYear() === year &&
          transactionDate.getMonth() === thisMonth
        );
      })
      .reduce((sum, transaction) => sum + transaction.amount, 0);

    return totalVolume;
  }

  // Get totals for this month and previous month
  const thisMonthTotal = getThisMonthTotal(transactions);
  const previousMonthTotal = getPreviousMonthTotal(transactions);

  // Handle cases where one or both months have no transactions
  if (previousMonthTotal === 0 && thisMonthTotal > 0) {
    return 100; // 100% increase if no transactions last month, but there are this month
  } else if (previousMonthTotal > 0 && thisMonthTotal === 0) {
    return -100; // 100% decrease if there were transactions last month but none this month
  } else if (previousMonthTotal === 0 && thisMonthTotal === 0) {
    return 0; // No transactions in both months, return 0% change
  }

  // Calculate the percentage difference
  const difference = thisMonthTotal - previousMonthTotal;
  const percentageDifference = (difference / previousMonthTotal) * 100;

  return percentageDifference.toFixed(2); // Return the percentage difference rounded to 2 decimal places
}

export const calculateYearTotal = (transactions) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  // Filter transactions that occurred in the current year and sum the amounts
  const totalVolume = transactions
    .filter(transaction => {
      const transactionDate = new Date(transaction.timestamp);
      return transactionDate.getFullYear() === currentYear;
    })
    .reduce((sum, transaction) => sum + transaction.amount, 0);

  return totalVolume;
}

export const groupTransactionsByMonth = (transactions) => {
  const months = Array(12).fill(0); // Create an array of 12 elements representing each month

  transactions.forEach(transaction => {
    const transactionDate = new Date(transaction.timestamp);
    const month = transactionDate.getMonth(); // Get month (0 = January, 11 = December)
    months[month] += transaction.amount; // Sum the amount for each month
  });

  return months;
};


export const getTodayEscrowTransfers = (transactions) => {
  // Get today's date in the format YYYY-MM-DD
  const today = new Date().toISOString().split('T')[0];

  // Filter transactions that occurred today and count them
  const todayTransactionsCount = transactions.filter(transaction =>
    transaction.createdAt.startsWith(today)
  ).length;

  return todayTransactionsCount;
}

export const analyzeUserCreation = (users) => {
  // Initialize an array to hold the counts for each month (Jan to Dec)
  const monthlyUserCounts = new Array(12).fill(0); // Array with 12 zeros, one for each month

  // Loop through each user in the array
  users.forEach(user => {
    if (user.createdAt) {
      // Create a Date object from the createdAt timestamp
      const createdDate = new Date(user.createdAt);
      const monthIndex = createdDate.getUTCMonth(); // getUTCMonth() returns month index (0-11)

      // Increment the count for the corresponding month
      monthlyUserCounts[monthIndex]++;
    }
  });

  return monthlyUserCounts; // Return only the counts
}

export const groupAndCountClusters = (users) => {
  const clusterCount = {};

  // Iterate over each user and count clusters
  users.forEach(user => {
    user.clusters.forEach(cluster => {
      const clusterId = cluster._id;
      if (clusterCount[clusterId]) {
        clusterCount[clusterId]++;
      } else {
        clusterCount[clusterId] = 1;
      }
    });
  });

  // Convert the clusterCount object into an array of objects
  return Object.keys(clusterCount).map(clusterId => ({
    clusterId,
    name: users.find(user => user.clusters.some(c => c._id === clusterId)).clusters.find(c => c._id === clusterId).name,
    count: clusterCount[clusterId],
  }));
}

export const checkNumberofActiveLoans = (loanArray) => {
  let activeLoans = 0

  // Check if any loan is both "Approved" and has a start date
  loanArray.forEach(loan => {
    if (loan.status === 'Active' && loan.startDate) {

      activeLoans++;
    }
  });

  return activeLoans; // Return only the counts
}

export const calculateInstantVsEscrow = (instantTransactions, escrowTransactions) => {
  const today = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format

  // Filter transactions to include only those done today
  const instantToday = instantTransactions.filter(transaction => transaction.timestamp.slice(0, 10) === today);
  const escrowToday = escrowTransactions.filter(transaction => transaction.createdAt.slice(0, 10) === today);

  // Get the total number of instant and escrow transactions done today
  const totalInstantToday = instantToday.length;
  const totalEscrowToday = escrowToday.length;

  // Calculate the total transactions done today
  const totalTransactionsToday = totalInstantToday + totalEscrowToday;

  // Calculate the percentage for both instant and escrow transactions
  const percentageInstantToday = totalTransactionsToday > 0 ? ((totalInstantToday / totalTransactionsToday) * 100).toFixed(2) : 0;
  const percentageEscrowToday = totalTransactionsToday > 0 ? ((totalEscrowToday / totalTransactionsToday) * 100).toFixed(2) : 0;

  return {
    totalInstantToday,
    totalEscrowToday,
    percentageInstantToday,
    percentageEscrowToday
  };
}

export const getUserMonthlyTransactions = (transactions, month) => {
  // Helper function to extract the year and month from the timestamp
  const getMonth = (timestamp) => moment(timestamp).format('M'); // Format YYYY-MM

  // Filter transactions by the specified month
  const filteredTransactions = transactions.filter(transaction => Number(getMonth(transaction.timestamp)) === Number(month));

  // Group transactions by user ID and sum amounts
  const userTransactions = filteredTransactions.reduce((acc, transaction) => {
    const userId = transaction.user._id;

    if (!acc[userId]) {
      acc[userId] = {
        user: {
          email: transaction.user.email,
          phone: transaction.user.phone,
        },
        totalAmount: 0,
        transactionCount: 0
      };
    }

    acc[userId].totalAmount += transaction.amount;
    acc[userId].transactionCount += 1;

    return acc;
  }, {});

  // Convert the result object into an array
  return Object.values(userTransactions);
}