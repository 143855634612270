import axios from 'axios'
import {
  USER_ACCOUNT_INFORMATION_FAIL,
  USER_ACCOUNT_INFORMATION_REQUEST,
  USER_ACCOUNT_INFORMATION_SUCCESS,
} from '../../../constants/adminConstants/farmtrove/userAccountConstants'

export const getUserAccountReport = (itemId) => async (dispatch, getState) => {
  dispatch({
    type: USER_ACCOUNT_INFORMATION_REQUEST,
    payload: itemId,
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/farmtrove/user/accounts/details',
      { itemId },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: USER_ACCOUNT_INFORMATION_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({ type: USER_ACCOUNT_INFORMATION_FAIL, payload: message })
  }
}
