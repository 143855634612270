import axios from 'axios'
import {
  VERIFY_OTP_FAIL,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
} from '../../constants/authConstants/otpConstants'

const verifyOtp = (code, phone) => async (dispatch) => {
  dispatch({ type: VERIFY_OTP_REQUEST, payload: { code, phone } })
  try {
    const { data } = await axios.post('/api/verify-otp', { code, phone })
    dispatch({ type: VERIFY_OTP_SUCCESS, payload: data })

    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: VERIFY_OTP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export default verifyOtp
