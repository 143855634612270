import {
  ALL_MANUFACTURER_MESSAGE_FAIL,
  ALL_MANUFACTURER_MESSAGE_REQUEST,
  ALL_MANUFACTURER_MESSAGE_SUCCESS,
  MANUFACTURER_READ_MESSAGE_FAIL,
  MANUFACTURER_READ_MESSAGE_REQUEST,
  MANUFACTURER_READ_MESSAGE_SUCCESS,
  MANUFACTURER_SEND_MESSAGE_FAIL,
  MANUFACTURER_SEND_MESSAGE_REQUEST,
  MANUFACTURER_SEND_MESSAGE_RESET,
  MANUFACTURER_SEND_MESSAGE_SUCCESS,
} from '../../../constants/manufacturerConstants/messageConstants'

export const manufacturerMessagesReducer = (
  state = { loading: true, messages: [] },
  action,
) => {
  switch (action.type) {
    case ALL_MANUFACTURER_MESSAGE_REQUEST:
      return { loading: true }
    case ALL_MANUFACTURER_MESSAGE_SUCCESS:
      return {
        loading: false,
        messages: action.payload,
      }
    case ALL_MANUFACTURER_MESSAGE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const manufacturerReadMessageReducer = (
  state = { loading: true },
  action,
) => {
  switch (action.type) {
    case MANUFACTURER_READ_MESSAGE_REQUEST:
      return { loading: true }
    case MANUFACTURER_READ_MESSAGE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      }
    case MANUFACTURER_READ_MESSAGE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const manufacturerSendMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case MANUFACTURER_SEND_MESSAGE_REQUEST:
      return { loading: true }
    case MANUFACTURER_SEND_MESSAGE_SUCCESS:
      return {
        loading: false,
        sent: action.payload,
      }
    case MANUFACTURER_SEND_MESSAGE_FAIL:
      return { loading: false, error: action.payload }
    case MANUFACTURER_SEND_MESSAGE_RESET:
      return {}
    default:
      return state
  }
}
