import {
  MANUFACTURER_DASHBOARD_ANALYSIS_FAIL,
  MANUFACTURER_DASHBOARD_ANALYSIS_REQUEST,
  MANUFACTURER_DASHBOARD_ANALYSIS_SUCCESS,
  MANUFACTURER_PRODUCT_ANALYSIS_FAIL,
  MANUFACTURER_PRODUCT_ANALYSIS_REQUEST,
  MANUFACTURER_PRODUCT_ANALYSIS_SUCCESS,
} from '../../../constants/manufacturerConstants/dashboardConstants'

export const manufacturerDashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case MANUFACTURER_DASHBOARD_ANALYSIS_REQUEST:
      return { loading: true }
    case MANUFACTURER_DASHBOARD_ANALYSIS_SUCCESS:
      return { loading: false, result: action.payload }
    case MANUFACTURER_DASHBOARD_ANALYSIS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const manufacturerProductAnalysisReducer = (
  state = { tags: [] },
  action,
) => {
  switch (action.type) {
    case MANUFACTURER_PRODUCT_ANALYSIS_REQUEST:
      return { loading: true }
    case MANUFACTURER_PRODUCT_ANALYSIS_SUCCESS:
      return { loading: false, tags: action.payload }
    case MANUFACTURER_PRODUCT_ANALYSIS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
