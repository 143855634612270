import axios from 'axios'
import {
    REQUEST_QUOTE_FAIL,
    REQUEST_QUOTE_REQUEST,
    REQUEST_QUOTE_SUCCESS,
} from '../../../constants/manufacturerConstants/farmClusterConstants'

export const requestQuote = (cluster, name, email, phone, checkboxes) => async (dispatch, getState) => {
    dispatch({ type: REQUEST_QUOTE_REQUEST, payload: { cluster, name, email, phone, checkboxes } })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.post('/api/manufacturer/farmcluster/request-a-quote',
            { cluster, name, email, phone, checkboxes },
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            })
        dispatch({ type: REQUEST_QUOTE_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: REQUEST_QUOTE_FAIL, payload: message })
    }
}
