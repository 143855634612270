import axios from 'axios'
import {
  ADD_FARM_CYCLE_FAIL,
  ADD_FARM_CYCLE_REQUEST,
  ADD_FARM_CYCLE_SUCCESS,
} from '../../../constants/farmerConstants/packagesConstants'

export const newCycle = ({
  packageId,
  packageNum,
  productCategoryId,
  name,
  startDate,
  endDate,
  terms,
  activities,
  eop,
  list,
  totalSum,
  additionalEoPTotalSum,
  getEopTotalSum,
  sellAmount,
  sellUoS,
  lossAmount,
  lossUoM,
  produceAfterLoss,
  totalProduceSold,
  profit,
  profitabilityROI, }
) => async (dispatch, getState) => {
  dispatch({
    type: ADD_FARM_CYCLE_REQUEST,
    payload: {
      packageId,
      packageNum,
      productCategoryId,
      name,
      startDate,
      endDate,
      terms,
      activities,
      eop,
      list,
      totalSum,
      additionalEoPTotalSum,
      getEopTotalSum,
      sellAmount,
      sellUoS,
      lossAmount,
      lossUoM,
      produceAfterLoss,
      totalProduceSold,
      profit,
      profitabilityROI,
    },
  })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/farmer/add-new-cycle',
      {
        packageId,
        packageNum,
        productCategoryId,
        name,
        startDate,
        endDate,
        terms,
        activities,
        eop,
        list,
        totalSum,
        additionalEoPTotalSum,
        getEopTotalSum,
        sellAmount,
        sellUoS,
        lossAmount,
        lossUoM,
        produceAfterLoss,
        totalProduceSold,
        profit,
        profitabilityROI,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: ADD_FARM_CYCLE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: ADD_FARM_CYCLE_FAIL,
      payload: message,
    })
  }
}
