export const SERVICE_PROVIDER_PLACE_ORDER_REQUEST = 'SERVICE_PROVIDER_PLACE_ORDER_REQUEST'
export const SERVICE_PROVIDER_PLACE_ORDER_SUCCESS = 'SERVICE_PROVIDER_PLACE_ORDER_SUCCESS'
export const SERVICE_PROVIDER_PLACE_ORDER_FAIL = 'SERVICE_PROVIDER_PLACE_ORDER_FAIL'
export const SERVICE_PROVIDER_PLACE_ORDER_RESET = 'SERVICE_PROVIDER_PLACE_ORDER_RESET'

export const SERVICE_PROVIDER_ALL_ORDER_REQUEST = 'SERVICE_PROVIDER_ALL_ORDER_REQUEST'
export const SERVICE_PROVIDER_ALL_ORDER_SUCCESS = 'SERVICE_PROVIDER_ALL_ORDER_SUCCESS'
export const SERVICE_PROVIDER_ALL_ORDER_FAIL = 'SERVICE_PROVIDER_ALL_ORDER_FAIL'

export const FARMER_PRODUCT_ORDERS_REQUEST = 'FARMER_PRODUCT_ORDERS_REQUEST'
export const FARMER_PRODUCT_ORDERS_SUCCESS = 'FARMER_PRODUCT_ORDERS_SUCCESS'
export const FARMER_PRODUCT_ORDERS_FAIL = 'FARMER_PRODUCT_ORDERS_FAIL'


export const UPDATE_FARMER_PRODUCT_ORDER_REQUEST = 'UPDATE_FARMER_PRODUCT_ORDER_REQUEST'
export const UPDATE_FARMER_PRODUCT_ORDER_SUCCESS = 'UPDATE_FARMER_PRODUCT_ORDER_SUCCESS'
export const UPDATE_FARMER_PRODUCT_ORDER_FAIL = 'UPDATE_FARMER_PRODUCT_ORDER_FAIL'
export const UPDATE_FARMER_PRODUCT_ORDER_RESET = 'UPDATE_FARMER_PRODUCT_ORDER_RESET'