import axios from 'axios'
import {
  DELETE_MANUFACTURER_FAIL,
  DELETE_MANUFACTURER_REQUEST,
  DELETE_MANUFACTURER_SUCCESS,
} from '../../../constants/adminConstants/manufacturersConstants'

export const deleteManufacuturer = (itemId) => async (dispatch, getState) => {
  dispatch({ type: DELETE_MANUFACTURER_REQUEST, payload: { itemId } })
  try {
    const {
      userSignin: { userInfo },
    } = getState()
    const { data } = await axios.post(
      '/api/admin/delete-manufacturer',
      { itemId },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      },
    )
    dispatch({ type: DELETE_MANUFACTURER_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.messagea
        : error.message
    dispatch({ type: DELETE_MANUFACTURER_FAIL, payload: message })
  }
}
