import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBox, MessageBox } from '../../../components'
import { Header, LeftSidebar, RightSidebar, Footer } from '../../../partials'
import moment from 'moment'
import { userSendMessage } from '../../../actions/farmerActions/messageActions/sendMessage'
import { USER_SEND_MESSAGE_RESET } from '../../../constants/farmerConstants/messageConstants'
import Dialog from '@material-ui/core/Dialog'

export default function UserMessagePage() {
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [open, setOpen] = useState(false)

  const today = moment().format('DD-MM-YYYY')

  const userMessages = useSelector((state) => state.userMessages)
  const { loading, error, messages } = userMessages

  const sendMessage = useSelector((state) => state.sendMessage)
  const { loading: sendingLoading, error: sendingError, sent } = sendMessage

  const dispatch = useDispatch()

  useEffect(() => {
    if (sent) {
      setTimeout(() => {
        dispatch({ type: USER_SEND_MESSAGE_RESET })
        setOpen(false)
      }, 2500)
    }
  }, [dispatch, sent])

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(userSendMessage(subject, message))
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <LeftSidebar />
      <RightSidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Inbox</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Messages</a>
                      </li>
                      <li className="breadcrumb-item active">Inbox</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className=" mb-3">
                  <div className="card">
                    <div className="btn-toolbar p-3" role="toolbar">
                      <div className="btn-group me-2 mb-2 mb-sm-0">
                        <button
                          type="button"
                          className="btn btn-danger btn-block waves-effect waves-light"
                          data-bs-toggle="modal"
                          data-bs-target="#composemodal"
                          onClick={() => setOpen(true)}
                        >
                          Compose Message
                        </button>
                      </div>
                    </div>
                    <ul className="message-list">
                      {loading ? (
                        <LoadingBox />
                      ) : error ? (
                        <MessageBox variant="danger">{error}</MessageBox>
                      ) : messages && messages.length > 0 ? (
                        messages.map((item) => (
                          <li
                            className={item.is_read === false ? 'unread' : ''}
                          >
                            <div className="col-mail col-mail-1">
                              <a
                                href={`/user/message/${item._id}`}
                                className="title"
                              >
                                {item &&
                                item.sender &&
                                item.sender.account === 'admin'
                                  ? item.sender.account
                                  : null}
                              </a>
                            </div>
                                  
                            <div className="col-mail col-mail-2">
                              <a
                                href={`/user/message/${item._id}`}
                                className="subject"
                              >
                                {item.subject} –
                                <span className="teaser"> {item.message}</span>
                              </a>
                              <div className="date">
                                {' '}
                                {moment(item.created_at).format(
                                  'DD-MM-YYYY',
                                ) === today
                                  ? moment(item.created_at).format('h:mm a')
                                  : moment(item.created_at).format('MMM D')}
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <div className="center">No Messages</div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="md"
        >
          {sendingLoading ? (
            <LoadingBox />
          ) : (
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="composemodalTitle">
                  New Message
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setOpen(false)}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  {sent ? (
                    <MessageBox variant="success">{sent.message}</MessageBox>
                  ) : sendingError ? (
                    <MessageBox variant="danger">{sendingError}</MessageBox>
                  ) : null}
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <textarea
                      className="form-control message"
                      name="message"
                      placeholder="Message"
                      required
                      rows={10}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Send <i className="fab fa-telegram-plane ms-1"></i>
                  </button>
                </div>
              </form>
            </div>
          )}
        </Dialog>
      </div>
      <Footer />
    </div>
  )
}
