export const BENEFITS_LIST_REQUEST = 'BENEFITS_LIST_REQUEST'
export const BENEFITS_LIST_SUCCESS = 'BENEFITS_LIST_SUCCESS'
export const BENEFITS_LIST_FAIL = 'BENEFITS_LIST_FAIL'

export const BENEFITS_CREATE_REQUEST = 'BENEFITS_CREATE_REQUEST'
export const BENEFITS_CREATE_SUCCESS = 'BENEFITS_CREATE_SUCCESS'
export const BENEFITS_CREATE_FAIL = 'BENEFITS_CREATE_FAIL'
export const BENEFITS_CREATE_RESET = 'BENEFITS_CREATE_RESET'

export const UPDATE_BENEFIT_REQUEST = 'UPDATE_BENEFIT_REQUEST'
export const UPDATE_BENEFIT_SUCCESS = 'UPDATE_BENEFIT_SUCCESS'
export const UPDATE_BENEFIT_FAIL = 'UPDATE_BENEFIT_FAIL'
export const UPDATE_BENEFIT_RESET = 'UPDATE_BENEFIT_RESET'

export const BENEFIT_DELETE_REQUEST = 'BENEFIT_DELETE_REQUEST'
export const BENEFIT_DELETE_SUCCESS = 'BENEFIT_DELETE_SUCCESS'
export const BENEFIT_DELETE_FAIL = 'BENEFIT_DELETE_FAIL'
export const BENEFIT_DELETE_RESET = 'BENEFIT_DELETE_RESET'

export const ADD_BENEFIT_CATEGORY_REQUEST = 'ADD_BENEFIT_CATEGORY_REQUEST'
export const ADD_BENEFIT_CATEGORY_SUCCESS = 'ADD_BENEFIT_CATEGORY_SUCCESS'
export const ADD_BENEFIT_CATEGORY_FAIL = 'ADD_BENEFIT_CATEGORY_FAIL'
export const ADD_BENEFIT_CATEGORY_RESET = 'ADD_BENEFIT_CATEGORY_RESET'

export const UPDATE_BENEFIT_CATEGORY_REQUEST = 'UPDATE_BENEFIT_CATEGORY_REQUEST'
export const UPDATE_BENEFIT_CATEGORY_SUCCESS = 'UPDATE_BENEFIT_CATEGORY_SUCCESS'
export const UPDATE_BENEFIT_CATEGORY_FAIL = 'UPDATE_BENEFIT_CATEGORY_FAIL'
export const UPDATE_BENEFIT_CATEGORY_RESET = 'UPDATE_BENEFIT_CATEGORY_RESET'

export const BENEFIT_CATEGORY_DELETE_REQUEST = 'BENEFIT_CATEGORY_DELETE_REQUEST'
export const BENEFIT_CATEGORY_DELETE_SUCCESS = 'BENEFIT_CATEGORY_DELETE_SUCCESS'
export const BENEFIT_CATEGORY_DELETE_FAIL = 'BENEFIT_CATEGORY_DELETE_FAIL'
export const BENEFIT_CATEGORY_DELETE_RESET = 'BENEFIT_CATEGORY_DELETE_RESET'

export const BENEFIT_CATEGORY_LIST_REQUEST = 'BENEFIT_CATEGORY_LIST_REQUEST'
export const BENEFIT_CATEGORY_LIST_SUCCESS = 'BENEFIT_CATEGORY_LIST_SUCCESS'
export const BENEFIT_CATEGORY_LIST_FAIL = 'BENEFIT_CATEGORY_LIST_FAIL'

export const CLAIM_LIST_REQUEST = 'CLAIM_LIST_REQUEST'
export const CLAIM_LIST_SUCCESS = 'CLAIM_LIST_SUCCESS'
export const CLAIM_LIST_FAIL = 'CLAIM_LIST_FAIL'

export const CLAIM_DETAIL_REQUEST = 'CLAIM_DETAIL_REQUEST'
export const CLAIM_DETAIL_SUCCESS = 'CLAIM_DETAIL_SUCCESS'
export const CLAIM_DETAIL_FAIL = 'CLAIM_DETAIL_FAIL'

export const CLAIM_DELETE_REQUEST = 'CLAIM_DELETE_REQUEST'
export const CLAIM_DELETE_SUCCESS = 'CLAIM_DELETE_SUCCESS'
export const CLAIM_DELETE_FAIL = 'CLAIM_DELETE_FAIL'
export const CLAIM_DELETE_RESET = 'CLAIM_DELETE_RESET'

export const UPDATE_CLAIM_REQUEST = 'UPDATE_CLAIM_REQUEST'
export const UPDATE_CLAIM_SUCCESS = 'UPDATE_CLAIM_SUCCESS'
export const UPDATE_CLAIM_FAIL = 'UPDATE_CLAIM_FAIL'
export const UPDATE_CLAIM_RESET = 'UPDATE_CLAIM_RESET'
