import axios from 'axios'
import {
    SECURE_TRANSFER_FAIL,
    SECURE_TRANSFER_REQUEST,
    SECURE_TRANSFER_SUCCESS,
} from '../../../constants/authConstants/walletConstants'

export const allSecureTransfer = () => async (dispatch, getState) => {
    dispatch({
        type: SECURE_TRANSFER_REQUEST,
    })
    try {
        const {
            userSignin: { userInfo },
        } = getState()
        const { data } = await axios.get(
            '/api/wallet/escrow/transactions',
            {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            },
        )
        dispatch({ type: SECURE_TRANSFER_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        dispatch({ type: SECURE_TRANSFER_FAIL, payload: message })
    }
}
