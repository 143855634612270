export const ORGANISATION_PRODUCT_LIST_REQUEST = 'ORGANISATION_PRODUCT_LIST_REQUEST'
export const ORGANISATION_PRODUCT_LIST_SUCCESS = 'ORGANISATION_PRODUCT_LIST_SUCCESS'
export const ORGANISATION_PRODUCT_LIST_FAIL = 'ORGANISATION_PRODUCT_LIST_FAIL'

export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST'
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS'
export const ADD_PRODUCT_FAIL = 'ADD_PRODUCT_FAIL'
export const ADD_PRODUCT_RESET = 'ADD_PRODUCT_RESET'

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL'
export const UPDATE_PRODUCT_RESET = 'UPDATE_PRODUCT_RESET'

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL'
export const DELETE_PRODUCT_RESET = 'DELETE_PRODUCT_RESET'

export const PRODUCT_CATEGORY_LIST_REQUEST = 'PRODUCT_CATEGORY_LIST_REQUEST'
export const PRODUCT_CATEGORY_LIST_SUCCESS = 'PRODUCT_CATEGORY_LIST_SUCCESS'
export const PRODUCT_CATEGORY_LIST_FAIL = 'PRODUCT_CATEGORY_LIST_FAIL'

export const ADD_PRODUCT_CATEGORY_REQUEST = 'ADD_PRODUCT_CATEGORY_REQUEST'
export const ADD_PRODUCT_CATEGORY_SUCCESS = 'ADD_PRODUCT_CATEGORY_SUCCESS'
export const ADD_PRODUCT_CATEGORY_FAIL = 'ADD_PRODUCT_CATEGORY_FAIL'
export const ADD_PRODUCT_CATEGORY_RESET = 'ADD_PRODUCT_CATEGORY_RESET'

export const UPDATE_PRODUCT_CATEGORY_REQUEST = 'UPDATE_PRODUCT_CATEGORY_REQUEST'
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = 'UPDATE_PRODUCT_CATEGORY_SUCCESS'
export const UPDATE_PRODUCT_CATEGORY_FAIL = 'UPDATE_PRODUCT_CATEGORY_FAIL'
export const UPDATE_PRODUCT_CATEGORY_RESET = 'UPDATE_PRODUCT_CATEGORY_RESET'

export const DELETE_PRODUCT_CATEGORY_REQUEST = 'DELETE_PRODUCT_CATEGORY_REQUEST'
export const DELETE_PRODUCT_CATEGORY_SUCCESS = 'DELETE_PRODUCT_CATEGORY_SUCCESS'
export const DELETE_PRODUCT_CATEGORY_FAIL = 'DELETE_PRODUCT_CATEGORY_FAIL'
export const DELETE_PRODUCT_CATEGORY_RESET = 'DELETE_PRODUCT_CATEGORY_RESET'

export const ASSIGN_PRODUCT_CATEGORY_POINT_REQUEST = 'ASSIGN_PRODUCT_CATEGORY_POINT_REQUEST'
export const ASSIGN_PRODUCT_CATEGORY_POINT_SUCCESS = 'ASSIGN_PRODUCT_CATEGORY_POINT_SUCCESS'
export const ASSIGN_PRODUCT_CATEGORY_POINT_FAIL = 'ASSIGN_PRODUCT_CATEGORY_POINT_FAIL'
export const ASSIGN_PRODUCT_CATEGORY_POINT_RESET = 'ASSIGN_PRODUCT_CATEGORY_POINT_RESET'

export const ASSIGNED_PRODUCT_CATEGORY_POINT_REQUEST = 'ASSIGNED_PRODUCT_CATEGORY_POINT_REQUEST'
export const ASSIGNED_PRODUCT_CATEGORY_POINT_SUCCESS = 'ASSIGNED_PRODUCT_CATEGORY_POINT_SUCCESS'
export const ASSIGNED_PRODUCT_CATEGORY_POINT_FAIL = 'ASSIGNED_PRODUCT_CATEGORY_POINT_FAIL'